import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { Identifier, IdentifierType } from 'routes/profile/models/Identifier';
import { ProfileConverters, Profile } from 'routes/profile/models/Profile';
import buyerPortalClient from 'rpc/client';
import { RetrieveBuyerRequest } from 'rpc/model/squareup/buyerportal/request/request';
import { RequestStatus } from 'rpc/model/squareup/customers/request';
import { retrieveSuggestedProfileIdentifiers } from 'services/buyerportal';
import { AppState } from 'store';
import { fetchFeatures } from 'store/featureSlice';
import { fetchAuthorizedApps } from 'routes/profile/account/AuthorizedApplications/authorizedAppsSlice';
import Es2Tracker from 'services/tracking/tracker';
import { compareIdentifiers } from 'utils/identifiers';
import { logoutUrl } from 'utils/environment';
import {
  LoadingState,
  LOADING_STATES,
} from 'routes/profile/models/LoadingState';
import { buyerportalCdpClient } from 'services/tracking/cdp/clients/buyerportal';

export type BuyerState = {
  isInactive: boolean;
  isLoadingSuggestedIdentifiers: boolean;
  suggestedIdentifiers: Identifier[];
} & Profile &
  LoadingState;

export const initialState: BuyerState = {
  verifiedIdentifiers: [],
  suggestedIdentifiers: [],
  addresses: [],
  isInactive: false,
  isLoadingSuggestedIdentifiers: false,
  primaryAddressId: '',
  primaryEmailId: '',
  primaryPhoneId: '',
  primaryCardId: '',
  unverifiedEmail: null,
  ...LOADING_STATES.INITIAL,
};

export type UpdatePrimaryIdentifierIdRequest = {
  id: string;
  identifierType: IdentifierType;
};

// Utilities
export const isCardIdentifier = (i: Identifier) =>
  i.identifierType === IdentifierType.Card;

export const selectBuyer = createSelector(
  (state: AppState) => state.profile,
  (profile) => profile
);
export const selectIdentifierCollection = createSelector(
  (appState: AppState, _: IdentifierType) =>
    appState.profile.verifiedIdentifiers,
  (_: AppState, targetIdentifierType: IdentifierType) => targetIdentifierType,
  (verifiedIdentifiers: Identifier[], targetIdentifierType: IdentifierType) =>
    verifiedIdentifiers.filter(
      (vIdent) => vIdent.identifierType === targetIdentifierType
    )
);

export const selectUnverifiedIdentifierCollection = createSelector(
  (appState: AppState, _: IdentifierType) =>
    appState.profile.suggestedIdentifiers,
  (_: AppState, targetIdentifierType: IdentifierType) => targetIdentifierType,
  (suggestedIdentifiers: Identifier[], targetIdentifierType: IdentifierType) =>
    suggestedIdentifiers.filter(
      (identifier) => identifier.identifierType === targetIdentifierType
    )
);

// Similar to the selectPrimaryId selector, but with BuyerState as the first arg instead of AppState.
// This means this can be used within action handlers that only have access to the BuyerState slice.
function getPrimaryId(
  profile: BuyerState,
  identifierType: IdentifierType
): string | undefined {
  switch (identifierType) {
    case IdentifierType.Email: {
      return profile.primaryEmailId;
    }
    case IdentifierType.Phone: {
      return profile.primaryPhoneId;
    }
    case IdentifierType.Card: {
      return profile.primaryCardId;
    }
    default: {
      return undefined;
    }
  }
}

export const selectPrimaryId = createSelector(
  [
    selectBuyer,
    (_: AppState, identifierType: IdentifierType) => identifierType,
  ],
  getPrimaryId
);

export const selectAddresses = createSelector(
  selectBuyer,
  (profile) => profile.addresses
);

export const selectPrimaryAddressId = createSelector(
  selectBuyer,
  (profile) => profile.primaryAddressId
);

export const selectBuyerMissingIdentifierTypes = createSelector(
  selectBuyer,
  (profile) =>
    Object.values(IdentifierType).filter(
      (identifierType) =>
        !profile.verifiedIdentifiers.some(
          (identifier) => identifier.identifierType === identifierType
        )
    )
);

export const fetchBuyer = createAsyncThunk<
  Profile,
  { skipLoadingState?: boolean; skipCardFetching?: boolean } | undefined,
  { state: AppState }
>('buyer/fetchBuyer', async (options, thunkApi) => {
  const { dispatch } = thunkApi;

  const handleDeletedBuyer = () => {
    dispatch(setInactive());
    return thunkApi.rejectWithValue('Buyer is pending deletion');
  };
  try {
    const response = await buyerPortalClient.retrieveBuyer(
      RetrieveBuyerRequest.create()
    );
    switch (response.status) {
      case RequestStatus.STATUS_DELETED: {
        return handleDeletedBuyer();
      }
      case RequestStatus.STATUS_NOT_FOUND: {
        window.location.replace(logoutUrl());
        return thunkApi.rejectWithValue('Buyer is deleted or does not exist');
      }
    }

    if (response.status !== RequestStatus.STATUS_SUCCESS) {
      throw new Error('Failed to retrieve buyer');
    }

    if (!response.buyer) {
      throw new Error('Could not find buyer on response from retrieveBuyer');
    }

    const personToken = response.buyer.personToken;
    const profile = ProfileConverters.fromRpcBuyer(response.buyer);

    // setup cdp tracking entity
    buyerportalCdpClient.identify(personToken);

    // setup es2 tracking
    Es2Tracker.setBuyerToken(personToken);

    const resourceFetches: Promise<any>[] = [
      dispatch(fetchFeatures(response.buyer.personToken)),
      dispatch(fetchAuthorizedApps()),
    ];

    await Promise.all(resourceFetches);

    return profile;
  } catch {
    // TODO - redirect to error page

    return thunkApi.rejectWithValue('Could not retrieve buyer');
  }
});

export const fetchSuggestedBuyerIdentifiers = createAsyncThunk<Identifier[]>(
  'buyer/fetchSuggestedBuyerIdentifiers',
  async () => {
    return await retrieveSuggestedProfileIdentifiers();
  }
);

const buyerSlice = createSlice({
  name: 'buyer',
  initialState,
  reducers: {
    setInactive(state) {
      return {
        ...state,
        ...LOADING_STATES.LOADED,
        isInactive: true,
      };
    },
    addIdentifier(state, action: PayloadAction<Identifier>) {
      const newIdentifier = action.payload;

      // Part 1: Check to see if this is the first identifier of its type.
      // If it is, the `primary${identifierType}Id` will be an empty string, and we need to
      // update it the same way the backend did i.e. assign it this new identifier's id.
      // Note: This does not apply to card identifiers, as the primaryCardId is referring to the primary
      // *chargeable* card, not preference card.
      if (newIdentifier.identifierType !== IdentifierType.Card) {
        const isFirstOfKind =
          getPrimaryId(state, newIdentifier.identifierType) === '';
        if (isFirstOfKind) {
          switch (newIdentifier.identifierType) {
            case IdentifierType.Email: {
              state.primaryEmailId = newIdentifier.token;
              break;
            }
            case IdentifierType.Phone: {
              state.primaryPhoneId = newIdentifier.token;
              break;
            }
            default: {
              throw new Error(
                `Identifier found to have invalid type at runtime: ${newIdentifier.identifierType}`
              );
            }
          }
        }
      }

      // Part 2: Add the identifier to our frontend state store.
      state.verifiedIdentifiers.push(newIdentifier);
      // Sort on add to stay consistent with the initial ordering
      state.verifiedIdentifiers.sort(compareIdentifiers);

      // Part 3: Check to see if the identifier we just added was one of the unverified identifiers
      // OR if it was *THE* unverified email (from ecom).
      // If so, remove the unverified version of the identifier since it was just verified.
      if (state.unverifiedEmail?.token === newIdentifier.token) {
        state.unverifiedEmail = null;
      }
      state.suggestedIdentifiers = state.suggestedIdentifiers.filter(
        (suggested) => suggested.token !== newIdentifier.token
      );
    },
    // manual* reducers are used by RTK Query to update the base buyer stored outside RTK Query
    manualUpdateBuyer(state, action: PayloadAction<Profile>) {
      return { ...state, ...action.payload };
    },
    manualDeleteVerifiedIdentifier(state, action: PayloadAction<Identifier>) {
      state.verifiedIdentifiers = state.verifiedIdentifiers.filter(
        (identifier) => identifier.token !== action.payload.token
      );
    },
    manualDeleteAddress(state, action: PayloadAction<string>) {
      state.addresses = state.addresses.filter(
        (address) => address.id !== action.payload
      );
    },
    manualDeleteProfile(state) {
      state.isInactive = true;
    },
    updatePrimaryPaymentCardId(state, action: PayloadAction<string>) {
      state.primaryCardId = action.payload;
    },
    removeSuggestedBuyerIdentifier(state, action: PayloadAction<string>) {
      const { payload: idToRemove } = action;
      state.suggestedIdentifiers = state.suggestedIdentifiers.filter(
        (suggestedIdentifier) => suggestedIdentifier.token !== idToRemove
      );
    },
  },
  extraReducers: (builder) => {
    // fetchBuyer
    builder.addCase(fetchBuyer.pending, (state, action) => {
      if (action.meta?.arg?.skipLoadingState) {
        return state;
      }

      return { ...state, ...LOADING_STATES.LOADING };
    });

    builder.addCase(fetchBuyer.fulfilled, (state, action) => {
      return { ...state, ...LOADING_STATES.LOADED, ...action.payload };
    });

    builder.addCase(fetchBuyer.rejected, (state) => {
      return { ...state, ...LOADING_STATES.LOADED };
    });

    // fetchSuggestedBuyerIdentifiers
    builder.addCase(fetchSuggestedBuyerIdentifiers.pending, (state) => {
      return { ...state, isLoadingSuggestedIdentifiers: true };
    });

    builder.addCase(
      fetchSuggestedBuyerIdentifiers.fulfilled,
      (state, action) => {
        return {
          ...state,
          isLoadingSuggestedIdentifiers: false,
          suggestedIdentifiers: action.payload,
        };
      }
    );

    builder.addCase(fetchSuggestedBuyerIdentifiers.rejected, (state) => {
      return { ...state, isLoadingSuggestedIdentifiers: false };
    });
  },
});

export const {
  addIdentifier,
  setInactive,
  manualUpdateBuyer,
  manualDeleteVerifiedIdentifier,
  manualDeleteAddress,
  manualDeleteProfile,
  updatePrimaryPaymentCardId,
  removeSuggestedBuyerIdentifier,
} = buyerSlice.actions;

export default buyerSlice.reducer;
