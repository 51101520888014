/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../../Fixes';
import Builder from '../../../../../Builder';
import '../../../../connect/v2/common/money';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      card: {
        nested: {
          balance: {
            nested: {
              loyalty_api: {
                nested: {
                  model: {
                    nested: {
                      LoyaltyPromotionLocations: {
                        fields: {
                          type: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyPromotionLocations.EligibilityType',
                            id: 1,
                            rule: 'optional',
                          },
                          qualifyingLocationIds: {
                            type: 'string',
                            id: 2,
                            rule: 'repeated',
                          },
                        },
                        nested: {
                          EligibilityType: {
                            values: {
                              TYPE_DO_NOT_USE: 0,
                              ALL_LOYALTY_LOCATIONS: 1,
                              QUALIFYING_LOCATIONS: 2,
                            },
                          },
                        },
                      },
                      LoyaltyPromotionIncentive: {
                        fields: {
                          type: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyPromotionIncentive.Type',
                            id: 1,
                            rule: 'optional',
                          },
                          pointsMultiplierData: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyPromotionIncentive.PointsMultiplierData',
                            id: 2,
                            rule: 'optional',
                          },
                          pointsAdditionData: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyPromotionIncentive.PointsAdditionData',
                            id: 3,
                            rule: 'optional',
                          },
                        },
                        nested: {
                          Type: {
                            values: {
                              TYPE_DO_NOT_USE: 0,
                              POINTS_MULTIPLIER: 1,
                              POINTS_ADDITION: 2,
                            },
                          },
                          PointsMultiplierData: {
                            fields: {
                              pointsMultiplier: {
                                type: 'uint32',
                                id: 1,
                                rule: 'optional',
                              },
                              multiplier: {
                                type: 'string',
                                id: 3,
                                rule: 'optional',
                              },
                            },
                          },
                          PointsAdditionData: {
                            fields: {
                              pointsAddition: {
                                type: 'uint32',
                                id: 1,
                                rule: 'optional',
                              },
                            },
                          },
                        },
                      },
                      LoyaltyPromotionAvailableTimeData: {
                        fields: {
                          startDate: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                          endDate: {
                            type: 'string',
                            id: 2,
                            rule: 'optional',
                          },
                          timePeriods: {
                            type: 'string',
                            id: 3,
                            rule: 'repeated',
                          },
                        },
                      },
                      LoyaltyPromotionTriggerLimit: {
                        fields: {
                          times: {
                            type: 'uint32',
                            id: 1,
                            rule: 'optional',
                            options: {
                              default: 1,
                            },
                          },
                          timePeriod: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyPromotionTriggerLimit.TimePeriod',
                            id: 2,
                            rule: 'optional',
                          },
                          interval: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyPromotionTriggerLimitInterval',
                            id: 3,
                            rule: 'optional',
                          },
                        },
                        nested: {
                          TimePeriod: {
                            values: {
                              TYPE_DO_NOT_USE: 0,
                              ALL_TIME: 1,
                              DAY: 2,
                            },
                          },
                        },
                      },
                      LoyaltyPromotionTriggerLimitInterval: {
                        values: {
                          TYPE_DO_NOT_USE: 0,
                          ALL_TIME: 1,
                          DAY: 2,
                        },
                      },
                      LoyaltyPromotionGenerationMethod: {
                        values: {
                          DO_NOT_USE: 0,
                          AI_GENERATED: 1,
                          AI_GENERATED_USER_MODIFIED: 2,
                          MANUAL: 3,
                        },
                      },
                      LoyaltyPromotion: {
                        fields: {
                          id: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                          name: {
                            type: 'string',
                            id: 2,
                            rule: 'optional',
                          },
                          incentive: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyPromotionIncentive',
                            id: 3,
                            rule: 'optional',
                          },
                          availableTime: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyPromotionAvailableTimeData',
                            id: 4,
                            rule: 'optional',
                          },
                          triggerLimit: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyPromotionTriggerLimit',
                            id: 5,
                            rule: 'optional',
                          },
                          minimumAmountSpentMoney: {
                            type: 'squareup.connect.v2.common.Money',
                            id: 6,
                            rule: 'optional',
                          },
                          status: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyPromotion.Status',
                            id: 7,
                            rule: 'optional',
                          },
                          createdAt: {
                            type: 'string',
                            id: 8,
                            rule: 'optional',
                          },
                          canceledAt: {
                            type: 'string',
                            id: 9,
                            rule: 'optional',
                          },
                          updatedAt: {
                            type: 'string',
                            id: 10,
                            rule: 'optional',
                          },
                          loyaltyProgramId: {
                            type: 'string',
                            id: 11,
                            rule: 'optional',
                          },
                          version: {
                            type: 'uint32',
                            id: 12,
                            rule: 'optional',
                          },
                          minimumSpendAmountMoney: {
                            type: 'squareup.connect.v2.common.Money',
                            id: 13,
                            rule: 'optional',
                          },
                          locations: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyPromotionLocations',
                            id: 14,
                            rule: 'optional',
                          },
                          qualifyingItemVariationIds: {
                            type: 'string',
                            id: 15,
                            rule: 'repeated',
                          },
                          qualifyingCategoryIds: {
                            type: 'string',
                            id: 16,
                            rule: 'repeated',
                          },
                          creationMethod: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyPromotionGenerationMethod',
                            id: 17,
                            rule: 'optional',
                          },
                        },
                        nested: {
                          Status: {
                            values: {
                              TYPE_DO_NOT_USE: 0,
                              UPCOMING: 1,
                              ACTIVE: 2,
                              ENDED: 3,
                              CANCELED: 4,
                              SCHEDULED: 5,
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyPromotionLocations &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyPromotionLocations?.nested
    ?.EligibilityType &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyPromotionIncentive &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyPromotionIncentive?.nested?.Type &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyPromotionIncentive?.nested
    ?.PointsMultiplierData &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyPromotionIncentive?.nested
    ?.PointsAdditionData &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyPromotionAvailableTimeData &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyPromotionTriggerLimit &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyPromotionTriggerLimit?.nested?.TimePeriod &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyPromotionTriggerLimitInterval &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyPromotionGenerationMethod &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyPromotion &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyPromotion?.nested?.Status
) {
  $root.addJSON(jsonData);
}

/**
 * --
 * @desc Represents the eligible locations for a [loyalty promotion](entity:LoyaltyPromotion).
 * --
 *
 * SOURCE LoyaltyPromotionLocations @ squareup/card/balance/loyalty_api/model/loyalty-promotion.proto at 18:1
 */
export const LoyaltyPromotionLocations = $root.lookupType(
  'squareup.card.balance.loyalty_api.model.LoyaltyPromotionLocations'
);
fixType(LoyaltyPromotionLocations, {
  type: { required: true },
  qualifyingLocationIds: { notEmpty: true },
});
Builder.createAndAttachToType(LoyaltyPromotionLocations);
/**
 * SOURCE EligibilityType @ squareup/card/balance/loyalty_api/model/loyalty-promotion.proto at 21:3
 */
LoyaltyPromotionLocations.EligibilityType = $root.lookupEnum(
  'squareup.card.balance.loyalty_api.model.LoyaltyPromotionLocations.EligibilityType'
).values;
/**
 * --
 * @desc Represents how points for a [loyalty promotion](entity:LoyaltyPromotion) are calculated,
 * either by multiplying the points earned from the base program or by adding a specified number
 * of points to the points earned from the base program.
 * --
 *
 * SOURCE LoyaltyPromotionIncentive @ squareup/card/balance/loyalty_api/model/loyalty-promotion.proto at 54:1
 */
export const LoyaltyPromotionIncentive = $root.lookupType(
  'squareup.card.balance.loyalty_api.model.LoyaltyPromotionIncentive'
);
fixType(LoyaltyPromotionIncentive, {
  type: { required: true },
});
Builder.createAndAttachToType(LoyaltyPromotionIncentive);
/**
 * --
 * @desc Indicates the type of points incentive for a [loyalty promotion](entity:LoyaltyPromotion),
 * which is used to determine how buyers can earn points from the promotion.
 * --
 *
 * SOURCE Type @ squareup/card/balance/loyalty_api/model/loyalty-promotion.proto at 61:3
 */
LoyaltyPromotionIncentive.Type = $root.lookupEnum(
  'squareup.card.balance.loyalty_api.model.LoyaltyPromotionIncentive.Type'
).values;
/**
 * --
 * @desc Represents the metadata for a `POINTS_MULTIPLIER` type of [loyalty promotion incentive](entity:LoyaltyPromotionIncentive).
 * --
 *
 * SOURCE PointsMultiplierData @ squareup/card/balance/loyalty_api/model/loyalty-promotion.proto at 80:3
 */
LoyaltyPromotionIncentive.PointsMultiplierData = $root.lookupType(
  'squareup.card.balance.loyalty_api.model.LoyaltyPromotionIncentive.PointsMultiplierData'
);
fixType(LoyaltyPromotionIncentive.PointsMultiplierData);
Builder.createAndAttachToType(LoyaltyPromotionIncentive.PointsMultiplierData);
/**
 * --
 * @desc Represents the metadata for a `POINTS_ADDITION` type of [loyalty promotion incentive](entity:LoyaltyPromotionIncentive).
 * --
 *
 * SOURCE PointsAdditionData @ squareup/card/balance/loyalty_api/model/loyalty-promotion.proto at 122:3
 */
LoyaltyPromotionIncentive.PointsAdditionData = $root.lookupType(
  'squareup.card.balance.loyalty_api.model.LoyaltyPromotionIncentive.PointsAdditionData'
);
fixType(LoyaltyPromotionIncentive.PointsAdditionData, {
  pointsAddition: { required: true },
});
Builder.createAndAttachToType(LoyaltyPromotionIncentive.PointsAdditionData);
/**
 * --
 * @desc Represents scheduling information that determines when purchases can qualify to earn points
 * from a [loyalty promotion](entity:LoyaltyPromotion).
 * --
 *
 * SOURCE LoyaltyPromotionAvailableTimeData @ squareup/card/balance/loyalty_api/model/loyalty-promotion.proto at 154:1
 */
export const LoyaltyPromotionAvailableTimeData = $root.lookupType(
  'squareup.card.balance.loyalty_api.model.LoyaltyPromotionAvailableTimeData'
);
fixType(LoyaltyPromotionAvailableTimeData, {
  timePeriods: { required: true },
});
Builder.createAndAttachToType(LoyaltyPromotionAvailableTimeData);
/**
 * --
 * @desc Represents the number of times a buyer can earn points during a [loyalty promotion](entity:LoyaltyPromotion).
 * If this field is not set, buyers can trigger the promotion an unlimited number of times to earn points during
 * the time that the promotion is available.
 *
 * A purchase that is disqualified from earning points because of this limit might qualify for another active promotion.
 * --
 *
 * SOURCE LoyaltyPromotionTriggerLimit @ squareup/card/balance/loyalty_api/model/loyalty-promotion.proto at 194:1
 */
export const LoyaltyPromotionTriggerLimit = $root.lookupType(
  'squareup.card.balance.loyalty_api.model.LoyaltyPromotionTriggerLimit'
);
fixType(LoyaltyPromotionTriggerLimit, {
  times: { required: true },
});
Builder.createAndAttachToType(LoyaltyPromotionTriggerLimit);
/**
 * SOURCE TimePeriod @ squareup/card/balance/loyalty_api/model/loyalty-promotion.proto at 205:3
 */
LoyaltyPromotionTriggerLimit.TimePeriod = $root.lookupEnum(
  'squareup.card.balance.loyalty_api.model.LoyaltyPromotionTriggerLimit.TimePeriod'
).values;
/**
 * --
 * @desc Indicates the time period that the [trigger limit](entity:LoyaltyPromotionTriggerLimit) applies to,
 * which is used to determine the number of times a buyer can earn points for a [loyalty promotion](entity:LoyaltyPromotion).
 * --
 *
 * SOURCE LoyaltyPromotionTriggerLimitInterval @ squareup/card/balance/loyalty_api/model/loyalty-promotion.proto at 241:1
 */
export const LoyaltyPromotionTriggerLimitInterval = $root.lookupEnum(
  'squareup.card.balance.loyalty_api.model.LoyaltyPromotionTriggerLimitInterval'
).values;
/**
 * SOURCE LoyaltyPromotionGenerationMethod @ squareup/card/balance/loyalty_api/model/loyalty-promotion.proto at 262:1
 */
export const LoyaltyPromotionGenerationMethod = $root.lookupEnum(
  'squareup.card.balance.loyalty_api.model.LoyaltyPromotionGenerationMethod'
).values;
/**
 * --
 * @desc Represents a promotion for a [loyalty program](entity:LoyaltyProgram). Loyalty promotions enable buyers
 * to earn extra points on top of those earned from the base program.
 *
 * A loyalty program can have a maximum of 10 loyalty promotions with an `ACTIVE` or `SCHEDULED` status.
 * --
 *
 * SOURCE LoyaltyPromotion @ squareup/card/balance/loyalty_api/model/loyalty-promotion.proto at 289:1
 */
export const LoyaltyPromotion = $root.lookupType(
  'squareup.card.balance.loyalty_api.model.LoyaltyPromotion'
);
fixType(LoyaltyPromotion, {
  name: { notEmpty: true },
  incentive: { required: true },
  availableTime: { required: true },
  qualifyingItemVariationIds: { notEmpty: true },
  qualifyingCategoryIds: { notEmpty: true },
});
Builder.createAndAttachToType(LoyaltyPromotion);
/**
 * --
 * @desc Indicates the status of a [loyalty promotion](entity:LoyaltyPromotion).
 * --
 *
 * SOURCE Status @ squareup/card/balance/loyalty_api/model/loyalty-promotion.proto at 295:3
 */
LoyaltyPromotion.Status = $root.lookupEnum(
  'squareup.card.balance.loyalty_api.model.LoyaltyPromotion.Status'
).values;
