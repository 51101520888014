/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import '../model/product';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      passes: {
        nested: {
          service: {
            nested: {
              GetPassDownloadUrlRequest: {
                fields: {
                  merchantToken: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  productType: {
                    type: 'squareup.passes.model.ProductType',
                    id: 2,
                    rule: 'optional',
                  },
                  backingEntityToken: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                },
              },
              GetPassDownloadUrlResponse: {
                fields: {
                  passDownloadInfo: {
                    type: 'squareup.passes.service.PassDownloadInfo',
                    id: 2,
                    rule: 'optional',
                  },
                },
              },
              PassDownloadInfo: {
                fields: {
                  applePersonalizedUrl: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  isApplePassInstalled: {
                    type: 'bool',
                    id: 2,
                    rule: 'optional',
                  },
                },
              },
              CreatePersonalizedLoyaltyPassUrlRequest: {
                fields: {
                  loyaltyAccountToken: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  source: {
                    type: 'squareup.passes.service.CreatePersonalizedLoyaltyPassUrlRequest.SOURCE',
                    id: 2,
                    rule: 'optional',
                  },
                },
                nested: {
                  SOURCE: {
                    values: {
                      DO_NOT_USE: 0,
                      BUYER_STATUS_PAGE: 1,
                      DIGITAL_RECEIPTS: 2,
                      MERCHANT_PORTAL: 3,
                    },
                  },
                },
              },
              CreatePersonalizedLoyaltyPassUrlResponse: {
                fields: {
                  personalizedPassDownloadData: {
                    type: 'squareup.passes.service.CreatePersonalizedLoyaltyPassUrlResponse.PersonalizedPassDownloadData',
                    id: 1,
                    rule: 'optional',
                  },
                },
                nested: {
                  PersonalizedPassDownloadData: {
                    fields: {
                      passDownloadUrl: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      isInstalled: {
                        type: 'bool',
                        id: 2,
                        rule: 'optional',
                      },
                    },
                  },
                },
              },
              UrlService: {
                methods: {
                  GetPassDownloadUrl: {
                    requestType:
                      'squareup.passes.service.GetPassDownloadUrlRequest',
                    responseType:
                      'squareup.passes.service.GetPassDownloadUrlResponse',
                  },
                  CreatePersonalizedLoyaltyPassUrl: {
                    requestType:
                      'squareup.passes.service.CreatePersonalizedLoyaltyPassUrlRequest',
                    responseType:
                      'squareup.passes.service.CreatePersonalizedLoyaltyPassUrlResponse',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.passes?.nested?.service?.nested
    ?.GetPassDownloadUrlRequest &&
  !$root.nested?.squareup?.nested?.passes?.nested?.service?.nested
    ?.GetPassDownloadUrlResponse &&
  !$root.nested?.squareup?.nested?.passes?.nested?.service?.nested
    ?.PassDownloadInfo &&
  !$root.nested?.squareup?.nested?.passes?.nested?.service?.nested
    ?.CreatePersonalizedLoyaltyPassUrlRequest &&
  !$root.nested?.squareup?.nested?.passes?.nested?.service?.nested
    ?.CreatePersonalizedLoyaltyPassUrlRequest?.nested?.SOURCE &&
  !$root.nested?.squareup?.nested?.passes?.nested?.service?.nested
    ?.CreatePersonalizedLoyaltyPassUrlResponse &&
  !$root.nested?.squareup?.nested?.passes?.nested?.service?.nested
    ?.CreatePersonalizedLoyaltyPassUrlResponse?.nested
    ?.PersonalizedPassDownloadData &&
  !$root.nested?.squareup?.nested?.passes?.nested?.service?.nested?.UrlService
) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE GetPassDownloadUrlRequest @ squareup/passes/service/url-service.proto at 13:1
 */
export const GetPassDownloadUrlRequest = $root.lookupType(
  'squareup.passes.service.GetPassDownloadUrlRequest'
);
fixType(GetPassDownloadUrlRequest, {
  merchantToken: { notEmpty: true },
  productType: { required: true },
  backingEntityToken: { notEmpty: true },
});
Builder.createAndAttachToType(GetPassDownloadUrlRequest);
/**
 * SOURCE GetPassDownloadUrlResponse @ squareup/passes/service/url-service.proto at 22:1
 */
export const GetPassDownloadUrlResponse = $root.lookupType(
  'squareup.passes.service.GetPassDownloadUrlResponse'
);
fixType(GetPassDownloadUrlResponse);
Builder.createAndAttachToType(GetPassDownloadUrlResponse);
/**
 * SOURCE PassDownloadInfo @ squareup/passes/service/url-service.proto at 29:1
 */
export const PassDownloadInfo = $root.lookupType(
  'squareup.passes.service.PassDownloadInfo'
);
fixType(PassDownloadInfo, {
  applePersonalizedUrl: { required: true },
  isApplePassInstalled: { required: true },
});
Builder.createAndAttachToType(PassDownloadInfo);
/**
 * SOURCE CreatePersonalizedLoyaltyPassUrlRequest @ squareup/passes/service/url-service.proto at 36:1
 */
export const CreatePersonalizedLoyaltyPassUrlRequest = $root.lookupType(
  'squareup.passes.service.CreatePersonalizedLoyaltyPassUrlRequest'
);
fixType(CreatePersonalizedLoyaltyPassUrlRequest, {
  loyaltyAccountToken: { notEmpty: true },
  source: { required: true },
});
Builder.createAndAttachToType(CreatePersonalizedLoyaltyPassUrlRequest);
/**
 * SOURCE SOURCE @ squareup/passes/service/url-service.proto at 37:3
 */
CreatePersonalizedLoyaltyPassUrlRequest.SOURCE = $root.lookupEnum(
  'squareup.passes.service.CreatePersonalizedLoyaltyPassUrlRequest.SOURCE'
).values;
/**
 * SOURCE CreatePersonalizedLoyaltyPassUrlResponse @ squareup/passes/service/url-service.proto at 54:1
 */
export const CreatePersonalizedLoyaltyPassUrlResponse = $root.lookupType(
  'squareup.passes.service.CreatePersonalizedLoyaltyPassUrlResponse'
);
fixType(CreatePersonalizedLoyaltyPassUrlResponse);
Builder.createAndAttachToType(CreatePersonalizedLoyaltyPassUrlResponse);
/**
 * SOURCE PersonalizedPassDownloadData @ squareup/passes/service/url-service.proto at 60:3
 */
CreatePersonalizedLoyaltyPassUrlResponse.PersonalizedPassDownloadData =
  $root.lookupType(
    'squareup.passes.service.CreatePersonalizedLoyaltyPassUrlResponse.PersonalizedPassDownloadData'
  );
fixType(CreatePersonalizedLoyaltyPassUrlResponse.PersonalizedPassDownloadData, {
  passDownloadUrl: { notEmpty: true },
  isInstalled: { notEmpty: true },
});
Builder.createAndAttachToType(
  CreatePersonalizedLoyaltyPassUrlResponse.PersonalizedPassDownloadData
);
/**
 * SOURCE UrlService @ squareup/passes/service/url-service.proto at 66:1
 */
export const UrlService = $root.lookupService(
  'squareup.passes.service.UrlService'
);
