/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import './request';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      buyerportal: {
        nested: {
          orders: {
            nested: {
              OrderService: {
                methods: {
                  ListOrders: {
                    requestType:
                      'squareup.buyerportal.orders.ListOrdersRequest',
                    responseType:
                      'squareup.buyerportal.orders.ListOrdersResponse',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.orders?.nested
    ?.OrderService
) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE OrderService @ squareup/buyerportal/orders/service.proto at 12:1
 */
export const OrderService = $root.lookupService(
  'squareup.buyerportal.orders.OrderService'
);
