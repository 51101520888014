import {
  GiftCard as RpcGiftCard,
  ValidGiftCard as ValidRpcGiftCard,
  GiftCardDetail as RpcGiftCardDetail,
  ValidGiftCardDetail as ValidRpcGiftCardDetail,
} from 'rpc/model/squareup/buyerportal/giftcards/data';
import { CurrencyCode } from 'rpc/model/squareup/common/currency_codes';
import { MerchantInfo } from 'routes/profile/models/Merchant';
import { format as formatMoney } from 'utils/currency';
import { DateTime } from 'rpc/model/squareup/common/time';

export const PAGE_SIZE = 100;

export interface Money {
  amount: number;
  currencyCode: string;
}

export interface GiftCardAttribute {
  token: string;
  merchantInfo: MerchantInfo;
  balance: Money;
  merchantUnitToken: string;
  panSuffix: string;
}

export class GiftCard implements GiftCardAttribute {
  public token: string;
  public merchantInfo: MerchantInfo;
  public balance: Money;
  public merchantUnitToken: string;
  public panSuffix: string;

  static fromResponse = function (giftCard: RpcGiftCard) {
    const balance = {
      amount: giftCard.balance!.amount!,
      currencyCode: CurrencyCode[giftCard.balance!.currencyCode!],
    };

    return new GiftCard({
      token: giftCard.giftCardTokenFingerprint!,
      merchantInfo: {
        id: giftCard.merchantInfo!.id!,
        displayName: giftCard.merchantInfo!.displayName!,
        imageUrl: giftCard.merchantInfo!.imageUrl,
        businessType: giftCard.merchantInfo!.businessType,
      },
      balance,
      merchantUnitToken: giftCard.unitTokenFingerprint!,
      panSuffix: giftCard.panSuffix!,
    });
  };

  static toRpcGiftCard = function (giftCard: GiftCard): ValidRpcGiftCard {
    return RpcGiftCard.validateAndCreate({
      giftCardTokenFingerprint: giftCard.token,
      merchantInfo: {
        id: giftCard.merchantInfo.id,
        displayName: giftCard.merchantInfo.displayName,
        imageUrl: giftCard.merchantInfo.imageUrl || '',
        businessType: giftCard.merchantInfo.businessType,
      },
      balance: {
        amount: giftCard.balance.amount,
        currencyCode: CurrencyCode[giftCard.balance.currencyCode],
      },
      updatedAt: DateTime.create({
        instantUsec: Date.now() * 1000,
      }),
      unitTokenFingerprint: giftCard.merchantUnitToken,
      panSuffix: giftCard.panSuffix,
    });
  };

  formattedBalance() {
    return formatMoney(this.balance);
  }

  constructor(props: GiftCardAttribute) {
    const { token, merchantInfo, balance, merchantUnitToken, panSuffix } =
      props;

    this.token = token;
    this.merchantInfo = merchantInfo;
    this.balance = balance;
    this.merchantUnitToken = merchantUnitToken;
    this.panSuffix = panSuffix;
  }
}

export interface GiftCardDetailAttribute {
  token: string;
  gan: string;
  merchantInfo: MerchantInfo;
  balance: Money;
  merchantUnitToken: string;
  imageUrl?: string;
  detailUrl?: string;
  isAutoLink?: boolean;
  eGiftTheme?: {
    imageUrl?: string;
    backgroundColor?: string;
  };
}

export class GiftCardDetail implements GiftCardDetailAttribute {
  public token: string;
  public gan: string;
  public merchantInfo: MerchantInfo;
  public balance: Money;
  public panSuffix: string;
  public merchantUnitToken: string;
  public imageUrl?: string;
  public detailUrl?: string;
  public isAutoLink?: boolean;
  public eGiftTheme?: {
    imageUrl?: string;
    backgroundColor?: string;
  };

  static toGiftCard(giftCardDetail: GiftCardDetail): GiftCard {
    return new GiftCard({
      balance: giftCardDetail.balance,
      merchantInfo: giftCardDetail.merchantInfo,
      merchantUnitToken: giftCardDetail.merchantUnitToken,
      panSuffix: giftCardDetail.gan.slice(-4),
      token: giftCardDetail.token,
    });
  }

  static fromResponse(giftCardDetail: RpcGiftCardDetail): GiftCardDetail {
    const balance = {
      amount: giftCardDetail.balance!.amount!,
      currencyCode: CurrencyCode[giftCardDetail.balance!.currencyCode!],
    };
    const eGiftTheme = giftCardDetail.eGiftTheme
      ? {
          imageUrl: giftCardDetail.eGiftTheme.imageUrl,
          backgroundColor: giftCardDetail.eGiftTheme.backgroundColor,
        }
      : undefined;
    return new GiftCardDetail({
      token: giftCardDetail.giftCardTokenFingerprint!,
      gan: giftCardDetail.gan!,
      merchantInfo: {
        id: giftCardDetail.merchantInfo!.id!,
        displayName: giftCardDetail.merchantInfo!.displayName!,
        imageUrl: giftCardDetail.merchantInfo!.imageUrl,
        businessType: giftCardDetail.merchantInfo!.businessType,
      },
      balance,
      merchantUnitToken: giftCardDetail.unitTokenFingerprint!,
      imageUrl: giftCardDetail.eGiftTheme?.imageUrl,
      detailUrl: giftCardDetail.recipientDetailUrl,
      isAutoLink: giftCardDetail.isAutoLink,
      eGiftTheme,
    });
  }

  static toRpcGiftCardDetail(
    giftCardDetail: GiftCardDetail
  ): ValidRpcGiftCardDetail {
    return RpcGiftCardDetail.validateAndCreate({
      giftCardTokenFingerprint: giftCardDetail.token,
      gan: giftCardDetail.gan,
      merchantInfo: {
        id: giftCardDetail.merchantInfo.id,
        displayName: giftCardDetail.merchantInfo.displayName,
        imageUrl: giftCardDetail.merchantInfo.imageUrl || '',
        businessType: giftCardDetail.merchantInfo.businessType,
      },
      balance: {
        amount: giftCardDetail.balance.amount,
        currencyCode: CurrencyCode[giftCardDetail.balance.currencyCode],
      },
      unitTokenFingerprint: giftCardDetail.merchantUnitToken,
      eGiftTheme: {
        imageUrl: giftCardDetail.eGiftTheme?.imageUrl || '',
      },
      recipientDetailUrl: giftCardDetail.detailUrl,
      isAutoLink: giftCardDetail.isAutoLink,
    });
  }

  formattedBalance() {
    return formatMoney(this.balance);
  }

  constructor(props: GiftCardDetailAttribute) {
    const {
      token,
      gan,
      merchantInfo,
      balance,
      merchantUnitToken,
      imageUrl,
      detailUrl,
      isAutoLink,
      eGiftTheme,
    } = props;

    this.token = token;
    this.gan = gan;
    this.panSuffix = gan.slice(-4);
    this.merchantInfo = merchantInfo;
    this.balance = balance;
    this.merchantUnitToken = merchantUnitToken;
    this.imageUrl = imageUrl;
    this.detailUrl = detailUrl;
    this.isAutoLink = isAutoLink;
    this.eGiftTheme = eGiftTheme;
  }
}
