// eslint-disable-next-line filenames/match-exported
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as request from 'rpc/model/squareup/customers/request';
import { RequestStatus } from 'rpc/model/squareup/customers/request';
import DashboardHeader from './components/DashboardHeader';
import type { PaymentMethod } from './components/PaymentMethodOnFile';
import PaymentMethodOnFile from './components/PaymentMethodOnFile';
import RemovePaymentMethodModal from './components/RemovePaymentMethodModal';
import {
  MarketActivityIndicator,
  MarketDivider,
  MarketToast,
  MarketToaster,
} from '@market/react';
import Footer from 'components/SqPrivacyFooter';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import {
  onFileBuyerDashboardViewEvent,
  SURFACE_DASHBOARD,
} from 'services/tracking/events/onFileManagementEvents';
import Es2Tracker from 'services/tracking/tracker';
import {
  ListOnFileBankAccountsRequest,
  OnFileBankAccount,
} from 'rpc/model/squareup/buyerportal/onfile/data';
import buyerPortalClient from 'rpc/client';
import * as bank_accounts from 'rpc/model/squareup/payments/external/bank_accounts';
import PageNotFound from './components/PageNotFound';

// Helper to parse utm_medium param from the URL
export const getUtmMediumQueryParam = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  return params['utm_medium'] || '';
};

/**
 * Main component for the On File Buyer Dashboard
 * https://docs.google.com/document/d/1OpQJel-zQJDmC3sOJlRmZwhx-PnqG7ZBKV2EztdJ8l4/edit#
 */

const OnFileBuyerDashboard: React.FC = () => {
  const { baToken } = useParams();
  const [dashboardHeaderFetchState, setDashboardHeaderFetchState] =
    useState<RequestStatus | null>(null);
  const updateDashboardFetchState = (requestStatus: RequestStatus) => {
    setDashboardHeaderFetchState(requestStatus);
  };
  const [paymentMethodOnFileFetchState, setPaymentMethodOnFileFetchState] =
    useState<RequestStatus | null>(null);
  const [isRemovePaymentMethodModalOpen, setIsRemovePaymentMethodModalOpen] =
    useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethod>();
  const onSelectPaymentMethod = (paymentMethod: PaymentMethod) => {
    setSelectedPaymentMethod(paymentMethod);
    setIsRemovePaymentMethodModalOpen(true);
  };
  const areAnonymousFlagsLoaded = useSelector(
    (state: AppState) => state.feature.areAnonymousFlagsLoaded
  );

  const [removePaymentMethodErrors, setRemovePaymentMethodErrors] = useState<
    request.IRequestError[]
  >([]);
  const [
    shouldShowSuccessfullyRemovedPaymentMethod,
    setShouldShowSuccessfullyRemovedPaymentMethod,
  ] = useState(false);
  const toasterRef = useRef<HTMLMarketToasterElement>(null);
  const errorRemovePaymentMethodToastRef = useRef<HTMLMarketToastElement>(null);
  const successRemovePaymentMethodToastRef =
    useRef<HTMLMarketToastElement>(null);
  useEffect(() => {
    toasterRef?.current?.show(successRemovePaymentMethodToastRef.current);
    setShouldShowSuccessfullyRemovedPaymentMethod(false);
  }, [
    shouldShowSuccessfullyRemovedPaymentMethod,
    setShouldShowSuccessfullyRemovedPaymentMethod,
  ]);
  useEffect(() => {
    if (removePaymentMethodErrors.length > 0) {
      toasterRef?.current?.show(errorRemovePaymentMethodToastRef.current);
      toasterRef?.current?.show(errorRemovePaymentMethodToastRef.current);
      setRemovePaymentMethodErrors([]);
    }
  }, [removePaymentMethodErrors, setRemovePaymentMethodErrors]);

  useEffect(() => {
    Es2Tracker.track(
      onFileBuyerDashboardViewEvent({
        surface: SURFACE_DASHBOARD,
        utmMedium: getUtmMediumQueryParam(),
      })
    );
  }, []);
  const [paymentMethods, setPaymentMethods] = useState<
    readonly OnFileBankAccount[]
  >([]);

  useEffect(() => {
    const getLinkedBankAccountsOnFile = async () => {
      try {
        const response = await buyerPortalClient.listOnFileBankAccounts(
          ListOnFileBankAccountsRequest.create({
            buyerAccessToken: baToken?.toString(),
          })
        );
        setPaymentMethodOnFileFetchState(response?.status);
        if (response?.bankAccounts.length > 0) {
          const validPaymentMethods = response.bankAccounts.filter(
            (paymentMethod) =>
              paymentMethod.accountStatus ===
              bank_accounts.BankAccountStatus.VERIFIED
          );
          setPaymentMethods(validPaymentMethods);
        }
        // TODO: [ajohny] (CRM-15814) sync with design on what to show if the call errors
        // eslint-disable-next-line no-empty
      } catch {}
    };
    getLinkedBankAccountsOnFile();
  }, [baToken]);

  const onRemoveBankAccountMethodSuccess = () => {
    setShouldShowSuccessfullyRemovedPaymentMethod(true);
    if (selectedPaymentMethod) {
      const newPaymentMethods = paymentMethods.filter((paymentMethod) => {
        return paymentMethod.bankId !== selectedPaymentMethod.bankId;
      });
      setPaymentMethods(newPaymentMethods);
      setSelectedPaymentMethod(undefined);
    }
  };

  if (!areAnonymousFlagsLoaded) {
    return (
      <div
        className={
          'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
        }
      >
        <MarketActivityIndicator size="small" />
      </div>
    );
  }

  // TODO: (ajohny) [CRM-15898] update this to also check loading state
  const shouldShowOnFileManagementDashboard =
    (dashboardHeaderFetchState === null ||
      dashboardHeaderFetchState === RequestStatus.STATUS_SUCCESS) &&
    (paymentMethodOnFileFetchState === null ||
      paymentMethodOnFileFetchState === RequestStatus.STATUS_SUCCESS);

  return (
    <div>
      {shouldShowOnFileManagementDashboard ? (
        <div className={'flex flex-col h-screen'}>
          <DashboardHeader
            baToken={baToken}
            updateDashboardFetchState={updateDashboardFetchState}
          />
          <div className={'my-0 mx-auto max-w-[600px] p-4 h-screen'}>
            <MarketDivider
              margin="medium"
              style={{ borderRadius: '5px 5px 5px 5px' }}
            />
            <PaymentMethodOnFile
              onSelectPaymentMethod={onSelectPaymentMethod}
              paymentMethods={paymentMethods}
            />
          </div>
          {selectedPaymentMethod && (
            <RemovePaymentMethodModal
              isModalOpen={isRemovePaymentMethodModalOpen}
              selectedPaymentMethod={selectedPaymentMethod}
              onDismissed={() => {
                setIsRemovePaymentMethodModalOpen(false);
              }}
              baToken={baToken}
              onError={setRemovePaymentMethodErrors}
              onSuccess={() => {
                onRemoveBankAccountMethodSuccess();
              }}
            />
          )}
          <MarketToaster ref={toasterRef}>
            <div style={{ display: 'none' }}>
              <MarketToast
                ref={errorRemovePaymentMethodToastRef}
                variant="critical"
              >
                Payment method on file couldn't be removed.
              </MarketToast>
              <MarketToast
                ref={successRemovePaymentMethodToastRef}
                variant="success"
              >
                Payment method on file removed.
              </MarketToast>
            </div>
          </MarketToaster>
          <Footer />
        </div>
      ) : (
        <PageNotFound />
      )}
    </div>
  );
};

export default OnFileBuyerDashboard;
