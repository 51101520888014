import { Money } from 'rpc/model/squareup/connect/v2/common/money';
import { Address as RpcAddress } from 'rpc/model/squareup/connect/v2/resources/address';
import { MerchantInfo } from 'routes/profile/models/Merchant';
import { Card } from './Card';
import { Receipt as RpcReceipt } from 'rpc/model/squareup/receipts/data';
import { OrderListItem } from 'rpc/model/squareup/buyerportal/orders/data';
import { getHumanFriendlyCardBrand } from 'utils/cardBrand';

export interface ReceiptItemAttribute {
  name: string;
  quantity: string;
  quantityUnit: string;
  amount?: Money;
}

export class ReceiptItem {
  public name: string;
  public quantity: string;
  public quantityUnit: string;
  public amount?: Money;

  constructor(props: ReceiptItemAttribute) {
    const { name, amount, quantity, quantityUnit } = props;

    this.name = name;
    this.quantity = quantity;
    this.quantityUnit = quantityUnit;
    this.amount = amount;
  }
}

export interface PaymentAddressAttribute {
  name?: string;
  address?: RpcAddress;
}

export class PaymentAddress {
  public name?: string;
  public address?: RpcAddress;

  constructor(props: PaymentAddressAttribute) {
    const { name, address } = props;

    this.name = name;
    this.address = address;
  }

  static formatAddressString = function (address: RpcAddress): string {
    const joinIfPresent = function (
      separator: string,
      items: string[]
    ): string {
      return items.filter((item) => item?.trim()).join(separator);
    };

    const addressParts = [
      address.addressLine1,
      address.addressLine2,
      joinIfPresent(', ', [
        address.locality,
        joinIfPresent(' ', [
          address.administrativeDistrictLevel1,
          address.postalCode,
        ]),
      ]),
    ];

    return joinIfPresent(', ', addressParts);
  };
}

export interface ReceiptAttribute {
  items: ReceiptItem[];
  taxAmount?: Money;
  billingAddress: PaymentAddress;
  shippingAddress: PaymentAddress;
  shippingMethod: string;
  paymentCard: Card;
  digitalReceipts: RpcReceipt[];
  paymentIds: string[];
}

export class Receipt {
  public items: ReceiptItem[];
  public taxAmount?: Money;
  public billingAddress: PaymentAddress;
  public shippingAddress: PaymentAddress;
  public shippingMethod: string;
  public paymentCard: Card;
  public digitalReceipts: RpcReceipt[];
  public paymentIds: string[];

  constructor(props: ReceiptAttribute) {
    const {
      items,
      taxAmount,
      billingAddress,
      shippingAddress,
      shippingMethod,
      paymentCard,
      digitalReceipts,
      paymentIds,
    } = props;

    this.items = items;
    this.taxAmount = taxAmount;
    this.billingAddress = billingAddress;
    this.shippingAddress = shippingAddress;
    this.shippingMethod = shippingMethod;
    this.paymentCard = paymentCard;
    this.digitalReceipts = digitalReceipts;
    this.paymentIds = paymentIds;
  }
}

export interface PaymentAttribute {
  token: string;
  merchantInfo: MerchantInfo;
  totalMoney?: Money;
  totalItems: number;
  createdAt: string;
  receipt: Receipt;
}

export class Payment {
  public token: string;
  public merchantInfo: MerchantInfo;
  public totalMoney?: Money;
  public totalItems: number;
  public createdAt: string;
  public receipt: Receipt;
  public subtotal?: Money;

  constructor(props: PaymentAttribute) {
    const { token, merchantInfo, totalMoney, totalItems, createdAt, receipt } =
      props;

    this.token = token;
    this.merchantInfo = merchantInfo;
    this.totalMoney = totalMoney;
    this.totalItems = totalItems;
    this.createdAt = createdAt;
    this.receipt = receipt;
    this.subtotal = Money.create({
      amount:
        (this.totalMoney?.amount || 0) - (this.receipt.taxAmount?.amount || 0),
      currency: this.totalMoney?.currency,
    });
  }

  static fromResponse = function (payment: OrderListItem): Payment | undefined {
    const { id, displayName, imageUrl } = payment.merchantInfo!;
    const cardBrand = payment.order?.tenders[0]?.cardDetails?.card?.cardBrand;
    const cardBrandText = cardBrand ? getHumanFriendlyCardBrand(cardBrand) : '';
    const shipmentDetails = payment.order?.fulfillments[0]?.shipmentDetails;

    if (payment.order) {
      return new Payment({
        createdAt: payment.order.createdAt,
        merchantInfo: { displayName, id, imageUrl },
        receipt: new Receipt({
          billingAddress: new PaymentAddress({
            address:
              payment.order.tenders[0]?.cardDetails?.card?.billingAddress,
            name: payment.order.tenders[0]?.cardDetails?.card?.cardholderName,
          }),
          digitalReceipts: payment.receipts ? [...payment.receipts] : [],
          items: payment.order.lineItems.map((lineItem) => {
            return new ReceiptItem({
              amount: lineItem.totalMoney,
              name: lineItem.name,
              quantity: lineItem.quantity,
              quantityUnit: String(lineItem.quantityUnit?.measurementUnit),
            });
          }),
          paymentCard: new Card({
            brand: cardBrandText,
            cardId:
              payment.order.tenders[0]?.cardDetails?.card?.id || 'unknown',
            cardPreferences: [],
            displayName:
              payment.order.tenders[0]?.cardDetails?.card?.last4 || '',
          }),
          paymentIds: payment.order.paymentGroups.flatMap(
            (paymentGroup) => paymentGroup.paymentIds
          ),
          shippingAddress: new PaymentAddress({
            address:
              payment.order.fulfillments[0]?.shipmentDetails?.recipient
                ?.address,
            name: payment.order.fulfillments[0]?.shipmentDetails?.recipient
              ?.displayName,
          }),
          shippingMethod: shipmentDetails
            ? `${shipmentDetails.shippingType} (${shipmentDetails.carrier})`
            : '',
          taxAmount: payment.order.totalTipMoney,
        }),
        token: payment.order.id,
        totalItems: payment.order.lineItems.length,
        totalMoney: payment.order.totalMoney,
      });
    }
    return undefined;
  };
}
