import { MarketAccessory, MarketPill, MarketRow } from '@market/react';
import React from 'react';
import BuyerCard from 'routes/profile/models/BuyerCard';
import Chevron from 'svgs/market-icons/Chevron';
import { theme } from 'tailwind.config';
import { useHistory } from 'react-router-dom';
import BrandedCardIcon from 'components/profile/BrandedCardIcon';
import { useTranslation } from 'react-i18next';

type PaymentCardRowProps = {
  paymentCard: BuyerCard;
  isDefault: boolean;
  hasMultipleCards: boolean;
};

const PaymentCardRow: React.FC<PaymentCardRowProps> = (props) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <MarketRow
      role={'option'}
      onMarketRowSelected={() => {
        history.push(`/paymentmethods/${props.paymentCard.cardsApiId}`);
      }}
    >
      <div className={'flex items-center'}>
        <MarketAccessory size={'image'} slot={'icon'}>
          <BrandedCardIcon brand={props.paymentCard.brand} />
        </MarketAccessory>
        <div className={'ml-4'}>
          <div className={'font-medium'}>{props.paymentCard.displayName}</div>
          <div className={'paragraph-20 text-grey'}>
            {t('common.exp')}. {props.paymentCard.expMonth}/
            {props.paymentCard.expYear}
          </div>
        </div>
        <div className={'ml-auto flex items-center'}>
          {props.isDefault && props.hasMultipleCards && (
            <MarketPill className={'mr-4'}>{t('common.default')}</MarketPill>
          )}
          <Chevron fill={theme.extend.colors.lightgrey} direction={'right'} />
        </div>
      </div>
    </MarketRow>
  );
};

export default PaymentCardRow;
