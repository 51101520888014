import { Card as RpcBuyerCard } from 'rpc/model/squareup/buyerportal/cards/data';
import { Identifier, IdentifierType } from './Identifier';
import { getHumanFriendlyCardBrand } from 'utils/cardBrand';
import visaIcon from 'images/card-brands/visa.png';
import mastercardIcon from 'images/card-brands/mastercard.png';
import amexIcon from 'images/card-brands/amex.jpg';

/**
 * Defines the domain model for a buyer card, aka a Chargeable Card.
 * Based off of https://prototype.sqprod.co/#/docs/squareup.buyerportal.cards.Card
 */
interface BuyerCardProps {
  /*
   * Used to interact with Cards API
   */
  cardsApiId: string;
  /**
   * Used for associating preferences to a card
   */
  preferenceCardId: string;
  brand: string;
  lastFour: string;
  expMonth: number;
  expYear: number;
  displayName: string;
  billingZip: string;
}

type BuyerCard = Readonly<BuyerCardProps>;

/**
 * Translates between buyerportal BuyerCards and buyerportal-fe's models.
 * Primarily property renames.
 *
 * Also includes one utility method to convert a BuyerCard to an Identifier
 * with type card, used when managing preferences for a buyer card.
 */
export const BuyerCardConverter = {
  fromRpcBuyerCards(rpcCards: readonly RpcBuyerCard[]): BuyerCard[] {
    return rpcCards.map(BuyerCardConverter.fromRpcBuyerCard);
  },
  fromRpcBuyerCard(rpcCard: RpcBuyerCard): BuyerCard {
    const {
      billingZip,
      cardId: cardsApiId,
      preferenceCardId,
      cardBrand: brandEnumValue,
      lastFour,
      expMonth,
      expYear,
    } = rpcCard;
    const brand = getHumanFriendlyCardBrand(brandEnumValue);
    const displayName = `${brand} ${lastFour}`;
    return {
      billingZip,
      brand,
      cardsApiId,
      expMonth,
      expYear,
      lastFour,
      preferenceCardId,
      displayName,
    };
  },
  toCardIdentifier(buyerCard: BuyerCard): Identifier {
    return {
      token: buyerCard.preferenceCardId,
      displayValue: buyerCard.displayName,
      identifierType: IdentifierType.Card,
    };
  },
  getBrandImageUrl(brand: string): string | null {
    switch (brand) {
      case 'Visa': {
        return visaIcon;
      }
      case 'MasterCard': {
        return mastercardIcon;
      }
      case 'American Express': {
        return amexIcon;
      }
    }

    return null;
  },
};

export default BuyerCard;
