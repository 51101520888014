// eslint-disable-next-line filenames/match-exported
import React, { useEffect } from 'react';
import { MarketDivider } from '@market/react';
import PrivacyFooter from 'components/SqPrivacyFooter';
import { useParams } from 'react-router-dom';
import ReceiptFAQ from './ReceiptFaq';
import UnlinkAction from './UnlinkAction';
import LoadingUnlinkReceipt from './LoadingUnlinkReceipt';
import Es2Tracker from 'services/tracking/tracker';
import { unauthenticatedNotYourReceiptViewAction } from 'services/tracking/events';
import { useFetchObfuscatedEmailReceiptQuery } from 'store/query/receipts';
import { LogoIcon } from 'svgs';
import { useTranslation } from 'react-i18next';
import GlobalToast from 'components/GlobalToast';

interface NotYourReceiptParams {
  receiptToken: string;
}

const NotYourReceipt: React.FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    Es2Tracker.track(unauthenticatedNotYourReceiptViewAction());
  }, []);

  const { receiptToken } = useParams<NotYourReceiptParams>();

  const {
    data: receiptData,
    isLoading: isEmailReceiptQueryLoading,
    isError: isEmailReceiptQueryFailed,
  } = useFetchObfuscatedEmailReceiptQuery(receiptToken);

  let component;
  if (isEmailReceiptQueryLoading) {
    component = <LoadingUnlinkReceipt />;
  } else if (isEmailReceiptQueryFailed) {
    component = <ReceiptFAQ />;
  } else {
    const { merchantName, obfuscatedEmail } = receiptData;

    component = (
      <>
        {obfuscatedEmail ? (
          <>
            <UnlinkAction
              receiptData={{
                merchantName,
                obfuscatedEmail,
                receiptToken,
              }}
            />
            <MarketDivider />
          </>
        ) : null}
        <ReceiptFAQ />
      </>
    );
  }

  return (
    <>
      <div className={'flex justify-center min-h-full grow'}>
        <GlobalToast />
        <div
          className={
            'flex flex-col justify-center py-[47px] px-[24px] w-[calc(100vw-48px)] sm:w-[440px]'
          }
        >
          <LogoIcon className={'text-[32px] mb-[12px]'} />
          <h2 className={'heading-30'}>
            {t('preference.routes.notYourReceipt.header')}
          </h2>
          {component}
        </div>
      </div>
      <PrivacyFooter />
    </>
  );
};

export default NotYourReceipt;
