// Based on the event schemas defined at https://docs.google.com/document/d/1dFs91wtBm_afiHsopKyVTUZH4RoYtTtjdh5dKmPfKjI/edit

// Human readable names for features
export enum FeatureName {
  MerchantOverviewPage = 'Square Profile Seller Page',
  LocationListModal = 'Square Profile Seller Page Location Modal',
  AccountManagementMenu = 'Square Profile Seller Page Account Management',
  LoyaltyStatusPage = 'Loyalty Status Page',
  // Add more predefined feature names as needed
}

// Source of truth: https://docs.google.com/spreadsheets/d/1n5PvCFwxjMvjOdHviRiVnqcG06cW0FOLVnjZy4ckWwg/edit#gid=0
export enum FeatureID {
  MerchantOverviewPage = 'de57bd68-cc9b-497b-aa90-f358705f290e',
  LocationListModal = 'cc10ea4e-560a-478f-9c13-c3c97c7adf98',
  AccountManagementMenu = '4664164c-1327-4050-8c39-a5c5e79e83c4',
  LoyaltyStatusPage = 'b594a5bb-7151-4a29-91bb-d4717caedba4',
  // Add more predefined feature IDs as needed
}

export enum EventName {
  VIEW_FEATURE = 'View Feature',
  CLICK_FEATURE = 'Click Feature',
  LOAD_FEATURE = 'Load Feature',
  CLICK_LINK = 'Click Link',
  HEARTBEAT = 'Heartbeat',
  SEARCH_QUERY = 'Search Query',
  ENCOUNTER_ERROR = 'Encounter Error',
  // Add other predefined feature names as needed
}

export enum ActionItem {
  CLICK_BUTTON = 'click_button',
  // Add other predefined action items as needed
}

export enum FeatureFormat {
  PAGE = 'page',
  MODULE = 'module',
  BLADE = 'blade',
  SIDEBAR = 'sidebar',
  MODAL = 'modal',
  ICON = 'icon',
  BANNER = 'banner',
  BUTTON = 'button',
  // Add other predefined feature formats as needed
}

// Base Event Interface
export interface BaseEvent {
  feature_name: string;
  feature_id: FeatureID;
  feature_format: FeatureFormat;
  feature_parent_id?: string | null;
  event_description: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  additional_parameters?: Record<string, any> | null;
}

export interface ViewFeatureEvent extends BaseEvent {
  is_default_view: boolean;
  feature_layout?: Record<string, boolean | string | number> | null;
}

export interface ClickFeatureEvent extends BaseEvent {
  action_item: string;
  sub_action_item?: string | null;
}

export type LoadFeatureEvent = BaseEvent;

export interface ClickLinkEvent extends BaseEvent {
  link_text: string;
  link_title: string;
  link_url: string;
}

export interface HeartbeatEvent {
  feature_name: string;
  feature_id: FeatureID;
  feature_format: FeatureFormat;
}

export interface SearchQueryEvent extends BaseEvent {
  query: string;
  query_id: string;
  suggestions?: Record<string, string> | null;
}

export interface EncounterErrorEvent extends BaseEvent {
  event_called: string;
  error_type: string;
  error_message?: string;
}

export type StandardizedEvent =
  | ViewFeatureEvent
  | ClickFeatureEvent
  | LoadFeatureEvent
  | ClickLinkEvent
  | HeartbeatEvent
  | SearchQueryEvent
  | EncounterErrorEvent;
