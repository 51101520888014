import React from 'react';
import SearchableLoadMoreList, {
  RowItemProps,
} from 'components/profile/SearchableLoadMoreList';
import {
  EmailPreference,
  MerchantEmailPreference,
  Product,
  Value,
} from 'routes/profile/models/EmailPreference';
import { useUpdateEmailPreferenceMutation } from 'store/query/api-extensions/receiptAndMarketingPreferences';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { openToast } from 'store/toastSlice';
import { buyerportalCdpClient } from 'services/tracking/cdp/clients/buyerportal';
import {
  actionSearchMerchants,
  actionToggleMarketing,
} from 'services/tracking/cdp/events/marketingPreferences';

type MerchantMarketingPrefsProps = {
  emailId: string;
  merchantPrefs: MerchantEmailPreference[];
  isLoading: boolean;
};

const MerchantMarketingPrefs: React.FC<MerchantMarketingPrefsProps> = ({
  emailId,
  merchantPrefs,
  isLoading,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [updateEmailPreference] = useUpdateEmailPreferenceMutation();

  const data: RowItemProps[] = merchantPrefs.map((mPref) => {
    const { imageUrl, displayName, id } = mPref.merchantInfo;
    return {
      id,
      displayName,
      imageUrl,
      enabled: mPref.marketingPreference.value === Value.OptIn,
    };
  });

  return (
    <SearchableLoadMoreList
      isLoading={isLoading}
      rowModels={data}
      onSearch={() => {
        buyerportalCdpClient.track(
          'Search Merchants',
          actionSearchMerchants(emailId)
        );
      }}
      onRowToggle={(isEnabled: boolean, id: string) =>
        updateEmailPreference({
          merchantId: id,
          emailId: emailId!,
          preference: new EmailPreference({
            product: Product.Marketing,
            value: isEnabled ? Value.OptIn : Value.OptOut,
          }),
        })
          .unwrap()
          .then(() => {
            buyerportalCdpClient.track(
              'Toggle Marketing',
              actionToggleMarketing(emailId!, isEnabled, id)
            );
          })
          .catch(() => {
            dispatch(
              openToast({
                variant: 'critical',
                message: t('common.somethingWentWrong.retryable.direct'),
              })
            );
          })
      }
    />
  );
};

export default MerchantMarketingPrefs;
