import { SignInIdentifierType } from 'routes/native-sign-in/types';
import { EVENT_NAME_ACTION, EVENT_NAME_VIEW } from '.';
import { IdentifierType } from 'routes/profile/models/Identifier';

const EVENT_GROUP_SIGN_IN = 'Sign In';

const getEventValue = (identifierType: SignInIdentifierType) => {
  switch (identifierType) {
    case IdentifierType.Email: {
      return 'Email';
    }
    case IdentifierType.Phone: {
      return 'Phone';
    }
    default: {
      return 'Unknown';
    }
  }
};

export const signInViewEvent = (identifierType: SignInIdentifierType) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SIGN_IN,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: `Request Initial Verification: ${getEventValue(
      identifierType
    )}`,
  };
};

export const signInSwitchIdentifierActionEvent = (
  identifierType: SignInIdentifierType
) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SIGN_IN,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: `Use: ${getEventValue(identifierType)}`,
  };
};

export const signInRequestVerifyActionEvent = (
  identifierType: SignInIdentifierType
) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SIGN_IN,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: `Request Initial Verification: ${getEventValue(
      identifierType
    )}`,
  };
};

export const signInVerifyCodeViewEvent = (
  identifierType: SignInIdentifierType
) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SIGN_IN,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: `Enter Initial Verification: ${getEventValue(
      identifierType
    )}`,
  };
};

export const signInVerifyCodeActionEvent = (
  identifierType: SignInIdentifierType
) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SIGN_IN,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: `Enter Initial Verification: ${getEventValue(
      identifierType
    )}`,
  };
};

export const signInCollectNameViewEvent = () => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SIGN_IN,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Name entry',
  };
};

export const signInCollectNameActionEvent = () => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SIGN_IN,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Name submission',
  };
};

export const signInIsThisYourProfileViewEvent = () => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SIGN_IN,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Request Verification: Associated Phone',
  };
};

export const signInIsThisYourProfileAcceptActionEvent = () => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SIGN_IN,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Request Verification: Associated Phone',
  };
};

export const signInIsThisYourProfileDeclineActionEvent = () => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SIGN_IN,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Decline Verification: Associated Phone',
  };
};

export const signInIdentifierClaimedViewEvent = () => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SIGN_IN,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Identifier Claimed',
  };
};

export const signInIdentifierClaimedSignInActionEvent = () => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SIGN_IN,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Identifier Claimed: Redirect to Sign In',
  };
};

export const signInIdentifierClaimedAddIdentifierActionEvent = () => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SIGN_IN,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value:
      'Identifier Claimed: Add Different Identifier',
  };
};

export const signInAddIdentifierViewEvent = (
  identifierType: SignInIdentifierType
) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SIGN_IN,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: `Request Verification: ${getEventValue(
      identifierType
    )}`,
  };
};

export const signInRequestAddIdentifierActionEvent = (
  identifierType: SignInIdentifierType
) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SIGN_IN,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: `Request Verification: ${getEventValue(
      identifierType
    )}`,
  };
};

export const signInCreateProfileActionEvent = () => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SIGN_IN,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Create Profile: unverified email',
  };
};

export const signInVerifyAddIdentifierViewEvent = (
  identifierType: SignInIdentifierType
) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SIGN_IN,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: `Enter Verification: ${getEventValue(
      identifierType
    )}`,
  };
};

export const signInVerifyAddIdentifierActionEvent = (
  identifierType: SignInIdentifierType
) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SIGN_IN,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: `Enter Verification: ${getEventValue(
      identifierType
    )}`,
  };
};

export const signInSkipProfileRepair = (
  identifierType: SignInIdentifierType
) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SIGN_IN,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: `Skip Profile Repair: ${getEventValue(
      identifierType
    )}`,
  };
};
