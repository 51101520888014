import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MarketField,
  MarketInputText,
  MarketList,
  MarketRow,
  MarketSelect,
} from '@market/react';
import {
  ContactInformationIdentifierType,
  IdentifierType,
} from 'routes/profile/models/Identifier';
import { formatPhoneNational } from 'utils/identifiers';

export const MAX_EMAIL_LENGTH = 254;

type IdentifierValueInputProps = {
  disabled?: boolean;
  enableTypeSelection?: boolean;
  identifierType: ContactInformationIdentifierType;
  identifierValue: string;
  onEnterPressed?: Function;
  onIdentifierTypeChanged?: (
    identifierType: ContactInformationIdentifierType
  ) => void;
  onIdentifierValueChanged: (identifierValue: string) => void;
  isInvalid?: boolean;
  phoneInputDataTestId?: string;
  emailInputDataTestId?: string;
  placeholder?: string;
};

const IdentifierValueInput: React.FC<IdentifierValueInputProps> = (props) => {
  const { t } = useTranslation(undefined, { useSuspense: false });

  const {
    disabled,
    enableTypeSelection,
    identifierType,
    identifierValue,
    onEnterPressed,
    onIdentifierTypeChanged,
    onIdentifierValueChanged,
    isInvalid,
    phoneInputDataTestId,
    emailInputDataTestId,
    placeholder,
  } = props;
  useEffect(() => {
    if (enableTypeSelection) {
      onIdentifierValueChanged('');
    }
    // TODO(@achautime): fix the following dep list
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identifierType, enableTypeSelection]);

  const onKeyUpCapturedEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (onEnterPressed && e.key === 'Enter') {
      onEnterPressed();
    }
  };

  const renderInput = () => {
    switch (identifierType) {
      case IdentifierType.Phone: {
        return (
          <MarketField
            key={'phone'}
            disabled={disabled}
            invalid={isInvalid}
            className={'mb-6'}
          >
            <MarketInputText value={identifierValue}>
              <label>{t('common.phoneNumber')}</label>
              <input
                slot={'input'}
                placeholder={placeholder}
                data-testid={phoneInputDataTestId}
                type={'tel'}
                autoComplete={'tel'}
                inputMode={'tel'}
                autoCapitalize={'off'}
                // can't move this to MarketInputText because Market cannot handle the autoformatting
                // (mraasch) ...but it seems you can have it on both
                value={identifierValue}
                {...(onEnterPressed && {
                  onKeyUpCapture: onKeyUpCapturedEnter,
                })}
                onChange={(e) => {
                  const newRawValue = e.target.value;
                  const numericValue = newRawValue.replace(/\D/g, '');
                  onIdentifierValueChanged(formatPhoneNational(numericValue));
                }}
              />
            </MarketInputText>
            <small slot={'error'}>{t('common.message.validation.phone')}</small>
          </MarketField>
        );
      }
      case IdentifierType.Email: {
        return (
          <MarketField
            key={'email'}
            disabled={disabled}
            invalid={isInvalid}
            className={'mb-6'}
          >
            <MarketInputText value={identifierValue} placeholder={placeholder}>
              <label>{t('common.emailAddress')}</label>
              <input
                slot={'input'}
                placeholder={placeholder}
                data-testid={emailInputDataTestId}
                type={'email'}
                autoComplete={'email'}
                inputMode={'email'}
                maxLength={MAX_EMAIL_LENGTH}
                autoCapitalize={'off'}
                value={identifierValue}
                {...(onEnterPressed && {
                  onKeyUpCapture: onKeyUpCapturedEnter,
                })}
                onChange={(e) => {
                  onIdentifierValueChanged(e.target.value);
                }}
              />
            </MarketInputText>
            <small slot={'error'}>{t('common.message.validation.email')}</small>
          </MarketField>
        );
      }
    }
  };

  return (
    <>
      {enableTypeSelection && (
        <MarketSelect
          className={'mb-4'}
          onMarketSelectValueDidChange={(e) => {
            if (onIdentifierTypeChanged) {
              onIdentifierTypeChanged(
                e.target.value as ContactInformationIdentifierType
              );
            }
          }}
          value={identifierType}
        >
          <label>{t('common.type')}</label>
          <MarketList slot={'list'}>
            <MarketRow value={IdentifierType.Phone}>
              {t('common.phoneNumber')}
            </MarketRow>
            <MarketRow value={IdentifierType.Email}>
              {t('common.emailAddress')}
            </MarketRow>
          </MarketList>
        </MarketSelect>
      )}
      {renderInput()}
    </>
  );
};

export default IdentifierValueInput;
