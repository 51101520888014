import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import GlobalModal from 'components/modals/GlobalModal';
import GlobalToast from 'components/GlobalToast';
import PrivacyFooter from 'components/SqPrivacyFooter';
import SideNav from 'routes/profile/nav/SideNav';
import Header from './layout/Header';
import PaymentCardDetails from './payment-methods/PaymentCards/Details';
import ReceiptSettings from './receipt-settings';
import MarketingPreferences from './marketing-preferences';
import GiftCardDetails from './payment-methods/GiftCards/Details';
import AddGiftCardRedirect from './payment-methods/GiftCards/AddGiftCardRedirect';
import Account from './account';
import PaymentMethods from './payment-methods';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { selectBoolFlag } from 'store/featureSlice';
import { BoolFlag } from './models/Flags';
import GlobalLoader from './common/loading/GlobalLoader';
import PurchaseHistory from './purchase-history';
import ReceiptDetails from './purchase-history/ReceiptDetails';
import { selectIdentifierCollection } from 'store/buyerSlice';
import { IdentifierType } from './models/Identifier';

// don't change this - GC website is generating URLs to this
export const GIFT_CARD_DETAIL_URL_BASE = '/profile/gift-card';

const Profile: React.FC = () => {
  const areAnonymousFlagsLoaded = useSelector(
    (state: AppState) => state.feature.areAnonymousFlagsLoaded
  );

  const useNewGiftCardFlow = useSelector((state: AppState) =>
    selectBoolFlag(state, BoolFlag.useNewGiftCardFlow)
  );
  const emails = useSelector((state: AppState) =>
    selectIdentifierCollection(state, IdentifierType.Email)
  );
  const showReceipts = useSelector((state: AppState) => {
    return (
      selectBoolFlag(state, BoolFlag.showReceipts) ||
      emails.find(
        (email) =>
          email.displayValue.endsWith('@squareup.com') ||
          email.displayValue.endsWith('@block.xyz')
      )
    );
  });

  if (!areAnonymousFlagsLoaded) {
    return <GlobalLoader />;
  }

  return (
    <div className={'flex flex-col h-screen'}>
      <Header />
      <div className={'md:flex grow'}>
        <GlobalModal />
        <GlobalToast />
        <div
          className={
            'hidden md:block flex-none w-64 px-4 py-6 shadow-rightDivider'
          }
        >
          <SideNav showSignOut={false} />
        </div>
        <Switch>
          <Route exact path={'/account'} component={Account} />
          {showReceipts && (
            <Route
              exact
              path={'/purchasehistory'}
              component={PurchaseHistory}
            />
          )}
          {showReceipts && (
            <Route
              exact
              path={'/purchasehistory/:paymentId'}
              component={ReceiptDetails}
            />
          )}
          <Route exact path={'/paymentmethods'} component={PaymentMethods} />
          <Route
            path={'/paymentmethods/giftcard/:giftCardToken'}
            component={GiftCardDetails}
          />
          {useNewGiftCardFlow && (
            <Route
              exact
              path={`${GIFT_CARD_DETAIL_URL_BASE}/add`}
              component={AddGiftCardRedirect}
            />
          )}
          <Route
            path={'/paymentmethods/:paymentCardId'}
            component={PaymentCardDetails}
          />
          <Route path={'/receiptsettings'} component={ReceiptSettings} />
          <Route
            path={'/marketingpreferences'}
            component={MarketingPreferences}
          />
          <Route path={'/'}>
            <Redirect to={'/account'} />
          </Route>
        </Switch>
      </div>
      <PrivacyFooter />
    </div>
  );
};

export default Profile;
