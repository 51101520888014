import React, { useMemo, useState } from 'react';
import {
  MarketRow,
  MarketList,
  MarketAccessory,
  MarketToggle,
  MarketButton,
  MarketInputSearch,
  MarketActivityIndicator,
} from '@market/react';
import useDebouncedValue from 'utils/custom-react-hooks/useDebouncedValue';
import MarketRowImage from './MarketRowImage';
import { useTranslation } from 'react-i18next';
import { SEARCH_DEBOUNCE_VALUE } from 'constants';

export type RowItemProps = {
  id: string;
  displayName: string;
  enabled: boolean;
  imageUrl?: string;
};

export type SearchableLoadMoreListProps = {
  rowModels: RowItemProps[];
  itemsPerBatch?: number;
  initialMaxItems?: number;
  onRowToggle: (isEnabled: boolean, id: string) => any;
  onSearch?: (query: string) => void;
  isLoading: boolean;
};

const DEFAULT_INITIAL_ITEMS = 20;
const DEFAULT_BATCH_SIZE = 20;

const SearchableLoadMoreList: React.FC<SearchableLoadMoreListProps> = ({
  rowModels,
  itemsPerBatch = DEFAULT_BATCH_SIZE,
  initialMaxItems = DEFAULT_INITIAL_ITEMS,
  onRowToggle,
  onSearch,
  isLoading,
}) => {
  const { t } = useTranslation();

  const [endIndex, setEndIndex] = useState<number>(initialMaxItems);
  const [searchText, setSearchText] = useState<string>('');
  const debouncedSearchText = useDebouncedValue(
    searchText,
    SEARCH_DEBOUNCE_VALUE
  );

  const handleLoadMoreClick = () => {
    setEndIndex(endIndex + itemsPerBatch);
  };

  const handleMarketInputSearchValueChange = (e) => {
    // reset the end index to the default
    setEndIndex(DEFAULT_INITIAL_ITEMS);
    const { current } = e.detail;
    setSearchText(current);
  };

  const rowsMatchingSearchText = useMemo(() => {
    if (onSearch && debouncedSearchText.length > 0) {
      onSearch(debouncedSearchText.toLocaleLowerCase());
    }

    return rowModels.filter((item) =>
      item.displayName
        .toLocaleLowerCase()
        .includes(debouncedSearchText.toLocaleLowerCase())
    );
    // don't listen to onSearch
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowModels, debouncedSearchText]);

  const hasMoreItems = rowsMatchingSearchText.length > endIndex;

  const rows = rowsMatchingSearchText.slice(0, endIndex).map((item) => {
    return (
      <AsyncTogglableRow key={item.id} {...item} onRowToggle={onRowToggle} />
    );
  });

  if (isLoading) {
    return (
      <div className="flex justify-center mt-4">
        <MarketActivityIndicator size="small" />
      </div>
    );
  }

  return (
    <>
      <MarketInputSearch
        onMarketInputSearchValueChange={handleMarketInputSearchValueChange}
        placeholder={t('common.search')}
      />
      <MarketList interactive multiselect>
        {rows}
      </MarketList>
      {hasMoreItems && (
        <MarketButton onClick={handleLoadMoreClick}>
          {t('common.loadMore')}
        </MarketButton>
      )}
    </>
  );
};

type RowToggleCallback = (enabled: boolean, id: string) => Promise<any>;

const AsyncTogglableRow: React.FC<
  RowItemProps & { onRowToggle: RowToggleCallback }
> = ({ id, imageUrl, displayName, enabled, onRowToggle }) => {
  const [isPending, setPending] = useState<boolean>(false);

  return (
    <MarketRow
      onMarketRowSelected={async (_) => {
        setPending(true);
        try {
          await onRowToggle(true, id);
        } finally {
          setPending(false);
        }
      }}
      onMarketRowDeselected={async (_) => {
        setPending(true);
        try {
          await onRowToggle(false, id);
        } finally {
          setPending(false);
        }
      }}
      selected={enabled}
      disabled={isPending}
    >
      <MarketAccessory size="image" slot="leading-accessory">
        <MarketRowImage imageUrl={imageUrl} placeholder={displayName} />
      </MarketAccessory>
      <label slot="label">{displayName}</label>
      <MarketToggle slot="control" />
    </MarketRow>
  );
};

export default SearchableLoadMoreList;
