import { MarketToast } from '@market/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

type ErrorToastSlotProps = {
  errored: boolean;
  clearError: Function;
  errorMessage?: string;
};

// deprecated - please don't use
const ErrorToastSlot: React.FC<ErrorToastSlotProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      {props.errored && (
        <div className="absolute bottom-7 left-0 p-6">
          <MarketToast
            variant={'critical'}
            onMarketToastManuallyDismissed={() => props.clearError()}
            onMarketToastAutoDismissed={() => props.clearError()}
          >
            {props.errorMessage ||
              t('common.somethingWentWrong.retryable.apologetic')}
          </MarketToast>
        </div>
      )}
    </>
  );
};

export default ErrorToastSlot;
