import React from 'react';

const PageNotFound = () => {
  return (
    <div className={'flex justify-center items-center h-screen'}>
      <p className={'text-center'}>
        The page you are looking for can't be found.
      </p>
    </div>
  );
};

export default PageNotFound;
