import dayjs from 'dayjs';
import i18n from 'i18n';
import duration from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';

// Import supported locales
// https://shuttle.squareup.com/projects/buyerportal-fe/edit
import 'dayjs/locale/ca';
import 'dayjs/locale/en-au';
import 'dayjs/locale/en-ca';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/en-ie';
import 'dayjs/locale/es';
import 'dayjs/locale/es-us';
import 'dayjs/locale/fr';
import 'dayjs/locale/fr-ca';
import 'dayjs/locale/ja';

dayjs.extend(duration);
dayjs.extend(localizedFormat);

// Export for parsing
export { Dayjs, default as dayjs } from 'dayjs';

export enum DateFormat {
  // Localized formats
  // https://day.js.org/docs/en/display/format#list-of-localized-formats
  LT = 'LT', // h:mm A
  LTS = 'LTS', // h:mm:ss A
  L = 'L', // MM/DD/YYYY
  LL = 'LL', // MMMM D, YYYY
  LLL = 'LLL', // MMMM D, YYYY h:mm A
  LLLL = 'LLLL', // dddd, MMMM D, YYYY h:mm A
  l = 'l', // M/D/YYYY
  ll = 'll', // MMM D, YYYY
  lll = 'lll', // MMM D, YYYY h:mm A
  llll = 'llll', // ddd, MMM D, YYYY h:mm A

  MonthDayCompact = 'MM/DD',
  MonthDayShort = 'MMM D',
  MonthDayFull = 'MMMM D',
}

export function formatDate(date: Date, format: string) {
  return dayjs(date).format(format);
}

function setLocaleGlobal(locale: string) {
  dayjs.locale(locale);
}

(function bindToI18nLanguage() {
  i18n.on('initialized', () => {
    setLocaleGlobal(i18n.language);
  });

  i18n.on('languageChanged', () => {
    setLocaleGlobal(i18n.language);
  });
})();
