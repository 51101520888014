// eslint-disable-next-line filenames/match-exported
import React, { useMemo } from 'react';
import { MarketDivider } from '@market/react';
import PaymentCards from './PaymentCards';
import GiftCards from './GiftCards';
import {
  useListCardsQuery,
  useListGiftCardsQuery,
  useRetrieveSuggestedProfileIdentifiersQuery,
} from 'store/query';
import ModuleLoadFailed from '../common/errors/ModuleLoadFailed';
import ModuleLoading from '../common/loading/ModuleLoading';
import { IdentifierType } from 'routes/profile/models/Identifier';
import { PAGE_SIZE } from 'routes/profile/models/GiftCard';
import { LogoIcon } from 'svgs';
import Page from '../layout/Page';
import useCdpPageTracking from 'utils/custom-react-hooks/useCdpPageTracking';
import { viewPaymentMethodsProperties } from 'services/tracking/cdp/events/paymentMethods';
import { useTranslation } from 'react-i18next';

const PaymentMethods: React.FC = () => {
  const { t } = useTranslation();

  const {
    data: paymentCards,
    isLoading: isPaymentCardsLoading,
    isError: didPaymentCardsLoadingFail,
  } = useListCardsQuery();

  const {
    data: unverifiedIdentifiers,
    isLoading: isUnverifiedIdentifiersLoading,
    isError: didUnverifiedIdentifiersLoadingFail,
  } = useRetrieveSuggestedProfileIdentifiersQuery();

  const {
    data: giftCards,
    isLoading: isGiftCardsLoading,
    isError: didGiftCardsLoadingFail,
  } = useListGiftCardsQuery(PAGE_SIZE);

  const unverifiedCards = useMemo(() => {
    return (
      unverifiedIdentifiers?.filter(
        (identifier) => identifier.identifierType === IdentifierType.Card
      ) || []
    );
  }, [unverifiedIdentifiers]);

  const isLoading =
    isPaymentCardsLoading ||
    isGiftCardsLoading ||
    isUnverifiedIdentifiersLoading;

  const didLoadingFail =
    didPaymentCardsLoadingFail ||
    didGiftCardsLoadingFail ||
    didUnverifiedIdentifiersLoadingFail;

  useCdpPageTracking({
    properties: viewPaymentMethodsProperties(
      paymentCards?.length || 0,
      giftCards?.length || 0,
      unverifiedCards?.length || 0
    ),
    isLoading,
    didLoadingFail,
  });

  if (isLoading) {
    return <ModuleLoading />;
  }

  if (didLoadingFail) {
    return <ModuleLoadFailed />;
  }

  return (
    <Page>
      <h2 className={'heading-30'}>{t('profile.paymentMethods.title')}</h2>
      <MarketDivider className={'my-8'} />
      <PaymentCards
        paymentCards={paymentCards!}
        unverifiedCards={unverifiedCards}
      />
      <MarketDivider className={'my-8'} />
      <GiftCards giftCards={giftCards!} />
      <div className={'flex items-center'}>
        <LogoIcon className={'p-3 pl-0'} height={20} width={20} />
        <span className={'text-base font-semibold'}>
          {t('profile.paymentMethods.squarePay')}
        </span>
      </div>
    </Page>
  );
};

export default PaymentMethods;
