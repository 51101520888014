/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import '../../customers/request';
import '../profile/common';
import '../common/data';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      buyerportal: {
        nested: {
          cashlink: {
            nested: {
              RetrieveCashLinkInfoRequest: {
                fields: {
                  cashLinkToken: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                },
              },
              RetrieveCashLinkInfoResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                  cashLinkInfo: {
                    type: 'squareup.buyerportal.cashlink.CashLinkInfo',
                    id: 3,
                    rule: 'optional',
                  },
                },
              },
              CashLinkInfo: {
                fields: {
                  deliveryIdentifier: {
                    type: 'squareup.buyerportal.profile.Identifier',
                    id: 1,
                    rule: 'optional',
                  },
                  linkedIdentifier: {
                    type: 'squareup.buyerportal.profile.Identifier',
                    id: 2,
                    rule: 'optional',
                  },
                  merchantInfo: {
                    type: 'squareup.buyerportal.common.MerchantInfo',
                    id: 3,
                    rule: 'optional',
                  },
                  redirectUrl: {
                    type: 'string',
                    id: 4,
                    rule: 'optional',
                  },
                },
              },
              LinkWithCashRequest: {
                fields: {
                  cashLinkToken: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  code: {
                    type: 'string',
                    id: 4,
                    rule: 'optional',
                  },
                  collectedEmail: {
                    type: 'string',
                    id: 5,
                    rule: 'optional',
                  },
                  phoneId: {
                    type: 'string',
                    id: 2,
                  },
                  phoneNumber: {
                    type: 'string',
                    id: 3,
                  },
                },
                nested: {
                  verification_credential: {
                    oneof: ['phoneId', 'phoneNumber'],
                  },
                },
              },
              LinkWithCashResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                  redirectUrl: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                },
              },
              RetrieveCashLinkRedirectUrlRequest: {
                fields: {
                  cashLinkToken: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                },
              },
              RetrieveCashLinkRedirectUrlResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                  redirectUrl: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  qrCodeImageUrl: {
                    type: 'string',
                    id: 4,
                    rule: 'optional',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.cashlink?.nested
    ?.RetrieveCashLinkInfoRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.cashlink?.nested
    ?.RetrieveCashLinkInfoResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.cashlink?.nested
    ?.CashLinkInfo &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.cashlink?.nested
    ?.LinkWithCashRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.cashlink?.nested
    ?.LinkWithCashResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.cashlink?.nested
    ?.RetrieveCashLinkRedirectUrlRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.cashlink?.nested
    ?.RetrieveCashLinkRedirectUrlResponse
) {
  $root.addJSON(jsonData);
}

/**
 * --
 * @desc Represents the request to retrieve the info of the cash link given the token.
 * --
 *
 * SOURCE RetrieveCashLinkInfoRequest @ squareup/buyerportal/cashlink/data.proto at 17:1
 */
export const RetrieveCashLinkInfoRequest = $root.lookupType(
  'squareup.buyerportal.cashlink.RetrieveCashLinkInfoRequest'
);
fixType(RetrieveCashLinkInfoRequest, {
  cashLinkToken: { required: true },
});
Builder.createAndAttachToType(RetrieveCashLinkInfoRequest);
/**
 * --
 * @desc Represents the response to retrieve the info of the cash link given the token.
 * --
 *
 * SOURCE RetrieveCashLinkInfoResponse @ squareup/buyerportal/cashlink/data.proto at 27:1
 */
export const RetrieveCashLinkInfoResponse = $root.lookupType(
  'squareup.buyerportal.cashlink.RetrieveCashLinkInfoResponse'
);
fixType(RetrieveCashLinkInfoResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(RetrieveCashLinkInfoResponse);
/**
 * SOURCE CashLinkInfo @ squareup/buyerportal/cashlink/data.proto at 36:1
 */
export const CashLinkInfo = $root.lookupType(
  'squareup.buyerportal.cashlink.CashLinkInfo'
);
fixType(CashLinkInfo);
Builder.createAndAttachToType(CashLinkInfo);
/**
 * --
 * @desc Represents the request to link the buyer account to cash.
 * --
 *
 * SOURCE LinkWithCashRequest @ squareup/buyerportal/cashlink/data.proto at 61:1
 */
export const LinkWithCashRequest = $root.lookupType(
  'squareup.buyerportal.cashlink.LinkWithCashRequest'
);
fixType(LinkWithCashRequest, {
  cashLinkToken: { required: true },
});
Builder.createAndAttachToType(LinkWithCashRequest);
/**
 * --
 * @desc Represents the response to a request to link the buyer account to cash
 * --
 *
 * SOURCE LinkWithCashResponse @ squareup/buyerportal/cashlink/data.proto at 91:1
 */
export const LinkWithCashResponse = $root.lookupType(
  'squareup.buyerportal.cashlink.LinkWithCashResponse'
);
fixType(LinkWithCashResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(LinkWithCashResponse);
/**
 * --
 * @desc Represents the request to link a cash coupon and buyerportal.
 * --
 *
 * SOURCE RetrieveCashLinkRedirectUrlRequest @ squareup/buyerportal/cashlink/data.proto at 106:1
 */
export const RetrieveCashLinkRedirectUrlRequest = $root.lookupType(
  'squareup.buyerportal.cashlink.RetrieveCashLinkRedirectUrlRequest'
);
fixType(RetrieveCashLinkRedirectUrlRequest, {
  cashLinkToken: { required: true },
});
Builder.createAndAttachToType(RetrieveCashLinkRedirectUrlRequest);
/**
 * --
 * @desc Represents the response to a request to link a cash coupon and buyerportal.
 * --
 *
 * SOURCE RetrieveCashLinkRedirectUrlResponse @ squareup/buyerportal/cashlink/data.proto at 116:1
 */
export const RetrieveCashLinkRedirectUrlResponse = $root.lookupType(
  'squareup.buyerportal.cashlink.RetrieveCashLinkRedirectUrlResponse'
);
fixType(RetrieveCashLinkRedirectUrlResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(RetrieveCashLinkRedirectUrlResponse);
