import React from 'react';
import {
  MarketContentCard,
  MarketAccordionItem,
  MarketRow,
  MarketList,
  MarketDivider,
  MarketPill,
} from '@market/react';
import { Tier } from 'routes/merchant-scoped-portal/integrations/loyalty/models/Tier';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';

interface TierDetailsCardProps {
  tier: Tier;
  isCollapsible?: boolean;
  isCurrentTier?: boolean;
}

export function TierDetailsWrapper(
  isCollapsible: boolean,
  tierName: string,
  tierId: string,
  isCurrentTier: boolean,
  children: React.ReactNode
): React.ReactNode {
  return isCollapsible && !isCurrentTier ? (
    <MarketAccordionItem name={tierId} size="medium" expanded={isCurrentTier}>
      <span slot="label">{tierName}</span>
      {children}
    </MarketAccordionItem>
  ) : (
    <>
      <div className="flex justify-between items-center">
        <h3>{tierName}</h3>
        {isCurrentTier && (
          <MarketPill variant="emphasis">
            {i18n.t('loyalty.tiers.currentTier')}
          </MarketPill>
        )}
      </div>
      {isCurrentTier && <MarketDivider size="thin" className="m-0" />}
      {children}
    </>
  );
}

const TierDetailsCard: React.FC<TierDetailsCardProps> = ({
  tier,
  isCollapsible = true,
  isCurrentTier = false,
}) => {
  const { t } = useTranslation();

  return (
    <MarketContentCard className="py-0 mb-4 not:last-child:mb-0">
      {TierDetailsWrapper(
        isCollapsible,
        tier.name,
        tier.uid,
        isCurrentTier,
        <MarketList>
          <MarketRow>
            <label slot="label">{t('loyalty.tiers.requirement.label')}</label>
            <p slot="subtext">{tier.formattedRequirement}</p>
          </MarketRow>
          <MarketRow>
            <label slot="label">{t('loyalty.tiers.benefits.label')}</label>
            <div slot="subtext">
              {tier.formattedBenefits.length > 1 ? (
                <ul className="m-0 pl-4">
                  {tier.formattedBenefits.map((benefit, index) => (
                    <li key={`tier-benefit-${index}`}>{benefit}</li>
                  ))}
                </ul>
              ) : (
                tier.formattedBenefits[0]
              )}
            </div>
          </MarketRow>
        </MarketList>
      )}
    </MarketContentCard>
  );
};

export default TierDetailsCard;
