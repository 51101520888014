import React, { PropsWithChildren } from 'react';

type MarketTabProps = {
  id: string;
  onMarketTabSelected: (tabId: string) => void;
  selected: boolean;
};

const MarketTab: React.FC<PropsWithChildren<MarketTabProps>> = ({
  children,
  id,
  selected,
  onMarketTabSelected,
}) => {
  return (
    <button
      key={id}
      onClick={() => onMarketTabSelected(id)}
      className={`${
        selected ? 'font-bold shadow-selectedBottomDivider' : 'text-grey'
      } heading-20 pb-2 pt-1 mr-2 border-none bg-transparent cursor-pointer`}
    >
      {children}
    </button>
  );
};

export default MarketTab;
