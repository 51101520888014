import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import LegacyClaimPointsPageRedirect from './LegacyClaimPointsPageRedirect';
import { useSelector } from 'react-redux';
import { selectBoolFlag } from 'store/featureSlice';
import { AnonymousBoolFlag } from 'routes/profile/models/Flags';
import { AppState } from 'store';
import LegacyProfilePageRedirect from './LegacyProfilePageRedirect';
import LegacyMerchantPageRedirect from './LegacyMerchantPageRedirect';

const LegacyLoyaltyUrlRedirects = () => {
  const isMerchantPortalEnabled = useSelector((state: AppState) =>
    selectBoolFlag(state, AnonymousBoolFlag.enableMerchantPortal)
  );

  if (!isMerchantPortalEnabled) {
    return <Redirect to="/" />;
  }

  return (
    <Switch>
      <Route
        path={[
          '/loyalty/claim/:claimablePointsToken',
          '/loyalty-stars/:claimablePointsToken',
        ]}
        component={LegacyClaimPointsPageRedirect}
      />
      <Route
        path={[
          '/loyalty/profile/:loyaltyAccountLookupToken',
          '/loyalty-status/:loyaltyAccountLookupToken',
          '/outreach/welcome/:loyaltyAccountLookupToken',
        ]}
        component={LegacyProfilePageRedirect}
      />
      <Route
        path="/loyalty/:merchantId"
        component={LegacyMerchantPageRedirect}
      />
      <Redirect to="/" />
    </Switch>
  );
};

export default LegacyLoyaltyUrlRedirects;
