import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MarketButton,
  MarketField,
  MarketInputText,
  MarketLink,
} from '@market/react';
import Es2Tracker from 'services/tracking/tracker';
import BackButton from 'components/BackButton';
import {
  createAccountlessPrivacyRequest,
  executeAccountlessPrivacyRequest,
} from 'services/buyerportal';
import PrivacyRequestType from '../enums/PrivacyRequestType';
import { IdentifierType } from 'routes/profile/models/Identifier';
import ErrorToastSlot from 'components/ErrorToastSlot';
import { RequestStatus } from 'rpc/model/squareup/customers/request';
import {
  privacyRequestsVerifyDeleteActionEvent,
  privacyRequestsVerifyDeleteViewEvent,
  privacyRequestsVerifyDeleteAccountExistsViewEvent,
  privacyRequestsVerifyDeleteInvalidActionEvent,
} from 'services/tracking/events/privacyRequests';
import nonTranslatableStrings from 'utils/nonTranslatableStrings';

type VerifyDeleteDataProps = {
  identifierType: IdentifierType;
  identifierValue: string;
  isExistingAccount: boolean;
  onClickBack: () => void;
  onClickNext: () => void;
};

const VerifyDeleteData: React.FC<VerifyDeleteDataProps> = (props) => {
  const CODE_LENGTH = 6;

  const { t } = useTranslation(undefined, { useSuspense: false });

  const [code, setCode] = useState<string>('');
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isUnexpectedError, setIsUnexpectedError] = useState<boolean>(false);
  const [isInvalidCode, setIsInvalidCode] = useState<boolean>(false);

  useEffect(() => {
    Es2Tracker.track(privacyRequestsVerifyDeleteViewEvent());

    if (props.isExistingAccount) {
      Es2Tracker.track(
        privacyRequestsVerifyDeleteAccountExistsViewEvent(props.identifierType)
      );
    }
  }, [props.isExistingAccount, props.identifierType]);

  const triggerCodeVerification = async () => {
    try {
      setIsProcessing(true);
      setCode('');

      await createAccountlessPrivacyRequest(
        props.identifierType,
        props.identifierValue,
        PrivacyRequestType.Delete
      );
    } catch {
      setIsUnexpectedError(true);
    } finally {
      setIsProcessing(false);
    }
  };

  const executeRequest = async () => {
    try {
      setIsProcessing(true);
      Es2Tracker.track(
        privacyRequestsVerifyDeleteActionEvent(props.identifierType)
      );

      await executeAccountlessPrivacyRequest(
        props.identifierType,
        props.identifierValue,
        PrivacyRequestType.Delete,
        code
      );

      props.onClickNext();
    } catch (ex) {
      if (ex.status === RequestStatus.STATUS_BAD_REQUEST) {
        Es2Tracker.track(
          privacyRequestsVerifyDeleteInvalidActionEvent(props.identifierType)
        );
        setIsInvalidCode(true);
        return;
      }

      setIsUnexpectedError(true);
    } finally {
      setIsProcessing(false);
    }
  };

  const renderSecondaryButton = () => {
    if (props.isExistingAccount) {
      return (
        <MarketLink
          className="mt-[20px] text-center"
          {...(isProcessing && { disabled: true })}
          href={'/'}
        >
          {t('common.signInToSquareProfile', {
            SQUARE: nonTranslatableStrings.square,
          })}
        </MarketLink>
      );
    } else {
      return (
        <MarketButton
          rank={'tertiary'}
          className="mt-[8px]"
          {...(isProcessing && { disabled: true })}
          onClick={triggerCodeVerification}
        >
          {t('common.requestNewCode')}
        </MarketButton>
      );
    }
  };

  return (
    <>
      <BackButton onClick={props.onClickBack} />
      <h2 className="mb-[16px]">
        {t('common.verifyIdentifier.title', {
          identifierValue: props.identifierValue,
        })}
      </h2>
      <p className="paragraph-30 mt-0 mb-[24px]">
        {props.isExistingAccount
          ? props.identifierType === IdentifierType.Email
            ? t(
                'privacyManagement.verifyDeleteData.existingAccount.email.subtitle'
              )
            : t(
                'privacyManagement.verifyDeleteData.existingAccount.phone.subtitle'
              )
          : t('privacyManagement.verifyDeleteData.subtitle')}
      </p>
      <MarketField invalid={isInvalidCode} className="mb-[24px]">
        <MarketInputText>
          <label>{t('common.code')}</label>
          <input
            slot={'input'}
            data-testid={'code-input'}
            autoComplete={'one-time-code'}
            autoCorrect={'off'}
            autoCapitalize={'off'}
            inputMode={'numeric'}
            maxLength={CODE_LENGTH}
            value={code}
            onChange={(e) => {
              setCode(e.target.value);
              setIsInvalidCode(false);
            }}
            onKeyUpCapture={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter') {
                executeRequest();
              }
            }}
          />
        </MarketInputText>
        <small slot={'error'}>{t('common.code.invalid')}</small>
      </MarketField>
      <MarketButton
        rank={'primary'}
        variant={'destructive'}
        {...((isInvalidCode || isProcessing) && { disabled: true })}
        onClick={executeRequest}
      >
        {t('common.deleteInformation')}
      </MarketButton>
      {renderSecondaryButton()}
      <ErrorToastSlot
        errored={isUnexpectedError}
        clearError={() => setIsUnexpectedError(false)}
      />
      <div className="mt-[22px] text-fill-20">
        {props.isExistingAccount && (
          <p className={'paragraph-30'}>
            {t('privacyManagement.verifyDeleteData.existingAccount.legal')}
          </p>
        )}
        <p className={'paragraph-30'}>
          {t('privacyManagement.verifyDeleteData.legal')}
        </p>
      </div>
    </>
  );
};

export default VerifyDeleteData;
