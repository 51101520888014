import React from 'react';
import { SvgIconProps, SvgIcon } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

const GiftCardIcon: React.FC<SvgIconProps> = (props) => {
  const theme = useTheme();

  const color =
    props.color === 'secondary'
      ? theme.palette.secondary
      : theme.palette.primary;

  return (
    <SvgIcon width="60" height="40" viewBox="0 0 60 40" {...props}>
      <rect width="60" height="40" rx="4" fill={color.light} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 17.75C24 17.4106 24.118 17.0984 24.3185 16.8809C24.5046 16.679 24.8143 16.5 25.3333 16.5C26.1009 16.5 26.651 16.8385 27.2161 17.5358C27.6584 18.0816 28.0287 18.7375 28.4649 19.5101L28.5907 19.7327C27.6887 19.7033 26.7183 19.6234 25.874 19.4063C25.2201 19.2382 24.7366 19.0098 24.4305 18.7373C24.159 18.4957 24 18.2006 24 17.75ZM27.6437 21.6865C27.4291 21.9065 27.201 22.1114 26.9555 22.2986C26.0332 23.0019 24.8056 23.5 23 23.5C22.4477 23.5 22 23.9477 22 24.5C22 25.0523 22.4477 25.5 23 25.5C25.2182 25.5 26.8777 24.8731 28.1682 23.8889C28.903 23.3286 29.4974 22.6678 30 21.9861C30.5026 22.6678 31.097 23.3286 31.8318 23.8889C33.1223 24.8731 34.7818 25.5 37 25.5C37.5523 25.5 38 25.0523 38 24.5C38 23.9477 37.5523 23.5 37 23.5C35.1944 23.5 33.9668 23.0019 33.0445 22.2986C32.799 22.1114 32.5709 21.9065 32.3563 21.6865C33.1058 21.6321 33.8938 21.5311 34.624 21.3433C35.4285 21.1365 36.2575 20.8024 36.8992 20.2312C37.5757 19.6291 38 18.7993 38 17.75C38 16.9644 37.7292 16.1516 37.152 15.5254C36.5604 14.8835 35.7034 14.5 34.6667 14.5C33.1012 14.5 32.0324 15.2865 31.23 16.2767C30.7871 16.8233 30.3826 17.4915 30 18.1598C29.6174 17.4915 29.2129 16.8233 28.77 16.2767C27.9676 15.2865 26.8988 14.5 25.3333 14.5C24.2966 14.5 23.4396 14.8835 22.848 15.5254C22.2708 16.1516 22 16.9644 22 17.75C22 18.7993 22.4243 19.6291 23.1008 20.2312C23.7425 20.8024 24.5715 21.1365 25.376 21.3433C26.1062 21.5311 26.8942 21.6322 27.6437 21.6865ZM34.126 19.4063C33.2817 19.6234 32.3113 19.7033 31.4093 19.7327L31.5351 19.5101C31.9713 18.7375 32.3416 18.0816 32.7839 17.5358C33.349 16.8385 33.8991 16.5 34.6667 16.5C35.1857 16.5 35.4954 16.679 35.6815 16.8809C35.882 17.0984 36 17.4106 36 17.75C36 18.2006 35.841 18.4957 35.5695 18.7373C35.2634 19.0098 34.7799 19.2382 34.126 19.4063Z"
        fill={color.main}
      />
    </SvgIcon>
  );
};

export default GiftCardIcon;
