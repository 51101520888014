import { MarketContentCard, MarketLink } from '@market/react';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface SignInCtaProps {
  displayText: string;
  returnTo?: string;
}

const SignInCta: React.FC<SignInCtaProps> = ({ displayText, returnTo }) => {
  const history = useHistory();

  const urlSearchParams = returnTo
    ? new URLSearchParams({ return_to: returnTo }).toString()
    : '';

  return (
    <MarketContentCard className="flex justify-center">
      <MarketLink
        data-testid="sign-in-link"
        onClick={() =>
          history.push({
            pathname: '/signin',
            search: urlSearchParams,
          })
        }
      >
        {displayText}
      </MarketLink>
    </MarketContentCard>
  );
};

export default SignInCta;
