import { CountryCode } from 'libphonenumber-js/types';

export type ICountryPhoneMetadata = {
  name: string;
  internationalCallingCode: string;
  flag: string;
};
export type ICountryPhoneMetadataMap = Record<
  CountryCode,
  ICountryPhoneMetadata
>;

export const countryCodeToPhoneMetadata: ICountryPhoneMetadataMap = {
  /* Priority */
  US: {
    name: 'United States',
    internationalCallingCode: '+1',
    flag: '🇺🇸',
  },
  AU: {
    name: 'Australia',
    internationalCallingCode: '+61',
    flag: '🇦🇺',
  },
  CA: {
    name: 'Canada',
    internationalCallingCode: '+1',
    flag: '🇨🇦',
  },
  FR: {
    name: 'France',
    internationalCallingCode: '+33',
    flag: '🇫🇷',
  },
  IE: {
    name: 'Ireland',
    internationalCallingCode: '+353',
    flag: '🇮🇪',
  },
  JP: {
    name: 'Japan',
    internationalCallingCode: '+81',
    flag: '🇯🇵',
  },
  ES: {
    name: 'Spain',
    internationalCallingCode: '+34',
    flag: '🇪🇸',
  },
  GB: {
    name: 'United Kingdom',
    internationalCallingCode: '+44',
    flag: '🇬🇧',
  },
  /* Remaining */
  AF: {
    name: 'Afghanistan',
    internationalCallingCode: '+93',
    flag: '🇦🇫',
  },
  AL: {
    name: 'Albania',
    internationalCallingCode: '+355',
    flag: '🇦🇱',
  },
  DZ: {
    name: 'Algeria',
    internationalCallingCode: '+213',
    flag: '🇩🇿',
  },
  AS: {
    name: 'American Samoa',
    internationalCallingCode: '+1-684',
    flag: '🇦🇸',
  },
  AD: {
    name: 'Andorra',
    internationalCallingCode: '+376',
    flag: '🇦🇩',
  },
  AO: {
    name: 'Angola',
    internationalCallingCode: '+244',
    flag: '🇦🇴',
  },
  AI: {
    name: 'Anguilla',
    internationalCallingCode: '+1-264',
    flag: '🇦🇮',
  },
  // AQ is not in the set of country codes defined by libphonenumber-js
  // AQ: {
  //   name: 'Antarctica',
  //   internationalCallingCode: '+672',
  //   flag: '🇦🇶',
  // },
  AG: {
    name: 'Antigua and Barbuda',
    internationalCallingCode: '+1-268',
    flag: '🇦🇬',
  },
  AR: {
    name: 'Argentina',
    internationalCallingCode: '+54',
    flag: '🇦🇷',
  },
  AM: {
    name: 'Armenia',
    internationalCallingCode: '+374',
    flag: '🇦🇲',
  },
  AW: {
    name: 'Aruba',
    internationalCallingCode: '+297',
    flag: '🇦🇼',
  },
  AT: {
    name: 'Austria',
    internationalCallingCode: '+43',
    flag: '🇦🇹',
  },
  AZ: {
    name: 'Azerbaijan',
    internationalCallingCode: '+994',
    flag: '🇦🇿',
  },
  BS: {
    name: 'Bahamas',
    internationalCallingCode: '+1-242',
    flag: '🇧🇸',
  },
  BH: {
    name: 'Bahrain',
    internationalCallingCode: '+973',
    flag: '🇧🇭',
  },
  BD: {
    name: 'Bangladesh',
    internationalCallingCode: '+880',
    flag: '🇧🇩',
  },
  BB: {
    name: 'Barbados',
    internationalCallingCode: '+1-246',
    flag: '🇧🇧',
  },
  BY: {
    name: 'Belarus',
    internationalCallingCode: '+375',
    flag: '🇧🇾',
  },
  BE: {
    name: 'Belgium',
    internationalCallingCode: '+32',
    flag: '🇧🇪',
  },
  BZ: {
    name: 'Belize',
    internationalCallingCode: '+501',
    flag: '🇧🇿',
  },
  BJ: {
    name: 'Benin',
    internationalCallingCode: '+229',
    flag: '🇧🇯',
  },
  BM: {
    name: 'Bermuda',
    internationalCallingCode: '+1-441',
    flag: '🇧🇲',
  },
  BT: {
    name: 'Bhutan',
    internationalCallingCode: '+975',
    flag: '🇧🇹',
  },
  BO: {
    name: 'Bolivia',
    internationalCallingCode: '+591',
    flag: '🇧🇴',
  },
  BA: {
    name: 'Bosnia and Herzegovina',
    internationalCallingCode: '+387',
    flag: '🇧🇦',
  },
  BW: {
    name: 'Botswana',
    internationalCallingCode: '+267',
    flag: '🇧🇼',
  },
  BR: {
    name: 'Brazil',
    internationalCallingCode: '+55',
    flag: '🇧🇷',
  },
  IO: {
    name: 'British Indian Ocean Territory',
    internationalCallingCode: '+246',
    flag: '🇮🇴',
  },
  BN: {
    name: 'Brunei',
    internationalCallingCode: '+673',
    flag: '🇧🇳',
  },
  BG: {
    name: 'Bulgaria',
    internationalCallingCode: '+359',
    flag: '🇧🇬',
  },
  BF: {
    name: 'Burkina Faso',
    internationalCallingCode: '+226',
    flag: '🇧🇫',
  },
  BI: {
    name: 'Burundi',
    internationalCallingCode: '+257',
    flag: '🇧🇮',
  },
  KH: {
    name: 'Cambodia',
    internationalCallingCode: '+855',
    flag: '🇰🇭',
  },
  CM: {
    name: 'Cameroon',
    internationalCallingCode: '+237',
    flag: '🇨🇲',
  },
  CV: {
    name: 'Cape Verde',
    internationalCallingCode: '+238',
    flag: '🇨🇻',
  },
  KY: {
    name: 'Cayman Islands',
    internationalCallingCode: '+1-345',
    flag: '🇰🇾',
  },
  CF: {
    name: 'Central African Republic',
    internationalCallingCode: '+236',
    flag: '🇨🇫',
  },
  TD: {
    name: 'Chad',
    internationalCallingCode: '+235',
    flag: '🇹🇩',
  },
  CL: {
    name: 'Chile',
    internationalCallingCode: '+56',
    flag: '🇨🇱',
  },
  CN: {
    name: 'China',
    internationalCallingCode: '+86',
    flag: '🇨🇳',
  },
  CX: {
    name: 'Christmas Island',
    internationalCallingCode: '+61',
    flag: '🇨🇽',
  },
  CC: {
    name: 'Cocos (Keeling) Islands',
    internationalCallingCode: '+61',
    flag: '🇨🇨',
  },
  CO: {
    name: 'Colombia',
    internationalCallingCode: '+57',
    flag: '🇨🇴',
  },
  KM: {
    name: 'Comoros',
    internationalCallingCode: '+269',
    flag: '🇰🇲',
  },
  CG: {
    name: 'Congo (Brazzaville)',
    internationalCallingCode: '+242',
    flag: '🇨🇬',
  },
  CD: {
    name: 'Congo (Kinshasa)',
    internationalCallingCode: '+243',
    flag: '🇨🇩',
  },
  CK: {
    name: 'Cook Islands',
    internationalCallingCode: '+682',
    flag: '🇨🇰',
  },
  CR: {
    name: 'Costa Rica',
    internationalCallingCode: '+506',
    flag: '🇨🇷',
  },
  HR: {
    name: 'Croatia',
    internationalCallingCode: '+385',
    flag: '🇭🇷',
  },
  CU: {
    name: 'Cuba',
    internationalCallingCode: '+53',
    flag: '🇨🇺',
  },
  CW: {
    name: 'Curaçao',
    internationalCallingCode: '+599',
    flag: '🇨🇼',
  },
  CY: {
    name: 'Cyprus',
    internationalCallingCode: '+357',
    flag: '🇨🇾',
  },
  CZ: {
    name: 'Czech Republic',
    internationalCallingCode: '+420',
    flag: '🇨🇿',
  },
  DK: {
    name: 'Denmark',
    internationalCallingCode: '+45',
    flag: '🇩🇰',
  },
  DJ: {
    name: 'Djibouti',
    internationalCallingCode: '+253',
    flag: '🇩🇯',
  },
  DM: {
    name: 'Dominica',
    internationalCallingCode: '+1-767',
    flag: '🇩🇲',
  },
  DO: {
    name: 'Dominican Republic',
    internationalCallingCode: '+1-809, 1-829, 1-849',
    flag: '🇩🇴',
  },
  EC: {
    name: 'Ecuador',
    internationalCallingCode: '+593',
    flag: '🇪🇨',
  },
  EG: {
    name: 'Egypt',
    internationalCallingCode: '+20',
    flag: '🇪🇬',
  },
  SV: {
    name: 'El Salvador',
    internationalCallingCode: '+503',
    flag: '🇸🇻',
  },
  GQ: {
    name: 'Equatorial Guinea',
    internationalCallingCode: '+240',
    flag: '🇬🇶',
  },
  ER: {
    name: 'Eritrea',
    internationalCallingCode: '+291',
    flag: '🇪🇷',
  },
  EE: {
    name: 'Estonia',
    internationalCallingCode: '+372',
    flag: '🇪🇪',
  },
  SZ: {
    name: 'Eswatini',
    internationalCallingCode: '+268',
    flag: '🇸🇿',
  },
  ET: {
    name: 'Ethiopia',
    internationalCallingCode: '+251',
    flag: '🇪🇹',
  },
  FK: {
    name: 'Falkland Islands',
    internationalCallingCode: '+500',
    flag: '🇫🇰',
  },
  FO: {
    name: 'Faroe Islands',
    internationalCallingCode: '+298',
    flag: '🇫🇴',
  },
  FJ: {
    name: 'Fiji',
    internationalCallingCode: '+679',
    flag: '🇫🇯',
  },
  FI: {
    name: 'Finland',
    internationalCallingCode: '+358',
    flag: '🇫🇮',
  },
  GF: {
    name: 'French Guiana',
    internationalCallingCode: '+594',
    flag: '🇬🇫',
  },
  PF: {
    name: 'French Polynesia',
    internationalCallingCode: '+689',
    flag: '🇵🇫',
  },
  GA: {
    name: 'Gabon',
    internationalCallingCode: '+241',
    flag: '🇬🇦',
  },
  GM: {
    name: 'Gambia',
    internationalCallingCode: '+220',
    flag: '🇬🇲',
  },
  GE: {
    name: 'Georgia',
    internationalCallingCode: '+995',
    flag: '🇬🇪',
  },
  DE: {
    name: 'Germany',
    internationalCallingCode: '+49',
    flag: '🇩🇪',
  },
  GH: {
    name: 'Ghana',
    internationalCallingCode: '+233',
    flag: '🇬🇭',
  },
  GI: {
    name: 'Gibraltar',
    internationalCallingCode: '+350',
    flag: '🇬🇮',
  },
  GR: {
    name: 'Greece',
    internationalCallingCode: '+30',
    flag: '🇬🇷',
  },
  GL: {
    name: 'Greenland',
    internationalCallingCode: '+299',
    flag: '🇬🇱',
  },
  GD: {
    name: 'Grenada',
    internationalCallingCode: '+1-473',
    flag: '🇬🇩',
  },
  GP: {
    name: 'Guadeloupe',
    internationalCallingCode: '+590',
    flag: '🇬🇵',
  },
  GU: {
    name: 'Guam',
    internationalCallingCode: '+1-671',
    flag: '🇬🇺',
  },
  GT: {
    name: 'Guatemala',
    internationalCallingCode: '+502',
    flag: '🇬🇹',
  },
  GG: {
    name: 'Guernsey',
    internationalCallingCode: '+44-1481',
    flag: '🇬🇬',
  },
  GN: {
    name: 'Guinea',
    internationalCallingCode: '+224',
    flag: '🇬🇳',
  },
  GW: {
    name: 'Guinea-Bissau',
    internationalCallingCode: '+245',
    flag: '🇬🇼',
  },
  GY: {
    name: 'Guyana',
    internationalCallingCode: '+592',
    flag: '🇬🇾',
  },
  HT: {
    name: 'Haiti',
    internationalCallingCode: '+509',
    flag: '🇭🇹',
  },
  HN: {
    name: 'Honduras',
    internationalCallingCode: '+504',
    flag: '🇭🇳',
  },
  HK: {
    name: 'Hong Kong',
    internationalCallingCode: '+852',
    flag: '🇭🇰',
  },
  HU: {
    name: 'Hungary',
    internationalCallingCode: '+36',
    flag: '🇭🇺',
  },
  IS: {
    name: 'Iceland',
    internationalCallingCode: '+354',
    flag: '🇮🇸',
  },
  IN: {
    name: 'India',
    internationalCallingCode: '+91',
    flag: '🇮🇳',
  },
  ID: {
    name: 'Indonesia',
    internationalCallingCode: '+62',
    flag: '🇮🇩',
  },
  /*
  IR: {
    name: 'Iran',
    code: '+98',
    flag: '🇮🇷',
  },
  */
  IQ: {
    name: 'Iraq',
    internationalCallingCode: '+964',
    flag: '🇮🇶',
  },
  IM: {
    name: 'Isle of Man',
    internationalCallingCode: '+44-1624',
    flag: '🇮🇲',
  },
  IL: {
    name: 'Israel',
    internationalCallingCode: '+972',
    flag: '🇮🇱',
  },
  IT: {
    name: 'Italy',
    internationalCallingCode: '+39',
    flag: '🇮🇹',
  },
  JM: {
    name: 'Jamaica',
    internationalCallingCode: '+1-876',
    flag: '🇯🇲',
  },
  JE: {
    name: 'Jersey',
    internationalCallingCode: '+44-1534',
    flag: '🇯🇪',
  },
  JO: {
    name: 'Jordan',
    internationalCallingCode: '+962',
    flag: '🇯🇴',
  },
  KZ: {
    name: 'Kazakhstan',
    internationalCallingCode: '+7',
    flag: '🇰🇿',
  },
  KE: {
    name: 'Kenya',
    internationalCallingCode: '+254',
    flag: '🇰🇪',
  },
  KI: {
    name: 'Kiribati',
    internationalCallingCode: '+686',
    flag: '🇰🇮',
  },
  XK: {
    name: 'Kosovo',
    internationalCallingCode: '+383',
    flag: '🇽🇰',
  },
  KW: {
    name: 'Kuwait',
    internationalCallingCode: '+965',
    flag: '🇰🇼',
  },
  KG: {
    name: 'Kyrgyzstan',
    internationalCallingCode: '+996',
    flag: '🇰🇬',
  },
  LA: {
    name: 'Laos',
    internationalCallingCode: '+856',
    flag: '🇱🇦',
  },
  LV: {
    name: 'Latvia',
    internationalCallingCode: '+371',
    flag: '🇱🇻',
  },
  LB: {
    name: 'Lebanon',
    internationalCallingCode: '+961',
    flag: '🇱🇧',
  },
  LS: {
    name: 'Lesotho',
    internationalCallingCode: '+266',
    flag: '🇱🇸',
  },
  LR: {
    name: 'Liberia',
    internationalCallingCode: '+231',
    flag: '🇱🇷',
  },
  LY: {
    name: 'Libya',
    internationalCallingCode: '+218',
    flag: '🇱🇾',
  },
  LI: {
    name: 'Liechtenstein',
    internationalCallingCode: '+423',
    flag: '🇱🇮',
  },
  LT: {
    name: 'Lithuania',
    internationalCallingCode: '+370',
    flag: '🇱🇹',
  },
  LU: {
    name: 'Luxembourg',
    internationalCallingCode: '+352',
    flag: '🇱🇺',
  },
  MO: {
    name: 'Macau',
    internationalCallingCode: '+853',
    flag: '🇲🇴',
  },
  MG: {
    name: 'Madagascar',
    internationalCallingCode: '+261',
    flag: '🇲🇬',
  },
  MW: {
    name: 'Malawi',
    internationalCallingCode: '+265',
    flag: '🇲🇼',
  },
  MY: {
    name: 'Malaysia',
    internationalCallingCode: '+60',
    flag: '🇲🇾',
  },
  MV: {
    name: 'Maldives',
    internationalCallingCode: '+960',
    flag: '🇲🇻',
  },
  ML: {
    name: 'Mali',
    internationalCallingCode: '+223',
    flag: '🇲🇱',
  },
  MT: {
    name: 'Malta',
    internationalCallingCode: '+356',
    flag: '🇲🇹',
  },
  MH: {
    name: 'Marshall Islands',
    internationalCallingCode: '+692',
    flag: '🇲🇭',
  },
  MQ: {
    name: 'Martinique',
    internationalCallingCode: '+596',
    flag: '🇲🇶',
  },
  MR: {
    name: 'Mauritania',
    internationalCallingCode: '+222',
    flag: '🇲🇷',
  },
  MU: {
    name: 'Mauritius',
    internationalCallingCode: '+230',
    flag: '🇲🇺',
  },
  YT: {
    name: 'Mayotte',
    internationalCallingCode: '+262',
    flag: '🇾🇹',
  },
  MX: {
    name: 'Mexico',
    internationalCallingCode: '+52',
    flag: '🇲🇽',
  },
  FM: {
    name: 'Micronesia',
    internationalCallingCode: '+691',
    flag: '🇫🇲',
  },
  MD: {
    name: 'Moldova',
    internationalCallingCode: '+373',
    flag: '🇲🇩',
  },
  MC: {
    name: 'Monaco',
    internationalCallingCode: '+377',
    flag: '🇲🇨',
  },
  MN: {
    name: 'Mongolia',
    internationalCallingCode: '+976',
    flag: '🇲🇳',
  },
  ME: {
    name: 'Montenegro',
    internationalCallingCode: '+382',
    flag: '🇲🇪',
  },
  MS: {
    name: 'Montserrat',
    internationalCallingCode: '+1-664',
    flag: '🇲🇸',
  },
  MA: {
    name: 'Morocco',
    internationalCallingCode: '+212',
    flag: '🇲🇦',
  },
  MZ: {
    name: 'Mozambique',
    internationalCallingCode: '+258',
    flag: '🇲🇿',
  },
  MM: {
    name: 'Myanmar',
    internationalCallingCode: '+95',
    flag: '🇲🇲',
  },
  NA: {
    name: 'Namibia',
    internationalCallingCode: '+264',
    flag: '🇳🇦',
  },
  NR: {
    name: 'Nauru',
    internationalCallingCode: '+674',
    flag: '🇳🇷',
  },
  NP: {
    name: 'Nepal',
    internationalCallingCode: '+977',
    flag: '🇳🇵',
  },
  NL: {
    name: 'Netherlands',
    internationalCallingCode: '+31',
    flag: '🇳🇱',
  },
  NC: {
    name: 'New Caledonia',
    internationalCallingCode: '+687',
    flag: '🇳🇨',
  },
  NZ: {
    name: 'New Zealand',
    internationalCallingCode: '+64',
    flag: '🇳🇿',
  },
  NI: {
    name: 'Nicaragua',
    internationalCallingCode: '+505',
    flag: '🇳🇮',
  },
  NE: {
    name: 'Niger',
    internationalCallingCode: '+227',
    flag: '🇳🇪',
  },
  NG: {
    name: 'Nigeria',
    internationalCallingCode: '+234',
    flag: '🇳🇬',
  },
  NU: {
    name: 'Niue',
    internationalCallingCode: '+683',
    flag: '🇳🇺',
  },
  NF: {
    name: 'Norfolk Island',
    internationalCallingCode: '+672',
    flag: '🇳🇫',
  },
  /*
  KP: {
    name: 'North Korea',
    code: '+850',
    flag: '🇰🇵',
  },
  */
  MK: {
    name: 'North Macedonia',
    internationalCallingCode: '+389',
    flag: '🇲🇰',
  },
  NO: {
    name: 'Norway',
    internationalCallingCode: '+47',
    flag: '🇳🇴',
  },
  OM: {
    name: 'Oman',
    internationalCallingCode: '+968',
    flag: '🇴🇲',
  },
  PK: {
    name: 'Pakistan',
    internationalCallingCode: '+92',
    flag: '🇵🇰',
  },
  PW: {
    name: 'Palau',
    internationalCallingCode: '+680',
    flag: '🇵🇼',
  },
  PS: {
    name: 'Palestine',
    internationalCallingCode: '+970',
    flag: '🇵🇸',
  },
  PA: {
    name: 'Panama',
    internationalCallingCode: '+507',
    flag: '🇵🇦',
  },
  PG: {
    name: 'Papua New Guinea',
    internationalCallingCode: '+675',
    flag: '🇵🇬',
  },
  PY: {
    name: 'Paraguay',
    internationalCallingCode: '+595',
    flag: '🇵🇾',
  },
  PE: {
    name: 'Peru',
    internationalCallingCode: '+51',
    flag: '🇵🇪',
  },
  PH: {
    name: 'Philippines',
    internationalCallingCode: '+63',
    flag: '🇵🇭',
  },
  PN: {
    name: 'Pitcairn Islands',
    internationalCallingCode: '+64',
    flag: '🇵🇳',
  },
  PL: {
    name: 'Poland',
    internationalCallingCode: '+48',
    flag: '🇵🇱',
  },
  PT: {
    name: 'Portugal',
    internationalCallingCode: '+351',
    flag: '🇵🇹',
  },
  PR: {
    name: 'Puerto Rico',
    internationalCallingCode: '+1',
    flag: '🇵🇷',
  },
  QA: {
    name: 'Qatar',
    internationalCallingCode: '+974',
    flag: '🇶🇦',
  },
  RE: {
    name: 'Réunion',
    internationalCallingCode: '+262',
    flag: '🇷🇪',
  },
  RO: {
    name: 'Romania',
    internationalCallingCode: '+40',
    flag: '🇷🇴',
  },
  RU: {
    name: 'Russia',
    internationalCallingCode: '+7',
    flag: '🇷🇺',
  },
  RW: {
    name: 'Rwanda',
    internationalCallingCode: '+250',
    flag: '🇷🇼',
  },
  BL: {
    name: 'Saint Barthélemy',
    internationalCallingCode: '+590',
    flag: '🇧🇱',
  },
  SH: {
    name: 'Saint Helena',
    internationalCallingCode: '+290',
    flag: '🇸🇭',
  },
  KN: {
    name: 'Saint Kitts and Nevis',
    internationalCallingCode: '+1-869',
    flag: '🇰🇳',
  },
  LC: {
    name: 'Saint Lucia',
    internationalCallingCode: '+1-758',
    flag: '🇱🇨',
  },
  MF: {
    name: 'Saint Martin',
    internationalCallingCode: '+590',
    flag: '🇲🇫',
  },
  PM: {
    name: 'Saint Pierre and Miquelon',
    internationalCallingCode: '+508',
    flag: '🇵🇲',
  },
  VC: {
    name: 'Saint Vincent and the Grenadines',
    internationalCallingCode: '+1-784',
    flag: '🇻🇨',
  },
  WS: {
    name: 'Samoa',
    internationalCallingCode: '+685',
    flag: '🇼🇸',
  },
  SM: {
    name: 'San Marino',
    internationalCallingCode: '+378',
    flag: '🇸🇲',
  },
  ST: {
    name: 'São Tomé and Príncipe',
    internationalCallingCode: '+239',
    flag: '🇸🇹',
  },
  SA: {
    name: 'Saudi Arabia',
    internationalCallingCode: '+966',
    flag: '🇸🇦',
  },
  SN: {
    name: 'Senegal',
    internationalCallingCode: '+221',
    flag: '🇸🇳',
  },
  RS: {
    name: 'Serbia',
    internationalCallingCode: '+381',
    flag: '🇷🇸',
  },
  SC: {
    name: 'Seychelles',
    internationalCallingCode: '+248',
    flag: '🇸🇨',
  },
  SL: {
    name: 'Sierra Leone',
    internationalCallingCode: '+232',
    flag: '🇸🇱',
  },
  SG: {
    name: 'Singapore',
    internationalCallingCode: '+65',
    flag: '🇸🇬',
  },
  SX: {
    name: 'Sint Maarten',
    internationalCallingCode: '+1-721',
    flag: '🇸🇽',
  },
  SK: {
    name: 'Slovakia',
    internationalCallingCode: '+421',
    flag: '🇸🇰',
  },
  SI: {
    name: 'Slovenia',
    internationalCallingCode: '+386',
    flag: '🇸🇮',
  },
  SB: {
    name: 'Solomon Islands',
    internationalCallingCode: '+677',
    flag: '🇸🇧',
  },
  SO: {
    name: 'Somalia',
    internationalCallingCode: '+252',
    flag: '🇸🇴',
  },
  ZA: {
    name: 'South Africa',
    internationalCallingCode: '+27',
    flag: '🇿🇦',
  },
  GS: {
    name: 'South Georgia and the South Sandwich Islands',
    internationalCallingCode: '+500',
    flag: '🇬🇸',
  },
  KR: {
    name: 'South Korea',
    internationalCallingCode: '+82',
    flag: '🇰🇷',
  },
  SS: {
    name: 'South Sudan',
    internationalCallingCode: '+211',
    flag: '🇸🇸',
  },
  LK: {
    name: 'Sri Lanka',
    internationalCallingCode: '+94',
    flag: '🇱🇰',
  },
  SD: {
    name: 'Sudan',
    internationalCallingCode: '+249',
    flag: '🇸🇩',
  },
  SR: {
    name: 'Suriname',
    internationalCallingCode: '+597',
    flag: '🇸🇷',
  },
  SJ: {
    name: 'Svalbard and Jan Mayen',
    internationalCallingCode: '+47',
    flag: '🇸🇯',
  },
  SE: {
    name: 'Sweden',
    internationalCallingCode: '+46',
    flag: '🇸🇪',
  },
  CH: {
    name: 'Switzerland',
    internationalCallingCode: '+41',
    flag: '🇨🇭',
  },
  /* SY: {
    name: 'Syria',
    code: '+963',
    flag: '🇸🇾',
  }, */
  TW: {
    name: 'Taiwan',
    internationalCallingCode: '+886',
    flag: '🇹🇼',
  },
  TJ: {
    name: 'Tajikistan',
    internationalCallingCode: '+992',
    flag: '🇹🇯',
  },
  TZ: {
    name: 'Tanzania',
    internationalCallingCode: '+255',
    flag: '🇹🇿',
  },
  TH: {
    name: 'Thailand',
    internationalCallingCode: '+66',
    flag: '🇹🇭',
  },
  TG: {
    name: 'Togo',
    internationalCallingCode: '+228',
    flag: '🇹🇬',
  },
  TK: {
    name: 'Tokelau',
    internationalCallingCode: '+690',
    flag: '🇹🇰',
  },
  TO: {
    name: 'Tonga',
    internationalCallingCode: '+676',
    flag: '🇹🇴',
  },
  TT: {
    name: 'Trinidad and Tobago',
    internationalCallingCode: '+1-868',
    flag: '🇹🇹',
  },
  TN: {
    name: 'Tunisia',
    internationalCallingCode: '+216',
    flag: '🇹🇳',
  },
  TR: {
    name: 'Turkey',
    internationalCallingCode: '+90',
    flag: '🇹🇷',
  },
  TM: {
    name: 'Turkmenistan',
    internationalCallingCode: '+993',
    flag: '🇹🇲',
  },
  TC: {
    name: 'Turks and Caicos Islands',
    internationalCallingCode: '+1-649',
    flag: '🇹🇨',
  },
  TV: {
    name: 'Tuvalu',
    internationalCallingCode: '+688',
    flag: '🇹🇻',
  },
  UG: {
    name: 'Uganda',
    internationalCallingCode: '+256',
    flag: '🇺🇬',
  },
  UA: {
    name: 'Ukraine',
    internationalCallingCode: '+380',
    flag: '🇺🇦',
  },
  AE: {
    name: 'United Arab Emirates',
    internationalCallingCode: '+971',
    flag: '🇦🇪',
  },
  UY: {
    name: 'Uruguay',
    internationalCallingCode: '+598',
    flag: '🇺🇾',
  },
  UZ: {
    name: 'Uzbekistan',
    internationalCallingCode: '+998',
    flag: '🇺🇿',
  },
  VU: {
    name: 'Vanuatu',
    internationalCallingCode: '+678',
    flag: '🇻🇺',
  },
  VA: {
    name: 'Vatican City',
    internationalCallingCode: '+379',
    flag: '🇻🇦',
  },
  VE: {
    name: 'Venezuela',
    internationalCallingCode: '+58',
    flag: '🇻🇪',
  },
  VN: {
    name: 'Vietnam',
    internationalCallingCode: '+84',
    flag: '🇻🇳',
  },
  VG: {
    name: 'British Virgin Islands',
    internationalCallingCode: '+1-284',
    flag: '🇻🇬',
  },
  VI: {
    name: 'U.S. Virgin Islands',
    internationalCallingCode: '+1-340',
    flag: '🇻🇮',
  },
  WF: {
    name: 'Wallis and Futuna',
    internationalCallingCode: '+681',
    flag: '🇼🇫',
  },
  EH: {
    name: 'Western Sahara',
    internationalCallingCode: '+212',
    flag: '🇪🇭',
  },
  YE: {
    name: 'Yemen',
    internationalCallingCode: '+967',
    flag: '🇾🇪',
  },
  ZM: {
    name: 'Zambia',
    internationalCallingCode: '+260',
    flag: '🇿🇲',
  },
  ZW: {
    name: 'Zimbabwe',
    internationalCallingCode: '+263',
    flag: '🇿🇼',
  },
};
