/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      protobuf: {
        nested: {
          rpc: {
            nested: {
              ResponseCode: {
                values: {
                  REQUEST_TIMEOUT: -1,
                  REQUEST_COMPLETED: 0,
                  INVALID_REQUEST: 400,
                  UNAUTHORIZED: 401,
                  FORBIDDEN: 403,
                  NOT_FOUND: 404,
                  CONFLICT: 409,
                  GONE: 410,
                  UNPROCESSABLE_ENTITY: 422,
                  TOO_MANY_REQUESTS: 429,
                  CANCELED: 499,
                  INTERNAL_ERROR: 500,
                  NOT_IMPLEMENTED: 501,
                  BAD_GATEWAY: 502,
                  SERVICE_UNAVAILABLE: 503,
                  GATEWAY_TIMEOUT: 504,
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.protobuf?.nested?.rpc?.nested?.ResponseCode
) {
  $root.addJSON(jsonData);
}

/**
 * Global RPC response codes, which match closely with HTTP status codes. RPC errors
 * do not have response messages, as the request was unable to be completed.
 * Domain-specific errors are generally returned with a method's response message.
 * Similar to HTTP, 4xx errors are effectively the fault of the client, while 5xx's
 * errors are that of the server.
 *
 * SOURCE ResponseCode @ squareup/protobuf/rpc/rpc.proto at 18:1
 */
export const ResponseCode = $root.lookupEnum(
  'squareup.protobuf.rpc.ResponseCode'
).values;
