/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import '../../pago/external/payments_model';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      client: {
        nested: {
          orders: {
            nested: {
              OrderPayment: {
                fields: {
                  payments: {
                    type: 'squareup.connect.v2.Payment',
                    id: 1,
                    rule: 'repeated',
                  },
                  paymentDetails: {
                    type: 'squareup.client.orders.OrderPayment.PaymentDetails',
                    id: 2,
                    rule: 'repeated',
                  },
                },
                nested: {
                  PaymentDetails: {
                    fields: {
                      payment: {
                        type: 'squareup.connect.v2.Payment',
                        id: 1,
                        rule: 'optional',
                      },
                      feePlanClientInfo: {
                        type: 'squareup.client.orders.OrderPayment.PaymentDetails.FeePlanClientInfo',
                        id: 2,
                        rule: 'optional',
                      },
                      loyaltyDetails: {
                        type: 'squareup.client.orders.OrderPayment.PaymentDetails.LoyaltyDetails',
                        id: 3,
                        rule: 'optional',
                      },
                    },
                    nested: {
                      FeePlanClientInfo: {
                        fields: {
                          discountBasisPoints: {
                            type: 'int64',
                            id: 1,
                            rule: 'optional',
                          },
                          interchangeCents: {
                            type: 'int64',
                            id: 2,
                            rule: 'optional',
                          },
                        },
                      },
                      LoyaltyDetails: {
                        fields: {
                          starsEarned: {
                            type: 'int32',
                            id: 1,
                            rule: 'optional',
                          },
                          newEnrollment: {
                            type: 'bool',
                            id: 2,
                            rule: 'optional',
                          },
                          reason: {
                            type: 'squareup.client.orders.OrderPayment.PaymentDetails.LoyaltyDetails.ReasonForNoStars',
                            id: 3,
                            rule: 'optional',
                          },
                          accountContact: {
                            type: 'squareup.client.orders.OrderPayment.PaymentDetails.LoyaltyDetails.AccountContact',
                            id: 4,
                            rule: 'optional',
                          },
                        },
                        nested: {
                          ReasonForNoStars: {
                            values: {
                              UNKNOWN: 0,
                              NOT_YET_SUBSCRIBED: 1,
                              CLIENT_DISABLED_LOYALTY: 2,
                              RETURNED_FROM_RECEIPT_SCREEN: 3,
                              RETURNED_FROM_LOYALTY_SCREEN: 4,
                              BUYER_DECLINED: 5,
                              OFFLINE_MODE: 6,
                              NETWORK_TIMEOUT: 7,
                              MISSING_LOYALTY_INFO: 8,
                              RETURNED_DURING_LOADING: 9,
                              PURCHASE_DID_NOT_QUALIFY: 10,
                            },
                          },
                          AccountContact: {
                            fields: {
                              displayName: {
                                type: 'string',
                                id: 1,
                                rule: 'optional',
                              },
                              contactToken: {
                                type: 'string',
                                id: 2,
                                rule: 'optional',
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.client?.nested?.orders?.nested
    ?.OrderPayment &&
  !$root.nested?.squareup?.nested?.client?.nested?.orders?.nested?.OrderPayment
    ?.nested?.PaymentDetails &&
  !$root.nested?.squareup?.nested?.client?.nested?.orders?.nested?.OrderPayment
    ?.nested?.PaymentDetails?.nested?.FeePlanClientInfo &&
  !$root.nested?.squareup?.nested?.client?.nested?.orders?.nested?.OrderPayment
    ?.nested?.PaymentDetails?.nested?.LoyaltyDetails &&
  !$root.nested?.squareup?.nested?.client?.nested?.orders?.nested?.OrderPayment
    ?.nested?.PaymentDetails?.nested?.LoyaltyDetails?.nested
    ?.ReasonForNoStars &&
  !$root.nested?.squareup?.nested?.client?.nested?.orders?.nested?.OrderPayment
    ?.nested?.PaymentDetails?.nested?.LoyaltyDetails?.nested?.AccountContact
) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE OrderPayment @ squareup/client/orders/order-payment.proto at 9:1
 */
export const OrderPayment = $root.lookupType(
  'squareup.client.orders.OrderPayment'
);
fixType(OrderPayment);
Builder.createAndAttachToType(OrderPayment);
/**
 * SOURCE PaymentDetails @ squareup/client/orders/order-payment.proto at 15:2
 */
OrderPayment.PaymentDetails = $root.lookupType(
  'squareup.client.orders.OrderPayment.PaymentDetails'
);
fixType(OrderPayment.PaymentDetails);
Builder.createAndAttachToType(OrderPayment.PaymentDetails);
/**
 * SOURCE FeePlanClientInfo @ squareup/client/orders/order-payment.proto at 20:3
 */
OrderPayment.PaymentDetails.FeePlanClientInfo = $root.lookupType(
  'squareup.client.orders.OrderPayment.PaymentDetails.FeePlanClientInfo'
);
fixType(OrderPayment.PaymentDetails.FeePlanClientInfo);
Builder.createAndAttachToType(OrderPayment.PaymentDetails.FeePlanClientInfo);
/**
 * SOURCE LoyaltyDetails @ squareup/client/orders/order-payment.proto at 25:3
 */
OrderPayment.PaymentDetails.LoyaltyDetails = $root.lookupType(
  'squareup.client.orders.OrderPayment.PaymentDetails.LoyaltyDetails'
);
fixType(OrderPayment.PaymentDetails.LoyaltyDetails);
Builder.createAndAttachToType(OrderPayment.PaymentDetails.LoyaltyDetails);
/**
 * SOURCE ReasonForNoStars @ squareup/client/orders/order-payment.proto at 35:4
 */
OrderPayment.PaymentDetails.LoyaltyDetails.ReasonForNoStars = $root.lookupEnum(
  'squareup.client.orders.OrderPayment.PaymentDetails.LoyaltyDetails.ReasonForNoStars'
).values;
/**
 * The contact attached to the Loyalty Account that accrued the points for this transaction
 *
 * SOURCE AccountContact @ squareup/client/orders/order-payment.proto at 66:4
 */
OrderPayment.PaymentDetails.LoyaltyDetails.AccountContact = $root.lookupType(
  'squareup.client.orders.OrderPayment.PaymentDetails.LoyaltyDetails.AccountContact'
);
fixType(OrderPayment.PaymentDetails.LoyaltyDetails.AccountContact);
Builder.createAndAttachToType(
  OrderPayment.PaymentDetails.LoyaltyDetails.AccountContact
);
