import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const URL_FETCH_OBFUSCATED_RECEIPT_EMAIL =
  '/services/squareup.receipts.SubscriptionService/subscriptions/receipt_info/';
export const URL_OPT_OUT_AUTOMATIC_RECEIPT_FROM_MERCHANT =
  '/services/squareup.receipts.SubscriptionService/subscriptions/automatic_receipt_opt_out/merchant/';

export interface FetchObfuscatedEmailReceiptReturnValue {
  merchantName: string | undefined;
  obfuscatedEmail: string | undefined;
  obfuscatedCard: string | undefined;
}

export interface OptOutAutomaticReceiptFromMerchantReturnValue {
  success?: string;
}

export const receiptSubscriptionsApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: '/services/squareup.receipts.SubscriptionService/subscriptions/',
  }),
  endpoints: (builder) => ({
    automaticReceiptOptInGlobal: builder.mutation<
      OptOutAutomaticReceiptFromMerchantReturnValue,
      string
    >({
      query: (receiptToken) => ({
        method: 'POST',
        url: `automatic_receipt_opt_in/global/${receiptToken}`,
      }),
    }),
    automaticReceiptOptInMerchant: builder.mutation<
      OptOutAutomaticReceiptFromMerchantReturnValue,
      string
    >({
      query: (receiptToken) => ({
        method: 'POST',
        url: `automatic_receipt_opt_in/merchant/${receiptToken}`,
      }),
    }),
    automaticReceiptOptOutGlobal: builder.mutation<
      OptOutAutomaticReceiptFromMerchantReturnValue,
      string
    >({
      query: (receiptToken) => ({
        method: 'POST',
        url: `automatic_receipt_opt_out/global/${receiptToken}`,
      }),
    }),
    automaticReceiptOptOutMerchant: builder.mutation<
      OptOutAutomaticReceiptFromMerchantReturnValue,
      string
    >({
      query: (receiptToken) => ({
        method: 'POST',
        url: `automatic_receipt_opt_out/merchant/${receiptToken}`,
      }),
    }),
    fetchObfuscatedEmailReceipt: builder.query<
      FetchObfuscatedEmailReceiptReturnValue,
      string
    >({
      query: (receiptToken) => ({
        url: `receipt_info/${receiptToken}`,
        validateStatus: (response, result) =>
          Boolean(result?.receipt) &&
          Boolean(
            result?.receipt?.payment_card?.human_brand ||
              result?.receipt?.payment_card?.pan_suffix
          ) &&
          Boolean(result.receipt?.user?.merchant_name),
      }),
      transformResponse: (response) => {
        const cardBrand = response?.receipt?.payment_card?.human_brand;
        const cardSuffix = response?.receipt?.payment_card?.pan_suffix;

        return {
          merchantName: response?.receipt?.user?.merchant_name,
          obfuscatedCard:
            cardBrand || cardSuffix
              ? [cardBrand, cardSuffix].filter(Boolean).join(' ')
              : null,
          obfuscatedEmail: response?.receipt?.payment_card?.obfuscated_email,
        };
      },
    }),
    unlinkAutomaticReceipt: builder.mutation<
      OptOutAutomaticReceiptFromMerchantReturnValue,
      string
    >({
      query: (receiptToken) => ({
        method: 'POST',
        url: `not_your_receipt/${receiptToken}`,
      }),
    }),
  }),
  reducerPath: 'receiptSubscriptionsApi',
});

export const {
  useFetchObfuscatedEmailReceiptQuery,
  useAutomaticReceiptOptInMerchantMutation,
  useAutomaticReceiptOptOutMerchantMutation,
  useAutomaticReceiptOptInGlobalMutation,
  useAutomaticReceiptOptOutGlobalMutation,
  useUnlinkAutomaticReceiptMutation,
} = receiptSubscriptionsApi;
