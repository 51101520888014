import * as Sentry from '@sentry/browser';

/**
 * @property {number} id
 */
export class RequestLogger {
  /**
   * @param {number} id
   */
  constructor(id) {
    this.id = id;
  }

  /**
   * @param {*} method
   * @param {string} url
   * @param {Uint8Array} requestData
   */
  logRequest(method, url, requestData) {} // eslint-disable-line no-unused-vars

  /**
   * @param {*} method
   * @param {string} url
   * @param {Uint8Array} responseData
   */
  logResponse(method, url, responseData) {} // eslint-disable-line no-unused-vars

  /**
   * @param {*} method
   * @param {string} url
   * @param {Error} error
   */
  logError(method, url, error) {} // eslint-disable-line no-unused-vars
}

/**
 * @property {?function(new:RequestLogger, number)} _requestLoggerClass
 * @property {number} _nextId
 */
export class RpcLoggerFactory {
  /**
   * @param {function(new:RequestLogger, number)} requestLoggerClass
   */
  constructor(requestLoggerClass) {
    this._nextId = 0;
    this._requestLoggerClass = requestLoggerClass;
  }

  build() {
    return new this._requestLoggerClass(this._nextId++);
  }
}

// https://material.io/guidelines/style/color.html#color-color-palette
const COLORS = [
  '#F44336', // red
  '#E91E63', // pink
  '#9C27B0', // purple
  '#673AB7', // deep purple
  '#3F51B5', // indigo
  '#2196F3', // blue
  '#03A9F4', // light blue
  '#00BCD4', // cyan
  '#009688', // teal
  '#4CAF50', // green
  '#8BC34A', // light green
  '#CDDC39', // lime
  '#FFEB3B', // yellow
  '#FFC107', // amber
  '#FF9800', // orange
  '#FF5722', // deep orange
  '#795548', // brown
  '#9E9E9E', // grey
  '#607D8B' // blue grey
];

/**
 * @property {Array<string>} [colors=COLORS]
 */
export class ConsoleRequestLogger extends RequestLogger {
  /**
   * @param {number} id
   * @param {Array<string>=} colors
   * @param {Console} console
   */
  constructor(id, colors = undefined, console = window.console) {
    super(id);

    this.colors = colors || COLORS;

    this.console = console;
  }

  /**
   * @returns {string}
   */
  getColor() {
    return this.colors[this.id % this.colors.length];
  }

  /**
   * @param {*} method
   * @param {string} url
   * @param {Uint8Array} requestData
   */
  logRequest(method, url, requestData) {
    const { resolvedRequestType } = method.resolve();

    this.console.groupCollapsed(
      `%cRPC#${this.id} ↑ %c${url}`,
      'color:blue',
      `color:${this.getColor()}`
    );
    this.console.log(resolvedRequestType.decode(requestData));
    this.console.groupEnd();
  }

  /**
   * @param {*} method
   * @param {string} url
   * @param {Uint8Array} responseData
   */
  logResponse(method, url, responseData) {
    const { resolvedResponseType } = method.resolve();

    this.console.groupCollapsed(
      `%cRPC#${this.id} ↓ %c${url}`,
      'color:green',
      `color:${this.getColor()}`
    );
    this.console.log(resolvedResponseType.decode(responseData));
    this.console.groupEnd();
  }

  /**
   * @param {*} method
   * @param {string} url
   * @param {Error} error
   */
  logError(method, url, error) {
    this.console.groupCollapsed(
      `%cRPC#${this.id} ↓ %c${url}`,
      'color:red',
      `color:${this.getColor()}`
    );
    this.console.log(error);
    this.console.groupEnd();

    Sentry.captureException(error);
  }
}

export class SilentRequestLogger extends RequestLogger {}

export class ConsoleLoggerFactory extends RpcLoggerFactory {
  /**
   * @param {boolean} enabled
   */
  constructor(enabled) {
    super(enabled ? ConsoleRequestLogger : SilentRequestLogger);
  }
}
