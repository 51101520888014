import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';

const OBA_PRIMARY_PALETTE = {
  main: 'rgba(0, 106, 255)',
  light: 'rgba(0, 106, 255, 0.1)',
};

const deriveTheme = () =>
  createMuiTheme({
    palette: {
      primary: OBA_PRIMARY_PALETTE,
      secondary: {
        main: '#71767B' /* Neutral / Gray 500 */,
        light: '#F1F1F2',
      },
      error: {
        main: '#D92B2B' /* System/Red 200 */,
      },
      text: {
        primary: '#343B42' /* Neutral / Gray 600 */,
        secondary: '#71767B' /* Neutral / Gray 500 */,
      },
    },
    typography: {
      fontFamily: 'Square Market',
      h1: {
        fontSize: 30,
        fontWeight: 500,
        lineHeight: '36px',
      },
      h3: {
        fontSize: 20,
        lineHeight: '28px',
        fontWeight: 500,
      },
      h4: {
        fontSize: 18,
        lineHeight: '28px',
        fontWeight: 500,
      },
      h5: {
        fontSize: 16,
        fontWeight: 500,
      },
      h6: {
        fontSize: 14,
        fontWeight: 500,
      },
      caption: {
        fontSize: 16,
      },
      body1: {
        fontSize: 16,
        lineHeight: '24px',
        fontWeight: 300,
        color: '#71767B',
      },
      subtitle1: {
        fontSize: 16,
        lineHeight: '24px',
        fontWeight: 300,
        color: '#71767B',
      },
      subtitle2: {
        fontSize: 14,
        fontWeight: 300,
        color: '#71767B',
        lineHeight: '24px',
      },
    },
    props: {
      MuiButton: {
        disableRipple: true,
      },
    },
    overrides: {
      MuiButton: {
        root: {
          textTransform: 'none',
        },
        label: {
          fontSize: 16,
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: OBA_PRIMARY_PALETTE.main,
          },
          '&:active': {
            boxShadow: 'none',
          },
        },
        text: {
          fontWeight: 300,
        },
        textPrimary: {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
      MuiTypography: {
        h3: {
          marginBottom: 8,
        },
      },
      MuiPaper: {
        rounded: {
          borderRadius: 6,
        },
        elevation1: {
          width: 688,
          boxShadow: 'none',
          marginBottom: 32,
          '@media (max-width:960px)': {
            width: 345,
            marginBottom: 16,
          },
        },
      },
      MuiSnackbar: {
        root: {
          borderRadius: 6,
        },
      },
      MuiSnackbarContent: {
        root: {
          fontSize: 16,
          fontWeight: 500,
          color: '#343B42',
          backgroundColor: '#E5F6E5',
        },
      },
      MuiDialog: {
        paperWidthSm: {
          '@media (min-width:960px)': {
            width: 512,
          },
        },
      },
      MuiDialogContent: {
        root: {
          paddingLeft: 16,
          paddingRight: 16,
          '@media (min-width:960px)': {
            paddingLeft: 56,
            paddingRight: 56,
            paddingBottom: 56,
          },
        },
      },
      MuiDialogTitle: {
        root: {
          padding: 16,
        },
      },
      MuiSvgIcon: {
        fontSizeLarge: {
          fontSize: 40,
        },
        fontSizeSmall: {
          fontSize: 16,
        },
      },
      MuiLink: {
        underlineHover: {
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'none',
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          '&$disabled': {
            backgroundColor: '#F5F6F7',
          },
        },
      },
    },
  });

const Theme: React.FC = (props) => {
  const theme = deriveTheme();
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

export default Theme;
