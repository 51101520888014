import React, { useCallback, useEffect, useMemo } from 'react';
import {
  MarketActivityIndicator,
  MarketButton,
  MarketDivider,
} from '@market/react';
import { useTranslation } from 'react-i18next';
import LoyaltyProgramOverview from '../components/LoyaltyProgramOverview';
import ClaimablePointsDescription from '../components/ClaimablePointsDescription';
import { Redirect, useParams } from 'react-router-dom';
import { LoyaltyClaimPointsRouteParams } from '../routeUtils';
import { useRetrieveClaimablePointsQuery } from 'store/query/api-extensions/loyalty';
import { useDispatch, useSelector } from 'react-redux';
import { openToast } from 'store/toastSlice';
import { selectPointsTerminology } from '../loyaltySlice';
import { AppState } from 'store';
import useNavigateToLoyaltyOnboarding from 'utils/custom-react-hooks/loyalty/useNavigateToLoyaltyOnboarding';
import { ClaimablePoints } from 'rpc/model/squareup/card/balance/loyalty_api/model/claimable-points';
import { useTrackLoyaltyEvent } from 'utils/custom-react-hooks/loyalty/useTrackLoyaltyEvent';
import { EventName, FeatureFormat } from 'services/tracking/cdp/events/types';

const ClaimPointsPage = () => {
  const { t } = useTranslation();
  const { claimablePointsToken, merchantId } =
    useParams<LoyaltyClaimPointsRouteParams>();
  const claimablePointsResult =
    useRetrieveClaimablePointsQuery(claimablePointsToken);
  const dispatch = useDispatch();
  const pointsTerminology = useSelector((state: AppState) =>
    selectPointsTerminology(state)
  );
  const isLoading =
    claimablePointsResult.isLoading || claimablePointsResult.isUninitialized;
  const claimablePoints = claimablePointsResult.data;
  const hasClaimablePoints = Boolean(claimablePoints);
  const claimablePointsDescription = useMemo(() => {
    if (isLoading) {
      return <MarketActivityIndicator />;
    }

    if (!hasClaimablePoints) {
      return <Redirect to={`/merchantportal/${merchantId}/loyalty`} />;
    }

    return <ClaimablePointsDescription claimablePoints={claimablePoints} />;
  }, [claimablePoints, hasClaimablePoints, isLoading, merchantId]);
  useEffect(() => {
    if (!isLoading && !hasClaimablePoints) {
      dispatch(
        openToast({
          message: t('loyalty.claimablePointsDescription.expired', {
            pointsTerminology: pointsTerminology?.other,
          }),
        })
      );
    }
  }, [dispatch, hasClaimablePoints, isLoading, pointsTerminology, t]);
  const navigateToLoyaltyOnboarding = useNavigateToLoyaltyOnboarding();
  const claimPoints = useCallback(() => {
    navigateToLoyaltyOnboarding({
      state: {
        claimablePointsToken,
        isTosConsentOptional: !claimablePoints?.requiresTosConsent,
      },
    });
  }, [
    claimablePoints?.requiresTosConsent,
    claimablePointsToken,
    navigateToLoyaltyOnboarding,
  ]);

  const trackLoyaltyEvent = useTrackLoyaltyEvent();
  useEffect(() => {
    if (hasClaimablePoints) {
      trackLoyaltyEvent(EventName.VIEW_FEATURE, {
        event_description:
          claimablePoints?.reason === ClaimablePoints.Reason.EMAIL_IMPORT
            ? 'View sign up page to transfer points'
            : 'View sign up page to claim points',
        feature_format: FeatureFormat.PAGE,
      });
    }
  }, [claimablePoints?.reason, hasClaimablePoints, trackLoyaltyEvent]);

  return (
    <div className="grid">
      <p className="text-center mt-[-28px] mb-8">
        {claimablePointsDescription}
      </p>
      <MarketButton onClick={claimPoints} rank="primary">
        {t('loyalty.program.enroll.button.label')}
      </MarketButton>
      <MarketDivider margin="large" />
      <LoyaltyProgramOverview />
    </div>
  );
};

export default ClaimPointsPage;
