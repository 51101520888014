/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import '../../customers/request';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      buyerportal: {
        nested: {
          profile: {
            nested: {
              DeleteProfileRequest: {
                fields: {
                  idempotencyKey: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                },
              },
              DeleteProfileResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                },
              },
              ExportProfileRequest: {
                fields: {
                  idempotencyKey: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  requestorEmail: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                },
              },
              ExportProfileResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.profile?.nested
    ?.DeleteProfileRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.profile?.nested
    ?.DeleteProfileResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.profile?.nested
    ?.ExportProfileRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.profile?.nested
    ?.ExportProfileResponse
) {
  $root.addJSON(jsonData);
}

/**
 * Privacy related protos for Buyer Portal Service RPC Request-Responses
 * Request to delete profile and associated data of buyer in current authenticated multipass session
 * to comply with privacy laws like CCPA/GDPR.
 *
 * SOURCE DeleteProfileRequest @ squareup/buyerportal/profile/privacy.proto at 18:1
 */
export const DeleteProfileRequest = $root.lookupType(
  'squareup.buyerportal.profile.DeleteProfileRequest'
);
fixType(DeleteProfileRequest, {
  idempotencyKey: { required: true },
});
Builder.createAndAttachToType(DeleteProfileRequest);
/**
 * Response containing status of delete profile request and errors if any that occurred while
 * processing the input request.
 *
 * SOURCE DeleteProfileResponse @ squareup/buyerportal/profile/privacy.proto at 27:1
 */
export const DeleteProfileResponse = $root.lookupType(
  'squareup.buyerportal.profile.DeleteProfileResponse'
);
fixType(DeleteProfileResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(DeleteProfileResponse);
/**
 * Request to export profile and associated PII data of buyer in current authenticated multipass
 * session to comply with privacy laws like CCPA/GDPR.
 *
 * SOURCE ExportProfileRequest @ squareup/buyerportal/profile/privacy.proto at 39:1
 */
export const ExportProfileRequest = $root.lookupType(
  'squareup.buyerportal.profile.ExportProfileRequest'
);
fixType(ExportProfileRequest, {
  idempotencyKey: { required: true },
  requestorEmail: { required: true },
});
Builder.createAndAttachToType(ExportProfileRequest);
/**
 * Response containing status of export profile request and errors if any that occurred while
 * processing the input request.
 *
 * SOURCE ExportProfileResponse @ squareup/buyerportal/profile/privacy.proto at 51:1
 */
export const ExportProfileResponse = $root.lookupType(
  'squareup.buyerportal.profile.ExportProfileResponse'
);
fixType(ExportProfileResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(ExportProfileResponse);
