import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LogoIcon } from 'svgs';
import { MarketButton, MarketInputText } from '@market/react';
import { v4 as uuidv4 } from 'uuid';
import Es2Tracker from 'services/tracking/tracker';
import { IdentifierType } from 'routes/profile/models/Identifier';
import {
  signInViewEvent,
  signInRequestVerifyActionEvent,
} from 'services/tracking/events/signIn';
import client from 'rpc/client';
import { RequestStatus } from 'rpc/model/squareup/customers/request';
import { SignInIdentifier, SignInIdentifierType } from './types';
import {
  CreateLoginOrSignupVerificationRequest,
  ICreateLoginOrSignupVerificationRequest,
} from 'rpc/model/squareup/buyerportal/onboarding/data';
import nonTranslatableStrings from 'utils/nonTranslatableStrings';

interface LockedPhoneSignInProps {
  // TODO: May not be obfuscated
  obfuscatedPhoneIdentifier: SignInIdentifier;
  onForward: (value: string, type: SignInIdentifierType) => void;
  onUnexpectedError: (isCatastrophic?: boolean) => void;
  onSignInToDifferentAccount: () => void;
}

const LockedPhoneSignIn: React.FC<LockedPhoneSignInProps> = ({
  obfuscatedPhoneIdentifier,
  ...props
}) => {
  const { t } = useTranslation();

  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  useEffect(() => {
    Es2Tracker.track(signInViewEvent(IdentifierType.Phone));
  }, []);

  const sendCode = async () => {
    try {
      setIsProcessing(true);
      Es2Tracker.track(signInRequestVerifyActionEvent(IdentifierType.Phone));

      const parameters: ICreateLoginOrSignupVerificationRequest = {
        idempotencyKey: uuidv4(),
        verificationCredential: {
          phoneNumberId: obfuscatedPhoneIdentifier.id!,
        },
      };

      const response = await client.createLoginOrSignupVerification(
        CreateLoginOrSignupVerificationRequest.create(parameters)
      );
      if (response.status !== RequestStatus.STATUS_SUCCESS) {
        throw new Error();
      }

      props.onForward(obfuscatedPhoneIdentifier.id!, IdentifierType.Phone);
    } catch {
      props.onUnexpectedError();
    } finally {
      setIsProcessing(false);
    }
  };

  const handleSignInToDifferentAccount = () => {
    // TODO (mraasch): New event for sign in to different profile
    // Es2Tracker.track();
    props.onSignInToDifferentAccount();
  };

  return (
    <>
      <LogoIcon className={'text-[32px]'} />
      <h2 className={'mt-7 mb-4 heading-30'}>
        {t('onboarding.signIn.title', {
          SQUARE: nonTranslatableStrings.square,
        })}
      </h2>
      <p className={'m-0 mb-4 paragraph-30'}>
        {t('onboarding.signIn.subtitle.phone', {
          SQUARE: nonTranslatableStrings.square,
        })}
      </p>
      <MarketInputText
        className="obfuscated-phone-input-market-wrapper mb-6"
        disabled
        value={obfuscatedPhoneIdentifier.value}
      >
        <label className="obfuscated-phone-label">
          {t('common.phoneNumber')}
        </label>
        <input
          className="obfuscated-phone-input"
          slot={'input'}
          data-testid={'phone-input'}
        />
      </MarketInputText>
      <MarketButton
        rank={'primary'}
        disabled={isProcessing}
        onClick={() => sendCode()}
      >
        {t('common.sendCode')}
      </MarketButton>
      <MarketButton
        rank={'tertiary'}
        {...(isProcessing && { disabled: true })}
        onClick={() => handleSignInToDifferentAccount()}
      >
        {/* TODO: Add this translation string */}
        {t('onboarding.lockedPhone.signInToDifferentAccount')}
      </MarketButton>
    </>
  );
};

export default LockedPhoneSignIn;
