import React from 'react';
import { useTranslation } from 'react-i18next';
import { LogoIcon } from 'svgs';
import nonTranslatableStrings from 'utils/nonTranslatableStrings';

const grey = 'rgba(0,0,0,0.55)';

type SecuredBySquareProps = {
  className?: string;
};

const SecuredBySquare: React.FC<SecuredBySquareProps> = (props) => {
  const { t } = useTranslation();

  return (
    <span className={`flex items-center ${props.className}`}>
      <LogoIcon className={'w-4 h-4'} htmlColor={grey} />
      <p className={'m-0 ml-[7px] medium-20'}>
        {t('cashenroll.securedBy', {
          companyName: nonTranslatableStrings.square,
        })}
      </p>
    </span>
  );
};

export default SecuredBySquare;
