/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import './model';
import './service';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      privacyvault: {
        nested: {
          VaultedData: {
            fields: {
              entries: {
                type: 'squareup.privacyvault.VaultedDataEntry',
                id: 3,
                rule: 'repeated',
              },
            },
          },
          VaultedDataEntry: {
            fields: {
              entity: {
                type: 'squareup.privacyvault.model.Entity',
                id: 1,
                rule: 'optional',
              },
              vaultedFields: {
                type: 'squareup.privacyvault.service.Protected',
                id: 2,
                keyType: 'string',
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.privacyvault?.nested?.VaultedData &&
  !$root.nested?.squareup?.nested?.privacyvault?.nested?.VaultedDataEntry
) {
  $root.addJSON(jsonData);
}

/**
 * Container of vaulted data that can repeat to support multiple entities used to derive fields.
 *
 * SOURCE VaultedData @ squareup/privacyvault/annotations.proto at 16:1
 */
export const VaultedData = $root.lookupType(
  'squareup.privacyvault.VaultedData'
);
fixType(VaultedData);
Builder.createAndAttachToType(VaultedData);
/**
 * Vaulted data entry created when vaulting occurs. Contains metadata related to the vaulting and
 * map entries to include individual vaulted fields. VaultedDataEntry will be unique per entity
 * (like map<Entity, ...> if we could use that).
 *
 * SOURCE VaultedDataEntry @ squareup/privacyvault/annotations.proto at 29:1
 */
export const VaultedDataEntry = $root.lookupType(
  'squareup.privacyvault.VaultedDataEntry'
);
fixType(VaultedDataEntry);
Builder.createAndAttachToType(VaultedDataEntry);
