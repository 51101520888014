/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import './data';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      buyerportal: {
        nested: {
          merchantportal: {
            nested: {
              MerchantPortalService: {
                methods: {
                  GetMerchantPortalBaseData: {
                    requestType:
                      'squareup.buyerportal.merchantportal.GetMerchantPortalBaseDataRequest',
                    responseType:
                      'squareup.buyerportal.merchantportal.GetMerchantPortalBaseDataResponse',
                  },
                  GetVisitedMerchants: {
                    requestType:
                      'squareup.buyerportal.merchantportal.GetVisitedMerchantsRequest',
                    responseType:
                      'squareup.buyerportal.merchantportal.GetVisitedMerchantsResponse',
                  },
                  GetMerchantLocations: {
                    requestType:
                      'squareup.buyerportal.merchantportal.GetMerchantLocationsRequest',
                    responseType:
                      'squareup.buyerportal.merchantportal.GetMerchantLocationsResponse',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.MerchantPortalService
) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE MerchantPortalService @ squareup/buyerportal/merchantportal/service.proto at 12:1
 */
export const MerchantPortalService = $root.lookupService(
  'squareup.buyerportal.merchantportal.MerchantPortalService'
);
