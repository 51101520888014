export type LoadingState = {
  isLoaded: boolean;
  isLoading: boolean;
};

const INITIAL: LoadingState = {
  isLoaded: false,
  isLoading: false,
};

const LOADED: LoadingState = {
  isLoaded: true,
  isLoading: false,
};

const LOADING: LoadingState = {
  isLoaded: false,
  isLoading: true,
};

// Pseudo-enum because TS only supports string or numeric enums.
// Can convert to class if type is needed.
export const LOADING_STATES = {
  INITIAL,
  LOADED,
  LOADING,
};
