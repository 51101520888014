/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import '../common/time';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      receipts: {
        nested: {
          data: {
            nested: {
              Receipt: {
                fields: {
                  token: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  merchantToken: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  paymentToken: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  emailId: {
                    type: 'string',
                    id: 4,
                    rule: 'optional',
                  },
                  createdAt: {
                    type: 'squareup.common.time.DateTime',
                    id: 5,
                    rule: 'optional',
                  },
                  emailAddress: {
                    type: 'string',
                    id: 6,
                    rule: 'optional',
                  },
                  phoneId: {
                    type: 'string',
                    id: 7,
                    rule: 'optional',
                  },
                  phoneNumber: {
                    type: 'string',
                    id: 8,
                    rule: 'optional',
                  },
                  saleAt: {
                    type: 'squareup.common.time.DateTime',
                    id: 9,
                    rule: 'optional',
                  },
                  requestedAt: {
                    type: 'squareup.common.time.DateTime',
                    id: 10,
                    rule: 'optional',
                  },
                  updatedAt: {
                    type: 'squareup.common.time.DateTime',
                    id: 11,
                    rule: 'optional',
                  },
                  state: {
                    type: 'squareup.receipts.data.Receipt.State',
                    id: 12,
                    rule: 'optional',
                  },
                  preferredLanguageCode: {
                    type: 'string',
                    id: 13,
                    rule: 'optional',
                  },
                  manuallyEnteredEmail: {
                    type: 'bool',
                    id: 14,
                    rule: 'optional',
                  },
                  manuallyEnteredPhone: {
                    type: 'bool',
                    id: 15,
                    rule: 'optional',
                  },
                  sendNumber: {
                    type: 'int32',
                    id: 16,
                    rule: 'optional',
                  },
                },
                nested: {
                  State: {
                    values: {
                      CREATED: 0,
                      ENQUEUED: 1,
                      SENDING: 2,
                      SENT: 3,
                      RENDERED: 4,
                      IGNORED: 5,
                      FAILED: 6,
                      DELIVERY_VERIFIED: 7,
                      BACKFILLED: 8,
                      INVALID: 9,
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.receipts?.nested?.data?.nested?.Receipt &&
  !$root.nested?.squareup?.nested?.receipts?.nested?.data?.nested?.Receipt
    ?.nested?.State
) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE Receipt @ squareup/receipts/data.proto at 43:1
 */
export const Receipt = $root.lookupType('squareup.receipts.data.Receipt');
fixType(Receipt);
Builder.createAndAttachToType(Receipt);
/**
 * SOURCE State @ squareup/receipts/data.proto at 66:3
 */
Receipt.State = $root.lookupEnum('squareup.receipts.data.Receipt.State').values;
