import React, { useEffect } from 'react';
import { parseGenericParameters } from './util/parameters';
import { useHistory, useLocation } from 'react-router-dom';
import { isRelativeUrl } from 'utils/url';

const PostAuthRedirect: React.FC = () => {
  const history = useHistory();
  const rawParams = new URLSearchParams(useLocation().search);
  const parsedGenericParams = parseGenericParameters(rawParams);

  useEffect(() => {
    const redirectToUrl = parsedGenericParams.returnTo || '/';

    if (isRelativeUrl(redirectToUrl)) {
      history.push(redirectToUrl);
    } else {
      window.location.assign(encodeURI(redirectToUrl));
    }
  });

  return <></>;
};

export default PostAuthRedirect;
