import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { RequestStatus } from 'rpc/model/squareup/customers/request';

const GENERIC_ERROR = {
  errors: [],
  status: RequestStatus.STATUS_INTERNAL_ERROR,
};

export const getResponseError = (
  response: {
    errors: readonly { details: string }[];
    status: RequestStatus;
  } = GENERIC_ERROR
) => {
  return {
    error: {
      data: {
        message:
          response.errors.length > 0
            ? response.errors.map((e) => e.details).join('; ')
            : 'Something went wrong.',
      },
      status: response.status,
    },
  };
};

export const callApi = <T>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callFn: () => Promise<any>
): MaybePromise<QueryReturnValue<T>> => {
  return new Promise((res, rej) => {
    callFn()
      .then((result: T) => res({ data: result }))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((ex: any) =>
        rej(
          getResponseError({
            errors: [ex],
            status: RequestStatus.STATUS_INTERNAL_ERROR,
          })
        )
      );
  });
};

export const delay = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
