/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../Fixes';
import Builder from '../../../../Builder';
import '../../../../google/protobuf/struct';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  grpc: {
    nested: {
      gateway: {
        nested: {
          protoc_gen_openapiv2: {
            nested: {
              options: {
                nested: {
                  Scheme: {
                    values: {
                      UNKNOWN: 0,
                      HTTP: 1,
                      HTTPS: 2,
                      WS: 3,
                      WSS: 4,
                    },
                  },
                  Swagger: {
                    fields: {
                      swagger: {
                        type: 'string',
                        id: 1,
                      },
                      info: {
                        type: 'grpc.gateway.protoc_gen_openapiv2.options.Info',
                        id: 2,
                      },
                      host: {
                        type: 'string',
                        id: 3,
                      },
                      basePath: {
                        type: 'string',
                        id: 4,
                      },
                      schemes: {
                        type: 'grpc.gateway.protoc_gen_openapiv2.options.Scheme',
                        id: 5,
                        rule: 'repeated',
                      },
                      consumes: {
                        type: 'string',
                        id: 6,
                        rule: 'repeated',
                      },
                      produces: {
                        type: 'string',
                        id: 7,
                        rule: 'repeated',
                      },
                      responses: {
                        type: 'grpc.gateway.protoc_gen_openapiv2.options.Response',
                        id: 10,
                        keyType: 'string',
                      },
                      securityDefinitions: {
                        type: 'grpc.gateway.protoc_gen_openapiv2.options.SecurityDefinitions',
                        id: 11,
                      },
                      security: {
                        type: 'grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement',
                        id: 12,
                        rule: 'repeated',
                      },
                      tags: {
                        type: 'grpc.gateway.protoc_gen_openapiv2.options.Tag',
                        id: 13,
                        rule: 'repeated',
                      },
                      externalDocs: {
                        type: 'grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation',
                        id: 14,
                      },
                      extensions: {
                        type: 'google.protobuf.Value',
                        id: 15,
                        keyType: 'string',
                      },
                    },
                  },
                  Header: {
                    fields: {
                      description: {
                        type: 'string',
                        id: 1,
                      },
                      type: {
                        type: 'string',
                        id: 2,
                      },
                      format: {
                        type: 'string',
                        id: 3,
                      },
                      default: {
                        type: 'string',
                        id: 6,
                      },
                      pattern: {
                        type: 'string',
                        id: 13,
                      },
                    },
                  },
                  Response: {
                    fields: {
                      description: {
                        type: 'string',
                        id: 1,
                      },
                      schema: {
                        type: 'grpc.gateway.protoc_gen_openapiv2.options.Schema',
                        id: 2,
                      },
                      headers: {
                        type: 'grpc.gateway.protoc_gen_openapiv2.options.Header',
                        id: 3,
                        keyType: 'string',
                      },
                      examples: {
                        type: 'string',
                        id: 4,
                        keyType: 'string',
                      },
                      extensions: {
                        type: 'google.protobuf.Value',
                        id: 5,
                        keyType: 'string',
                      },
                    },
                  },
                  Info: {
                    fields: {
                      title: {
                        type: 'string',
                        id: 1,
                      },
                      description: {
                        type: 'string',
                        id: 2,
                      },
                      termsOfService: {
                        type: 'string',
                        id: 3,
                      },
                      contact: {
                        type: 'grpc.gateway.protoc_gen_openapiv2.options.Contact',
                        id: 4,
                      },
                      license: {
                        type: 'grpc.gateway.protoc_gen_openapiv2.options.License',
                        id: 5,
                      },
                      version: {
                        type: 'string',
                        id: 6,
                      },
                      extensions: {
                        type: 'google.protobuf.Value',
                        id: 7,
                        keyType: 'string',
                      },
                    },
                  },
                  Contact: {
                    fields: {
                      name: {
                        type: 'string',
                        id: 1,
                      },
                      url: {
                        type: 'string',
                        id: 2,
                      },
                      email: {
                        type: 'string',
                        id: 3,
                      },
                    },
                  },
                  License: {
                    fields: {
                      name: {
                        type: 'string',
                        id: 1,
                      },
                      url: {
                        type: 'string',
                        id: 2,
                      },
                    },
                  },
                  ExternalDocumentation: {
                    fields: {
                      description: {
                        type: 'string',
                        id: 1,
                      },
                      url: {
                        type: 'string',
                        id: 2,
                      },
                    },
                  },
                  Schema: {
                    fields: {
                      jsonSchema: {
                        type: 'grpc.gateway.protoc_gen_openapiv2.options.JSONSchema',
                        id: 1,
                      },
                      discriminator: {
                        type: 'string',
                        id: 2,
                      },
                      readOnly: {
                        type: 'bool',
                        id: 3,
                      },
                      externalDocs: {
                        type: 'grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation',
                        id: 5,
                      },
                      example: {
                        type: 'string',
                        id: 6,
                      },
                    },
                  },
                  JSONSchema: {
                    fields: {
                      ref: {
                        type: 'string',
                        id: 3,
                      },
                      title: {
                        type: 'string',
                        id: 5,
                      },
                      description: {
                        type: 'string',
                        id: 6,
                      },
                      default: {
                        type: 'string',
                        id: 7,
                      },
                      readOnly: {
                        type: 'bool',
                        id: 8,
                      },
                      example: {
                        type: 'string',
                        id: 9,
                      },
                      multipleOf: {
                        type: 'double',
                        id: 10,
                      },
                      maximum: {
                        type: 'double',
                        id: 11,
                      },
                      exclusiveMaximum: {
                        type: 'bool',
                        id: 12,
                      },
                      minimum: {
                        type: 'double',
                        id: 13,
                      },
                      exclusiveMinimum: {
                        type: 'bool',
                        id: 14,
                      },
                      maxLength: {
                        type: 'uint64',
                        id: 15,
                      },
                      minLength: {
                        type: 'uint64',
                        id: 16,
                      },
                      pattern: {
                        type: 'string',
                        id: 17,
                      },
                      maxItems: {
                        type: 'uint64',
                        id: 20,
                      },
                      minItems: {
                        type: 'uint64',
                        id: 21,
                      },
                      uniqueItems: {
                        type: 'bool',
                        id: 22,
                      },
                      maxProperties: {
                        type: 'uint64',
                        id: 24,
                      },
                      minProperties: {
                        type: 'uint64',
                        id: 25,
                      },
                      required: {
                        type: 'string',
                        id: 26,
                        rule: 'repeated',
                      },
                      array: {
                        type: 'string',
                        id: 34,
                        rule: 'repeated',
                      },
                      type: {
                        type: 'grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.JSONSchemaSimpleTypes',
                        id: 35,
                        rule: 'repeated',
                      },
                      format: {
                        type: 'string',
                        id: 36,
                      },
                      enum: {
                        type: 'string',
                        id: 46,
                        rule: 'repeated',
                      },
                      fieldConfiguration: {
                        type: 'grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.FieldConfiguration',
                        id: 1001,
                      },
                      extensions: {
                        type: 'google.protobuf.Value',
                        id: 48,
                        keyType: 'string',
                      },
                    },
                    nested: {
                      JSONSchemaSimpleTypes: {
                        values: {
                          UNKNOWN: 0,
                          ARRAY: 1,
                          BOOLEAN: 2,
                          INTEGER: 3,
                          NULL: 4,
                          NUMBER: 5,
                          OBJECT: 6,
                          STRING: 7,
                        },
                      },
                      FieldConfiguration: {
                        fields: {
                          pathParamName: {
                            type: 'string',
                            id: 47,
                          },
                        },
                      },
                    },
                  },
                  Tag: {
                    fields: {
                      name: {
                        type: 'string',
                        id: 1,
                      },
                      description: {
                        type: 'string',
                        id: 2,
                      },
                      externalDocs: {
                        type: 'grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation',
                        id: 3,
                      },
                      extensions: {
                        type: 'google.protobuf.Value',
                        id: 4,
                        keyType: 'string',
                      },
                    },
                  },
                  SecurityDefinitions: {
                    fields: {
                      security: {
                        type: 'grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme',
                        id: 1,
                        keyType: 'string',
                      },
                    },
                  },
                  SecurityScheme: {
                    fields: {
                      type: {
                        type: 'grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.Type',
                        id: 1,
                      },
                      description: {
                        type: 'string',
                        id: 2,
                      },
                      name: {
                        type: 'string',
                        id: 3,
                      },
                      in: {
                        type: 'grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.In',
                        id: 4,
                      },
                      flow: {
                        type: 'grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.Flow',
                        id: 5,
                      },
                      authorizationUrl: {
                        type: 'string',
                        id: 6,
                      },
                      tokenUrl: {
                        type: 'string',
                        id: 7,
                      },
                      scopes: {
                        type: 'grpc.gateway.protoc_gen_openapiv2.options.Scopes',
                        id: 8,
                      },
                      extensions: {
                        type: 'google.protobuf.Value',
                        id: 9,
                        keyType: 'string',
                      },
                    },
                    nested: {
                      Type: {
                        values: {
                          TYPE_INVALID: 0,
                          TYPE_BASIC: 1,
                          TYPE_API_KEY: 2,
                          TYPE_OAUTH2: 3,
                        },
                      },
                      In: {
                        values: {
                          IN_INVALID: 0,
                          IN_QUERY: 1,
                          IN_HEADER: 2,
                        },
                      },
                      Flow: {
                        values: {
                          FLOW_INVALID: 0,
                          FLOW_IMPLICIT: 1,
                          FLOW_PASSWORD: 2,
                          FLOW_APPLICATION: 3,
                          FLOW_ACCESS_CODE: 4,
                        },
                      },
                    },
                  },
                  SecurityRequirement: {
                    fields: {
                      securityRequirement: {
                        type: 'grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue',
                        id: 1,
                        keyType: 'string',
                      },
                    },
                    nested: {
                      SecurityRequirementValue: {
                        fields: {
                          scope: {
                            type: 'string',
                            id: 1,
                            rule: 'repeated',
                          },
                        },
                      },
                    },
                  },
                  Scopes: {
                    fields: {
                      scope: {
                        type: 'string',
                        id: 1,
                        keyType: 'string',
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.grpc?.nested?.gateway?.nested?.protoc_gen_openapiv2?.nested
    ?.options?.nested?.Scheme &&
  !$root.nested?.grpc?.nested?.gateway?.nested?.protoc_gen_openapiv2?.nested
    ?.options?.nested?.Swagger &&
  !$root.nested?.grpc?.nested?.gateway?.nested?.protoc_gen_openapiv2?.nested
    ?.options?.nested?.Header &&
  !$root.nested?.grpc?.nested?.gateway?.nested?.protoc_gen_openapiv2?.nested
    ?.options?.nested?.Response &&
  !$root.nested?.grpc?.nested?.gateway?.nested?.protoc_gen_openapiv2?.nested
    ?.options?.nested?.Info &&
  !$root.nested?.grpc?.nested?.gateway?.nested?.protoc_gen_openapiv2?.nested
    ?.options?.nested?.Contact &&
  !$root.nested?.grpc?.nested?.gateway?.nested?.protoc_gen_openapiv2?.nested
    ?.options?.nested?.License &&
  !$root.nested?.grpc?.nested?.gateway?.nested?.protoc_gen_openapiv2?.nested
    ?.options?.nested?.ExternalDocumentation &&
  !$root.nested?.grpc?.nested?.gateway?.nested?.protoc_gen_openapiv2?.nested
    ?.options?.nested?.Schema &&
  !$root.nested?.grpc?.nested?.gateway?.nested?.protoc_gen_openapiv2?.nested
    ?.options?.nested?.JSONSchema &&
  !$root.nested?.grpc?.nested?.gateway?.nested?.protoc_gen_openapiv2?.nested
    ?.options?.nested?.JSONSchema?.nested?.JSONSchemaSimpleTypes &&
  !$root.nested?.grpc?.nested?.gateway?.nested?.protoc_gen_openapiv2?.nested
    ?.options?.nested?.JSONSchema?.nested?.FieldConfiguration &&
  !$root.nested?.grpc?.nested?.gateway?.nested?.protoc_gen_openapiv2?.nested
    ?.options?.nested?.Tag &&
  !$root.nested?.grpc?.nested?.gateway?.nested?.protoc_gen_openapiv2?.nested
    ?.options?.nested?.SecurityDefinitions &&
  !$root.nested?.grpc?.nested?.gateway?.nested?.protoc_gen_openapiv2?.nested
    ?.options?.nested?.SecurityScheme &&
  !$root.nested?.grpc?.nested?.gateway?.nested?.protoc_gen_openapiv2?.nested
    ?.options?.nested?.SecurityScheme?.nested?.Type &&
  !$root.nested?.grpc?.nested?.gateway?.nested?.protoc_gen_openapiv2?.nested
    ?.options?.nested?.SecurityScheme?.nested?.In &&
  !$root.nested?.grpc?.nested?.gateway?.nested?.protoc_gen_openapiv2?.nested
    ?.options?.nested?.SecurityScheme?.nested?.Flow &&
  !$root.nested?.grpc?.nested?.gateway?.nested?.protoc_gen_openapiv2?.nested
    ?.options?.nested?.SecurityRequirement &&
  !$root.nested?.grpc?.nested?.gateway?.nested?.protoc_gen_openapiv2?.nested
    ?.options?.nested?.SecurityRequirement?.nested?.SecurityRequirementValue &&
  !$root.nested?.grpc?.nested?.gateway?.nested?.protoc_gen_openapiv2?.nested
    ?.options?.nested?.Scopes
) {
  $root.addJSON(jsonData);
}

/**
 * Scheme describes the schemes supported by the OpenAPI Swagger
 * and Operation objects.
 *
 * SOURCE Scheme @ grpc/gateway/protoc_gen_openapiv2/options/openapiv2.proto at 11:1
 */
export const Scheme = $root.lookupEnum(
  'grpc.gateway.protoc_gen_openapiv2.options.Scheme'
).values;
/**
 * `Swagger` is a representation of OpenAPI v2 specification's Swagger object.
 *
 * See: https://github.com/OAI/OpenAPI-Specification/blob/3.0.0/versions/2.0.md#swaggerObject
 *
 * Example:
 *
 *  option (grpc.gateway.protoc_gen_openapiv2.options.openapiv2_swagger) = {
 *    info: {
 *      title: "Echo API";
 *      version: "1.0";
 *      description: "";
 *      contact: {
 *        name: "gRPC-Gateway project";
 *        url: "https://github.com/grpc-ecosystem/grpc-gateway";
 *        email: "none@example.com";
 *      };
 *      license: {
 *        name: "BSD 3-Clause License";
 *        url: "https://github.com/grpc-ecosystem/grpc-gateway/blob/main/LICENSE";
 *      };
 *    };
 *    schemes: HTTPS;
 *    consumes: "application/json";
 *    produces: "application/json";
 *  };
 *
 *
 * SOURCE Swagger @ grpc/gateway/protoc_gen_openapiv2/options/openapiv2.proto at 45:1
 */
export const Swagger = $root.lookupType(
  'grpc.gateway.protoc_gen_openapiv2.options.Swagger'
);
fixType(Swagger);
Builder.createAndAttachToType(Swagger);
/**
 * `Header` is a representation of OpenAPI v2 specification's Header object.
 *
 * See: https://github.com/OAI/OpenAPI-Specification/blob/3.0.0/versions/2.0.md#headerObject
 *
 *
 * SOURCE Header @ grpc/gateway/protoc_gen_openapiv2/options/openapiv2.proto at 229:1
 */
export const Header = $root.lookupType(
  'grpc.gateway.protoc_gen_openapiv2.options.Header'
);
fixType(Header);
Builder.createAndAttachToType(Header);
/**
 * `Response` is a representation of OpenAPI v2 specification's Response object.
 *
 * See: https://github.com/OAI/OpenAPI-Specification/blob/3.0.0/versions/2.0.md#responseObject
 *
 *
 * SOURCE Response @ grpc/gateway/protoc_gen_openapiv2/options/openapiv2.proto at 274:1
 */
export const Response = $root.lookupType(
  'grpc.gateway.protoc_gen_openapiv2.options.Response'
);
fixType(Response);
Builder.createAndAttachToType(Response);
/**
 * `Info` is a representation of OpenAPI v2 specification's Info object.
 *
 * See: https://github.com/OAI/OpenAPI-Specification/blob/3.0.0/versions/2.0.md#infoObject
 *
 * Example:
 *
 *  option (grpc.gateway.protoc_gen_openapiv2.options.openapiv2_swagger) = {
 *    info: {
 *      title: "Echo API";
 *      version: "1.0";
 *      description: "";
 *      contact: {
 *        name: "gRPC-Gateway project";
 *        url: "https://github.com/grpc-ecosystem/grpc-gateway";
 *        email: "none@example.com";
 *      };
 *      license: {
 *        name: "BSD 3-Clause License";
 *        url: "https://github.com/grpc-ecosystem/grpc-gateway/blob/main/LICENSE";
 *      };
 *    };
 *    ...
 *  };
 *
 *
 * SOURCE Info @ grpc/gateway/protoc_gen_openapiv2/options/openapiv2.proto at 318:1
 */
export const Info = $root.lookupType(
  'grpc.gateway.protoc_gen_openapiv2.options.Info'
);
fixType(Info);
Builder.createAndAttachToType(Info);
/**
 * `Contact` is a representation of OpenAPI v2 specification's Contact object.
 *
 * See: https://github.com/OAI/OpenAPI-Specification/blob/3.0.0/versions/2.0.md#contactObject
 *
 * Example:
 *
 *  option (grpc.gateway.protoc_gen_openapiv2.options.openapiv2_swagger) = {
 *    info: {
 *      ...
 *      contact: {
 *        name: "gRPC-Gateway project";
 *        url: "https://github.com/grpc-ecosystem/grpc-gateway";
 *        email: "none@example.com";
 *      };
 *      ...
 *    };
 *    ...
 *  };
 *
 *
 * SOURCE Contact @ grpc/gateway/protoc_gen_openapiv2/options/openapiv2.proto at 358:1
 */
export const Contact = $root.lookupType(
  'grpc.gateway.protoc_gen_openapiv2.options.Contact'
);
fixType(Contact);
Builder.createAndAttachToType(Contact);
/**
 * `License` is a representation of OpenAPI v2 specification's License object.
 *
 * See: https://github.com/OAI/OpenAPI-Specification/blob/3.0.0/versions/2.0.md#licenseObject
 *
 * Example:
 *
 *  option (grpc.gateway.protoc_gen_openapiv2.options.openapiv2_swagger) = {
 *    info: {
 *      ...
 *      license: {
 *        name: "BSD 3-Clause License";
 *        url: "https://github.com/grpc-ecosystem/grpc-gateway/blob/main/LICENSE";
 *      };
 *      ...
 *    };
 *    ...
 *  };
 *
 *
 * SOURCE License @ grpc/gateway/protoc_gen_openapiv2/options/openapiv2.proto at 387:1
 */
export const License = $root.lookupType(
  'grpc.gateway.protoc_gen_openapiv2.options.License'
);
fixType(License);
Builder.createAndAttachToType(License);
/**
 * `ExternalDocumentation` is a representation of OpenAPI v2 specification's
 * ExternalDocumentation object.
 *
 * See: https://github.com/OAI/OpenAPI-Specification/blob/3.0.0/versions/2.0.md#externalDocumentationObject
 *
 * Example:
 *
 *  option (grpc.gateway.protoc_gen_openapiv2.options.openapiv2_swagger) = {
 *    ...
 *    external_docs: {
 *      description: "More about gRPC-Gateway";
 *      url: "https://github.com/grpc-ecosystem/grpc-gateway";
 *    }
 *    ...
 *  };
 *
 *
 * SOURCE ExternalDocumentation @ grpc/gateway/protoc_gen_openapiv2/options/openapiv2.proto at 410:1
 */
export const ExternalDocumentation = $root.lookupType(
  'grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation'
);
fixType(ExternalDocumentation);
Builder.createAndAttachToType(ExternalDocumentation);
/**
 * `Schema` is a representation of OpenAPI v2 specification's Schema object.
 *
 * See: https://github.com/OAI/OpenAPI-Specification/blob/3.0.0/versions/2.0.md#schemaObject
 *
 *
 * SOURCE Schema @ grpc/gateway/protoc_gen_openapiv2/options/openapiv2.proto at 423:1
 */
export const Schema = $root.lookupType(
  'grpc.gateway.protoc_gen_openapiv2.options.Schema'
);
fixType(Schema);
Builder.createAndAttachToType(Schema);
/**
 * `JSONSchema` represents properties from JSON Schema taken, and as used, in
 * the OpenAPI v2 spec.
 *
 * This includes changes made by OpenAPI v2.
 *
 * See: https://github.com/OAI/OpenAPI-Specification/blob/3.0.0/versions/2.0.md#schemaObject
 *
 * See also: https://cswr.github.io/JsonSchema/spec/basic_types/,
 * https://github.com/json-schema-org/json-schema-spec/blob/master/schema.json
 *
 * Example:
 *
 *  message SimpleMessage {
 *    option (grpc.gateway.protoc_gen_openapiv2.options.openapiv2_schema) = {
 *      json_schema: {
 *        title: "SimpleMessage"
 *        description: "A simple message."
 *        required: ["id"]
 *      }
 *    };
 *
 *    // Id represents the message identifier.
 *    string id = 1; [
 *        (grpc.gateway.protoc_gen_openapiv2.options.openapiv2_field) = {
 *          description: "The unique identifier of the simple message."
 *        }];
 *  }
 *
 *
 * SOURCE JSONSchema @ grpc/gateway/protoc_gen_openapiv2/options/openapiv2.proto at 474:1
 */
export const JSONSchema = $root.lookupType(
  'grpc.gateway.protoc_gen_openapiv2.options.JSONSchema'
);
fixType(JSONSchema);
Builder.createAndAttachToType(JSONSchema);
/**
 * SOURCE JSONSchemaSimpleTypes @ grpc/gateway/protoc_gen_openapiv2/options/openapiv2.proto at 538:3
 */
JSONSchema.JSONSchemaSimpleTypes = $root.lookupEnum(
  'grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.JSONSchemaSimpleTypes'
).values;
/**
 * 'FieldConfiguration' provides additional field level properties used when generating the OpenAPI v2 file.
 * These properties are not defined by OpenAPIv2, but they are used to control the generation.
 *
 * SOURCE FieldConfiguration @ grpc/gateway/protoc_gen_openapiv2/options/openapiv2.proto at 570:3
 */
JSONSchema.FieldConfiguration = $root.lookupType(
  'grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.FieldConfiguration'
);
fixType(JSONSchema.FieldConfiguration);
Builder.createAndAttachToType(JSONSchema.FieldConfiguration);
/**
 * `Tag` is a representation of OpenAPI v2 specification's Tag object.
 *
 * See: https://github.com/OAI/OpenAPI-Specification/blob/3.0.0/versions/2.0.md#tagObject
 *
 *
 * SOURCE Tag @ grpc/gateway/protoc_gen_openapiv2/options/openapiv2.proto at 587:1
 */
export const Tag = $root.lookupType(
  'grpc.gateway.protoc_gen_openapiv2.options.Tag'
);
fixType(Tag);
Builder.createAndAttachToType(Tag);
/**
 * `SecurityDefinitions` is a representation of OpenAPI v2 specification's
 * Security Definitions object.
 *
 * See: https://github.com/OAI/OpenAPI-Specification/blob/3.0.0/versions/2.0.md#securityDefinitionsObject
 *
 * A declaration of the security schemes available to be used in the
 * specification. This does not enforce the security schemes on the operations
 * and only serves to provide the relevant details for each scheme.
 *
 * SOURCE SecurityDefinitions @ grpc/gateway/protoc_gen_openapiv2/options/openapiv2.proto at 611:1
 */
export const SecurityDefinitions = $root.lookupType(
  'grpc.gateway.protoc_gen_openapiv2.options.SecurityDefinitions'
);
fixType(SecurityDefinitions);
Builder.createAndAttachToType(SecurityDefinitions);
/**
 * `SecurityScheme` is a representation of OpenAPI v2 specification's
 * Security Scheme object.
 *
 * See: https://github.com/OAI/OpenAPI-Specification/blob/3.0.0/versions/2.0.md#securitySchemeObject
 *
 * Allows the definition of a security scheme that can be used by the
 * operations. Supported schemes are basic authentication, an API key (either as
 * a header or as a query parameter) and OAuth2's common flows (implicit,
 * password, application and access code).
 *
 * SOURCE SecurityScheme @ grpc/gateway/protoc_gen_openapiv2/options/openapiv2.proto at 626:1
 */
export const SecurityScheme = $root.lookupType(
  'grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme'
);
fixType(SecurityScheme);
Builder.createAndAttachToType(SecurityScheme);
/**
 * The type of the security scheme. Valid values are "basic",
 * "apiKey" or "oauth2".
 *
 * SOURCE Type @ grpc/gateway/protoc_gen_openapiv2/options/openapiv2.proto at 629:3
 */
SecurityScheme.Type = $root.lookupEnum(
  'grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.Type'
).values;
/**
 * The location of the API key. Valid values are "query" or "header".
 *
 * SOURCE In @ grpc/gateway/protoc_gen_openapiv2/options/openapiv2.proto at 637:3
 */
SecurityScheme.In = $root.lookupEnum(
  'grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.In'
).values;
/**
 * The flow used by the OAuth2 security scheme. Valid values are
 * "implicit", "password", "application" or "accessCode".
 *
 * SOURCE Flow @ grpc/gateway/protoc_gen_openapiv2/options/openapiv2.proto at 645:3
 */
SecurityScheme.Flow = $root.lookupEnum(
  'grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.Flow'
).values;
/**
 * `SecurityRequirement` is a representation of OpenAPI v2 specification's
 * Security Requirement object.
 *
 * See: https://github.com/OAI/OpenAPI-Specification/blob/3.0.0/versions/2.0.md#securityRequirementObject
 *
 * Lists the required security schemes to execute this operation. The object can
 * have multiple security schemes declared in it which are all required (that
 * is, there is a logical AND between the schemes).
 *
 * The name used for each property MUST correspond to a security scheme
 * declared in the Security Definitions.
 *
 * SOURCE SecurityRequirement @ grpc/gateway/protoc_gen_openapiv2/options/openapiv2.proto at 697:1
 */
export const SecurityRequirement = $root.lookupType(
  'grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement'
);
fixType(SecurityRequirement);
Builder.createAndAttachToType(SecurityRequirement);
/**
 * If the security scheme is of type "oauth2", then the value is a list of
 * scope names required for the execution. For other security scheme types,
 * the array MUST be empty.
 *
 * SOURCE SecurityRequirementValue @ grpc/gateway/protoc_gen_openapiv2/options/openapiv2.proto at 701:3
 */
SecurityRequirement.SecurityRequirementValue = $root.lookupType(
  'grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue'
);
fixType(SecurityRequirement.SecurityRequirementValue);
Builder.createAndAttachToType(SecurityRequirement.SecurityRequirementValue);
/**
 * `Scopes` is a representation of OpenAPI v2 specification's Scopes object.
 *
 * See: https://github.com/OAI/OpenAPI-Specification/blob/3.0.0/versions/2.0.md#scopesObject
 *
 * Lists the available scopes for an OAuth2 security scheme.
 *
 * SOURCE Scopes @ grpc/gateway/protoc_gen_openapiv2/options/openapiv2.proto at 716:1
 */
export const Scopes = $root.lookupType(
  'grpc.gateway.protoc_gen_openapiv2.options.Scopes'
);
fixType(Scopes);
Builder.createAndAttachToType(Scopes);
