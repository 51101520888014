import { PatchCollection } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import client from 'rpc/client';
import { CardPreference, Action } from 'routes/profile/models/CardPreference';
import {
  GlobalEmailPreference,
  EmailPreference,
  MerchantEmailPreference as DomainMerchantEmailPreference,
  Product,
  CardReceiptPreference,
  Value,
} from 'routes/profile/models/EmailPreference';
import { Identifier as RpcIdentifier } from 'rpc/model/squareup/buyerportal/profile/common';
import {
  RetrieveGlobalEmailPreferencesRequest,
  RetrieveGlobalEmailPreferencesResponse,
  RetrieveMerchantEmailPreferencesRequest,
  RetrieveMerchantEmailPreferencesResponse,
  UpdateEmailPreferenceRequest,
  UpdateEmailPreferenceResponse,
} from 'rpc/model/squareup/buyerportal/preference/email';
import {
  RetrieveCardPreferencesRequest,
  RetrieveCardPreferencesResponse,
  UpdateCardPreferenceRequest,
  UpdateCardPreferenceResponse,
  CardPreference as RpcCardPreference,
} from 'rpc/model/squareup/buyerportal/preference/card';
import { RequestStatus } from 'rpc/model/squareup/customers/request';
import { callApi, getResponseError } from '../utils';
import { getRpcIdentifierType } from 'utils/identifiers';
import { buyerportalApi } from '..';
import { AppState } from 'store';
import { selectIdentifierCollection } from 'store/buyerSlice';
import { IdentifierType } from 'routes/profile/models/Identifier';
import { CardInfo } from 'rpc/model/squareup/buyerportal/common/data';

// This is the max allowed by the backend.
const MERCHANT_EMAIL_PREFERENCES_PAGE_SIZE = 100;

// The preferences API endpoints handle receipts, marketing, global and merchant preferences.
export const extendedApi = buyerportalApi.injectEndpoints({
  endpoints: (builder) => ({
    retrieveGlobalEmailPreferences: builder.query<
      GlobalEmailPreference,
      string
    >({
      providesTags: ['retrieveGlobalEmailPreferences'],
      async queryFn(emailId) {
        const response = await callApi<RetrieveGlobalEmailPreferencesResponse>(
          async () =>
            await client.retrieveGlobalEmailPreferences(
              RetrieveGlobalEmailPreferencesRequest.create({ emailId })
            )
        );
        if (response.data!.status !== RequestStatus.STATUS_SUCCESS) {
          return getResponseError(response.data!);
        }

        return {
          data: GlobalEmailPreference.fromResponse(emailId, response.data!),
        };
      },
    }),
    retrieveMerchantEmailPreferences: builder.query<
      DomainMerchantEmailPreference[],
      string
    >({
      providesTags: ['retrieveMerchantEmailPreferences'],
      async queryFn(emailId) {
        let allPrefs: DomainMerchantEmailPreference[] = [];
        let morePrefs = true;

        const processApiResponse = async () => {
          const response =
            await callApi<RetrieveMerchantEmailPreferencesResponse>(
              async () =>
                await client.retrieveMerchantEmailPreferences(
                  RetrieveMerchantEmailPreferencesRequest.create({
                    emailId,
                    offset: allPrefs.length,
                    limit: MERCHANT_EMAIL_PREFERENCES_PAGE_SIZE,
                  })
                )
            );

          if (response.data!.status !== RequestStatus.STATUS_SUCCESS) {
            return getResponseError(response.data!);
          }

          const rpcPrefs = response.data!.preferences;
          if (rpcPrefs.length < MERCHANT_EMAIL_PREFERENCES_PAGE_SIZE) {
            morePrefs = false;
          }

          const convertedPrefs = rpcPrefs.map(
            DomainMerchantEmailPreference.fromResponse
          );

          allPrefs = [...allPrefs, ...convertedPrefs];
        };

        while (morePrefs) {
          await processApiResponse();
        }

        return {
          data: allPrefs,
        };
      },
    }),
    retrieveCardPreferences: builder.query<
      { cardInfo?: CardInfo; cardPreferences: CardPreference[] },
      string
    >({
      async queryFn(cardId: string) {
        const response = await callApi<RetrieveCardPreferencesResponse>(
          async () =>
            await client.retrieveCardPreferences(
              RetrieveCardPreferencesRequest.create({ cardId })
            )
        );
        if (response.data!.status !== RequestStatus.STATUS_SUCCESS) {
          return getResponseError(response.data!);
        }

        return {
          data: {
            cardInfo: response.data!.cardInfo,
            cardPreferences:
              response.data!.cardPreferences?.map(
                CardPreference.fromResponse
              ) ?? [],
          },
        };
      },
      providesTags: ['CardPreferences'],
    }),
    updateCardPreference: builder.mutation<
      null,
      {
        preferenceCardId: string;
        cardPreference: CardPreference;
        action: Action;
      }
    >({
      async queryFn({ preferenceCardId, cardPreference, action }) {
        const response = await callApi<UpdateCardPreferenceResponse>(
          async () =>
            await client.updateCardPreference(
              UpdateCardPreferenceRequest.create({
                cardId: preferenceCardId,
                actionType:
                  action === Action.Link
                    ? RpcCardPreference.UpdateActionType.UPDATE_ACTION_LINK
                    : RpcCardPreference.UpdateActionType.UPDATE_ACTION_UNLINK,
                cardPreference: RpcCardPreference.create({
                  product: RpcCardPreference.Product.PRODUCT_RECEIPT,
                  type: RpcCardPreference.Type.TYPE_IDENTIFIER,
                  identifier: RpcIdentifier.create({
                    id: cardPreference.identifier.token,
                    type: getRpcIdentifierType(
                      cardPreference.identifier.identifierType
                    ),
                    displayValue: cardPreference.identifier.displayValue,
                  }),
                }),
              })
            )
        );

        if (response.data!.status !== RequestStatus.STATUS_SUCCESS) {
          return getResponseError(response.data!);
        }

        return {
          data: null,
        };
      },
      async onQueryStarted(
        { preferenceCardId, cardPreference, action },
        { dispatch, queryFulfilled }
      ) {
        const updateResult = dispatch(
          extendedApi.util.updateQueryData(
            'retrieveCardPreferences',
            preferenceCardId || '',
            (cardResponse) => {
              // if we're linking a card to an email, the global and merchant email preferences must be re-retrieved.
              let cardPrefs = cardResponse.cardPreferences;

              const existingCardPref = cardPrefs.find(
                (pref) =>
                  pref.identifier.identifierType === IdentifierType.Email
              );
              if (!existingCardPref && action === Action.Link) {
                dispatch(
                  buyerportalApi.util.invalidateTags([
                    {
                      type: 'retrieveGlobalEmailPreferences',
                    },
                    { type: 'retrieveMerchantEmailPreferences' },
                  ])
                );
              }

              // remove old card pref if exists
              cardPrefs = cardPrefs.filter(
                (pref) =>
                  pref.identifier.identifierType !==
                  cardPreference.identifier.identifierType
              );

              // add updated card pref
              if (action === Action.Link) {
                cardPrefs = [...cardPrefs, cardPreference];
              }

              cardResponse.cardPreferences = cardPrefs;
              return cardResponse;
            }
          )
        );

        queryFulfilled.catch(updateResult.undo);
      },
    }),
    // TODO: This endpoint is handling too many scenarios which makes optimistic caching very complex. Ideally we refactor the backend endpoints to not handle so many different scenarios.
    updateEmailPreference: builder.mutation<
      null,
      {
        emailId: string;
        preference: EmailPreference;
        merchantId?: string;
        cardId?: string;
        copyId?: string;
      }
    >({
      async queryFn({ emailId, preference, merchantId, cardId, copyId }) {
        const response = await callApi<UpdateEmailPreferenceResponse>(
          async () =>
            await client.updateEmailPreference(
              UpdateEmailPreferenceRequest.create({
                emailId,
                preference: EmailPreference.toProto(preference),
                merchantId,
                cardId,
                copyId,
              })
            )
        );

        if (response.data!.status !== RequestStatus.STATUS_SUCCESS) {
          return getResponseError(response.data!);
        }

        return {
          data: null,
        };
      },
      // This has to handle updates to:
      // 1. receipts and marketing
      // 2. merchants and global
      async onQueryStarted(
        { emailId, preference, merchantId, cardId },
        { dispatch, getState, queryFulfilled }
      ) {
        const updateResults: PatchCollection[] = [];
        const isGlobalPrefUpdate = !merchantId;
        switch (preference.product) {
          case Product.Marketing: {
            if (isGlobalPrefUpdate) {
              updateResults.push(
                dispatch(
                  extendedApi.util.updateQueryData(
                    'retrieveGlobalEmailPreferences',
                    emailId,
                    (preferenceToUpdate) => {
                      // TODO: Update this to use mutations
                      return {
                        ...preferenceToUpdate,
                        marketingPreference: {
                          ...preferenceToUpdate.marketingPreference,
                          value: preference.value,
                        },
                      };
                    }
                  )
                )
              );

              /*
                When a buyer globally opts into marketing, refetch merchant email preferences.
                This server behavior is subject to change, so it's safest if we just refetch the merchant preferences.

                TODO: Replace this with an in-memory optimistic caching strategy when Marketing service has a
                stabilized API.
               */
              if (preference.value === Value.OptIn) {
                dispatch(
                  buyerportalApi.util.invalidateTags([
                    'retrieveMerchantEmailPreferences',
                  ])
                );
              }
            } else {
              // Merchant
              updateResults.push(
                dispatch(
                  extendedApi.util.updateQueryData(
                    'retrieveMerchantEmailPreferences',
                    emailId,
                    (draftMerchantPrefs) => {
                      const indexToUpdate = draftMerchantPrefs.findIndex(
                        (merchantPref) =>
                          merchantPref.merchantInfo.id === merchantId
                      );
                      draftMerchantPrefs[indexToUpdate].marketingPreference =
                        preference;
                    }
                  )
                )
              );
            }
            break;
          }
          case Product.Receipt: {
            // for card-scoped updates, we need to loop through every verified email to update the card preferences
            const appState = getState() as AppState;
            const verifiedEmails = selectIdentifierCollection(
              appState,
              IdentifierType.Email
            );

            for (const verifiedEmail of verifiedEmails) {
              if (isGlobalPrefUpdate) {
                updateResults.push(
                  dispatch(
                    extendedApi.util.updateQueryData(
                      'retrieveGlobalEmailPreferences',
                      verifiedEmail.token,
                      (draftGlobalPref) => {
                        // NB: In the new paradigm, "global" receipt prefs are scoped to a card, rather an email.
                        const relevantCardPref =
                          draftGlobalPref.cardReceiptPreferences.find(
                            (cardPref) => cardPref.cardId === cardId
                          );

                        // This may be a new card which had no receipt pref, meaning we need to create one
                        if (relevantCardPref) {
                          relevantCardPref.receiptPreference = preference;
                        } else {
                          draftGlobalPref.cardReceiptPreferences.push(
                            new CardReceiptPreference({
                              cardId: cardId!,
                              brand: '',
                              displayName: '',
                              receiptPreference: preference,
                            })
                          );
                        }
                      }
                    )
                  )
                );
              } else {
                // Merchant
                updateResults.push(
                  dispatch(
                    extendedApi.util.updateQueryData(
                      'retrieveMerchantEmailPreferences',
                      verifiedEmail.token,
                      (draftMerchantPrefs) => {
                        const merchantPrefToUpdate = draftMerchantPrefs.find(
                          (merchantPref) =>
                            merchantPref.merchantInfo.id === merchantId
                        )!;
                        const cardReceiptPrefToUpdate =
                          merchantPrefToUpdate.cardReceiptPreferences.find(
                            (cardReceiptPref) =>
                              cardReceiptPref.cardId === cardId
                          )!;
                        cardReceiptPrefToUpdate.receiptPreference = preference;
                      }
                    )
                  )
                );
              }
            }

            break;
          }
          default: {
            throw new Error('Invalid product');
          }
        }

        queryFulfilled.catch(() => {
          updateResults.forEach((updateResult) => updateResult.undo());
        });
      },
    }),
  }),
});

export const {
  useRetrieveGlobalEmailPreferencesQuery,
  useRetrieveCardPreferencesQuery,
  useRetrieveMerchantEmailPreferencesQuery,
  useUpdateCardPreferenceMutation,
  useUpdateEmailPreferenceMutation,
} = extendedApi;
