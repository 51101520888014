import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const BASE_URL =
  '/services/squareup.postoffice.SubscriptionService/subscriptions/';

export interface FetchMarketingData {
  merchantName: string | undefined;
  obfuscatedEmail: string | undefined;
}

export const postofficeApi = createApi({
  reducerPath: 'postofficeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  endpoints: (builder) => ({
    fetch: builder.query<
      FetchMarketingData,
      {
        id: string;
        source: string;
        value: string;
      }
    >({
      query: ({ id, source, value }) => ({
        url: `${id}?source=${source}&value=${value}`,
        validateStatus: (response, result) =>
          Boolean(result?.email_user_opt_in) &&
          Boolean(result?.email_user_opt_in?.buyer?.obfuscated_email) &&
          Boolean(result.email_user_opt_in?.user?.merchant_name),
      }),
      transformResponse: (response) => {
        return {
          merchantName: response?.email_user_opt_in?.user?.merchant_name,
          obfuscatedEmail: response?.email_user_opt_in?.buyer?.obfuscated_email,
        };
      },
    }),
    optIn: builder.mutation<
      null,
      {
        id: string;
        source: string;
        value: string;
      }
    >({
      query: ({ id, source, value }) => ({
        url: `${id}/opt_in?source=${source}&value=${value}`,
        method: 'POST',
      }),
    }),
    optOut: builder.mutation<
      null,
      {
        id: string;
        source: string;
        value: string;
      }
    >({
      query: ({ id, source, value }) => ({
        url: `${id}/opt_out?source=${source}&value=${value}`,
        method: 'POST',
      }),
    }),
    globalOptIn: builder.mutation<
      null,
      {
        id: string;
        source: string;
        value: string;
        countryCode: string;
        languageCode: string;
      }
    >({
      query: ({ id, source, value, countryCode, languageCode }) => ({
        url: `${id}/global_opt_in?source=${source}&value=${value}&country_code=${countryCode}&language_code=${languageCode}`,
        method: 'POST',
      }),
    }),
    globalOptOut: builder.mutation<
      null,
      {
        id: string;
        source: string;
        value: string;
        countryCode: string;
        languageCode: string;
      }
    >({
      query: ({ id, source, value, countryCode, languageCode }) => ({
        url: `${id}/global_opt_out?source=${source}&value=${value}&country_code=${countryCode}&language_code=${languageCode}`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useFetchQuery,
  useOptInMutation,
  useOptOutMutation,
  useGlobalOptInMutation,
  useGlobalOptOutMutation,
} = postofficeApi;
