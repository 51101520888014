import { LoyaltyAccountExpiringPointDeadline } from 'rpc/model/squareup/card/balance/loyalty_api/model/loyalty-account';

export class ExpiringPointsDeadline {
  static fromProto(
    proto: LoyaltyAccountExpiringPointDeadline
  ): ExpiringPointsDeadline {
    return new ExpiringPointsDeadline()
      .setExpiresAt(proto.expiresAt)
      .setPoints(proto.points);
  }

  expiresAt?: Date;
  points?: number;

  setExpiresAt(expiresAt: string) {
    this.expiresAt = new Date(Date.parse(expiresAt));
    return this;
  }

  setPoints(points: number) {
    this.points = points;
    return this;
  }
}
