import React from 'react';
import { MarketHeader } from '@market/react';
import SideNav from 'routes/profile/nav/SideNav';
import BaseModal from 'components/modals/BaseModal';

const MobileNavModal: React.FC = () => {
  return (
    <BaseModal>
      <MarketHeader />
      <SideNav showSignOut={true} />
    </BaseModal>
  );
};

export default MobileNavModal;
