import React from 'react';
import { useTranslation } from 'react-i18next';
import { LogoIcon } from 'svgs';

const Errored: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={'flex items-center flex-col px-[27px] py-[25px]'}>
      <div className={'max-w-[700px]'}>
        <LogoIcon className={'mb-9'} fontSize={'large'} />
        <h2 className={'mb-4 heading-30'}>
          {t('cashcouponbeta.invalidLink.title')}
        </h2>
        <p className={'mb-8 paragraph-30'}>
          {t('cashcouponbeta.invalidLink.subtitle')}
        </p>
      </div>
    </div>
  );
};

export default Errored;
