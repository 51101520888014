import React from 'react';
import { MarketSvgProps } from '.';

const Hamburger: React.FC<MarketSvgProps> = (props) => {
  return (
    <svg width="18" height="12" viewBox="0 0 18 12" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H18V2H0V0ZM18 5H0V7H18V5ZM18 10H0V12H18V10Z"
        fill={props.fill || '#000'}
      />
    </svg>
  );
};

export default Hamburger;
