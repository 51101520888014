import { MarketButton, MarketHeader } from '@market/react';
import BaseModal from 'components/modals/BaseModal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GiftCardPreview from '../Details/GiftCardPreview';
import { useDispatch } from 'react-redux';
import { closeModal } from 'store/modalSlice';
import { useAddGiftCardMutation } from 'store/query';
import { openToast } from 'store/toastSlice';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { RequestStatus } from 'rpc/model/squareup/customers/request';
import { GiftCardDetail } from 'routes/profile/models/GiftCard';

export type AddGiftCardModalProps = {
  cardToAdd: GiftCardDetail;
  secret: string;
};

const AddGiftCardModal: React.FC<AddGiftCardModalProps> = ({
  cardToAdd,
  secret,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [addGiftCard, addGiftCardResult] = useAddGiftCardMutation();

  const confirmAddGiftCard = () => {
    addGiftCard({
      giftCardDetail: cardToAdd,
      giftCardTokenSecret: secret,
    })
      .unwrap()
      .then(() => {
        dispatch(
          openToast({
            variant: 'success',
            message: t(
              'profile.paymentMethods.giftCards.addGiftCardModal.giftCardAdded'
            ),
          })
        );
      })
      .catch((ex) => {
        if ('data' in ex) {
          const apiError = ex as FetchBaseQueryError;
          if (apiError.status === RequestStatus.STATUS_BAD_REQUEST) {
            dispatch(
              openToast({
                variant: 'warning',
                message: t(
                  'profile.paymentMethods.giftCards.addGiftCardModal.giftCardAlreadyAdded'
                ),
              })
            );
          }
        } else {
          dispatch(
            openToast({
              variant: 'critical',
              message: t('common.somethingWentWrong.retryable.direct'),
            })
          );
        }
      })
      .finally(() => {
        dispatch(closeModal());
      });
  };

  return (
    <BaseModal>
      <MarketHeader className={'mb-8'}>
        <h2>{t('profile.paymentMethods.giftCards.addGiftCardModal.title')}</h2>
        <p
          dangerouslySetInnerHTML={{
            __html: t(
              'profile.paymentMethods.giftCards.addGiftCardModal.instructions',
              {
                merchantName: `<b>${cardToAdd.merchantInfo.displayName}</b>`,
                cardLastFour: `<span>${cardToAdd.gan.slice(-4)}</span>`,
              }
            ),
          }}
        />
        <MarketButton
          rank={'primary'}
          slot={'actions'}
          onClick={confirmAddGiftCard}
          {...(addGiftCardResult.isLoading && { isLoading: true })}
        >
          {t('common.add')}
        </MarketButton>
      </MarketHeader>
      <div className={'flex justify-center p-6 pt-0'}>
        <GiftCardPreview giftCardDetail={cardToAdd} />
      </div>
    </BaseModal>
  );
};

export default AddGiftCardModal;
