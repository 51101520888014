import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import ModuleLoadFailed from 'routes/profile/common/errors/ModuleLoadFailed';
import ModuleLoading from 'routes/profile/common/loading/ModuleLoading';
import { useRetrieveClaimablePointsQuery } from 'store/query/api-extensions/loyalty';

/**
 * Redirects to the new claim points page URL from legacy URLs, which should match one of these patterns:
 *   - /loyalty/claim/:claimablePointsToken
 *   - /loyalty-stars/:claimablePointsToken
 */
interface LegacyClaimPointsUrlParams {
  claimablePointsToken: string;
}

const LegacyClaimPointsPageRedirect = () => {
  const { claimablePointsToken } = useParams<LegacyClaimPointsUrlParams>();

  const claimablePointsResult = useRetrieveClaimablePointsQuery(
    claimablePointsToken,
    {
      skip: !claimablePointsToken,
    }
  );

  if (claimablePointsResult.isLoading) {
    return <ModuleLoading />;
  }

  const merchantId = claimablePointsResult.data?.merchantId;
  if (claimablePointsResult.isError || !merchantId) {
    return <ModuleLoadFailed />;
  }

  return (
    <Redirect
      to={`/merchantportal/${merchantId}/loyalty/claim/${claimablePointsToken}`}
    />
  );
};

export default LegacyClaimPointsPageRedirect;
