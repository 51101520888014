/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../Fixes';
import Builder from '../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  nanopb: {
    nested: {
      FieldType: {
        values: {
          FT_DEFAULT: 0,
          FT_CALLBACK: 1,
          FT_POINTER: 4,
          FT_STATIC: 2,
          FT_IGNORE: 3,
        },
      },
      IntSize: {
        values: {
          IS_DEFAULT: 0,
          IS_8: 8,
          IS_16: 16,
          IS_32: 32,
          IS_64: 64,
        },
      },
      NanoPBOptions: {
        fields: {
          maxSize: {
            type: 'int32',
            id: 1,
            rule: 'optional',
          },
          maxCount: {
            type: 'int32',
            id: 2,
            rule: 'optional',
          },
          intSize: {
            type: 'nanopb.IntSize',
            id: 7,
            rule: 'optional',
            options: {
              default: 'IS_DEFAULT',
            },
          },
          type: {
            type: 'nanopb.FieldType',
            id: 3,
            rule: 'optional',
            options: {
              default: 'FT_DEFAULT',
            },
          },
          longNames: {
            type: 'bool',
            id: 4,
            rule: 'optional',
            options: {
              default: true,
            },
          },
          packedStruct: {
            type: 'bool',
            id: 5,
            rule: 'optional',
            options: {
              default: false,
            },
          },
          packedEnum: {
            type: 'bool',
            id: 10,
            rule: 'optional',
            options: {
              default: false,
            },
          },
          skipMessage: {
            type: 'bool',
            id: 6,
            rule: 'optional',
            options: {
              default: false,
            },
          },
          noUnions: {
            type: 'bool',
            id: 8,
            rule: 'optional',
            options: {
              default: false,
            },
          },
          msgid: {
            type: 'uint32',
            id: 9,
            rule: 'optional',
          },
          anonymousOneof: {
            type: 'bool',
            id: 11,
            rule: 'optional',
            options: {
              default: false,
            },
          },
          ritual: {
            type: 'bool',
            id: 12,
            rule: 'optional',
            options: {
              default: false,
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.nanopb?.nested?.FieldType &&
  !$root.nested?.nanopb?.nested?.IntSize &&
  !$root.nested?.nanopb?.nested?.NanoPBOptions
) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE FieldType @ nanopb/nanopb.proto at 15:1
 */
export const FieldType = $root.lookupEnum('nanopb.FieldType').values;
/**
 * SOURCE IntSize @ nanopb/nanopb.proto at 23:1
 */
export const IntSize = $root.lookupEnum('nanopb.IntSize').values;
/**
 * This is the inner options message, which basically defines options for
 * a field. When it is used in message or file scope, it applies to all
 * fields.
 *
 * SOURCE NanoPBOptions @ nanopb/nanopb.proto at 34:1
 */
export const NanoPBOptions = $root.lookupType('nanopb.NanoPBOptions');
fixType(NanoPBOptions);
Builder.createAndAttachToType(NanoPBOptions);
