/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../Fixes';
import Builder from '../../../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      card: {
        nested: {
          balance: {
            nested: {
              model: {
                nested: {
                  LoyaltyTermsOfService: {
                    fields: {
                      countryCode: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      version: {
                        type: 'uint32',
                        id: 2,
                        rule: 'optional',
                      },
                      copies: {
                        type: 'squareup.card.balance.model.LoyaltyTermsOfService.Copy',
                        id: 3,
                        rule: 'repeated',
                      },
                    },
                    nested: {
                      Copy: {
                        fields: {
                          copyToken: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                          locale: {
                            type: 'string',
                            id: 2,
                            rule: 'optional',
                          },
                          content: {
                            type: 'string',
                            id: 3,
                            rule: 'optional',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.model?.nested
    ?.LoyaltyTermsOfService &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.model?.nested
    ?.LoyaltyTermsOfService?.nested?.Copy
) {
  $root.addJSON(jsonData);
}

/**
 * A reference to the loyalty terms of service.
 *
 * SOURCE LoyaltyTermsOfService @ squareup/card/balance/model/loyalty-terms-of-service.proto at 16:1
 */
export const LoyaltyTermsOfService = $root.lookupType(
  'squareup.card.balance.model.LoyaltyTermsOfService'
);
fixType(LoyaltyTermsOfService, {
  countryCode: { required: true },
  version: { required: true },
});
Builder.createAndAttachToType(LoyaltyTermsOfService);
/**
 * SOURCE Copy @ squareup/card/balance/model/loyalty-terms-of-service.proto at 26:3
 */
LoyaltyTermsOfService.Copy = $root.lookupType(
  'squareup.card.balance.model.LoyaltyTermsOfService.Copy'
);
fixType(LoyaltyTermsOfService.Copy, {
  copyToken: { notEmpty: true },
  locale: { notEmpty: true },
  content: { notEmpty: true },
});
Builder.createAndAttachToType(LoyaltyTermsOfService.Copy);
