import i18n from 'i18next';
import ICU from 'i18next-icu';
import icuEn from 'i18next-icu/locale-data/en';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

export const languageDetector = new LanguageDetector(null, {
  order: ['navigator'],
});

export function extractPrimaryLocale(locale: string | string[] | undefined) {
  return Array.isArray(locale) ? locale[0] : locale ?? undefined;
}

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .use(new ICU({ localeData: icuEn }))
  .use(
    resourcesToBackend((language, namespace, callback) => {
      import(`./translations/${language}/strings.json`)
        .then(({ [namespace]: resources }) => {
          callback(null, resources);
        })
        .catch((error) => {
          callback(error, null);
        });
    })
  )
  .init({
    debug: process.env.NODE_ENV !== 'production',
    fallbackLng: {
      ca: ['ca-ES', 'en'],
      es: ['es-ES', 'en'],
      fr: ['fr-FR', 'en'],
      ja: ['ja-JP', 'en'],
      default: ['en'],
    },
    preload: ['en'],
    interpolation: {
      escapeValue: false,
    },
    ns: 'buyerportal',
    defaultNS: 'buyerportal',
  });

export { default } from 'i18next';
