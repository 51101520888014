/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../Fixes';
import Builder from '../../../../Builder';
import './data';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      buyerportal: {
        nested: {
          merchantportal: {
            nested: {
              loyalty: {
                nested: {
                  LoyaltyService: {
                    methods: {
                      RetrieveLoyaltyAccountInfoForBuyer: {
                        requestType:
                          'squareup.buyerportal.merchantportal.loyalty.RetrieveLoyaltyAccountInfoForBuyerRequest',
                        responseType:
                          'squareup.buyerportal.merchantportal.loyalty.RetrieveLoyaltyAccountInfoForBuyerResponse',
                      },
                      RetrieveLoyaltyProgramInfo: {
                        requestType:
                          'squareup.buyerportal.merchantportal.loyalty.RetrieveLoyaltyProgramInfoRequest',
                        responseType:
                          'squareup.buyerportal.merchantportal.loyalty.RetrieveLoyaltyProgramInfoResponse',
                      },
                      CreateLoyaltyAccountForBuyer: {
                        requestType:
                          'squareup.buyerportal.merchantportal.loyalty.CreateLoyaltyAccountForBuyerRequest',
                        responseType:
                          'squareup.buyerportal.merchantportal.loyalty.CreateLoyaltyAccountForBuyerResponse',
                      },
                      RetrieveLoyaltyAccountInfo: {
                        requestType:
                          'squareup.buyerportal.merchantportal.loyalty.RetrieveLoyaltyAccountInfoRequest',
                        responseType:
                          'squareup.buyerportal.merchantportal.loyalty.RetrieveLoyaltyAccountInfoResponse',
                      },
                      ListPendingRewards: {
                        requestType:
                          'squareup.buyerportal.merchantportal.loyalty.ListPendingRewardsRequest',
                        responseType:
                          'squareup.buyerportal.merchantportal.loyalty.ListPendingRewardsResponse',
                      },
                      CollectEmailForIncentive: {
                        requestType:
                          'squareup.buyerportal.merchantportal.loyalty.CollectEmailForIncentiveRequest',
                        responseType:
                          'squareup.buyerportal.merchantportal.loyalty.CollectEmailForIncentiveResponse',
                      },
                      SearchLoyaltyPromotions: {
                        requestType:
                          'squareup.buyerportal.merchantportal.loyalty.SearchLoyaltyPromotionsRequest',
                        responseType:
                          'squareup.buyerportal.merchantportal.loyalty.SearchLoyaltyPromotionsResponse',
                      },
                      RetrieveLoyaltyPromotionDetail: {
                        requestType:
                          'squareup.buyerportal.merchantportal.loyalty.RetrieveLoyaltyPromotionDetailRequest',
                        responseType:
                          'squareup.buyerportal.merchantportal.loyalty.RetrieveLoyaltyPromotionDetailResponse',
                      },
                      ListLoyaltyEventDetails: {
                        requestType:
                          'squareup.buyerportal.merchantportal.loyalty.ListLoyaltyEventDetailsRequest',
                        responseType:
                          'squareup.buyerportal.merchantportal.loyalty.ListLoyaltyEventDetailsResponse',
                      },
                      RetrieveEmailCollectionIncentiveTermsAndStatus: {
                        requestType:
                          'squareup.buyerportal.merchantportal.loyalty.RetrieveEmailCollectionIncentiveTermsAndStatusRequest',
                        responseType:
                          'squareup.buyerportal.merchantportal.loyalty.RetrieveEmailCollectionIncentiveTermsAndStatusResponse',
                      },
                      RetrieveClaimablePoints: {
                        requestType:
                          'squareup.buyerportal.merchantportal.loyalty.RetrieveClaimablePointsRequest',
                        responseType:
                          'squareup.buyerportal.merchantportal.loyalty.RetrieveClaimablePointsResponse',
                      },
                      GetTermsOfService: {
                        requestType:
                          'squareup.buyerportal.merchantportal.loyalty.GetTermsOfServiceRequest',
                        responseType:
                          'squareup.buyerportal.merchantportal.loyalty.GetTermsOfServiceResponse',
                      },
                      UpdateTermsOfServiceConsent: {
                        requestType:
                          'squareup.buyerportal.merchantportal.loyalty.UpdateTermsOfServiceConsentRequest',
                        responseType:
                          'squareup.buyerportal.merchantportal.loyalty.UpdateTermsOfServiceConsentResponse',
                      },
                      RetrievePersonalizedPassDownloadUrl: {
                        requestType:
                          'squareup.buyerportal.merchantportal.loyalty.RetrievePersonalizedPassDownloadUrlRequest',
                        responseType:
                          'squareup.buyerportal.merchantportal.loyalty.RetrievePersonalizedPassDownloadUrlResponse',
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.loyalty?.nested?.LoyaltyService
) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE LoyaltyService @ squareup/buyerportal/merchantportal/loyalty/service.proto at 12:1
 */
export const LoyaltyService = $root.lookupService(
  'squareup.buyerportal.merchantportal.loyalty.LoyaltyService'
);
