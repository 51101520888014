/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      tabuloid: {
        nested: {
          HttpServiceOptions: {
            fields: {
              path: {
                type: 'string',
                id: 1,
                rule: 'optional',
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.tabuloid?.nested?.HttpServiceOptions) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE HttpServiceOptions @ squareup/tabuloid/tabuloid.proto at 23:1
 */
export const HttpServiceOptions = $root.lookupType(
  'squareup.tabuloid.HttpServiceOptions'
);
fixType(HttpServiceOptions);
Builder.createAndAttachToType(HttpServiceOptions);
