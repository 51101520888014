import { Benefit } from 'rpc/model/squareup/memberships/model/benefit';
import { BenefitPackage } from 'rpc/model/squareup/memberships/model/benefit-package';
import i18n from 'i18n';
import { DEFAULT_POINTS_TERMINOLOGY } from 'routes/merchant-scoped-portal/integrations/loyalty/utils/program';
import { LoyaltyProgramTerminology } from 'rpc/model/squareup/card/balance/loyalty_api/model/loyalty-program';

export interface TierAttributes {
  uid: string;
  name: string;
  benefits: ReadonlyArray<Benefit>;
  pointThreshold?: number;
  pointsTerminology?: LoyaltyProgramTerminology;
}

export class Tier {
  uid: string;
  name: string;
  benefits: ReadonlyArray<Benefit>;
  pointThreshold?: number;
  pointsTerminology: LoyaltyProgramTerminology;

  constructor(props: TierAttributes) {
    this.uid = props.uid;
    this.name = props.name;
    this.benefits = props.benefits;
    this.pointThreshold = props.pointThreshold;
    this.pointsTerminology =
      props.pointsTerminology ?? DEFAULT_POINTS_TERMINOLOGY;
  }

  static fromProto(
    benefitPackage: BenefitPackage,
    pointsTerminology?: LoyaltyProgramTerminology
  ): Tier {
    const { uid, name, benefits, eligibilityCriteria } = benefitPackage;
    return new Tier({
      benefits,
      name,
      pointThreshold: eligibilityCriteria?.[0]?.earnCriteria?.threshold,
      pointsTerminology,
      uid,
    });
  }

  get formattedRequirement(): string {
    if (this.pointThreshold) {
      return i18n.t('loyalty.common.points', {
        count: this.pointThreshold,
        pointsTerminology:
          this.pointThreshold === 1
            ? this.pointsTerminology.one
            : this.pointsTerminology.other,
      });
    }
    return '';
  }

  get formattedBenefits(): string[] {
    return this.benefits.map((benefit) => {
      switch (benefit.type) {
        case Benefit.Type.LOYALTY_POINTS_ADDITION: {
          return i18n.t('loyalty.tiers.benefits.pointsAddition', {
            count: benefit.loyaltyPointsAddition?.pointsAddition,
            pointsTerminology:
              benefit.loyaltyPointsAddition?.pointsAddition === 1
                ? this.pointsTerminology.one
                : this.pointsTerminology.other,
          });
        }
        case Benefit.Type.LOYALTY_POINTS_MULTIPLIER: {
          return i18n.t('loyalty.tiers.benefits.pointsMultiplier', {
            multiplier: Number(
              benefit.loyaltyPointsMultiplier?.pointsMultiplier
            ),
            pointsTerminology: this.pointsTerminology.other,
          });
        }
        case Benefit.Type.UNENFORCED: {
          return benefit.unenforced?.description ?? '';
        }
        default: {
          return '';
        }
      }
    });
  }
}
