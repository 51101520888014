/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import '../../checkoutfe/extensions/extensions';
import '../../common/token';
import '../../items/items';
import '../../connect/v2/common/money';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      omg: {
        nested: {
          pos: {
            nested: {
              OpenTicket: {
                fields: {
                  ticketOwner: {
                    type: 'squareup.checkoutfe.extensions.BillModelMirrors.CreatorDetails',
                    id: 1,
                    rule: 'optional',
                  },
                  predefinedTicket: {
                    type: 'squareup.omg.pos.OpenTicket.PredefinedTicket',
                    id: 2,
                    rule: 'optional',
                  },
                  clientCreatedAt: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  deletedLineItems: {
                    type: 'squareup.omg.pos.OpenTicket.Tombstone',
                    id: 4,
                    rule: 'repeated',
                  },
                  mutableFieldExtension: {
                    type: 'squareup.omg.pos.OpenTicket.MutableFieldExtension',
                    id: 5,
                    rule: 'optional',
                  },
                  originalOpenTicketTokenPair: {
                    type: 'squareup.common.token.TokenPair',
                    id: 6,
                    rule: 'optional',
                  },
                  originalVectorClockVersion: {
                    type: 'int64',
                    id: 7,
                    rule: 'optional',
                  },
                },
                nested: {
                  PredefinedTicket: {
                    fields: {
                      ticketGroup: {
                        type: 'squareup.api.items.TicketGroup',
                        id: 1,
                        rule: 'optional',
                      },
                      ticketTemplate: {
                        type: 'squareup.api.items.TicketTemplate',
                        id: 2,
                        rule: 'optional',
                      },
                    },
                  },
                  Tombstone: {
                    fields: {
                      uid: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      deletedObjectUid: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                    },
                  },
                  MutableFieldExtension: {
                    fields: {
                      name: {
                        type: 'squareup.omg.pos.OpenTicket.MutableFieldExtension.MutableFieldContext',
                        id: 1,
                        rule: 'optional',
                      },
                      note: {
                        type: 'squareup.omg.pos.OpenTicket.MutableFieldExtension.MutableFieldContext',
                        id: 2,
                        rule: 'optional',
                      },
                      ticketOwner: {
                        type: 'squareup.omg.pos.OpenTicket.MutableFieldExtension.MutableFieldContext',
                        id: 3,
                        rule: 'optional',
                      },
                      predefinedTicket: {
                        type: 'squareup.omg.pos.OpenTicket.MutableFieldExtension.MutableFieldContext',
                        id: 4,
                        rule: 'optional',
                      },
                    },
                    nested: {
                      MutableFieldContext: {
                        fields: {
                          lastEditedTime: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                        },
                      },
                    },
                  },
                },
              },
              LifecycleEvent: {
                fields: {
                  uid: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  eventType: {
                    type: 'squareup.omg.pos.LifecycleEvent.EventType',
                    id: 2,
                    rule: 'optional',
                  },
                  createdAt: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  creatorDetails: {
                    type: 'squareup.checkoutfe.extensions.BillModelMirrors.CreatorDetails',
                    id: 4,
                    rule: 'optional',
                  },
                  ownershipTransferDetails: {
                    type: 'squareup.omg.pos.LifecycleEvent.OwnershipTransferDetails',
                    id: 5,
                    rule: 'optional',
                  },
                },
                nested: {
                  OwnershipTransferDetails: {
                    fields: {
                      transferFromEmployee: {
                        type: 'squareup.checkoutfe.extensions.BillModelMirrors.Employee',
                        id: 1,
                        rule: 'optional',
                      },
                      transferToEmployee: {
                        type: 'squareup.checkoutfe.extensions.BillModelMirrors.Employee',
                        id: 2,
                        rule: 'optional',
                      },
                    },
                  },
                  EventType: {
                    values: {
                      DO_NOT_USE: 0,
                      CLOSE: 1,
                      REOPEN: 2,
                      OWNERSHIP_TRANSFER: 3,
                      OPEN: 4,
                    },
                  },
                },
              },
              InheritedLineItemDetails: {
                fields: {
                  inheritedEventUids: {
                    type: 'string',
                    id: 1,
                    rule: 'repeated',
                  },
                  createReason: {
                    type: 'squareup.omg.pos.InheritedLineItemDetails.CreateReason',
                    id: 2,
                    rule: 'optional',
                  },
                },
                nested: {
                  CreateReason: {
                    values: {
                      DO_NOT_USE: 0,
                      REOPEN: 1,
                      SPLIT: 2,
                      MOVE: 3,
                    },
                  },
                },
              },
              PosOrderExtension: {
                fields: {
                  openTicket: {
                    type: 'squareup.omg.pos.OpenTicket',
                    id: 1,
                    rule: 'optional',
                  },
                  lifecycleEvents: {
                    type: 'squareup.omg.pos.LifecycleEvent',
                    id: 2,
                    rule: 'repeated',
                  },
                  receiptDisplayDetails: {
                    type: 'squareup.checkoutfe.extensions.BillModelMirrors.ReceiptDisplayDetails',
                    id: 3,
                    keyType: 'string',
                  },
                  preAuthPaymentId: {
                    type: 'string',
                    id: 4,
                    rule: 'optional',
                  },
                  billEventVersion: {
                    type: 'int32',
                    id: 6,
                    rule: 'optional',
                  },
                  selfCheckout: {
                    type: 'squareup.omg.pos.SelfCheckout',
                    id: 7,
                    rule: 'optional',
                  },
                },
              },
              SignInType: {
                values: {
                  DO_NOT_USE: 0,
                  DEVICE_CODE: 1,
                  USERNAME_PASSWORD: 2,
                },
              },
              PosLineItemExtension: {
                fields: {
                  inheritedLineItemDetails: {
                    type: 'squareup.omg.pos.InheritedLineItemDetails',
                    id: 1,
                    rule: 'optional',
                  },
                  deviceCredentialToken: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  signInType: {
                    type: 'squareup.omg.pos.SignInType',
                    id: 3,
                    rule: 'optional',
                  },
                  kitchenName: {
                    type: 'string',
                    id: 4,
                    rule: 'optional',
                  },
                },
              },
              PosTaxExtension: {
                fields: {
                  appliedToMoney: {
                    type: 'squareup.connect.v2.common.Money',
                    id: 1,
                    rule: 'optional',
                  },
                  afterApplicationTotalMoney: {
                    type: 'squareup.connect.v2.common.Money',
                    id: 2,
                    rule: 'optional',
                  },
                },
              },
              PosModifierExtension: {
                fields: {
                  hideFromCustomer: {
                    type: 'bool',
                    id: 1,
                    rule: 'optional',
                  },
                },
              },
              SelfCheckout: {
                fields: {
                  selfCheckoutPaymentFeature: {
                    type: 'squareup.omg.pos.SelfCheckout.SelfCheckoutPaymentFeature',
                    id: 1,
                    rule: 'optional',
                  },
                },
                nested: {
                  SelfCheckoutPaymentFeature: {
                    values: {
                      DO_NOT_USE: 0,
                      SCAN_TO_PAY: 1,
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.omg?.nested?.pos?.nested?.OpenTicket &&
  !$root.nested?.squareup?.nested?.omg?.nested?.pos?.nested?.OpenTicket?.nested
    ?.PredefinedTicket &&
  !$root.nested?.squareup?.nested?.omg?.nested?.pos?.nested?.OpenTicket?.nested
    ?.Tombstone &&
  !$root.nested?.squareup?.nested?.omg?.nested?.pos?.nested?.OpenTicket?.nested
    ?.MutableFieldExtension &&
  !$root.nested?.squareup?.nested?.omg?.nested?.pos?.nested?.OpenTicket?.nested
    ?.MutableFieldExtension?.nested?.MutableFieldContext &&
  !$root.nested?.squareup?.nested?.omg?.nested?.pos?.nested?.LifecycleEvent &&
  !$root.nested?.squareup?.nested?.omg?.nested?.pos?.nested?.LifecycleEvent
    ?.nested?.OwnershipTransferDetails &&
  !$root.nested?.squareup?.nested?.omg?.nested?.pos?.nested?.LifecycleEvent
    ?.nested?.EventType &&
  !$root.nested?.squareup?.nested?.omg?.nested?.pos?.nested
    ?.InheritedLineItemDetails &&
  !$root.nested?.squareup?.nested?.omg?.nested?.pos?.nested
    ?.InheritedLineItemDetails?.nested?.CreateReason &&
  !$root.nested?.squareup?.nested?.omg?.nested?.pos?.nested
    ?.PosOrderExtension &&
  !$root.nested?.squareup?.nested?.omg?.nested?.pos?.nested?.SignInType &&
  !$root.nested?.squareup?.nested?.omg?.nested?.pos?.nested
    ?.PosLineItemExtension &&
  !$root.nested?.squareup?.nested?.omg?.nested?.pos?.nested?.PosTaxExtension &&
  !$root.nested?.squareup?.nested?.omg?.nested?.pos?.nested
    ?.PosModifierExtension &&
  !$root.nested?.squareup?.nested?.omg?.nested?.pos?.nested?.SelfCheckout &&
  !$root.nested?.squareup?.nested?.omg?.nested?.pos?.nested?.SelfCheckout
    ?.nested?.SelfCheckoutPaymentFeature
) {
  $root.addJSON(jsonData);
}

/**
 * Information about the open ticket this Order represents
 *
 * SOURCE OpenTicket @ squareup/omg/pos/extensions.proto at 21:1
 */
export const OpenTicket = $root.lookupType('squareup.omg.pos.OpenTicket');
fixType(OpenTicket);
Builder.createAndAttachToType(OpenTicket);
/**
 * The predefined ticket details associated with this open ticket.
 *
 * Merchants can create predefined ticket groups and ticket templates to implement a rudimentary form of table management.
 * A ticket group has many ticket templates.  Ticket groups would be something like "Bar" or "Patio", while ticket templates
 * would be something like "Table 1", "Bar 2", etc.
 *
 * Even with predefined tickets enabled, merchants can create "custom" tickets that do not have a ticket template.  These custom
 * tickets can still have a ticket group.  Think of a custom ticket named "Guy In Red Shirt" assigned to the ticket group "Bar"
 * (because he left his credit card at the bar).
 *
 * SOURCE PredefinedTicket @ squareup/omg/pos/extensions.proto at 33:3
 */
OpenTicket.PredefinedTicket = $root.lookupType(
  'squareup.omg.pos.OpenTicket.PredefinedTicket'
);
fixType(OpenTicket.PredefinedTicket);
Builder.createAndAttachToType(OpenTicket.PredefinedTicket);
/**
 * Represents objects that have been deleted from this Order.
 *
 * When Clients delete an item, OrdersFE is expected to additionally set a Tombstone. Tombstones are used to prevent items
 * from being re-created if there are concurrent edits to a item and one writer deletes the item.
 *
 * SOURCE Tombstone @ squareup/omg/pos/extensions.proto at 44:3
 */
OpenTicket.Tombstone = $root.lookupType(
  'squareup.omg.pos.OpenTicket.Tombstone'
);
fixType(OpenTicket.Tombstone);
Builder.createAndAttachToType(OpenTicket.Tombstone);
/**
 * Holds metadata about mutable fields. This data is used to support "Last Edit Wins" behavior, where conflicts
 * are resolved based on the time that the field was last edited on a POS.
 *
 * SOURCE MutableFieldExtension @ squareup/omg/pos/extensions.proto at 55:3
 */
OpenTicket.MutableFieldExtension = $root.lookupType(
  'squareup.omg.pos.OpenTicket.MutableFieldExtension'
);
fixType(OpenTicket.MutableFieldExtension);
Builder.createAndAttachToType(OpenTicket.MutableFieldExtension);
/**
 * Metadata about a single mutable field on the Open Ticket.
 *
 * SOURCE MutableFieldContext @ squareup/omg/pos/extensions.proto at 60:5
 */
OpenTicket.MutableFieldExtension.MutableFieldContext = $root.lookupType(
  'squareup.omg.pos.OpenTicket.MutableFieldExtension.MutableFieldContext'
);
fixType(OpenTicket.MutableFieldExtension.MutableFieldContext);
Builder.createAndAttachToType(
  OpenTicket.MutableFieldExtension.MutableFieldContext
);
/**
 * Contains details about the Open Ticket lifecycle changes
 *
 * SOURCE LifecycleEvent @ squareup/omg/pos/extensions.proto at 117:1
 */
export const LifecycleEvent = $root.lookupType(
  'squareup.omg.pos.LifecycleEvent'
);
fixType(LifecycleEvent, {
  eventType: { required: true },
  createdAt: { required: true },
});
Builder.createAndAttachToType(LifecycleEvent);
/**
 * Details about the transfer of ticket ownership
 *
 * SOURCE OwnershipTransferDetails @ squareup/omg/pos/extensions.proto at 119:3
 */
LifecycleEvent.OwnershipTransferDetails = $root.lookupType(
  'squareup.omg.pos.LifecycleEvent.OwnershipTransferDetails'
);
fixType(LifecycleEvent.OwnershipTransferDetails, {
  transferToEmployee: { required: true },
});
Builder.createAndAttachToType(LifecycleEvent.OwnershipTransferDetails);
/**
 * The step in the open ticket lifecycle.
 *
 * SOURCE EventType @ squareup/omg/pos/extensions.proto at 133:3
 */
LifecycleEvent.EventType = $root.lookupEnum(
  'squareup.omg.pos.LifecycleEvent.EventType'
).values;
/**
 * Metadata about an LineItem that was created from a previous itemization.
 * This should be populated whenever a line item is created from another line item (e.g. in a reopen, split, or move).
 * Events should be copied in full, without modification, when a line item is created from a previous line item.
 *
 * SOURCE InheritedLineItemDetails @ squareup/omg/pos/extensions.proto at 194:1
 */
export const InheritedLineItemDetails = $root.lookupType(
  'squareup.omg.pos.InheritedLineItemDetails'
);
fixType(InheritedLineItemDetails, {
  createReason: { required: true },
});
Builder.createAndAttachToType(InheritedLineItemDetails);
/**
 * Indicates what caused this line item to be created.
 *
 * SOURCE CreateReason @ squareup/omg/pos/extensions.proto at 196:3
 */
InheritedLineItemDetails.CreateReason = $root.lookupEnum(
  'squareup.omg.pos.InheritedLineItemDetails.CreateReason'
).values;
/**
 * Extensions for features specific to the POS, but not a particular VPOS
 *
 * SOURCE PosOrderExtension @ squareup/omg/pos/extensions.proto at 227:1
 */
export const PosOrderExtension = $root.lookupType(
  'squareup.omg.pos.PosOrderExtension'
);
fixType(PosOrderExtension);
Builder.createAndAttachToType(PosOrderExtension);
/**
 * SOURCE SignInType @ squareup/omg/pos/extensions.proto at 253:1
 */
export const SignInType = $root.lookupEnum(
  'squareup.omg.pos.SignInType'
).values;
/**
 * Extensions for features specific to the POS, but not a particular VPOS.
 *
 * SOURCE PosLineItemExtension @ squareup/omg/pos/extensions.proto at 260:1
 */
export const PosLineItemExtension = $root.lookupType(
  'squareup.omg.pos.PosLineItemExtension'
);
fixType(PosLineItemExtension);
Builder.createAndAttachToType(PosLineItemExtension);
/**
 * Extensions for features specific to the POS, but not a particular VPOS
 *
 * SOURCE PosTaxExtension @ squareup/omg/pos/extensions.proto at 286:1
 */
export const PosTaxExtension = $root.lookupType(
  'squareup.omg.pos.PosTaxExtension'
);
fixType(PosTaxExtension);
Builder.createAndAttachToType(PosTaxExtension);
/**
 * Extensions for features specific to the POS, but not a particular VPOS
 *
 * SOURCE PosModifierExtension @ squareup/omg/pos/extensions.proto at 300:1
 */
export const PosModifierExtension = $root.lookupType(
  'squareup.omg.pos.PosModifierExtension'
);
fixType(PosModifierExtension);
Builder.createAndAttachToType(PosModifierExtension);
/**
 * SOURCE SelfCheckout @ squareup/omg/pos/extensions.proto at 304:1
 */
export const SelfCheckout = $root.lookupType('squareup.omg.pos.SelfCheckout');
fixType(SelfCheckout);
Builder.createAndAttachToType(SelfCheckout);
/**
 * SOURCE SelfCheckoutPaymentFeature @ squareup/omg/pos/extensions.proto at 305:3
 */
SelfCheckout.SelfCheckoutPaymentFeature = $root.lookupEnum(
  'squareup.omg.pos.SelfCheckout.SelfCheckoutPaymentFeature'
).values;
