import { MarketButton } from '@market/react';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { ModalType, openModal } from 'store/modalSlice';
import { selectIdentifierCollection, selectPrimaryId } from 'store/buyerSlice';
import { IdentifierType } from 'routes/profile/models/Identifier';
import { AppState } from 'store';
import { buyerportalCdpClient } from 'services/tracking/cdp/clients/buyerportal';
import { startDownloadDataProperties } from 'services/tracking/cdp/events/profile';
import { useTranslation } from 'react-i18next';

const DownloadData: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const emails = useSelector((state: AppState) =>
    selectIdentifierCollection(state, IdentifierType.Email)
  );
  const primaryEmailId = useSelector(
    (appState: AppState) => selectPrimaryId(appState, IdentifierType.Email)!
  );
  const primaryEmail =
    emails.length > 0
      ? emails.find((email) => email.token === primaryEmailId)
      : undefined;

  // funneling the idempotency key from here will ensure the backend
  // doesn't accept multiple export requests on the same page load
  const idempotencyKey = useRef(uuidv4());

  const requestDownload = () => {
    buyerportalCdpClient.track(
      'Start Download Data',
      startDownloadDataProperties()
    );

    dispatch(
      openModal({
        type: ModalType.DownloadData,
        props: {
          idempotencyKey: idempotencyKey.current,
          defaultEmail: primaryEmail?.displayValue || '',
        },
      })
    );
  };

  return (
    <>
      <h3 className={'heading-20 my-0'}>
        {t('profile.account.downloadData.title')}
      </h3>
      <p className={'paragraph-30 mt-3 mb-4 text-grey'}>
        {t('profile.account.downloadData.subtitle')}
      </p>
      <MarketButton onClick={requestDownload}>
        {t('profile.account.downloadData.requestDownload')}
      </MarketButton>
    </>
  );
};

export default DownloadData;
