// eslint-disable-next-line filenames/match-exported
import React, { useMemo, useState } from 'react';
import {
  MarketAccessory,
  MarketBanner,
  MarketInputSearch,
  MarketList,
  MarketRow,
} from '@market/react';
import ModuleLoadFailed from '../common/errors/ModuleLoadFailed';
import ModuleLoading from '../common/loading/ModuleLoading';
import Page from '../layout/Page';
import Chevron from 'svgs/market-icons/Chevron';
import { theme } from 'tailwind.config';
import { Payment } from '../models/Payment';
import { formatConnectMoney } from 'utils/currency';
import { DateFormat, dayjs } from 'utils/date';
import { useListOrdersQuery } from 'store/query/api-extensions/orderBaseRpcs';
import Store from 'svgs/market-icons/Store';
import useCdpPageTracking from 'utils/custom-react-hooks/useCdpPageTracking';
import { useTranslation } from 'react-i18next';
import { viewPurchaseHistoryProperties } from 'services/tracking/cdp/events/purchaseHistory';
import { useHistory } from 'react-router-dom';
import { environment } from 'utils/environment';

export const BASE_RECEIPT_URL = environment().isProduction
  ? 'https://squareup.com/receipt/preview'
  : 'https://squareupstaging.com/receipt/preview';

const PurchaseHistory: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    data: payments,
    isLoading,
    isError: didLoadingFail,
  } = useListOrdersQuery();

  useCdpPageTracking({
    didLoadingFail,
    isLoading,
    properties: viewPurchaseHistoryProperties(payments?.length || 0),
  });

  const [searchText, setSearchText] = useState<string>('');
  const filteredPayments = useMemo(
    () =>
      (payments || []).filter((payment: Payment) =>
        payment.merchantInfo.displayName
          .toLowerCase()
          .includes(searchText.toLowerCase())
      ),
    [payments, searchText]
  );

  const handleMarketInputSearchValueChange = (e: {
    detail: { current: string };
  }) => {
    const { current } = e.detail;
    setSearchText(current);
  };

  const formatItemsText = (itemsCount: number) => {
    if (itemsCount === 1) {
      return `1 ${t('profile.purchaseHistory.items.single')}`;
    } else {
      return `${itemsCount} ${t('profile.purchaseHistory.items.multiple')}`;
    }
  };

  if (isLoading) {
    return <ModuleLoading />;
  }

  if (didLoadingFail) {
    return <ModuleLoadFailed />;
  }

  return (
    <Page>
      {/* Remove before GA */}
      <MarketBanner>
        Purchase History is under development and is exposed to Block employees
        only. Please reach out in{' '}
        <a href="https://square.enterprise.slack.com/archives/CJWA97E91">
          #square-profile
        </a>{' '}
        with questions or feedback.
      </MarketBanner>

      <h2 className={'heading-30'}>{t('profile.purchaseHistory.title')}</h2>
      <div className={'flex grow items-center justify-between mb-4'}>
        <h3 className={'heading-20 my-0'}>
          {t('profile.purchaseHistory.completed')}
        </h3>
      </div>
      <MarketInputSearch
        onMarketInputSearchValueChange={handleMarketInputSearchValueChange}
        placeholder={t('common.search')}
        className="mb-4"
        value={searchText}
      />
      <MarketList interactive role={'list'}>
        {(filteredPayments || []).map((payment: Payment) => (
          <MarketRow
            role={'option'}
            onMarketRowSelected={() => {
              if (
                payment.receipt.digitalReceipts.length === 1 ||
                payment.receipt.paymentIds.length === 1
              ) {
                window.open(
                  `${BASE_RECEIPT_URL}/${
                    payment.receipt.digitalReceipts[0]?.paymentToken ||
                    payment.receipt.paymentIds[0]
                  }`,
                  '_blank'
                );
              } else {
                // we use the first paymentId as the identifier
                history.push(
                  `/purchasehistory/${payment.receipt.paymentIds[0]}`
                );
              }
            }}
            key={payment.token}
          >
            <div className={'flex items-center py-4'}>
              <MarketAccessory size={'image'} slot={'icon'}>
                {payment.merchantInfo.imageUrl ? (
                  <img
                    alt={payment.merchantInfo.displayName}
                    src={payment.merchantInfo.imageUrl}
                  />
                ) : (
                  <Store />
                )}
              </MarketAccessory>
              <div className={'ml-4'}>
                <label className={'font-medium'}>
                  {payment.merchantInfo.displayName}
                </label>
                <div className={'paragraph-20 text-grey'}>
                  {payment.totalItems > 0 &&
                    `${formatItemsText(payment.totalItems)}• `}
                  {payment.totalMoney && formatConnectMoney(payment.totalMoney)}
                </div>
                <div className={'paragraph-20 text-grey'}>
                  {dayjs(payment.createdAt).format(DateFormat.LL)}
                </div>
              </div>
              <div className={'ml-auto flex items-center'}>
                <MarketAccessory size={'icon'} slot={'trigger'} role={'button'}>
                  <Chevron
                    direction="right"
                    fill={theme.extend.colors.lightgrey}
                  />
                </MarketAccessory>
              </div>
            </div>
          </MarketRow>
        ))}
      </MarketList>
    </Page>
  );
};

export default PurchaseHistory;
