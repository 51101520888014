// eslint-disable-next-line filenames/match-exported
import React, { useState } from 'react';
import {
  ContactInformationIdentifierType,
  IdentifierType,
} from 'routes/profile/models/Identifier';
import RequestDeleteData from './RequestDeleteData';
import SuccessDeleteData from './SuccessDeleteData';
import VerifyDeleteData from './VerifyDeleteData';

enum DeleteDataStep {
  Request = 0,
  Verify = 1,
  Success = 2,
}

type DeleteDataProps = {
  onCancel: () => void;
};

const DeleteData: React.FC<DeleteDataProps> = (props) => {
  const [step, setStep] = useState<DeleteDataStep>(DeleteDataStep.Request);
  const [identifierType, setIdentifierType] =
    useState<ContactInformationIdentifierType>(IdentifierType.Email);
  const [identifierValue, setIdentifierValue] = useState<string>('');
  const [isExistingAccount, setIsExistingAccount] = useState<boolean>(false);

  /*
   * Renders the component corresponding to the current step of the delete data flow.
   */
  const renderStep = () => {
    switch (step) {
      case DeleteDataStep.Request: {
        return (
          <RequestDeleteData
            onClickBack={props.onCancel}
            onClickNext={(
              collectedType,
              collectedValue,
              collectedIsExistingAccount
            ) => {
              setIdentifierType(collectedType);
              setIdentifierValue(collectedValue);
              setIsExistingAccount(collectedIsExistingAccount);
              setStep(step + 1);
            }}
          />
        );
      }
      case DeleteDataStep.Verify: {
        return (
          <VerifyDeleteData
            identifierType={identifierType}
            identifierValue={identifierValue}
            isExistingAccount={isExistingAccount}
            onClickBack={() => setStep(step - 1)}
            onClickNext={() => setStep(step + 1)}
          />
        );
      }
      case DeleteDataStep.Success: {
        return (
          <SuccessDeleteData
            identifierType={identifierType}
            identifierValue={identifierValue}
            onRestart={props.onCancel}
          />
        );
      }
    }
  };

  return <>{renderStep()}</>;
};

export default DeleteData;
