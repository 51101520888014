import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const SmallLoadingIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
      <circle
        opacity="0.2"
        cx="10"
        cy="10"
        r="8"
        stroke="#F5F6F7"
        strokeWidth="3"
        fill="none"
      />
      <path
        fillOpacity="0"
        fill="#000000"
        strokeWidth="3"
        stroke="#ffffff"
        d="m18,10c0,-4.41828 -3.5817,-8 -8,-8"
      />
    </SvgIcon>
  );
};

export default SmallLoadingIcon;
