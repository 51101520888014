/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../../Fixes';
import Builder from '../../../../../Builder';
import '../../common/user_attributes';
import '../../common/token';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      feature: {
        nested: {
          relay: {
            nested: {
              flags: {
                nested: {
                  message: {
                    nested: {
                      UserContextType: {
                        values: {
                          SQUARE_MOBILE_DEVICE: 0,
                          MERCHANT: 1,
                          UNIT: 2,
                          PERSON: 3,
                        },
                      },
                      MerchantContext: {
                        fields: {
                          merchantToken: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                        },
                      },
                      UnitContext: {
                        fields: {
                          unitToken: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                        },
                      },
                      PersonContext: {
                        fields: {
                          personToken: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                        },
                      },
                      SquareMobileDevice: {
                        fields: {
                          deviceId: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                          additionalDeviceAttributes: {
                            type: 'squareup.feature.relay.common.UserAttributes',
                            id: 2,
                            rule: 'optional',
                          },
                        },
                      },
                      UserContext: {
                        fields: {
                          type: {
                            type: 'squareup.feature.relay.flags.message.UserContextType',
                            id: 1,
                            rule: 'optional',
                          },
                          device: {
                            type: 'squareup.feature.relay.flags.message.SquareMobileDevice',
                            id: 2,
                          },
                          merchant: {
                            type: 'squareup.feature.relay.flags.message.MerchantContext',
                            id: 3,
                          },
                          unit: {
                            type: 'squareup.feature.relay.flags.message.UnitContext',
                            id: 4,
                          },
                          person: {
                            type: 'squareup.feature.relay.flags.message.PersonContext',
                            id: 5,
                          },
                        },
                        nested: {
                          context: {
                            oneof: ['device', 'merchant', 'unit', 'person'],
                          },
                        },
                      },
                      MultiUserContext: {
                        fields: {
                          defaultType: {
                            type: 'squareup.feature.relay.flags.message.UserContextType',
                            id: 1,
                            rule: 'optional',
                          },
                          userContexts: {
                            type: 'squareup.feature.relay.flags.message.UserContext',
                            id: 2,
                            rule: 'repeated',
                          },
                        },
                      },
                      SingleUserContext: {
                        fields: {
                          userToken: {
                            type: 'squareup.feature.relay.common.Token',
                            id: 1,
                            rule: 'optional',
                          },
                          userAttributes: {
                            type: 'squareup.feature.relay.common.UserAttributes',
                            id: 2,
                            rule: 'optional',
                          },
                        },
                      },
                      MultipassSessionUserContext: {
                        fields: {
                          defaultType: {
                            type: 'squareup.feature.relay.flags.message.UserContextType',
                            id: 1,
                            rule: 'optional',
                          },
                          device: {
                            type: 'squareup.feature.relay.flags.message.SquareMobileDevice',
                            id: 2,
                            rule: 'optional',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.feature?.nested?.relay?.nested?.flags?.nested
    ?.message?.nested?.UserContextType &&
  !$root.nested?.squareup?.nested?.feature?.nested?.relay?.nested?.flags?.nested
    ?.message?.nested?.MerchantContext &&
  !$root.nested?.squareup?.nested?.feature?.nested?.relay?.nested?.flags?.nested
    ?.message?.nested?.UnitContext &&
  !$root.nested?.squareup?.nested?.feature?.nested?.relay?.nested?.flags?.nested
    ?.message?.nested?.PersonContext &&
  !$root.nested?.squareup?.nested?.feature?.nested?.relay?.nested?.flags?.nested
    ?.message?.nested?.SquareMobileDevice &&
  !$root.nested?.squareup?.nested?.feature?.nested?.relay?.nested?.flags?.nested
    ?.message?.nested?.UserContext &&
  !$root.nested?.squareup?.nested?.feature?.nested?.relay?.nested?.flags?.nested
    ?.message?.nested?.MultiUserContext &&
  !$root.nested?.squareup?.nested?.feature?.nested?.relay?.nested?.flags?.nested
    ?.message?.nested?.SingleUserContext &&
  !$root.nested?.squareup?.nested?.feature?.nested?.relay?.nested?.flags?.nested
    ?.message?.nested?.MultipassSessionUserContext
) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE UserContextType @ squareup/feature/relay/flags/message/context.proto at 12:1
 */
export const UserContextType = $root.lookupEnum(
  'squareup.feature.relay.flags.message.UserContextType'
).values;
/**
 * SOURCE MerchantContext @ squareup/feature/relay/flags/message/context.proto at 19:1
 */
export const MerchantContext = $root.lookupType(
  'squareup.feature.relay.flags.message.MerchantContext'
);
fixType(MerchantContext);
Builder.createAndAttachToType(MerchantContext);
/**
 * SOURCE UnitContext @ squareup/feature/relay/flags/message/context.proto at 23:1
 */
export const UnitContext = $root.lookupType(
  'squareup.feature.relay.flags.message.UnitContext'
);
fixType(UnitContext);
Builder.createAndAttachToType(UnitContext);
/**
 * SOURCE PersonContext @ squareup/feature/relay/flags/message/context.proto at 27:1
 */
export const PersonContext = $root.lookupType(
  'squareup.feature.relay.flags.message.PersonContext'
);
fixType(PersonContext);
Builder.createAndAttachToType(PersonContext);
/**
 * SOURCE SquareMobileDevice @ squareup/feature/relay/flags/message/context.proto at 31:1
 */
export const SquareMobileDevice = $root.lookupType(
  'squareup.feature.relay.flags.message.SquareMobileDevice'
);
fixType(SquareMobileDevice);
Builder.createAndAttachToType(SquareMobileDevice);
/**
 * SOURCE UserContext @ squareup/feature/relay/flags/message/context.proto at 36:1
 */
export const UserContext = $root.lookupType(
  'squareup.feature.relay.flags.message.UserContext'
);
fixType(UserContext);
Builder.createAndAttachToType(UserContext);
/**
 * SOURCE MultiUserContext @ squareup/feature/relay/flags/message/context.proto at 46:1
 */
export const MultiUserContext = $root.lookupType(
  'squareup.feature.relay.flags.message.MultiUserContext'
);
fixType(MultiUserContext);
Builder.createAndAttachToType(MultiUserContext);
/**
 * SOURCE SingleUserContext @ squareup/feature/relay/flags/message/context.proto at 51:1
 */
export const SingleUserContext = $root.lookupType(
  'squareup.feature.relay.flags.message.SingleUserContext'
);
fixType(SingleUserContext);
Builder.createAndAttachToType(SingleUserContext);
/**
 * SOURCE MultipassSessionUserContext @ squareup/feature/relay/flags/message/context.proto at 56:1
 */
export const MultipassSessionUserContext = $root.lookupType(
  'squareup.feature.relay.flags.message.MultipassSessionUserContext'
);
fixType(MultipassSessionUserContext);
Builder.createAndAttachToType(MultipassSessionUserContext);
