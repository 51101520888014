import React from 'react';
import { MarketList, MarketRow, MarketAccordionItem } from '@market/react';
import { logoutUrl } from 'utils/environment';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from 'store/modalSlice';
import { useTranslation } from 'react-i18next';
import { AppState } from 'store';
import { selectBoolFlag } from 'store/featureSlice';
import { BoolFlag } from '../models/Flags';
import { selectIdentifierCollection } from 'store/buyerSlice';
import { IdentifierType } from '../models/Identifier';

type SideNavProps = {
  showSignOut: boolean;
};

type NavItem = {
  name: string;
  url?: string;
  subitems?: NavItem[];
};

const SideNav: React.FC<SideNavProps> = ({ showSignOut }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const emails = useSelector((state: AppState) =>
    selectIdentifierCollection(state, IdentifierType.Email)
  );
  const showReceipts = useSelector((state: AppState) => {
    return (
      selectBoolFlag(state, BoolFlag.showReceipts) ||
      emails.find(
        (email) =>
          email.displayValue.endsWith('@squareup.com') ||
          email.displayValue.endsWith('@block.xyz')
      )
    );
  });

  const renderNavItem = (item: NavItem) => {
    if (!showSignOut && item.url === logoutUrl()) {
      return <></>;
    }

    if (item.subitems) {
      const isSubItemSelected = item.subitems.some((subitem) =>
        window.location.href.includes(subitem.url!)
      );

      return (
        <MarketAccordionItem
          name={item.name}
          size="medium"
          expanded={isSubItemSelected}
        >
          <span slot="label" className="font-medium text-base tracking-wider">
            {item.name}
          </span>
          {item.subitems.map((item) => {
            const isSelected = window.location.href.includes(item.url!);
            return (
              <div className="ml-4">
                <div
                  className={`pl-4 hover:transition-all hover:bg-marketBlueHover active:bg-marketBlueActive ${
                    isSelected ? 'bg-marketBlueHover text-emphasisBlue' : ''
                  } rounded-lg cursor-pointer`}
                >
                  {renderNavItem(item)}
                </div>
              </div>
            );
          })}
        </MarketAccordionItem>
      );
    }

    const isSelected = item.url && window.location.href.includes(item.url);

    return (
      <MarketRow
        key={item.name}
        onClick={() => {
          if (item.url! === logoutUrl()) {
            window.location.replace(logoutUrl());
          } else {
            history.push(item.url!);
            dispatch(closeModal());
          }
        }}
        value={item.name}
        selected={isSelected || undefined}
      >
        <span
          className={`font-medium text-black text-md ${
            isSelected ? 'text-emphasisBlue' : ''
          }`}
        >
          {item.name}
        </span>
        <div className="shadow-bottomDivider w-full h-1 absolute bottom-0"></div>
      </MarketRow>
    );
  };

  const items: NavItem[] = [
    {
      name: t('sidenav.paymentMethods'),
      url: '/paymentmethods',
    },
    {
      name: t('sidenav.profile'),
      url: '/account',
    },
    {
      name: t('sidenav.preferencesAndSettings'),
      subitems: [
        {
          name: t('sidenav.receiptSettings'),
          url: '/receiptsettings',
        },
        {
          name: t('sidenav.marketingPreferences'),
          url: '/marketingpreferences',
        },
      ],
    },
    {
      name: t('sidenav.signOut'),
      url: logoutUrl(),
    },
  ];

  if (showReceipts) {
    items.splice(0, 0, {
      name: 'Purchase history',
      url: '/purchasehistory',
      subitems: undefined,
    });
  }

  return (
    <nav className={'px-2'}>
      <MarketList slot="list" interactive>
        {items.map((item) => renderNavItem(item))}
      </MarketList>
    </nav>
  );
};

export default SideNav;
