import { AccountInfo } from 'rpc/model/squareup/buyerportal/merchantportal/loyalty/data';
import { LoyaltyAccount } from 'rpc/model/squareup/card/balance/loyalty_api/model/loyalty-account';

/*
 * Selects the best loyalty account for the buyer based on the buyer's primary phone token.
 * If a loyalty account is found with a direct phone match, it is returned.
 * Otherwise, the loyalty account with the most number of points is returned.
 *
 * @param buyerPrimaryPhoneToken - The buyer's primary phone token
 * @param accountInfoList - The list of account info objects to search for the best loyalty account
 * @returns The best loyalty account for the buyer
 */
export function selectBestLoyaltyAccount(
  accountInfoList: readonly AccountInfo[],
  buyerPrimaryPhoneToken?: string
): LoyaltyAccount | undefined {
  if (buyerPrimaryPhoneToken) {
    const directMatch = accountInfoList?.find(
      (accountInfo) =>
        accountInfo.phoneIdentifier?.id === buyerPrimaryPhoneToken
    )?.loyaltyAccount;
    if (directMatch) {
      return directMatch;
    }
  }

  // get the loyalty account with the most number of points
  // if direct phone match isn't found
  return accountInfoList?.reduce(
    (maxAccount: LoyaltyAccount | undefined, account: AccountInfo) => {
      const loyaltyAccount = account.loyaltyAccount;
      if (
        loyaltyAccount &&
        (!maxAccount ||
          loyaltyAccount.lifetimePoints > maxAccount.lifetimePoints)
      ) {
        return loyaltyAccount;
      }
      return maxAccount;
    },
    undefined
  );
}
