import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import OverviewTileContainer, {
  InternalLinkProps,
} from 'routes/merchant-scoped-portal/components/OverviewTileContainer';
import { MerchantPortalContext } from '../../../index';
import SignInCTA from 'routes/merchant-scoped-portal/components/SignInCta';
import {
  ClickFeatureEvent,
  EventName,
  FeatureFormat,
  FeatureID,
  FeatureName,
} from 'services/tracking/cdp/events/types';
import { buyerportalCdpClient } from 'services/tracking/cdp/clients/buyerportal';
import { useRetrieveAccountInfoForBuyerQuery } from 'store/query/api-extensions/loyalty';
import { getProgramDescription } from '../utils/program';
import LoyaltyAccountInfo from './LoyaltyAccountInfo';
import { LoyaltyProgram } from 'rpc/model/squareup/card/balance/loyalty_api/model/loyalty-program';
import { MarketButton } from '@market/react';
import useNavigateToLoyaltyOnboarding from 'utils/custom-react-hooks/loyalty/useNavigateToLoyaltyOnboarding';

interface LoyaltyOverviewTileProps {
  program: LoyaltyProgram;
}

const LoyaltyOverviewTile: React.FC<LoyaltyOverviewTileProps> = ({
  program,
}) => {
  const { t } = useTranslation();
  const mpContext = useContext(MerchantPortalContext);
  const { buyer, merchantOverview } = mpContext.merchantPortalBaseData ?? {};
  const { merchantId = '', merchantName = '' } = merchantOverview ?? {};
  const isAuthenticated = Boolean(buyer);
  const programDescription = getProgramDescription(program);
  const accountInfoResult = useRetrieveAccountInfoForBuyerQuery(merchantId, {
    skip: !isAuthenticated,
  });
  const isLoading =
    isAuthenticated &&
    (accountInfoResult.isLoading || accountInfoResult.isUninitialized);

  const loyaltyHeaderLinkProps: InternalLinkProps = {
    onClick: () => {
      const clickLoyaltyDetailsEvent: ClickFeatureEvent = {
        action_item: 'select_loyalty',
        additional_parameters: {
          // TODO: Fill in with loyalty data
          // loyalty_count: int,
          buyer_authenticated: Boolean(buyer),
          merchant_id: merchantId,
          merchant_name: merchantName,
        },
        event_description: 'clicks loyalty account',
        feature_format: FeatureFormat.PAGE,
        feature_id: FeatureID.MerchantOverviewPage,
        feature_name: FeatureName.MerchantOverviewPage,
      };
      buyerportalCdpClient.track(
        EventName.CLICK_FEATURE,
        clickLoyaltyDetailsEvent
      );
    },
    relativePath: '/loyalty',
    text: 'View details',
    type: 'internal',
  };

  const accounts = accountInfoResult.data;
  const hasAccounts = accounts && accounts.length > 0;
  const navigateToLoyaltyOnboarding = useNavigateToLoyaltyOnboarding();
  const enroll = useCallback(
    () => navigateToLoyaltyOnboarding(),
    [navigateToLoyaltyOnboarding]
  );

  return (
    <OverviewTileContainer
      headerLinkConfig={loyaltyHeaderLinkProps}
      isLoading={isLoading}
      title="Rewards"
    >
      <p>{programDescription}</p>
      {!isAuthenticated && (
        <SignInCTA
          displayText={t('profile.merchantProfile.loyaltyTile.signIn')}
          returnTo={window.location.href}
        />
      )}
      {hasAccounts &&
        accounts.map((accountInfo) => (
          <LoyaltyAccountInfo
            key={accountInfo.loyaltyAccount?.id}
            accountInfo={accountInfo}
            program={program}
          />
        ))}
      {isAuthenticated && !hasAccounts && (
        <MarketButton className="w-full" onClick={enroll} rank="primary">
          {t('loyalty.common.enrollNow')}
        </MarketButton>
      )}
    </OverviewTileContainer>
  );
};

export default LoyaltyOverviewTile;
