import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import SqLoading from 'components/SqLoading';
import { useLazyRetrieveGiftCardDetailBySecretQuery } from 'store/query';
import { openToast } from 'store/toastSlice';
import { useTranslation } from 'react-i18next';
import { ModalType, openModal } from 'store/modalSlice';

const AddGiftCardRedirect: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(search);
  const giftCardTokenSecret = searchParams.get('token');
  const dispatch = useDispatch();

  const [retrieveGiftCardDetailBySecret] =
    useLazyRetrieveGiftCardDetailBySecretQuery();

  useEffect(() => {
    if (giftCardTokenSecret) {
      retrieveGiftCardDetailBySecret(giftCardTokenSecret)
        .unwrap()
        .then((card) => {
          dispatch(
            openModal({
              type: ModalType.AddGiftCard,
              props: {
                cardToAdd: card,
                secret: giftCardTokenSecret,
              },
            })
          );
        })
        .catch(() => {
          dispatch(
            openToast({
              variant: 'critical',
              message: t('common.somethingWentWrong.retryable.apologetic'),
            })
          );
        });
    }

    history.push('/paymentmethods');
  }, [
    dispatch,
    history,
    t,
    retrieveGiftCardDetailBySecret,
    giftCardTokenSecret,
  ]);

  return (
    <div
      data-testid="add-gift-card-loading"
      className={'flex h-screen justify-center items-center'}
    >
      <SqLoading />
    </div>
  );
};

export default withRouter(AddGiftCardRedirect);
