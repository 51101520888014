/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      omg: {
        nested: {
          fulfillment: {
            nested: {
              CommitmentOwnerExtension: {
                fields: {
                  commitmentOwner: {
                    type: 'squareup.omg.fulfillment.CommitmentOwnerExtension.CommitmentOwner',
                    id: 1,
                    rule: 'optional',
                    options: {
                      default: 'SALE',
                    },
                  },
                },
                nested: {
                  CommitmentOwner: {
                    values: {
                      COMMITMENT_OWNER_DO_NOT_USE: 0,
                      SALE: 1,
                      FULFILLMENT: 2,
                      CLIENT_OPT_OUT: 3,
                    },
                  },
                },
              },
              ShouldCommitLineItemExtension: {
                fields: {
                  shouldCommitInventory: {
                    type: 'bool',
                    id: 1,
                    rule: 'optional',
                    options: {
                      default: true,
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.omg?.nested?.fulfillment?.nested
    ?.CommitmentOwnerExtension &&
  !$root.nested?.squareup?.nested?.omg?.nested?.fulfillment?.nested
    ?.CommitmentOwnerExtension?.nested?.CommitmentOwner &&
  !$root.nested?.squareup?.nested?.omg?.nested?.fulfillment?.nested
    ?.ShouldCommitLineItemExtension
) {
  $root.addJSON(jsonData);
}

/**
 * Order-level extension. Information about the commitment owner, relating to Decrement on Fulfillment
 *
 * SOURCE CommitmentOwnerExtension @ squareup/omg/fulfillment/extensions.proto at 16:1
 */
export const CommitmentOwnerExtension = $root.lookupType(
  'squareup.omg.fulfillment.CommitmentOwnerExtension'
);
fixType(CommitmentOwnerExtension);
Builder.createAndAttachToType(CommitmentOwnerExtension);
/**
 * --
 * @desc The commitment owner enum, SALE = decrement stock on sale,
 * FULFILLMENT = decrement stock on fulfillment
 * --
 *
 * SOURCE CommitmentOwner @ squareup/omg/fulfillment/extensions.proto at 37:3
 */
CommitmentOwnerExtension.CommitmentOwner = $root.lookupEnum(
  'squareup.omg.fulfillment.CommitmentOwnerExtension.CommitmentOwner'
).values;
/**
 * Line item - level extension. Information about whether or not auto-commitment of inventory should proceed, relating to Decrement on Fulfillment
 *
 * SOURCE ShouldCommitLineItemExtension @ squareup/omg/fulfillment/extensions.proto at 51:1
 */
export const ShouldCommitLineItemExtension = $root.lookupType(
  'squareup.omg.fulfillment.ShouldCommitLineItemExtension'
);
fixType(ShouldCommitLineItemExtension);
Builder.createAndAttachToType(ShouldCommitLineItemExtension);
