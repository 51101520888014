import { Factory } from 'rosie';
import { Member } from 'rpc/model/squareup/memberships/model/member';
import { ProgressMetric } from 'rpc/model/squareup/memberships/model/progress-metric';
import { BenefitPackageFactory } from 'routes/merchant-scoped-portal/integrations/loyalty/testing/factories/MembershipProgramFactory';

export const BenefitPackageStatusFactory =
  new Factory<Member.BenefitPackageStatus>()
    .option('earnedAt', () => new Date())
    .option('expiresAt', ['earnedAt'], (earnedAt: Date) => {
      const expiresAt = new Date(earnedAt);
      expiresAt.setFullYear(earnedAt.getFullYear() + 1);
      return expiresAt;
    })
    .option('progressValue', 600)
    .attr('type', Member.BenefitPackageStatus.Type.EARNED)
    .attr('package', () => BenefitPackageFactory.build({}, { threshold: 1000 }))
    .after((benefitPackageStatus, { earnedAt, expiresAt, progressValue }) => {
      const builder = new Member.BenefitPackageStatus.Builder()
        .type(benefitPackageStatus.type)
        .package(benefitPackageStatus.package);

      switch (benefitPackageStatus.type) {
        case Member.BenefitPackageStatus.Type.EARNED: {
          builder.earnedData(
            new Member.BenefitPackageStatus.EarnedData.Builder()
              .earnedAt(earnedAt.toISOString())
              .expiresAt(expiresAt.toISOString())
              .build()
          );
          break;
        }
        case Member.BenefitPackageStatus.Type.PROGRESS: {
          builder.progressData(
            new Member.BenefitPackageStatus.ProgressData.Builder()
              .progress([
                new Member.Progress.Builder()
                  .metric(ProgressMetric.LOYALTY_POINTS)
                  .threshold(
                    benefitPackageStatus.package?.eligibilityCriteria?.[0]
                      .earnCriteria?.threshold
                  )
                  .value(progressValue)
                  .build(),
              ])
              .build()
          );
          break;
        }
        default: {
          break;
        }
      }

      return builder.build();
    });

export const TieredDataFactory = new Factory<Member.TieredData>()
  .attr('currentTier', () =>
    BenefitPackageStatusFactory.build({
      type: Member.BenefitPackageStatus.Type.EARNED,
      package: BenefitPackageFactory.build({}, { threshold: 500 }),
    })
  )
  .attr('nextTier', () =>
    BenefitPackageStatusFactory.build({
      type: Member.BenefitPackageStatus.Type.PROGRESS,
    })
  );

export const MemberFactory = new Factory<Member>()
  .attr('type', Member.Type.TIERED)
  .attr('version', 1)
  .attr('tieredData', TieredDataFactory.build())
  .attr(
    'benefits',
    ['tieredData'],
    (tieredData: Member.TieredData) => tieredData.currentTier?.package?.benefits
  );
