import * as Sentry from '@sentry/react';
import { Team } from '../teamRegistry';
import type { Extras } from '@sentry/types';

export const logUnexpectedError = (
  error: Error,
  teamNames: Team[],
  extraData?: Extras
) => {
  Sentry.withScope((scope) => {
    scope.setTag('teamName', teamNames.join(','));
    if (extraData) {
      scope.setExtras(extraData);
    }
    Sentry.captureException(error);
  });
};
