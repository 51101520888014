export interface NotificationStore {
  open: boolean;
  message?: string;
}

export const NOTIFICATION_DISPLAY_MESSAGE = 'NOTIFICATION_DISPLAY_MESSAGE';
export const NOTIFICATION_HIDE_MESSAGE = 'NOTIFICATION_HIDE_MESSAGE';

export interface NotificationDisplayMessageAction {
  type: typeof NOTIFICATION_DISPLAY_MESSAGE;
  message: string;
}

export interface NotificationHideMessageAction {
  type: typeof NOTIFICATION_HIDE_MESSAGE;
}

export type NotificationActionTypes =
  | NotificationDisplayMessageAction
  | NotificationHideMessageAction;
