// eslint-disable-next-line filenames/match-exported
import { MarketButton, MarketDivider } from '@market/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LogoIcon } from 'svgs';

type VerifyEmailThroughEmailTokenSuccessProps = {
  obfuscatedEmail: string;
};

const VerifyEmailThroughEmailTokenSuccess: React.FC<
  VerifyEmailThroughEmailTokenSuccessProps
> = (props) => {
  const { t } = useTranslation();

  return (
    <div
      className={
        'flex flex-col relative xs:max-w-[440px] w-screen h-4/5 content-center justify-center '
      }
    >
      {<LogoIcon fontSize={'large'} className={'relative'} />}
      <div className={'pt-5'}>
        <h2 className={'font-bold text-2xl'}>
          {t('unauthenticatedVerifyEmail.success.title')}
        </h2>
        <p className={'text-base'}>
          {t('unauthenticatedVerifyEmail.success.subtitle', {
            obfuscatedEmail: props.obfuscatedEmail,
          })}
        </p>
      </div>

      <MarketDivider></MarketDivider>

      <div>
        <h3 className={'font-bold text-xl'}>
          {t('unauthenticatedVerifyEmail.success.update')}
        </h3>
        <p className={'text-base pb-5'}>
          {t('unauthenticatedVerifyEmail.success.subtitle2')}
        </p>
      </div>
      {
        <MarketButton href={'/'}>
          {t('unauthenticatedVerifyEmail.signIn')}
        </MarketButton>
      }
    </div>
  );
};

export default VerifyEmailThroughEmailTokenSuccess;
