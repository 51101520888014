/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import '../common/currency';
import '../common/time';
import './transaction_fee_tax_report';
import '../cost/model';
import '../common/countries';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      paymentreporter: {
        nested: {
          service: {
            nested: {
              Fee: {
                fields: {
                  id: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  feeAmountExcludingTax: {
                    type: 'squareup.common.Money',
                    id: 3,
                    rule: 'optional',
                  },
                  taxes: {
                    type: 'squareup.paymentreporter.service.Fee.TaxOnFee',
                    id: 4,
                    rule: 'repeated',
                  },
                  sellerId: {
                    type: 'squareup.paymentreporter.service.Fee.SellerId',
                    id: 5,
                    rule: 'optional',
                  },
                  createdAt: {
                    type: 'squareup.common.time.DateTime',
                    id: 6,
                    rule: 'optional',
                  },
                  version: {
                    type: 'int64',
                    id: 7,
                    rule: 'optional',
                  },
                  transferFeeDetail: {
                    type: 'squareup.paymentreporter.service.Fee.TransferFeeDetail',
                    id: 2,
                  },
                  transactionCostDetail: {
                    type: 'squareup.paymentreporter.service.TransactionCostDetail',
                    id: 8,
                  },
                  businessFeeDetail: {
                    type: 'squareup.paymentreporter.service.BusinessFeeDetail',
                    id: 9,
                  },
                  ecomSourceDetail: {
                    type: 'squareup.paymentreporter.service.EcomSourceDetail',
                    id: 10,
                  },
                },
                nested: {
                  TransferFeeDetail: {
                    fields: {
                      transferId: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      action: {
                        type: 'squareup.paymentreporter.service.Fee.TransferFeeDetail.Action',
                        id: 2,
                        rule: 'optional',
                      },
                      feeType: {
                        type: 'squareup.paymentreporter.service.Fee.TransferFeeDetail.FeeType',
                        id: 3,
                        rule: 'optional',
                      },
                    },
                    nested: {
                      FeeType: {
                        values: {
                          UNKNOWN_FEE_TYPE_DO_NOT_USE: 0,
                          INSTANT_TRANSFER_FEE: 1,
                          SAME_DAY_TRANSFER_FEE: 2,
                        },
                      },
                      Action: {
                        values: {
                          UNKNOWN_ACTION_DO_NOT_USE: 0,
                          TRANSFER: 1,
                          TRANSFER_REFUND: 2,
                        },
                      },
                    },
                  },
                  TaxOnFee: {
                    fields: {
                      amountMoney: {
                        type: 'squareup.common.Money',
                        id: 1,
                        rule: 'optional',
                      },
                      taxType: {
                        type: 'squareup.paymentreporter.service.TaxType',
                        id: 2,
                        rule: 'optional',
                      },
                      uid: {
                        type: 'string',
                        id: 3,
                        rule: 'optional',
                      },
                    },
                  },
                  SellerId: {
                    fields: {
                      idType: {
                        type: 'squareup.paymentreporter.service.Fee.SellerId.IdType',
                        id: 1,
                        rule: 'optional',
                      },
                      value: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                    },
                    nested: {
                      IdType: {
                        values: {
                          UNKNOWN_ID_TYPE_DO_NOT_USE: 0,
                          LOCATION_ID: 1,
                          MERCHANT_ID: 2,
                        },
                      },
                    },
                  },
                  source_detail: {
                    oneof: [
                      'transferFeeDetail',
                      'transactionCostDetail',
                      'businessFeeDetail',
                      'ecomSourceDetail',
                    ],
                  },
                },
              },
              TransactionCostDetail: {
                fields: {
                  action: {
                    type: 'squareup.cost.Action',
                    id: 1,
                    rule: 'optional',
                  },
                  feeClass: {
                    type: 'squareup.cost.FeeClass',
                    id: 2,
                    rule: 'optional',
                  },
                  feeDescriptorDetail: {
                    type: 'squareup.paymentreporter.service.FeeDescriptorDetail',
                    id: 3,
                    rule: 'optional',
                  },
                  acquirer: {
                    type: 'squareup.cost.Acquirer',
                    id: 4,
                    rule: 'optional',
                  },
                  country: {
                    type: 'squareup.common.countries.Country',
                    id: 5,
                    rule: 'optional',
                  },
                  reportingDate: {
                    type: 'squareup.common.time.DateTime',
                    id: 6,
                    rule: 'optional',
                  },
                  feeEffectiveAt: {
                    type: 'squareup.common.time.DateTime',
                    id: 7,
                    rule: 'optional',
                  },
                  paymentToken: {
                    type: 'string',
                    id: 8,
                    rule: 'optional',
                  },
                  refundToken: {
                    type: 'string',
                    id: 9,
                    rule: 'optional',
                  },
                  ledgerEntryToken: {
                    type: 'string',
                    id: 10,
                    rule: 'optional',
                  },
                  tcdToken: {
                    type: 'string',
                    id: 12,
                    rule: 'optional',
                  },
                  source: {
                    type: 'squareup.paymentreporter.service.TransactionCostDetail.Source',
                    id: 13,
                    rule: 'optional',
                  },
                },
                nested: {
                  Source: {
                    values: {
                      DEFAULT_DO_NOT_USE: 0,
                      TCD: 1,
                      ESTIMATED: 2,
                    },
                  },
                },
              },
              EcomSourceDetail: {
                fields: {
                  courierProvider: {
                    type: 'squareup.paymentreporter.service.EcomSourceDetail.CourierProvider',
                    id: 1,
                    rule: 'optional',
                  },
                  applicationId: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  orderId: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  paymentId: {
                    type: 'string',
                    id: 4,
                    rule: 'repeated',
                  },
                  refundId: {
                    type: 'string',
                    id: 5,
                    rule: 'optional',
                  },
                },
                nested: {
                  CourierProvider: {
                    values: {
                      DEFAULT_DO_NOT_USE: 0,
                      SELLER: 1,
                      DOORDASH: 2,
                      POSTMATES: 3,
                      NASH: 4,
                    },
                  },
                },
              },
              FeeDescriptorDetail: {
                fields: {
                  feeDescriptor: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  overrideFeeDescriptor: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  feeDescriptorFallback: {
                    type: 'squareup.paymentreporter.service.FeeDescriptorDetail.FeeDescriptorFallback',
                    id: 3,
                    rule: 'optional',
                  },
                },
                nested: {
                  FeeDescriptorFallback: {
                    values: {
                      DEFAULT_DO_NOT_USE: 0,
                      CARD_SUBCATEGORY: 1,
                      OVERRIDE: 2,
                    },
                  },
                },
              },
              BusinessFeeDetail: {
                fields: {
                  feeToken: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  feeType: {
                    type: 'squareup.paymentreporter.service.BusinessFeeDetail.FeeType',
                    id: 2,
                    rule: 'optional',
                  },
                  effectiveAt: {
                    type: 'squareup.common.time.DateTime',
                    id: 3,
                    rule: 'optional',
                  },
                },
                nested: {
                  FeeType: {
                    values: {
                      DEFAULT_DO_NOT_USE: 0,
                      FANF: 1,
                      SETTLEMENT: 2,
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.paymentreporter?.nested?.service?.nested
    ?.Fee &&
  !$root.nested?.squareup?.nested?.paymentreporter?.nested?.service?.nested?.Fee
    ?.nested?.TransferFeeDetail &&
  !$root.nested?.squareup?.nested?.paymentreporter?.nested?.service?.nested?.Fee
    ?.nested?.TransferFeeDetail?.nested?.FeeType &&
  !$root.nested?.squareup?.nested?.paymentreporter?.nested?.service?.nested?.Fee
    ?.nested?.TransferFeeDetail?.nested?.Action &&
  !$root.nested?.squareup?.nested?.paymentreporter?.nested?.service?.nested?.Fee
    ?.nested?.TaxOnFee &&
  !$root.nested?.squareup?.nested?.paymentreporter?.nested?.service?.nested?.Fee
    ?.nested?.SellerId &&
  !$root.nested?.squareup?.nested?.paymentreporter?.nested?.service?.nested?.Fee
    ?.nested?.SellerId?.nested?.IdType &&
  !$root.nested?.squareup?.nested?.paymentreporter?.nested?.service?.nested
    ?.TransactionCostDetail &&
  !$root.nested?.squareup?.nested?.paymentreporter?.nested?.service?.nested
    ?.TransactionCostDetail?.nested?.Source &&
  !$root.nested?.squareup?.nested?.paymentreporter?.nested?.service?.nested
    ?.EcomSourceDetail &&
  !$root.nested?.squareup?.nested?.paymentreporter?.nested?.service?.nested
    ?.EcomSourceDetail?.nested?.CourierProvider &&
  !$root.nested?.squareup?.nested?.paymentreporter?.nested?.service?.nested
    ?.FeeDescriptorDetail &&
  !$root.nested?.squareup?.nested?.paymentreporter?.nested?.service?.nested
    ?.FeeDescriptorDetail?.nested?.FeeDescriptorFallback &&
  !$root.nested?.squareup?.nested?.paymentreporter?.nested?.service?.nested
    ?.BusinessFeeDetail &&
  !$root.nested?.squareup?.nested?.paymentreporter?.nested?.service?.nested
    ?.BusinessFeeDetail?.nested?.FeeType
) {
  $root.addJSON(jsonData);
}

/**
 * Defines a general purpose fee schema that can be published to kafka feeds
 * This data is consumed downstream by the payment reporting team and indexed into ElasticGraph
 * for reporting purposes.
 *
 * Discussion doc on this schema: https://docs.google.com/document/d/1uasJOpycIQKacJv7oKTmrDK0PVUgWee7idxm8j_MvMw/edit#heading=h.acgslqddzf1k
 *
 * SOURCE Fee @ squareup/paymentreporter/fee.proto at 23:1
 */
export const Fee = $root.lookupType('squareup.paymentreporter.service.Fee');
fixType(Fee, {
  id: { notEmpty: true },
  feeAmountExcludingTax: { required: true },
  sellerId: { required: true },
  createdAt: { required: true },
  version: { required: true },
});
Builder.createAndAttachToType(Fee);
/**
 * This proto is used to define key attributes for fees related to transfers
 *
 * SOURCE TransferFeeDetail @ squareup/paymentreporter/fee.proto at 25:3
 */
Fee.TransferFeeDetail = $root.lookupType(
  'squareup.paymentreporter.service.Fee.TransferFeeDetail'
);
fixType(Fee.TransferFeeDetail, {
  transferId: { notEmpty: true },
  action: { required: true },
  feeType: { required: true },
});
Builder.createAndAttachToType(Fee.TransferFeeDetail);
/**
 * SOURCE FeeType @ squareup/paymentreporter/fee.proto at 26:5
 */
Fee.TransferFeeDetail.FeeType = $root.lookupEnum(
  'squareup.paymentreporter.service.Fee.TransferFeeDetail.FeeType'
).values;
/**
 * SOURCE Action @ squareup/paymentreporter/fee.proto at 32:5
 */
Fee.TransferFeeDetail.Action = $root.lookupEnum(
  'squareup.paymentreporter.service.Fee.TransferFeeDetail.Action'
).values;
/**
 * SOURCE TaxOnFee @ squareup/paymentreporter/fee.proto at 44:3
 */
Fee.TaxOnFee = $root.lookupType(
  'squareup.paymentreporter.service.Fee.TaxOnFee'
);
fixType(Fee.TaxOnFee, {
  amountMoney: { required: true },
  taxType: { required: true },
});
Builder.createAndAttachToType(Fee.TaxOnFee);
/**
 * SOURCE SellerId @ squareup/paymentreporter/fee.proto at 51:3
 */
Fee.SellerId = $root.lookupType(
  'squareup.paymentreporter.service.Fee.SellerId'
);
fixType(Fee.SellerId, {
  idType: { required: true },
  value: { notEmpty: true },
});
Builder.createAndAttachToType(Fee.SellerId);
/**
 * SOURCE IdType @ squareup/paymentreporter/fee.proto at 52:5
 */
Fee.SellerId.IdType = $root.lookupEnum(
  'squareup.paymentreporter.service.Fee.SellerId.IdType'
).values;
/**
 * SOURCE TransactionCostDetail @ squareup/paymentreporter/fee.proto at 103:1
 */
export const TransactionCostDetail = $root.lookupType(
  'squareup.paymentreporter.service.TransactionCostDetail'
);
fixType(TransactionCostDetail, {
  action: { required: true },
  feeDescriptorDetail: { required: true },
  country: { required: true },
  reportingDate: { required: true },
  feeEffectiveAt: { required: true },
  paymentToken: { notEmpty: true },
  ledgerEntryToken: { notEmpty: true },
  source: { required: true },
});
Builder.createAndAttachToType(TransactionCostDetail);
/**
 * SOURCE Source @ squareup/paymentreporter/fee.proto at 121:3
 */
TransactionCostDetail.Source = $root.lookupEnum(
  'squareup.paymentreporter.service.TransactionCostDetail.Source'
).values;
/**
 * SOURCE EcomSourceDetail @ squareup/paymentreporter/fee.proto at 131:1
 */
export const EcomSourceDetail = $root.lookupType(
  'squareup.paymentreporter.service.EcomSourceDetail'
);
fixType(EcomSourceDetail, {
  courierProvider: { required: true },
  applicationId: { required: true },
  orderId: { required: true },
});
Builder.createAndAttachToType(EcomSourceDetail);
/**
 * SOURCE CourierProvider @ squareup/paymentreporter/fee.proto at 132:3
 */
EcomSourceDetail.CourierProvider = $root.lookupEnum(
  'squareup.paymentreporter.service.EcomSourceDetail.CourierProvider'
).values;
/**
 * SOURCE FeeDescriptorDetail @ squareup/paymentreporter/fee.proto at 149:1
 */
export const FeeDescriptorDetail = $root.lookupType(
  'squareup.paymentreporter.service.FeeDescriptorDetail'
);
fixType(FeeDescriptorDetail, {
  feeDescriptor: { notEmpty: true },
});
Builder.createAndAttachToType(FeeDescriptorDetail);
/**
 * SOURCE FeeDescriptorFallback @ squareup/paymentreporter/fee.proto at 154:3
 */
FeeDescriptorDetail.FeeDescriptorFallback = $root.lookupEnum(
  'squareup.paymentreporter.service.FeeDescriptorDetail.FeeDescriptorFallback'
).values;
/**
 * Defines a general purpose fee schema that is tied to the merchant and not a payment transaction
 * and that can be published to kafka feeds.
 * This data is consumed downstream by the payment reporting team and indexed into ElasticGraph
 * for reporting purposes.
 *
 * Design doc on this schema: https://docs.google.com/document/d/1VVTBoDp2bzt6-6o8QqcNoHZm_FQtv4l9BJfsuCruErc/edit#heading=h.v20325pc0091
 *
 * SOURCE BusinessFeeDetail @ squareup/paymentreporter/fee.proto at 173:1
 */
export const BusinessFeeDetail = $root.lookupType(
  'squareup.paymentreporter.service.BusinessFeeDetail'
);
fixType(BusinessFeeDetail, {
  feeToken: { required: true },
  feeType: { required: true },
  effectiveAt: { required: true },
});
Builder.createAndAttachToType(BusinessFeeDetail);
/**
 * SOURCE FeeType @ squareup/paymentreporter/fee.proto at 174:3
 */
BusinessFeeDetail.FeeType = $root.lookupEnum(
  'squareup.paymentreporter.service.BusinessFeeDetail.FeeType'
).values;
