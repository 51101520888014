import React, { useEffect, useState } from 'react';
import {
  MarketButton,
  MarketField,
  MarketInputText,
  MarketToast,
} from '@market/react';
import SecuredBySquare from './components/common/SecuredBySquare';
import { useTranslation } from 'react-i18next';
import { CashLinkInfo } from 'rpc/model/squareup/buyerportal/cashlink/data';
import { getIdentifier } from './utils/CashLinkInfoHelper';
import { Identifier } from 'rpc/model/squareup/buyerportal/profile/common';
import { useParams } from 'react-router-dom';
import { CashLinkingProcessRouteProps } from 'routes/cash-linking';
import { isValidEmail } from 'utils/identifiers';
import useValidation from 'utils/custom-react-hooks/useValidation';
import Es2Tracker from 'services/tracking/tracker';
import {
  cashRequestEmailActionEvent,
  cashRequestEmailViewEvent,
} from 'services/tracking/events';
import nonTranslatableStrings from 'utils/nonTranslatableStrings';
import { MAX_EMAIL_LENGTH } from 'components/IdentifierValueInput';
import { checkCashVerification } from 'services/buyerportal';

type RequestEmailProps = {
  cashLinkInfo: CashLinkInfo;
  nextStep: (email: string) => void;
};

const RequestEmail: React.FC<RequestEmailProps> = (props) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState<string>('');
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isInvalidEmail, resetEmailValidation, validateEmail] = useValidation(
    (email) => isValidEmail(email)
  );
  const [isUnexpectedError, setIsUnexpectedError] = useState<boolean>(false);

  const { cashLinkToken } = useParams<CashLinkingProcessRouteProps>();

  useEffect(() => {
    Es2Tracker.track(
      cashRequestEmailViewEvent(
        props.cashLinkInfo.deliveryIdentifier!,
        props.cashLinkInfo.merchantInfo!.id
      )
    );
  }, [props.cashLinkInfo]);

  /*
   * Link the buyer to Cash App.
   */
  const checkCashAppVerification = async (emailExists: boolean) => {
    if (emailExists && !validateEmail(email)) {
      return;
    }

    try {
      setIsProcessing(true);

      Es2Tracker.track(
        cashRequestEmailActionEvent(
          props.cashLinkInfo.deliveryIdentifier!,
          props.cashLinkInfo.merchantInfo!.id
        )
      );

      const phoneId = getIdentifier(
        props.cashLinkInfo,
        Identifier.Type.TYPE_PHONE
      )!.id;

      const personToken = await checkCashVerification(
        cashLinkToken,
        phoneId,
        null,
        '',
        emailExists ? email : null
      );

      props.nextStep(
        `https://cashapp.onelink.me/ui6m?af_dp=cashme%3A%2F%2F&customer_reference_id=${personToken}`
      );
    } catch {
      setIsUnexpectedError(true);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className={'flex flex-col'}>
      <h2 className={'mt-7 mb-4 heading-30'}>
        {t('cashlinking.requestEmail.title')}
      </h2>
      <p className={'m-0 mb-4 paragraph-30'}>
        {t('cashlinking.requestEmail.subtitle')}
      </p>
      <MarketField className={'mb-7'} invalid={isInvalidEmail}>
        <MarketInputText>
          <label>{t('common.email')}</label>
          <input
            slot={'input'}
            data-testid={'email-input'}
            type={'email'}
            autoComplete={'email'}
            inputMode={'email'}
            maxLength={MAX_EMAIL_LENGTH}
            name={'email'}
            autoCorrect={'off'}
            autoCapitalize={'off'}
            onChange={(e) => {
              setEmail(e.target.value);
              resetEmailValidation();
            }}
          />
        </MarketInputText>
        <small slot={'error'}>{t('common.message.validation.email')}</small>
      </MarketField>
      <MarketButton
        rank={'primary'}
        /* Fun workaround to get disabled working: https://github.com/squareup/market/issues/1570 */
        {...((!email || isProcessing) && { disabled: true })}
        onClick={() => checkCashAppVerification(true)}
      >
        {t('cashenroll.common.continueToCashApp', {
          appName: nonTranslatableStrings.cashApp,
        })}
      </MarketButton>
      <MarketButton
        rank={'tertiary'}
        {...(isProcessing && { disabled: true })}
        onClick={() => checkCashAppVerification(false)}
      >
        {t('cashenroll.requestEmail.continueWithoutEmail')}
      </MarketButton>
      <SecuredBySquare className={'mt-7 self-center'} />
      {isUnexpectedError && (
        <div className={'absolute bottom-[28px] p-6 m-auto'}>
          <MarketToast
            variant={'critical'}
            onMarketToastManuallyDismissed={() => setIsUnexpectedError(false)}
            onMarketToastAutoDismissed={() => setIsUnexpectedError(false)}
          >
            {t('common.somethingWentWrong.retryable.apologetic')}
          </MarketToast>
        </div>
      )}
    </div>
  );
};

export default RequestEmail;
