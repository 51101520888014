import { CardPreference } from 'routes/profile/models/CardPreference';
import { RetrieveCardPreferencesResponse } from 'rpc/model/squareup/buyerportal/preference/card';

export interface CardInfo {
  id: string;
  brand: string;
  displayName: string;
}

export interface CardAttribute {
  cardId: string;
  brand: string;
  displayName: string;
  cardPreferences: Array<CardPreference>;
}

export class Card implements CardAttribute {
  public cardId: string;
  public brand: string;
  public displayName: string;
  public cardPreferences: Array<CardPreference>;

  static fromResponse = function (
    cardId: string,
    response: RetrieveCardPreferencesResponse
  ) {
    return new Card({
      cardId,
      brand: response.cardInfo!.brand!,
      displayName: response.cardInfo!.displayName!,
      cardPreferences:
        response.cardPreferences?.map(CardPreference.fromResponse) ?? [],
    });
  };

  constructor(props: CardAttribute) {
    const { cardId, brand, displayName, cardPreferences } = props;

    this.cardId = cardId;
    this.brand = brand;
    this.displayName = displayName;
    this.cardPreferences = cardPreferences;
  }
}
