import React from 'react';
import { MarketSvgProps } from '.';

const Card: React.FC<MarketSvgProps> = (props) => {
  const fill = props.fill || '#000';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M19 5H5C3.34 5 2 6.34 2 8V16C2 17.66 3.34 19 5 19H19C20.66 19 22 17.66 22 16V8C22 6.34 20.66 5 19 5ZM20 16C20 16.55 19.55 17 19 17H5C4.45 17 4 16.55 4 16V11H20V16ZM4 9V8C4 7.45 4.45 7 5 7H19C19.55 7 20 7.45 20 8V9H4Z"
        fill={fill}
      />
      <path d="M17 13H14V15H17V13Z" fill={fill} />
      <path d="M12 13H6V15H12V13Z" fill={fill} />
    </svg>
  );
};
export default Card;
