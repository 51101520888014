import React from 'react';
import { Trans } from 'react-i18next';
import { getDashboardDomain } from 'config/config';
import { MarketLink } from '@market/react';
import nonTranslatableStrings from 'utils/nonTranslatableStrings';

type SquarePrivacyPolicyProps = {
  useAppSubdomain: boolean;
};

const SquarePrivacyPolicy: React.FC<SquarePrivacyPolicyProps> = ({
  useAppSubdomain,
}) => (
  <Trans
    i18nKey="onboarding.common.privacyPolicy"
    components={[
      <MarketLink
        target={'_blank'}
        href={`https://${getDashboardDomain(
          useAppSubdomain
        )}/legal/general/buyer-account-terms`}
      />,
      <MarketLink
        target={'_blank'}
        href={`https://${getDashboardDomain(
          useAppSubdomain
        )}/legal/general/buyer-features`}
      />,
    ]}
    values={{ SQUARE: nonTranslatableStrings.square }}
  ></Trans>
);

export default SquarePrivacyPolicy;
