import { RRule } from 'rrule';
import { dayjs } from 'utils/date';

// Based on JS days
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getUTCDay
export enum Weekday {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export interface ParsedTimePeriod {
  weekday: number;
  hours: TimePeriod;
}

export interface TimePeriod {
  startTime: Time;
  endTime: Time;
}

export interface Time {
  hour: number;
  minute: number;
}

const JSWeekdayFromRRuleWeekday = {
  [RRule.SU.toString()]: Weekday.Sunday,
  [RRule.MO.toString()]: Weekday.Monday,
  [RRule.TU.toString()]: Weekday.Tuesday,
  [RRule.WE.toString()]: Weekday.Wednesday,
  [RRule.TH.toString()]: Weekday.Thursday,
  [RRule.FR.toString()]: Weekday.Friday,
  [RRule.SA.toString()]: Weekday.Saturday,
};

const START_TIME_REGEX = /T(?<hour>\d\d)(?<minute>\d\d)/;
const DTSTART_REGEX = /DTSTART:(?<dtstart>\w+)\n/;
const DURATION_REGEX = /DURATION:(?<duration>\w+)\n/;
const RRULE_REGEX = /RRULE:(?<rrule>[\w,;=]+)\n/;
const I_CAL_REGEX = /^BEGIN:VEVENT\n(?<iCalString>([\w,:;=]+\n)+)END:VEVENT$/;
const BYDAY_REGEX = /BYDAY=(?<byday>[\d,A-Za-z]+);?/;
const FREQ_REGEX = /FREQ=WEEKLY;?/;

/**
 *
 * @param startTime
 * @param durationString
 * @returns
 */
function fromStartTimeAndDuration(
  startTime: Time,
  durationString: string
): TimePeriod {
  const startDateTime = dayjs(
    new Date().setHours(startTime.hour, startTime.minute)
  );
  const duration = dayjs.duration(durationString);

  if (!(duration.hours() > 0 || duration.minutes() > 0)) {
    throw new Error(
      `Expected duration to have a time component, but got ${durationString}`
    );
  }

  const endDateTime = startDateTime.add(duration);

  if (!(startDateTime.day() === endDateTime.day())) {
    throw new Error('TimeRange cannot span more than 1 day');
  }

  return {
    startTime,
    endTime: {
      hour: endDateTime.hour(),
      minute: endDateTime.minute(),
    },
  };
}

/**
 *
 * @param iCalString
 * @returns
 */
export function parseICalString(iCalString: string): ParsedTimePeriod[] {
  const iCalStringMatch = iCalString.match(I_CAL_REGEX);

  if (!iCalStringMatch?.groups?.iCalString) {
    throw new Error(`Expected valid iCalendar string, but got ${iCalString}`);
  }

  const dtstartMatch = iCalStringMatch.groups.iCalString.match(DTSTART_REGEX);

  if (!dtstartMatch?.groups?.dtstart) {
    throw new Error(
      `Expected iCalendar string to have "DTSTART", but got\n${iCalString}`
    );
  }

  const startTimeMatch = dtstartMatch.groups.dtstart.match(START_TIME_REGEX);

  if (!(startTimeMatch?.groups?.hour && startTimeMatch?.groups?.minute)) {
    throw new Error(
      `Expected iCalendar string to have valid "DTSTART", but got\n${iCalString}`
    );
  }

  const hour = Number.parseInt(startTimeMatch.groups.hour, 10);
  const minute = Number.parseInt(startTimeMatch.groups.minute, 10);
  if (!(hour >= 0 && hour <= 23)) {
    throw new Error(
      `Expected iCalendar string to have valid "DTSTART", but got\n${iCalString}`
    );
  }
  if (!(minute >= 0 && minute <= 59)) {
    throw new Error(
      `Expected iCalendar string to have valid "DTSTART", but got\n${iCalString}`
    );
  }

  const durationMatch = iCalStringMatch.groups.iCalString.match(DURATION_REGEX);
  if (!durationMatch?.groups?.duration) {
    throw new Error(
      `Expected iCalendar string to have "DURATION", but got\n${iCalString}`
    );
  }

  const rruleMatch = iCalStringMatch.groups.iCalString.match(RRULE_REGEX);
  if (rruleMatch?.groups?.rrule) {
    const freqMatch = iCalStringMatch.groups.iCalString.match(FREQ_REGEX);
    if (!freqMatch) {
      throw new Error(
        `Expected iCalendar string to have "FREQ=WEEKLY", but got\n${iCalString}`
      );
    }

    const bydayMatch = rruleMatch.groups.rrule.match(BYDAY_REGEX);

    if (!bydayMatch?.groups?.byday) {
      throw new Error(
        `Expected iCalendar RRULE to have "BYDAY", but got\n${iCalString}`
      );
    }

    const rruleWeekdays = bydayMatch.groups.byday.split(',');

    return rruleWeekdays.map((rruleWeekday) => {
      const weekday = JSWeekdayFromRRuleWeekday[rruleWeekday];
      if (!Object.values(Weekday).includes(weekday)) {
        throw new Error(
          `Expected iCalendar RRULE to have valid "BYDAY", but got\n${iCalString}`
        );
      }

      return {
        weekday,
        hours: fromStartTimeAndDuration(
          { hour, minute },
          durationMatch!.groups!.duration
        ),
      };
    });
  }

  // Date does not contain recurrence rule, derive day of week from DTSTART
  const weekday = dayjs(dtstartMatch?.groups?.dtstart, 'YYYYMMDDTHHmmss')
    .toDate()
    .getUTCDay();

  if (!durationMatch?.groups) {
    return [];
  }

  return [
    {
      weekday,
      hours: fromStartTimeAndDuration(
        { hour, minute },
        durationMatch.groups.duration
      ),
    },
  ];
}
