import React from 'react';
import { MarketSvgProps } from '.';

const Celebrate: React.FC<MarketSvgProps> = ({ fill = '#000' }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 8C12.9 8 12 7.1 12 6C12 4.9 12.9 4 14 4C12.9 4 12 3.1 12 2C12 0.9 12.9 0 14 0V2C15.1 2 16 2.9 16 4C16 5.1 15.1 6 14 6V8ZM9 4C9.55228 4 10 3.55228 10 3C10 2.44772 9.55228 2 9 2C8.44771 2 8 2.44772 8 3C8 3.55228 8.44771 4 9 4ZM17.2937 5.29199L20.2918 2.29389L21.706 3.70809L18.7079 6.70619L17.2937 5.29199ZM0 24L8 6L18 16L0 24ZM10.66 17.07L6.93 13.34L3.94 20.06L10.66 17.07ZM12.62 16.21L14.53 15.36L8.65 9.47L7.8 11.38L12.62 16.21ZM22 15C22 15.5523 21.5523 16 21 16C20.4477 16 20 15.5523 20 15C20 14.4477 20.4477 14 21 14C21.5523 14 22 14.4477 22 15ZM22 10C22 8.9 21.1 8 20 8C18.9 8 18 8.9 18 10H16C16 11.1 16.9 12 18 12C19.1 12 20 11.1 20 10C20 11.1 20.9 12 22 12C23.1 12 24 11.1 24 10H22Z"
        fill={fill}
      />
    </svg>
  );
};

export default Celebrate;
