import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { LoyaltyData, SignInVariantData } from 'routes/native-sign-in/types';
import {
  useCreateLoyaltyAccountForBuyerMutation,
  useUpdateTermsOfServiceConsentMutation,
} from 'store/query/api-extensions/loyalty';
import { VerificationCredential } from 'rpc/model/squareup/buyerportal/common/data';
import { LoyaltyTermsOfService } from 'rpc/model/squareup/card/balance/model/loyalty-terms-of-service';
import { v4 as uuidv4 } from 'uuid';
import { isValidLoyaltyVariantData } from 'routes/native-sign-in/util/parameters';
import { setAvailableNewTos } from 'routes/merchant-scoped-portal/integrations/loyalty/loyaltySlice';
import { logUnexpectedError } from 'routes/merchant-scoped-portal/utils/error';
import { Team } from 'routes/merchant-scoped-portal/teamRegistry';

/**
 * Custom hook to create a loyalty account for a buyer and optionally update the terms
 * of service consent.
 *
 * The hook returns a function `createLoyaltyAccount` which, when called, attempts to:
 * 1. Validate the provided onboarding variant data and verification credential.
 * 2. Create the loyalty account using the provided data.
 * 3. Update the terms of service consent if newly accepted terms are provided.
 * 4. Return the created loyalty account, or undefined if an error occurred.
 *
 * If an error occurs during the creation process, it captures the exception using Sentry.
 */
export const useCreateLoyaltyAccount = ({
  newlyAcceptedTos,
  onboardingVariantData,
  verificationCredential,
}: {
  newlyAcceptedTos?: LoyaltyTermsOfService | null;
  onboardingVariantData?: SignInVariantData[keyof SignInVariantData] | null;
  verificationCredential?: VerificationCredential | null;
}) => {
  const [createLoyaltyAccountForBuyer] =
    useCreateLoyaltyAccountForBuyerMutation();
  const [updateTermsOfServiceConsent] =
    useUpdateTermsOfServiceConsentMutation();
  const dispatch = useDispatch();

  const createLoyaltyAccount = useCallback(async () => {
    let loyaltyAccount;
    try {
      if (
        !(
          onboardingVariantData &&
          isValidLoyaltyVariantData(onboardingVariantData)
        ) ||
        !verificationCredential
      ) {
        throw new Error(
          'Invalid onboarding variant data or verification credential'
        );
      }
      const loyaltyData = onboardingVariantData as LoyaltyData;

      loyaltyAccount = await createLoyaltyAccountForBuyer({
        claimablePointsToken: loyaltyData.claimablePointsToken,
        idempotencyKey: uuidv4(),
        loyaltyProgramId: loyaltyData.programId,
        merchantId: loyaltyData.merchantId,
        verificationCredential,
      }).unwrap();

      if (!loyaltyAccount) {
        return undefined;
      }

      if (newlyAcceptedTos) {
        await updateTermsOfServiceConsent({
          loyaltyAccountLookupToken: loyaltyAccount.loyaltyAccountLookupToken,
          newlyAcceptedTos,
        });
      }
    } catch (error) {
      const loyaltyData = { ...onboardingVariantData } as LoyaltyData;
      delete loyaltyData?.buyer;
      logUnexpectedError(
        new Error('Error creating loyalty account'),
        [Team.CeWeb],
        {
          error,
          loyaltyData,
        }
      );
    }
    dispatch(setAvailableNewTos(undefined));
    return loyaltyAccount;
  }, [
    createLoyaltyAccountForBuyer,
    dispatch,
    newlyAcceptedTos,
    onboardingVariantData,
    updateTermsOfServiceConsent,
    verificationCredential,
  ]);

  return createLoyaltyAccount;
};
