import { TFunction } from 'react-i18next';
import { GiftCardDetail } from 'routes/profile/models/GiftCard';

const BUSINESS_TYPE_KEY_PREFIX = 'common.businessType';
const SHOW_ADD_FUNDS_QUERY_PARAM = 'showAddFunds';

/*
 * Formats a gift card GAN into sections of 4 characters separated by spaces
 * ex: 1111222233334444 => 1111 2222 3333 4444
 */
const formatGan = (gan?: string): string | undefined => {
  return gan?.match(/.{1,4}/g)?.join(' ');
};

/*
 * Translates a business type string possibly including underscores (ex: 'coffee_tea_shops')
 * into a human readable, internationalized string (ex: 'Coffee and Tea Shops'). First translates
 * the snake case business type string into camel case ('coffee_tea_shops' => 'coffeeTeaShops').
 * Next appends the BUSINESS_TYPE_KEY_PREFIX ('coffeeTeaShops' => 'common.businessType.coffeeTeaShops')
 * to get translation key. The key is then passed into the specified translate function to convert it into
 * a translated string ('common.businessType.coffeeTeaShops' => 'Coffee and Tea Shops'). Returns
 * undefined if a translation is missing for the given key.
 *
 * See https://git.sqcorp.co/projects/XGMP/repos/sq-java-gh-bb-migration/browse/roster/mcc/src/main/resources/merchant_categories.yaml
 * for the list of possible snake-cased business types
 */
const translateBusinessType = (
  translate: TFunction,
  businessType?: string
): string | undefined => {
  const camelCaseBusinessType = businessType?.replace(
    /[^\dA-Za-z]+(.)/g,
    (_, chr) => chr.toUpperCase()
  );
  if (!camelCaseBusinessType) {
    return undefined;
  }

  const businessTypeTranslationKey = `${BUSINESS_TYPE_KEY_PREFIX}.${camelCaseBusinessType}`;
  const translatedBusinessType = translate(businessTypeTranslationKey);
  if (translatedBusinessType === businessTypeTranslationKey) {
    return undefined;
  }
  return translatedBusinessType;
};

/*
 * Returns a link to the view gift card page (egift-frontend) with a query parameter
 * that specifies opening the page with the load funds modal displayed automatically.
 */
const getGiftCardLoadFundsUrl = (
  giftCardDetail: GiftCardDetail
): string | undefined => {
  const detailUrl = giftCardDetail.detailUrl;
  return detailUrl ? `${detailUrl}?${SHOW_ADD_FUNDS_QUERY_PARAM}` : undefined;
};

export { formatGan, translateBusinessType, getGiftCardLoadFundsUrl };
