import React from 'react';
import { MarketSvgProps } from '.';

const LoudSpeaker: React.FC<MarketSvgProps> = ({ fill = '#000' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M22 5L4 9.5V9H2V15H4V14.5L6 15V16C6 17.65 7.35 19 9 19C10.49 19 11.72 17.91 11.95 16.49L22 19V5ZM9 17C8.45 17 8 16.55 8 16V15.5L10 16C10 16.55 9.55 17 9 17ZM20 16.44L4 12.44V11.56L20 7.56V16.44Z"
        fill={fill}
      />
    </svg>
  );
};

export default LoudSpeaker;
