import { Factory } from 'rosie';
import {
  RetrieveClaimablePointsResponse,
  RetrieveLoyaltyAccountInfoForBuyerResponse,
  RetrieveLoyaltyAccountInfoResponse,
} from 'rpc/model/squareup/buyerportal/merchantportal/loyalty/data';
import { AccountInfoFactory } from './AccountInfo';
import { RequestStatus } from 'rpc/model/squareup/customers/request';
import { ClaimablePointsFactory } from './ClaimablePoints';

const InfoFactory = new Factory<RetrieveLoyaltyAccountInfoResponse.Info>()
  .attr('account', AccountInfoFactory.build())
  .attr('merchantId', 'merchant-id')
  .after((info) => RetrieveLoyaltyAccountInfoResponse.Info.create(info));

export const RetrieveClaimablePointResponseFactory =
  new Factory<RetrieveClaimablePointsResponse>()
    .attr('claimablePoints', ClaimablePointsFactory.build())
    .attr('errors', [])
    .attr('status', RequestStatus.STATUS_SUCCESS);

export const RetrieveLoyaltyAccountInfoResponseFactory =
  new Factory<RetrieveLoyaltyAccountInfoResponse>()
    .attr('status', RequestStatus.STATUS_SUCCESS)
    .attr('info', InfoFactory.build())
    .after((response) => RetrieveLoyaltyAccountInfoResponse.create(response));

export const RetrieveLoyaltyAccountInfoForBuyerResponseFactory =
  new Factory<RetrieveLoyaltyAccountInfoForBuyerResponse>()
    .option('numAccounts', 1)
    .attr('status', RequestStatus.STATUS_SUCCESS)
    .attr('accounts', ['numAccounts'], (numAccounts) =>
      Array.from({ length: numAccounts }, () => AccountInfoFactory.build())
    )
    .after((response) => RetrieveLoyaltyAccountInfoResponse.create(response));
