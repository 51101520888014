import React from 'react';
import { useTranslation } from 'react-i18next';
import AlertBase, { AlertBaseProps } from './AlertBase';

// This is just the AlertBase component with a prefilled title
export type SomethingWentWrongProps = Pick<
  AlertBaseProps,
  'subtitle' | 'onTryAgain' | 'type'
>;

// Content for the "something went wrong" error message.
// Can be slotted into any container
const SomethingWentWrong: React.FC<SomethingWentWrongProps> = ({
  subtitle,
  onTryAgain,
  type,
}) => {
  const { t } = useTranslation();
  return (
    <AlertBase
      title={t('common.somethingWentWrong.blocked')}
      subtitle={subtitle}
      onTryAgain={onTryAgain}
      type={type}
    />
  );
};

export default SomethingWentWrong;
