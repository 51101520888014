/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import '../../items/items';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      api: {
        nested: {
          sync: {
            nested: {
              ObjectType: {
                fields: {
                  type: {
                    type: 'squareup.api.items.Type',
                    id: 101,
                    rule: 'optional',
                  },
                },
              },
              ObjectId: {
                fields: {
                  id: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  type: {
                    type: 'squareup.api.sync.ObjectType',
                    id: 2,
                    rule: 'optional',
                  },
                  v3Token: {
                    type: 'string',
                    id: 5,
                    rule: 'optional',
                  },
                  merchantToken: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  customerToken: {
                    type: 'string',
                    id: 4,
                    rule: 'optional',
                  },
                  refType: {
                    type: 'squareup.api.items.Type',
                    id: 101,
                    rule: 'optional',
                  },
                },
              },
              ObjectWrapper: {
                fields: {
                  id: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  objectId: {
                    type: 'squareup.api.sync.ObjectId',
                    id: 2,
                    rule: 'optional',
                  },
                  timestamp: {
                    type: 'int64',
                    id: 3,
                    rule: 'optional',
                  },
                  deleted: {
                    type: 'bool',
                    id: 4,
                    rule: 'optional',
                    options: {
                      default: false,
                    },
                  },
                  objectType: {
                    type: 'squareup.api.items.Type',
                    id: 101,
                    rule: 'optional',
                  },
                  ticketGroup: {
                    type: 'squareup.api.items.TicketGroup',
                    id: 1023,
                    rule: 'optional',
                  },
                  ticketTemplate: {
                    type: 'squareup.api.items.TicketTemplate',
                    id: 1024,
                    rule: 'optional',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.api?.nested?.sync?.nested?.ObjectType &&
  !$root.nested?.squareup?.nested?.api?.nested?.sync?.nested?.ObjectId &&
  !$root.nested?.squareup?.nested?.api?.nested?.sync?.nested?.ObjectWrapper
) {
  $root.addJSON(jsonData);
}

/**
 * Describes an object.
 *
 * SOURCE ObjectType @ squareup/api/sync/sync.proto at 277:1
 */
export const ObjectType = $root.lookupType('squareup.api.sync.ObjectType');
fixType(ObjectType);
Builder.createAndAttachToType(ObjectType);
/**
 * An object is identified by the tuple of its string id and type
 *
 * SOURCE ObjectId @ squareup/api/sync/sync.proto at 301:1
 */
export const ObjectId = $root.lookupType('squareup.api.sync.ObjectId');
fixType(ObjectId);
Builder.createAndAttachToType(ObjectId);
/**
 * SOURCE ObjectWrapper @ squareup/api/sync/sync.proto at 323:1
 */
export const ObjectWrapper = $root.lookupType(
  'squareup.api.sync.ObjectWrapper'
);
fixType(ObjectWrapper);
Builder.createAndAttachToType(ObjectWrapper);
