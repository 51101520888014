import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import BackButton from '../../components/BackButton';
import {
  MarketButton,
  MarketField,
  MarketInputText,
  MarketLink,
} from '@market/react';
import Es2Tracker from 'services/tracking/tracker';
import {
  signInVerifyCodeActionEvent,
  signInVerifyCodeViewEvent,
} from 'services/tracking/events/signIn';
import buyerPortalClient from 'rpc/client';
import { MAX_CODE_LENGTH, THROTTLE_TIMEOUT } from './util/constants';
import { LoyaltyData, SignInIdentifier } from './types';
import { AuthenticateOrCreateAccountDraftRequest } from 'rpc/model/squareup/buyerportal/onboarding/data';
import { IdentifierType } from 'routes/profile/models/Identifier';
import { RequestStatus } from 'rpc/model/squareup/customers/request';
import { Identifier as RpcIdentifier } from 'rpc/model/squareup/buyerportal/profile/common';
import { v4 as uuidv4 } from 'uuid';
import useThrottle from '../../utils/custom-react-hooks/useThrottle';
import { RequestErrorCode } from 'models/Error';
import { useTrackLoyaltyEvent } from 'utils/custom-react-hooks/loyalty/useTrackLoyaltyEvent';
import { EventName, FeatureFormat } from 'services/tracking/cdp/events/types';

interface SignInTOTPProps {
  identifier: SignInIdentifier;
  loyaltyData?: LoyaltyData;
  onBack: () => void;
  onForward: (
    personToken: string,
    missingIdentifier?: RpcIdentifier
  ) => Promise<void>;
  onUnexpectedError: (isCatastrophicError?: boolean) => void;
}

const SignInTOTP: React.FC<SignInTOTPProps> = (props) => {
  const { t } = useTranslation();

  const [code, setCode] = useState<string>('');
  const [isInvalidCode, setIsInvalidCode] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [shouldVerifyCode, setShouldVerifyCode] = useState<boolean>(false);

  const isCodeSubmittable = useMemo(
    () => code.length === MAX_CODE_LENGTH && !isProcessing,
    [code, isProcessing]
  );

  const trackLoyaltyEvent = useTrackLoyaltyEvent();
  useEffect(() => {
    if (props.loyaltyData) {
      trackLoyaltyEvent(EventName.VIEW_FEATURE, {
        additional_parameters: {
          buyer_authenticated: Boolean(props.loyaltyData?.buyer),
          merchant_id: props.loyaltyData?.merchantId,
        },
        event_description: 'View enter the code for phone verification page',
        feature_format: FeatureFormat.PAGE,
      });
    }
  }, [props.loyaltyData, trackLoyaltyEvent]);

  useEffect(() => {
    Es2Tracker.track(signInVerifyCodeViewEvent(props.identifier.type));
  }, [props.identifier.type]);

  const verifyCode = useCallback(async () => {
    setIsProcessing(true);

    let verificationCredential;
    if (props.identifier.type === IdentifierType.Email) {
      verificationCredential = {
        email: props.identifier.value,
      };
    } else {
      verificationCredential = props.identifier.id
        ? {
            phoneNumberId: props.identifier.id,
          }
        : {
            phoneNumber: props.identifier.value,
          };
    }
    const request = AuthenticateOrCreateAccountDraftRequest.create({
      verificationCode: code,
      verificationCredential,
    });

    try {
      const response = await buyerPortalClient.authenticateOrCreateAccountDraft(
        request
      );
      if (response.status !== RequestStatus.STATUS_SUCCESS) {
        const code = response?.errors?.[0]?.code;
        throw new Error(code);
      }
      await props.onForward(
        response.personToken,
        response.missingIdentifier || undefined
      );
    } catch (e) {
      if (e?.message === RequestErrorCode.CodeInvalidError) {
        setIsInvalidCode(true);
      } else {
        props.onUnexpectedError();
      }
    } finally {
      setIsProcessing(false);
    }
  }, [code, props]);

  const verificationCodeInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (verificationCodeInputRef.current) {
        verificationCodeInputRef.current.focus();
      }
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (shouldVerifyCode && isCodeSubmittable) {
      verifyCode();
      setShouldVerifyCode(false);
    }
  }, [shouldVerifyCode, isCodeSubmittable, verifyCode]);

  const [requestNewCode, isRequestNewCodeThrottled] = useThrottle(async () => {
    setIsProcessing(true);
    let request;
    if (props.identifier.type === IdentifierType.Email) {
      request = {
        idempotencyKey: uuidv4(),
        verificationCredential: {
          email: props.identifier.value,
        },
      };
    } else {
      request = {
        idempotencyKey: uuidv4(),
        verificationCredential: props.identifier.id
          ? {
              phoneNumberId: props.identifier.id,
            }
          : {
              phoneNumber: props.identifier.value,
            },
      };
    }

    try {
      Es2Tracker.track(signInVerifyCodeActionEvent(props.identifier.type));

      const response = await buyerPortalClient.createLoginOrSignupVerification(
        request
      );
      if (response.status !== RequestStatus.STATUS_SUCCESS) {
        throw new Error(response.errors?.[0]?.details);
      }
    } catch {
      props.onUnexpectedError();
    } finally {
      setIsProcessing(false);
    }
  }, THROTTLE_TIMEOUT);

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter' || !isCodeSubmittable) {
      return;
    }
    verifyCode();
  };

  return (
    <>
      <BackButton onClick={props.onBack} />
      <h2 className={'mt-7 mb-4 overflow-hidden text-ellipsis heading-30'}>
        {t('common.verifyIdentifier.title', {
          identifierValue: props.identifier.value,
        })}
      </h2>
      <p className={'m-0 mb-6 paragraph-30'}>
        {t('common.verifyIdentifier.subtitle')}{' '}
        <MarketLink
          {...((isRequestNewCodeThrottled || isProcessing) && {
            disabled: true,
          })}
          onClick={requestNewCode}
        >
          {t('common.requestNewCode')}
        </MarketLink>
      </p>

      <MarketField invalid={isInvalidCode} className={'mb-6 w-full'}>
        <MarketInputText>
          <label>{t('common.code')}</label>
          <input
            slot={'input'}
            data-testid={'code'}
            autoComplete={'one-time-code'}
            autoCorrect={'off'}
            autoCapitalize={'off'}
            inputMode={'numeric'}
            maxLength={MAX_CODE_LENGTH}
            value={code}
            onChange={(e) => {
              setCode(e.target.value);
              setIsInvalidCode(false);

              if (e.target.value.length === MAX_CODE_LENGTH && !isProcessing) {
                setShouldVerifyCode(true);
              }
            }}
            onKeyUp={handleKeyUp}
            ref={verificationCodeInputRef}
          />
        </MarketInputText>
        <small slot={'error'}>{t('common.code.invalid')}</small>
      </MarketField>
      <MarketButton
        rank={'primary'}
        className={'mb-2'}
        /* Fun workaround to get disabled working: https://github.com/squareup/market/issues/1570 */
        {...(!isCodeSubmittable && { disabled: true })}
        onClick={verifyCode}
      >
        {isProcessing ? t('common.loading') : t('common.submit')}
      </MarketButton>
    </>
  );
};

export default SignInTOTP;
