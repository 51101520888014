import i18n from 'i18n';
import { Member } from 'rpc/model/squareup/memberships/model/member';
import { theme } from 'tailwind.config';
import { isEmpty } from 'utils/string';

export interface MemberStatusAttributes {
  currentTier?: Member.BenefitPackageStatus;
  nextTier?: Member.BenefitPackageStatus;
}

export class MemberStatus {
  currentTier?: Member.BenefitPackageStatus;
  nextTier?: Member.BenefitPackageStatus;

  constructor(props: MemberStatusAttributes) {
    this.currentTier = props.currentTier;
    this.nextTier = props.nextTier;
  }

  static fromMemberProto(member: Member): MemberStatus {
    return new MemberStatus({
      currentTier: member.tieredData?.currentTier,
      nextTier: member.tieredData?.nextTier,
    });
  }

  get isBaseTier(): boolean {
    return !this.currentTier;
  }

  get currentTierColor() {
    const currentTierColor = this.currentTier?.package?.color;
    return isEmpty(currentTierColor)
      ? theme.extend.colors.blue
      : currentTierColor;
  }

  get currentTierName(): string {
    return (
      this.currentTier?.package?.name ?? i18n.t('loyalty.common.baseTierName')
    );
  }

  get currentTierUid(): string | undefined {
    return this.currentTier?.package?.uid;
  }

  get currentTierExpiration(): Date | undefined {
    return this.currentTier?.earnedData?.expiresAt
      ? new Date(this.currentTier.earnedData.expiresAt)
      : undefined;
  }

  /*
   * The member's current progress points. Note: this value will not exist
   * if the member has already reached the last tier.
   */
  get currentPoints(): number | undefined {
    return this.nextTier?.progressData?.progress?.[0]?.value;
  }

  get isLastTier(): boolean {
    return !this.nextTier;
  }

  get nextTierName(): string | undefined {
    return this.nextTier?.package?.name;
  }

  get pointsTowardNextTier(): number | undefined {
    if (this.nextTier?.progressData?.progress?.[0]) {
      return (
        this.nextTier?.progressData?.progress?.[0]?.threshold -
        this.nextTier?.progressData?.progress?.[0]?.value
      );
    }
    return undefined;
  }

  get nextTierProgressPercentage(): number | undefined {
    if (typeof this.nextTier?.progressData?.progress?.[0]?.value === 'number') {
      return Number.parseFloat(
        (
          this.nextTier.progressData.progress[0].value /
          this.nextTier.progressData.progress[0].threshold
        ).toFixed(2)
      );
    }
    return undefined;
  }
}
