// eslint-disable-next-line filenames/match-exported
import {
  MarketRow,
  MarketToggle,
  MarketDivider,
  MarketEmptyState,
} from '@market/react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { selectPrimaryId, selectIdentifierCollection } from 'store/buyerSlice';
import { IdentifierType } from 'routes/profile/models/Identifier';
import { EmailPreference, Value } from 'routes/profile/models/EmailPreference';
import ModuleLoading from '../common/loading/ModuleLoading';
import ModuleLoadFailed from '../common/errors/ModuleLoadFailed';
import { openToast } from 'store/toastSlice';
import MerchantMarketingPrefs from './MerchantMarketingPrefs';
import {
  useRetrieveGlobalEmailPreferencesQuery,
  useRetrieveMerchantEmailPreferencesQuery,
  useUpdateEmailPreferenceMutation,
} from 'store/query/api-extensions/receiptAndMarketingPreferences';
import MarketingEmailDropdown from './MarketingEmailDropdown';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import useCdpPageTracking from 'utils/custom-react-hooks/useCdpPageTracking';
import Page from '../layout/Page';
import {
  actionSelectEmailProperties,
  actionToggleMarketing,
  viewMarketingPreferencesProperties,
} from 'services/tracking/cdp/events/marketingPreferences';
import { useTranslation } from 'react-i18next';
import { buyerportalCdpClient } from 'services/tracking/cdp/clients/buyerportal';

type MarketingPreferencesProps = {};

const MarketingPreferences: React.FC<MarketingPreferencesProps> = () => {
  const { t } = useTranslation();
  const [isInitialLoaded, setIsInitialLoaded] = useState(false);

  const dispatch = useDispatch();
  const primaryEmailId = useSelector(
    (appState: AppState) => selectPrimaryId(appState, IdentifierType.Email)!
  );

  const emailIdentifiers = useSelector((state: AppState) =>
    selectIdentifierCollection(state, IdentifierType.Email)
  );

  const [selectedEmailId, setSelectedEmailId] = useState<string | null>(
    primaryEmailId || emailIdentifiers.at(0)?.token || null
  );

  const [updateEmailPreference, updateEmailPreferenceResult] =
    useUpdateEmailPreferenceMutation();

  const retrievePreferencesArg = selectedEmailId || skipToken;

  const {
    data: globalEmailPreferences,
    isLoading: isGlobalEmailPreferencesLoading,
    isFetching: isGlobalEmailPreferencesFetching,
    isError: didGlobalEmailPreferencesLoadingFail,
  } = useRetrieveGlobalEmailPreferencesQuery(retrievePreferencesArg);

  const {
    data: merchantEmailPreferences,
    isLoading: isMerchantEmailPreferencesLoading,
    isError: didMerchantEmailPreferencesLoadingFail,
  } = useRetrieveMerchantEmailPreferencesQuery(retrievePreferencesArg);

  const isLoading =
    !isInitialLoaded &&
    (isGlobalEmailPreferencesLoading || isMerchantEmailPreferencesLoading);

  const didLoadingFail =
    didGlobalEmailPreferencesLoadingFail ||
    didMerchantEmailPreferencesLoadingFail;

  const isGlobalMarketingPrefTrue =
    globalEmailPreferences?.marketingPreference.value === Value.OptIn;

  useCdpPageTracking({
    properties: viewMarketingPreferencesProperties(
      selectedEmailId || undefined,
      isGlobalMarketingPrefTrue
    ),
    isLoading,
    didLoadingFail,
    onLoad: () => setIsInitialLoaded(true),
  });

  if (isLoading) {
    return <ModuleLoading />;
  }

  if (didLoadingFail) {
    return <ModuleLoadFailed />;
  }

  const hasVisitedMerchants = merchantEmailPreferences
    ? merchantEmailPreferences.length > 0
    : false;

  const toggleGlobalEmailPreference = (isToggledOn: boolean) => {
    updateEmailPreference({
      emailId: selectedEmailId!,
      preference: new EmailPreference({
        product: globalEmailPreferences!.marketingPreference.product,
        value: isToggledOn ? Value.OptIn : Value.OptOut,
      }),
    })
      .unwrap()
      .then(() => {
        dispatch(
          openToast({
            variant: 'success',
            message: t('common.changeSuccess'),
          })
        );
      })
      .catch(() => {
        dispatch(
          openToast({
            variant: 'critical',
            message: t('common.somethingWentWrong.retryable.direct'),
          })
        );
      });
  };

  const renderEmpty = () => {
    return (
      <MarketEmptyState>
        <p slot={'secondary-text'}>
          {t('profile.marketingPreferences.emptyStateText')}
        </p>
      </MarketEmptyState>
    );
  };

  const renderNonEmpty = () => {
    const shouldShowMarketingPrefs =
      isGlobalMarketingPrefTrue &&
      hasVisitedMerchants &&
      !updateEmailPreferenceResult.isLoading;

    return (
      <>
        <div className="my-8">
          <MarketingEmailDropdown
            emailIdentifiers={emailIdentifiers}
            onSelect={(event) => {
              buyerportalCdpClient.track(
                'Select Email',
                actionSelectEmailProperties(event.detail.value as string)
              );
              setSelectedEmailId(event.detail.value as string);
            }}
            selectedEmailId={selectedEmailId!}
          />
        </div>
        <MarketRow
          key={selectedEmailId}
          {...(isGlobalMarketingPrefTrue && { selected: true })}
          {...((updateEmailPreferenceResult.isLoading ||
            isGlobalEmailPreferencesFetching) && {
            disabled: true,
          })}
          onMarketRowSelected={(_) => {
            buyerportalCdpClient.track(
              'Toggle Marketing',
              actionToggleMarketing(selectedEmailId!, true)
            );

            toggleGlobalEmailPreference(true);
          }}
          onMarketRowDeselected={(_) => {
            buyerportalCdpClient.track(
              'Toggle Marketing',
              actionToggleMarketing(selectedEmailId!, false)
            );

            toggleGlobalEmailPreference(false);
          }}
        >
          <h4 className="medium-20 my-0">
            {t('profile.marketingPreferences.autoSubscribe')}
          </h4>
          <p className="my-0 text-grey">
            {t('profile.marketingPreferences.autoSubscribeExplain')}
          </p>
          <MarketToggle slot="control" />
        </MarketRow>
        {shouldShowMarketingPrefs && (
          <>
            <MarketDivider className="my-8"></MarketDivider>
            <div>
              <h3 className={'heading-20 mt-8 mb-0'}>
                {t('profile.marketingPreferences.merchantEmailTitle')}
              </h3>
              <div className="my-4">
                <p>{t('profile.marketingPreferences.merchantEmailSubtitle')}</p>
              </div>
            </div>
            <MerchantMarketingPrefs
              emailId={primaryEmailId}
              merchantPrefs={merchantEmailPreferences!}
              isLoading={isMerchantEmailPreferencesLoading}
            />
          </>
        )}
      </>
    );
  };

  return (
    <Page>
      <h2 className={'heading-30 mb-4'}>
        {t('profile.marketingPreferences.title')}
      </h2>
      <p className={'mb-8'}>{t('profile.marketingPreferences.subtitle')}</p>
      {emailIdentifiers.length > 0 ? renderNonEmpty() : renderEmpty()}
    </Page>
  );
};

export default MarketingPreferences;
