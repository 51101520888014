import { Identifier as RpcIdentifier } from 'rpc/model/squareup/buyerportal/profile/common';

export enum IdentifierType {
  Email = 'EMAIL',
  Phone = 'PHONE',
  Card = 'CARD',
}

export type ContactInformationIdentifierType =
  | IdentifierType.Email
  | IdentifierType.Phone;

export interface IdentifierAttribute {
  token: string;
  identifierType: IdentifierType;
  displayValue: string;
}

export class Identifier {
  public token: string;
  public identifierType: IdentifierType;
  public displayValue: string;

  static fromResponse = function (identifier: RpcIdentifier): Identifier {
    const token = identifier.id!;
    const displayValue = identifier.displayValue!;
    let identifierType: IdentifierType;

    switch (identifier.type) {
      case RpcIdentifier.Type.TYPE_EMAIL: {
        identifierType = IdentifierType.Email;
        break;
      }
      case RpcIdentifier.Type.TYPE_PHONE: {
        identifierType = IdentifierType.Phone;
        break;
      }
      case RpcIdentifier.Type.TYPE_CARD: {
        identifierType = IdentifierType.Card;
        break;
      }
      default: {
        identifierType = IdentifierType.Email; // TODO: introduce error state?
        break;
      }
    }

    return new Identifier({ token, displayValue, identifierType });
  };

  constructor(props: IdentifierAttribute) {
    const { token, identifierType, displayValue } = props;

    this.token = token;
    this.identifierType = identifierType;
    this.displayValue = displayValue;
  }

  toProto() {
    const builder = new RpcIdentifier.Builder()
      .id(this.token)
      .displayValue(this.displayValue);

    switch (this.identifierType) {
      case IdentifierType.Email: {
        builder.type(RpcIdentifier.Type.TYPE_EMAIL);
        break;
      }
      case IdentifierType.Phone: {
        builder.type(RpcIdentifier.Type.TYPE_PHONE);
        break;
      }
      case IdentifierType.Card: {
        builder.type(RpcIdentifier.Type.TYPE_CARD);
        break;
      }
      default: {
        break;
      }
    }

    return builder.build();
  }
}
