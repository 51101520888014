/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      omg: {
        nested: {
          workflows: {
            nested: {
              WorkflowsCommandExtension: {
                fields: {
                  appliedCommands: {
                    type: 'squareup.omg.workflows.CommandHolder',
                    id: 1,
                    rule: 'repeated',
                  },
                },
              },
              CommandHolder: {
                fields: {
                  uid: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  command: {
                    type: 'bytes',
                    id: 2,
                    rule: 'optional',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.omg?.nested?.workflows?.nested
    ?.WorkflowsCommandExtension &&
  !$root.nested?.squareup?.nested?.omg?.nested?.workflows?.nested?.CommandHolder
) {
  $root.addJSON(jsonData);
}

/**
 * Info on persisted commands for an Order
 *
 * SOURCE WorkflowsCommandExtension @ squareup/omg/workflows/extensions.proto at 15:1
 */
export const WorkflowsCommandExtension = $root.lookupType(
  'squareup.omg.workflows.WorkflowsCommandExtension'
);
fixType(WorkflowsCommandExtension);
Builder.createAndAttachToType(WorkflowsCommandExtension);
/**
 * Message to wrap around commands
 *
 * SOURCE CommandHolder @ squareup/omg/workflows/extensions.proto at 20:1
 */
export const CommandHolder = $root.lookupType(
  'squareup.omg.workflows.CommandHolder'
);
fixType(CommandHolder);
Builder.createAndAttachToType(CommandHolder);
