/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      connect: {
        nested: {
          v2: {
            nested: {
              BankAccountStatus: {
                values: {
                  VERIFICATION_IN_PROGRESS: 1,
                  VERIFIED: 2,
                  DISABLED: 3,
                },
              },
              BankAccountType: {
                values: {
                  UNKNOWN: 0,
                  CHECKING: 1,
                  SAVINGS: 2,
                  INVESTMENT: 3,
                  OTHER: 4,
                  BUSINESS_CHECKING: 5,
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested
    ?.BankAccountStatus &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.BankAccountType
) {
  $root.addJSON(jsonData);
}

/**
 * --
 * @desc Indicates the current verification status of a `BankAccount` object.
 * --
 *
 * SOURCE BankAccountStatus @ squareup/payments/external/bank_accounts.proto at 309:1
 */
export const BankAccountStatus = $root.lookupEnum(
  'squareup.connect.v2.BankAccountStatus'
).values;
/**
 * --
 * @desc Indicates the financial purpose of the bank account.
 * --
 *
 * SOURCE BankAccountType @ squareup/payments/external/bank_accounts.proto at 334:1
 */
export const BankAccountType = $root.lookupEnum(
  'squareup.connect.v2.BankAccountType'
).values;
