/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      customers: {
        nested: {
          request: {
            nested: {
              RequestStatus: {
                values: {
                  STATUS_UNKNOWN: 0,
                  STATUS_SUCCESS: 1,
                  STATUS_NOT_FOUND: 2,
                  STATUS_BAD_REQUEST: 3,
                  STATUS_DELETED: 4,
                  STATUS_FORBIDDEN: 5,
                  STATUS_UNAUTHORIZED: 6,
                  STATUS_PRECONDITION_FAILED: 7,
                  STATUS_CONFLICT: 8,
                  STATUS_INTERNAL_ERROR: 9,
                },
              },
              RequestError: {
                fields: {
                  field: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  value: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  rawValue: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  code: {
                    type: 'string',
                    id: 4,
                    rule: 'optional',
                  },
                  details: {
                    type: 'string',
                    id: 5,
                    rule: 'optional',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.customers?.nested?.request?.nested
    ?.RequestStatus &&
  !$root.nested?.squareup?.nested?.customers?.nested?.request?.nested
    ?.RequestError
) {
  $root.addJSON(jsonData);
}

/**
 * Request status for response.
 *
 * SOURCE RequestStatus @ squareup/customers/request.proto at 13:1
 */
export const RequestStatus = $root.lookupEnum(
  'squareup.customers.request.RequestStatus'
).values;
/**
 * Errors to return as part of the response.
 *
 * SOURCE RequestError @ squareup/customers/request.proto at 101:1
 */
export const RequestError = $root.lookupType(
  'squareup.customers.request.RequestError'
);
fixType(RequestError, {
  field: { required: true },
  code: { required: true },
});
Builder.createAndAttachToType(RequestError);
