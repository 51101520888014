import React from 'react';
import { MarketSvgProps } from '.';

const Call: React.FC<MarketSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.19837 2.31L11.7953 7.9069L9.48298 10.2192C10.7198 11.777 12.1643 13.2257 13.7193 14.4681L16.0379 12.1495L21.6948 17.8064L18.6627 20.8385C18.0179 21.4832 17.0718 21.8414 16.1001 21.6468C9.50614 20.3262 3.6786 14.4986 2.358 7.90468C2.16338 6.93295 2.52152 5.98685 3.1663 5.34207L6.19837 2.31ZM6.19837 5.13842L4.58051 6.75629C4.35136 6.98544 4.27096 7.2718 4.31905 7.51193C5.48118 13.3146 10.6902 18.5236 16.4928 19.6857C16.733 19.7338 17.0193 19.6534 17.2485 19.4243L18.8663 17.8064L16.0379 14.978L13.9006 17.1152L13.2074 16.5989C11.0065 14.9597 8.98387 12.9314 7.35225 10.7268L6.83964 10.0341L8.96685 7.9069L6.19837 5.13842Z"
        fill={props.fill || '#000'}
      />
    </svg>
  );
};

export default Call;
