import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ModalType, openModal } from 'store/modalSlice';
import { useRetrieveEmailCollectionIncentiveQuery } from 'store/query/api-extensions/loyalty';
import { useParams } from 'react-router-dom';
import {
  AuthenticatedLoyaltyRouteParams,
  LoyaltySubRoute,
} from '../routeUtils';
import { openToast, ToastVariant } from 'store/toastSlice';
import { useTranslation } from 'react-i18next';
import { useNavigateToLoyaltyRoute } from 'utils/custom-react-hooks/loyalty/useNavigateToLoyaltyRoute';

/**
 * A dummy page rendered at /merchantportal/:merchantId/loyalty/lalt/:loyaltyAccountLookupToken/earning/mailing-list
 * which simply opens the EmailCollectionIncentiveModal — this component does not need to render any UI, and it supports
 * deep-linking to the EmailCollectionIncentiveModal.
 */
const EmailCollectionIncentivePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { merchantId, loyaltyAccountLookupToken } =
    useParams<AuthenticatedLoyaltyRouteParams>();
  const navigateToEarningPage = useNavigateToLoyaltyRoute({
    loyaltyAccountLookupToken,
    merchantId,
    subRoute: LoyaltySubRoute.Earning,
  });
  const incentiveTermsAndStatusResult =
    useRetrieveEmailCollectionIncentiveQuery(loyaltyAccountLookupToken, {
      skip: !loyaltyAccountLookupToken,
    });
  const { data: incentiveTermsAndStatus, isUninitialized } =
    incentiveTermsAndStatusResult;
  const isLoading = incentiveTermsAndStatusResult.isLoading || isUninitialized;
  useEffect(() => {
    if (isLoading) {
      return;
    }

    // This should only happen once when the incentive is loaded
    if (incentiveTermsAndStatus && loyaltyAccountLookupToken) {
      dispatch(
        openModal({
          props: {
            incentiveTermsAndStatus,
            loyaltyAccountLookupToken,
            merchantId,
            onClose: navigateToEarningPage,
          },
          type: ModalType.LoyaltyEmailIncentiveModal,
        })
      );
    } else {
      dispatch(
        openToast({
          message: t('common.somethingWentWrong.blocked'),
          variant: ToastVariant.Critical,
        })
      );
      navigateToEarningPage();
    }
  }, [
    dispatch,
    isLoading,
    incentiveTermsAndStatus,
    loyaltyAccountLookupToken,
    merchantId,
    navigateToEarningPage,
    t,
  ]);

  return null;
};

export default EmailCollectionIncentivePage;
