/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import '../common/data';
import '../../customers/request';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      buyerportal: {
        nested: {
          preference: {
            nested: {
              CardReceiptPreference: {
                fields: {
                  cardInfo: {
                    type: 'squareup.buyerportal.common.CardInfo',
                    id: 1,
                    rule: 'optional',
                  },
                  preference: {
                    type: 'squareup.buyerportal.preference.EmailPreference',
                    id: 2,
                    rule: 'optional',
                  },
                },
              },
              EmailPreference: {
                fields: {
                  product: {
                    type: 'squareup.buyerportal.preference.EmailPreference.Product',
                    id: 1,
                    rule: 'optional',
                  },
                  value: {
                    type: 'squareup.buyerportal.preference.EmailPreference.Value',
                    id: 2,
                    rule: 'optional',
                  },
                },
                nested: {
                  Value: {
                    values: {
                      VALUE_DO_NOT_USE: 0,
                      VALUE_OPT_IN: 1,
                      VALUE_OPT_OUT: 2,
                    },
                  },
                  Product: {
                    values: {
                      PRODUCT_DO_NOT_USE: 0,
                      PRODUCT_MARKETING: 1,
                      PRODUCT_RECEIPT: 2,
                    },
                  },
                },
              },
              MerchantEmailPreference: {
                fields: {
                  merchantInfo: {
                    type: 'squareup.buyerportal.common.MerchantInfo',
                    id: 1,
                    rule: 'optional',
                  },
                  marketingPreference: {
                    type: 'squareup.buyerportal.preference.EmailPreference',
                    id: 2,
                    rule: 'optional',
                  },
                  receiptPreferences: {
                    type: 'squareup.buyerportal.preference.CardReceiptPreference',
                    id: 3,
                    rule: 'repeated',
                  },
                },
              },
              RetrieveGlobalEmailPreferencesRequest: {
                fields: {
                  emailId: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                },
              },
              RetrieveGlobalEmailPreferencesResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                  marketingPreference: {
                    type: 'squareup.buyerportal.preference.EmailPreference',
                    id: 3,
                    rule: 'optional',
                  },
                  receiptPreference: {
                    type: 'squareup.buyerportal.preference.EmailPreference',
                    id: 4,
                    rule: 'optional',
                  },
                  cardReceiptPreferences: {
                    type: 'squareup.buyerportal.preference.CardReceiptPreference',
                    id: 5,
                    rule: 'repeated',
                  },
                  useMarketingprefs: {
                    type: 'bool',
                    id: 6,
                    rule: 'optional',
                  },
                },
              },
              RetrieveMerchantEmailPreferencesRequest: {
                fields: {
                  emailId: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  offset: {
                    type: 'int32',
                    id: 2,
                    rule: 'optional',
                  },
                  limit: {
                    type: 'int32',
                    id: 3,
                    rule: 'optional',
                  },
                },
              },
              RetrieveMerchantEmailPreferencesResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                  preferences: {
                    type: 'squareup.buyerportal.preference.MerchantEmailPreference',
                    id: 3,
                    rule: 'repeated',
                  },
                  total: {
                    type: 'int32',
                    id: 4,
                    rule: 'optional',
                  },
                },
              },
              UpdateEmailPreferenceRequest: {
                fields: {
                  emailId: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  merchantId: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  cardId: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  preference: {
                    type: 'squareup.buyerportal.preference.EmailPreference',
                    id: 4,
                    rule: 'optional',
                  },
                  copyId: {
                    type: 'string',
                    id: 5,
                    rule: 'optional',
                  },
                },
              },
              UpdateEmailPreferenceResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.preference?.nested
    ?.CardReceiptPreference &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.preference?.nested
    ?.EmailPreference &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.preference?.nested
    ?.EmailPreference?.nested?.Value &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.preference?.nested
    ?.EmailPreference?.nested?.Product &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.preference?.nested
    ?.MerchantEmailPreference &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.preference?.nested
    ?.RetrieveGlobalEmailPreferencesRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.preference?.nested
    ?.RetrieveGlobalEmailPreferencesResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.preference?.nested
    ?.RetrieveMerchantEmailPreferencesRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.preference?.nested
    ?.RetrieveMerchantEmailPreferencesResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.preference?.nested
    ?.UpdateEmailPreferenceRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.preference?.nested
    ?.UpdateEmailPreferenceResponse
) {
  $root.addJSON(jsonData);
}

/**
 * Email Preferences protos for Buyer Portal Service RPC Request-Responses
 * Receipt preference for a particular payment card of a buyer.
 *
 * SOURCE CardReceiptPreference @ squareup/buyerportal/preference/email.proto at 17:1
 */
export const CardReceiptPreference = $root.lookupType(
  'squareup.buyerportal.preference.CardReceiptPreference'
);
fixType(CardReceiptPreference, {
  cardInfo: { required: true },
  preference: { required: true },
});
Builder.createAndAttachToType(CardReceiptPreference);
/**
 * Email preference for a product.
 *
 * SOURCE EmailPreference @ squareup/buyerportal/preference/email.proto at 28:1
 */
export const EmailPreference = $root.lookupType(
  'squareup.buyerportal.preference.EmailPreference'
);
fixType(EmailPreference, {
  product: { required: true },
  value: { required: true },
});
Builder.createAndAttachToType(EmailPreference);
/**
 * Various values for the email preference.
 *
 * SOURCE Value @ squareup/buyerportal/preference/email.proto at 30:3
 */
EmailPreference.Value = $root.lookupEnum(
  'squareup.buyerportal.preference.EmailPreference.Value'
).values;
/**
 * Various products that support email preferences.
 *
 * SOURCE Product @ squareup/buyerportal/preference/email.proto at 42:3
 */
EmailPreference.Product = $root.lookupEnum(
  'squareup.buyerportal.preference.EmailPreference.Product'
).values;
/**
 * Email preference of buyer for a specific merchant.
 *
 * SOURCE MerchantEmailPreference @ squareup/buyerportal/preference/email.proto at 63:1
 */
export const MerchantEmailPreference = $root.lookupType(
  'squareup.buyerportal.preference.MerchantEmailPreference'
);
fixType(MerchantEmailPreference, {
  merchantInfo: { required: true },
});
Builder.createAndAttachToType(MerchantEmailPreference);
/**
 * Request to retrieve global email preferences of the authenticated buyer.
 *
 * SOURCE RetrieveGlobalEmailPreferencesRequest @ squareup/buyerportal/preference/email.proto at 77:1
 */
export const RetrieveGlobalEmailPreferencesRequest = $root.lookupType(
  'squareup.buyerportal.preference.RetrieveGlobalEmailPreferencesRequest'
);
fixType(RetrieveGlobalEmailPreferencesRequest, {
  emailId: { required: true },
});
Builder.createAndAttachToType(RetrieveGlobalEmailPreferencesRequest);
/**
 * Response containing global email preferences of the authenticated buyer.
 *
 * SOURCE RetrieveGlobalEmailPreferencesResponse @ squareup/buyerportal/preference/email.proto at 85:1
 */
export const RetrieveGlobalEmailPreferencesResponse = $root.lookupType(
  'squareup.buyerportal.preference.RetrieveGlobalEmailPreferencesResponse'
);
fixType(RetrieveGlobalEmailPreferencesResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(RetrieveGlobalEmailPreferencesResponse);
/**
 * Request to retrieve email preferences for merchants visited by the authenticated buyer.
 *
 * SOURCE RetrieveMerchantEmailPreferencesRequest @ squareup/buyerportal/preference/email.proto at 108:1
 */
export const RetrieveMerchantEmailPreferencesRequest = $root.lookupType(
  'squareup.buyerportal.preference.RetrieveMerchantEmailPreferencesRequest'
);
fixType(RetrieveMerchantEmailPreferencesRequest, {
  emailId: { required: true },
});
Builder.createAndAttachToType(RetrieveMerchantEmailPreferencesRequest);
/**
 * Response containing email preferences for merchants visited by the authenticated buyer.
 *
 * SOURCE RetrieveMerchantEmailPreferencesResponse @ squareup/buyerportal/preference/email.proto at 122:1
 */
export const RetrieveMerchantEmailPreferencesResponse = $root.lookupType(
  'squareup.buyerportal.preference.RetrieveMerchantEmailPreferencesResponse'
);
fixType(RetrieveMerchantEmailPreferencesResponse, {
  status: { required: true },
  total: { required: true },
});
Builder.createAndAttachToType(RetrieveMerchantEmailPreferencesResponse);
/**
 * Request to update email preferences of the authenticated buyer and a given email.
 *
 * SOURCE UpdateEmailPreferenceRequest @ squareup/buyerportal/preference/email.proto at 139:1
 */
export const UpdateEmailPreferenceRequest = $root.lookupType(
  'squareup.buyerportal.preference.UpdateEmailPreferenceRequest'
);
fixType(UpdateEmailPreferenceRequest, {
  emailId: { required: true },
  preference: { required: true },
});
Builder.createAndAttachToType(UpdateEmailPreferenceRequest);
/**
 * Response containing status and error for a buyer's update email preference request.
 *
 * SOURCE UpdateEmailPreferenceResponse @ squareup/buyerportal/preference/email.proto at 159:1
 */
export const UpdateEmailPreferenceResponse = $root.lookupType(
  'squareup.buyerportal.preference.UpdateEmailPreferenceResponse'
);
fixType(UpdateEmailPreferenceResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(UpdateEmailPreferenceResponse);
