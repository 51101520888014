import { MarketRow, MarketToggle } from '@market/react';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  EmailPreference,
  Product as EmailPreferenceProduct,
  MerchantEmailPreference,
  Value,
} from 'routes/profile/models/EmailPreference';
import MerchantReceiptPrefs from './MerchantReceiptPrefs';
import { useUpdateEmailPreferenceMutation } from 'store/query/api-extensions/receiptAndMarketingPreferences';
import { openToast } from 'store/toastSlice';
import BuyerCard from 'routes/profile/models/BuyerCard';
import { useTranslation } from 'react-i18next';
import { buyerportalCdpClient } from 'services/tracking/cdp/clients/buyerportal';
import { actionToggleAutoReceipts } from 'services/tracking/cdp/events/receiptSettings';

type ReceiptPreferenceTogglesProps = {
  deliveryEmailId: string | null;
  selectedPaymentCard: BuyerCard;
  isGloballyOptedIn: boolean;
  isGlobalToggleDisabled: boolean;
  merchantPrefs: MerchantEmailPreference[];
  isMerchantEmailPreferencesLoading: boolean;
};

const ReceiptPreferenceToggles: React.FC<ReceiptPreferenceTogglesProps> = ({
  deliveryEmailId,
  selectedPaymentCard,
  merchantPrefs,
  isGloballyOptedIn,
  isGlobalToggleDisabled,
  isMerchantEmailPreferencesLoading,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [updateEmailPreference, updateEmailPreferenceResult] =
    useUpdateEmailPreferenceMutation();

  const toggleGlobalReceiptPreference = (
    isToggledOn: boolean,
    prefCardId: string
  ) => {
    updateEmailPreference({
      // Non-null assertion: We can't toggle global pref if there's no email linked to card
      emailId: deliveryEmailId!,
      cardId: prefCardId,
      preference: new EmailPreference({
        product: EmailPreferenceProduct.Receipt,
        value: isToggledOn ? Value.OptIn : Value.OptOut,
      }),
    })
      .unwrap()
      .then(() => {
        buyerportalCdpClient.track(
          'Toggle Auto Receipts',
          actionToggleAutoReceipts(isToggledOn)
        );

        dispatch(
          openToast({
            variant: 'success',
            message: t('common.changeSuccess'),
          })
        );
      })
      .catch(() => {
        dispatch(
          openToast({
            variant: 'critical',
            message: t('common.somethingWentWrong.retryable.direct'),
          })
        );
      });
  };

  // No email linked to card => enabling receipts doesn't make sense because there's no email to send them to
  // If still global update pending, disable the toggle
  const shouldDisableGlobalToggle =
    isGlobalToggleDisabled ||
    !deliveryEmailId ||
    updateEmailPreferenceResult.isLoading;

  const renderMerchantPrefs = () => {
    if (!isGloballyOptedIn) {
      return null;
    }
    return (
      <MerchantReceiptPrefs
        emailId={deliveryEmailId!}
        cardId={selectedPaymentCard.preferenceCardId}
        merchantPrefs={merchantPrefs}
        isMerchantPrefsLoading={
          isMerchantEmailPreferencesLoading ||
          updateEmailPreferenceResult.isLoading
        }
      />
    );
  };

  return (
    <>
      <h3 className={'heading-20 mt-8 mb-0'}>
        {t('profile.receiptSettings.receiptPreferenceToggles.title')}
      </h3>
      <div className="my-4">
        <p>{t('profile.receiptSettings.receiptPreferenceToggles.subtitle')}</p>
      </div>
      <MarketRow
        id="receipt-pref-auto-receipt-row"
        onMarketRowDeselected={(_) =>
          toggleGlobalReceiptPreference(
            false,
            selectedPaymentCard!.preferenceCardId
          )
        }
        onMarketRowSelected={(_) =>
          toggleGlobalReceiptPreference(
            true,
            selectedPaymentCard!.preferenceCardId
          )
        }
        selected={isGloballyOptedIn}
        disabled={shouldDisableGlobalToggle}
      >
        <div>
          <h4 className="medium-20 my-0">
            {t(
              'profile.receiptSettings.receiptPreferenceToggles.receiveAutoEmails'
            )}
          </h4>
          <p className="my-0 text-gray-500">
            {t(
              'profile.receiptSettings.receiptPreferenceToggles.receiptDefaultEmailAddress'
            )}
          </p>
        </div>
        <MarketToggle slot="control" />
      </MarketRow>

      {renderMerchantPrefs()}
    </>
  );
};

export default ReceiptPreferenceToggles;
