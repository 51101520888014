import React, { Fragment } from 'react';
import { MarketActivityIndicator } from '@market/react';
import Page from 'routes/profile/layout/Page';
import { useTranslation } from 'react-i18next';

export type ModuleLoadingProps = {
  embedded?: boolean;
};

const ModuleLoading: React.FC<ModuleLoadingProps> = ({ embedded }) => {
  const { t } = useTranslation();
  const MaybeWrapper = embedded ? Fragment : Page;

  return (
    <MaybeWrapper>
      <div className={'flex flex-col items-center justify-center h-full'}>
        <MarketActivityIndicator size={'large'} />
        <div className={'mt-8'}>{t('common.moduleLoading')}</div>
      </div>
    </MaybeWrapper>
  );
};

export default ModuleLoading;
