import { MarketButton } from '@market/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthorizedApplications } from 'routes/profile/account/AuthorizedApplications/authorizedAppsSlice';
import { AuthorizedApplicationMetadata } from 'routes/profile/models/AuthorizedApplication';
import { AuthorizedApplicationName } from 'rpc/model/squareup/buyerportal/authorizedapps/data';
import { buyerportalCdpClient } from 'services/tracking/cdp/clients/buyerportal';
import { startDisconnectCashAppProperties } from 'services/tracking/cdp/events/profile';
import { ModalType, openModal } from 'store/modalSlice';
import { useTranslation } from 'react-i18next';

type AuthorizedApplicationProps = {
  appId: AuthorizedApplicationName;
};

const AuthorizedApplication: React.FC<AuthorizedApplicationProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const metadata = AuthorizedApplicationMetadata[props.appId];

  const unlink = async () => {
    buyerportalCdpClient.track(
      'Start Disconnect App',
      startDisconnectCashAppProperties()
    );

    dispatch(
      openModal({
        type: ModalType.ConfirmDeleteAuthorizedApplication,
        props: {
          appMetadata: metadata,
        },
      })
    );
  };

  return (
    <>
      <img
        className={'w-[40px] h-[40px]'}
        src={metadata.logo}
        alt={metadata.displayAppName}
      />
      <div className={'flex grow justify-between items-center ml-4'}>
        <div className={'flex flex-col justify-center'}>
          <span className={'font-medium'}>{metadata.displayAppName}</span>
          <span className={'pr-8 paragraph-20 text-grey'}>
            {metadata.description}
          </span>
        </div>
        <MarketButton
          size={'small'}
          rank={'tertiary'}
          variant={'destructive'}
          onClick={unlink}
        >
          {t('common.disconnect')}
        </MarketButton>
      </div>
    </>
  );
};

const AuthorizedApplications: React.FC = () => {
  const { t } = useTranslation();
  const linkedApplications = useSelector(selectAuthorizedApplications);

  const renderNoApplications = () => {
    return (
      <p className={'paragraph-30 m-0 text-grey'}>
        {t('profile.account.AuthorizedApplications.noApplications')}
      </p>
    );
  };

  const renderLinkedApplications = () => {
    return (
      <ul className={'flex list-none p-0 m-0 mt-8 items-center'}>
        {linkedApplications.map((app) => {
          return (
            <li key={app.id} className={'flex grow items-center'}>
              <AuthorizedApplication appId={app.id} />
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <>
      <h3 className={'heading-20 mt-0 mb-4'}>
        {t('profile.account.AuthorizedApplications.connectedApps')}
      </h3>
      {linkedApplications.length > 0
        ? renderLinkedApplications()
        : renderNoApplications()}
    </>
  );
};

export default AuthorizedApplications;
