import React, { useMemo } from 'react';
import { MarketAccessory, MarketActionCard, MarketRow } from '@market/react';
import LoudSpeaker from 'svgs/market-icons/LoudSpeaker';
import { ExpiringPointsDeadline } from '../models/ExpiringPointsDeadline';
import { useTranslation } from 'react-i18next';
import { DateFormat, formatDate } from 'utils/date';
import { useDispatch, useSelector } from 'react-redux';
import { ModalType, openModal } from 'store/modalSlice';
import { AppState } from 'store';
import { selectLoyaltyProgram } from 'routes/merchant-scoped-portal/integrations/loyalty/loyaltySlice';

interface PointsExpirationPreviewProps {
  deadlines: Array<ExpiringPointsDeadline>;
}

const PointsExpirationPreview: React.FC<PointsExpirationPreviewProps> = ({
  deadlines,
}) => {
  const deadlinesSortedAsc = useMemo(
    () =>
      [...deadlines].sort(
        (a, b) => a.expiresAt!.getTime() - b.expiresAt!.getTime()
      ),
    [deadlines]
  );
  const loyaltyProgram = useSelector((state: AppState) =>
    selectLoyaltyProgram(state.loyaltyMerchantPortal)
  );
  const earliestDeadline = deadlinesSortedAsc[0];
  const { t } = useTranslation();
  const singularOrPluralLoyaltyTerminology =
    earliestDeadline.points === 1
      ? loyaltyProgram?.terminology?.one
      : loyaltyProgram?.terminology?.other;
  const label = t('loyalty.pointsExpirationPreview.label', {
    count: earliestDeadline.points,
    singularOrPluralLoyaltyTerminology,
    expiration: formatDate(
      earliestDeadline.expiresAt!,
      DateFormat.MonthDayShort
    ),
  });

  const dispatch = useDispatch();
  const openPointsExpirationModal = () => {
    dispatch(
      openModal({
        type: ModalType.PointsExpiration,
        props: { deadlines: deadlinesSortedAsc },
      })
    );
  };

  return (
    <MarketActionCard onClick={openPointsExpirationModal}>
      <MarketRow variant="drill" transient>
        <MarketAccessory slot="leading-accessory">
          <LoudSpeaker />
        </MarketAccessory>
        <label slot="label">{label}</label>
      </MarketRow>
    </MarketActionCard>
  );
};

export default PointsExpirationPreview;
