// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-empty-function */
import { MarketButton, MarketDivider } from '@market/react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-cycle
import { UNSUBSCRIBE_VALUES } from '.';
import { viewAutoReceiptUnsubscribed } from 'services/tracking/cdp/events/singleClickAutomaticReceiptPreferences';
import useCdpPageTracking, {
  Client_Namespace,
} from 'utils/custom-react-hooks/useCdpPageTracking';

interface UnsubscribedProps {
  receiptToken: string;
  unsubscribedFrom: UNSUBSCRIBE_VALUES.MERCHANT | UNSUBSCRIBE_VALUES.GLOBAL;
  obfuscatedEmail?: string;
  obfuscatedCard: string;
  merchantName: string;
  doResubscribe: () => void;
  isResubscribing: boolean;
}

const Unsubscribed: React.FC<UnsubscribedProps> = (props) => {
  const { t } = useTranslation();

  /* CDP page tracking */

  useCdpPageTracking({
    client: Client_Namespace.UNSUBSCRIBE,
    didLoadingFail: false,
    isLoading: false,
    properties: viewAutoReceiptUnsubscribed(
      props.receiptToken,
      props.unsubscribedFrom === UNSUBSCRIBE_VALUES.MERCHANT
        ? 'merchant'
        : 'all'
    ),
  });

  /* Content */

  const resubscribeStyling = `hover:cursor-pointer no-underline font-bold ${
    props.isResubscribing ? 'text-grey' : 'text-blue'
  }`;
  const resubscribeOnClick = props.isResubscribing
    ? () => {}
    : props.doResubscribe;

  return (
    <>
      <h2>
        {t(
          'preference.components.unsubscribe.automaticReceipts.unsubscribed.title'
        )}
      </h2>
      <div>
        {props.unsubscribedFrom === UNSUBSCRIBE_VALUES.MERCHANT
          ? props.obfuscatedEmail
            ? t(
                'preference.components.unsubscribe.automaticReceipts.unsubscribedFromMerchant.content',
                {
                  merchantName: props.merchantName,
                  obfuscatedEmail: props.obfuscatedEmail,
                }
              )
            : t(
                'preference.components.unsubscribe.automaticReceipts.unsubscribedFromMerchant.noEmailContent',
                {
                  merchantName: props.merchantName,
                }
              )
          : t(
              'preference.components.unsubscribe.automaticReceipts.unsubscribedFromGlobal.content',
              {
                card: props.obfuscatedCard,
              }
            )}
      </div>
      {props.obfuscatedEmail && (
        <div className={'mt-7 mb-7'}>
          {/* prettier formatting breaks the "resubscribe" link, so we turn it off for this code block. */}
          {/* prettier-ignore */}
          <Trans i18nKey="preference.components.unsubscribe.automaticReceipts.content.resubscribe">
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events, react/no-unescaped-entities*/}
          If you didn't mean to unsubscribe, you can <span className={resubscribeStyling} onClick={resubscribeOnClick}>resubscribe</span> now.
        </Trans>
        </div>
      )}
      <MarketDivider />

      <h3>{t('preference.components.loginCard.title.updatePreference')}</h3>

      <div>{t('unauthenticatedVerifyEmail.success.subtitle2')}</div>

      <MarketButton
        href={'/'}
        className={'mt-7'}
        data-testid={'sign-in-button'}
      >
        {t('unauthenticatedVerifyEmail.signIn')}
      </MarketButton>
    </>
  );
};

export default Unsubscribed;
