// eslint-disable-next-line filenames/match-exported
import SqLoading from 'components/SqLoading';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ReceiptFAQ from 'routes/not-your-receipt/ReceiptFaq';
import Options from './Options';
import Unsubscribed from './Unsubscribed';
import GlobalToast from 'components/GlobalToast';
import { LogoIcon } from 'svgs';
import PrivacyFooter from 'components/SqPrivacyFooter';
import {
  useFetchQuery,
  useOptInMutation,
  useOptOutMutation,
  useGlobalOptInMutation,
  useGlobalOptOutMutation,
} from 'store/query/postoffice';
import { useDispatch } from 'react-redux';
import { openToast } from 'store/toastSlice';
import { useTranslation } from 'react-i18next';
import Resubscribed from './Resubscribed';
import useCdpPageTracking, {
  Client_Namespace,
} from 'utils/custom-react-hooks/useCdpPageTracking';
import {
  actionClickUnsubscribe,
  viewMarketingUnsubscribe,
} from 'services/tracking/cdp/events/singleClickMarketingPreferences';
import { buyerportalUnsubscribeCdpClient } from 'services/tracking/cdp/clients/buyerportal';

interface ManageMarketingParams {
  id: string;
}

export enum UNSUBSCRIBE_VALUES {
  NONE,
  MERCHANT,
  GLOBAL,
}

export type RetreiveObfuscatedPreferenceData = {
  emailOptInId?: string;
  receiptToken?: string;
  merchantName: string;
  obfuscatedEmail: string;
};

const ManageMarketing: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams<ManageMarketingParams>();

  const query = new URLSearchParams(window.location.search);
  const source = query.get('source') || '';
  const value = query.get('value') || '';

  const showErrorToast = async () => {
    dispatch(
      openToast({
        variant: 'critical',
        message: t('common.somethingWentWrong.retryable.direct'),
      })
    );
  };

  /* Queries */

  const {
    data: marketingData,
    isLoading: isFetchLoading,
    isError: hasFetchFailed,
  } = useFetchQuery({ id, source, value });

  /* Mutations */

  const [optIn, { isLoading: isOptInLoading, isSuccess: isOptInSuccess }] =
    useOptInMutation();
  const [optOut, { isLoading: isOptOutLoading }] = useOptOutMutation();
  const [
    globalOptIn,
    { isLoading: isGlobalOptInLoading, isSuccess: isGlobalOptInSuccess },
  ] = useGlobalOptInMutation();
  const [globalOptOut, { isLoading: isGlobalOptOutLoading }] =
    useGlobalOptOutMutation();

  /* CDP page view tracking */
  useCdpPageTracking({
    properties: viewMarketingUnsubscribe(id),
    isLoading: isFetchLoading,
    didLoadingFail: hasFetchFailed,
    client: Client_Namespace.UNSUBSCRIBE,
  });

  /* Unsubscribe state + functions */

  const [unsubscribedFrom, setUnsubscribedFrom] = useState<UNSUBSCRIBE_VALUES>(
    UNSUBSCRIBE_VALUES.NONE
  );

  const unsubscribeMerchant = async () => {
    buyerportalUnsubscribeCdpClient.track(
      'Click Unsubscribe',
      actionClickUnsubscribe(id, 'email', 'merchant')
    );
    optOut({ id, source, value })
      .unwrap()
      .then(() => {
        setUnsubscribedFrom(UNSUBSCRIBE_VALUES.MERCHANT);
      })
      .catch((_) => {
        showErrorToast();
      });
  };

  const unsubscribeGlobal = async () => {
    buyerportalUnsubscribeCdpClient.track(
      'Click Unsubscribe',
      actionClickUnsubscribe(id, 'email', 'all')
    );

    // TODO: Remove this once postoffice supports country code and language code in global opt out
    const countryCode = 'US';
    const locale = 'en-US';

    globalOptOut({ id, source, value, countryCode, languageCode: locale })
      .unwrap()
      .then(() => {
        setUnsubscribedFrom(UNSUBSCRIBE_VALUES.GLOBAL);
      })
      .catch((_) => {
        showErrorToast();
      });
  };

  const resubscribe = async () => {
    if (unsubscribedFrom === UNSUBSCRIBE_VALUES.MERCHANT) {
      optIn({ id, source, value })
        .unwrap()
        .catch((_) => {
          showErrorToast();
        });
    } else if (unsubscribedFrom === UNSUBSCRIBE_VALUES.GLOBAL) {
      // TODO: Remove this once postoffice supports country code and language code in global opt out
      const countryCode = 'US';
      const locale = 'en-US';

      globalOptIn({ id, source, value, countryCode, languageCode: locale })
        .unwrap()
        .catch((_) => {
          showErrorToast();
        });
    }
  };

  /* Content */

  let content;
  if (isFetchLoading) {
    content = (
      <div
        className={
          'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
        }
      >
        <SqLoading />
      </div>
    );
  } else if (
    hasFetchFailed ||
    marketingData?.merchantName === undefined ||
    marketingData?.obfuscatedEmail === undefined
  ) {
    content = <ReceiptFAQ />;
  } else if (unsubscribedFrom === UNSUBSCRIBE_VALUES.NONE) {
    content = (
      <Options
        doUnsubscribeMerchant={unsubscribeMerchant}
        doUnsubscribeGlobal={unsubscribeGlobal}
        obfuscatedEmail={marketingData.obfuscatedEmail}
        merchantName={marketingData.merchantName}
        isUnsubscribing={isOptOutLoading || isGlobalOptOutLoading}
      />
    );
  } else if (isGlobalOptInSuccess || isOptInSuccess) {
    content = (
      <Resubscribed
        unsubscribedFrom={unsubscribedFrom}
        emailOptInId={id}
        obfuscatedEmail={marketingData?.obfuscatedEmail || ''}
        merchantName={marketingData?.merchantName || ''}
        doResubscribe={resubscribe}
        isResubscribing={isOptInLoading || isGlobalOptInLoading}
        isResubscribed={isGlobalOptInSuccess || isOptInSuccess}
      />
    );
  } else {
    content = (
      <Unsubscribed
        unsubscribedFrom={unsubscribedFrom}
        emailOptInId={id}
        obfuscatedEmail={marketingData?.obfuscatedEmail || ''}
        merchantName={marketingData?.merchantName || ''}
        doResubscribe={resubscribe}
        isResubscribing={isOptInLoading || isGlobalOptInLoading}
      />
    );
  }

  return (
    <>
      <div className={'flex justify-center min-h-full grow'}>
        <GlobalToast />
        <div
          className={
            'flex flex-col justify-center py-[47px] px-[24px] w-[calc(100vw-48px)] sm:w-[440px]'
          }
        >
          <LogoIcon className={'text-[32px] mb-[12px]'} />
          {content}
        </div>
      </div>
      <PrivacyFooter />
    </>
  );
};

export default ManageMarketing;
