import { LoyaltyTermsOfService } from 'rpc/model/squareup/card/balance/model/loyalty-terms-of-service';

function matchesLanguage(
  language: string
): (copy: LoyaltyTermsOfService.Copy) => boolean {
  return function matchesCopy(copy: LoyaltyTermsOfService.Copy) {
    return copy.locale.toLowerCase().startsWith(language.toLowerCase());
  };
}

export function chooseConsentCopy(
  desiredLocale: string,
  copyList: readonly LoyaltyTermsOfService.Copy[]
): LoyaltyTermsOfService.Copy | undefined {
  const copyWithExactLocale = copyList.find(
    (copy) => copy.locale === desiredLocale
  );
  if (copyWithExactLocale) {
    return copyWithExactLocale;
  }

  const primaryLocaleLanguage = desiredLocale.split('-')[0].toLowerCase();
  const copyWithSameLanguage = copyList.find(
    matchesLanguage(primaryLocaleLanguage)
  );
  if (copyWithSameLanguage) {
    return copyWithSameLanguage;
  }

  const copyWithEnglishLanguage = copyList.find(matchesLanguage('en'));
  if (copyWithEnglishLanguage) {
    return copyWithEnglishLanguage;
  }

  return undefined;
}
