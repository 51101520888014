/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../Fixes';
import Builder from '../../../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      feature: {
        nested: {
          relay: {
            nested: {
              common: {
                nested: {
                  Token: {
                    fields: {
                      token: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      type: {
                        type: 'squareup.feature.relay.common.Token.Type',
                        id: 2,
                        rule: 'optional',
                      },
                    },
                    nested: {
                      Type: {
                        values: {
                          UNKNOWN: 0,
                          UNIT: 1,
                          MERCHANT: 2,
                          DEVICE_ID: 3,
                          ANONYMOUS_VISITOR: 4,
                          PERSON: 5,
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.feature?.nested?.relay?.nested?.common
    ?.nested?.Token &&
  !$root.nested?.squareup?.nested?.feature?.nested?.relay?.nested?.common
    ?.nested?.Token?.nested?.Type
) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE Token @ squareup/feature/relay/common/token.proto at 10:1
 */
export const Token = $root.lookupType('squareup.feature.relay.common.Token');
fixType(Token);
Builder.createAndAttachToType(Token);
/**
 * The type of the token
 *
 * SOURCE Type @ squareup/feature/relay/common/token.proto at 15:3
 */
Token.Type = $root.lookupEnum(
  'squareup.feature.relay.common.Token.Type'
).values;
