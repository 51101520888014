// eslint-disable-next-line filenames/match-exported
import React, { useState } from 'react';
import {
  ContactInformationIdentifierType,
  IdentifierType,
} from 'routes/profile/models/Identifier';
import DestinationExportData from './DestinationExportData';
import RequestExportData from './RequestExportData';
import SuccessExportData from './SuccessExportData';
import VerifyExportData from './VerifyExportData';

enum ExportDataStep {
  Request = 0,
  Destination = 1,
  Verify = 2,
  Success = 3,
}

type ExportDataProps = {
  onCancel: () => void;
};

const ExportData: React.FC<ExportDataProps> = (props) => {
  const [step, setStep] = useState<ExportDataStep>(ExportDataStep.Request);
  const [identifierType, setIdentifierType] =
    useState<ContactInformationIdentifierType>(IdentifierType.Email);
  const [identifierValue, setIdentifierValue] = useState<string>('');
  const [destinationEmail, setDestinationEmail] = useState<string>('');

  /*
   * Renders the component corresponding to the current step of the export data flow.
   */
  const renderStep = () => {
    switch (step) {
      case ExportDataStep.Request: {
        return (
          <RequestExportData
            onClickBack={props.onCancel}
            onClickNext={(collectedType, collectedValue) => {
              setIdentifierType(collectedType);
              setIdentifierValue(collectedValue);
              setStep(step + 1);
            }}
          />
        );
      }
      case ExportDataStep.Destination: {
        return (
          <DestinationExportData
            identifierType={identifierType}
            identifierValue={identifierValue}
            onClickBack={() => setStep(step - 1)}
            onClickNext={(destinationEmail) => {
              setDestinationEmail(destinationEmail);
              setStep(step + 1);
            }}
          />
        );
      }
      case ExportDataStep.Verify: {
        return (
          <VerifyExportData
            identifierType={identifierType}
            identifierValue={identifierValue}
            destinationEmail={destinationEmail}
            onClickBack={() => setStep(step - 1)}
            onClickNext={() => setStep(step + 1)}
          />
        );
      }
      case ExportDataStep.Success: {
        return <SuccessExportData identifierType={identifierType} />;
      }
    }
  };

  return <>{renderStep()}</>;
};

export default ExportData;
