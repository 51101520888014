import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  createLoyaltySubRouteUrl,
  LoyaltySubRoute,
} from 'routes/merchant-scoped-portal/integrations/loyalty/routeUtils';

export const useNavigateToLoyaltyRoute = ({
  loyaltyAccountLookupToken,
  merchantId,
  subRoute = LoyaltySubRoute.Rewards,
}: {
  loyaltyAccountLookupToken: string;
  merchantId: string;
  subRoute?: LoyaltySubRoute;
}) => {
  const url = useMemo(
    () =>
      createLoyaltySubRouteUrl({
        loyaltyAccountLookupToken,
        merchantId,
        subRoute,
      }),
    [loyaltyAccountLookupToken, merchantId, subRoute]
  );

  const history = useHistory();
  const navigateToLoyaltyRoute = useCallback(() => {
    history.push(url);
  }, [history, url]);

  return navigateToLoyaltyRoute;
};
