import CdpClient from '../client';
import config from 'config/config';

export const buyerportalCdpClient = new CdpClient(
  config.customerDataPlatform.buyerportalApiKey
);

export const buyerportalUnsubscribeCdpClient = new CdpClient(
  config.customerDataPlatform.buyerportalUnsubscribeApiKey
);
