import { buyerportalApi } from '..';
import { callApi, getResponseError } from '../utils';
import { RequestStatus } from 'rpc/model/squareup/customers/request';
import { Payment } from 'routes/profile/models/Payment';
import {
  ListOrdersRequest,
  ListOrdersResponse,
} from 'rpc/model/squareup/buyerportal/orders/request';
import ordersClient from 'rpc/ordersClient';
import { OrderListItem } from 'rpc/model/squareup/buyerportal/orders/data';

export const orderBaseRpcs = buyerportalApi.injectEndpoints({
  endpoints: (builder) => ({
    listOrders: builder.query<Payment[], void>({
      async queryFn() {
        const response = await callApi<ListOrdersResponse>(
          async () => await ordersClient.listOrders(ListOrdersRequest.create())
        );

        if (response.data!.status !== RequestStatus.STATUS_SUCCESS) {
          return getResponseError(response.data!);
        }

        return {
          data: response.data?.orderListItems.map((payment: OrderListItem) =>
            Payment.fromResponse(payment)
          ),
        };
      },
    }),
  }),
});

export const { useListOrdersQuery } = orderBaseRpcs;
