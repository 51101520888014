import React, { useMemo } from 'react';
import { Link, LinkProps, useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MarketTab, MarketTabList } from '@market/react';
import {
  AuthenticatedLoyaltySubRouteParams,
  LoyaltySubRoute,
  authenticatedLoyaltyRoutePattern,
  authenticatedLoyaltySubRoutePattern,
} from '../routeUtils';

const UnstyledLink = (props: LinkProps) => (
  <Link className="no-underline text-inherit" {...props} />
);

const NavigationBar = () => {
  const baseRouteMatch = useRouteMatch(authenticatedLoyaltyRoutePattern);
  const subRouteMatch = useRouteMatch<AuthenticatedLoyaltySubRouteParams>(
    authenticatedLoyaltySubRoutePattern
  );
  const history = useHistory();
  const { t } = useTranslation();

  const selectedTab = useMemo(() => {
    switch (subRouteMatch?.params?.subRoute?.toLowerCase()) {
      case LoyaltySubRoute.Account: {
        return LoyaltySubRoute.Account;
      }
      case LoyaltySubRoute.Activity: {
        return LoyaltySubRoute.Activity;
      }
      case LoyaltySubRoute.Earning: {
        return LoyaltySubRoute.Earning;
      }
      default: {
        return LoyaltySubRoute.Rewards;
      }
    }
  }, [subRouteMatch]);

  return (
    <MarketTabList
      data-testid="loyalty-nav"
      selectedTab={selectedTab}
      onMarketTabListSelectedTabChanged={(event) => {
        // This is a current limitation of market tabs and is necessary to navigate
        // to the desired route when you click anywhere on the tab, and not just
        // the text/link itself.
        history.push(`${baseRouteMatch?.url}/${event.detail.value}`);
      }}
    >
      <MarketTab id={LoyaltySubRoute.Rewards}>
        <UnstyledLink to={`${baseRouteMatch?.url}/rewards`}>
          {t('loyalty.nav.rewards')}
        </UnstyledLink>
      </MarketTab>
      <MarketTab id={LoyaltySubRoute.Earning}>
        <UnstyledLink to={`${baseRouteMatch?.url}/earning`}>
          {t('loyalty.nav.earning')}
        </UnstyledLink>
      </MarketTab>
      <MarketTab id={LoyaltySubRoute.Account}>
        <UnstyledLink to={`${baseRouteMatch?.url}/account`}>
          {t('loyalty.nav.account')}
        </UnstyledLink>
      </MarketTab>
    </MarketTabList>
  );
};

export default NavigationBar;
