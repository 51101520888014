import { Address as RpcAddress } from 'rpc/model/squareup/buyerportal/buyer/data';
import { Country } from 'rpc/model/squareup/common/countries';

export interface Address {
  id: string;
  addressLine1: string;
  addressLine2: string;
  locality: string;
  sublocality: string;
  administrativeDistrictLevel1: string;
  postalCode: string;
  countryCode: Country;
}

export const AddressConverters = {
  fromRpcAddress(address: RpcAddress): Address {
    if (!address.addressData) {
      throw new Error(
        'Cannot convert null or undefined address data to address model.'
      );
    }

    const {
      addressLine1,
      addressLine2,
      locality,
      sublocality,
      administrativeDistrictLevel1,
      postalCode,
      countryCode,
    } = address.addressData;

    return {
      id: address.addressToken,
      addressLine1,
      addressLine2,
      locality,
      sublocality,
      administrativeDistrictLevel1,
      postalCode,
      countryCode,
    };
  },
};

export const AddressFormatters = {
  formatSingleLineAddress(address: Address) {
    if (!address) {
      return '';
    }

    const addressParts = getAddressPartsDefault(address);

    return joinIfPresent(', ', addressParts);
  },
};

function getAddressPartsDefault(address: Address) {
  return [
    address.addressLine1,
    address.addressLine2,
    joinIfPresent(', ', [
      address.locality,
      joinIfPresent(' ', [
        address.administrativeDistrictLevel1,
        address.postalCode,
      ]),
    ]),
  ];
}

function joinIfPresent(separator: string, items: string[]) {
  return items.filter((item) => item?.trim()).join(separator);
}
