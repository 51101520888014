// eslint-disable-next-line filenames/match-exported
import DataApiInternal from '@squareup/data-api.js-core/dist/data-api';
import { environment } from 'utils/environment';

export class CdpClient {
  apiKey: string;
  environmentName: string;

  constructor(apiKey: string) {
    const { name } = environment();
    this.load(apiKey, name);
    this.apiKey = apiKey;
    this.environmentName = name;
  }

  page(properties: { [key: string]: any }, options = {}) {
    this.load(this.apiKey, this.environmentName);
    DataApiInternal.page(null, properties, options);
  }

  identify(personToken: string) {
    this.load(this.apiKey, this.environmentName);
    DataApiInternal.identify(personToken, 'buyer');
  }

  track(eventName: string, properties: { [key: string]: any }, options = {}) {
    this.load(this.apiKey, this.environmentName);
    DataApiInternal.track(eventName, properties, options);
  }

  load(key: string, environment: string): void {
    DataApiInternal.load(key, environment);
  }
}

export default CdpClient;
