import {
  AuthorizedApplicationName,
  ListAuthorizedApplicationsResponse,
} from 'rpc/model/squareup/buyerportal/authorizedapps/data';
import cashAppLogo from 'routes/cash-linking/assets/cashAppLogo.png';

export type AuthorizedApplication = {
  displayAppName: string;
  displayValue: string;
  description: string;
  removeConfirmDescription: string;
  logo: string;
  id: AuthorizedApplicationName;
};

export const AuthorizedApplicationMetadata: {
  [key in AuthorizedApplicationName]: AuthorizedApplication;
} = {
  [AuthorizedApplicationName.DO_NOT_USE]: {
    displayAppName: 'Unknown',
    displayValue: 'Unknown',
    description: 'Unknown',
    removeConfirmDescription: 'Unknown',
    logo: '',
    id: AuthorizedApplicationName.DO_NOT_USE,
  },
  [AuthorizedApplicationName.CASH_APP]: {
    displayAppName: 'Cash App',
    displayValue: '',
    description: 'Receive and redeem coupons from Square sellers on Cash App.',
    removeConfirmDescription:
      "You'll no longer have access to your coupons form Square sellers in your Cash App.",
    logo: cashAppLogo,
    id: AuthorizedApplicationName.CASH_APP,
  },
};

export const AuthorizedApplicationConverters = {
  fromResponse(
    response: ListAuthorizedApplicationsResponse
  ): AuthorizedApplication[] {
    return response.applications.map((app) => ({
      ...AuthorizedApplicationMetadata[app.name],
      displayValue: app.displayValue,
    }));
  },
};
