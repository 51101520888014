/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import './benefit-package';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      memberships: {
        nested: {
          model: {
            nested: {
              MembershipProgram: {
                fields: {
                  id: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  type: {
                    type: 'squareup.memberships.model.MembershipProgram.Type',
                    id: 2,
                    rule: 'optional',
                  },
                  status: {
                    type: 'squareup.memberships.model.MembershipProgram.Status',
                    id: 3,
                    rule: 'optional',
                  },
                  tieredData: {
                    type: 'squareup.memberships.model.MembershipProgram.TieredData',
                    id: 4,
                    rule: 'optional',
                  },
                  version: {
                    type: 'int32',
                    id: 5,
                    rule: 'optional',
                  },
                  updatedAt: {
                    type: 'string',
                    id: 7,
                    rule: 'optional',
                  },
                  merchantToken: {
                    type: 'string',
                    id: 6,
                    rule: 'optional',
                  },
                },
                nested: {
                  Type: {
                    values: {
                      TYPE_DO_NOT_USE: 0,
                      TIERED: 1,
                    },
                  },
                  TieredData: {
                    fields: {
                      tiers: {
                        type: 'squareup.memberships.model.BenefitPackage',
                        id: 1,
                        rule: 'repeated',
                      },
                      memberStartingProgress: {
                        type: 'squareup.memberships.model.MembershipProgram.TieredData.MemberStartingProgress',
                        id: 2,
                        rule: 'optional',
                      },
                      backfillStartAt: {
                        type: 'string',
                        id: 3,
                        rule: 'optional',
                      },
                    },
                    nested: {
                      MemberStartingProgress: {
                        values: {
                          TYPE_DO_NOT_USE: 0,
                          ZERO_PROGRESS: 1,
                          CURRENT_PROGRESS: 2,
                          BACKFILLED_PROGRESS: 3,
                        },
                      },
                    },
                  },
                  Status: {
                    values: {
                      STATUS_DO_NOT_USE: 0,
                      INACTIVE: 1,
                      ACTIVE: 2,
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.memberships?.nested?.model?.nested
    ?.MembershipProgram &&
  !$root.nested?.squareup?.nested?.memberships?.nested?.model?.nested
    ?.MembershipProgram?.nested?.Type &&
  !$root.nested?.squareup?.nested?.memberships?.nested?.model?.nested
    ?.MembershipProgram?.nested?.TieredData &&
  !$root.nested?.squareup?.nested?.memberships?.nested?.model?.nested
    ?.MembershipProgram?.nested?.TieredData?.nested?.MemberStartingProgress &&
  !$root.nested?.squareup?.nested?.memberships?.nested?.model?.nested
    ?.MembershipProgram?.nested?.Status
) {
  $root.addJSON(jsonData);
}

/**
 * --
 * @desc Represents a membership program. Membership programs define how buyers can earn one or more benefit package(s).
 * --
 *
 * SOURCE MembershipProgram @ squareup/memberships/model/membership-program.proto at 18:1
 */
export const MembershipProgram = $root.lookupType(
  'squareup.memberships.model.MembershipProgram'
);
fixType(MembershipProgram);
Builder.createAndAttachToType(MembershipProgram);
/**
 * --
 * @desc The type of membership program.
 * --
 *
 * SOURCE Type @ squareup/memberships/model/membership-program.proto at 22:3
 */
MembershipProgram.Type = $root.lookupEnum(
  'squareup.memberships.model.MembershipProgram.Type'
).values;
/**
 * --
 * @desc Provides metadata when the membership program `type` is `TIERED`
 * --
 *
 * SOURCE TieredData @ squareup/memberships/model/membership-program.proto at 34:3
 */
MembershipProgram.TieredData = $root.lookupType(
  'squareup.memberships.model.MembershipProgram.TieredData'
);
fixType(MembershipProgram.TieredData, {
  tiers: { required: true },
});
Builder.createAndAttachToType(MembershipProgram.TieredData);
/**
 * --
 * @desc The starting progress of a member when onboarding an existing buyer.
 * --
 *
 * SOURCE MemberStartingProgress @ squareup/memberships/model/membership-program.proto at 43:5
 */
MembershipProgram.TieredData.MemberStartingProgress = $root.lookupEnum(
  'squareup.memberships.model.MembershipProgram.TieredData.MemberStartingProgress'
).values;
/**
 * --
 * @desc Indicates whether the program is currently active.
 * --
 *
 * SOURCE Status @ squareup/memberships/model/membership-program.proto at 80:3
 */
MembershipProgram.Status = $root.lookupEnum(
  'squareup.memberships.model.MembershipProgram.Status'
).values;
