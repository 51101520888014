const FEATURE = 'Marketing Preferences';

export const viewMarketingPreferencesProperties = (
  defaultEmailId?: string,
  marketingOptIn?: boolean
) => {
  return {
    pageTitle: 'Marketing Preferences',
    defaultEmailId,
    marketingOptIn,
  };
};

export const actionSelectEmailProperties = (emailId: string) => {
  return {
    feature: FEATURE,
    emailId,
  };
};

export const actionToggleMarketing = (
  emailId: string,
  toggledOn: boolean,
  merchantToken?: string
) => {
  return {
    feature: FEATURE,
    emailId,
    toggledOn,
    merchantToken,
  };
};

export const actionSearchMerchants = (emailId: string) => {
  return {
    feature: FEATURE,
    emailId,
  };
};
