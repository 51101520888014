/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import './benefit';
import './progress-metric';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      memberships: {
        nested: {
          model: {
            nested: {
              BenefitPackage: {
                fields: {
                  uid: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  type: {
                    type: 'squareup.memberships.model.BenefitPackage.Type',
                    id: 2,
                    rule: 'optional',
                  },
                  name: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  color: {
                    type: 'string',
                    id: 4,
                    rule: 'optional',
                  },
                  eligibilityCriteria: {
                    type: 'squareup.memberships.model.BenefitPackage.EligibilityCriteria',
                    id: 5,
                    rule: 'repeated',
                  },
                  benefits: {
                    type: 'squareup.memberships.model.Benefit',
                    id: 6,
                    rule: 'repeated',
                  },
                  tieredData: {
                    type: 'squareup.memberships.model.BenefitPackage.TieredData',
                    id: 7,
                    rule: 'optional',
                  },
                },
                nested: {
                  Type: {
                    values: {
                      TYPE_DO_NOT_USE: 0,
                      TIERED: 1,
                    },
                  },
                  TieredData: {
                    fields: {
                      tierRank: {
                        type: 'uint32',
                        id: 1,
                        rule: 'optional',
                      },
                    },
                  },
                  EligibilityCriteria: {
                    fields: {
                      uid: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      earnCriteria: {
                        type: 'squareup.memberships.model.BenefitPackage.EligibilityCriteria.EarnCriteria',
                        id: 2,
                        rule: 'optional',
                      },
                      eligibilityPeriod: {
                        type: 'squareup.memberships.model.BenefitPackage.EligibilityCriteria.EligibilityPeriod',
                        id: 3,
                        rule: 'optional',
                      },
                      termLength: {
                        type: 'squareup.memberships.model.BenefitPackage.EligibilityCriteria.TermLength',
                        id: 4,
                        rule: 'optional',
                      },
                    },
                    nested: {
                      EarnCriteria: {
                        fields: {
                          metric: {
                            type: 'squareup.memberships.model.ProgressMetric',
                            id: 1,
                            rule: 'optional',
                          },
                          threshold: {
                            type: 'int64',
                            id: 2,
                            rule: 'optional',
                          },
                        },
                      },
                      EligibilityPeriod: {
                        values: {
                          ELIGIBILITY_DO_NOT_USE: 0,
                          ELIGIBILITY_LIFETIME: 1,
                          ELIGIBILITY_CALENDAR_YEAR: 2,
                          ELIGIBILITY_ROLLING_YEAR: 3,
                        },
                      },
                      TermLength: {
                        values: {
                          TERM_DO_NOT_USE: 0,
                          TERM_LIFETIME: 1,
                          TERM_CALENDAR_YEAR: 2,
                          TERM_ROLLING_YEAR: 3,
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.memberships?.nested?.model?.nested
    ?.BenefitPackage &&
  !$root.nested?.squareup?.nested?.memberships?.nested?.model?.nested
    ?.BenefitPackage?.nested?.Type &&
  !$root.nested?.squareup?.nested?.memberships?.nested?.model?.nested
    ?.BenefitPackage?.nested?.TieredData &&
  !$root.nested?.squareup?.nested?.memberships?.nested?.model?.nested
    ?.BenefitPackage?.nested?.EligibilityCriteria &&
  !$root.nested?.squareup?.nested?.memberships?.nested?.model?.nested
    ?.BenefitPackage?.nested?.EligibilityCriteria?.nested?.EarnCriteria &&
  !$root.nested?.squareup?.nested?.memberships?.nested?.model?.nested
    ?.BenefitPackage?.nested?.EligibilityCriteria?.nested?.EligibilityPeriod &&
  !$root.nested?.squareup?.nested?.memberships?.nested?.model?.nested
    ?.BenefitPackage?.nested?.EligibilityCriteria?.nested?.TermLength
) {
  $root.addJSON(jsonData);
}

/**
 * --
 * @desc Represents a collection of benefits in a membership program. A benefit package defines the requirements to gain access to the benefits, such as earning a number of loyalty points in a given period, as well as the benefits provided. A membership program can offer multiple benefit packages.
 * --
 *
 * SOURCE BenefitPackage @ squareup/memberships/model/benefit-package.proto at 19:1
 */
export const BenefitPackage = $root.lookupType(
  'squareup.memberships.model.BenefitPackage'
);
fixType(BenefitPackage, {
  name: { notEmpty: true },
  color: { notEmpty: true },
  eligibilityCriteria: { required: true },
  benefits: { required: true },
});
Builder.createAndAttachToType(BenefitPackage);
/**
 * --
 * @desc The type of benefit package.
 * --
 *
 * SOURCE Type @ squareup/memberships/model/benefit-package.proto at 23:3
 */
BenefitPackage.Type = $root.lookupEnum(
  'squareup.memberships.model.BenefitPackage.Type'
).values;
/**
 * --
 * @desc Provides metadata when the benefit package `type` is `TIERED`
 * --
 *
 * SOURCE TieredData @ squareup/memberships/model/benefit-package.proto at 35:3
 */
BenefitPackage.TieredData = $root.lookupType(
  'squareup.memberships.model.BenefitPackage.TieredData'
);
fixType(BenefitPackage.TieredData);
Builder.createAndAttachToType(BenefitPackage.TieredData);
/**
 * --
 * @desc The requirements for a buyer to access a given package. Multiple criteria can be provided and are evaluated such that if _any_ criteria is met, the buyer will gain access to the package.
 * --
 *
 * SOURCE EligibilityCriteria @ squareup/memberships/model/benefit-package.proto at 45:3
 */
BenefitPackage.EligibilityCriteria = $root.lookupType(
  'squareup.memberships.model.BenefitPackage.EligibilityCriteria'
);
fixType(BenefitPackage.EligibilityCriteria, {
  earnCriteria: { required: true },
  eligibilityPeriod: { required: true },
});
Builder.createAndAttachToType(BenefitPackage.EligibilityCriteria);
/**
 * --
 * @desc Represents a threshold for a given metric to determine tier eligibility.
 * --
 *
 * SOURCE EarnCriteria @ squareup/memberships/model/benefit-package.proto at 49:5
 */
BenefitPackage.EligibilityCriteria.EarnCriteria = $root.lookupType(
  'squareup.memberships.model.BenefitPackage.EligibilityCriteria.EarnCriteria'
);
fixType(BenefitPackage.EligibilityCriteria.EarnCriteria, {
  metric: { required: true },
  threshold: { required: true },
});
Builder.createAndAttachToType(BenefitPackage.EligibilityCriteria.EarnCriteria);
/**
 * --
 * @desc The period over which progress will be accumulated.
 * --
 *
 * SOURCE EligibilityPeriod @ squareup/memberships/model/benefit-package.proto at 64:5
 */
BenefitPackage.EligibilityCriteria.EligibilityPeriod = $root.lookupEnum(
  'squareup.memberships.model.BenefitPackage.EligibilityCriteria.EligibilityPeriod'
).values;
/**
 * --
 * @desc The period over which the buyer will retain access to this package.
 * --
 *
 * SOURCE TermLength @ squareup/memberships/model/benefit-package.proto at 86:5
 */
BenefitPackage.EligibilityCriteria.TermLength = $root.lookupEnum(
  'squareup.memberships.model.BenefitPackage.EligibilityCriteria.TermLength'
).values;
