const ALLOWED_EXTERNAL_HOSTS = new Set([
  'localoffers.squareupstaging.com',
  'localoffers.squareup.com',
]);

export function isValidSquareProfileUrl(url: string): boolean {
  try {
    const parsedURL = new URL(url);

    // Check if the URL matches the current host
    const currentHost = window.location.host;

    return (
      parsedURL.host === currentHost ||
      ALLOWED_EXTERNAL_HOSTS.has(parsedURL.host)
    );
  } catch {
    return false;
  }
}

export function isRelativeUrl(url: string): boolean {
  // Check if the URL starts with "//", indicating a protocol-relative URL
  if (url.startsWith('//')) {
    return false;
  }

  // Check if the URL starts with "/", indicating a relative URL
  if (url.startsWith('/')) {
    return true;
  }

  // If it doesn't start with "/" or "//", consider it an absolute URL
  return false;
}

/**
 * Normalizes a URL to ensure it has the https protocol if needed,
 * and validates the URL format.
 *
 * @param {string | undefined} url - The URL to normalize.
 * @returns {string | undefined} - The normalized URL or undefined if the URL is invalid or has a non-http/https protocol.
 */
export function normalizeUrl(url?: string): string | undefined {
  if (!url) {
    return undefined;
  }

  try {
    const parsedUrl = new URL(url);

    // If the protocol is not http or https, return undefined
    if (!['http:', 'https:'].includes(parsedUrl.protocol)) {
      return undefined;
    }

    // If the URL is valid and has http or https protocol, return it as is
    return parsedUrl.href.replace(/\/$/, '');
  } catch {
    // If URL is relative or invalid, return undefined
    if (url.startsWith('www.')) {
      // Add https:// to URLs starting with 'www.'
      return `https://${url}`;
    } else if (/^[\dA-Za-z-]+\.[A-Za-z]{2,}$/.test(url)) {
      // If URL is like 'example.com', add https://www.
      return `https://www.${url}`;
    } else if (/^[\w-]+(\.[\w-]+)+$/.test(url)) {
      // If URL has subdomains and doesn't start with www., add https://
      return `https://${url}`;
    } else {
      return undefined;
    }
  }
}
