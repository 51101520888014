import React from 'react';
import { MarketActivityIndicator, MarketLink } from '@market/react';
import { useHistory, useLocation } from 'react-router-dom';
import { Components } from '@market/web-components/dist/types/';
import { useTranslation } from 'react-i18next';
import SomethingWentWrong from './alerts/SomethingWentWrong';

type ClickableTextProps = {
  text: string;
  onClick?: () => void;
};

export type InternalLinkProps = {
  type: 'internal';
  relativePath: string;
} & ClickableTextProps;

export type ExternalLinkProps = {
  type: 'external';
  href: string;
  target: Components.MarketLink['target'];
} & ClickableTextProps;

export type ButtonLinkProps = {
  PrefixIcon?: React.FC;
  type: 'button';
  onClick: () => void;
} & ClickableTextProps;

export type LinkProps = InternalLinkProps | ExternalLinkProps | ButtonLinkProps;

export type OverviewTileContainerProps = {
  hasBorder?: boolean;
  title: string;
  headerLinkConfig?: LinkProps;
  children?: React.ReactNode;
  isErrored?: boolean;
  isLoading?: boolean;
};

export const ROUNDED_GRAY_BORDER_CLASSES =
  'border border-gray-300 border-solid rounded-md';

const OverviewTileContainer: React.FC<OverviewTileContainerProps> = ({
  hasBorder,
  title,
  headerLinkConfig,
  children,
  isErrored,
  isLoading,
}) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  const renderHeaderLink = () => {
    if (!headerLinkConfig) {
      return null;
    }

    // render different types of links based on the type of the link
    switch (headerLinkConfig.type) {
      case 'internal': {
        return (
          <MarketLink
            onClick={() => {
              if (headerLinkConfig.onClick) {
                headerLinkConfig.onClick();
              }
              history.push(`${pathname}${headerLinkConfig.relativePath}`);
            }}
          >
            {headerLinkConfig.text}
          </MarketLink>
        );
      }
      case 'external': {
        return (
          <MarketLink
            href={headerLinkConfig.href}
            target={headerLinkConfig.target}
            onClick={headerLinkConfig.onClick}
          >
            {headerLinkConfig.text}
          </MarketLink>
        );
      }
      case 'button': {
        return (
          <div className="flex items-center">
            {headerLinkConfig.PrefixIcon && <headerLinkConfig.PrefixIcon />}
            <MarketLink onClick={headerLinkConfig.onClick}>
              {' '}
              {headerLinkConfig.text}
            </MarketLink>
          </div>
        );
      }
      default: {
        return null;
      }
    }
  };

  const renderForLoading = () => {
    return (
      <div className="flex flex-col items-center justify-center py-8 px-4">
        <MarketActivityIndicator size="small" data-testid="loading-spinner" />
      </div>
    );
  };

  const renderForError = () => {
    return (
      <SomethingWentWrong
        subtitle={t('common.checkConnection')}
        type="section"
      />
    );
  };

  const renderContentForState = () => {
    if (isLoading) {
      return renderForLoading();
    }
    if (isErrored) {
      return renderForError();
    }
    return children;
  };

  const shouldRenderBorder = hasBorder || isErrored || isLoading;

  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex justify-between items-center">
        <h3 className="my-0">{title}</h3>
        {renderHeaderLink()}
      </div>
      <div
        className={shouldRenderBorder ? ROUNDED_GRAY_BORDER_CLASSES : ''}
        data-testid={`${
          shouldRenderBorder ? '' : 'un'
        }bordered-overview-tile-container`}
      >
        {renderContentForState()}
      </div>
    </div>
  );
};

export default OverviewTileContainer;
