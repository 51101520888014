import { Identifier } from 'routes/profile/models/Identifier';
import { CardPreference as RpcCardPreference } from 'rpc/model/squareup/buyerportal/preference/card';
import { Card } from './Card';

export enum Product {
  Receipt,
}

export enum Type {
  Identifier,
}

export enum Action {
  Link,
  Unlink,
}

export interface CardPreferenceAttribute {
  type: Type;
  product: Product;
  identifier: Identifier;
}

export class CardPreference implements CardPreferenceAttribute {
  public type: Type;
  public product: Product;
  public identifier: Identifier;

  static fromResponse = function (
    prefernce: RpcCardPreference
  ): CardPreference {
    const type = Type.Identifier;
    const product = Product.Receipt;
    const identifier = Identifier.fromResponse(prefernce.identifier!);

    return new CardPreference({ type, product, identifier });
  };

  constructor(props: CardPreferenceAttribute) {
    const { type, product, identifier } = props;

    this.type = type;
    this.product = product;
    this.identifier = identifier;
  }
}

/* Wrapper for CardPreference */
export type CardPreferenceModel = {
  id: string;
  record?: Card;
};

export const CardPreferenceConverters = {
  fromCard(response: Card): CardPreferenceModel {
    return {
      id: response.cardId,
      record: response,
    };
  },
};
