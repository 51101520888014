/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../Fixes';
import Builder from '../../../../Builder';
import './message/get_flags';
import './message/flag_evaluation_details';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      feature: {
        nested: {
          relay: {
            nested: {
              flags: {
                nested: {
                  FlagsService: {
                    methods: {
                      GetFlags: {
                        requestType:
                          'squareup.feature.relay.flags.message.GetFlagsRequest',
                        responseType:
                          'squareup.feature.relay.flags.message.GetFlagsResponse',
                      },
                      GetFlagEvaluationDetails: {
                        requestType:
                          'squareup.feature.relay.flags.message.FlagEvaluationDetailsRequest',
                        responseType:
                          'squareup.feature.relay.flags.message.FlagEvaluationDetailsResponse',
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.feature?.nested?.relay?.nested?.flags?.nested
    ?.FlagsService
) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE FlagsService @ squareup/feature/relay/flags/service.proto at 14:1
 */
export const FlagsService = $root.lookupService(
  'squareup.feature.relay.flags.FlagsService'
);
