/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      connect: {
        nested: {
          v2: {
            nested: {
              CashDrawerDetails: {
                fields: {
                  shiftId: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  clientEventId: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                },
              },
              SuperPosMetadata: {
                fields: {
                  modeId: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested
    ?.CashDrawerDetails &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.SuperPosMetadata
) {
  $root.addJSON(jsonData);
}

/**
 * --
 * @desc Details about the cash drawer provided by clients at the time of the payment or refund.
 * --
 *
 * SOURCE CashDrawerDetails @ squareup/pago/external/external_api_common.proto at 15:1
 */
export const CashDrawerDetails = $root.lookupType(
  'squareup.connect.v2.CashDrawerDetails'
);
fixType(CashDrawerDetails);
Builder.createAndAttachToType(CashDrawerDetails);
/**
 * Metadata related to a SuperPOS Payment/Refund that is initiated with SuperPOS app.
 *
 * SOURCE SuperPosMetadata @ squareup/pago/external/external_api_common.proto at 29:1
 */
export const SuperPosMetadata = $root.lookupType(
  'squareup.connect.v2.SuperPosMetadata'
);
fixType(SuperPosMetadata);
Builder.createAndAttachToType(SuperPosMetadata);
