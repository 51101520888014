import { useState, useEffect } from 'react';

/**
 * This is a custom React hook that will keep track of a delayed value. This is good for limiting the number of updates to state (ex: due to lots of quick user interactions, like typing).
 *
 * @param value The value to delay setting
 * @param delay The amount of time to delay before setting the [value]
 */
function useDebouncedValue<T>(value: T, delay: number): T {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(
    () => {
      // Update debounced value after the specified delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on component unmount)
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

export default useDebouncedValue;
