import { MarketButton } from '@market/react';
import React from 'react';
import BackArrowIcon from 'svgs/BackArrowIcon';

type BackButtonProps = {
  onClick: Function;
  className?: string;
  slot?: string;
};

const BackButton: React.FC<BackButtonProps> = (props) => {
  return (
    <MarketButton
      slot={props.slot}
      data-testid={'back-button'}
      rank={'secondary'}
      className={`w-[50px] h-[50px] ${props.className}`}
      onClick={() => props.onClick()}
    >
      <BackArrowIcon slot={'icon'} className={'w-4 h-4'} />
    </MarketButton>
  );
};

export default BackButton;
