import React from 'react';
import { MarketRow, MarketAccessory } from '@market/react';
import { TrashIcon, BankIcon } from 'svgs';
import { OnFileBankAccount } from 'rpc/model/squareup/buyerportal/onfile/data';

export type PaymentMethod = {
  accountNumberSuffix: string;
  bankName: string;
  bankId: string;
};

type PaymentMethodOnFileProps = {
  onSelectPaymentMethod: (paymentMethod: PaymentMethod) => void;
  paymentMethods: readonly OnFileBankAccount[];
};

const PaymentMethodOnFile: React.FC<PaymentMethodOnFileProps> = ({
  onSelectPaymentMethod,
  paymentMethods,
}) => {
  return (
    <div>
      <div>
        <p className={'font-bold text-[19px]'}>Payment methods on file</p>
        <p className={'text-base'}>
          The following payments have been saved on file with all locations:
        </p>
      </div>
      {paymentMethods.map((payment) => {
        return (
          <MarketRow
            key={payment.bankId}
            controlPosition="leading"
            data-testid="payment-method-row"
          >
            <label slot="label">
              {payment.bankName} **** {payment.accountNumberSuffix}
            </label>
            <MarketAccessory size="icon" slot="leading-accessory">
              <BankIcon />
            </MarketAccessory>
            <MarketAccessory
              size="icon"
              slot="trailing-accessory"
              onClick={() => {
                onSelectPaymentMethod(payment);
              }}
            >
              <TrashIcon
                className={'cursor-pointer'}
                fill="rgba(0, 0, 0, 0.55)"
              />
            </MarketAccessory>
          </MarketRow>
        );
      })}
      <p className={'text-sm text-black/[.55]'}>
        Note: If you set up recurring or pre-scheduled payment, your
        authorization is valid until payment method on file is removed.
      </p>
    </div>
  );
};

export default PaymentMethodOnFile;
