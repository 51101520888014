import { buyerportalApi } from '..';
import {
  GetMerchantLocationsRequest,
  GetMerchantLocationsResponse,
  GetMerchantPortalBaseDataRequest,
  GetMerchantPortalBaseDataResponse,
  IMerchantOverview,
  MerchantLocation,
} from 'rpc/model/squareup/buyerportal/merchantportal/data';
import { callApi, getResponseError } from '../utils';
import merchantPortalClient from 'rpc/merchantPortalClient';
import { RequestStatus } from 'rpc/model/squareup/customers/request';
import { IBuyer } from 'rpc/model/squareup/buyerportal/buyer/data';

export type MerchantPortalBaseData = {
  merchantOverview: IMerchantOverview;
  buyer?: IBuyer;
};

export const merchantPortalBaseRpcs = buyerportalApi.injectEndpoints({
  endpoints: (builder) => ({
    getMerchantPortalBaseData: builder.query<MerchantPortalBaseData, string>({
      async queryFn(merchantId) {
        const response = await callApi<GetMerchantPortalBaseDataResponse>(
          async () =>
            await merchantPortalClient.getMerchantPortalBaseData(
              GetMerchantPortalBaseDataRequest.create({ merchantId })
            )
        );

        if (response.data!.status !== RequestStatus.STATUS_SUCCESS) {
          return getResponseError(response.data!);
        }
        return {
          data: {
            merchantOverview: response.data!.merchantOverview,
            buyer: response.data!.buyer,
          },
        };
      },
    }),
    getMerchantLocations: builder.query<MerchantLocation[], string>({
      async queryFn(merchantId) {
        const response = await callApi<GetMerchantLocationsResponse>(
          async () =>
            await merchantPortalClient.getMerchantLocations(
              GetMerchantLocationsRequest.create({ merchantId })
            )
        );

        if (response.data!.status !== RequestStatus.STATUS_SUCCESS) {
          return getResponseError(response.data!);
        }

        return {
          data: response.data!.locations,
        };
      },
    }),
  }),
});

export const {
  useGetMerchantPortalBaseDataQuery,
  useGetMerchantLocationsQuery,
} = merchantPortalBaseRpcs;
