/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      buyerportal: {
        nested: {
          profile: {
            nested: {
              Identifier: {
                fields: {
                  id: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  type: {
                    type: 'squareup.buyerportal.profile.Identifier.Type',
                    id: 2,
                    rule: 'optional',
                  },
                  displayValue: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                },
                nested: {
                  Type: {
                    values: {
                      TYPE_DO_NOT_USE: 0,
                      TYPE_EMAIL: 1,
                      TYPE_PHONE: 2,
                      TYPE_CARD: 3,
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.profile?.nested
    ?.Identifier &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.profile?.nested
    ?.Identifier?.nested?.Type
) {
  $root.addJSON(jsonData);
}

/**
 * Common protos for Buyer Profile Request-Responses
 * Identifier that uniquely identify a Buyer Profile.
 *
 * SOURCE Identifier @ squareup/buyerportal/profile/common.proto at 16:1
 */
export const Identifier = $root.lookupType(
  'squareup.buyerportal.profile.Identifier'
);
fixType(Identifier, {
  id: { required: true },
  type: { required: true },
  displayValue: { required: true },
});
Builder.createAndAttachToType(Identifier);
/**
 * Types of identifiers that are supported on a Buyer Profile.
 *
 * SOURCE Type @ squareup/buyerportal/profile/common.proto at 21:3
 */
Identifier.Type = $root.lookupEnum(
  'squareup.buyerportal.profile.Identifier.Type'
).values;
