/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import '../profile/common';
import '../common/data';
import '../../customers/request';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      buyerportal: {
        nested: {
          preference: {
            nested: {
              CardPreference: {
                fields: {
                  type: {
                    type: 'squareup.buyerportal.preference.CardPreference.Type',
                    id: 1,
                    rule: 'optional',
                  },
                  product: {
                    type: 'squareup.buyerportal.preference.CardPreference.Product',
                    id: 2,
                    rule: 'optional',
                  },
                  identifier: {
                    type: 'squareup.buyerportal.profile.Identifier',
                    id: 3,
                    rule: 'optional',
                  },
                },
                nested: {
                  Product: {
                    values: {
                      PRODUCT_DO_NOT_USE: 0,
                      PRODUCT_RECEIPT: 1,
                      PRODUCT_LOYALTY: 2,
                    },
                  },
                  Type: {
                    values: {
                      TYPE_DO_NOT_USE: 0,
                      TYPE_IDENTIFIER: 1,
                    },
                  },
                  UpdateActionType: {
                    values: {
                      UPDATE_ACTION_DO_NOT_USE: 0,
                      UPDATE_ACTION_LINK: 1,
                      UPDATE_ACTION_UNLINK: 2,
                    },
                  },
                },
              },
              CardInfoAndPreference: {
                fields: {
                  cardInfo: {
                    type: 'squareup.buyerportal.common.CardInfo',
                    id: 1,
                    rule: 'optional',
                  },
                  cardPreferences: {
                    type: 'squareup.buyerportal.preference.CardPreference',
                    id: 2,
                    rule: 'optional',
                  },
                },
              },
              RetrieveCardPreferencesRequest: {
                fields: {
                  cardId: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                },
              },
              RetrieveCardPreferencesResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                  cardInfo: {
                    type: 'squareup.buyerportal.common.CardInfo',
                    id: 3,
                    rule: 'optional',
                  },
                  cardPreferences: {
                    type: 'squareup.buyerportal.preference.CardPreference',
                    id: 4,
                    rule: 'repeated',
                  },
                },
              },
              UpdateCardPreferenceRequest: {
                fields: {
                  cardId: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  actionType: {
                    type: 'squareup.buyerportal.preference.CardPreference.UpdateActionType',
                    id: 2,
                    rule: 'optional',
                  },
                  cardPreference: {
                    type: 'squareup.buyerportal.preference.CardPreference',
                    id: 3,
                    rule: 'optional',
                  },
                },
              },
              UpdateCardPreferenceResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                },
              },
              SearchCardPreferencesRequest: {
                fields: {
                  identifier: {
                    type: 'squareup.buyerportal.profile.Identifier',
                    id: 1,
                    rule: 'optional',
                  },
                  product: {
                    type: 'squareup.buyerportal.preference.CardPreference.Product',
                    id: 2,
                    rule: 'optional',
                  },
                },
              },
              SearchCardPreferencesResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                  cardInfoAndPreferences: {
                    type: 'squareup.buyerportal.preference.CardInfoAndPreference',
                    id: 3,
                    rule: 'repeated',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.preference?.nested
    ?.CardPreference &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.preference?.nested
    ?.CardPreference?.nested?.Product &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.preference?.nested
    ?.CardPreference?.nested?.Type &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.preference?.nested
    ?.CardPreference?.nested?.UpdateActionType &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.preference?.nested
    ?.CardInfoAndPreference &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.preference?.nested
    ?.RetrieveCardPreferencesRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.preference?.nested
    ?.RetrieveCardPreferencesResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.preference?.nested
    ?.UpdateCardPreferenceRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.preference?.nested
    ?.UpdateCardPreferenceResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.preference?.nested
    ?.SearchCardPreferencesRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.preference?.nested
    ?.SearchCardPreferencesResponse
) {
  $root.addJSON(jsonData);
}

/**
 * Card Preferences protos for Buyer Portal Service RPC Request-Responses
 * Preference for a given payment card and product.
 *
 * SOURCE CardPreference @ squareup/buyerportal/preference/card.proto at 18:1
 */
export const CardPreference = $root.lookupType(
  'squareup.buyerportal.preference.CardPreference'
);
fixType(CardPreference, {
  type: { required: true },
  product: { required: true },
});
Builder.createAndAttachToType(CardPreference);
/**
 * Various products supported for Card Preferences.
 *
 * SOURCE Product @ squareup/buyerportal/preference/card.proto at 23:3
 */
CardPreference.Product = $root.lookupEnum(
  'squareup.buyerportal.preference.CardPreference.Product'
).values;
/**
 * Various types of preferences that can be linked to a payment card.
 *
 * SOURCE Type @ squareup/buyerportal/preference/card.proto at 37:3
 */
CardPreference.Type = $root.lookupEnum(
  'squareup.buyerportal.preference.CardPreference.Type'
).values;
/**
 * Various types of update actions supported for card preferences.
 *
 * SOURCE UpdateActionType @ squareup/buyerportal/preference/card.proto at 49:3
 */
CardPreference.UpdateActionType = $root.lookupEnum(
  'squareup.buyerportal.preference.CardPreference.UpdateActionType'
).values;
/**
 * SOURCE CardInfoAndPreference @ squareup/buyerportal/preference/card.proto at 71:1
 */
export const CardInfoAndPreference = $root.lookupType(
  'squareup.buyerportal.preference.CardInfoAndPreference'
);
fixType(CardInfoAndPreference, {
  cardInfo: { required: true },
  cardPreferences: { required: true },
});
Builder.createAndAttachToType(CardInfoAndPreference);
/**
 * Request to retrieve card preferences of the authenticated buyer and given card.
 *
 * SOURCE RetrieveCardPreferencesRequest @ squareup/buyerportal/preference/card.proto at 82:1
 */
export const RetrieveCardPreferencesRequest = $root.lookupType(
  'squareup.buyerportal.preference.RetrieveCardPreferencesRequest'
);
fixType(RetrieveCardPreferencesRequest, {
  cardId: { required: true },
});
Builder.createAndAttachToType(RetrieveCardPreferencesRequest);
/**
 * Response containing card preferences of the authenticated buyer and given card.
 *
 * SOURCE RetrieveCardPreferencesResponse @ squareup/buyerportal/preference/card.proto at 90:1
 */
export const RetrieveCardPreferencesResponse = $root.lookupType(
  'squareup.buyerportal.preference.RetrieveCardPreferencesResponse'
);
fixType(RetrieveCardPreferencesResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(RetrieveCardPreferencesResponse);
/**
 * Request to update a card preference of the authenticated buyer and given card.
 *
 * SOURCE UpdateCardPreferenceRequest @ squareup/buyerportal/preference/card.proto at 107:1
 */
export const UpdateCardPreferenceRequest = $root.lookupType(
  'squareup.buyerportal.preference.UpdateCardPreferenceRequest'
);
fixType(UpdateCardPreferenceRequest, {
  cardId: { required: true },
  actionType: { required: true },
});
Builder.createAndAttachToType(UpdateCardPreferenceRequest);
/**
 * Response containing status and errors for a buyer's update card preference request.
 *
 * SOURCE UpdateCardPreferenceResponse @ squareup/buyerportal/preference/card.proto at 121:1
 */
export const UpdateCardPreferenceResponse = $root.lookupType(
  'squareup.buyerportal.preference.UpdateCardPreferenceResponse'
);
fixType(UpdateCardPreferenceResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(UpdateCardPreferenceResponse);
/**
 * Request to search for card preferences for a Buyer.
 *
 * SOURCE SearchCardPreferencesRequest @ squareup/buyerportal/preference/card.proto at 132:1
 */
export const SearchCardPreferencesRequest = $root.lookupType(
  'squareup.buyerportal.preference.SearchCardPreferencesRequest'
);
fixType(SearchCardPreferencesRequest, {
  identifier: { required: true },
});
Builder.createAndAttachToType(SearchCardPreferencesRequest);
/**
 * Response to search request for card preferences for a Buyer.
 *
 * SOURCE SearchCardPreferencesResponse @ squareup/buyerportal/preference/card.proto at 143:1
 */
export const SearchCardPreferencesResponse = $root.lookupType(
  'squareup.buyerportal.preference.SearchCardPreferencesResponse'
);
fixType(SearchCardPreferencesResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(SearchCardPreferencesResponse);
