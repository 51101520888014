/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      connect: {
        nested: {
          v2: {
            nested: {
              ecom_connect: {
                nested: {
                  resources: {
                    nested: {
                      Site: {
                        fields: {
                          id: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                          siteTitle: {
                            type: 'string',
                            id: 2,
                            rule: 'optional',
                          },
                          domain: {
                            type: 'string',
                            id: 3,
                            rule: 'optional',
                          },
                          isPublished: {
                            type: 'bool',
                            id: 4,
                            rule: 'optional',
                          },
                          createdAt: {
                            type: 'string',
                            id: 5,
                            rule: 'optional',
                          },
                          updatedAt: {
                            type: 'string',
                            id: 6,
                            rule: 'optional',
                          },
                          siteThemeId: {
                            type: 'string',
                            id: 7,
                            rule: 'optional',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.ecom_connect
    ?.nested?.resources?.nested?.Site
) {
  $root.addJSON(jsonData);
}

/**
 * --
 * @desc Represents a Square Online site, which is an online store for a Square seller.
 * --
 *
 * SOURCE Site @ squareup/ecom_connect/resources/ecom_connect.proto at 20:1
 */
export const Site = $root.lookupType(
  'squareup.connect.v2.ecom_connect.resources.Site'
);
fixType(Site);
Builder.createAndAttachToType(Site);
