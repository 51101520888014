import React, { useEffect } from 'react';
import {
  MarketDialog,
  MarketFooter,
  MarketButtonGroup,
  MarketButton,
} from '@market/react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'store/modalSlice';
import { useTranslation } from 'react-i18next';

export interface BasicDialogProps {
  header: string;
  onRender?: () => void;
  persistent: boolean;
  primaryButtonLabel?: string;
  primaryButtonAction: () => void;
  secondaryButtonLabel?: string;
  secondaryButtonAction?: () => void;
  subtext: string;
}

const BasicDialog: React.FC<BasicDialogProps> = ({
  header,
  onRender,
  persistent = false,
  primaryButtonAction,
  primaryButtonLabel,
  secondaryButtonAction,
  secondaryButtonLabel,
  subtext,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const close = () => {
    dispatch(closeModal());
  };

  useEffect(() => {
    onRender?.();
  }, [onRender]);

  const handleClick = (buttonAction: () => void) => {
    return () => {
      buttonAction();
      close();
    };
  };

  return (
    <MarketDialog persistent={persistent} onMarketDialogDismissed={close}>
      <main>
        <h2 className="mt-1">{header}</h2>
        <p>{subtext}</p>
      </main>
      <MarketFooter>
        <MarketButtonGroup alignment="fill">
          <MarketButton
            rank="primary"
            onClick={handleClick(primaryButtonAction)}
          >
            {primaryButtonLabel ?? t('common.confirm')}
          </MarketButton>
          {secondaryButtonAction && (
            <MarketButton
              rank="secondary"
              onClick={handleClick(secondaryButtonAction)}
            >
              {secondaryButtonLabel ?? t('common.cancel')}
            </MarketButton>
          )}
        </MarketButtonGroup>
      </MarketFooter>
    </MarketDialog>
  );
};

export default BasicDialog;
