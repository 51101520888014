import React, { useMemo } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { Link } from '@material-ui/core';
import { LinkProps } from '@material-ui/core/Link';

export const WrappedLink = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(
  (props, ref) => <RouterLink innerRef={ref} {...props} />
);
WrappedLink.displayName = 'WrappedLink';

interface SqLinkProps {
  to?: string;
  href?: string;
}

export const SqLink: React.FC<
  Omit<LinkProps, 'href'> & Omit<RouterLinkProps, 'to'> & SqLinkProps // Revisit props definition
> = ({ children, className, to, href, onClick, target }) => {
  const classes = useMemo(() => ({ root: className }), [className]);

  if (href) {
    return (
      <Link href={href} target={target} classes={classes} onClick={onClick}>
        {children}
      </Link>
    );
  }

  if (to) {
    return (
      <Link component={WrappedLink} to={to} classes={classes} onClick={onClick}>
        {children}
      </Link>
    );
  }

  return (
    <Link onClick={onClick} classes={classes}>
      {children}
    </Link>
  );
};

export default SqLink;
