/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import '../api/sync/sync';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      api: {
        nested: {
          items: {
            nested: {
              Type: {
                values: {
                  ITEM: 0,
                  PAGE: 1,
                  ITEM_IMAGE: 2,
                  ITEM_PAGE_MEMBERSHIP: 3,
                  MENU_CATEGORY: 4,
                  ITEM_VARIATION: 5,
                  FEE: 6,
                  PLACEHOLDER: 7,
                  DISCOUNT: 8,
                  ITEM_FEE_MEMBERSHIP: 9,
                  ITEM_MODIFIER_LIST: 10,
                  ITEM_ITEM_MODIFIER_LIST_MEMBERSHIP: 11,
                  ITEM_MODIFIER_OPTION: 12,
                  ADDITIONAL_ITEM_IMAGE: 14,
                  INVENTORY_INFO: 16,
                  OBSOLETE_TENDER_FEE: 17,
                  DINING_OPTION: 18,
                  TAX_RULE: 19,
                  CONFIGURATION: 20,
                  TICKET_GROUP: 21,
                  TICKET_TEMPLATE: 22,
                  VOID_REASON: 23,
                  MENU: 24,
                  TAG: 25,
                  FLOOR_PLAN: 26,
                  FLOOR_PLAN_TILE: 27,
                  FAVORITES_LIST_POSITION: 28,
                  MENU_GROUP_MEMBERSHIP: 29,
                  SURCHARGE: 30,
                  PRICING_RULE: 31,
                  PRODUCT_SET: 32,
                  TIME_PERIOD: 33,
                  SURCHARGE_FEE_MEMBERSHIP: 34,
                  AGE_RESTRICTION: 35,
                },
              },
              TicketTemplate: {
                fields: {
                  id: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  name: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  ordinal: {
                    type: 'int32',
                    id: 3,
                    rule: 'optional',
                  },
                  ticketGroup: {
                    type: 'squareup.api.sync.ObjectId',
                    id: 4,
                    rule: 'optional',
                  },
                },
              },
              TicketGroup: {
                fields: {
                  id: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  name: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  ordinal: {
                    type: 'int32',
                    id: 3,
                    rule: 'optional',
                  },
                  namingMethod: {
                    type: 'squareup.api.items.TicketGroup.NamingMethod',
                    id: 4,
                    rule: 'optional',
                    options: {
                      default: 'AUTOMATIC_NUMBERING',
                    },
                  },
                  label: {
                    type: 'string',
                    id: 5,
                    rule: 'optional',
                  },
                  numberingStartsAt: {
                    type: 'int32',
                    id: 6,
                    rule: 'optional',
                  },
                  prefixTableNames: {
                    type: 'squareup.api.items.TicketGroup.PrefixTableNames',
                    id: 7,
                    rule: 'optional',
                    options: {
                      default: 'NONE',
                    },
                  },
                },
                nested: {
                  NamingMethod: {
                    values: {
                      AUTOMATIC_NUMBERING: 0,
                      MANUAL: 1,
                    },
                  },
                  PrefixTableNames: {
                    values: {
                      DO_NOT_USE: 0,
                      NONE: 1,
                      SECTION: 2,
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.api?.nested?.items?.nested?.Type &&
  !$root.nested?.squareup?.nested?.api?.nested?.items?.nested?.TicketTemplate &&
  !$root.nested?.squareup?.nested?.api?.nested?.items?.nested?.TicketGroup &&
  !$root.nested?.squareup?.nested?.api?.nested?.items?.nested?.TicketGroup
    ?.nested?.NamingMethod &&
  !$root.nested?.squareup?.nested?.api?.nested?.items?.nested?.TicketGroup
    ?.nested?.PrefixTableNames
) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE Type @ squareup/items/items.proto at 37:1
 */
export const Type = $root.lookupEnum('squareup.api.items.Type').values;
/**
 * A ticket template is a configurable name and identifier that is used to create
 * pre-defined open tickets.  Merchants create a list of ticket templates that they
 * then use to create open ticket instances.  This is useful as a rudimentary form of
 * table management (e.g., ticket templates called "Table 1", "Table 2", etc.).
 *
 * Ticket templates are stored in an ordered list within a ticket group, referenced
 * by id.
 *
 * SOURCE TicketTemplate @ squareup/items/items.proto at 2145:1
 */
export const TicketTemplate = $root.lookupType(
  'squareup.api.items.TicketTemplate'
);
fixType(TicketTemplate);
Builder.createAndAttachToType(TicketTemplate);
/**
 * Ticket groups are ordered collections of ticket templates.  In a table management
 * context, a ticket group might be something like "Dining Room", "Bar", or "Patio".
 *
 * SOURCE TicketGroup @ squareup/items/items.proto at 2168:1
 */
export const TicketGroup = $root.lookupType('squareup.api.items.TicketGroup');
fixType(TicketGroup);
Builder.createAndAttachToType(TicketGroup);
/**
 * SOURCE NamingMethod @ squareup/items/items.proto at 2179:3
 */
TicketGroup.NamingMethod = $root.lookupEnum(
  'squareup.api.items.TicketGroup.NamingMethod'
).values;
/**
 * SOURCE PrefixTableNames @ squareup/items/items.proto at 2203:3
 */
TicketGroup.PrefixTableNames = $root.lookupEnum(
  'squareup.api.items.TicketGroup.PrefixTableNames'
).values;
