import React, { useMemo } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import ModuleLoadFailed from 'routes/profile/common/errors/ModuleLoadFailed';
import ModuleLoading from 'routes/profile/common/loading/ModuleLoading';
import { useRetrieveAccountInfoQuery } from 'store/query/api-extensions/loyalty';
import { createLoyaltySubRouteUrl } from '../routeUtils';

const LegacyProfilePageRedirect = () => {
  const { loyaltyAccountLookupToken } = useParams<{
    loyaltyAccountLookupToken: string;
  }>();

  const { data, isError, isLoading } = useRetrieveAccountInfoQuery(
    loyaltyAccountLookupToken,
    {
      skip: !loyaltyAccountLookupToken,
    }
  );

  const { merchantId } = data || {};
  const redirectUrl = useMemo(
    () =>
      merchantId
        ? createLoyaltySubRouteUrl({
            loyaltyAccountLookupToken,
            merchantId,
          })
        : null,
    [loyaltyAccountLookupToken, merchantId]
  );

  if (isLoading) {
    return <ModuleLoading />;
  }

  if (isError || !redirectUrl) {
    return <ModuleLoadFailed />;
  }

  return <Redirect to={redirectUrl} />;
};

export default LegacyProfilePageRedirect;
