import {
  LoyaltyProgram,
  LoyaltyProgramAccrualRule,
  LoyaltyProgramTerminology,
} from 'rpc/model/squareup/card/balance/loyalty_api/model/loyalty-program';
import i18n from 'i18n';
import { formatConnectMoney } from 'utils/currency';

export const DEFAULT_POINTS_TERMINOLOGY = LoyaltyProgramTerminology.create({
  one: i18n.t('loyalty.terminology.points.one'),
  other: i18n.t('loyalty.terminology.points.other'),
});

export function getProgramDescription(program: LoyaltyProgram): string {
  const accrualRule = program.accrualRules[0];
  const points = accrualRule.points;
  const pointsTerminology =
    points === 1 ? program.terminology?.one : program.terminology?.other;

  switch (accrualRule.accrualType) {
    case LoyaltyProgramAccrualRule.Type.VISIT: {
      const minAmount = accrualRule.visitData?.minimumAmountMoney;
      if (minAmount) {
        return i18n.t(
          'loyalty.program.earningRuleDescription.visitBased.withMinAmount',
          {
            minAmount: formatConnectMoney(minAmount, {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }),
            points,
            pointsTerminology,
          }
        );
      }
      return i18n.t(
        'loyalty.program.earningRuleDescription.visitBased.withoutMinAmount',
        {
          points,
          pointsTerminology,
        }
      );
    }
    case LoyaltyProgramAccrualRule.Type.SPEND: {
      return i18n.t('loyalty.program.earningRuleDescription.spendBased', {
        amount: formatConnectMoney(accrualRule.spendData?.amountMoney!, {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        }),
        points,
        pointsTerminology,
      });
    }
    case LoyaltyProgramAccrualRule.Type.ITEM_VARIATION:
    case LoyaltyProgramAccrualRule.Type.CATEGORY: {
      return i18n.t(
        'loyalty.program.earningRuleDescription.itemOrCategoryBased',
        {
          pointsTerminology: program.terminology?.other,
        }
      );
    }
    default: {
      return '';
    }
  }
}
