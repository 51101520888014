import BuyerCard from 'routes/profile/models/BuyerCard';
import { GiftCard } from 'routes/profile/models/GiftCard';

export const viewPaymentMethodsProperties = (
  paymentCardCount: number,
  giftCardCount: number,
  unverifiedCardCount: number
) => {
  return {
    pageTitle: 'Payment Methods',
    paymentCardCount,
    giftCardCount,
    unverifiedCardCount,
  };
};

export const viewPaymentCardDetailsProperties = (
  cardId: string,
  isExpired: boolean,
  isDefault: boolean
) => {
  return {
    pageTitle: 'Card Details',
    cardId,
    expired: isExpired,
    defaultSet: isDefault,
  };
};

export const viewGiftCardDetailsProperties = (
  giftCardId: string,
  balance: string,
  merchantToken: string,
  manuallyAdded: boolean
) => {
  return {
    pageTitle: 'Gift Card Details',
    giftCardId,
    balance,
    merchantToken,
    manuallyAdded,
  };
};

export const actionSetPaymentCardAsDefaultProperties = (
  paymentCard: BuyerCard,
  isDefault: boolean
) => {
  return {
    cardType: paymentCard.brand,
    cardId: paymentCard.cardsApiId,
    isDefault,
  };
};

export const actionRemovePaymentCardProperties = (
  cardType: string,
  cardId: string,
  isDefault: boolean
) => {
  return {
    cardType,
    cardId,
    isDefault,
  };
};

export const actionVerifyUnverifiedPaymentCardProperties = (cardId: string) => {
  return {
    cardId,
  };
};

export const actionRemoveGiftCardProperties = (giftCard: GiftCard) => {
  return {
    giftCardToken: giftCard.token,
    balance: giftCard.formattedBalance(),
  };
};

export const actionTransferGiftCardBalanceProperties = (
  fromGiftCardToken: string,
  toGiftCardToken: string,
  transferAmount: string,
  merchantToken: string
) => {
  return {
    fromGiftCardToken,
    toGiftCardToken,
    transferAmount,
    merchantToken,
  };
};
