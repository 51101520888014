import { MarketRow } from '@market/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { selectAccount } from '../loyaltySlice';
import { DateFormat, dayjs } from 'utils/date';

const EnrollmentDetails = () => {
  const { t } = useTranslation();
  const accountInfo = useSelector((state: AppState) =>
    selectAccount(state.loyaltyMerchantPortal)
  );

  let enrollmentDate;
  const enrolledAt = accountInfo?.loyaltyAccount?.enrolledAt;
  if (enrolledAt) {
    enrollmentDate = dayjs(enrolledAt).format(DateFormat.LL);
  }

  return (
    <section className="mb-6">
      <MarketRow className="bg-fill-50 px-3">
        <strong slot="label">{t('loyalty.enrollmentDetails.label')}</strong>
        {enrollmentDate && (
          <p slot="subtext">
            {t('loyalty.enrollmentDetails.subtext', {
              enrollmentDate,
            })}
          </p>
        )}
      </MarketRow>
    </section>
  );
};

export default EnrollmentDetails;
