import React from 'react';
import BaseModal, { ModalSize } from 'components/modals/BaseModal';
import { MarketButton, MarketButtonGroup, MarketFooter } from '@market/react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from 'store/modalSlice';
import { useDeleteCardMutation } from 'store/query';
import { openToast } from 'store/toastSlice';
import { useHistory } from 'react-router-dom';
import { buyerportalCdpClient } from 'services/tracking/cdp/clients/buyerportal';
import BuyerCard from 'routes/profile/models/BuyerCard';
import { AppState } from 'store';
import { selectPrimaryId } from 'store/buyerSlice';
import { IdentifierType } from 'routes/profile/models/Identifier';
import { actionRemovePaymentCardProperties } from 'services/tracking/cdp/events/paymentMethods';
import { useTranslation } from 'react-i18next';

export type ConfirmRemovePaymentCardModalProps = {
  paymentCard: BuyerCard;
};

const ConfirmRemovePaymentCardModal: React.FC<
  ConfirmRemovePaymentCardModalProps
> = ({ paymentCard }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const primaryCardId = useSelector((state: AppState) =>
    selectPrimaryId(state, IdentifierType.Card)
  );

  const { t } = useTranslation();

  const [removePaymentCard, removePaymentCardResult] = useDeleteCardMutation();

  const confirmRemovePaymentCard = async () => {
    await removePaymentCard(paymentCard.cardsApiId)
      .unwrap()
      .then(() => {
        dispatch(
          openToast({
            variant: 'success',
            message: t(
              'profile.paymentMethods.paymentCards.addPaymentCardModal.paymentCardRemoved'
            ),
          })
        );

        buyerportalCdpClient.track(
          'Remove Card',
          actionRemovePaymentCardProperties(
            paymentCard.brand,
            paymentCard.cardsApiId,
            paymentCard.cardsApiId === primaryCardId
          )
        );

        history.push('/paymentmethods');
        dispatch(closeModal());
      })
      .catch(() => {
        dispatch(
          openToast({
            variant: 'critical',
            message: t('common.somethingWentWrong.retryable.direct'),
          })
        );
      });
  };

  return (
    <BaseModal size={ModalSize.Small}>
      <h2 className="mt-0">
        {t(
          'profile.paymentMethods.paymentCards.confirmRemovePaymentCardModal.removeTitle'
        )}
      </h2>
      <p>
        {t(
          'profile.paymentMethods.paymentCards.confirmRemovePaymentCardModal.removeSubtitle'
        )}
      </p>
      <MarketFooter style={{ paddingBottom: 4 }}>
        <MarketButtonGroup alignment={'fill'}>
          <MarketButton
            rank={'primary'}
            variant={'destructive'}
            onClick={confirmRemovePaymentCard}
            data-testid={'confirm-remove-gift-card'}
            {...(removePaymentCardResult.isLoading && { disabled: true })}
          >
            {t('common.remove')}
          </MarketButton>
          <MarketButton
            rank={'secondary'}
            onClick={() => dispatch(closeModal())}
            {...(removePaymentCardResult.isLoading && { disabled: true })}
          >
            {t('common.cancel')}
          </MarketButton>
        </MarketButtonGroup>
      </MarketFooter>
    </BaseModal>
  );
};

export default ConfirmRemovePaymentCardModal;
