import React, { useState } from 'react';
import { MarketButton, MarketHeader } from '@market/react';
import { useRequestAddProfileIdentifierMutation } from 'store/query';
import useValidation from 'utils/custom-react-hooks/useValidation';
import {
  ContactInformationIdentifierType,
  IdentifierType,
} from 'routes/profile/models/Identifier';
import { isValidIdentifier } from 'utils/identifiers';
import IdentifierValueInput from 'components/IdentifierValueInput';
import { openToast } from 'store/toastSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

type RequestProps = {
  onRequestAdd: (
    identifierType: ContactInformationIdentifierType,
    identifierValue: string
  ) => void;
};

const Request: React.FC<RequestProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [identifierType, setIdentifierType] =
    useState<ContactInformationIdentifierType>(IdentifierType.Email);
  const [identifierValue, setIdentifierValue] = useState<string>('');

  const [isInvalid, resetValidation, validate] =
    useValidation(isValidIdentifier);

  const [requestAddProfileIdentifier, requestAddProfileIdentifierResult] =
    useRequestAddProfileIdentifierMutation();

  const requestAddIdentifier = async () => {
    if (!validate(identifierType, identifierValue)) {
      return;
    }

    await requestAddProfileIdentifier({
      identifierType,
      identifierValue,
    })
      .unwrap()
      .then(() => {
        props.onRequestAdd(identifierType, identifierValue);
      })
      .catch(() => {
        dispatch(
          openToast({
            variant: 'critical',
            message: t('common.somethingWentWrong.retryable.direct'),
          })
        );
      });
  };

  const isSaveButtonDisabled = requestAddProfileIdentifierResult.isLoading;

  return (
    <>
      <MarketHeader>
        <MarketButton
          rank={'primary'}
          slot={'actions'}
          {...(isSaveButtonDisabled && { disabled: true })}
          onClick={requestAddIdentifier}
        >
          {t('common.next')}
        </MarketButton>
      </MarketHeader>
      <h2 className={'heading-30 mt-6 mb-8'}>
        {t(
          'profile.account.contactInfo.addContactInfoModal.request.addContactInfo'
        )}
      </h2>
      <div>
        <IdentifierValueInput
          emailInputDataTestId={'email-input'}
          phoneInputDataTestId={'phone-input'}
          identifierType={identifierType}
          identifierValue={identifierValue}
          onEnterPressed={requestAddIdentifier}
          isInvalid={isInvalid}
          onIdentifierTypeChanged={(newIdentifierType) => {
            setIdentifierType(newIdentifierType);
            resetValidation();
          }}
          onIdentifierValueChanged={(newIdentifierValue) => {
            setIdentifierValue(newIdentifierValue);
            resetValidation();
          }}
          enableTypeSelection
        />
      </div>
    </>
  );
};

export default Request;
