import React from 'react';
import { useTranslation } from 'react-i18next';
import appStoreBadge from 'routes/cash-linking/assets/appStoreBadge.svg';
import playStoreBadge from 'routes/cash-linking/assets/playStoreBadge.png';
import { LogoIcon } from 'svgs';
import nonTranslatableStrings from 'utils/nonTranslatableStrings';

const CashAppSell: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <LogoIcon className={'mb-9'} fontSize={'large'} />
      <h2 className={'m-0 mb-4 heading-30'}>
        {t('cashenroll.cashAppSell.title', {
          appName: nonTranslatableStrings.cashApp,
        })}
      </h2>
      <p className={'m-0 mb-8 paragraph-30'}>
        {t('cashenroll.cashAppSell.subtitle', {
          appName: nonTranslatableStrings.cashApp,
        })}
      </p>
      <div
        className={
          'flex items-center justify-between h-[70px] md:justify-start'
        }
      >
        <a
          href={'https://apps.apple.com/us/app/cash-app/id711923939'}
          className={'h-full'}
        >
          <img
            src={appStoreBadge}
            alt={t('cashenroll.appStoreBadgeAlt')}
            className={'h-full box-border p-[11px] pl-0'}
          />
        </a>
        <a
          href={
            'https://play.google.com/store/apps/details?id=com.squareup.cash'
          }
          className={'h-full'}
        >
          <img
            src={playStoreBadge}
            alt={t('cashenroll.playStoreBadgeAlt')}
            className={'h-full'}
          />
        </a>
      </div>
    </div>
  );
};

export default CashAppSell;
