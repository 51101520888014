/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../../Fixes';
import Builder from '../../../../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      feature: {
        nested: {
          relay: {
            nested: {
              flags: {
                nested: {
                  message: {
                    nested: {
                      Status: {
                        values: {
                          UNKNOWN: 0,
                          SUCCESS: 1,
                          SDK_ERROR: 2,
                          INVALID_REQUEST: 3,
                          NOT_FOUND: 4,
                          WRONG_TYPE: 5,
                          SERVICE_NOT_READY: 7,
                          MISSING_USER_TOKEN: 8,
                          DUPLICATED_MULTI_CONTEXT: 9,
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.feature?.nested?.relay?.nested?.flags?.nested
    ?.message?.nested?.Status
) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE Status @ squareup/feature/relay/flags/message/status.proto at 8:1
 */
export const Status = $root.lookupEnum(
  'squareup.feature.relay.flags.message.Status'
).values;
