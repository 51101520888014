/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      passes: {
        nested: {
          model: {
            nested: {
              ProductType: {
                values: {
                  PASS_TYPE_DO_NOT_USE: 0,
                  LOYALTY: 1,
                  GIFT_CARD: 2,
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.passes?.nested?.model?.nested?.ProductType
) {
  $root.addJSON(jsonData);
}

/**
 * The type of products that can create a pass
 *
 * SOURCE ProductType @ squareup/passes/model/product.proto at 11:1
 */
export const ProductType = $root.lookupEnum(
  'squareup.passes.model.ProductType'
).values;
