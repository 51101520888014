import { MarketButton } from '@market/react';
import React, { Fragment } from 'react';
import Page from 'routes/profile/layout/Page';
import AlertI from 'svgs/market-icons/AlertI';
import { useTranslation } from 'react-i18next';

export type ModuleLoadFailedProps = {
  embedded?: boolean;
};

const ModuleLoadFailed: React.FC<ModuleLoadFailedProps> = ({ embedded }) => {
  const { t } = useTranslation();

  const MaybeWrapper = embedded ? Fragment : Page;
  return (
    <MaybeWrapper>
      <div className={'flex flex-col items-center justify-center h-full'}>
        <AlertI width={80} height={80} />
        <h3 className={'mt-8 mb-2'}>
          {t('common.somethingWentWrong.retryable.direct')}
        </h3>
        <div className={'mb-8'}>{t('common.checkConnection')}</div>
        <MarketButton onClick={() => window.location.reload()}>
          {t('common.tryAgain')}
        </MarketButton>
      </div>
    </MaybeWrapper>
  );
};

export default ModuleLoadFailed;
