/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import '../../omg/model/orders';
import '../common/data';
import '../../receipts/data';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      buyerportal: {
        nested: {
          orders: {
            nested: {
              OrderListItem: {
                fields: {
                  order: {
                    type: 'squareup.omg.model.Order',
                    id: 1,
                    rule: 'optional',
                  },
                  merchantInfo: {
                    type: 'squareup.buyerportal.common.MerchantInfo',
                    id: 2,
                    rule: 'optional',
                  },
                  receipts: {
                    type: 'squareup.receipts.data.Receipt',
                    id: 3,
                    rule: 'repeated',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.orders?.nested
    ?.OrderListItem
) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE OrderListItem @ squareup/buyerportal/orders/data.proto at 12:1
 */
export const OrderListItem = $root.lookupType(
  'squareup.buyerportal.orders.OrderListItem'
);
fixType(OrderListItem);
Builder.createAndAttachToType(OrderListItem);
