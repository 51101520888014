import BackButton from 'components/BackButton';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  MarketAccessory,
  MarketBanner,
  MarketButton,
  MarketDivider,
  MarketList,
  MarketRow,
} from '@market/react';
import Page from 'routes/profile/layout/Page';
import { formatConnectMoney } from 'utils/currency';
import { Money } from 'rpc/model/squareup/connect/v2/common/money';
import { PaymentAddress, ReceiptItem } from 'routes/profile/models/Payment';
import BrandedCardIcon from 'components/profile/BrandedCardIcon';
import { DateFormat, dayjs } from 'utils/date';
import Store from 'svgs/market-icons/Store';
import { useTranslation } from 'react-i18next';
import { Receipt as RpcReceipt } from 'rpc/model/squareup/receipts/data';
import { useListOrdersQuery } from 'store/query/api-extensions/orderBaseRpcs';
import ModuleLoading from 'routes/profile/common/loading/ModuleLoading';
import ModuleLoadFailed from 'routes/profile/common/errors/ModuleLoadFailed';
import { BASE_RECEIPT_URL } from 'routes/profile/purchase-history';

type ReceiptDetailProps = {
  paymentId: string;
};

const ReceiptDetails: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [imageFailedToLoad, setImageFailedToLoad] = useState<boolean>(false);

  const {
    data: payments,
    isLoading,
    isError: didLoadingFail,
  } = useListOrdersQuery();

  const { paymentId } = useParams<ReceiptDetailProps>();

  const formatItemsText = (itemsCount: number) => {
    if (itemsCount === 1) {
      return '1 item';
    } else {
      return `${itemsCount} items`;
    }
  };

  if (isLoading) {
    return <ModuleLoading />;
  }

  if (didLoadingFail) {
    return <ModuleLoadFailed />;
  }

  // we use the first paymentId as the identifier
  const payment = (payments || []).find(
    (payment) => payment.receipt.paymentIds[0] === paymentId
  );

  const formatMoney = (money?: Money) => {
    if (money) {
      return formatConnectMoney(money);
    } else {
      return formatConnectMoney(
        Money.create({ amount: 0, currency: payment?.totalMoney?.currency })
      );
    }
  };

  return (
    <Page>
      {/* Remove before GA */}
      <MarketBanner>
        Purchase History is under development and is exposed to Block employees
        only. Please reach out in{' '}
        <a href="https://square.enterprise.slack.com/archives/CJWA97E91">
          #square-profile
        </a>{' '}
        with questions or feedback.
      </MarketBanner>
      <div className={'flex items-center py-4'}>
        <BackButton onClick={() => history.push('/purchasehistory')} />
        <div className={'flex heading-20 w-full justify-center'}>
          {t('profile.purchaseHistory.receipt.title')}
        </div>
      </div>
      <MarketAccessory size={'image'} slot={'icon'}>
        {payment?.merchantInfo.imageUrl || imageFailedToLoad ? (
          <img
            onError={() => {
              setImageFailedToLoad(true);
            }}
            alt={payment?.merchantInfo.displayName}
            src={payment?.merchantInfo.imageUrl}
          />
        ) : (
          <Store />
        )}
      </MarketAccessory>
      <div className={'flex items-center py-4'}>
        <div className={'ml-4'}>
          <label className={'font-medium'}>
            {payment?.merchantInfo.displayName}
          </label>
          <div className={'paragraph-20 text-grey'}>
            {formatItemsText(payment?.totalItems || 0)} •{' '}
            {formatMoney(payment?.totalMoney)}
          </div>
          <div className={'paragraph-20 text-grey'}>
            {dayjs(payment?.createdAt).format(DateFormat.LL)}
          </div>
        </div>
        <div className={'ml-auto flex items-center'}>
          <MarketButton
            onClick={() => {
              window.open(
                `/merchantportal/${payment?.merchantInfo.id}`,
                '_blank'
              );
            }}
          >
            {t('profile.purchaseHistory.merchantPortal.link')}
          </MarketButton>
        </div>
      </div>
      <h2 className={'mb-4 heading-30'}>{formatMoney(payment?.totalMoney)}</h2>
      <MarketDivider className={'my-8'} />
      <div className={'flex grow items-center justify-between mb-4'}>
        <h3 className={'heading-20 my-0'}>
          {t('profile.purchaseHistory.receipt.summary')}
        </h3>
      </div>
      <MarketList role={'list'}>
        <MarketRow>
          {payment?.receipt.items.map((item: ReceiptItem) => (
            <div className={'flex items-center py-4'} key={item.name}>
              <div className={'ml-4'}>
                {item.name}
                <div
                  className={'paragraph-20 text-grey'}
                >{`${item.quantity} ${item.quantityUnit}`}</div>
              </div>
              <div className={'ml-auto flex items-center'}>
                {formatMoney(item.amount)}
              </div>
            </div>
          ))}
        </MarketRow>
        <MarketRow>
          <div className={'flex items-center pt-4'}>
            <div className={'ml-4'}>
              {t('profile.purchaseHistory.receipt.subtotal')}
            </div>
            <div className={'ml-auto flex items-center'}>
              {formatMoney(payment?.subtotal)}
            </div>
          </div>
          <div className={'flex items-center pb-4'}>
            <div className={'ml-4'}>
              {t('profile.purchaseHistory.receipt.tax')}
            </div>
            <div className={'ml-auto flex items-center'}>
              {formatMoney(payment?.receipt.taxAmount)}
            </div>
          </div>
        </MarketRow>
        <MarketRow>
          <div className={'flex items-center py-4'}>
            <div className={'ml-4'}>
              <label className={'font-medium'}>
                {t('profile.purchaseHistory.receipt.total')}
              </label>
            </div>
            <div className={'ml-auto flex items-center'}>
              <label className={'font-medium'}>
                {formatMoney(payment?.totalMoney)}
              </label>
            </div>
          </div>
        </MarketRow>
      </MarketList>
      <MarketDivider className={'my-8'} />
      <div className={'flex grow items-center justify-between mb-4'}>
        <h3 className={'heading-20 my-0'}>
          {t('profile.purchaseHistory.receipt.details')}
        </h3>
      </div>
      <MarketList role={'list'}>
        {payment?.receipt.billingAddress.name && (
          <MarketRow>
            <div className={'flex items-center py-4'}>
              <div className={'ml-4'}>
                <label className={'font-medium'}>
                  {t('profile.purchaseHistory.receipt.billingAddress')}
                </label>
                <div className={'paragraph-20 text-grey'}>
                  {payment.receipt.billingAddress.name}
                </div>
                <div className={'paragraph-20 text-grey'}>
                  {payment.receipt.billingAddress.address &&
                    PaymentAddress.formatAddressString(
                      payment.receipt.billingAddress.address
                    )}
                </div>
              </div>
            </div>
          </MarketRow>
        )}
        {payment?.receipt.shippingAddress.name && (
          <MarketRow>
            <div className={'flex items-center py-4'}>
              <div className={'ml-4'}>
                <label className={'font-medium'}>
                  {t('profile.purchaseHistory.receipt.shippingAddress')}
                </label>
                <div className={'paragraph-20 text-grey'}>
                  {payment.receipt.shippingAddress.name}
                </div>
                <div className={'paragraph-20 text-grey'}>
                  {payment.receipt.shippingAddress.address &&
                    PaymentAddress.formatAddressString(
                      payment.receipt.shippingAddress.address
                    )}
                </div>
              </div>
            </div>
          </MarketRow>
        )}
        {payment?.receipt?.shippingMethod?.length &&
          payment?.receipt?.shippingMethod?.length > 0 && (
            <MarketRow>
              <div className={'flex items-center py-4'}>
                <div className={'ml-4'}>
                  <label className={'font-medium'}>
                    {t('profile.purchaseHistory.receipt.shippingMethod')}
                  </label>
                  <div className={'paragraph-20 text-grey'}>
                    {payment?.receipt.shippingMethod}
                  </div>
                </div>
              </div>
            </MarketRow>
          )}
        <MarketRow>
          <div className={'flex items-center py-4'}>
            <div className={'ml-4'}>
              <label className={'font-medium'}>
                {t('profile.purchaseHistory.receipt.paymentMethod')}
              </label>
              <div className={'flex items-center'}>
                <MarketAccessory size={'image'} slot={'icon'}>
                  <BrandedCardIcon brand={payment?.receipt.paymentCard.brand} />
                </MarketAccessory>
                <div className={'ml-4'}>
                  <div className={'font-medium'}>
                    {payment?.receipt.paymentCard.displayName}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MarketRow>
        <MarketRow>
          <div className={'flex items-center py-4'}>
            <div className={'ml-4'}>
              <label className={'font-medium'}>
                {t('profile.purchaseHistory.receipt.receipts')}
              </label>
            </div>
          </div>
          {payment?.receipt.digitalReceipts.length === 0 &&
            payment?.receipt.paymentIds.map((paymentId: string) => (
              // if there are no receipts but we have paymentIds we can still pull the receipts
              <div className={'flex items-center py-4'} key={paymentId}>
                <div className={'ml-4'}>{paymentId}</div>
                <div className={'ml-auto flex items-center'}>
                  <MarketButton
                    onClick={() =>
                      window.open(`${BASE_RECEIPT_URL}/${paymentId}`, '_blank')
                    }
                  >
                    {t('profile.purchaseHistory.receipt.view')}
                  </MarketButton>
                </div>
              </div>
            ))}
          {payment?.receipt.digitalReceipts.map((receipt: RpcReceipt) => (
            <div
              className={'flex items-center py-4'}
              key={receipt.paymentToken}
            >
              <div className={'ml-4'}>
                {receipt?.emailAddress || receipt.phoneNumber}
                <div className={'paragraph-20 text-grey'}>
                  {receipt?.saleAt?.instantUsec &&
                    dayjs(receipt?.saleAt?.instantUsec / 1000).format(
                      DateFormat.lll
                    )}
                </div>
              </div>
              <div className={'ml-auto flex items-center'}>
                <MarketButton
                  onClick={() =>
                    window.open(
                      `${BASE_RECEIPT_URL}/${receipt.paymentToken}`,
                      '_blank'
                    )
                  }
                >
                  {t('profile.purchaseHistory.receipt.view')}
                </MarketButton>
              </div>
            </div>
          ))}
        </MarketRow>
      </MarketList>
    </Page>
  );
};

export default ReceiptDetails;
