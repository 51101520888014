/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      giftcard: {
        nested: {
          model: {
            nested: {
              EGiftThemeCategory: {
                fields: {
                  label: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  localizedName: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  countryCode: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  rank: {
                    type: 'int32',
                    id: 4,
                    rule: 'optional',
                    options: {
                      default: 0,
                    },
                  },
                  disabled: {
                    type: 'bool',
                    id: 5,
                    rule: 'optional',
                    options: {
                      default: false,
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.giftcard?.nested?.model?.nested
    ?.EGiftThemeCategory
) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE EGiftThemeCategory @ squareup/giftcard/model/egift_theme_category.proto at 9:1
 */
export const EGiftThemeCategory = $root.lookupType(
  'squareup.giftcard.model.EGiftThemeCategory'
);
fixType(EGiftThemeCategory, {
  label: { required: true },
  localizedName: { required: true },
  countryCode: { required: true },
});
Builder.createAndAttachToType(EGiftThemeCategory);
