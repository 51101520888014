/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import '../../customers/request';
import '../../connect/v2/resources/country';
import '../../connect/v2/common/money';
import '../../payments/external/bank_accounts';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      buyerportal: {
        nested: {
          onfile: {
            nested: {
              ListOnFileBankAccountsRequest: {
                fields: {
                  buyerAccessToken: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                },
              },
              ListOnFileBankAccountsResponse: {
                fields: {
                  bankAccounts: {
                    type: 'squareup.buyerportal.onfile.OnFileBankAccount',
                    id: 1,
                    rule: 'repeated',
                  },
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 2,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 3,
                    rule: 'repeated',
                  },
                },
              },
              OnFileBankAccount: {
                fields: {
                  bankId: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  accountNumberSuffix: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  country: {
                    type: 'squareup.connect.v2.resources.Country',
                    id: 3,
                    rule: 'optional',
                  },
                  currency: {
                    type: 'squareup.connect.v2.common.Currency',
                    id: 4,
                    rule: 'optional',
                  },
                  accountType: {
                    type: 'squareup.connect.v2.BankAccountType',
                    id: 5,
                    rule: 'optional',
                  },
                  holderName: {
                    type: 'string',
                    id: 6,
                    rule: 'optional',
                  },
                  accountStatus: {
                    type: 'squareup.connect.v2.BankAccountStatus',
                    id: 7,
                    rule: 'optional',
                  },
                  bankName: {
                    type: 'string',
                    id: 8,
                    rule: 'optional',
                  },
                },
              },
              UnlinkOnFileBankAccountRequest: {
                fields: {
                  buyerAccessToken: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  bankAccountId: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                },
              },
              UnlinkOnFileBankAccountResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                },
              },
              RetrieveOnFileMerchantRequest: {
                fields: {
                  buyerAccessToken: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                },
              },
              RetrieveOnFileMerchantResponse: {
                fields: {
                  merchantToken: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  merchantName: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  brandColor: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  profileImageUrl: {
                    type: 'string',
                    id: 4,
                    rule: 'optional',
                  },
                  phoneNumber: {
                    type: 'string',
                    id: 5,
                    rule: 'optional',
                  },
                  emailAddress: {
                    type: 'string',
                    id: 6,
                    rule: 'optional',
                  },
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 7,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 8,
                    rule: 'repeated',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.onfile?.nested
    ?.ListOnFileBankAccountsRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.onfile?.nested
    ?.ListOnFileBankAccountsResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.onfile?.nested
    ?.OnFileBankAccount &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.onfile?.nested
    ?.UnlinkOnFileBankAccountRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.onfile?.nested
    ?.UnlinkOnFileBankAccountResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.onfile?.nested
    ?.RetrieveOnFileMerchantRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.onfile?.nested
    ?.RetrieveOnFileMerchantResponse
) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE ListOnFileBankAccountsRequest @ squareup/buyerportal/onfile/data.proto at 14:1
 */
export const ListOnFileBankAccountsRequest = $root.lookupType(
  'squareup.buyerportal.onfile.ListOnFileBankAccountsRequest'
);
fixType(ListOnFileBankAccountsRequest, {
  buyerAccessToken: { notEmpty: true },
});
Builder.createAndAttachToType(ListOnFileBankAccountsRequest);
/**
 * SOURCE ListOnFileBankAccountsResponse @ squareup/buyerportal/onfile/data.proto at 18:1
 */
export const ListOnFileBankAccountsResponse = $root.lookupType(
  'squareup.buyerportal.onfile.ListOnFileBankAccountsResponse'
);
fixType(ListOnFileBankAccountsResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(ListOnFileBankAccountsResponse);
/**
 * SOURCE OnFileBankAccount @ squareup/buyerportal/onfile/data.proto at 26:1
 */
export const OnFileBankAccount = $root.lookupType(
  'squareup.buyerportal.onfile.OnFileBankAccount'
);
fixType(OnFileBankAccount, {
  bankId: { required: true },
  accountNumberSuffix: { required: true },
  country: { required: true },
  currency: { required: true },
  accountType: { required: true },
  holderName: { required: true },
  accountStatus: { required: true },
  bankName: { required: true },
});
Builder.createAndAttachToType(OnFileBankAccount);
/**
 * SOURCE UnlinkOnFileBankAccountRequest @ squareup/buyerportal/onfile/data.proto at 44:1
 */
export const UnlinkOnFileBankAccountRequest = $root.lookupType(
  'squareup.buyerportal.onfile.UnlinkOnFileBankAccountRequest'
);
fixType(UnlinkOnFileBankAccountRequest, {
  buyerAccessToken: { notEmpty: true },
  bankAccountId: { notEmpty: true },
});
Builder.createAndAttachToType(UnlinkOnFileBankAccountRequest);
/**
 * SOURCE UnlinkOnFileBankAccountResponse @ squareup/buyerportal/onfile/data.proto at 50:1
 */
export const UnlinkOnFileBankAccountResponse = $root.lookupType(
  'squareup.buyerportal.onfile.UnlinkOnFileBankAccountResponse'
);
fixType(UnlinkOnFileBankAccountResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(UnlinkOnFileBankAccountResponse);
/**
 * SOURCE RetrieveOnFileMerchantRequest @ squareup/buyerportal/onfile/data.proto at 56:1
 */
export const RetrieveOnFileMerchantRequest = $root.lookupType(
  'squareup.buyerportal.onfile.RetrieveOnFileMerchantRequest'
);
fixType(RetrieveOnFileMerchantRequest, {
  buyerAccessToken: { notEmpty: true },
});
Builder.createAndAttachToType(RetrieveOnFileMerchantRequest);
/**
 * SOURCE RetrieveOnFileMerchantResponse @ squareup/buyerportal/onfile/data.proto at 60:1
 */
export const RetrieveOnFileMerchantResponse = $root.lookupType(
  'squareup.buyerportal.onfile.RetrieveOnFileMerchantResponse'
);
fixType(RetrieveOnFileMerchantResponse, {
  merchantToken: { required: true },
  merchantName: { required: true },
  profileImageUrl: { required: true },
  phoneNumber: { required: true },
  emailAddress: { required: true },
  status: { required: true },
});
Builder.createAndAttachToType(RetrieveOnFileMerchantResponse);
