import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import phonePlaceholder from 'routes/cash-linking/assets/phonePlaceholder.png';
import { LogoIcon } from 'svgs';
import Es2Tracker from 'services/tracking/tracker';
import { cashNotOnMobileViewEvent } from 'services/tracking/events';
import nonTranslatableStrings from 'utils/nonTranslatableStrings';

const NotOnMobile: React.FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    Es2Tracker.track(cashNotOnMobileViewEvent());
  }, []);

  return (
    <div className={'flex justify-center px-[25px] py-[27px]'}>
      <div className={'max-w-full'}>
        <LogoIcon className={'mb-9'} fontSize={'large'} />
        <h2 className={'m-0 mb-4 heading-30'}>
          {t('cashenroll.notOnMobile.title')}
        </h2>
        <p className={'m-0 mb-8 paragraph-30'}>
          {t('cashenroll.notOnMobile.subtitle', {
            appName: nonTranslatableStrings.cashApp,
          })}
        </p>
        <div
          className={
            'flex items-center justify-center px-0 py-[47px] rounded-[32px] bg-[linear-gradient(0deg,#00000008,#00000008,#FFFFFF)]'
          }
        >
          <img
            className={'w-[50%]'}
            src={phonePlaceholder}
            alt={t('cashenroll.notOnMobile.phonePlaceholderAlt')}
          />
        </div>
      </div>
    </div>
  );
};

export default NotOnMobile;
