/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../Fixes';
import Builder from '../../../../Builder';
import '../common/money';
import './additional_recipient';
import './card';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      connect: {
        nested: {
          v2: {
            nested: {
              resources: {
                nested: {
                  Tender: {
                    fields: {
                      id: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      uid: {
                        type: 'string',
                        id: 15,
                        rule: 'optional',
                      },
                      locationId: {
                        type: 'string',
                        id: 3,
                        rule: 'optional',
                      },
                      transactionId: {
                        type: 'string',
                        id: 4,
                        rule: 'optional',
                      },
                      createdAt: {
                        type: 'string',
                        id: 5,
                        rule: 'optional',
                      },
                      note: {
                        type: 'string',
                        id: 6,
                        rule: 'optional',
                      },
                      amountMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 7,
                        rule: 'optional',
                      },
                      tipMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 14,
                        rule: 'optional',
                      },
                      processingFeeMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 8,
                        rule: 'optional',
                      },
                      customerId: {
                        type: 'string',
                        id: 9,
                        rule: 'optional',
                      },
                      type: {
                        type: 'squareup.connect.v2.resources.Tender.Type',
                        id: 10,
                        rule: 'optional',
                      },
                      cardDetails: {
                        type: 'squareup.connect.v2.resources.Tender.CardDetails',
                        id: 11,
                        rule: 'optional',
                      },
                      cashDetails: {
                        type: 'squareup.connect.v2.resources.Tender.CashDetails',
                        id: 12,
                        rule: 'optional',
                      },
                      bankAccountDetails: {
                        type: 'squareup.connect.v2.resources.Tender.BankAccountDetails',
                        id: 21,
                        rule: 'optional',
                      },
                      walletDetails: {
                        type: 'squareup.connect.v2.resources.Tender.DigitalWalletDetails',
                        id: 20,
                        rule: 'optional',
                      },
                      buyNowPayLaterDetails: {
                        type: 'squareup.connect.v2.resources.Tender.BuyNowPayLaterDetails',
                        id: 22,
                        rule: 'optional',
                      },
                      squareAccountDetails: {
                        type: 'squareup.connect.v2.resources.Tender.SquareAccountDetails',
                        id: 23,
                        rule: 'optional',
                      },
                      bankTransferDetails: {
                        type: 'squareup.connect.v2.resources.Tender.BankTransferDetails',
                        id: 24,
                        rule: 'optional',
                      },
                      additionalRecipients: {
                        type: 'squareup.connect.v2.resources.AdditionalRecipient',
                        id: 13,
                        rule: 'repeated',
                      },
                      source: {
                        type: 'squareup.connect.v2.resources.Tender.PaymentSource',
                        id: 16,
                        rule: 'optional',
                      },
                      riskEvaluation: {
                        type: 'squareup.connect.v2.resources.Tender.RiskEvaluation',
                        id: 17,
                        rule: 'optional',
                      },
                      paymentId: {
                        type: 'string',
                        id: 18,
                        rule: 'optional',
                      },
                    },
                    nested: {
                      Type: {
                        values: {
                          CARD: 1,
                          CASH: 2,
                          THIRD_PARTY_CARD: 3,
                          SQUARE_GIFT_CARD: 4,
                          NO_SALE: 5,
                          CHECK: 6,
                          MERCHANT_GIFT_CARD: 7,
                          THIRD_PARTY_E_MONEY: 8,
                          BANK_ACCOUNT: 9,
                          WALLET: 10,
                          BUY_NOW_PAY_LATER: 11,
                          SQUARE_ACCOUNT: 12,
                          BANK_TRANSFER: 13,
                          OTHER: 0,
                        },
                      },
                      CardDetails: {
                        fields: {
                          status: {
                            type: 'squareup.connect.v2.resources.Tender.CardDetails.Status',
                            id: 1,
                            rule: 'optional',
                          },
                          card: {
                            type: 'squareup.connect.v2.resources.Card',
                            id: 2,
                            rule: 'optional',
                          },
                          entryMethod: {
                            type: 'squareup.connect.v2.resources.Tender.CardDetails.EntryMethod',
                            id: 3,
                            rule: 'optional',
                          },
                        },
                        nested: {
                          Status: {
                            values: {
                              AUTHORIZED: 1,
                              CAPTURED: 2,
                              VOIDED: 3,
                              FAILED: 4,
                            },
                          },
                          EntryMethod: {
                            values: {
                              SWIPED: 0,
                              KEYED: 1,
                              EMV: 2,
                              ON_FILE: 3,
                              CONTACTLESS: 4,
                            },
                          },
                        },
                      },
                      CashDetails: {
                        fields: {
                          buyerTenderedMoney: {
                            type: 'squareup.connect.v2.common.Money',
                            id: 1,
                            rule: 'optional',
                          },
                          changeBackMoney: {
                            type: 'squareup.connect.v2.common.Money',
                            id: 2,
                            rule: 'optional',
                          },
                        },
                      },
                      BankAccountDetails: {
                        fields: {
                          status: {
                            type: 'squareup.connect.v2.resources.Tender.BankAccountDetails.Status',
                            id: 1,
                            rule: 'optional',
                          },
                        },
                        nested: {
                          Status: {
                            values: {
                              PENDING: 1,
                              COMPLETED: 2,
                              FAILED: 3,
                            },
                          },
                        },
                      },
                      DigitalWalletDetails: {
                        fields: {
                          status: {
                            type: 'squareup.connect.v2.resources.Tender.DigitalWalletDetails.Status',
                            id: 1,
                            rule: 'optional',
                          },
                          digitalWalletBrand: {
                            type: 'squareup.connect.v2.resources.Tender.DigitalWalletDetails.Brand',
                            id: 2,
                            rule: 'optional',
                          },
                          buyerFullName: {
                            type: 'string',
                            id: 3,
                            rule: 'optional',
                          },
                        },
                        nested: {
                          Status: {
                            values: {
                              AUTHORIZED: 1,
                              CAPTURED: 2,
                              VOIDED: 3,
                              FAILED: 4,
                            },
                          },
                          Brand: {
                            values: {
                              OTHER_BRAND: 0,
                              CASH_APP: 1,
                              ALIPAY: 2,
                              PAYPAY: 3,
                              RAKUTEN_PAY: 4,
                              AU_PAY: 5,
                              D_BARAI: 6,
                              MERPAY: 7,
                              WECHAT_PAY: 8,
                            },
                          },
                        },
                      },
                      BuyNowPayLaterDetails: {
                        fields: {
                          buyNowPayLaterBrand: {
                            type: 'squareup.connect.v2.resources.Tender.BuyNowPayLaterDetails.Brand',
                            id: 1,
                            rule: 'optional',
                          },
                          status: {
                            type: 'squareup.connect.v2.resources.Tender.BuyNowPayLaterDetails.Status',
                            id: 2,
                            rule: 'optional',
                          },
                        },
                        nested: {
                          Status: {
                            values: {
                              AUTHORIZED: 1,
                              CAPTURED: 2,
                              VOIDED: 3,
                              FAILED: 4,
                            },
                          },
                          Brand: {
                            values: {
                              OTHER_BRAND: 0,
                              AFTERPAY: 1,
                            },
                          },
                        },
                      },
                      SquareAccountDetails: {
                        fields: {
                          status: {
                            type: 'squareup.connect.v2.resources.Tender.SquareAccountDetails.Status',
                            id: 1,
                            rule: 'optional',
                          },
                        },
                        nested: {
                          Status: {
                            values: {
                              AUTHORIZED: 1,
                              CAPTURED: 2,
                              VOIDED: 3,
                              FAILED: 4,
                            },
                          },
                        },
                      },
                      BankTransferDetails: {
                        fields: {
                          status: {
                            type: 'squareup.connect.v2.resources.Tender.BankTransferDetails.Status',
                            id: 1,
                            rule: 'optional',
                          },
                        },
                        nested: {
                          Status: {
                            values: {
                              PENDING: 1,
                              COMPLETED: 2,
                              FAILED: 3,
                            },
                          },
                        },
                      },
                      PaymentSource: {
                        fields: {
                          applicationId: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                          applicationName: {
                            type: 'string',
                            id: 2,
                            rule: 'optional',
                          },
                        },
                      },
                      RiskEvaluation: {
                        fields: {
                          level: {
                            type: 'squareup.connect.v2.resources.Tender.RiskEvaluation.Level',
                            id: 1,
                            rule: 'optional',
                          },
                          numericScore: {
                            type: 'double',
                            id: 3,
                            rule: 'optional',
                          },
                          reasons: {
                            type: 'string',
                            id: 4,
                            rule: 'repeated',
                          },
                          status: {
                            type: 'squareup.connect.v2.resources.Tender.RiskEvaluation.Status',
                            id: 2,
                            rule: 'optional',
                          },
                        },
                        nested: {
                          Level: {
                            values: {
                              UNKNOWN_LEVEL: 0,
                              NORMAL: 1,
                              MODERATE: 2,
                              HIGH: 3,
                            },
                          },
                          Status: {
                            values: {
                              UNKNOWN_STATUS: 0,
                              NOT_ELIGIBLE: 1,
                              PENDING: 2,
                              ACTIVE: 3,
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources
    ?.nested?.Tender &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources
    ?.nested?.Tender?.nested?.Type &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources
    ?.nested?.Tender?.nested?.CardDetails &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources
    ?.nested?.Tender?.nested?.CardDetails?.nested?.Status &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources
    ?.nested?.Tender?.nested?.CardDetails?.nested?.EntryMethod &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources
    ?.nested?.Tender?.nested?.CashDetails &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources
    ?.nested?.Tender?.nested?.BankAccountDetails &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources
    ?.nested?.Tender?.nested?.BankAccountDetails?.nested?.Status &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources
    ?.nested?.Tender?.nested?.DigitalWalletDetails &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources
    ?.nested?.Tender?.nested?.DigitalWalletDetails?.nested?.Status &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources
    ?.nested?.Tender?.nested?.DigitalWalletDetails?.nested?.Brand &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources
    ?.nested?.Tender?.nested?.BuyNowPayLaterDetails &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources
    ?.nested?.Tender?.nested?.BuyNowPayLaterDetails?.nested?.Status &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources
    ?.nested?.Tender?.nested?.BuyNowPayLaterDetails?.nested?.Brand &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources
    ?.nested?.Tender?.nested?.SquareAccountDetails &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources
    ?.nested?.Tender?.nested?.SquareAccountDetails?.nested?.Status &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources
    ?.nested?.Tender?.nested?.BankTransferDetails &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources
    ?.nested?.Tender?.nested?.BankTransferDetails?.nested?.Status &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources
    ?.nested?.Tender?.nested?.PaymentSource &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources
    ?.nested?.Tender?.nested?.RiskEvaluation &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources
    ?.nested?.Tender?.nested?.RiskEvaluation?.nested?.Level &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources
    ?.nested?.Tender?.nested?.RiskEvaluation?.nested?.Status
) {
  $root.addJSON(jsonData);
}

/**
 * @desc Represents a tender (i.e., a method of payment) used in a Square transaction.
 *
 * SOURCE Tender @ squareup/connect/v2/resources/tender.proto at 24:1
 */
export const Tender = $root.lookupType('squareup.connect.v2.resources.Tender');
fixType(Tender, {
  type: { required: true },
});
Builder.createAndAttachToType(Tender);
/**
 * @desc Indicates a tender's type.
 *
 * SOURCE Type @ squareup/connect/v2/resources/tender.proto at 85:3
 */
Tender.Type = $root.lookupEnum(
  'squareup.connect.v2.resources.Tender.Type'
).values;
/**
 * @desc Represents additional details of a tender with `type` `CARD` or `SQUARE_GIFT_CARD`
 *
 * SOURCE CardDetails @ squareup/connect/v2/resources/tender.proto at 153:3
 */
Tender.CardDetails = $root.lookupType(
  'squareup.connect.v2.resources.Tender.CardDetails'
);
fixType(Tender.CardDetails);
Builder.createAndAttachToType(Tender.CardDetails);
/**
 * --
 * @desc Indicates the card transaction's current status.
 * --
 *
 * SOURCE Status @ squareup/connect/v2/resources/tender.proto at 158:5
 */
Tender.CardDetails.Status = $root.lookupEnum(
  'squareup.connect.v2.resources.Tender.CardDetails.Status'
).values;
/**
 * @desc Indicates the method used to enter the card's details.
 *
 * SOURCE EntryMethod @ squareup/connect/v2/resources/tender.proto at 184:5
 */
Tender.CardDetails.EntryMethod = $root.lookupEnum(
  'squareup.connect.v2.resources.Tender.CardDetails.EntryMethod'
).values;
/**
 * @desc Represents the details of a tender with `type` `CASH`.
 *
 * SOURCE CashDetails @ squareup/connect/v2/resources/tender.proto at 218:3
 */
Tender.CashDetails = $root.lookupType(
  'squareup.connect.v2.resources.Tender.CashDetails'
);
fixType(Tender.CashDetails);
Builder.createAndAttachToType(Tender.CashDetails);
/**
 * --
 * @desc Represents the details of a tender with `type` `BANK_ACCOUNT`.
 *
 * See [BankAccountPaymentDetails](entity:BankAccountPaymentDetails)
 * for more exposed details of a bank account payment.
 * --
 *
 * SOURCE BankAccountDetails @ squareup/connect/v2/resources/tender.proto at 238:3
 */
Tender.BankAccountDetails = $root.lookupType(
  'squareup.connect.v2.resources.Tender.BankAccountDetails'
);
fixType(Tender.BankAccountDetails);
Builder.createAndAttachToType(Tender.BankAccountDetails);
/**
 * @desc Indicates the bank account payment's current status.
 *
 * SOURCE Status @ squareup/connect/v2/resources/tender.proto at 240:5
 */
Tender.BankAccountDetails.Status = $root.lookupEnum(
  'squareup.connect.v2.resources.Tender.BankAccountDetails.Status'
).values;
/**
 * @desc Represents the details of a tender with `type` `WALLET`.
 *
 * SOURCE DigitalWalletDetails @ squareup/connect/v2/resources/tender.proto at 269:3
 */
Tender.DigitalWalletDetails = $root.lookupType(
  'squareup.connect.v2.resources.Tender.DigitalWalletDetails'
);
fixType(Tender.DigitalWalletDetails);
Builder.createAndAttachToType(Tender.DigitalWalletDetails);
/**
 * --
 * @desc Indicates the digital wallet payment's current status.
 * --
 *
 * SOURCE Status @ squareup/connect/v2/resources/tender.proto at 274:5
 */
Tender.DigitalWalletDetails.Status = $root.lookupEnum(
  'squareup.connect.v2.resources.Tender.DigitalWalletDetails.Status'
).values;
/**
 * SOURCE Brand @ squareup/connect/v2/resources/tender.proto at 296:5
 */
Tender.DigitalWalletDetails.Brand = $root.lookupEnum(
  'squareup.connect.v2.resources.Tender.DigitalWalletDetails.Brand'
).values;
/**
 * @desc Represents the details of a tender with `type` `BUY_NOW_PAY_LATER`.
 *
 * SOURCE BuyNowPayLaterDetails @ squareup/connect/v2/resources/tender.proto at 340:3
 */
Tender.BuyNowPayLaterDetails = $root.lookupType(
  'squareup.connect.v2.resources.Tender.BuyNowPayLaterDetails'
);
fixType(Tender.BuyNowPayLaterDetails);
Builder.createAndAttachToType(Tender.BuyNowPayLaterDetails);
/**
 * SOURCE Status @ squareup/connect/v2/resources/tender.proto at 342:5
 */
Tender.BuyNowPayLaterDetails.Status = $root.lookupEnum(
  'squareup.connect.v2.resources.Tender.BuyNowPayLaterDetails.Status'
).values;
/**
 * SOURCE Brand @ squareup/connect/v2/resources/tender.proto at 357:5
 */
Tender.BuyNowPayLaterDetails.Brand = $root.lookupEnum(
  'squareup.connect.v2.resources.Tender.BuyNowPayLaterDetails.Brand'
).values;
/**
 * @desc Represents the details of a tender with `type` `SQUARE_ACCOUNT`.
 *
 * SOURCE SquareAccountDetails @ squareup/connect/v2/resources/tender.proto at 386:3
 */
Tender.SquareAccountDetails = $root.lookupType(
  'squareup.connect.v2.resources.Tender.SquareAccountDetails'
);
fixType(Tender.SquareAccountDetails);
Builder.createAndAttachToType(Tender.SquareAccountDetails);
/**
 * SOURCE Status @ squareup/connect/v2/resources/tender.proto at 388:5
 */
Tender.SquareAccountDetails.Status = $root.lookupEnum(
  'squareup.connect.v2.resources.Tender.SquareAccountDetails.Status'
).values;
/**
 * @desc Represents the details of a tender with `type` `BANK_TRANSFER`.
 *
 * SOURCE BankTransferDetails @ squareup/connect/v2/resources/tender.proto at 422:3
 */
Tender.BankTransferDetails = $root.lookupType(
  'squareup.connect.v2.resources.Tender.BankTransferDetails'
);
fixType(Tender.BankTransferDetails);
Builder.createAndAttachToType(Tender.BankTransferDetails);
/**
 * @desc Indicates the bank transfer payment's current status.
 *
 * SOURCE Status @ squareup/connect/v2/resources/tender.proto at 425:5
 */
Tender.BankTransferDetails.Status = $root.lookupEnum(
  'squareup.connect.v2.resources.Tender.BankTransferDetails.Status'
).values;
/**
 * @desc Stores additional information on what 3rd party app recorded the Tender.
 *
 * SOURCE PaymentSource @ squareup/connect/v2/resources/tender.proto at 461:3
 */
Tender.PaymentSource = $root.lookupType(
  'squareup.connect.v2.resources.Tender.PaymentSource'
);
fixType(Tender.PaymentSource);
Builder.createAndAttachToType(Tender.PaymentSource);
/**
 * @desc Represents fraud risk information for the associated tender.
 *
 * SOURCE RiskEvaluation @ squareup/connect/v2/resources/tender.proto at 480:3
 */
Tender.RiskEvaluation = $root.lookupType(
  'squareup.connect.v2.resources.Tender.RiskEvaluation'
);
fixType(Tender.RiskEvaluation);
Builder.createAndAttachToType(Tender.RiskEvaluation);
/**
 * @desc Indicates the level of risk associated with a tender.
 *
 * SOURCE Level @ squareup/connect/v2/resources/tender.proto at 484:5
 */
Tender.RiskEvaluation.Level = $root.lookupEnum(
  'squareup.connect.v2.resources.Tender.RiskEvaluation.Level'
).values;
/**
 * @desc Indicates the current status of the associated tender in Square's risk evaluation process.
 *
 * SOURCE Status @ squareup/connect/v2/resources/tender.proto at 504:5
 */
Tender.RiskEvaluation.Status = $root.lookupEnum(
  'squareup.connect.v2.resources.Tender.RiskEvaluation.Status'
).values;
