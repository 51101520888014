import React, { useEffect } from 'react';
import { MarketButton } from '@market/react';
import Es2Tracker from 'services/tracking/tracker';
import { signInIdentifierClaimedViewEvent } from 'services/tracking/events/signIn';
import { SignInIdentifier, SignInIdentifierType } from './types';
import { useTranslation } from 'react-i18next';
import { IdentifierType } from 'routes/profile/models/Identifier';
import nonTranslatableStrings from 'utils/nonTranslatableStrings';

interface IdentifierClaimedErrorProps {
  claimedIdentifier: SignInIdentifier;
  personToken: string | undefined;
  onForward: () => void;
  // Will be invoked only if personToken is present
  onForwardSecondary: (claimedIdentifier: SignInIdentifier) => void;
}

const IdentifierClaimedError: React.FC<IdentifierClaimedErrorProps> = (
  props
) => {
  const { t } = useTranslation();

  useEffect(() => {
    Es2Tracker.track(signInIdentifierClaimedViewEvent());
  });

  const accountStateToTranslations = {
    existingAccount: {
      header: (claimedIdentifierValue: string): string => {
        return t('onboarding.identifierClaimed.existingAccount.header', {
          claimedIdentifierValue,
        });
      },
      instructions: (claimedIdentifierType: SignInIdentifierType): string => {
        return t(
          `onboarding.identifierClaimed.existingAccount.instructions.${
            claimedIdentifierType === IdentifierType.Email ? 'email' : 'phone'
          }`
        );
      },
      button: (claimedIdentifierType: SignInIdentifierType): string => {
        return t(
          `onboarding.identifierClaimed.existingAccount.buttonText.${
            claimedIdentifierType === IdentifierType.Email ? 'email' : 'phone'
          }`
        );
      },
      secondaryButton: (): string =>
        t('onboarding.identifierClaimed.existingAccount.secondaryButtonText'),
    },
    newAccount: {
      header: (claimedIdentifierValue: string): string => {
        return t('onboarding.identifierClaimed.newAccount.header', {
          claimedIdentifierValue,
          SQUARE: nonTranslatableStrings.square,
        });
      },
      instructions: (claimedIdentifierType: SignInIdentifierType): string => {
        return t(
          `onboarding.identifierClaimed.newAccount.instructions.${
            claimedIdentifierType === IdentifierType.Email ? 'email' : 'phone'
          }`
        );
      },
      button: () => {
        return t('onboarding.identifierClaimed.newAccount.buttonText');
      },
    },
  };

  const translations = props.personToken
    ? accountStateToTranslations.existingAccount
    : accountStateToTranslations.newAccount;

  return (
    <>
      <h2 className={'mt-7 mb-4 heading-30'}>
        {translations.header(props.claimedIdentifier.value)}
      </h2>
      <p className={'m-0 mb-4 paragraph-30'}>
        {translations.instructions(props.claimedIdentifier.type)}
      </p>

      <MarketButton
        rank={'secondary'}
        onClick={props.onForward}
        className="mb-2"
      >
        {translations.button(props.claimedIdentifier.type)}
      </MarketButton>
      {props.personToken && (
        <MarketButton
          rank="tertiary"
          onClick={() => props.onForwardSecondary(props.claimedIdentifier)}
        >
          {translations.secondaryButton()}
        </MarketButton>
      )}
    </>
  );
};

export default IdentifierClaimedError;
