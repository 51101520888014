/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      buyerportal: {
        nested: {
          common: {
            nested: {
              CardInfo: {
                fields: {
                  id: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  brand: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  displayName: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  panSequenceNumbers: {
                    type: 'string',
                    id: 4,
                    rule: 'repeated',
                  },
                },
              },
              MerchantInfo: {
                fields: {
                  id: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  displayName: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  imageUrl: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  businessType: {
                    type: 'string',
                    id: 4,
                    rule: 'optional',
                  },
                },
              },
              VerificationCredential: {
                fields: {
                  phoneNumber: {
                    type: 'string',
                    id: 1,
                  },
                  phoneNumberId: {
                    type: 'string',
                    id: 2,
                  },
                  email: {
                    type: 'string',
                    id: 3,
                  },
                  emailId: {
                    type: 'string',
                    id: 4,
                  },
                },
                nested: {
                  credential: {
                    oneof: ['phoneNumber', 'phoneNumberId', 'email', 'emailId'],
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.common?.nested
    ?.CardInfo &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.common?.nested
    ?.MerchantInfo &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.common?.nested
    ?.VerificationCredential
) {
  $root.addJSON(jsonData);
}

/**
 * Common data protos for Buyer Portal Service RPC Request-Responses
 * Information for a payment card.
 *
 * SOURCE CardInfo @ squareup/buyerportal/common/data.proto at 16:1
 */
export const CardInfo = $root.lookupType(
  'squareup.buyerportal.common.CardInfo'
);
fixType(CardInfo, {
  id: { required: true },
  brand: { required: true },
  displayName: { required: true },
});
Builder.createAndAttachToType(CardInfo);
/**
 * Information for a merchant.
 *
 * SOURCE MerchantInfo @ squareup/buyerportal/common/data.proto at 32:1
 */
export const MerchantInfo = $root.lookupType(
  'squareup.buyerportal.common.MerchantInfo'
);
fixType(MerchantInfo, {
  id: { required: true },
  displayName: { required: true },
  imageUrl: { required: true },
});
Builder.createAndAttachToType(MerchantInfo);
/**
 * Represents an identifier used for verification.
 *
 * SOURCE VerificationCredential @ squareup/buyerportal/common/data.proto at 49:1
 */
export const VerificationCredential = $root.lookupType(
  'squareup.buyerportal.common.VerificationCredential'
);
fixType(VerificationCredential);
Builder.createAndAttachToType(VerificationCredential);
