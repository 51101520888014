/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      governance: {
        nested: {
          GovernanceDomain: {
            values: {
              DO_NOT_USE: 0,
              BLOCK: 1,
              BLOCK_CASH: 2,
              BLOCK_CASH_AFTERPAY_CONSUMER: 3,
              BLOCK_CASH_GLOBAL: 4,
              BLOCK_CASH_INVESTING: 5,
              BLOCK_CASH_TAXES: 6,
              BLOCK_CASH_VERSE: 7,
              BLOCK_SQUARE: 8,
              BLOCK_SQUARE_AFTERPAY_MERCHANT: 9,
              BLOCK_SQUARE_CREW: 10,
              BLOCK_SFS: 11,
              BLOCK_TIDAL: 12,
              BLOCK_TBD: 13,
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.governance?.nested?.GovernanceDomain) {
  $root.addJSON(jsonData);
}

/**
 * A Governance Domain refers to a Block product with unique regulatory requirements.
 *
 * SOURCE GovernanceDomain @ squareup/common/governance.proto at 76:1
 */
export const GovernanceDomain = $root.lookupEnum(
  'squareup.governance.GovernanceDomain'
).values;
