/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../Fixes';
import Builder from '../../../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      connect: {
        nested: {
          v2: {
            nested: {
              common: {
                nested: {
                  TaxCategory: {
                    values: {
                      TAX_CATEGORY_DO_NOT_USE: 0,
                      GENERAL_TANGIBLE_GOOD: 1,
                      NON_TAXABLE: 2,
                      SHIPPING_FEE: 3,
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.common?.nested
    ?.TaxCategory
) {
  $root.addJSON(jsonData);
}

/**
 * @desc Represents the line item tax category.
 *
 * SOURCE TaxCategory @ squareup/connect/v2/common/tax_category.proto at 16:1
 */
export const TaxCategory = $root.lookupEnum(
  'squareup.connect.v2.common.TaxCategory'
).values;
