import React from 'react';
import { useTranslation } from 'react-i18next';
import { LogoIcon } from 'svgs';

type QRCodeProps = {
  imageUrl: string;
};

const QRCode: React.FC<QRCodeProps> = (props) => {
  const { t } = useTranslation();

  return (
    <div
      className={
        'flex w-full h-[calc(100vh-112px)] justify-center items-center'
      }
    >
      <div className={'max-w-[700px] px-[25px] box-border'}>
        <LogoIcon className={'pb-[40px]'} fontSize={'large'} />
        <div className={'flex justify-center items-top'}>
          <div>
            <h2 className={'mt-0 mb-4 heading-30'}>
              {t('cashcouponbeta.interstitial.qrCode.title')}
            </h2>
            <p className={'m-0 paragraph-30'}>
              {t('cashcouponbeta.interstitial.qrCode.subtitle')}
            </p>
          </div>
          <img
            className={'w-[240px] h-[240px]'}
            src={props.imageUrl}
            alt={t('cashcouponbeta.interstitial.qrCode.altText')}
          />
        </div>
      </div>
    </div>
  );
};

export default QRCode;
