import { MerchantLocation } from 'rpc/model/squareup/buyerportal/merchantportal/data';
import { Country } from 'rpc/model/squareup/common/countries';
import { GlobalAddress, Phone } from 'rpc/model/squareup/common/location';
import { formatMultilineAddress } from 'utils/globalAddress';

// GlobalAddress
const MOCK_SF_ADDRESS = new GlobalAddress.Builder()
  .addressLine1('910 Montgomery St, #105')
  .locality('San Francisco')
  .administrativeDistrictLevel1('CA')
  .countryCode(Country.US)
  .postalCode('94133')
  .build();
const MOCK_NYC_ADDRESS = new GlobalAddress.Builder()
  .addressLine1('257 Park Ave South')
  .locality('New York')
  .administrativeDistrictLevel1('NY')
  .postalCode('10010')
  .countryCode(Country.US)
  .build();
const MOCK_CA_ADDRESS = new GlobalAddress.Builder()
  .addressLine1('Eaton Centre')
  .addressLine2('220 Yonge St')
  .addressLine3('Unit #B20')
  .locality('Toronto')
  .administrativeDistrictLevel1('ON')
  .countryCode(Country.CA)
  .postalCode('M5B2H1')
  .build();
const MOCK_JP_ADDRESS = new GlobalAddress.Builder()
  .addressLine1('GINZA SIX B2F, 6 Chome-10-1, Ginza')
  .locality('Tokyo')
  .sublocality('Chuo City')
  .countryCode(Country.JP)
  .postalCode('〒104-0061')
  .build();

// phone numbers
const US_PHONE_NUMBER = new Phone.Builder().number('2124831839').build();
const CA_PHONE_NUMBER = new Phone.Builder().number('4164831839').build();
const JP_PHONE_NUMBER = new Phone.Builder().number('348331839').build();

// emails
const US_EMAIL = 'nyc@lepetitchien.com';
const JP_EMAIL = 'jp@lepetitchien.com';

// Merchant Locations
const MOCK_SF_LOCATION = new MerchantLocation.Builder()
  .locationId('sf')
  .address(MOCK_SF_ADDRESS)
  .phone(US_PHONE_NUMBER)
  .email(US_EMAIL)
  .build();
const MOCK_NYC_LOCATION = new MerchantLocation.Builder()
  .locationId('nyc')
  .address(MOCK_NYC_ADDRESS)
  .phone(US_PHONE_NUMBER)
  .email(US_EMAIL)
  .build();
const MOCK_CA_LOCATION = new MerchantLocation.Builder()
  .locationId('ca')
  .address(MOCK_CA_ADDRESS)
  .phone(CA_PHONE_NUMBER)
  .email(US_EMAIL)
  .build();
const MOCK_JP_LOCATION = new MerchantLocation.Builder()
  .locationId('jp')
  .address(MOCK_JP_ADDRESS)
  .phone(JP_PHONE_NUMBER)
  .email(JP_EMAIL)
  .build();

export const MOCK_LOCATIONS = {
  SF: MOCK_SF_LOCATION,
  NYC: MOCK_NYC_LOCATION,
  CA: MOCK_CA_LOCATION,
  JP: MOCK_JP_LOCATION,
};

// merchant data
const MERCHANT_DATA_US = {
  locationId: MOCK_SF_LOCATION.locationId,
  formattedAddress: formatMultilineAddress(MOCK_SF_ADDRESS),
  address: MOCK_SF_ADDRESS,
  phone: US_PHONE_NUMBER,
  email: US_EMAIL,
};
const MERCHANT_DATA_US_NO_ADDRESS = {
  locationId: MOCK_SF_LOCATION.locationId,
  formattedAddress: undefined,
  address: undefined,
  email: US_EMAIL,
  phone: US_PHONE_NUMBER,
};
const MERCHANT_DATA_US_NO_PHONE = {
  locationId: MOCK_SF_LOCATION.locationId,
  formattedAddress: formatMultilineAddress(MOCK_SF_ADDRESS),
  address: MOCK_SF_ADDRESS,
  email: US_EMAIL,
  phone: undefined,
};
const MERCHANT_DATA_US_NO_EMAIL = {
  locationId: MOCK_SF_LOCATION.locationId,
  formattedAddress: formatMultilineAddress(MOCK_SF_ADDRESS),
  address: MOCK_SF_ADDRESS,
  email: undefined,
  phone: US_PHONE_NUMBER,
};
const MERCHANT_DATA_JP = {
  locationId: MOCK_JP_LOCATION.locationId,
  formattedAddress: formatMultilineAddress(MOCK_JP_ADDRESS),
  address: MOCK_JP_ADDRESS,
  email: JP_EMAIL,
  phone: JP_PHONE_NUMBER,
};

export const MOCK_MERCHANT_DATA = {
  US: MERCHANT_DATA_US,
  US_NO_ADDRESS: MERCHANT_DATA_US_NO_ADDRESS,
  US_NO_PHONE: MERCHANT_DATA_US_NO_PHONE,
  US_NO_EMAIL: MERCHANT_DATA_US_NO_EMAIL,
  JP: MERCHANT_DATA_JP,
};
