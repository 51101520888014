import SqLoading from 'components/SqLoading';
import React from 'react';

const LoadingUnlinkReceipt: React.FC = () => {
  return (
    <div
      className={
        'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      }
    >
      <SqLoading />
    </div>
  );
};

export default LoadingUnlinkReceipt;
