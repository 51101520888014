/*
 * This is to be used whenever you need to display a non-translatable string.
 * This ensures that translators do not mistakenly translate a string that should not be translated.
 */
const nonTranslatableStrings = {
  cashApp: 'Cash App',
  square: 'Square',
  squarePay: 'Square Pay',
  squareProfile: 'Square Profile',
  cardBrands: {
    visa: 'Visa',
    mastercard: 'MasterCard',
    americanExpress: 'American Express',
    discover: 'Discover',
    jcb: 'JCB',
    chinaUnionPay: 'China UnionPay',
    squareGiftCard: 'Square Gift Card',
    squareCapitalCard: 'Square Capital Card',
    interac: 'Interac',
    eftPos: 'EFT POS',
    feliCa: 'FeliCa',
    ebt: 'EBT',
  },
};

export default nonTranslatableStrings;
