/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import '../../customers/request';
import './common';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      buyerportal: {
        nested: {
          profile: {
            nested: {
              RetrieveSuggestedProfileIdentifiersRequest: {
                fields: {},
              },
              RetrieveSuggestedProfileIdentifiersResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                  identifiers: {
                    type: 'squareup.buyerportal.profile.Identifier',
                    id: 3,
                    rule: 'repeated',
                  },
                },
              },
              UnlinkSuggestedProfileIdentifierRequest: {
                fields: {
                  identifierId: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  identifierType: {
                    type: 'squareup.buyerportal.profile.Identifier.Type',
                    id: 2,
                    rule: 'optional',
                  },
                },
              },
              UnlinkSuggestedProfileIdentifierResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.profile?.nested
    ?.RetrieveSuggestedProfileIdentifiersRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.profile?.nested
    ?.RetrieveSuggestedProfileIdentifiersResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.profile?.nested
    ?.UnlinkSuggestedProfileIdentifierRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.profile?.nested
    ?.UnlinkSuggestedProfileIdentifierResponse
) {
  $root.addJSON(jsonData);
}

/**
 * Suggested Profile Identifiers related protos for Buyer Portal Service RPC Request-Responses
 * Request to retrieve suggested identifiers using verified identifiers from profile corresponding
 * to the buyer in current authenticated multipass session.
 *
 * SOURCE RetrieveSuggestedProfileIdentifiersRequest @ squareup/buyerportal/profile/suggested_identifier.proto at 18:1
 */
export const RetrieveSuggestedProfileIdentifiersRequest = $root.lookupType(
  'squareup.buyerportal.profile.RetrieveSuggestedProfileIdentifiersRequest'
);
fixType(RetrieveSuggestedProfileIdentifiersRequest);
Builder.createAndAttachToType(RetrieveSuggestedProfileIdentifiersRequest);
/**
 * Response containing suggested identifiers linked to verified identifiers from authenticated
 * buyer's profile.
 *
 * SOURCE RetrieveSuggestedProfileIdentifiersResponse @ squareup/buyerportal/profile/suggested_identifier.proto at 26:1
 */
export const RetrieveSuggestedProfileIdentifiersResponse = $root.lookupType(
  'squareup.buyerportal.profile.RetrieveSuggestedProfileIdentifiersResponse'
);
fixType(RetrieveSuggestedProfileIdentifiersResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(RetrieveSuggestedProfileIdentifiersResponse);
/**
 * Request to unlink a suggested profile identifier linked to a verified identifier.
 * Handles the Not my card/email/phone use case case.
 *
 * SOURCE UnlinkSuggestedProfileIdentifierRequest @ squareup/buyerportal/profile/suggested_identifier.proto at 41:1
 */
export const UnlinkSuggestedProfileIdentifierRequest = $root.lookupType(
  'squareup.buyerportal.profile.UnlinkSuggestedProfileIdentifierRequest'
);
fixType(UnlinkSuggestedProfileIdentifierRequest, {
  identifierType: { required: true },
});
Builder.createAndAttachToType(UnlinkSuggestedProfileIdentifierRequest);
/**
 * Response indicate status and errors if any for the request to unlink suggested identifier.
 *
 * SOURCE UnlinkSuggestedProfileIdentifierResponse @ squareup/buyerportal/profile/suggested_identifier.proto at 52:1
 */
export const UnlinkSuggestedProfileIdentifierResponse = $root.lookupType(
  'squareup.buyerportal.profile.UnlinkSuggestedProfileIdentifierResponse'
);
fixType(UnlinkSuggestedProfileIdentifierResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(UnlinkSuggestedProfileIdentifierResponse);
