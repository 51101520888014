/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../Fixes';
import Builder from '../../../../Builder';
import './attribute';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      feature: {
        nested: {
          relay: {
            nested: {
              common: {
                nested: {
                  UserAttributes: {
                    fields: {
                      userAttributes: {
                        type: 'squareup.feature.relay.common.Attribute',
                        id: 1,
                        rule: 'repeated',
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.feature?.nested?.relay?.nested?.common
    ?.nested?.UserAttributes
) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE UserAttributes @ squareup/feature/relay/common/user_attributes.proto at 11:1
 */
export const UserAttributes = $root.lookupType(
  'squareup.feature.relay.common.UserAttributes'
);
fixType(UserAttributes, {
  userAttributes: { required: true },
});
Builder.createAndAttachToType(UserAttributes);
