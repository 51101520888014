import React from 'react';
import { MarketSvgProps } from '.';

const Globe: React.FC<MarketSvgProps> = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 0C4.04 0 0 4.04 0 9C0 13.96 4.03 18 8.99 18C8.99 18 8.99 18 9 18C9.01 18 9 18 9.01 18C13.97 18 18 13.96 18 9C18 4.04 13.96 0 9 0ZM14.45 4.61C13.89 4.82 13.27 5 12.61 5.14C12.41 4.19 12.14 3.33 11.81 2.59C12.84 3.05 13.75 3.74 14.45 4.61ZM16 9C16 9.94 15.81 10.83 15.48 11.65C14.71 11.33 13.85 11.08 12.91 10.89C12.97 10.28 13 9.65 13 9C13 8.35 12.97 7.72 12.91 7.12C13.84 6.93 14.71 6.67 15.48 6.36C15.81 7.17 16 8.06 16 9ZM9 15.99C8.6 15.8 7.84 14.62 7.38 12.58C7.91 12.53 8.45 12.51 9 12.51C9.55 12.51 10.09 12.54 10.62 12.58C10.16 14.62 9.4 15.8 9 15.99ZM9 10.5C8.34 10.5 7.7 10.54 7.07 10.59C7.03 10.09 7 9.57 7 9C7 8.43 7.03 7.91 7.07 7.41C7.7 7.47 8.34 7.5 9 7.5C9.66 7.5 10.3 7.46 10.93 7.41C10.97 7.91 11 8.43 11 9C11 9.57 10.97 10.09 10.93 10.59C10.3 10.53 9.66 10.5 9 10.5ZM2 9C2 8.06 2.19 7.17 2.52 6.35C3.29 6.67 4.15 6.92 5.09 7.11C5.03 7.72 5 8.35 5 9C5 9.65 5.03 10.28 5.09 10.88C4.16 11.07 3.29 11.33 2.52 11.64C2.19 10.83 2 9.94 2 9ZM9 2.01C9.4 2.2 10.16 3.38 10.62 5.42C10.09 5.47 9.55 5.5 9 5.5C8.45 5.5 7.91 5.47 7.38 5.43C7.84 3.38 8.6 2.2 9 2.01ZM6.19 2.59C5.86 3.32 5.59 4.18 5.39 5.14C4.73 5 4.11 4.82 3.55 4.61C4.25 3.74 5.16 3.05 6.19 2.59ZM3.55 13.39C4.11 13.18 4.72 13 5.39 12.86C5.59 13.81 5.86 14.67 6.19 15.41C5.16 14.95 4.25 14.26 3.55 13.39ZM11.81 15.41C12.14 14.68 12.41 13.82 12.61 12.86C13.27 13 13.89 13.18 14.45 13.39C13.75 14.26 12.84 14.95 11.81 15.41Z"
        fill="white"
      />
    </svg>
  );
};

export default Globe;
