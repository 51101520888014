import {
  MarketButton,
  MarketList,
  MarketRadio,
  MarketRow,
} from '@market/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import nonTranslatableStrings from 'utils/nonTranslatableStrings';

interface OptionsProps {
  doUnsubscribeMerchant: Function;
  doUnsubscribeGlobal: Function;
  obfuscatedEmail: string;
  obfuscatedCard: string;
  merchantName: string;
  isUnsubscribing: boolean;
}

enum OPTION_VALUES {
  MERCHANT = 'merchant',
  GLOBAL = 'global',
}

const Options: React.FC<OptionsProps> = (props) => {
  const { t } = useTranslation();

  const [selectedValue, setSelectedValue] = useState<OPTION_VALUES>(
    OPTION_VALUES.MERCHANT
  );

  return (
    <>
      <h2>{t('preference.components.unsubscribe.automaticReceipts.title')}</h2>

      <div>
        {t('preference.components.unsubscribe.automaticReceipts.subtitle', {
          square: nonTranslatableStrings.square,
        })}
      </div>

      <MarketList
        value={selectedValue}
        onMarketListSelectionsDidChange={(event) => {
          if (!event.detail.newSelectionValue) {
            return;
          }
          setSelectedValue(
            event.detail.newSelectionValue === OPTION_VALUES.MERCHANT
              ? OPTION_VALUES.MERCHANT
              : OPTION_VALUES.GLOBAL
          );
        }}
      >
        <MarketRow value={OPTION_VALUES.MERCHANT}>
          <label slot={'label'} id={'unsubscribeMerchantLabel'}>
            {t(
              'preference.components.unsubscribe.automaticReceipts.content.unsubscribeMerchant',
              { merchantName: props.merchantName }
            )}
          </label>
          <MarketRadio
            slot={'control'}
            aria-labelledby={'unsubscribeMerchantLabel'}
          />
        </MarketRow>

        {props.obfuscatedCard === '' ? null : ( // Cash payments don't have a card, therefore don't give them an option to unsubscribe a card.
          <MarketRow value={OPTION_VALUES.GLOBAL}>
            <label slot={'label'} id={'unsubscribeGlobalLabel'}>
              {t(
                'preference.components.unsubscribe.automaticReceipts.content.unsubscribeGlobal',
                { card: props.obfuscatedCard }
              )}
            </label>
            <MarketRadio
              slot={'control'}
              aria-labelledby={'unsubscribeGlobalLabel'}
            />
          </MarketRow>
        )}
      </MarketList>

      <MarketButton
        className={'mt-2'}
        onClick={() => {
          if (selectedValue === OPTION_VALUES.MERCHANT) {
            props.doUnsubscribeMerchant();
          } else if (selectedValue === OPTION_VALUES.GLOBAL) {
            props.doUnsubscribeGlobal();
          }
        }}
        rank={'primary'}
        {...(props.isUnsubscribing && { disabled: true })}
      >
        {t(
          'preference.components.unsubscribe.automaticReceipts.unsubscribeButton'
        )}
      </MarketButton>
    </>
  );
};

export default Options;
