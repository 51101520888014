import { GlobalAddress } from 'rpc/model/squareup/common/location';
import { Country } from 'rpc/model/squareup/common/countries';
import i18n, { extractPrimaryLocale } from 'i18n';
import * as Sentry from '@sentry/browser';

export function getCountryFromLocale(locale: string | string[] | undefined) {
  const firstValue = extractPrimaryLocale(locale);
  return Country[
    (firstValue === undefined
      ? firstValue
      : firstValue.split('-')[1]) as keyof typeof Country
  ];
}

function getCountryName(countryCode: Country | undefined) {
  const coutriesSupported = new Set<Country>([
    Country.AU,
    Country.CA,
    Country.ES,
    Country.FR,
    Country.IE,
    Country.JP,
    Country.PT,
    Country.GB,
    Country.US,
  ]);

  // get country name to display in address based off countryCode
  if (!countryCode) {
    return '';
  }
  if (coutriesSupported.has(countryCode)) {
    return i18n.t(`common.country.${Country[countryCode]}`);
  } else {
    // log if country is not supported
    Sentry.captureException(
      `GlobalAddress: unsupported country code ${countryCode}`
    );
    return '';
  }
}

export function formatMultilineAddress(
  globalAddress: GlobalAddress | undefined
) {
  if (!globalAddress) {
    return undefined;
  }

  return getAddressParts(globalAddress, globalAddress.countryCode).join('\n');
}

export function getAddressParts(
  globalAddress: GlobalAddress,
  countryCode: Country | undefined
) {
  if (!globalAddress) {
    return [];
  }

  let parts;
  const country = getCountryName(countryCode);

  switch (countryCode) {
    case Country.JP: {
      parts = getAddressPartsJP(globalAddress, country);
      break;
    }
    case Country.IE: {
      parts = getAddressPartsIE(globalAddress, country);
      break;
    }
    case Country.ES: {
      parts = getAddressPartsES(globalAddress, country);
      break;
    }
    case Country.FR: {
      parts = getAddressPartsFR(globalAddress, country);
      break;
    }
    case Country.PT: {
      parts = getAddressPartsPT(globalAddress, country);
      break;
    }
    default: {
      parts = getAddressPartsDefault(globalAddress, country);
    }
  }

  return parts.filter(Boolean);
}

function getAddressPartsJP(globalAddress: GlobalAddress, country: string) {
  const postalCode = globalAddress.postalCode;
  // in JP address formatting the postal mark, 〒, precedes the code to
  // indicate that the number following is a postal code.
  const postalCodeWithPostalPrefix =
    postalCode?.charAt(0) === '〒' ? postalCode : `〒${postalCode}`;

  const part1 = [
    postalCodeWithPostalPrefix,
    globalAddress.administrativeDistrictLevel1,
    `${globalAddress.locality} ${country}`,
    globalAddress.sublocality,
  ]
    .filter(Boolean)
    .join(', ');
  const part2 = [
    globalAddress.addressLine1,
    globalAddress.addressLine2,
    globalAddress.addressLine3,
  ]
    .filter(Boolean)
    .join(', ');
  return [part1, part2];
}

function getAddressPartsIE(globalAddress: GlobalAddress, country: string) {
  const part1 = [globalAddress.addressLine1, globalAddress.addressLine2]
    .filter(Boolean)
    .join(', ');
  const part2 = [
    globalAddress.locality,
    globalAddress.administrativeDistrictLevel1
      ? `Co. ${globalAddress.administrativeDistrictLevel1}`
      : undefined,
    globalAddress.postalCode,
    country,
  ]
    .filter(Boolean)
    .join(', ');
  return [part1, part2];
}

function getAddressPartsES(globalAddress: GlobalAddress, country: string) {
  const part1 = [globalAddress.addressLine1, globalAddress.addressLine2]
    .filter(Boolean)
    .join(', ');
  const part2 = [getAddressLastLine(globalAddress, Country.ES), country]
    .filter(Boolean)
    .join(', ');

  return [part1, part2];
}

function getAddressPartsFR(globalAddress: GlobalAddress, country: string) {
  const postalAndLocality = [globalAddress.postalCode, globalAddress.locality]
    .filter(Boolean)
    .join(' ');
  const part1 = [globalAddress.addressLine1, globalAddress.addressLine2]
    .filter(Boolean)
    .join(', ');
  const part2 = [postalAndLocality, country].filter(Boolean).join(', ');
  return [part1, part2];
}

function getAddressPartsPT(globalAddress: GlobalAddress, country: string) {
  const part1 = [globalAddress.addressLine1, globalAddress.addressLine2]
    .filter(Boolean)
    .join(', ');
  const part2 = [getAddressLastLine(globalAddress, Country.PT), country]
    .filter(Boolean)
    .join(', ');

  return [part1, part2];
}

function getAddressPartsDefault(globalAddress: GlobalAddress, country: string) {
  const part1 = [
    globalAddress.addressLine1,
    globalAddress.addressLine2,
    globalAddress.addressLine3,
  ]
    .filter(Boolean)
    .join(', ');
  const part2 = [
    getAddressLastLine(globalAddress, globalAddress.countryCode),
    country,
  ]
    .filter(Boolean)
    .join(', ');
  return [part1, part2];
}

export function getAddressLastLine(
  globalAddress: GlobalAddress,
  countryCode: Country
) {
  const administrativeDistrictLevel1 =
    globalAddress.administrativeDistrictLevel1 || '';
  const locality = globalAddress.locality || '';
  const postalCode = globalAddress.postalCode || '';

  switch (countryCode) {
    case Country.PT: {
      const postalAndLocality = [postalCode, locality]
        .filter(Boolean)
        .join(' ');
      return postalAndLocality.trim();
    }
    case Country.ES: {
      const postalAndLocality = [postalCode, locality]
        .filter(Boolean)
        .join(' ');
      const separator =
        postalAndLocality.length > 0 && administrativeDistrictLevel1
          ? ', '
          : '';
      return `${postalAndLocality}${separator}${administrativeDistrictLevel1}`.trim();
    }
    default: {
      const lastLineWithLocality = `${locality}, ${administrativeDistrictLevel1} ${postalCode}`;
      const lastLineWithoutLocality = `${administrativeDistrictLevel1} ${postalCode}`;
      return (
        globalAddress.locality ? lastLineWithLocality : lastLineWithoutLocality
      ).trim();
    }
  }
}
