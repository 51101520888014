import {
  EmailPreference as RpcEmailPreference,
  CardReceiptPreference as RpcCardReceiptPreference,
  RetrieveGlobalEmailPreferencesResponse,
  RetrieveMerchantEmailPreferencesResponse,
  MerchantEmailPreference as RpcMerchantEmailPreference,
} from 'rpc/model/squareup/buyerportal/preference/email';
import { immerable } from 'immer';
import {
  getRpcProduct,
  getRpcValue,
  getEnumProduct,
  getEnumValue,
} from 'utils/emailPreferences';
import { MerchantInfo } from 'routes/profile/models/Merchant';

export const PAGE_SIZE = 10;

export enum Product {
  Marketing,
  Receipt,
}

// FIXME: use the types from the generated protos directly in V2.
// These enum values are missing the "DO_NOT_USE" values as their first elements.
// https://prototype.sqprod.co/#/docs/squareup.buyerportal.preference.EmailPreference.Value
// src/rpc/model/squareup/buyerportal/preference/email.d.ts
export enum Value {
  OptIn,
  OptOut,
}

interface EmailPreferenceAttribute {
  product: Product;
  value: Value;
}

export class EmailPreference implements EmailPreferenceAttribute {
  [immerable] = true;
  public product: Product;
  public value: Value;

  static fromResponse = function (emailPreference: RpcEmailPreference) {
    const product = getEnumProduct(emailPreference.product!);
    const value = getEnumValue(emailPreference.value!);

    return new EmailPreference({ product, value });
  };

  static toProto = function (
    emailPreference: EmailPreference
  ): RpcEmailPreference {
    return RpcEmailPreference.create({
      product: getRpcProduct(emailPreference.product),
      value: getRpcValue(emailPreference.value),
    });
  };

  constructor(props: EmailPreferenceAttribute) {
    const { product, value } = props;

    this.product = product;
    this.value = value;
  }
}

interface CardReceiptPreferenceAttribute {
  cardId: string;
  brand: string;
  displayName: string;
  receiptPreference: EmailPreference; // Receipt Preference for the payment card
}

export class CardReceiptPreference implements CardReceiptPreferenceAttribute {
  [immerable] = true;
  public cardId: string;
  public brand: string;
  public displayName: string;
  public receiptPreference: EmailPreference;

  static fromResponse = function (
    cardReceiptPreference: RpcCardReceiptPreference
  ) {
    return new CardReceiptPreference({
      cardId: cardReceiptPreference.cardInfo!.id!,
      brand: cardReceiptPreference.cardInfo!.brand!,
      displayName: cardReceiptPreference.cardInfo!.displayName!,
      receiptPreference: EmailPreference.fromResponse(
        cardReceiptPreference.preference!
      ),
    });
  };

  constructor(props: CardReceiptPreferenceAttribute) {
    const { cardId, brand, displayName, receiptPreference } = props;

    this.cardId = cardId;
    this.brand = brand;
    this.displayName = displayName;
    this.receiptPreference = receiptPreference;
  }
}

interface GlobalEmailPreferenceAttribute {
  emailId: string;
  marketingPreference: EmailPreference;
  receiptPreference: EmailPreference;
  cardReceiptPreferences: Array<CardReceiptPreference>;
  useMarketingPrefs: boolean;
}

export class GlobalEmailPreference {
  [immerable] = true;
  public emailId: string;
  public marketingPreference: EmailPreference;
  public receiptPreference: EmailPreference;
  public cardReceiptPreferences: Array<CardReceiptPreference>;
  public useMarketingPrefs = false;

  static fromResponse = function (
    emailId: string,
    response: RetrieveGlobalEmailPreferencesResponse
  ) {
    return new GlobalEmailPreference({
      emailId,
      marketingPreference: EmailPreference.fromResponse(
        response.marketingPreference!
      ),
      receiptPreference: EmailPreference.fromResponse(
        response.receiptPreference!
      ),
      cardReceiptPreferences: response.cardReceiptPreferences!.map(
        (preference) => CardReceiptPreference.fromResponse(preference)
      ),
      useMarketingPrefs: response.useMarketingprefs,
    });
  };

  constructor(props: GlobalEmailPreferenceAttribute) {
    const {
      emailId,
      marketingPreference,
      receiptPreference,
      cardReceiptPreferences,
      useMarketingPrefs,
    } = props;

    this.emailId = emailId;
    this.marketingPreference = marketingPreference;
    this.receiptPreference = receiptPreference;
    this.cardReceiptPreferences = cardReceiptPreferences;
    this.useMarketingPrefs = useMarketingPrefs;
  }
}

interface BusinessEmailPreferenceAttribute {
  emailId: string;
  total: number;
  merchantPreferences: Record<number, Array<MerchantEmailPreference>>;
}

export class BusinessEmailPreference
  implements BusinessEmailPreferenceAttribute
{
  public emailId: string;
  public total: number;
  public merchantPreferences: Record<number, Array<MerchantEmailPreference>>;

  static fromResponse = function (
    emailId: string,
    offset: number,
    response: RetrieveMerchantEmailPreferencesResponse
  ) {
    const merchantPreferences = {
      [offset]:
        response.preferences?.map(MerchantEmailPreference.fromResponse) ?? [],
    };

    return new BusinessEmailPreference({
      emailId,
      total: response.total!,
      merchantPreferences,
    });
  };

  constructor(props: BusinessEmailPreferenceAttribute) {
    const { emailId, merchantPreferences, total } = props;

    this.emailId = emailId;
    this.total = total;
    this.merchantPreferences = merchantPreferences ?? [];
  }
}

interface MerchantEmailPreferenceAttribute {
  marketingPreference: EmailPreference;
  cardReceiptPreferences: Array<CardReceiptPreference>;
  merchantInfo: MerchantInfo;
}

export class MerchantEmailPreference
  implements MerchantEmailPreferenceAttribute
{
  [immerable] = true;
  public marketingPreference: EmailPreference;
  public cardReceiptPreferences: Array<CardReceiptPreference>;
  public merchantInfo: MerchantInfo;

  constructor(props: MerchantEmailPreferenceAttribute) {
    const { cardReceiptPreferences, marketingPreference, merchantInfo } = props;

    this.marketingPreference = marketingPreference;
    this.cardReceiptPreferences = cardReceiptPreferences;
    this.merchantInfo = merchantInfo;
  }

  static fromResponse(
    rpcPref: RpcMerchantEmailPreference
  ): MerchantEmailPreference {
    const { id, displayName, imageUrl } = rpcPref.merchantInfo!;

    return new MerchantEmailPreference({
      marketingPreference: EmailPreference.fromResponse(
        rpcPref.marketingPreference!
      ),
      cardReceiptPreferences:
        rpcPref.receiptPreferences?.map(CardReceiptPreference.fromResponse) ??
        [],
      merchantInfo: { id, displayName, imageUrl },
    });
  }
}
