import React from 'react';

type BackArrowIconProps = {
  slot: string;
  className?: string;
};

const BackArrowIcon: React.FC<BackArrowIconProps> = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.292894 7.29297C-0.0976308 7.68349 -0.0976307 8.31666 0.292894 8.70718L7.29289 15.7072L8.70711 14.293L3.41421 9.00008L15 9.00008L15 7.00008L3.41421 7.00008L8.70711 1.70718L7.29289 0.292968L0.292894 7.29297Z"
        fill="black"
        fillOpacity="0.9"
      />
    </svg>
  );
};

export default BackArrowIcon;
