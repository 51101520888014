/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../../Fixes';
import Builder from '../../../../../Builder';
import './loyalty-event';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      card: {
        nested: {
          balance: {
            nested: {
              loyalty_api: {
                nested: {
                  model: {
                    nested: {
                      LoyaltyEventGroup: {
                        fields: {
                          type: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyEventGroup.EventGroupType',
                            id: 1,
                            rule: 'optional',
                          },
                          events: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyEvent',
                            id: 5,
                            rule: 'repeated',
                          },
                        },
                        nested: {
                          EventGroupType: {
                            values: {
                              TYPE_DO_NOT_USE: 0,
                              ACCUMULATION: 1,
                              SINGLE: 2,
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyEventGroup &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyEventGroup?.nested?.EventGroupType
) {
  $root.addJSON(jsonData);
}

/**
 * --
 * @desc A group of loyalty events. Accumulation events for the same Order are placed in the same
 * group if possible. Otherwise, the group contains a single event. If no orderId is available,
 * the events will be placed in separate groups.
 *
 * SOURCE LoyaltyEventGroup @ squareup/card/balance/loyalty_api/model/loyalty-event-group.proto at 23:1
 */
export const LoyaltyEventGroup = $root.lookupType(
  'squareup.card.balance.loyalty_api.model.LoyaltyEventGroup'
);
fixType(LoyaltyEventGroup, {
  type: { required: true },
});
Builder.createAndAttachToType(LoyaltyEventGroup);
/**
 * SOURCE EventGroupType @ squareup/card/balance/loyalty_api/model/loyalty-event-group.proto at 26:3
 */
LoyaltyEventGroup.EventGroupType = $root.lookupEnum(
  'squareup.card.balance.loyalty_api.model.LoyaltyEventGroup.EventGroupType'
).values;
