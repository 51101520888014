import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MarketAccessory, MarketButton, MarketRow } from '@market/react';
import CashAppBadge from 'svgs/CashAppBadge';
import { useTrackLoyaltyEvent } from 'utils/custom-react-hooks/loyalty/useTrackLoyaltyEvent';
import {
  ActionItem,
  EventName,
  FeatureFormat,
} from 'services/tracking/cdp/events/types';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { selectLoyaltyProgram } from 'routes/merchant-scoped-portal/integrations/loyalty/loyaltySlice';
import { MerchantPortalContext } from 'routes/merchant-scoped-portal';

interface DownloadCashAppSectionProps {
  cashAppDownloadUrl: string;
}

const DownloadCashAppSection: React.FC<DownloadCashAppSectionProps> = ({
  cashAppDownloadUrl,
}) => {
  const { t } = useTranslation();

  const trackLoyaltyEvent = useTrackLoyaltyEvent();
  const onButtonClick = useCallback(() => {
    trackLoyaltyEvent(EventName.CLICK_FEATURE, {
      action_item: ActionItem.CLICK_BUTTON,
      event_description: 'Download Cash App',
      feature_format: FeatureFormat.BUTTON,
    });
  }, [trackLoyaltyEvent]);

  const loyaltyProgram = useSelector((state: AppState) =>
    selectLoyaltyProgram(state.loyaltyMerchantPortal)
  );
  const merchantPortalContext = useContext(MerchantPortalContext);
  const { merchantOverview } = merchantPortalContext.merchantPortalBaseData!;

  return (
    <section>
      <MarketRow>
        <label slot="label">{t('loyalty.cashAppUpSell.title')}</label>
        <label slot="subtext">
          {t('loyalty.cashAppUpSell.subtext', {
            merchantName: merchantOverview?.merchantName,
            pointsTerminology: loyaltyProgram?.terminology?.other,
          })}
        </label>
      </MarketRow>
      <MarketButton
        className="text-white bg-black"
        href={cashAppDownloadUrl}
        target="_blank"
        onClick={onButtonClick}
      >
        <MarketAccessory slot="icon">
          <CashAppBadge />
        </MarketAccessory>
        {t('loyalty.cashAppUpSell.downloadCashApp')}
      </MarketButton>
    </section>
  );
};

export default DownloadCashAppSection;
