/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      authz_engines: {
        nested: {
          obligations: {
            nested: {
              Obligation: {
                values: {
                  OBLIGATION_UNSPECIFIED: 0,
                  ENROLL_SMS_2FA: 2,
                  CHALLENGE_SMS_2FA: 3,
                  CHALLENGE_EMAIL_2FA: 4,
                  CONTACT_CUSTOMER_SUPPORT: 5,
                  TWO_FACTOR_AUTH_ENROLLMENT: 6,
                  TERMS_OF_SERVICE_ATTESTATION: 7,
                  IDENTITY_VERIFICATION_USER_INPUT: 8,
                  IDENTITY_VERIFICATION_PENDING_APPROVAL: 9,
                  IDENTITY_VERIFICATION_SSP_DOCUMENT_UPLOAD: 10,
                  IDENTITY_VERIFICATION_SSP_DOCUMENT_UPLOAD_APPROVAL: 11,
                  IDENTITY_VERIFICATION_VERIFF_DOCUMENT_UPLOAD: 12,
                  REQUIRES_PAYMENTS_ACTIVATION: 13,
                  TWO_FACTOR_UPGRADE: 200,
                  TWO_FACTOR_ENROLL: 201,
                  SCA_CHALLENGE: 202,
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.authz_engines?.nested?.obligations?.nested
    ?.Obligation
) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE Obligation @ squareup/authz_engines/obligations/obligations.proto at 8:1
 */
export const Obligation = $root.lookupEnum(
  'squareup.authz_engines.obligations.Obligation'
).values;
