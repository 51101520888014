/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import '../common/time';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      agenda: {
        nested: {
          Freq: {
            values: {
              DAILY: 4,
              WEEKLY: 5,
              MONTHLY: 6,
              YEARLY: 7,
            },
          },
          Weekday: {
            values: {
              SU: 1,
              MO: 2,
              TU: 3,
              WE: 4,
              TH: 5,
              FR: 6,
              SA: 7,
            },
          },
          WeekDayNum: {
            fields: {
              weeknum: {
                type: 'int32',
                id: 1,
                rule: 'optional',
              },
              weekday: {
                type: 'squareup.agenda.Weekday',
                id: 2,
                rule: 'optional',
              },
            },
          },
          ByWDayList: {
            fields: {
              weekdaynum: {
                type: 'squareup.agenda.WeekDayNum',
                id: 1,
                rule: 'repeated',
              },
            },
          },
          ByMoDayList: {
            fields: {
              monthdaynum: {
                type: 'int32',
                id: 1,
                rule: 'repeated',
              },
            },
          },
          ByYrDayList: {
            fields: {
              yeardaynum: {
                type: 'int32',
                id: 1,
                rule: 'repeated',
              },
            },
          },
          ByWkNoList: {
            fields: {
              weeknum: {
                type: 'int32',
                id: 1,
                rule: 'repeated',
              },
            },
          },
          ByMoList: {
            fields: {
              monthnum: {
                type: 'int32',
                id: 1,
                rule: 'repeated',
              },
            },
          },
          BySpList: {
            fields: {
              setposday: {
                type: 'int32',
                id: 1,
                rule: 'repeated',
              },
            },
          },
          RecurrenceRule: {
            fields: {
              freq: {
                type: 'squareup.agenda.Freq',
                id: 1,
                rule: 'optional',
              },
              until: {
                type: 'squareup.common.time.DateTime',
                id: 2,
                rule: 'optional',
              },
              count: {
                type: 'int32',
                id: 3,
                rule: 'optional',
              },
              interval: {
                type: 'int32',
                id: 4,
                rule: 'optional',
                options: {
                  default: 1,
                },
              },
              byday: {
                type: 'squareup.agenda.ByWDayList',
                id: 8,
                rule: 'optional',
              },
              bymonthday: {
                type: 'squareup.agenda.ByMoDayList',
                id: 9,
                rule: 'optional',
              },
              byyearday: {
                type: 'squareup.agenda.ByYrDayList',
                id: 10,
                rule: 'optional',
              },
              byweekno: {
                type: 'squareup.agenda.ByWkNoList',
                id: 11,
                rule: 'optional',
              },
              bymonth: {
                type: 'squareup.agenda.ByMoList',
                id: 12,
                rule: 'optional',
              },
              bysetpos: {
                type: 'squareup.agenda.BySpList',
                id: 13,
                rule: 'optional',
              },
              wkst: {
                type: 'squareup.agenda.Weekday',
                id: 14,
                rule: 'optional',
                options: {
                  default: 'MO',
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.agenda?.nested?.Freq &&
  !$root.nested?.squareup?.nested?.agenda?.nested?.Weekday &&
  !$root.nested?.squareup?.nested?.agenda?.nested?.WeekDayNum &&
  !$root.nested?.squareup?.nested?.agenda?.nested?.ByWDayList &&
  !$root.nested?.squareup?.nested?.agenda?.nested?.ByMoDayList &&
  !$root.nested?.squareup?.nested?.agenda?.nested?.ByYrDayList &&
  !$root.nested?.squareup?.nested?.agenda?.nested?.ByWkNoList &&
  !$root.nested?.squareup?.nested?.agenda?.nested?.ByMoList &&
  !$root.nested?.squareup?.nested?.agenda?.nested?.BySpList &&
  !$root.nested?.squareup?.nested?.agenda?.nested?.RecurrenceRule
) {
  $root.addJSON(jsonData);
}

/**
 * Proto definition of Recurrence Rule (http://tools.ietf.org/html/rfc5545#section-3.3.10)
 *
 * SOURCE Freq @ squareup/agenda/calendar.proto at 18:1
 */
export const Freq = $root.lookupEnum('squareup.agenda.Freq').values;
/**
 * Days of week. It is appropriate to use numerical values of this enum with other calendar APIS
 *
 * SOURCE Weekday @ squareup/agenda/calendar.proto at 26:1
 */
export const Weekday = $root.lookupEnum('squareup.agenda.Weekday').values;
/**
 * SOURCE WeekDayNum @ squareup/agenda/calendar.proto at 36:1
 */
export const WeekDayNum = $root.lookupType('squareup.agenda.WeekDayNum');
fixType(WeekDayNum, {
  weekday: { required: true },
});
Builder.createAndAttachToType(WeekDayNum);
/**
 * SOURCE ByWDayList @ squareup/agenda/calendar.proto at 41:1
 */
export const ByWDayList = $root.lookupType('squareup.agenda.ByWDayList');
fixType(ByWDayList);
Builder.createAndAttachToType(ByWDayList);
/**
 * SOURCE ByMoDayList @ squareup/agenda/calendar.proto at 46:1
 */
export const ByMoDayList = $root.lookupType('squareup.agenda.ByMoDayList');
fixType(ByMoDayList);
Builder.createAndAttachToType(ByMoDayList);
/**
 * SOURCE ByYrDayList @ squareup/agenda/calendar.proto at 50:1
 */
export const ByYrDayList = $root.lookupType('squareup.agenda.ByYrDayList');
fixType(ByYrDayList);
Builder.createAndAttachToType(ByYrDayList);
/**
 * SOURCE ByWkNoList @ squareup/agenda/calendar.proto at 55:1
 */
export const ByWkNoList = $root.lookupType('squareup.agenda.ByWkNoList');
fixType(ByWkNoList);
Builder.createAndAttachToType(ByWkNoList);
/**
 * SOURCE ByMoList @ squareup/agenda/calendar.proto at 60:1
 */
export const ByMoList = $root.lookupType('squareup.agenda.ByMoList');
fixType(ByMoList);
Builder.createAndAttachToType(ByMoList);
/**
 * SOURCE BySpList @ squareup/agenda/calendar.proto at 64:1
 */
export const BySpList = $root.lookupType('squareup.agenda.BySpList');
fixType(BySpList);
Builder.createAndAttachToType(BySpList);
/**
 * Recurrence rule. Contains a field per part. Field comments copied from http://tools.ietf.org/html/rfc5545#section-3.3.10
 *
 * SOURCE RecurrenceRule @ squareup/agenda/calendar.proto at 71:1
 */
export const RecurrenceRule = $root.lookupType(
  'squareup.agenda.RecurrenceRule'
);
fixType(RecurrenceRule, {
  freq: { required: true },
});
Builder.createAndAttachToType(RecurrenceRule);
