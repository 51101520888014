import BaseModal from 'components/modals/BaseModal';
import React, { useCallback, useContext, useMemo } from 'react';
import {
  MarketAccessory,
  MarketDivider,
  MarketHeader,
  MarketLink,
  MarketRow,
} from '@market/react';
import { useTranslation } from 'react-i18next';
import { MerchantPortalContext } from 'routes/merchant-scoped-portal';
import { useGetMerchantLocationsQuery } from 'store/query/api-extensions/merchantPortalBaseRpcs';
import ModuleLoading from 'routes/profile/common/loading/ModuleLoading';
import ModuleLoadFailed from 'routes/profile/common/errors/ModuleLoadFailed';
import LocationList from 'routes/merchant-scoped-portal/components/merchant-location/LocationList';
import { formatMerchantLocations } from 'routes/merchant-scoped-portal/components/merchant-location/view-models/FormattedMerchantLocation';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { selectLoyaltyProgram } from 'routes/merchant-scoped-portal/integrations/loyalty/loyaltySlice';
import Store from 'svgs/market-icons/Store';
import { normalizeUrl } from 'utils/url';
import { LoyaltyProgramRewardDefinition } from 'rpc/model/squareup/card/balance/loyalty_api/model/loyalty-program';

const RedemptionOptionsModal: React.FC = () => {
  const { t } = useTranslation();
  const { merchantPortalBaseData } = useContext(MerchantPortalContext);
  const merchantId = merchantPortalBaseData?.merchantOverview.merchantId ?? '';
  const sites = merchantPortalBaseData?.merchantOverview.sites;

  const publishedSiteUrls = useMemo(() => {
    return sites
      ? sites
          .filter((site) => site.isPublished)
          .map((site) => site.domain)
          .filter(Boolean)
      : [];
  }, [sites]);

  const {
    data: merchantLocations,
    isLoading,
    isError,
  } = useGetMerchantLocationsQuery(merchantId);

  const loyaltyProgram = useSelector((state: AppState) =>
    selectLoyaltyProgram(state.loyaltyMerchantPortal)
  );

  const loyaltyLocations =
    merchantLocations?.filter((location) =>
      loyaltyProgram?.locationIds?.includes(location.locationId)
    ) ?? [];

  // Square Online is not able to support reward redemption for category-based
  // rewards. So if a program only has category-based reward tiers, we will
  // hide Square Online links since the buyer won't actually be able to
  // redeem rewards there
  const hasOnlyCategoryBasedRewards = useMemo(() => {
    return (
      loyaltyProgram?.rewardTiers?.every(
        (tier) =>
          tier.definition?.scope ===
          LoyaltyProgramRewardDefinition.Scope.CATEGORY
      ) ?? false
    );
  }, [loyaltyProgram]);

  const renderHeaderAndSites = useCallback(() => {
    if (!hasOnlyCategoryBasedRewards && publishedSiteUrls.length > 0) {
      return (
        <>
          <MarketHeader
            data-testid="loyalty__redemption-options-modal__header"
            className="mb-4"
          >
            <h2>{t('loyalty.redemptionOptionsModal.redeemOnline.header')}</h2>
          </MarketHeader>
          {publishedSiteUrls.map((siteUrl) => (
            <MarketLink
              href={normalizeUrl(siteUrl)}
              target="_blank"
              key={siteUrl}
              data-testid="loyalty__redemption-options-modal__site-link"
            >
              <MarketRow variant="drill">
                <MarketAccessory
                  className="bg-fill-40 rounded"
                  size="image"
                  slot="leading-accessory"
                >
                  <Store width={24} height={24} fill="black" />
                </MarketAccessory>
                <label slot="label" className="text-black">
                  {siteUrl}
                </label>
              </MarketRow>
            </MarketLink>
          ))}
          <MarketDivider margin="large" />
          <h2>{t('loyalty.redemptionOptionsModal.redeemInStore.header')}</h2>
        </>
      );
    } else {
      return (
        <MarketHeader
          data-testid="loyalty__redemption-options-modal__header"
          className="mb-4"
        >
          <h2>{t('loyalty.redemptionOptionsModal.redeemInStore.header')}</h2>
        </MarketHeader>
      );
    }
  }, [hasOnlyCategoryBasedRewards, publishedSiteUrls, t]);

  if (isLoading) {
    return (
      <BaseModal>
        <ModuleLoading embedded />
      </BaseModal>
    );
  }
  if (isError) {
    return (
      <BaseModal>
        <ModuleLoadFailed embedded />
      </BaseModal>
    );
  }

  return (
    <BaseModal>
      {renderHeaderAndSites()}
      <LocationList
        merchantLocations={formatMerchantLocations(loyaltyLocations)}
        shouldShowContactInfo={true}
      />
    </BaseModal>
  );
};

export default RedemptionOptionsModal;
