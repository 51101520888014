import React from 'react';

export enum CashAppBadgeVariant {
  Default,
  Partial,
  Rounded,
  Square,
}

interface CashAppBadgeProps {
  width?: number;
  height?: number;
  variant?: CashAppBadgeVariant;
}

// https://developers.cash.app/docs/api/resources/Cash-App-Pay-assets#merchant-assets
const CashAppBadge: React.FC<CashAppBadgeProps> = ({
  width = 24,
  height = 24,
  variant = CashAppBadgeVariant.Default,
}) => {
  switch (variant) {
    case CashAppBadgeVariant.Default: {
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 400 400"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 192C0 124.794 0 91.1906 13.0792 65.5211C24.584 42.9417 42.9417 24.584 65.5211 13.0792C91.1906 0 124.794 0 192 0H208C275.206 0 308.809 0 334.479 13.0792C357.058 24.584 375.416 42.9417 386.921 65.5211C400 91.1906 400 124.794 400 192V208C400 275.206 400 308.809 386.921 334.479C375.416 357.058 357.058 375.416 334.479 386.921C308.809 400 275.206 400 208 400H192C124.794 400 91.1906 400 65.5211 386.921C42.9417 375.416 24.584 357.058 13.0792 334.479C0 308.809 0 275.206 0 208V192Z"
            fill="#00D64F"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M210.392 130.477C231.119 130.477 250.985 138.87 263.965 150.39C267.248 153.305 272.162 153.287 275.243 150.165L290.687 134.501C293.945 131.195 293.826 125.782 290.307 122.761C279.944 113.852 266.031 105.091 248.907 99.4822L253.751 76.4171C254.801 71.3898 250.973 66.6652 245.833 66.6652L215.989 66.6652C212.173 66.6652 208.873 69.3362 208.078 73.0695L203.715 93.5823C164.043 95.5648 130.419 115.359 130.419 155.975C130.419 191.137 158.238 206.207 187.6 216.635C215.42 227.064 230.098 230.934 230.098 245.612C230.098 260.682 215.42 269.568 193.779 269.568C174.05 269.568 153.383 263.056 137.345 247.244C134.199 244.14 129.166 244.116 126.02 247.221L109.425 263.591C106.178 266.79 106.208 272.049 109.478 275.218C122.418 287.777 138.799 296.87 157.496 301.963L152.943 323.331C151.881 328.34 155.674 333.065 160.802 333.106L190.693 333.332C194.539 333.361 197.88 330.673 198.664 326.904L202.979 306.361C250.48 303.429 279.552 277.61 279.552 239.819C279.552 205.05 250.581 190.365 215.42 178.388C195.328 171.04 177.937 166.024 177.937 150.96C177.937 136.282 194.171 130.477 210.392 130.477"
            fill="white"
          />
        </svg>
      );
    }
    case CashAppBadgeVariant.Partial: {
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 83 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46.1255 28.6625C55.3727 28.6625 64.2359 32.4069 70.0274 37.5469C71.4918 38.8471 73.6844 38.8392 75.0588 37.4463L81.9492 30.4579C83.403 28.9829 83.35 26.5678 81.7797 25.2199C77.1561 21.2451 70.9489 17.3365 63.3091 14.834L65.47 4.54348C65.9387 2.30052 64.2306 0.192627 61.9374 0.192627L48.6227 0.192627C46.9199 0.192627 45.4476 1.38428 45.0927 3.04994L43.1464 12.2018C25.4464 13.0863 10.4449 21.9177 10.4449 40.0388C10.4449 55.7262 22.8566 62.4498 35.9568 67.1025C48.3685 71.7552 54.9172 73.4818 54.9172 80.0306C54.9172 86.7541 48.3685 90.7184 38.7134 90.7184C29.9111 90.7184 20.6904 87.8134 13.5352 80.7588C12.1317 79.3738 9.8861 79.3633 8.48261 80.7482L1.07849 88.0517C-0.370029 89.479 -0.356789 91.8253 1.10232 93.2394C6.8752 98.8428 14.184 102.9 22.5255 105.172L20.4944 114.705C20.0204 116.94 21.7126 119.048 24.0005 119.066L37.3364 119.167C39.0524 119.18 40.5433 117.981 40.8928 116.299L42.818 107.134C64.0108 105.826 76.9813 94.3066 76.9813 77.446C76.9813 61.9334 64.0559 55.3819 48.3685 50.0381C39.4046 46.7597 31.6456 44.522 31.6456 37.8011C31.6456 31.2523 38.8882 28.6625 46.1255 28.6625"
            fill="white"
          />
        </svg>
      );
    }
    case CashAppBadgeVariant.Rounded: {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 275 194"
          fill="none"
        >
          <rect width="275" height="194" rx="33" fill="#00D64F" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M141.554 65.5117C150.752 65.5117 159.568 69.2363 165.329 74.349C166.785 75.6424 168.966 75.6345 170.334 74.2489L177.187 67.2976C178.634 65.8304 178.581 63.4281 177.019 62.0874C172.42 58.1336 166.245 54.2457 158.646 51.7565L160.796 41.5204C161.262 39.2894 159.563 37.1926 157.282 37.1926H144.038C142.344 37.1926 140.879 38.378 140.526 40.0348L138.59 49.1382C120.984 50.018 106.062 58.8027 106.062 76.8277C106.062 92.432 118.408 99.12 131.439 103.748C143.785 108.376 150.299 110.094 150.299 116.608C150.299 123.296 143.785 127.239 134.181 127.239C125.425 127.239 116.253 124.349 109.136 117.332C107.74 115.954 105.506 115.944 104.11 117.321L96.7452 124.586C95.3044 126.006 95.3175 128.34 96.7689 129.746C102.511 135.32 109.781 139.356 118.079 141.616L116.058 151.098C115.587 153.322 117.27 155.418 119.546 155.437L132.811 155.537C134.518 155.55 136.001 154.357 136.349 152.684L138.264 143.568C159.344 142.266 172.246 130.808 172.246 114.037C172.246 98.6063 159.389 92.0896 143.785 86.774C134.868 83.513 127.15 81.2872 127.15 74.6019C127.15 68.0878 134.355 65.5117 141.554 65.5117"
            fill="white"
          />
        </svg>
      );
    }
    case CashAppBadgeVariant.Square: {
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 275 194"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="275" height="194" fill="#00D64F" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M141.554 65.5117C150.752 65.5117 159.568 69.2363 165.329 74.349C166.785 75.6424 168.966 75.6345 170.334 74.2489L177.187 67.2976C178.634 65.8304 178.581 63.4281 177.019 62.0874C172.42 58.1336 166.245 54.2457 158.646 51.7565L160.796 41.5204C161.262 39.2894 159.563 37.1926 157.282 37.1926H144.038C142.344 37.1926 140.879 38.378 140.526 40.0348L138.59 49.1382C120.984 50.018 106.062 58.8027 106.062 76.8277C106.062 92.432 118.408 99.12 131.439 103.748C143.785 108.376 150.299 110.094 150.299 116.608C150.299 123.296 143.785 127.239 134.181 127.239C125.425 127.239 116.253 124.349 109.136 117.332C107.74 115.954 105.506 115.944 104.11 117.321L96.7452 124.586C95.3044 126.006 95.3175 128.34 96.7689 129.746C102.511 135.32 109.781 139.356 118.079 141.616L116.058 151.098C115.587 153.322 117.27 155.418 119.546 155.437L132.811 155.537C134.518 155.55 136.001 154.357 136.349 152.684L138.264 143.568C159.344 142.266 172.246 130.808 172.246 114.037C172.246 98.6063 159.389 92.0896 143.785 86.774C134.868 83.513 127.15 81.2872 127.15 74.6019C127.15 68.0878 134.355 65.5117 141.554 65.5117"
            fill="white"
          />
        </svg>
      );
    }
  }
};

export default CashAppBadge;
