import React, { useEffect } from 'react';
import SqLoading from 'components/SqLoading';
import Es2Tracker from 'services/tracking/tracker';
import { cashRedirectViewEvent } from 'services/tracking/events';
import { CashLinkInfo } from 'rpc/model/squareup/buyerportal/cashlink/data';

type CashRedirectProps = {
  cashLinkInfo: CashLinkInfo;
  url: string;
  onRedirectTimeout: Function;
};

const CashRedirect: React.FC<CashRedirectProps> = (props) => {
  useEffect(() => {
    Es2Tracker.track(
      cashRedirectViewEvent(
        props.cashLinkInfo.deliveryIdentifier!,
        props.cashLinkInfo.merchantInfo!.id
      )
    );

    setTimeout(() => {
      window.location.replace(props.url);
    }, 2000);

    setTimeout(() => {
      props.onRedirectTimeout();
    }, 5000);
  }, [props]);

  return (
    <div
      className={
        'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      }
    >
      <SqLoading />
    </div>
  );
};

export default CashRedirect;
