/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import '../fulfillment/fulfillment';
import '../../connect/v2/resources/tender';
import '../../connect/v2/resources/refund';
import '../../connect/v2/common/money';
import '../../privacyvault/annotations';
import '../order_extensions';
import '../../client/orders/model';
import '../../client/orders/order-customer';
import '../../client/orders/rst-client-details';
import '../../client/orders/order-payment';
import '../../client/orders/order-channel';
import '../../client/orders/order-discount';
import '../../connect/v2/common/measurement_units';
import '../../connect/v2/common/tax_category';
import '../../connect/v2/catalog/resources/datatypes';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      omg: {
        nested: {
          model: {
            nested: {
              Order: {
                fields: {
                  id: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  locationId: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  referenceId: {
                    type: 'string',
                    id: 22,
                    rule: 'optional',
                  },
                  creatorAppId: {
                    type: 'string',
                    id: 44,
                    rule: 'optional',
                  },
                  source: {
                    type: 'squareup.omg.model.Order.Source',
                    id: 45,
                    rule: 'optional',
                  },
                  customerId: {
                    type: 'string',
                    id: 46,
                    rule: 'optional',
                  },
                  name: {
                    type: 'string',
                    id: 33,
                    rule: 'optional',
                  },
                  merchantId: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  lineItems: {
                    type: 'squareup.omg.model.Order.LineItem',
                    id: 6,
                    rule: 'repeated',
                  },
                  taxes: {
                    type: 'squareup.omg.model.Order.LineItem.Tax',
                    id: 7,
                    rule: 'repeated',
                  },
                  discounts: {
                    type: 'squareup.omg.model.Order.LineItem.Discount',
                    id: 8,
                    rule: 'repeated',
                  },
                  serviceCharges: {
                    type: 'squareup.omg.model.Order.ServiceCharge',
                    id: 34,
                    rule: 'repeated',
                  },
                  fulfillments: {
                    type: 'squareup.omg.fulfillment.Fulfillment',
                    id: 10,
                    rule: 'repeated',
                  },
                  fulfillmentPromises: {
                    type: 'squareup.omg.fulfillment.FulfillmentPromise',
                    id: 80,
                    rule: 'repeated',
                  },
                  returns: {
                    type: 'squareup.omg.model.Order.Return',
                    id: 38,
                    rule: 'repeated',
                  },
                  returnAmounts: {
                    type: 'squareup.omg.model.Order.MoneyAmounts',
                    id: 39,
                    rule: 'optional',
                  },
                  netAmounts: {
                    type: 'squareup.omg.model.Order.MoneyAmounts',
                    id: 40,
                    rule: 'optional',
                  },
                  roundingAdjustment: {
                    type: 'squareup.omg.model.Order.RoundingAdjustment',
                    id: 42,
                    rule: 'optional',
                  },
                  tendersFinalized: {
                    type: 'bool',
                    id: 36,
                    rule: 'optional',
                  },
                  tenders: {
                    type: 'squareup.connect.v2.resources.Tender',
                    id: 32,
                    rule: 'repeated',
                  },
                  paymentGroups: {
                    type: 'squareup.omg.model.Order.PaymentGroup',
                    id: 47,
                    rule: 'repeated',
                  },
                  refunds: {
                    type: 'squareup.connect.v2.resources.Refund',
                    id: 41,
                    rule: 'repeated',
                  },
                  refundGroups: {
                    type: 'squareup.omg.model.Order.RefundGroup',
                    id: 48,
                    rule: 'repeated',
                  },
                  metadata: {
                    type: 'string',
                    id: 15,
                    keyType: 'string',
                  },
                  createdAt: {
                    type: 'string',
                    id: 16,
                    rule: 'optional',
                  },
                  updatedAt: {
                    type: 'string',
                    id: 17,
                    rule: 'optional',
                  },
                  closedAt: {
                    type: 'string',
                    id: 31,
                    rule: 'optional',
                  },
                  state: {
                    type: 'squareup.omg.model.Order.State',
                    id: 18,
                    rule: 'optional',
                  },
                  substatus: {
                    type: 'string',
                    id: 29,
                    rule: 'optional',
                  },
                  version: {
                    type: 'int32',
                    id: 19,
                    rule: 'optional',
                  },
                  workflow: {
                    type: 'string',
                    id: 20,
                    rule: 'optional',
                  },
                  workflowVersion: {
                    type: 'int32',
                    id: 21,
                    rule: 'optional',
                  },
                  totalMoney: {
                    type: 'squareup.connect.v2.common.Money',
                    id: 28,
                    rule: 'optional',
                  },
                  totalTaxMoney: {
                    type: 'squareup.connect.v2.common.Money',
                    id: 23,
                    rule: 'optional',
                  },
                  totalDiscountMoney: {
                    type: 'squareup.connect.v2.common.Money',
                    id: 26,
                    rule: 'optional',
                  },
                  totalTipMoney: {
                    type: 'squareup.connect.v2.common.Money',
                    id: 27,
                    rule: 'optional',
                  },
                  totalServiceChargeMoney: {
                    type: 'squareup.connect.v2.common.Money',
                    id: 35,
                    rule: 'optional',
                  },
                  shortReferenceId: {
                    type: 'string',
                    id: 37,
                    rule: 'optional',
                  },
                  displayedOrderReference: {
                    type: 'string',
                    id: 79,
                    rule: 'optional',
                  },
                  ticketName: {
                    type: 'string',
                    id: 54,
                    rule: 'optional',
                  },
                  pricingOptions: {
                    type: 'squareup.omg.model.Order.PricingOptions',
                    id: 53,
                    rule: 'optional',
                  },
                  discountCodes: {
                    type: 'squareup.omg.model.Order.DiscountCode',
                    id: 51,
                    rule: 'repeated',
                  },
                  rewards: {
                    type: 'squareup.omg.model.Order.Reward',
                    id: 52,
                    rule: 'repeated',
                  },
                  creditRedemptions: {
                    type: 'squareup.omg.model.Order.CreditRedemption',
                    id: 73,
                    rule: 'repeated',
                  },
                  voids: {
                    type: 'squareup.omg.model.Order.Void',
                    id: 55,
                    rule: 'repeated',
                  },
                  comps: {
                    type: 'squareup.omg.model.Order.Comp',
                    id: 56,
                    rule: 'repeated',
                  },
                  note: {
                    type: 'string',
                    id: 57,
                    rule: 'optional',
                  },
                  sequentialNumber: {
                    type: 'string',
                    id: 58,
                    rule: 'optional',
                  },
                  vaultedData: {
                    type: 'squareup.privacyvault.VaultedData',
                    id: 50,
                    rule: 'optional',
                  },
                  orderExtensions: {
                    type: 'squareup.omg.OrderExtensions',
                    id: 60,
                    rule: 'optional',
                  },
                  returnedQuantities: {
                    type: 'squareup.omg.model.Order.ReturnedQuantity',
                    id: 61,
                    rule: 'repeated',
                  },
                  netAmountDueMoney: {
                    type: 'squareup.connect.v2.common.Money',
                    id: 62,
                    rule: 'optional',
                  },
                  diningOption: {
                    type: 'squareup.omg.model.Order.DiningOption',
                    id: 63,
                    rule: 'optional',
                  },
                  processingModes: {
                    type: 'squareup.omg.model.Order.ProcessingModes',
                    id: 64,
                    rule: 'optional',
                  },
                  taxExemptions: {
                    type: 'squareup.omg.model.Order.LineItem.TaxExemption',
                    id: 65,
                    rule: 'repeated',
                  },
                  apiReferenceIds: {
                    type: 'squareup.omg.model.Order.OrderApiReferenceIds',
                    id: 66,
                    rule: 'optional',
                  },
                  pricingBlocklists: {
                    type: 'squareup.omg.model.Order.PricingBlocklists',
                    id: 67,
                    rule: 'optional',
                  },
                  depositsFullyPaidAt: {
                    type: 'string',
                    id: 68,
                    rule: 'optional',
                  },
                  deposits: {
                    type: 'squareup.omg.model.Order.Deposit',
                    id: 69,
                    rule: 'repeated',
                  },
                  paidAt: {
                    type: 'string',
                    id: 70,
                    rule: 'optional',
                  },
                  idempotentHistory: {
                    type: 'squareup.omg.model.Order.IdempotentRequestHistory',
                    id: 72,
                    rule: 'repeated',
                  },
                  channels: {
                    type: 'squareup.omg.model.Order.ChannelAttribution',
                    id: 74,
                    rule: 'repeated',
                  },
                  lineItemGroups: {
                    type: 'squareup.omg.model.Order.LineItemGroup',
                    id: 75,
                    rule: 'repeated',
                  },
                  returnedTips: {
                    type: 'squareup.omg.model.Order.ReturnedTip',
                    id: 76,
                    rule: 'repeated',
                  },
                  futureStates: {
                    type: 'squareup.omg.model.FutureState',
                    id: 77,
                    rule: 'repeated',
                  },
                  clientTimestamps: {
                    type: 'squareup.omg.model.Order.ClientTimestamps',
                    id: 78,
                    rule: 'optional',
                  },
                  wasStatus: {
                    type: 'squareup.omg.model.Order.State',
                    id: 9000,
                    rule: 'optional',
                  },
                  extOrderClientDetails: {
                    type: 'squareup.client.orders.OrderClientDetails',
                    id: 10000,
                    rule: 'optional',
                  },
                  extOrderCustomer: {
                    type: 'squareup.client.orders.OrderCustomer',
                    id: 10001,
                    rule: 'optional',
                  },
                  extRstClientDetails: {
                    type: 'squareup.client.orders.RstClientDetails',
                    id: 10002,
                    rule: 'optional',
                  },
                  extOrderPayment: {
                    type: 'squareup.client.orders.OrderPayment',
                    id: 10004,
                    rule: 'optional',
                  },
                  extOrderChannel: {
                    type: 'squareup.client.orders.OrderChannel',
                    id: 10005,
                    rule: 'optional',
                  },
                  extOrderDiscount: {
                    type: 'squareup.client.orders.OrderDiscount',
                    id: 10006,
                    rule: 'optional',
                  },
                },
                nested: {
                  IdempotentRequestHistory: {
                    fields: {
                      uid: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      idempotencyKey: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                      requestServerTimestamp: {
                        type: 'string',
                        id: 3,
                        rule: 'optional',
                      },
                      clientOu: {
                        type: 'string',
                        id: 4,
                        rule: 'optional',
                      },
                      applicationId: {
                        type: 'string',
                        id: 5,
                        rule: 'optional',
                      },
                    },
                  },
                  Source: {
                    fields: {
                      name: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      applicationName: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                      applicationId: {
                        type: 'string',
                        id: 3,
                        rule: 'optional',
                      },
                      clientOu: {
                        type: 'string',
                        id: 4,
                        rule: 'optional',
                      },
                      squareProduct: {
                        type: 'string',
                        id: 5,
                        rule: 'optional',
                      },
                      appearsInOrderManager: {
                        type: 'bool',
                        id: 6,
                        rule: 'optional',
                      },
                    },
                  },
                  LineItemApiReferenceIds: {
                    fields: {
                      bookingId: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                    },
                  },
                  State: {
                    values: {
                      DO_NOT_USE: 0,
                      OPEN: 1,
                      COMPLETED: 2,
                      CANCELED: 3,
                      DRAFT: 4,
                    },
                  },
                  QuantityUnit: {
                    fields: {
                      measurementUnit: {
                        type: 'squareup.connect.v2.common.MeasurementUnit',
                        id: 1,
                        rule: 'optional',
                      },
                      precision: {
                        type: 'int32',
                        id: 2,
                        rule: 'optional',
                      },
                      catalogObjectId: {
                        type: 'string',
                        id: 3,
                        rule: 'optional',
                      },
                      catalogVersion: {
                        type: 'int64',
                        id: 4,
                        rule: 'optional',
                      },
                    },
                  },
                  PricingBlocklists: {
                    fields: {
                      blockedDiscountIds: {
                        type: 'string',
                        id: 1,
                        rule: 'repeated',
                      },
                      blockedTaxIds: {
                        type: 'string',
                        id: 2,
                        rule: 'repeated',
                      },
                    },
                  },
                  LineItem: {
                    fields: {
                      uid: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      name: {
                        type: 'string',
                        id: 8,
                        rule: 'optional',
                      },
                      quantity: {
                        type: 'string',
                        id: 4,
                        rule: 'optional',
                      },
                      quantityUnit: {
                        type: 'squareup.omg.model.Order.QuantityUnit',
                        id: 29,
                        rule: 'optional',
                      },
                      tareQuantity: {
                        type: 'string',
                        id: 43,
                        rule: 'optional',
                      },
                      note: {
                        type: 'string',
                        id: 15,
                        rule: 'optional',
                      },
                      catalogObjectId: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                      catalogClientToken: {
                        type: 'string',
                        id: 51,
                        rule: 'optional',
                      },
                      catalogVersion: {
                        type: 'int64',
                        id: 3,
                        rule: 'optional',
                      },
                      variationName: {
                        type: 'string',
                        id: 9,
                        rule: 'optional',
                      },
                      catalogItemId: {
                        type: 'string',
                        id: 23,
                        rule: 'optional',
                      },
                      itemCatalogClientToken: {
                        type: 'string',
                        id: 52,
                        rule: 'optional',
                      },
                      catalogCategoryId: {
                        type: 'string',
                        id: 24,
                        rule: 'optional',
                      },
                      categoryCatalogClientToken: {
                        type: 'string',
                        id: 53,
                        rule: 'optional',
                      },
                      itemType: {
                        type: 'squareup.omg.model.Order.LineItem.ItemType',
                        id: 26,
                        rule: 'optional',
                        options: {
                          default: 'ITEM',
                        },
                      },
                      sku: {
                        type: 'string',
                        id: 27,
                        rule: 'optional',
                      },
                      categoryName: {
                        type: 'string',
                        id: 25,
                        rule: 'optional',
                      },
                      metadata: {
                        type: 'string',
                        id: 11,
                        keyType: 'string',
                      },
                      modifiers: {
                        type: 'squareup.omg.model.Order.LineItem.Modifier',
                        id: 12,
                        rule: 'repeated',
                      },
                      taxes: {
                        type: 'squareup.omg.model.Order.LineItem.Tax',
                        id: 13,
                        rule: 'repeated',
                      },
                      discounts: {
                        type: 'squareup.omg.model.Order.LineItem.Discount',
                        id: 14,
                        rule: 'repeated',
                      },
                      appliedTaxes: {
                        type: 'squareup.omg.model.Order.LineItem.AppliedTax',
                        id: 21,
                        rule: 'repeated',
                      },
                      appliedDiscounts: {
                        type: 'squareup.omg.model.Order.LineItem.AppliedDiscount',
                        id: 22,
                        rule: 'repeated',
                      },
                      appliedServiceCharges: {
                        type: 'squareup.omg.model.Order.LineItem.AppliedServiceCharge',
                        id: 36,
                        rule: 'repeated',
                      },
                      basePriceMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 10,
                        rule: 'optional',
                      },
                      variationTotalPriceMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 20,
                        rule: 'optional',
                      },
                      grossSalesMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 16,
                        rule: 'optional',
                      },
                      totalTaxMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 17,
                        rule: 'optional',
                      },
                      totalDiscountMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 18,
                        rule: 'optional',
                      },
                      totalMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 19,
                        rule: 'optional',
                      },
                      pricingBlocklists: {
                        type: 'squareup.omg.model.Order.LineItem.PricingBlocklists',
                        id: 30,
                        rule: 'optional',
                      },
                      quantityEntryType: {
                        type: 'squareup.omg.model.Order.LineItem.QuantityEntryType',
                        id: 31,
                        rule: 'optional',
                        options: {
                          default: 'MANUALLY_ENTERED',
                        },
                      },
                      taxCategory: {
                        type: 'squareup.connect.v2.common.TaxCategory',
                        id: 32,
                        rule: 'optional',
                        options: {
                          default: 'GENERAL_TANGIBLE_GOOD',
                        },
                      },
                      returnedQuantities: {
                        type: 'squareup.omg.model.Order.ReturnedQuantity',
                        id: 33,
                        rule: 'repeated',
                      },
                      lineItemExtensions: {
                        type: 'squareup.omg.OrderLineItemExtensions',
                        id: 35,
                        rule: 'optional',
                      },
                      diningOption: {
                        type: 'squareup.omg.model.Order.DiningOption',
                        id: 37,
                        rule: 'optional',
                      },
                      totalServiceChargeMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 38,
                        rule: 'optional',
                      },
                      apiReferenceIds: {
                        type: 'squareup.omg.model.Order.LineItemApiReferenceIds',
                        id: 39,
                        rule: 'optional',
                      },
                      appliedTaxExemptions: {
                        type: 'squareup.omg.model.Order.AppliedTaxExemption',
                        id: 40,
                        rule: 'repeated',
                      },
                      appliedDeposit: {
                        type: 'squareup.omg.model.Order.LineItem.AppliedDeposit',
                        id: 41,
                        rule: 'optional',
                      },
                      taxable: {
                        type: 'bool',
                        id: 44,
                        rule: 'optional',
                        options: {
                          default: true,
                        },
                      },
                      channels: {
                        type: 'squareup.omg.model.Order.ChannelAttribution',
                        id: 45,
                        rule: 'repeated',
                      },
                      appliedGroups: {
                        type: 'string',
                        id: 46,
                        rule: 'repeated',
                      },
                      appliedComboId: {
                        type: 'string',
                        id: 47,
                        rule: 'optional',
                      },
                      appliedComboChoiceId: {
                        type: 'string',
                        id: 48,
                        rule: 'optional',
                      },
                      comboPriceAdjustmentMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 49,
                        rule: 'optional',
                      },
                      comboAppliedMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 50,
                        rule: 'optional',
                      },
                      comboAppliedWeight: {
                        type: 'int64',
                        id: 54,
                        rule: 'optional',
                      },
                      wasMultipleQuantityMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 9000,
                        rule: 'optional',
                      },
                      catalogItemVariationCount: {
                        type: 'int32',
                        id: 10000,
                        rule: 'optional',
                      },
                    },
                    nested: {
                      QuantityEntryType: {
                        values: {
                          QUANTITY_ENTRY_TYPE_DO_NOT_USE: 0,
                          MANUALLY_ENTERED: 1,
                          READ_FROM_SCALE: 2,
                          MANUAL_WITH_CONNECTED_SCALE: 3,
                          READ_FROM_SCALE_TARED: 4,
                          READ_FROM_SCALE_WITH_MANUAL_TARE: 5,
                        },
                      },
                      ItemType: {
                        values: {
                          DO_NOT_USE: 0,
                          ITEM: 1,
                          CUSTOM_AMOUNT: 2,
                          GIFT_CARD: 3,
                          CREDIT_PACKAGE: 4,
                        },
                      },
                      Discount: {
                        fields: {
                          uid: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                          catalogObjectId: {
                            type: 'string',
                            id: 2,
                            rule: 'optional',
                          },
                          catalogVersion: {
                            type: 'int64',
                            id: 3,
                            rule: 'optional',
                          },
                          catalogClientToken: {
                            type: 'string',
                            id: 26,
                            rule: 'optional',
                          },
                          name: {
                            type: 'string',
                            id: 4,
                            rule: 'optional',
                          },
                          type: {
                            type: 'squareup.omg.model.Order.LineItem.Discount.Type',
                            id: 8,
                            rule: 'optional',
                          },
                          percentage: {
                            type: 'string',
                            id: 5,
                            rule: 'optional',
                          },
                          amountMoney: {
                            type: 'squareup.connect.v2.common.Money',
                            id: 6,
                            rule: 'optional',
                          },
                          appliedMoney: {
                            type: 'squareup.connect.v2.common.Money',
                            id: 7,
                            rule: 'optional',
                          },
                          metadata: {
                            type: 'string',
                            id: 10,
                            keyType: 'string',
                          },
                          scope: {
                            type: 'squareup.omg.model.Order.LineItem.Discount.Scope',
                            id: 12,
                            rule: 'optional',
                          },
                          discountCodeIds: {
                            type: 'string',
                            id: 14,
                            rule: 'repeated',
                          },
                          rewardIds: {
                            type: 'string',
                            id: 15,
                            rule: 'repeated',
                          },
                          creditRedemptionIds: {
                            type: 'string',
                            id: 25,
                            rule: 'repeated',
                          },
                          pricingRuleId: {
                            type: 'string',
                            id: 16,
                            rule: 'optional',
                          },
                          pricingRuleVersion: {
                            type: 'int64',
                            id: 21,
                            rule: 'optional',
                          },
                          quantity: {
                            type: 'string',
                            id: 17,
                            rule: 'optional',
                            options: {
                              default: '1',
                            },
                          },
                          maximumAmountMoney: {
                            type: 'squareup.connect.v2.common.Money',
                            id: 18,
                            rule: 'optional',
                          },
                          modifyTaxBasis: {
                            type: 'squareup.omg.model.Order.LineItem.Discount.ModifyTaxBasis',
                            id: 19,
                            rule: 'optional',
                          },
                          applyPerQuantity: {
                            type: 'bool',
                            id: 20,
                            rule: 'optional',
                          },
                          applicationMethod: {
                            type: 'squareup.omg.model.Order.LineItem.Discount.ApplicationMethod',
                            id: 22,
                            rule: 'optional',
                          },
                          discountExtensions: {
                            type: 'squareup.omg.OrderDiscountExtensions',
                            id: 24,
                            rule: 'optional',
                          },
                        },
                        nested: {
                          Type: {
                            values: {
                              UNKNOWN_DISCOUNT: 0,
                              FIXED_PERCENTAGE: 1,
                              FIXED_AMOUNT: 2,
                              VARIABLE_PERCENTAGE: 3,
                              VARIABLE_AMOUNT: 4,
                            },
                          },
                          Scope: {
                            values: {
                              OTHER_DISCOUNT_SCOPE: 0,
                              LINE_ITEM: 1,
                              ORDER: 2,
                            },
                          },
                          ModifyTaxBasis: {
                            values: {
                              DO_NOT_USE: 0,
                              MODIFY_TAX_BASIS: 1,
                              DO_NOT_MODIFY_TAX_BASIS: 2,
                            },
                          },
                          ApplicationMethod: {
                            values: {
                              UNKNOWN_METHOD: 0,
                              MANUALLY_APPLIED: 1,
                              PRICING_RULE: 2,
                              COMP: 3,
                            },
                          },
                        },
                      },
                      Modifier: {
                        fields: {
                          uid: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                          catalogObjectId: {
                            type: 'string',
                            id: 5,
                            rule: 'optional',
                          },
                          catalogVersion: {
                            type: 'int64',
                            id: 6,
                            rule: 'optional',
                          },
                          catalogClientToken: {
                            type: 'string',
                            id: 12,
                            rule: 'optional',
                          },
                          catalogModifierListId: {
                            type: 'string',
                            id: 11,
                            rule: 'optional',
                          },
                          name: {
                            type: 'string',
                            id: 4,
                            rule: 'optional',
                          },
                          quantity: {
                            type: 'string',
                            id: 10,
                            rule: 'optional',
                          },
                          basePriceMoney: {
                            type: 'squareup.connect.v2.common.Money',
                            id: 2,
                            rule: 'optional',
                          },
                          totalPriceMoney: {
                            type: 'squareup.connect.v2.common.Money',
                            id: 3,
                            rule: 'optional',
                          },
                          metadata: {
                            type: 'string',
                            id: 7,
                            keyType: 'string',
                          },
                          modifierExtensions: {
                            type: 'squareup.omg.OrderModifierExtensions',
                            id: 9,
                            rule: 'optional',
                          },
                        },
                      },
                      Tax: {
                        fields: {
                          uid: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                          catalogObjectId: {
                            type: 'string',
                            id: 2,
                            rule: 'optional',
                          },
                          catalogVersion: {
                            type: 'int64',
                            id: 3,
                            rule: 'optional',
                          },
                          catalogClientToken: {
                            type: 'string',
                            id: 19,
                            rule: 'optional',
                          },
                          name: {
                            type: 'string',
                            id: 5,
                            rule: 'optional',
                          },
                          type: {
                            type: 'squareup.omg.model.Order.LineItem.Tax.Type',
                            id: 7,
                            rule: 'optional',
                            options: {
                              default: 'ADDITIVE',
                            },
                          },
                          percentage: {
                            type: 'string',
                            id: 6,
                            rule: 'optional',
                          },
                          calculationPhase: {
                            type: 'squareup.omg.model.Order.LineItem.Tax.TaxCalculationPhaseScope.TaxCalculationPhase',
                            id: 8,
                            rule: 'optional',
                            options: {
                              default: 'TAX_SUBTOTAL_PHASE',
                            },
                          },
                          metadata: {
                            type: 'string',
                            id: 9,
                            keyType: 'string',
                          },
                          appliedMoney: {
                            type: 'squareup.connect.v2.common.Money',
                            id: 10,
                            rule: 'optional',
                          },
                          scope: {
                            type: 'squareup.omg.model.Order.LineItem.Tax.Scope',
                            id: 13,
                            rule: 'optional',
                          },
                          autoApplied: {
                            type: 'bool',
                            id: 15,
                            rule: 'optional',
                          },
                          taxExtensions: {
                            type: 'squareup.omg.OrderTaxExtensions',
                            id: 17,
                            rule: 'optional',
                          },
                          amountMoney: {
                            type: 'squareup.connect.v2.common.Money',
                            id: 18,
                            rule: 'optional',
                          },
                          calculationType: {
                            type: 'squareup.omg.model.Order.LineItem.Tax.CalculationType',
                            id: 20,
                            rule: 'optional',
                            options: {
                              default: 'PERCENTAGE',
                            },
                          },
                        },
                        nested: {
                          Type: {
                            values: {
                              UNKNOWN_TAX: 0,
                              ADDITIVE: 1,
                              INCLUSIVE: 2,
                            },
                          },
                          TaxCalculationPhaseScope: {
                            nested: {
                              TaxCalculationPhase: {
                                values: {
                                  DO_NOT_USE: 0,
                                  TAX_SUBTOTAL_PHASE: 1,
                                  TAX_TOTAL_PHASE: 2,
                                },
                              },
                            },
                          },
                          Scope: {
                            values: {
                              OTHER_TAX_SCOPE: 0,
                              LINE_ITEM: 1,
                              ORDER: 2,
                            },
                          },
                          CalculationType: {
                            values: {
                              DO_NOT_USE: 0,
                              PERCENTAGE: 1,
                              FIXED_AMOUNT: 2,
                            },
                          },
                        },
                      },
                      PricingBlocklists: {
                        fields: {
                          blockedDiscounts: {
                            type: 'squareup.omg.model.Order.LineItem.PricingBlocklists.BlockedDiscount',
                            id: 1,
                            rule: 'repeated',
                          },
                          blockedTaxes: {
                            type: 'squareup.omg.model.Order.LineItem.PricingBlocklists.BlockedTax',
                            id: 2,
                            rule: 'repeated',
                          },
                        },
                        nested: {
                          BlockedDiscount: {
                            fields: {
                              uid: {
                                type: 'string',
                                id: 1,
                                rule: 'optional',
                              },
                              discountUid: {
                                type: 'string',
                                id: 2,
                                rule: 'optional',
                              },
                              discountCatalogObjectId: {
                                type: 'string',
                                id: 3,
                                rule: 'optional',
                              },
                            },
                          },
                          BlockedTax: {
                            fields: {
                              uid: {
                                type: 'string',
                                id: 1,
                                rule: 'optional',
                              },
                              taxUid: {
                                type: 'string',
                                id: 2,
                                rule: 'optional',
                              },
                              taxCatalogObjectId: {
                                type: 'string',
                                id: 3,
                                rule: 'optional',
                              },
                            },
                          },
                        },
                      },
                      TaxExemption: {
                        fields: {
                          uid: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                          catalogObjectId: {
                            type: 'string',
                            id: 2,
                            rule: 'optional',
                          },
                          catalogVersion: {
                            type: 'int64',
                            id: 3,
                            rule: 'optional',
                          },
                          name: {
                            type: 'string',
                            id: 4,
                            rule: 'optional',
                          },
                        },
                      },
                      AppliedDiscount: {
                        fields: {
                          uid: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                          discountUid: {
                            type: 'string',
                            id: 2,
                            rule: 'optional',
                          },
                          appliedMoney: {
                            type: 'squareup.connect.v2.common.Money',
                            id: 3,
                            rule: 'optional',
                          },
                        },
                      },
                      AppliedTax: {
                        fields: {
                          uid: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                          taxUid: {
                            type: 'string',
                            id: 2,
                            rule: 'optional',
                          },
                          appliedMoney: {
                            type: 'squareup.connect.v2.common.Money',
                            id: 3,
                            rule: 'optional',
                          },
                        },
                      },
                      AppliedServiceCharge: {
                        fields: {
                          uid: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                          serviceChargeUid: {
                            type: 'string',
                            id: 2,
                            rule: 'optional',
                          },
                          appliedMoney: {
                            type: 'squareup.connect.v2.common.Money',
                            id: 3,
                            rule: 'optional',
                          },
                        },
                      },
                      AppliedDeposit: {
                        fields: {
                          uid: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                          depositUid: {
                            type: 'string',
                            id: 2,
                            rule: 'optional',
                          },
                        },
                      },
                    },
                  },
                  LineItemGroup: {
                    fields: {
                      uid: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      lineItemUids: {
                        type: 'string',
                        id: 2,
                        rule: 'repeated',
                      },
                      type: {
                        type: 'squareup.omg.model.Order.LineItemGroup.Type',
                        id: 3,
                        rule: 'optional',
                      },
                      catalogObjectId: {
                        type: 'string',
                        id: 4,
                        rule: 'optional',
                      },
                      catalogVersion: {
                        type: 'int64',
                        id: 5,
                        rule: 'optional',
                      },
                      catalogClientToken: {
                        type: 'string',
                        id: 12,
                        rule: 'optional',
                      },
                      catalogItemId: {
                        type: 'string',
                        id: 6,
                        rule: 'optional',
                      },
                      catalogName: {
                        type: 'string',
                        id: 7,
                        rule: 'optional',
                      },
                      catalogVariationName: {
                        type: 'string',
                        id: 8,
                        rule: 'optional',
                      },
                      basePriceMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 9,
                        rule: 'optional',
                      },
                      comboChoices: {
                        type: 'squareup.omg.model.Order.LineItemGroup.ComboChoice',
                        id: 10,
                        rule: 'repeated',
                      },
                      note: {
                        type: 'string',
                        id: 11,
                        rule: 'optional',
                      },
                    },
                    nested: {
                      Type: {
                        values: {
                          CUSTOM: 1,
                          COMBO: 2,
                        },
                      },
                      ComboChoice: {
                        fields: {
                          uid: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                          comboChoiceId: {
                            type: 'string',
                            id: 2,
                            rule: 'optional',
                          },
                          name: {
                            type: 'string',
                            id: 3,
                            rule: 'optional',
                          },
                          itemId: {
                            type: 'string',
                            id: 4,
                            rule: 'optional',
                          },
                          itemVariationId: {
                            type: 'string',
                            id: 5,
                            rule: 'optional',
                          },
                          categoryId: {
                            type: 'string',
                            id: 6,
                            rule: 'optional',
                          },
                          numSelections: {
                            type: 'int64',
                            id: 7,
                            rule: 'optional',
                          },
                        },
                      },
                    },
                  },
                  AppliedTaxExemption: {
                    fields: {
                      uid: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      taxExemptionUid: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                    },
                  },
                  ReturnedQuantity: {
                    fields: {
                      uid: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      returnOrderId: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                      returnUid: {
                        type: 'string',
                        id: 3,
                        rule: 'optional',
                      },
                      returnLineItemUid: {
                        type: 'string',
                        id: 4,
                        rule: 'optional',
                      },
                      returnLineItemType: {
                        type: 'squareup.omg.model.Order.LineItem.ItemType',
                        id: 8,
                        rule: 'optional',
                      },
                      returnedQuantity: {
                        type: 'string',
                        id: 5,
                        rule: 'optional',
                      },
                      refundedMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 6,
                        rule: 'optional',
                      },
                      state: {
                        type: 'squareup.omg.model.Order.ReturnedQuantity.State',
                        id: 7,
                        rule: 'optional',
                        options: {
                          default: 'COMPLETED',
                        },
                      },
                    },
                    nested: {
                      State: {
                        values: {
                          RETURNED_QUANTITY_STATE_DO_NOT_USE: 0,
                          PROPOSED: 1,
                          COMPLETED: 2,
                        },
                      },
                    },
                  },
                  ReturnedTip: {
                    fields: {
                      uid: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      returnOrderId: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                      returnUid: {
                        type: 'string',
                        id: 3,
                        rule: 'optional',
                      },
                      returnTipUid: {
                        type: 'string',
                        id: 4,
                        rule: 'optional',
                      },
                      tenderUid: {
                        type: 'string',
                        id: 5,
                        rule: 'optional',
                      },
                      refundedMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 6,
                        rule: 'optional',
                      },
                      state: {
                        type: 'squareup.omg.model.Order.ReturnedTip.State',
                        id: 7,
                        rule: 'optional',
                        options: {
                          default: 'COMPLETED',
                        },
                      },
                    },
                    nested: {
                      State: {
                        values: {
                          RETURNED_TIP_STATE_DO_NOT_USE: 0,
                          PROPOSED: 1,
                          COMPLETED: 2,
                        },
                      },
                    },
                  },
                  ReturnedServiceCharge: {
                    fields: {
                      uid: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      returnOrderId: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                      returnUid: {
                        type: 'string',
                        id: 3,
                        rule: 'optional',
                      },
                      returnServiceChargeUid: {
                        type: 'string',
                        id: 4,
                        rule: 'optional',
                      },
                      refundedMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 5,
                        rule: 'optional',
                      },
                      state: {
                        type: 'squareup.omg.model.Order.ReturnedServiceCharge.State',
                        id: 6,
                        rule: 'optional',
                        options: {
                          default: 'COMPLETED',
                        },
                      },
                    },
                    nested: {
                      State: {
                        values: {
                          RETURNED_SERVICE_CHARGE_STATE_DO_NOT_USE: 0,
                          PROPOSED: 1,
                          COMPLETED: 2,
                        },
                      },
                    },
                  },
                  ServiceCharge: {
                    fields: {
                      uid: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      name: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                      catalogObjectId: {
                        type: 'string',
                        id: 12,
                        rule: 'optional',
                      },
                      catalogVersion: {
                        type: 'int64',
                        id: 13,
                        rule: 'optional',
                      },
                      catalogClientToken: {
                        type: 'string',
                        id: 28,
                        rule: 'optional',
                      },
                      percentage: {
                        type: 'string',
                        id: 3,
                        rule: 'optional',
                      },
                      amountMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 4,
                        rule: 'optional',
                      },
                      appliedMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 5,
                        rule: 'optional',
                      },
                      totalMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 9,
                        rule: 'optional',
                      },
                      totalTaxMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 10,
                        rule: 'optional',
                      },
                      calculationPhase: {
                        type: 'squareup.omg.model.Order.ServiceCharge.CalculationPhase',
                        id: 6,
                        rule: 'optional',
                      },
                      taxable: {
                        type: 'bool',
                        id: 7,
                        rule: 'optional',
                      },
                      taxes: {
                        type: 'squareup.omg.model.Order.LineItem.Tax',
                        id: 8,
                        rule: 'repeated',
                      },
                      appliedTaxes: {
                        type: 'squareup.omg.model.Order.LineItem.AppliedTax',
                        id: 11,
                        rule: 'repeated',
                      },
                      metadata: {
                        type: 'string',
                        id: 14,
                        keyType: 'string',
                      },
                      type: {
                        type: 'squareup.omg.model.Order.ServiceCharge.Type',
                        id: 15,
                        rule: 'optional',
                        options: {
                          default: 'CUSTOM',
                        },
                      },
                      treatmentType: {
                        type: 'squareup.omg.model.Order.ServiceCharge.TreatmentType',
                        id: 18,
                        rule: 'optional',
                        options: {
                          default: 'LINE_ITEM_TREATMENT',
                        },
                      },
                      pricingRuleId: {
                        type: 'string',
                        id: 19,
                        rule: 'optional',
                      },
                      pricingRuleVersion: {
                        type: 'int64',
                        id: 20,
                        rule: 'optional',
                      },
                      applicationMethod: {
                        type: 'squareup.omg.model.Order.ServiceCharge.ApplicationMethod',
                        id: 21,
                        rule: 'optional',
                      },
                      scope: {
                        type: 'squareup.omg.model.Order.ServiceCharge.Scope',
                        id: 22,
                        rule: 'optional',
                      },
                      serviceChargeExtensions: {
                        type: 'squareup.omg.OrderServiceChargeExtensions',
                        id: 17,
                        rule: 'optional',
                      },
                      taxCategory: {
                        type: 'squareup.connect.v2.common.TaxCategory',
                        id: 23,
                        rule: 'optional',
                        options: {
                          default: 'GENERAL_TANGIBLE_GOOD',
                        },
                      },
                      appliedServiceCharges: {
                        type: 'squareup.omg.model.Order.LineItem.AppliedServiceCharge',
                        id: 24,
                        rule: 'repeated',
                      },
                      totalServiceChargeMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 25,
                        rule: 'optional',
                      },
                      pricingBlocklists: {
                        type: 'squareup.omg.model.Order.LineItem.PricingBlocklists',
                        id: 26,
                        rule: 'optional',
                      },
                      returnedServiceCharges: {
                        type: 'squareup.omg.model.Order.ReturnedServiceCharge',
                        id: 27,
                        rule: 'repeated',
                      },
                    },
                    nested: {
                      CalculationPhase: {
                        values: {
                          SERVICE_CHARGE_CALCULATION_PHASE_DO_NOT_USE: 0,
                          SUBTOTAL_PHASE: 1,
                          TOTAL_PHASE: 2,
                          APPORTIONED_PERCENTAGE_PHASE: 3,
                          APPORTIONED_AMOUNT_PHASE: 4,
                          CARD_SURCHARGE_PHASE: 5,
                        },
                      },
                      Type: {
                        values: {
                          SERVICE_CHARGE_TYPE_DO_NOT_USE: 0,
                          AUTO_GRATUITY: 1,
                          CUSTOM: 2,
                          CARD_SURCHARGE: 3,
                        },
                      },
                      TreatmentType: {
                        values: {
                          SERVICE_CHARGE_TREATMENT_TYPE_DO_NOT_USE: 0,
                          LINE_ITEM_TREATMENT: 1,
                          APPORTIONED_TREATMENT: 2,
                        },
                      },
                      ApplicationMethod: {
                        values: {
                          UNKNOWN_METHOD: 0,
                          MANUALLY_APPLIED: 1,
                          PRICING_RULE: 2,
                        },
                      },
                      Scope: {
                        values: {
                          OTHER_SERVICE_CHARGE_SCOPE: 0,
                          LINE_ITEM: 1,
                          ORDER: 2,
                        },
                      },
                    },
                  },
                  RoundingAdjustment: {
                    fields: {
                      uid: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      name: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                      amountMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 3,
                        rule: 'optional',
                      },
                    },
                  },
                  MoneyAmounts: {
                    fields: {
                      totalMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 1,
                        rule: 'optional',
                      },
                      taxMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 2,
                        rule: 'optional',
                      },
                      discountMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 3,
                        rule: 'optional',
                      },
                      tipMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 4,
                        rule: 'optional',
                      },
                      serviceChargeMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 5,
                        rule: 'optional',
                      },
                    },
                  },
                  Return: {
                    fields: {
                      uid: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      sourceOrderId: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                      returnLineItems: {
                        type: 'squareup.omg.model.Order.ReturnLineItem',
                        id: 3,
                        rule: 'repeated',
                      },
                      returnServiceCharges: {
                        type: 'squareup.omg.model.Order.ReturnServiceCharge',
                        id: 4,
                        rule: 'repeated',
                      },
                      returnTaxes: {
                        type: 'squareup.omg.model.Order.ReturnTax',
                        id: 5,
                        rule: 'repeated',
                      },
                      returnDiscounts: {
                        type: 'squareup.omg.model.Order.ReturnDiscount',
                        id: 6,
                        rule: 'repeated',
                      },
                      returnTips: {
                        type: 'squareup.omg.model.Order.ReturnTip',
                        id: 7,
                        rule: 'repeated',
                      },
                      roundingAdjustment: {
                        type: 'squareup.omg.model.Order.RoundingAdjustment',
                        id: 8,
                        rule: 'optional',
                      },
                      returnAmounts: {
                        type: 'squareup.omg.model.Order.MoneyAmounts',
                        id: 9,
                        rule: 'optional',
                      },
                      returnType: {
                        type: 'squareup.omg.model.Order.Return.Type',
                        id: 10,
                        rule: 'optional',
                        options: {
                          default: 'LINKED',
                        },
                      },
                    },
                    nested: {
                      Type: {
                        values: {
                          DO_NOT_USE: 0,
                          LINKED: 1,
                          UNLINKED: 5,
                        },
                      },
                    },
                  },
                  PaymentGroup: {
                    fields: {
                      uid: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      state: {
                        type: 'squareup.omg.model.Order.PaymentGroup.State',
                        id: 2,
                        rule: 'optional',
                      },
                      paymentIds: {
                        type: 'string',
                        id: 3,
                        rule: 'repeated',
                      },
                      addedPaymentIds: {
                        type: 'string',
                        id: 5,
                        rule: 'repeated',
                      },
                      discardedPaymentIds: {
                        type: 'string',
                        id: 6,
                        rule: 'repeated',
                      },
                      completedPaymentIds: {
                        type: 'string',
                        id: 9,
                        rule: 'repeated',
                      },
                      completionProcessingMode: {
                        type: 'squareup.omg.model.Order.ProcessingMode',
                        id: 7,
                        rule: 'optional',
                        options: {
                          default: 'ONLINE',
                        },
                      },
                      delayCompletionReason: {
                        type: 'squareup.omg.model.Order.PaymentGroup.DelayCompletionReason',
                        id: 8,
                        rule: 'optional',
                      },
                      intentToPayVersion: {
                        type: 'int32',
                        id: 10,
                        rule: 'optional',
                      },
                      paymentType: {
                        type: 'squareup.omg.model.PaymentType',
                        id: 11,
                        rule: 'optional',
                      },
                    },
                    nested: {
                      State: {
                        values: {
                          DO_NOT_USE: 0,
                          SELECTED: 1,
                          WILL_COMPLETE: 2,
                          CANCELED: 3,
                          COMPLETED: 4,
                          OPEN: 5,
                        },
                      },
                      DelayCompletionReason: {
                        values: {
                          DELAY_COMPLETION_REASON_DO_NOT_USE: 0,
                          PAPER_TIP: 1,
                          REOPENABLE: 2,
                          PAYING: 3,
                        },
                      },
                    },
                  },
                  RefundGroup: {
                    fields: {
                      uid: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      state: {
                        type: 'squareup.omg.model.Order.RefundGroup.State',
                        id: 2,
                        rule: 'optional',
                      },
                      refundIds: {
                        type: 'string',
                        id: 3,
                        rule: 'repeated',
                      },
                    },
                    nested: {
                      State: {
                        values: {
                          DO_NOT_USE: 0,
                          PENDING: 1,
                          CANCELED: 2,
                          COMPLETED: 3,
                        },
                      },
                    },
                  },
                  ReturnLineItem: {
                    fields: {
                      uid: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      sourceLineItemUid: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                      name: {
                        type: 'string',
                        id: 4,
                        rule: 'optional',
                      },
                      quantity: {
                        type: 'string',
                        id: 5,
                        rule: 'optional',
                      },
                      quantityUnit: {
                        type: 'squareup.omg.model.Order.QuantityUnit',
                        id: 27,
                        rule: 'optional',
                      },
                      note: {
                        type: 'string',
                        id: 6,
                        rule: 'optional',
                      },
                      catalogObjectId: {
                        type: 'string',
                        id: 7,
                        rule: 'optional',
                      },
                      catalogVersion: {
                        type: 'int64',
                        id: 8,
                        rule: 'optional',
                      },
                      catalogClientToken: {
                        type: 'string',
                        id: 36,
                        rule: 'optional',
                      },
                      variationName: {
                        type: 'string',
                        id: 9,
                        rule: 'optional',
                      },
                      catalogItemId: {
                        type: 'string',
                        id: 10,
                        rule: 'optional',
                      },
                      catalogCategoryId: {
                        type: 'string',
                        id: 11,
                        rule: 'optional',
                      },
                      itemType: {
                        type: 'squareup.omg.model.Order.LineItem.ItemType',
                        id: 12,
                        rule: 'optional',
                        options: {
                          default: 'ITEM',
                        },
                      },
                      sku: {
                        type: 'string',
                        id: 13,
                        rule: 'optional',
                      },
                      categoryName: {
                        type: 'string',
                        id: 14,
                        rule: 'optional',
                      },
                      returnModifiers: {
                        type: 'squareup.omg.model.Order.ReturnLineItemModifier',
                        id: 16,
                        rule: 'repeated',
                      },
                      returnTaxes: {
                        type: 'squareup.omg.model.Order.ReturnTax',
                        id: 17,
                        rule: 'repeated',
                      },
                      returnDiscounts: {
                        type: 'squareup.omg.model.Order.ReturnDiscount',
                        id: 18,
                        rule: 'repeated',
                      },
                      appliedTaxes: {
                        type: 'squareup.omg.model.Order.LineItem.AppliedTax',
                        id: 19,
                        rule: 'repeated',
                      },
                      appliedDiscounts: {
                        type: 'squareup.omg.model.Order.LineItem.AppliedDiscount',
                        id: 20,
                        rule: 'repeated',
                      },
                      basePriceMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 21,
                        rule: 'optional',
                      },
                      variationTotalPriceMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 22,
                        rule: 'optional',
                      },
                      grossReturnMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 23,
                        rule: 'optional',
                      },
                      totalTaxMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 24,
                        rule: 'optional',
                      },
                      totalDiscountMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 25,
                        rule: 'optional',
                      },
                      totalMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 26,
                        rule: 'optional',
                      },
                      restockType: {
                        type: 'squareup.omg.model.Order.ReturnLineItem.RestockType',
                        id: 28,
                        rule: 'optional',
                      },
                      appliedServiceCharges: {
                        type: 'squareup.omg.model.Order.LineItem.AppliedServiceCharge',
                        id: 29,
                        rule: 'repeated',
                      },
                      totalServiceChargeMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 30,
                        rule: 'optional',
                      },
                      appliedGroups: {
                        type: 'string',
                        id: 31,
                        rule: 'repeated',
                      },
                      appliedComboId: {
                        type: 'string',
                        id: 32,
                        rule: 'optional',
                      },
                      appliedComboChoiceId: {
                        type: 'string',
                        id: 33,
                        rule: 'optional',
                      },
                      comboPriceAdjustmentMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 34,
                        rule: 'optional',
                      },
                      comboAppliedMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 35,
                        rule: 'optional',
                      },
                      comboAppliedWeight: {
                        type: 'int64',
                        id: 37,
                        rule: 'optional',
                      },
                    },
                    nested: {
                      RestockType: {
                        values: {
                          DO_NOT_USE: 0,
                          RESTOCK: 1,
                          NO_RESTOCK: 2,
                          CANCELLATION: 3,
                        },
                      },
                    },
                  },
                  ReturnLineItemModifier: {
                    fields: {
                      uid: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      sourceModifierUid: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                      catalogObjectId: {
                        type: 'string',
                        id: 3,
                        rule: 'optional',
                      },
                      catalogVersion: {
                        type: 'int64',
                        id: 4,
                        rule: 'optional',
                      },
                      catalogClientToken: {
                        type: 'string',
                        id: 9,
                        rule: 'optional',
                      },
                      name: {
                        type: 'string',
                        id: 5,
                        rule: 'optional',
                      },
                      basePriceMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 6,
                        rule: 'optional',
                      },
                      totalPriceMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 7,
                        rule: 'optional',
                      },
                      quantity: {
                        type: 'string',
                        id: 8,
                        rule: 'optional',
                      },
                    },
                  },
                  ReturnServiceCharge: {
                    fields: {
                      uid: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      sourceServiceChargeUid: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                      name: {
                        type: 'string',
                        id: 4,
                        rule: 'optional',
                      },
                      catalogObjectId: {
                        type: 'string',
                        id: 5,
                        rule: 'optional',
                      },
                      catalogVersion: {
                        type: 'int64',
                        id: 6,
                        rule: 'optional',
                      },
                      catalogClientToken: {
                        type: 'string',
                        id: 21,
                        rule: 'optional',
                      },
                      percentage: {
                        type: 'string',
                        id: 7,
                        rule: 'optional',
                      },
                      amountMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 8,
                        rule: 'optional',
                      },
                      appliedMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 9,
                        rule: 'optional',
                      },
                      totalMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 10,
                        rule: 'optional',
                      },
                      totalTaxMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 11,
                        rule: 'optional',
                      },
                      calculationPhase: {
                        type: 'squareup.omg.model.Order.ServiceCharge.CalculationPhase',
                        id: 12,
                        rule: 'optional',
                      },
                      taxable: {
                        type: 'bool',
                        id: 13,
                        rule: 'optional',
                      },
                      returnTaxes: {
                        type: 'squareup.omg.model.Order.ReturnTax',
                        id: 14,
                        rule: 'repeated',
                      },
                      appliedTaxes: {
                        type: 'squareup.omg.model.Order.LineItem.AppliedTax',
                        id: 15,
                        rule: 'repeated',
                      },
                      treatmentType: {
                        type: 'squareup.omg.model.Order.ServiceCharge.TreatmentType',
                        id: 16,
                        rule: 'optional',
                        options: {
                          default: 'LINE_ITEM_TREATMENT',
                        },
                      },
                      scope: {
                        type: 'squareup.omg.model.Order.ServiceCharge.Scope',
                        id: 17,
                        rule: 'optional',
                      },
                      appliedServiceCharges: {
                        type: 'squareup.omg.model.Order.LineItem.AppliedServiceCharge',
                        id: 18,
                        rule: 'repeated',
                      },
                      totalServiceChargeMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 19,
                        rule: 'optional',
                      },
                      type: {
                        type: 'squareup.omg.model.Order.ServiceCharge.Type',
                        id: 20,
                        rule: 'optional',
                        options: {
                          default: 'CUSTOM',
                        },
                      },
                    },
                  },
                  ReturnTax: {
                    fields: {
                      uid: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      sourceTaxUid: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                      catalogObjectId: {
                        type: 'string',
                        id: 4,
                        rule: 'optional',
                      },
                      catalogVersion: {
                        type: 'int64',
                        id: 5,
                        rule: 'optional',
                      },
                      catalogClientToken: {
                        type: 'string',
                        id: 14,
                        rule: 'optional',
                      },
                      name: {
                        type: 'string',
                        id: 6,
                        rule: 'optional',
                      },
                      type: {
                        type: 'squareup.omg.model.Order.LineItem.Tax.Type',
                        id: 7,
                        rule: 'optional',
                        options: {
                          default: 'ADDITIVE',
                        },
                      },
                      percentage: {
                        type: 'string',
                        id: 8,
                        rule: 'optional',
                      },
                      amountMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 13,
                        rule: 'optional',
                      },
                      calculationPhase: {
                        type: 'squareup.omg.model.Order.LineItem.Tax.TaxCalculationPhaseScope.TaxCalculationPhase',
                        id: 9,
                        rule: 'optional',
                        options: {
                          default: 'TAX_SUBTOTAL_PHASE',
                        },
                      },
                      appliedMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 11,
                        rule: 'optional',
                      },
                      calculationType: {
                        type: 'squareup.omg.model.Order.LineItem.Tax.CalculationType',
                        id: 15,
                        rule: 'optional',
                        options: {
                          default: 'PERCENTAGE',
                        },
                      },
                      scope: {
                        type: 'squareup.omg.model.Order.LineItem.Tax.Scope',
                        id: 12,
                        rule: 'optional',
                      },
                    },
                  },
                  ReturnDiscount: {
                    fields: {
                      uid: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      sourceDiscountUid: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                      catalogObjectId: {
                        type: 'string',
                        id: 4,
                        rule: 'optional',
                      },
                      catalogVersion: {
                        type: 'int64',
                        id: 5,
                        rule: 'optional',
                      },
                      catalogClientToken: {
                        type: 'string',
                        id: 18,
                        rule: 'optional',
                      },
                      name: {
                        type: 'string',
                        id: 6,
                        rule: 'optional',
                      },
                      type: {
                        type: 'squareup.omg.model.Order.LineItem.Discount.Type',
                        id: 7,
                        rule: 'optional',
                      },
                      percentage: {
                        type: 'string',
                        id: 8,
                        rule: 'optional',
                      },
                      amountMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 9,
                        rule: 'optional',
                      },
                      appliedMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 10,
                        rule: 'optional',
                      },
                      scope: {
                        type: 'squareup.omg.model.Order.LineItem.Discount.Scope',
                        id: 12,
                        rule: 'optional',
                      },
                      quantity: {
                        type: 'string',
                        id: 13,
                        rule: 'optional',
                        options: {
                          default: '1',
                        },
                      },
                      maximumAmountMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 14,
                        rule: 'optional',
                      },
                      modifyTaxBasis: {
                        type: 'squareup.omg.model.Order.LineItem.Discount.ModifyTaxBasis',
                        id: 15,
                        rule: 'optional',
                      },
                      applyPerQuantity: {
                        type: 'bool',
                        id: 16,
                        rule: 'optional',
                      },
                      applicationMethod: {
                        type: 'squareup.omg.model.Order.LineItem.Discount.ApplicationMethod',
                        id: 17,
                        rule: 'optional',
                      },
                    },
                  },
                  ReturnTip: {
                    fields: {
                      uid: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      appliedMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 4,
                        rule: 'optional',
                      },
                      sourceTenderUid: {
                        type: 'string',
                        id: 5,
                        rule: 'optional',
                      },
                      sourceTenderId: {
                        type: 'string',
                        id: 6,
                        rule: 'optional',
                      },
                    },
                  },
                  PricingOptions: {
                    fields: {
                      autoApplyDiscounts: {
                        type: 'bool',
                        id: 1,
                        rule: 'optional',
                      },
                      autoApplyTaxes: {
                        type: 'bool',
                        id: 2,
                        rule: 'optional',
                      },
                      automaticTaxSource: {
                        type: 'squareup.omg.model.Order.PricingOptions.AutomaticTaxSource',
                        id: 3,
                        rule: 'optional',
                        options: {
                          default: 'CATALOG',
                        },
                      },
                      autoApplyServiceCharges: {
                        type: 'bool',
                        id: 4,
                        rule: 'optional',
                      },
                      automaticRatesInclusionType: {
                        type: 'squareup.omg.model.Order.PricingOptions.InclusionType',
                        id: 5,
                        rule: 'optional',
                      },
                    },
                    nested: {
                      AutomaticTaxSource: {
                        values: {
                          AUTOMATIC_TAX_SOURCE_DO_NOT_USE: 0,
                          CATALOG: 1,
                          AUTOMATIC_RATES: 2,
                        },
                      },
                      InclusionType: {
                        values: {
                          INCLUSION_TYPE_DO_NOT_USE: 0,
                          ADDITIVE: 1,
                          INCLUSIVE: 2,
                        },
                      },
                    },
                  },
                  DiscountCode: {
                    fields: {
                      id: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      pricingRuleId: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                      quantity: {
                        type: 'int32',
                        id: 3,
                        rule: 'optional',
                        options: {
                          default: 1,
                        },
                      },
                    },
                  },
                  Reward: {
                    fields: {
                      id: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      rewardTierId: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                      pricingRuleId: {
                        type: 'string',
                        id: 3,
                        rule: 'optional',
                      },
                      pricingRuleReference: {
                        type: 'squareup.connect.v2.merchant_catalog.resources.CatalogObjectReference',
                        id: 4,
                        rule: 'optional',
                      },
                    },
                  },
                  CreditRedemption: {
                    fields: {
                      id: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      packageId: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                      pricingRuleReference: {
                        type: 'squareup.connect.v2.merchant_catalog.resources.CatalogObjectReference',
                        id: 3,
                        rule: 'optional',
                      },
                      quantity: {
                        type: 'string',
                        id: 4,
                        rule: 'optional',
                        options: {
                          default: '1',
                        },
                      },
                    },
                  },
                  Void: {
                    fields: {
                      uid: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      scope: {
                        type: 'squareup.omg.model.Order.Void.Scope',
                        id: 2,
                        rule: 'optional',
                      },
                      reason: {
                        type: 'squareup.omg.model.Order.Void.VoidReason',
                        id: 3,
                        rule: 'optional',
                      },
                      employeeId: {
                        type: 'string',
                        id: 4,
                        rule: 'optional',
                      },
                      deviceCredentialId: {
                        type: 'string',
                        id: 5,
                        rule: 'optional',
                      },
                      createdAt: {
                        type: 'string',
                        id: 6,
                        rule: 'optional',
                      },
                      itemsOld: {
                        type: 'squareup.omg.model.Order.Void.Items',
                        id: 7,
                        rule: 'repeated',
                      },
                      lineItems: {
                        type: 'squareup.omg.model.Order.LineItem',
                        id: 8,
                        rule: 'repeated',
                      },
                    },
                    nested: {
                      Scope: {
                        values: {
                          DO_NOT_USE: 0,
                          LINE_ITEM: 1,
                          ORDER: 2,
                        },
                      },
                      VoidReason: {
                        fields: {
                          catalogObjectId: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                          catalogVersion: {
                            type: 'int64',
                            id: 2,
                            rule: 'optional',
                          },
                          reasonDetail: {
                            type: 'string',
                            id: 3,
                            rule: 'optional',
                          },
                        },
                      },
                      Items: {
                        fields: {
                          uid: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                          lineItemUid: {
                            type: 'string',
                            id: 2,
                            rule: 'optional',
                          },
                          catalogObjectId: {
                            type: 'string',
                            id: 3,
                            rule: 'optional',
                          },
                          catalogVersion: {
                            type: 'int64',
                            id: 4,
                            rule: 'optional',
                          },
                          basePriceMoney: {
                            type: 'squareup.connect.v2.common.Money',
                            id: 5,
                            rule: 'optional',
                          },
                          quantity: {
                            type: 'string',
                            id: 6,
                            rule: 'optional',
                          },
                          quantityUnit: {
                            type: 'squareup.omg.model.Order.QuantityUnit',
                            id: 7,
                            rule: 'optional',
                          },
                          lineItemName: {
                            type: 'string',
                            id: 8,
                            rule: 'optional',
                          },
                          appliedDeposit: {
                            type: 'squareup.omg.model.Order.LineItem.AppliedDeposit',
                            id: 9,
                            rule: 'optional',
                          },
                        },
                      },
                    },
                  },
                  Comp: {
                    fields: {
                      uid: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      discountUid: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                      reason: {
                        type: 'squareup.omg.model.Order.Comp.CompReason',
                        id: 3,
                        rule: 'optional',
                      },
                      employeeId: {
                        type: 'string',
                        id: 4,
                        rule: 'optional',
                      },
                      deviceCredentialId: {
                        type: 'string',
                        id: 5,
                        rule: 'optional',
                      },
                      createdAt: {
                        type: 'string',
                        id: 6,
                        rule: 'optional',
                      },
                    },
                    nested: {
                      CompReason: {
                        fields: {
                          catalogObjectId: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                          catalogVersion: {
                            type: 'int64',
                            id: 2,
                            rule: 'optional',
                          },
                          reasonDetail: {
                            type: 'string',
                            id: 3,
                            rule: 'optional',
                          },
                        },
                      },
                    },
                  },
                  DiningOption: {
                    fields: {
                      name: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      catalogObjectId: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                      catalogClientToken: {
                        type: 'string',
                        id: 6,
                        rule: 'optional',
                      },
                      catalogVersion: {
                        type: 'int64',
                        id: 3,
                        rule: 'optional',
                      },
                      id: {
                        type: 'string',
                        id: 4,
                        rule: 'optional',
                      },
                      ordinal: {
                        type: 'int32',
                        id: 5,
                        rule: 'optional',
                      },
                    },
                  },
                  ProcessingModes: {
                    fields: {
                      creationProcessingMode: {
                        type: 'squareup.omg.model.Order.ProcessingMode',
                        id: 1,
                        rule: 'optional',
                        options: {
                          default: 'ONLINE',
                        },
                      },
                      completionProcessingMode: {
                        type: 'squareup.omg.model.Order.ProcessingMode',
                        id: 2,
                        rule: 'optional',
                        options: {
                          default: 'ONLINE',
                        },
                      },
                    },
                  },
                  ProcessingMode: {
                    values: {
                      PROCESSING_MODE_DO_NOT_USE: 0,
                      ONLINE: 1,
                      OFFLINE: 2,
                    },
                  },
                  OrderApiReferenceIds: {
                    fields: {
                      subscriptionId: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      invoiceId: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                      clientId: {
                        type: 'string',
                        id: 3,
                        rule: 'optional',
                      },
                      reservationId: {
                        type: 'string',
                        id: 4,
                        rule: 'optional',
                      },
                      invoiceTemplateId: {
                        type: 'string',
                        id: 5,
                        rule: 'optional',
                      },
                    },
                  },
                  Deposit: {
                    fields: {
                      uid: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      scope: {
                        type: 'squareup.omg.model.Order.Deposit.Scope',
                        id: 2,
                        rule: 'optional',
                      },
                      depositTotalAmount: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 3,
                        rule: 'optional',
                      },
                      depositSubtotalAmount: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 4,
                        rule: 'optional',
                      },
                      depositTaxAmount: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 5,
                        rule: 'optional',
                      },
                      appliedAmount: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 6,
                        rule: 'optional',
                      },
                      forfeitedAmount: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 7,
                        rule: 'optional',
                      },
                      refundedAmount: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 8,
                        rule: 'optional',
                      },
                      canceledAmount: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 9,
                        rule: 'optional',
                      },
                      appliedTaxAmount: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 10,
                        rule: 'optional',
                      },
                      refundedTaxAmount: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 11,
                        rule: 'optional',
                      },
                      forfeitServiceChargeUid: {
                        type: 'string',
                        id: 12,
                        rule: 'optional',
                      },
                    },
                    nested: {
                      Scope: {
                        values: {
                          DO_NOT_USE: 0,
                          ORDER: 1,
                          LINE_ITEM: 2,
                        },
                      },
                    },
                  },
                  ChannelAttribution: {
                    fields: {
                      uid: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      channelId: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                      type: {
                        type: 'squareup.omg.model.Order.ChannelAttribution.Type',
                        id: 3,
                        rule: 'optional',
                      },
                    },
                    nested: {
                      Type: {
                        values: {
                          CHANNEL_TYPE_DO_NOT_USE: 0,
                          MARKETING: 1,
                          SALES: 2,
                        },
                      },
                    },
                  },
                  ClientTimestamps: {
                    fields: {
                      createdAt: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      updatedAt: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                    },
                  },
                },
              },
              PaymentType: {
                values: {
                  PAYMENT_TYPE_DO_NOT_USE: 0,
                  BALANCE: 1,
                  PARTIAL: 2,
                },
              },
              FutureState: {
                fields: {
                  uid: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  owner: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  entries: {
                    type: 'squareup.omg.model.FutureState.Entry',
                    id: 9,
                    rule: 'repeated',
                  },
                  allowType: {
                    type: 'squareup.omg.model.FutureState.AllowType',
                    id: 10,
                    rule: 'optional',
                    options: {
                      default: 'ALLOW',
                    },
                  },
                },
                nested: {
                  AllowType: {
                    values: {
                      AllowType_DO_NOT_USE: 0,
                      ALLOW: 1,
                      DISALLOW: 2,
                    },
                  },
                  Entry: {
                    fields: {
                      uid: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      path: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                      operation: {
                        type: 'squareup.omg.model.FutureState.Entry.OperationType',
                        id: 3,
                        rule: 'optional',
                        options: {
                          default: 'SET',
                        },
                      },
                      value: {
                        type: 'string',
                        id: 4,
                        rule: 'optional',
                      },
                      updateType: {
                        type: 'squareup.omg.model.FutureState.Entry.UpdateType',
                        id: 5,
                        rule: 'optional',
                        options: {
                          default: 'REQUIRED',
                        },
                      },
                      moneyOnly: {
                        type: 'bool',
                        id: 6,
                        rule: 'optional',
                      },
                    },
                    nested: {
                      OperationType: {
                        values: {
                          OperationType_DO_NOT_USE: 0,
                          SET: 1,
                          REMOVE: 2,
                          CONTAINS: 3,
                        },
                      },
                      UpdateType: {
                        values: {
                          UpdateType_DO_NOT_USE: 0,
                          REQUIRED: 1,
                          OPTIONAL: 2,
                          DISALLOW: 3,
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.IdempotentRequestHistory &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.Source &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.LineItemApiReferenceIds &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.State &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.QuantityUnit &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.PricingBlocklists &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.LineItem &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.LineItem?.nested?.QuantityEntryType &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.LineItem?.nested?.ItemType &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.LineItem?.nested?.Discount &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.LineItem?.nested?.Discount?.nested?.Type &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.LineItem?.nested?.Discount?.nested?.Scope &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.LineItem?.nested?.Discount?.nested?.ModifyTaxBasis &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.LineItem?.nested?.Discount?.nested?.ApplicationMethod &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.LineItem?.nested?.Modifier &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.LineItem?.nested?.Tax &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.LineItem?.nested?.Tax?.nested?.Type &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.LineItem?.nested?.Tax?.nested?.TaxCalculationPhaseScope?.nested
    ?.TaxCalculationPhase &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.LineItem?.nested?.Tax?.nested?.Scope &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.LineItem?.nested?.Tax?.nested?.CalculationType &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.LineItem?.nested?.PricingBlocklists &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.LineItem?.nested?.PricingBlocklists?.nested?.BlockedDiscount &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.LineItem?.nested?.PricingBlocklists?.nested?.BlockedTax &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.LineItem?.nested?.TaxExemption &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.LineItem?.nested?.AppliedDiscount &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.LineItem?.nested?.AppliedTax &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.LineItem?.nested?.AppliedServiceCharge &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.LineItem?.nested?.AppliedDeposit &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.LineItemGroup &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.LineItemGroup?.nested?.Type &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.LineItemGroup?.nested?.ComboChoice &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.AppliedTaxExemption &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.ReturnedQuantity &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.ReturnedQuantity?.nested?.State &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.ReturnedTip &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.ReturnedTip?.nested?.State &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.ReturnedServiceCharge &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.ReturnedServiceCharge?.nested?.State &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.ServiceCharge &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.ServiceCharge?.nested?.CalculationPhase &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.ServiceCharge?.nested?.Type &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.ServiceCharge?.nested?.TreatmentType &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.ServiceCharge?.nested?.ApplicationMethod &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.ServiceCharge?.nested?.Scope &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.RoundingAdjustment &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.MoneyAmounts &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.Return &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.Return?.nested?.Type &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.PaymentGroup &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.PaymentGroup?.nested?.State &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.PaymentGroup?.nested?.DelayCompletionReason &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.RefundGroup &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.RefundGroup?.nested?.State &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.ReturnLineItem &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.ReturnLineItem?.nested?.RestockType &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.ReturnLineItemModifier &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.ReturnServiceCharge &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.ReturnTax &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.ReturnDiscount &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.ReturnTip &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.PricingOptions &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.PricingOptions?.nested?.AutomaticTaxSource &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.PricingOptions?.nested?.InclusionType &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.DiscountCode &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.Reward &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.CreditRedemption &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.Void &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.Void?.nested?.Scope &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.Void?.nested?.VoidReason &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.Void?.nested?.Items &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.Comp &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.Comp?.nested?.CompReason &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.DiningOption &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.ProcessingModes &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.ProcessingMode &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.OrderApiReferenceIds &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.Deposit &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.Deposit?.nested?.Scope &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.ChannelAttribution &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.ChannelAttribution?.nested?.Type &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.Order?.nested
    ?.ClientTimestamps &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.PaymentType &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.FutureState &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.FutureState
    ?.nested?.AllowType &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.FutureState
    ?.nested?.Entry &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.FutureState
    ?.nested?.Entry?.nested?.OperationType &&
  !$root.nested?.squareup?.nested?.omg?.nested?.model?.nested?.FutureState
    ?.nested?.Entry?.nested?.UpdateType
) {
  $root.addJSON(jsonData);
}

/**
 * --
 * @desc Contains all information related to a single order to process with Square,
 * including line items that specify the products to purchase. `Order` objects also
 * include information about any associated tenders, refunds, and returns.
 *
 * All Connect V2 Transactions have all been converted to Orders including all associated
 * itemization data.
 * --
 *
 * SOURCE Order @ squareup/omg/model/orders.proto at 61:1
 */
export const Order = $root.lookupType('squareup.omg.model.Order');
fixType(Order, {
  locationId: { notEmpty: true },
});
Builder.createAndAttachToType(Order);
/**
 * --
 * @desc Idempotent requests that were run on the order
 * --
 *
 * SOURCE IdempotentRequestHistory @ squareup/omg/model/orders.proto at 755:3
 */
Order.IdempotentRequestHistory = $root.lookupType(
  'squareup.omg.model.Order.IdempotentRequestHistory'
);
fixType(Order.IdempotentRequestHistory);
Builder.createAndAttachToType(Order.IdempotentRequestHistory);
/**
 * --
 * @desc Represents the origination details of an order.
 * --
 *
 * SOURCE Source @ squareup/omg/model/orders.proto at 774:3
 */
Order.Source = $root.lookupType('squareup.omg.model.Order.Source');
fixType(Order.Source);
Builder.createAndAttachToType(Order.Source);
/**
 * SOURCE LineItemApiReferenceIds @ squareup/omg/model/orders.proto at 835:3
 */
Order.LineItemApiReferenceIds = $root.lookupType(
  'squareup.omg.model.Order.LineItemApiReferenceIds'
);
fixType(Order.LineItemApiReferenceIds);
Builder.createAndAttachToType(Order.LineItemApiReferenceIds);
/**
 * --
 * @desc The state of the order.
 * --
 *
 * SOURCE State @ squareup/omg/model/orders.proto at 852:3
 */
Order.State = $root.lookupEnum('squareup.omg.model.Order.State').values;
/**
 * --
 * @desc Contains the measurement unit for a quantity and a precision that
 * specifies the number of digits after the decimal point for decimal quantities.
 * --
 *
 * SOURCE QuantityUnit @ squareup/omg/model/orders.proto at 883:3
 */
Order.QuantityUnit = $root.lookupType('squareup.omg.model.Order.QuantityUnit');
fixType(Order.QuantityUnit);
Builder.createAndAttachToType(Order.QuantityUnit);
/**
 * --
 * @desc Describes pricing adjustments that are blocked from automatic application to an order.
 * --
 *
 * SOURCE PricingBlocklists @ squareup/omg/model/orders.proto at 923:3
 */
Order.PricingBlocklists = $root.lookupType(
  'squareup.omg.model.Order.PricingBlocklists'
);
fixType(Order.PricingBlocklists);
Builder.createAndAttachToType(Order.PricingBlocklists);
/**
 * --
 * @desc Represents a line item in an order. Each line item describes a different
 * product to purchase, with its own quantity and price details.
 * --
 *
 * SOURCE LineItem @ squareup/omg/model/orders.proto at 941:3
 */
Order.LineItem = $root.lookupType('squareup.omg.model.Order.LineItem');
fixType(Order.LineItem, {
  quantity: { notEmpty: true },
});
Builder.createAndAttachToType(Order.LineItem);
/**
 * @desc How the quantity was added to the line item.
 *
 * SOURCE QuantityEntryType @ squareup/omg/model/orders.proto at 1283:5
 */
Order.LineItem.QuantityEntryType = $root.lookupEnum(
  'squareup.omg.model.Order.LineItem.QuantityEntryType'
).values;
/**
 * reserving for next ID.
 * @desc Represents the line item type.
 *
 * SOURCE ItemType @ squareup/omg/model/orders.proto at 1461:5
 */
Order.LineItem.ItemType = $root.lookupEnum(
  'squareup.omg.model.Order.LineItem.ItemType'
).values;
/**
 * --
 * @desc Represents a discount that applies to one or more line items in an
 * order.
 *
 * Fixed-amount, order-scoped discounts are distributed across all non-zero line item totals.
 * The amount distributed to each line item is relative to the
 * amount contributed by the item to the order subtotal.
 * --
 *
 * SOURCE Discount @ squareup/omg/model/orders.proto at 1497:5
 */
Order.LineItem.Discount = $root.lookupType(
  'squareup.omg.model.Order.LineItem.Discount'
);
fixType(Order.LineItem.Discount);
Builder.createAndAttachToType(Order.LineItem.Discount);
/**
 * reserved for next id
 * --
 * @desc Indicates how the discount is applied to the associated line item or order.
 * --
 *
 * SOURCE Type @ squareup/omg/model/orders.proto at 1746:7
 */
Order.LineItem.Discount.Type = $root.lookupEnum(
  'squareup.omg.model.Order.LineItem.Discount.Type'
).values;
/**
 * --
 * @desc Indicates whether this is a line-item or order-level discount.
 * --
 *
 * SOURCE Scope @ squareup/omg/model/orders.proto at 1784:7
 */
Order.LineItem.Discount.Scope = $root.lookupEnum(
  'squareup.omg.model.Order.LineItem.Discount.Scope'
).values;
/**
 * --
 * @desc Indicates whether the discount should reduce the price used to calculate tax.
 * --
 *
 * SOURCE ModifyTaxBasis @ squareup/omg/model/orders.proto at 1806:7
 */
Order.LineItem.Discount.ModifyTaxBasis = $root.lookupEnum(
  'squareup.omg.model.Order.LineItem.Discount.ModifyTaxBasis'
).values;
/**
 * --
 * @desc Categorizes why a discount was applied to an order or line item.
 * --
 *
 * SOURCE ApplicationMethod @ squareup/omg/model/orders.proto at 1823:7
 */
Order.LineItem.Discount.ApplicationMethod = $root.lookupEnum(
  'squareup.omg.model.Order.LineItem.Discount.ApplicationMethod'
).values;
/**
 * --
 * @desc A [CatalogModifier](entity:CatalogModifier).
 * --
 *
 * SOURCE Modifier @ squareup/omg/model/orders.proto at 1850:5
 */
Order.LineItem.Modifier = $root.lookupType(
  'squareup.omg.model.Order.LineItem.Modifier'
);
fixType(Order.LineItem.Modifier);
Builder.createAndAttachToType(Order.LineItem.Modifier);
/**
 * --
 * @desc Represents a tax that applies to one or more line item in the order.
 *
 * Fixed-amount, order-scoped taxes are distributed across all non-zero line item totals.
 * The amount distributed to each line item is relative to the amount the item
 * contributes to the order subtotal.
 * --
 *
 * SOURCE Tax @ squareup/omg/model/orders.proto at 1977:5
 */
Order.LineItem.Tax = $root.lookupType('squareup.omg.model.Order.LineItem.Tax');
fixType(Order.LineItem.Tax);
Builder.createAndAttachToType(Order.LineItem.Tax);
/**
 * --
 * @desc Indicates how the tax is applied to the associated line item or order.
 * --
 *
 * SOURCE Type @ squareup/omg/model/orders.proto at 1982:7
 */
Order.LineItem.Tax.Type = $root.lookupEnum(
  'squareup.omg.model.Order.LineItem.Tax.Type'
).values;
/**
 * SOURCE TaxCalculationPhaseScope @ squareup/omg/model/orders.proto at 2007:7
 */
Order.LineItem.Tax.TaxCalculationPhaseScope = {};
/**
 * SOURCE TaxCalculationPhase @ squareup/omg/model/orders.proto at 2010:9
 */
Order.LineItem.Tax.TaxCalculationPhaseScope.TaxCalculationPhase =
  $root.lookupEnum(
    'squareup.omg.model.Order.LineItem.Tax.TaxCalculationPhaseScope.TaxCalculationPhase'
  ).values;
/**
 * --
 * @desc Indicates whether this is a line-item or order-level tax.
 * --
 *
 * SOURCE Scope @ squareup/omg/model/orders.proto at 2020:7
 */
Order.LineItem.Tax.Scope = $root.lookupEnum(
  'squareup.omg.model.Order.LineItem.Tax.Scope'
).values;
/**
 * SOURCE CalculationType @ squareup/omg/model/orders.proto at 2176:7
 */
Order.LineItem.Tax.CalculationType = $root.lookupEnum(
  'squareup.omg.model.Order.LineItem.Tax.CalculationType'
).values;
/**
 * --
 * @desc Describes pricing adjustments that are blocked from automatic
 * application to a line item. For more information, see
 * [Apply Taxes and Discounts](https://developer.squareup.com/docs/orders-api/apply-taxes-and-discounts).
 * --
 *
 * SOURCE PricingBlocklists @ squareup/omg/model/orders.proto at 2212:5
 */
Order.LineItem.PricingBlocklists = $root.lookupType(
  'squareup.omg.model.Order.LineItem.PricingBlocklists'
);
fixType(Order.LineItem.PricingBlocklists);
Builder.createAndAttachToType(Order.LineItem.PricingBlocklists);
/**
 * --
 * @desc A discount to block from applying to a line item. The discount must be
 * identified by either `discount_uid` or `discount_catalog_object_id`, but not both.
 * --
 *
 * SOURCE BlockedDiscount @ squareup/omg/model/orders.proto at 2233:7
 */
Order.LineItem.PricingBlocklists.BlockedDiscount = $root.lookupType(
  'squareup.omg.model.Order.LineItem.PricingBlocklists.BlockedDiscount'
);
fixType(Order.LineItem.PricingBlocklists.BlockedDiscount);
Builder.createAndAttachToType(Order.LineItem.PricingBlocklists.BlockedDiscount);
/**
 * --
 * @desc A tax to block from applying to a line item. The tax must be
 * identified by either `tax_uid` or `tax_catalog_object_id`, but not both.
 * --
 *
 * SOURCE BlockedTax @ squareup/omg/model/orders.proto at 2265:7
 */
Order.LineItem.PricingBlocklists.BlockedTax = $root.lookupType(
  'squareup.omg.model.Order.LineItem.PricingBlocklists.BlockedTax'
);
fixType(Order.LineItem.PricingBlocklists.BlockedTax);
Builder.createAndAttachToType(Order.LineItem.PricingBlocklists.BlockedTax);
/**
 * --
 * @desc Represents a tax exemption that applies to one or more line item in the order.
 * --
 *
 * SOURCE TaxExemption @ squareup/omg/model/orders.proto at 2296:5
 */
Order.LineItem.TaxExemption = $root.lookupType(
  'squareup.omg.model.Order.LineItem.TaxExemption'
);
fixType(Order.LineItem.TaxExemption);
Builder.createAndAttachToType(Order.LineItem.TaxExemption);
/**
 * --
 * @desc Represents an applied portion of a discount to a line item in an order.
 *
 * Order scoped discounts have automatically applied discounts present for each line item.
 * Line-item scoped discounts must have applied discounts added manually for any applicable line
 * items. The corresponding applied money is automatically computed based on participating
 * line items.
 * --
 *
 * SOURCE AppliedDiscount @ squareup/omg/model/orders.proto at 2333:5
 */
Order.LineItem.AppliedDiscount = $root.lookupType(
  'squareup.omg.model.Order.LineItem.AppliedDiscount'
);
fixType(Order.LineItem.AppliedDiscount, {
  discountUid: { notEmpty: true },
});
Builder.createAndAttachToType(Order.LineItem.AppliedDiscount);
/**
 * --
 * @desc Represents an applied portion of a tax to a line item in an order.
 *
 * Order-scoped taxes automatically include the applied taxes in each line item.
 * Line item taxes must be referenced from any applicable line items.
 * The corresponding applied money is automatically computed, based on the
 * set of participating line items.
 * --
 *
 * SOURCE AppliedTax @ squareup/omg/model/orders.proto at 2375:5
 */
Order.LineItem.AppliedTax = $root.lookupType(
  'squareup.omg.model.Order.LineItem.AppliedTax'
);
fixType(Order.LineItem.AppliedTax, {
  taxUid: { notEmpty: true },
});
Builder.createAndAttachToType(Order.LineItem.AppliedTax);
/**
 * SOURCE AppliedServiceCharge @ squareup/omg/model/orders.proto at 2408:5
 */
Order.LineItem.AppliedServiceCharge = $root.lookupType(
  'squareup.omg.model.Order.LineItem.AppliedServiceCharge'
);
fixType(Order.LineItem.AppliedServiceCharge, {
  serviceChargeUid: { notEmpty: true },
});
Builder.createAndAttachToType(Order.LineItem.AppliedServiceCharge);
/**
 * --
 * @desc Represents a deposit applied towards a line item.
 * --
 *
 * SOURCE AppliedDeposit @ squareup/omg/model/orders.proto at 2443:5
 */
Order.LineItem.AppliedDeposit = $root.lookupType(
  'squareup.omg.model.Order.LineItem.AppliedDeposit'
);
fixType(Order.LineItem.AppliedDeposit);
Builder.createAndAttachToType(Order.LineItem.AppliedDeposit);
/**
 * SOURCE LineItemGroup @ squareup/omg/model/orders.proto at 2460:3
 */
Order.LineItemGroup = $root.lookupType(
  'squareup.omg.model.Order.LineItemGroup'
);
fixType(Order.LineItemGroup);
Builder.createAndAttachToType(Order.LineItemGroup);
/**
 * SOURCE Type @ squareup/omg/model/orders.proto at 2519:5
 */
Order.LineItemGroup.Type = $root.lookupEnum(
  'squareup.omg.model.Order.LineItemGroup.Type'
).values;
/**
 * SOURCE ComboChoice @ squareup/omg/model/orders.proto at 2524:5
 */
Order.LineItemGroup.ComboChoice = $root.lookupType(
  'squareup.omg.model.Order.LineItemGroup.ComboChoice'
);
fixType(Order.LineItemGroup.ComboChoice);
Builder.createAndAttachToType(Order.LineItemGroup.ComboChoice);
/**
 * --
 * @desc Represents a tax exemption that applies to one or more line item in the order.
 * --
 *
 * SOURCE AppliedTaxExemption @ squareup/omg/model/orders.proto at 2568:3
 */
Order.AppliedTaxExemption = $root.lookupType(
  'squareup.omg.model.Order.AppliedTaxExemption'
);
fixType(Order.AppliedTaxExemption, {
  taxExemptionUid: { notEmpty: true },
});
Builder.createAndAttachToType(Order.AppliedTaxExemption);
/**
 * --
 * @desc Represents a return quantity that applies to a line items/custom amount in an order.
 * --
 *
 * SOURCE ReturnedQuantity @ squareup/omg/model/orders.proto at 2597:3
 */
Order.ReturnedQuantity = $root.lookupType(
  'squareup.omg.model.Order.ReturnedQuantity'
);
fixType(Order.ReturnedQuantity);
Builder.createAndAttachToType(Order.ReturnedQuantity);
/**
 * --
 * @desc The current state of this returned quantity.
 * --
 *
 * SOURCE State @ squareup/omg/model/orders.proto at 2643:5
 */
Order.ReturnedQuantity.State = $root.lookupEnum(
  'squareup.omg.model.Order.ReturnedQuantity.State'
).values;
/**
 * --
 * @desc Represents a return tip that happened for a tender on the order
 * --
 *
 * SOURCE ReturnedTip @ squareup/omg/model/orders.proto at 2666:3
 */
Order.ReturnedTip = $root.lookupType('squareup.omg.model.Order.ReturnedTip');
fixType(Order.ReturnedTip);
Builder.createAndAttachToType(Order.ReturnedTip);
/**
 * --
 * @desc The current state of this returned tip
 * --
 *
 * SOURCE State @ squareup/omg/model/orders.proto at 2707:5
 */
Order.ReturnedTip.State = $root.lookupEnum(
  'squareup.omg.model.Order.ReturnedTip.State'
).values;
/**
 * --
 * @desc Represents a returned service charge
 * --
 *
 * SOURCE ReturnedServiceCharge @ squareup/omg/model/orders.proto at 2730:3
 */
Order.ReturnedServiceCharge = $root.lookupType(
  'squareup.omg.model.Order.ReturnedServiceCharge'
);
fixType(Order.ReturnedServiceCharge);
Builder.createAndAttachToType(Order.ReturnedServiceCharge);
/**
 * --
 * @desc The current state of this returned service charge
 * --
 *
 * SOURCE State @ squareup/omg/model/orders.proto at 2766:5
 */
Order.ReturnedServiceCharge.State = $root.lookupEnum(
  'squareup.omg.model.Order.ReturnedServiceCharge.State'
).values;
/**
 * --
 * @desc Represents a service charge applied to an order.
 * --
 *
 * SOURCE ServiceCharge @ squareup/omg/model/orders.proto at 2789:3
 */
Order.ServiceCharge = $root.lookupType(
  'squareup.omg.model.Order.ServiceCharge'
);
fixType(Order.ServiceCharge);
Builder.createAndAttachToType(Order.ServiceCharge);
/**
 * --
 * @desc Represents a phase in the process of calculating order totals.
 * Service charges are applied after the indicated phase.
 *
 * [Read more about how order totals are calculated.](https://developer.squareup.com/docs/orders-api/how-it-works#how-totals-are-calculated)
 * --
 *
 * SOURCE CalculationPhase @ squareup/omg/model/orders.proto at 2891:5
 */
Order.ServiceCharge.CalculationPhase = $root.lookupEnum(
  'squareup.omg.model.Order.ServiceCharge.CalculationPhase'
).values;
/**
 * SOURCE Type @ squareup/omg/model/orders.proto at 3008:5
 */
Order.ServiceCharge.Type = $root.lookupEnum(
  'squareup.omg.model.Order.ServiceCharge.Type'
).values;
/**
 * --
 * @desc Indicates whether the service charge will be treated as a value-holding line item or
 * apportioned toward a line item.
 * --
 *
 * SOURCE TreatmentType @ squareup/omg/model/orders.proto at 3040:5
 */
Order.ServiceCharge.TreatmentType = $root.lookupEnum(
  'squareup.omg.model.Order.ServiceCharge.TreatmentType'
).values;
/**
 * --
 * @desc Categorizes why a service charge was applied to an order or line item.
 * --
 *
 * SOURCE ApplicationMethod @ squareup/omg/model/orders.proto at 3081:5
 */
Order.ServiceCharge.ApplicationMethod = $root.lookupEnum(
  'squareup.omg.model.Order.ServiceCharge.ApplicationMethod'
).values;
/**
 * --
 * @desc Indicates whether this is a line-item or order-level apportioned
 * service charge.
 * --
 *
 * SOURCE Scope @ squareup/omg/model/orders.proto at 3112:5
 */
Order.ServiceCharge.Scope = $root.lookupEnum(
  'squareup.omg.model.Order.ServiceCharge.Scope'
).values;
/**
 * --
 * @desc A rounding adjustment of the money being returned. Commonly used to apply cash rounding
 * when the minimum unit of the account is smaller than the lowest physical denomination of the currency.
 * --
 *
 * SOURCE RoundingAdjustment @ squareup/omg/model/orders.proto at 4278:3
 */
Order.RoundingAdjustment = $root.lookupType(
  'squareup.omg.model.Order.RoundingAdjustment'
);
fixType(Order.RoundingAdjustment);
Builder.createAndAttachToType(Order.RoundingAdjustment);
/**
 * --
 * @desc A collection of various money amounts.
 * --
 *
 * SOURCE MoneyAmounts @ squareup/omg/model/orders.proto at 4301:3
 */
Order.MoneyAmounts = $root.lookupType('squareup.omg.model.Order.MoneyAmounts');
fixType(Order.MoneyAmounts);
Builder.createAndAttachToType(Order.MoneyAmounts);
/**
 * --
 * @desc The set of line items, service charges, taxes, discounts, tips, and other items being returned in an order.
 * --
 *
 * SOURCE Return @ squareup/omg/model/orders.proto at 4329:3
 */
Order.Return = $root.lookupType('squareup.omg.model.Order.Return');
fixType(Order.Return);
Builder.createAndAttachToType(Order.Return);
/**
 * next ID
 *
 * SOURCE Type @ squareup/omg/model/orders.proto at 4399:5
 */
Order.Return.Type = $root.lookupEnum(
  'squareup.omg.model.Order.Return.Type'
).values;
/**
 * SOURCE PaymentGroup @ squareup/omg/model/orders.proto at 4414:3
 */
Order.PaymentGroup = $root.lookupType('squareup.omg.model.Order.PaymentGroup');
fixType(Order.PaymentGroup);
Builder.createAndAttachToType(Order.PaymentGroup);
/**
 * SOURCE State @ squareup/omg/model/orders.proto at 4463:5
 */
Order.PaymentGroup.State = $root.lookupEnum(
  'squareup.omg.model.Order.PaymentGroup.State'
).values;
/**
 * --
 * @desc The reason for the delayed completion of the payment group.
 * --
 *
 * SOURCE DelayCompletionReason @ squareup/omg/model/orders.proto at 4488:5
 */
Order.PaymentGroup.DelayCompletionReason = $root.lookupEnum(
  'squareup.omg.model.Order.PaymentGroup.DelayCompletionReason'
).values;
/**
 * SOURCE RefundGroup @ squareup/omg/model/orders.proto at 4532:3
 */
Order.RefundGroup = $root.lookupType('squareup.omg.model.Order.RefundGroup');
fixType(Order.RefundGroup);
Builder.createAndAttachToType(Order.RefundGroup);
/**
 * SOURCE State @ squareup/omg/model/orders.proto at 4547:5
 */
Order.RefundGroup.State = $root.lookupEnum(
  'squareup.omg.model.Order.RefundGroup.State'
).values;
/**
 * --
 * @desc The line item being returned in an order.
 * --
 *
 * SOURCE ReturnLineItem @ squareup/omg/model/orders.proto at 4566:3
 */
Order.ReturnLineItem = $root.lookupType(
  'squareup.omg.model.Order.ReturnLineItem'
);
fixType(Order.ReturnLineItem, {
  quantity: { notEmpty: true },
});
Builder.createAndAttachToType(Order.ReturnLineItem);
/**
 * SOURCE RestockType @ squareup/omg/model/orders.proto at 4799:5
 */
Order.ReturnLineItem.RestockType = $root.lookupEnum(
  'squareup.omg.model.Order.ReturnLineItem.RestockType'
).values;
/**
 * --
 * @desc A line item modifier being returned.
 * --
 *
 * SOURCE ReturnLineItemModifier @ squareup/omg/model/orders.proto at 4891:3
 */
Order.ReturnLineItemModifier = $root.lookupType(
  'squareup.omg.model.Order.ReturnLineItemModifier'
);
fixType(Order.ReturnLineItemModifier);
Builder.createAndAttachToType(Order.ReturnLineItemModifier);
/**
 * --
 * @desc Represents the service charge applied to the original order.
 * --
 *
 * SOURCE ReturnServiceCharge @ squareup/omg/model/orders.proto at 4974:3
 */
Order.ReturnServiceCharge = $root.lookupType(
  'squareup.omg.model.Order.ReturnServiceCharge'
);
fixType(Order.ReturnServiceCharge);
Builder.createAndAttachToType(Order.ReturnServiceCharge);
/**
 * --
 * @desc Represents a tax being returned that applies to one or more return line items in an order.
 *
 * Fixed-amount, order-scoped taxes are distributed across all non-zero return line item totals.
 * The amount distributed to each return line item is relative to that item’s contribution to the
 * order subtotal.
 * --
 *
 * SOURCE ReturnTax @ squareup/omg/model/orders.proto at 5174:3
 */
Order.ReturnTax = $root.lookupType('squareup.omg.model.Order.ReturnTax');
fixType(Order.ReturnTax);
Builder.createAndAttachToType(Order.ReturnTax);
/**
 * --
 * @desc Represents a discount being returned that applies to one or more return line items in an
 * order.
 *
 * Fixed-amount, order-scoped discounts are distributed across all non-zero return line item totals.
 * The amount distributed to each return line item is relative to that item’s contribution to the
 * order subtotal.
 * --
 *
 * SOURCE ReturnDiscount @ squareup/omg/model/orders.proto at 5283:3
 */
Order.ReturnDiscount = $root.lookupType(
  'squareup.omg.model.Order.ReturnDiscount'
);
fixType(Order.ReturnDiscount);
Builder.createAndAttachToType(Order.ReturnDiscount);
/**
 * --
 * @desc A tip being returned.
 * --
 *
 * SOURCE ReturnTip @ squareup/omg/model/orders.proto at 5431:3
 */
Order.ReturnTip = $root.lookupType('squareup.omg.model.Order.ReturnTip');
fixType(Order.ReturnTip);
Builder.createAndAttachToType(Order.ReturnTip);
/**
 * --
 * @desc Pricing options for an order. The options affect how the order's price is calculated.
 * They can be used, for example, to apply automatic price adjustments that are based on preconfigured
 * [pricing rules](entity:CatalogPricingRule).
 * --
 *
 * SOURCE PricingOptions @ squareup/omg/model/orders.proto at 5474:3
 */
Order.PricingOptions = $root.lookupType(
  'squareup.omg.model.Order.PricingOptions'
);
fixType(Order.PricingOptions);
Builder.createAndAttachToType(Order.PricingOptions);
/**
 * SOURCE AutomaticTaxSource @ squareup/omg/model/orders.proto at 5489:5
 */
Order.PricingOptions.AutomaticTaxSource = $root.lookupEnum(
  'squareup.omg.model.Order.PricingOptions.AutomaticTaxSource'
).values;
/**
 * SOURCE InclusionType @ squareup/omg/model/orders.proto at 5525:5
 */
Order.PricingOptions.InclusionType = $root.lookupEnum(
  'squareup.omg.model.Order.PricingOptions.InclusionType'
).values;
/**
 * --
 * @desc Represents a Discount Code that may be applied to an Order if the necessary
 * criteria are met. Discount Codes are created through the Discount Codes API.
 * --
 *
 * SOURCE DiscountCode @ squareup/omg/model/orders.proto at 5558:3
 */
Order.DiscountCode = $root.lookupType('squareup.omg.model.Order.DiscountCode');
fixType(Order.DiscountCode, {
  id: { notEmpty: true },
});
Builder.createAndAttachToType(Order.DiscountCode);
/**
 * --
 * @desc Represents a reward that can be applied to an order if the necessary
 * reward tier criteria are met. Rewards are created through the Loyalty API.
 * --
 *
 * SOURCE Reward @ squareup/omg/model/orders.proto at 5583:3
 */
Order.Reward = $root.lookupType('squareup.omg.model.Order.Reward');
fixType(Order.Reward, {
  id: { notEmpty: true },
  rewardTierId: { notEmpty: true },
});
Builder.createAndAttachToType(Order.Reward);
/**
 * --
 * @desc Represents a Credit Redemption that may be applied to an Order if the necessary
 * criteria are met. Credit Redemptions are created through the Credit Redemptions API.
 * --
 *
 * SOURCE CreditRedemption @ squareup/omg/model/orders.proto at 5620:3
 */
Order.CreditRedemption = $root.lookupType(
  'squareup.omg.model.Order.CreditRedemption'
);
fixType(Order.CreditRedemption, {
  id: { notEmpty: true },
  pricingRuleReference: { required: true },
});
Builder.createAndAttachToType(Order.CreditRedemption);
/**
 * --
 * @desc Represents a void on an order.
 * --
 *
 * SOURCE Void @ squareup/omg/model/orders.proto at 5653:3
 */
Order.Void = $root.lookupType('squareup.omg.model.Order.Void');
fixType(Order.Void);
Builder.createAndAttachToType(Order.Void);
/**
 * SOURCE Scope @ squareup/omg/model/orders.proto at 5668:5
 */
Order.Void.Scope = $root.lookupEnum(
  'squareup.omg.model.Order.Void.Scope'
).values;
/**
 * SOURCE VoidReason @ squareup/omg/model/orders.proto at 5693:5
 */
Order.Void.VoidReason = $root.lookupType(
  'squareup.omg.model.Order.Void.VoidReason'
);
fixType(Order.Void.VoidReason, {
  catalogObjectId: { notEmpty: true },
  reasonDetail: { notEmpty: true },
});
Builder.createAndAttachToType(Order.Void.VoidReason);
/**
 * --
 * @desc Stores details about the line items that were voided in this operation.
 *
 * SOURCE Items @ squareup/omg/model/orders.proto at 5755:5
 */
Order.Void.Items = $root.lookupType('squareup.omg.model.Order.Void.Items');
fixType(Order.Void.Items, {
  quantity: { notEmpty: true },
});
Builder.createAndAttachToType(Order.Void.Items);
/**
 * --
 * @desc Represents a comp on an order.
 * --
 *
 * SOURCE Comp @ squareup/omg/model/orders.proto at 5826:3
 */
Order.Comp = $root.lookupType('squareup.omg.model.Order.Comp');
fixType(Order.Comp);
Builder.createAndAttachToType(Order.Comp);
/**
 * SOURCE CompReason @ squareup/omg/model/orders.proto at 5850:5
 */
Order.Comp.CompReason = $root.lookupType(
  'squareup.omg.model.Order.Comp.CompReason'
);
fixType(Order.Comp.CompReason, {
  catalogObjectId: { notEmpty: true },
});
Builder.createAndAttachToType(Order.Comp.CompReason);
/**
 * --
 * @desc Represents a dining option that can be applied either to the order top level or to
 * individual line items. Dining options are created through the Catalog API.
 * --
 *
 * SOURCE DiningOption @ squareup/omg/model/orders.proto at 5907:3
 */
Order.DiningOption = $root.lookupType('squareup.omg.model.Order.DiningOption');
fixType(Order.DiningOption);
Builder.createAndAttachToType(Order.DiningOption);
/**
 * --
 * @desc The processing modes for order creation and completion.
 * --
 *
 * SOURCE ProcessingModes @ squareup/omg/model/orders.proto at 5950:3
 */
Order.ProcessingModes = $root.lookupType(
  'squareup.omg.model.Order.ProcessingModes'
);
fixType(Order.ProcessingModes);
Builder.createAndAttachToType(Order.ProcessingModes);
/**
 * The processing mode enum indicates the order was created/completed online or offline
 *
 * SOURCE ProcessingMode @ squareup/omg/model/orders.proto at 5983:3
 */
Order.ProcessingMode = $root.lookupEnum(
  'squareup.omg.model.Order.ProcessingMode'
).values;
/**
 * SOURCE OrderApiReferenceIds @ squareup/omg/model/orders.proto at 5995:3
 */
Order.OrderApiReferenceIds = $root.lookupType(
  'squareup.omg.model.Order.OrderApiReferenceIds'
);
fixType(Order.OrderApiReferenceIds);
Builder.createAndAttachToType(Order.OrderApiReferenceIds);
/**
 * --
 * @desc Represents a deposit on the order.
 * --
 *
 * SOURCE Deposit @ squareup/omg/model/orders.proto at 6043:3
 */
Order.Deposit = $root.lookupType('squareup.omg.model.Order.Deposit');
fixType(Order.Deposit);
Builder.createAndAttachToType(Order.Deposit);
/**
 * Determines if this deposit applies to the whole order or an individual line item.
 *
 * SOURCE Scope @ squareup/omg/model/orders.proto at 6134:5
 */
Order.Deposit.Scope = $root.lookupEnum(
  'squareup.omg.model.Order.Deposit.Scope'
).values;
/**
 * SOURCE ChannelAttribution @ squareup/omg/model/orders.proto at 6141:3
 */
Order.ChannelAttribution = $root.lookupType(
  'squareup.omg.model.Order.ChannelAttribution'
);
fixType(Order.ChannelAttribution);
Builder.createAndAttachToType(Order.ChannelAttribution);
/**
 * --
 * @desc Indicates whether this is a line-item or order-level channel.
 * --
 *
 * SOURCE Type @ squareup/omg/model/orders.proto at 6161:5
 */
Order.ChannelAttribution.Type = $root.lookupEnum(
  'squareup.omg.model.Order.ChannelAttribution.Type'
).values;
/**
 * SOURCE ClientTimestamps @ squareup/omg/model/orders.proto at 6269:3
 */
Order.ClientTimestamps = $root.lookupType(
  'squareup.omg.model.Order.ClientTimestamps'
);
fixType(Order.ClientTimestamps);
Builder.createAndAttachToType(Order.ClientTimestamps);
/**
 * SOURCE PaymentType @ squareup/omg/model/orders.proto at 6388:1
 */
export const PaymentType = $root.lookupEnum(
  'squareup.omg.model.PaymentType'
).values;
/**
 * SOURCE FutureState @ squareup/omg/model/orders.proto at 6418:1
 */
export const FutureState = $root.lookupType('squareup.omg.model.FutureState');
fixType(FutureState, {
  allowType: { required: true },
});
Builder.createAndAttachToType(FutureState);
/**
 * SOURCE AllowType @ squareup/omg/model/orders.proto at 6448:3
 */
FutureState.AllowType = $root.lookupEnum(
  'squareup.omg.model.FutureState.AllowType'
).values;
/**
 * SOURCE Entry @ squareup/omg/model/orders.proto at 6460:3
 */
FutureState.Entry = $root.lookupType('squareup.omg.model.FutureState.Entry');
fixType(FutureState.Entry, {
  path: { required: true },
  operation: { required: true },
  updateType: { required: true },
});
Builder.createAndAttachToType(FutureState.Entry);
/**
 * SOURCE OperationType @ squareup/omg/model/orders.proto at 6501:5
 */
FutureState.Entry.OperationType = $root.lookupEnum(
  'squareup.omg.model.FutureState.Entry.OperationType'
).values;
/**
 * SOURCE UpdateType @ squareup/omg/model/orders.proto at 6516:5
 */
FutureState.Entry.UpdateType = $root.lookupEnum(
  'squareup.omg.model.FutureState.Entry.UpdateType'
).values;
