import { MarketButton, MarketDivider } from '@market/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UNSUBSCRIBE_VALUES } from '.';
import nonTranslatableStrings from 'utils/nonTranslatableStrings';
import { viewAutoReceiptResubscribed } from 'services/tracking/cdp/events/singleClickAutomaticReceiptPreferences';
import useCdpPageTracking, {
  Client_Namespace,
} from 'utils/custom-react-hooks/useCdpPageTracking';

interface ResubscribedProps {
  receiptToken: string;
  unsubscribedFrom: UNSUBSCRIBE_VALUES.MERCHANT | UNSUBSCRIBE_VALUES.GLOBAL;
  obfuscatedCard: string;
  obfuscatedEmail: string;
  merchantName: string;
}

const Resubscribed: React.FC<ResubscribedProps> = (props) => {
  const { t } = useTranslation();

  /* CDP page view tracking */

  useCdpPageTracking({
    properties: viewAutoReceiptResubscribed(
      props.receiptToken,
      props.unsubscribedFrom === UNSUBSCRIBE_VALUES.MERCHANT
        ? 'merchant'
        : 'all'
    ),
    isLoading: false,
    didLoadingFail: false,
    client: Client_Namespace.UNSUBSCRIBE,
  });

  /* Content */

  return (
    <>
      <h2>
        {t(
          'preference.components.unsubscribe.automaticReceipts.resubscribe.title'
        )}
      </h2>
      <div>
        {props.unsubscribedFrom === UNSUBSCRIBE_VALUES.MERCHANT
          ? t(
              'preference.components.unsubscribe.automaticReceipts.resubscribeMerchant.content',
              {
                merchantName: props.merchantName,
                obfuscatedEmail: props.obfuscatedEmail,
              }
            )
          : t(
              'preference.components.unsubscribe.automaticReceipts.resubscribeGlobal.content',
              {
                merchantName: nonTranslatableStrings.square,
                card: props.obfuscatedCard,
              }
            )}
      </div>

      <MarketDivider />

      <h3>{t('preference.components.loginCard.title.updatePreference')}</h3>

      <div>{t('unauthenticatedVerifyEmail.success.subtitle2')}</div>

      <MarketButton href={'/'} className={'mt-7'}>
        {t('unauthenticatedVerifyEmail.signIn')}
      </MarketButton>
    </>
  );
};

export default Resubscribed;
