/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      common: {
        nested: {
          client: {
            nested: {
              Version: {
                fields: {
                  major: {
                    type: 'uint32',
                    id: 1,
                    rule: 'optional',
                  },
                  minor: {
                    type: 'uint32',
                    id: 2,
                    rule: 'optional',
                  },
                  revision: {
                    type: 'uint32',
                    id: 3,
                    rule: 'optional',
                  },
                  prereleaseType: {
                    type: 'squareup.common.client.Version.PrereleaseType',
                    id: 4,
                    rule: 'optional',
                  },
                  prerelease: {
                    type: 'uint32',
                    id: 5,
                    rule: 'optional',
                  },
                  buildId: {
                    type: 'string',
                    id: 6,
                    rule: 'optional',
                  },
                },
                nested: {
                  PrereleaseType: {
                    values: {
                      ALPHA: 10,
                      BETA: 20,
                      PRERELEASE: 30,
                      RELEASE_CANDIDATE: 40,
                    },
                  },
                },
              },
              Product: {
                nested: {
                  BuildType: {
                    values: {
                      DEVELOPMENT: 0,
                      ALPHA: 10,
                      DOGFOOD: 15,
                      BETA: 20,
                      RELEASE: 30,
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.common?.nested?.client?.nested?.Version &&
  !$root.nested?.squareup?.nested?.common?.nested?.client?.nested?.Version
    ?.nested?.PrereleaseType &&
  !$root.nested?.squareup?.nested?.common?.nested?.client?.nested?.Product
    ?.nested?.BuildType
) {
  $root.addJSON(jsonData);
}

/**
 * Common client properties. "Client" in this context means "not
 * server" and includes phones, tablets, and JavaScript apps running
 * in a browser.
 * A generic versioning scheme, e.g., 4.2.1b6/62783438, that can be
 * used both for Square apps and platforms, e.g., Android, iOS,
 * Chrome.  Since we don't control platform versioning we can't force
 * platform to conform to this scheme, in which case fields are best
 * fit. (Still preferable to proto consumers doing ad hoc string
 * parsing.)
 *
 * Everything is optional, both from the proto perspective and
 * semantically: it's possible and valid to only include the major
 * field or to have a build_id SHA and nothing else. It'll depend on
 * what's being described.
 *
 * SOURCE Version @ squareup/common/client.proto at 34:1
 */
export const Version = $root.lookupType('squareup.common.client.Version');
fixType(Version);
Builder.createAndAttachToType(Version);
/**
 * The category of a prerelease. Usually identified by a prerelease
 * demarcation: e.g., the b in 4.2.1b6 means BETA. Modeled after
 * rubygems. Values should have a meaningful sort order.
 *
 * SOURCE PrereleaseType @ squareup/common/client.proto at 40:3
 */
Version.PrereleaseType = $root.lookupEnum(
  'squareup.common.client.Version.PrereleaseType'
).values;
/**
 * Describes a square product, both released and unreleased.
 *
 * SOURCE Product @ squareup/common/client.proto at 58:1
 */
export const Product = {};
/**
 * The build type (in contrast to prerelease type above. For example, DEVELOPMENT in
 * com.squareup.square.development.)
 *
 * SOURCE BuildType @ squareup/common/client.proto at 85:3
 */
Product.BuildType = $root.lookupEnum(
  'squareup.common.client.Product.BuildType'
).values;
