import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectLoyaltyPhoneIdentifier } from 'routes/merchant-scoped-portal/integrations/loyalty/loyaltySlice';
import { AppState } from 'store';
import { OnboardingVariant } from 'routes/native-sign-in/types';

/**
 * This hook returns a helper function which navigates to the Prefilled phone variant of the sign-in page.
 * It assumes a Loyalty phone identifier has been fetched and is available in the Redux store
 */
export const useNavigateToPrefilledBuyerSignIn = () => {
  const history = useHistory();
  const loyaltyPhoneIdentifier = useSelector((state: AppState) =>
    selectLoyaltyPhoneIdentifier(state)
  );
  const navigateToPrefilledBuyerSignIn = useCallback(
    ({
      extraUrlSearchParams = {},
    }: {
      extraUrlSearchParams?: Record<string, string>;
    } = {}) => {
      history.push({
        pathname: '/signin',
        search: new URLSearchParams({
          ...extraUrlSearchParams,
          variant: OnboardingVariant.PrefilledPhone,
        }).toString(),
        state: {
          fingerprint: loyaltyPhoneIdentifier?.id,
          obfuscatedValue: loyaltyPhoneIdentifier?.displayValue,
        },
      });
    },
    [history, loyaltyPhoneIdentifier?.displayValue, loyaltyPhoneIdentifier?.id]
  );

  return navigateToPrefilledBuyerSignIn;
};
