/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import '../../common/location';
import '../buyer/data';
import '../../customers/request';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      buyerportal: {
        nested: {
          request: {
            nested: {
              AddAddressRequest: {
                fields: {
                  addressData: {
                    type: 'squareup.common.location.GlobalAddress',
                    id: 1,
                    rule: 'optional',
                  },
                },
              },
              AddAddressResponse: {
                fields: {
                  address: {
                    type: 'squareup.buyerportal.buyer.Address',
                    id: 1,
                    rule: 'optional',
                  },
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 2,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 3,
                    rule: 'repeated',
                  },
                },
              },
              RemoveAddressRequest: {
                fields: {
                  addressToken: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                },
              },
              RemoveAddressResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                },
              },
              RetrieveBuyerRequest: {
                fields: {},
              },
              RetrieveBuyerResponse: {
                fields: {
                  buyer: {
                    type: 'squareup.buyerportal.buyer.Buyer',
                    id: 1,
                    rule: 'optional',
                  },
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 2,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 3,
                    rule: 'repeated',
                  },
                },
              },
              UpdateBuyerRequest: {
                fields: {
                  buyer: {
                    type: 'squareup.buyerportal.buyer.Buyer',
                    id: 1,
                    rule: 'optional',
                  },
                  idempotencyKey: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                },
              },
              UpdateBuyerResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                  buyer: {
                    type: 'squareup.buyerportal.buyer.Buyer',
                    id: 3,
                    rule: 'optional',
                  },
                },
              },
              CreateVerificationRequest: {
                fields: {
                  idempotencyKey: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  phoneId: {
                    type: 'string',
                    id: 1,
                  },
                  phoneNumber: {
                    type: 'string',
                    id: 2,
                  },
                },
                nested: {
                  verification_credential: {
                    oneof: ['phoneId', 'phoneNumber'],
                  },
                },
              },
              CreateVerificationResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                },
              },
              CheckCashVerificationRequest: {
                fields: {
                  cashLinkToken: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  code: {
                    type: 'string',
                    id: 4,
                    rule: 'optional',
                  },
                  collectedEmail: {
                    type: 'string',
                    id: 5,
                    rule: 'optional',
                  },
                  phoneId: {
                    type: 'string',
                    id: 2,
                  },
                  phoneNumber: {
                    type: 'string',
                    id: 3,
                  },
                },
                nested: {
                  verification_credential: {
                    oneof: ['phoneId', 'phoneNumber'],
                  },
                },
              },
              CheckCashVerificationResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                  personToken: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.request?.nested
    ?.AddAddressRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.request?.nested
    ?.AddAddressResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.request?.nested
    ?.RemoveAddressRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.request?.nested
    ?.RemoveAddressResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.request?.nested
    ?.RetrieveBuyerRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.request?.nested
    ?.RetrieveBuyerResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.request?.nested
    ?.UpdateBuyerRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.request?.nested
    ?.UpdateBuyerResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.request?.nested
    ?.CreateVerificationRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.request?.nested
    ?.CreateVerificationResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.request?.nested
    ?.CheckCashVerificationRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.request?.nested
    ?.CheckCashVerificationResponse
) {
  $root.addJSON(jsonData);
}

/**
 * Request payload for AddAddress.
 *
 * SOURCE AddAddressRequest @ squareup/buyerportal/request/request.proto at 17:1
 */
export const AddAddressRequest = $root.lookupType(
  'squareup.buyerportal.request.AddAddressRequest'
);
fixType(AddAddressRequest, {
  addressData: { required: true },
});
Builder.createAndAttachToType(AddAddressRequest);
/**
 * Response payload for AddAddress.
 *
 * SOURCE AddAddressResponse @ squareup/buyerportal/request/request.proto at 24:1
 */
export const AddAddressResponse = $root.lookupType(
  'squareup.buyerportal.request.AddAddressResponse'
);
fixType(AddAddressResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(AddAddressResponse);
/**
 * Request payload for RemoveAddress.
 *
 * SOURCE RemoveAddressRequest @ squareup/buyerportal/request/request.proto at 38:1
 */
export const RemoveAddressRequest = $root.lookupType(
  'squareup.buyerportal.request.RemoveAddressRequest'
);
fixType(RemoveAddressRequest, {
  addressToken: { required: true },
});
Builder.createAndAttachToType(RemoveAddressRequest);
/**
 * Response containing status of input request and errors that might have occurred while processing the input request.
 *
 * SOURCE RemoveAddressResponse @ squareup/buyerportal/request/request.proto at 46:1
 */
export const RemoveAddressResponse = $root.lookupType(
  'squareup.buyerportal.request.RemoveAddressResponse'
);
fixType(RemoveAddressResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(RemoveAddressResponse);
/**
 * Request payload for RetrieveBuyer.
 *
 * SOURCE RetrieveBuyerRequest @ squareup/buyerportal/request/request.proto at 57:1
 */
export const RetrieveBuyerRequest = $root.lookupType(
  'squareup.buyerportal.request.RetrieveBuyerRequest'
);
fixType(RetrieveBuyerRequest);
Builder.createAndAttachToType(RetrieveBuyerRequest);
/**
 * Response payload for RetrieveBuyer.
 *
 * SOURCE RetrieveBuyerResponse @ squareup/buyerportal/request/request.proto at 62:1
 */
export const RetrieveBuyerResponse = $root.lookupType(
  'squareup.buyerportal.request.RetrieveBuyerResponse'
);
fixType(RetrieveBuyerResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(RetrieveBuyerResponse);
/**
 * Request payload for UpdateBuyer
 *
 * SOURCE UpdateBuyerRequest @ squareup/buyerportal/request/request.proto at 76:1
 */
export const UpdateBuyerRequest = $root.lookupType(
  'squareup.buyerportal.request.UpdateBuyerRequest'
);
fixType(UpdateBuyerRequest, {
  buyer: { required: true },
  idempotencyKey: { required: true },
});
Builder.createAndAttachToType(UpdateBuyerRequest);
/**
 * Request payload for UpdateBuyer
 *
 * SOURCE UpdateBuyerResponse @ squareup/buyerportal/request/request.proto at 87:1
 */
export const UpdateBuyerResponse = $root.lookupType(
  'squareup.buyerportal.request.UpdateBuyerResponse'
);
fixType(UpdateBuyerResponse, {
  status: { required: true },
  buyer: { required: true },
});
Builder.createAndAttachToType(UpdateBuyerResponse);
/**
 * Represents the request to deliver the TOTP verification.
 *
 * SOURCE CreateVerificationRequest @ squareup/buyerportal/request/request.proto at 102:1
 */
export const CreateVerificationRequest = $root.lookupType(
  'squareup.buyerportal.request.CreateVerificationRequest'
);
fixType(CreateVerificationRequest, {
  idempotencyKey: { required: true },
});
Builder.createAndAttachToType(CreateVerificationRequest);
/**
 * Represents the response to deliver the TOTP verification.
 *
 * SOURCE CreateVerificationResponse @ squareup/buyerportal/request/request.proto at 120:1
 */
export const CreateVerificationResponse = $root.lookupType(
  'squareup.buyerportal.request.CreateVerificationResponse'
);
fixType(CreateVerificationResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(CreateVerificationResponse);
/**
 * Represents a request to complete a verification in the cash coupon linking flow.
 *
 * SOURCE CheckCashVerificationRequest @ squareup/buyerportal/request/request.proto at 129:1
 */
export const CheckCashVerificationRequest = $root.lookupType(
  'squareup.buyerportal.request.CheckCashVerificationRequest'
);
fixType(CheckCashVerificationRequest, {
  cashLinkToken: { required: true },
});
Builder.createAndAttachToType(CheckCashVerificationRequest);
/**
 * Represents the response to complete a verification in the cash coupon linking flow.
 *
 * SOURCE CheckCashVerificationResponse @ squareup/buyerportal/request/request.proto at 159:1
 */
export const CheckCashVerificationResponse = $root.lookupType(
  'squareup.buyerportal.request.CheckCashVerificationResponse'
);
fixType(CheckCashVerificationResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(CheckCashVerificationResponse);
