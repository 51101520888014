/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../../Fixes';
import Builder from '../../../../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      feature: {
        nested: {
          relay: {
            nested: {
              flags: {
                nested: {
                  message: {
                    nested: {
                      LaunchDarklyProject: {
                        values: {
                          NOT_SUPPLIED: 0,
                          PIE: 1,
                          CAPITAL: 2,
                          SQUARE_CONSOLE: 3,
                          TIDAL: 4,
                          SHOP: 5,
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.feature?.nested?.relay?.nested?.flags?.nested
    ?.message?.nested?.LaunchDarklyProject
) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE LaunchDarklyProject @ squareup/feature/relay/flags/message/launch_darkly_project.proto at 8:1
 */
export const LaunchDarklyProject = $root.lookupEnum(
  'squareup.feature.relay.flags.message.LaunchDarklyProject'
).values;
