import { Factory } from 'rosie';
import {
  LoyaltyAccount,
  LoyaltyAccountExpiringPointDeadline,
  LoyaltyAccountMapping,
} from 'rpc/model/squareup/card/balance/loyalty_api/model/loyalty-account';

const LoyaltyAccountExpiringPointDeadlineFactory =
  new Factory<LoyaltyAccountExpiringPointDeadline>()
    .option('expiresAtDate', new Date())
    .attr('points', 100)
    .attr('expiresAt', ['expiresAtDate'], (expiresAtDate: Date) =>
      expiresAtDate.toISOString()
    );

export const LoyaltyAccountFactory = new Factory<LoyaltyAccount>()
  .option('phoneNumber', '+14155551234')
  // TODO: Restore id sequence once the RetrieveLoyaltyAccountInfo is implemented (LOYALTY-8904)
  // .sequence('id', (i) => `loyalty-account-${i}`)
  .attr('id', '2f80730a-519d-458e-ae34-83ab7b5158c8')
  .attr('programId', 'loyalty-program-id')
  .attr('balance', 87)
  .attr('lifetimePoints', 240)
  .attr('mapping', ['phoneNumber'], (phoneNumber: string) =>
    LoyaltyAccountMapping.create({
      phoneNumber,
    })
  )
  .attr('cashAppDownloadUrl', 'https://cashstaging.app/u/HHE5jw2f')
  .attr('createdAt', '2024-04-01T12:00:00Z')
  .attr('enrolledAt', '2024-04-01T12:00:00Z')
  .attr('updatedAt', '2024-05-05T00:00:00Z')
  .attr('expiringPointDeadlines', [
    LoyaltyAccountExpiringPointDeadlineFactory.build({
      expiresAt: '2024-12-31T08:00',
      points: 16,
    }),
    LoyaltyAccountExpiringPointDeadlineFactory.build({
      expiresAt: '2025-01-31T08:00',
      points: 8,
    }),
    LoyaltyAccountExpiringPointDeadlineFactory.build({
      expiresAt: '2025-02-28T08:00',
      points: 12,
    }),
    LoyaltyAccountExpiringPointDeadlineFactory.build({
      expiresAt: '2025-03-31T08:00',
      points: 25,
    }),
  ])
  .attr('loyaltyAccountLookupToken', 'loyalty-account-lookup-token');
