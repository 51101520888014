import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'store';
import { v4 as uuidv4 } from 'uuid';

// Consumers dispatch actions of this shape to open a toast
export type ToastDefinition = {
  variant?: ToastVariant;
  message?: string;
  persistent?: boolean;
};

// Internal representation of a toast with additional metadata
type ActiveToastDefinition = ToastDefinition & {
  id: string;
  hasOpened: boolean;
};

export enum ToastVariant {
  Critical = 'critical',
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
}

export type ToastState = {
  activeToasts: Record<string, ActiveToastDefinition>;
};

export const selectActiveToasts = (state: AppState) => state.toast.activeToasts;

export const initialState: ToastState = {
  activeToasts: {},
};

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    openToast(state: ToastState, action: PayloadAction<ToastDefinition>) {
      const newUuid = uuidv4();
      const newToast: ActiveToastDefinition = {
        ...action.payload,
        id: newUuid,
        hasOpened: false,
      };
      state.activeToasts[newUuid] = newToast;
    },
    closeToast(state: ToastState, action: PayloadAction<string>) {
      delete state.activeToasts[action.payload];
    },
    updateHasOpened(state: ToastState, action: PayloadAction<string>) {
      state.activeToasts[action.payload].hasOpened = true;
    },
  },
});

export const { openToast, closeToast, updateHasOpened } = toastSlice.actions;

export default toastSlice.reducer;
