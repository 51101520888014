import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SqLoading from 'components/SqLoading';
import nonTranslatableStrings from 'utils/nonTranslatableStrings';

type CashRedirectProps = {
  url: string;
};

const CashRedirect: React.FC<CashRedirectProps> = (props) => {
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      window.location.replace(props.url);
    }, 2000);
  }, [props]);

  return (
    <div
      className={
        'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      }
    >
      <div
        className={
          'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
        }
      >
        <SqLoading />
        <p className={'mt-6'}>
          {t('cashcouponbeta.cashRedirect.loading', {
            appName: nonTranslatableStrings.cashApp,
          })}
        </p>
      </div>
    </div>
  );
};

export default CashRedirect;
