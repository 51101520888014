/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      client: {
        nested: {
          orders: {
            nested: {
              Action: {
                fields: {
                  type: {
                    type: 'squareup.client.orders.Action.Type',
                    id: 1,
                    rule: 'optional',
                  },
                  shortDisplayName: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  longDisplayName: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  canApplyToLineItems: {
                    type: 'bool',
                    id: 4,
                    rule: 'optional',
                    options: {
                      default: false,
                    },
                  },
                },
                nested: {
                  Type: {
                    values: {
                      UNKNOWN: 0,
                      ACCEPT: 1,
                      MARK_READY: 2,
                      MARK_PICKED_UP: 3,
                      MARK_SHIPPED: 4,
                      CANCEL: 5,
                      MARK_IN_PROGRESS: 6,
                      COMPLETE: 7,
                      BUY_SHIPPING_LABEL: 8,
                      MARK_DELIVERED: 9,
                      CANCEL_WITH_AUTHORIZED_PAYMENT: 10,
                    },
                  },
                },
              },
              Channel: {
                fields: {
                  displayName: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                },
              },
              OrderGroup: {
                values: {
                  UNKNOWN: 0,
                  ACTIVE: 1,
                  NEEDS_ACTION: 2,
                  IN_PROGRESS: 3,
                  COMPLETED: 4,
                  READY: 5,
                  NEW: 6,
                  UPCOMING: 7,
                  ARRIVED: 8,
                  VOIDED: 9,
                  FULFILLED: 10,
                  FULFILLMENT_CANCELED: 11,
                  ORDER_CANCELED: 12,
                  OVERDUE: 13,
                  NOT_OVERDUE: 14,
                },
              },
              FulfillmentAPIAction: {
                fields: {
                  type: {
                    type: 'squareup.client.orders.FulfillmentAPIAction.Type',
                    id: 1,
                    rule: 'optional',
                  },
                },
                nested: {
                  Type: {
                    values: {
                      UNKNOWN: 0,
                      UPDATE_ORDER_RECIPIENT: 1,
                    },
                  },
                },
              },
              OrderDisplayStateData: {
                fields: {
                  state: {
                    type: 'squareup.client.orders.OrderDisplayStateData.OrderDisplayState',
                    id: 1,
                    rule: 'optional',
                  },
                  displayName: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  overdueAt: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  transitions: {
                    type: 'squareup.client.orders.OrderDisplayStateData.OrderDisplayStateTransition',
                    id: 4,
                    rule: 'repeated',
                  },
                },
                nested: {
                  OrderDisplayState: {
                    values: {
                      DO_NOT_USE: 0,
                      NEW: 1,
                      IN_PROGRESS: 2,
                      READY: 3,
                      COMPLETED: 4,
                      CANCELED: 5,
                      REJECTED: 6,
                      FAILED: 7,
                      UPCOMING: 8,
                      OPEN: 9,
                      STARTED: 10,
                      CLOSED: 11,
                      AWAITING_PAYMENT: 12,
                      VOIDED: 13,
                      FULFILLMENT_CANCELED: 14,
                    },
                  },
                  OrderDisplayStateTransition: {
                    fields: {
                      endState: {
                        type: 'squareup.client.orders.OrderDisplayStateData.OrderDisplayState',
                        id: 1,
                        rule: 'optional',
                      },
                      endStateDisplayName: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                      transitionAt: {
                        type: 'string',
                        id: 3,
                        rule: 'optional',
                      },
                      groupsToAdd: {
                        type: 'squareup.client.orders.OrderGroup',
                        id: 4,
                        rule: 'repeated',
                      },
                      groupsToRemove: {
                        type: 'squareup.client.orders.OrderGroup',
                        id: 5,
                        rule: 'repeated',
                      },
                    },
                  },
                },
              },
              FulfillmentDisplayStateData: {
                fields: {
                  state: {
                    type: 'squareup.client.orders.FulfillmentDisplayStateData.FulfillmentDisplayState',
                    id: 1,
                    rule: 'optional',
                  },
                  displayName: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  overdueAt: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  transitions: {
                    type: 'squareup.client.orders.FulfillmentDisplayStateData.FulfillmentDisplayStateTransition',
                    id: 4,
                    rule: 'repeated',
                  },
                },
                nested: {
                  FulfillmentDisplayState: {
                    values: {
                      DO_NOT_USE: 0,
                      UPCOMING: 1,
                      NEW: 2,
                      IN_PROGRESS: 3,
                      READY: 4,
                      COMPLETED: 5,
                      CANCELED: 6,
                    },
                  },
                  FulfillmentDisplayStateTransition: {
                    fields: {
                      endState: {
                        type: 'squareup.client.orders.FulfillmentDisplayStateData.FulfillmentDisplayState',
                        id: 1,
                        rule: 'optional',
                      },
                      endStateDisplayName: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                      transitionAt: {
                        type: 'string',
                        id: 3,
                        rule: 'optional',
                      },
                      groupsToAdd: {
                        type: 'squareup.client.orders.OrderGroup',
                        id: 4,
                        rule: 'repeated',
                      },
                      groupsToRemove: {
                        type: 'squareup.client.orders.OrderGroup',
                        id: 5,
                        rule: 'repeated',
                      },
                    },
                  },
                },
              },
              FulfillmentClientDetails: {
                fields: {
                  availableActions: {
                    type: 'squareup.client.orders.Action',
                    id: 1,
                    rule: 'repeated',
                  },
                  courier: {
                    type: 'squareup.client.orders.FulfillmentClientDetails.Courier',
                    id: 2,
                    rule: 'optional',
                  },
                  fulfillmentWindowEndsAt: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  fulfillmentDisplayStateData: {
                    type: 'squareup.client.orders.FulfillmentDisplayStateData',
                    id: 4,
                    rule: 'optional',
                  },
                  availableFulfillmentApiActions: {
                    type: 'squareup.client.orders.FulfillmentAPIAction',
                    id: 5,
                    rule: 'repeated',
                  },
                  fulfillmentTypeDisplayName: {
                    type: 'string',
                    id: 6,
                    rule: 'optional',
                  },
                },
                nested: {
                  Courier: {
                    fields: {
                      displayName: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                    },
                  },
                },
              },
              OrderClientDetails: {
                fields: {
                  groups: {
                    type: 'squareup.client.orders.OrderGroup',
                    id: 1,
                    rule: 'repeated',
                  },
                  channel: {
                    type: 'squareup.client.orders.Channel',
                    id: 2,
                    rule: 'optional',
                  },
                  fulfillmentsDisplayName: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  localizedPromptTitle: {
                    type: 'string',
                    id: 4,
                    rule: 'optional',
                  },
                  localizedPromptDescription: {
                    type: 'string',
                    id: 5,
                    rule: 'optional',
                  },
                  orderDisplayStateData: {
                    type: 'squareup.client.orders.OrderDisplayStateData',
                    id: 6,
                    rule: 'optional',
                  },
                  externalLink: {
                    type: 'string',
                    id: 7,
                    rule: 'optional',
                  },
                  availableFulfillmentApiActions: {
                    type: 'squareup.client.orders.FulfillmentAPIAction',
                    id: 8,
                    rule: 'repeated',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.client?.nested?.orders?.nested?.Action &&
  !$root.nested?.squareup?.nested?.client?.nested?.orders?.nested?.Action
    ?.nested?.Type &&
  !$root.nested?.squareup?.nested?.client?.nested?.orders?.nested?.Channel &&
  !$root.nested?.squareup?.nested?.client?.nested?.orders?.nested?.OrderGroup &&
  !$root.nested?.squareup?.nested?.client?.nested?.orders?.nested
    ?.FulfillmentAPIAction &&
  !$root.nested?.squareup?.nested?.client?.nested?.orders?.nested
    ?.FulfillmentAPIAction?.nested?.Type &&
  !$root.nested?.squareup?.nested?.client?.nested?.orders?.nested
    ?.OrderDisplayStateData &&
  !$root.nested?.squareup?.nested?.client?.nested?.orders?.nested
    ?.OrderDisplayStateData?.nested?.OrderDisplayState &&
  !$root.nested?.squareup?.nested?.client?.nested?.orders?.nested
    ?.OrderDisplayStateData?.nested?.OrderDisplayStateTransition &&
  !$root.nested?.squareup?.nested?.client?.nested?.orders?.nested
    ?.FulfillmentDisplayStateData &&
  !$root.nested?.squareup?.nested?.client?.nested?.orders?.nested
    ?.FulfillmentDisplayStateData?.nested?.FulfillmentDisplayState &&
  !$root.nested?.squareup?.nested?.client?.nested?.orders?.nested
    ?.FulfillmentDisplayStateData?.nested?.FulfillmentDisplayStateTransition &&
  !$root.nested?.squareup?.nested?.client?.nested?.orders?.nested
    ?.FulfillmentClientDetails &&
  !$root.nested?.squareup?.nested?.client?.nested?.orders?.nested
    ?.FulfillmentClientDetails?.nested?.Courier &&
  !$root.nested?.squareup?.nested?.client?.nested?.orders?.nested
    ?.OrderClientDetails
) {
  $root.addJSON(jsonData);
}

/**
 * Actions (via PerformFulfillmentAction) that can be taken on a fulfillment.
 * The actions that can be taken on a given fulfillment are attached to the FulfillmentClientDetails.
 * This removes the need for clients to be able to determine actions that can be performed across various channels
 * and workflows.
 *
 * Actions can then be performed on the fulfillment via the PerformFulfillmentAction endpoint. This removes the need for
 * clients to need to attempt to modify the order and fulfillment directly and instead allows them to specify intent while
 * deferring to the service for figuring out how the order should be modified.
 *
 * The mark picked up action marks a fulfillment on the order as picked up. In the case of a pickup or managed delivery
 * fulfillment the server will then complete the fulfillment and additionally complete the order if there are no remaining
 * unfulfilled items. In the future there can be a delivery fulfillment type where the fulfillment does not get completed upon being
 * picked up (by the courier) because the merchant also wants to track whether the order has been delivered to the customer.
 *
 * SOURCE Action @ squareup/client/orders/model.proto at 32:1
 */
export const Action = $root.lookupType('squareup.client.orders.Action');
fixType(Action, {
  type: { required: true },
  shortDisplayName: { notEmpty: true },
  longDisplayName: { notEmpty: true },
  canApplyToLineItems: { required: true },
});
Builder.createAndAttachToType(Action);
/**
 * SOURCE Type @ squareup/client/orders/model.proto at 33:3
 */
Action.Type = $root.lookupEnum('squareup.client.orders.Action.Type').values;
/**
 * The channel (e.g. Caviar) where the order originated.
 *
 * NB: Some representation of the logo can be added here once supported. See DEVS-294.
 *
 * SOURCE Channel @ squareup/client/orders/model.proto at 74:1
 */
export const Channel = $root.lookupType('squareup.client.orders.Channel');
fixType(Channel, {
  displayName: { notEmpty: true },
});
Builder.createAndAttachToType(Channel);
/**
 * The UI groups an order belongs to.
 *
 * SOURCE OrderGroup @ squareup/client/orders/model.proto at 81:1
 */
export const OrderGroup = $root.lookupEnum(
  'squareup.client.orders.OrderGroup'
).values;
/**
 * Actions (via the Fulfillment API) that can be taken on an order or fulfillment.
 * The actions that can be taken on a given order are attached to the OrderClientDetails.
 * The actions that can be taken on a given fulfillment are attached to the FulfillmentClientDetails.
 * This removes the need for clients to be able to determine actions that can be performed across various channels
 * and workflows.
 *
 * SOURCE FulfillmentAPIAction @ squareup/client/orders/model.proto at 153:1
 */
export const FulfillmentAPIAction = $root.lookupType(
  'squareup.client.orders.FulfillmentAPIAction'
);
fixType(FulfillmentAPIAction, {
  type: { required: true },
});
Builder.createAndAttachToType(FulfillmentAPIAction);
/**
 * SOURCE Type @ squareup/client/orders/model.proto at 154:3
 */
FulfillmentAPIAction.Type = $root.lookupEnum(
  'squareup.client.orders.FulfillmentAPIAction.Type'
).values;
/**
 * Order display state data includes an enumerated state describing the seller-facing status of the order, among other related properties.
 * It should be used for display purposes only – not to determine any application logic regarding order state management.
 *
 * SOURCE OrderDisplayStateData @ squareup/client/orders/model.proto at 167:1
 */
export const OrderDisplayStateData = $root.lookupType(
  'squareup.client.orders.OrderDisplayStateData'
);
fixType(OrderDisplayStateData, {
  state: { required: true },
  displayName: { notEmpty: true },
});
Builder.createAndAttachToType(OrderDisplayStateData);
/**
 * The order display state represents a top-level, seller-facing status of an order.
 * This is an interpretation of the underlying order model and its fulfillments.
 *
 * SOURCE OrderDisplayState @ squareup/client/orders/model.proto at 172:3
 */
OrderDisplayStateData.OrderDisplayState = $root.lookupEnum(
  'squareup.client.orders.OrderDisplayStateData.OrderDisplayState'
).values;
/**
 * SOURCE OrderDisplayStateTransition @ squareup/client/orders/model.proto at 194:3
 */
OrderDisplayStateData.OrderDisplayStateTransition = $root.lookupType(
  'squareup.client.orders.OrderDisplayStateData.OrderDisplayStateTransition'
);
fixType(OrderDisplayStateData.OrderDisplayStateTransition, {
  endState: { required: true },
  endStateDisplayName: { notEmpty: true },
  transitionAt: { required: true },
});
Builder.createAndAttachToType(
  OrderDisplayStateData.OrderDisplayStateTransition
);
/**
 * Fulfillment display state data includes an enumerated state describing the seller-facing status of the fulfillment, among other related properties.
 * It should be used for display purposes only – not to determine any application logic regarding fulfillment state management.
 *
 * SOURCE FulfillmentDisplayStateData @ squareup/client/orders/model.proto at 246:1
 */
export const FulfillmentDisplayStateData = $root.lookupType(
  'squareup.client.orders.FulfillmentDisplayStateData'
);
fixType(FulfillmentDisplayStateData, {
  state: { required: true },
  displayName: { notEmpty: true },
});
Builder.createAndAttachToType(FulfillmentDisplayStateData);
/**
 * SOURCE FulfillmentDisplayState @ squareup/client/orders/model.proto at 248:3
 */
FulfillmentDisplayStateData.FulfillmentDisplayState = $root.lookupEnum(
  'squareup.client.orders.FulfillmentDisplayStateData.FulfillmentDisplayState'
).values;
/**
 * SOURCE FulfillmentDisplayStateTransition @ squareup/client/orders/model.proto at 260:3
 */
FulfillmentDisplayStateData.FulfillmentDisplayStateTransition =
  $root.lookupType(
    'squareup.client.orders.FulfillmentDisplayStateData.FulfillmentDisplayStateTransition'
  );
fixType(FulfillmentDisplayStateData.FulfillmentDisplayStateTransition, {
  endState: { required: true },
  endStateDisplayName: { notEmpty: true },
  transitionAt: { required: true },
});
Builder.createAndAttachToType(
  FulfillmentDisplayStateData.FulfillmentDisplayStateTransition
);
/**
 * Holds client-specific derived metadata for a Fulfillment.
 *
 * SOURCE FulfillmentClientDetails @ squareup/client/orders/model.proto at 309:1
 */
export const FulfillmentClientDetails = $root.lookupType(
  'squareup.client.orders.FulfillmentClientDetails'
);
fixType(FulfillmentClientDetails);
Builder.createAndAttachToType(FulfillmentClientDetails);
/**
 * Managed Delivery
 *
 * SOURCE Courier @ squareup/client/orders/model.proto at 314:3
 */
FulfillmentClientDetails.Courier = $root.lookupType(
  'squareup.client.orders.FulfillmentClientDetails.Courier'
);
fixType(FulfillmentClientDetails.Courier, {
  displayName: { notEmpty: true },
});
Builder.createAndAttachToType(FulfillmentClientDetails.Courier);
/**
 * Holds client-specific derived metadata for an Order.
 *
 * SOURCE OrderClientDetails @ squareup/client/orders/model.proto at 338:1
 */
export const OrderClientDetails = $root.lookupType(
  'squareup.client.orders.OrderClientDetails'
);
fixType(OrderClientDetails, {
  fulfillmentsDisplayName: { notEmpty: true },
  orderDisplayStateData: { required: true },
});
Builder.createAndAttachToType(OrderClientDetails);
