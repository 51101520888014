/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import '../../connect/v2/common/money';
import '../../checkoutfe/extensions/extensions';
import '../../connect/v2/resources/tender';
import '../../connect/v2/resources/card';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      omg: {
        nested: {
          rst: {
            nested: {
              BillPrintState: {
                fields: {
                  printedAt: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  printedTotalMoney: {
                    type: 'squareup.connect.v2.common.Money',
                    id: 2,
                    rule: 'optional',
                  },
                },
              },
              SourceOrderInformation: {
                fields: {
                  orderId: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  orderName: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                },
              },
              Coursing: {
                fields: {
                  course: {
                    type: 'squareup.omg.rst.Coursing.Course',
                    id: 1,
                    rule: 'repeated',
                  },
                },
                nested: {
                  Course: {
                    fields: {
                      uid: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      ordinal: {
                        type: 'uint32',
                        id: 2,
                        rule: 'optional',
                      },
                      straightFire: {
                        type: 'bool',
                        id: 3,
                        rule: 'optional',
                      },
                      events: {
                        type: 'squareup.omg.rst.Coursing.Course.Event',
                        id: 4,
                        rule: 'repeated',
                      },
                      sourceOrderInformation: {
                        type: 'squareup.omg.rst.SourceOrderInformation',
                        id: 5,
                        rule: 'optional',
                      },
                      name: {
                        type: 'string',
                        id: 6,
                        rule: 'optional',
                      },
                    },
                    nested: {
                      Event: {
                        fields: {
                          uid: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                          eventType: {
                            type: 'squareup.omg.rst.Coursing.Course.Event.EventType',
                            id: 2,
                            rule: 'optional',
                          },
                          createdAt: {
                            type: 'string',
                            id: 3,
                            rule: 'optional',
                          },
                          creatorDetails: {
                            type: 'squareup.checkoutfe.extensions.BillModelMirrors.CreatorDetails',
                            id: 4,
                            rule: 'optional',
                          },
                        },
                        nested: {
                          EventType: {
                            values: {
                              DO_NOT_USE: 0,
                              CREATION: 1,
                              SEND: 2,
                              ADDITION: 3,
                              FIRE: 4,
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
              Seating: {
                fields: {
                  seats: {
                    type: 'squareup.omg.rst.Seating.Seat',
                    id: 1,
                    rule: 'repeated',
                  },
                  events: {
                    type: 'squareup.omg.rst.Seating.Event',
                    id: 2,
                    rule: 'repeated',
                  },
                },
                nested: {
                  Seat: {
                    fields: {
                      uid: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      ordinal: {
                        type: 'uint32',
                        id: 2,
                        rule: 'optional',
                      },
                      enabled: {
                        type: 'bool',
                        id: 3,
                        rule: 'optional',
                        options: {
                          default: true,
                        },
                      },
                      createdAt: {
                        type: 'string',
                        id: 4,
                        rule: 'optional',
                      },
                      sourceOrderInformation: {
                        type: 'squareup.omg.rst.SourceOrderInformation',
                        id: 5,
                        rule: 'optional',
                      },
                    },
                  },
                  Event: {
                    fields: {
                      uid: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      eventType: {
                        type: 'squareup.omg.rst.Seating.Event.EventType',
                        id: 2,
                        rule: 'optional',
                      },
                      count: {
                        type: 'uint32',
                        id: 3,
                        rule: 'optional',
                        options: {
                          default: 0,
                        },
                      },
                      creatorDetails: {
                        type: 'squareup.checkoutfe.extensions.BillModelMirrors.CreatorDetails',
                        id: 4,
                        rule: 'optional',
                      },
                      createdAt: {
                        type: 'string',
                        id: 5,
                        rule: 'optional',
                      },
                      ordinal: {
                        type: 'uint32',
                        id: 6,
                        rule: 'optional',
                      },
                    },
                    nested: {
                      EventType: {
                        values: {
                          DO_NOT_USE: 0,
                          USER_SELECTED: 1,
                          SPLIT: 2,
                          MERGE: 3,
                          VOID: 4,
                        },
                      },
                    },
                  },
                },
              },
              SeatingAssignment: {
                fields: {
                  destination: {
                    type: 'squareup.omg.rst.SeatingAssignment.Destination',
                    id: 1,
                    rule: 'optional',
                  },
                },
                nested: {
                  Destination: {
                    fields: {
                      type: {
                        type: 'squareup.omg.rst.SeatingAssignment.Destination.Type',
                        id: 1,
                        rule: 'optional',
                      },
                      seatUids: {
                        type: 'string',
                        id: 2,
                        rule: 'repeated',
                      },
                      createdAt: {
                        type: 'string',
                        id: 3,
                        rule: 'optional',
                      },
                    },
                    nested: {
                      Type: {
                        values: {
                          DO_NOT_USE: 0,
                          TABLE_SHARE: 1,
                          SEAT: 2,
                        },
                      },
                    },
                  },
                },
              },
              ReopenDetails: {
                fields: {
                  reopenedAt: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  tenderDisplayDetails: {
                    type: 'squareup.omg.rst.ReopenDetails.TenderDisplayDetails',
                    id: 2,
                    rule: 'repeated',
                  },
                },
                nested: {
                  TenderDisplayDetails: {
                    fields: {
                      uid: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      type: {
                        type: 'squareup.connect.v2.resources.Tender.Type',
                        id: 2,
                        rule: 'optional',
                      },
                      amountMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 3,
                        rule: 'optional',
                      },
                      displayDetails: {
                        type: 'squareup.omg.rst.ReopenDetails.TenderDisplayDetails.CardDisplayDetails',
                        id: 4,
                        rule: 'optional',
                      },
                      tenderId: {
                        type: 'string',
                        id: 5,
                        rule: 'optional',
                      },
                    },
                    nested: {
                      CardDisplayDetails: {
                        fields: {
                          lastFour: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                          brand: {
                            type: 'squareup.connect.v2.resources.Card.Brand',
                            id: 2,
                            rule: 'optional',
                          },
                        },
                      },
                    },
                  },
                },
              },
              ConversationalMode: {
                fields: {
                  uid: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  type: {
                    type: 'squareup.omg.rst.ConversationalMode.Type',
                    id: 2,
                    rule: 'optional',
                  },
                },
                nested: {
                  Type: {
                    values: {
                      DO_NOT_USE: 0,
                      ADD: 1,
                      EXTRA: 2,
                      SIDE: 3,
                      SUB: 4,
                      NO: 5,
                      ALLERGY: 6,
                    },
                  },
                },
              },
              AutoGratuitySettings: {
                fields: {
                  minimumSeatCount: {
                    type: 'int32',
                    id: 1,
                    rule: 'optional',
                  },
                  autoEnableType: {
                    type: 'squareup.omg.rst.AutoGratuitySettings.AutoEnableType',
                    id: 2,
                    rule: 'optional',
                  },
                  hasBeenManuallyToggled: {
                    type: 'bool',
                    id: 3,
                    rule: 'optional',
                    options: {
                      default: false,
                    },
                  },
                },
                nested: {
                  AutoEnableType: {
                    values: {
                      ALWAYS: 0,
                      MINIMUM_SEAT_COUNT: 1,
                    },
                  },
                },
              },
              ReservationInformation: {
                fields: {
                  guestFirstName: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  guestLastName: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  partySize: {
                    type: 'uint32',
                    id: 3,
                    rule: 'optional',
                  },
                  occasions: {
                    type: 'string',
                    id: 4,
                    rule: 'repeated',
                  },
                  guestRelationships: {
                    type: 'string',
                    id: 5,
                    rule: 'repeated',
                  },
                  providerName: {
                    type: 'string',
                    id: 6,
                    rule: 'optional',
                  },
                  guestDiningPreferences: {
                    type: 'string',
                    id: 7,
                    rule: 'repeated',
                  },
                  guestSeatingPreferences: {
                    type: 'string',
                    id: 8,
                    rule: 'repeated',
                  },
                  reservationNotes: {
                    type: 'squareup.omg.rst.ReservationInformation.ReservationNotes',
                    id: 9,
                    rule: 'optional',
                  },
                  reservationTime: {
                    type: 'string',
                    id: 10,
                    rule: 'optional',
                  },
                  reservationId: {
                    type: 'string',
                    id: 11,
                    rule: 'optional',
                  },
                  tags: {
                    type: 'string',
                    id: 12,
                    rule: 'repeated',
                  },
                  deposit: {
                    type: 'squareup.omg.rst.ReservationInformation.Deposit',
                    id: 13,
                    rule: 'optional',
                  },
                },
                nested: {
                  ReservationNotes: {
                    fields: {
                      guestGeneralNote: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      guestDiningPreferencesNote: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                      guestSpecialRelationshipNote: {
                        type: 'string',
                        id: 3,
                        rule: 'optional',
                      },
                      venueNote: {
                        type: 'string',
                        id: 4,
                        rule: 'optional',
                      },
                      guestRequest: {
                        type: 'string',
                        id: 5,
                        rule: 'optional',
                      },
                      guestSeatingPreferencesNote: {
                        type: 'string',
                        id: 6,
                        rule: 'optional',
                      },
                    },
                  },
                  Deposit: {
                    fields: {
                      depositAmount: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 1,
                        rule: 'optional',
                      },
                      paymentId: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                    },
                  },
                },
              },
              RstOrderExtension: {
                fields: {
                  billPrintState: {
                    type: 'squareup.omg.rst.BillPrintState',
                    id: 1,
                    rule: 'optional',
                  },
                  coursing: {
                    type: 'squareup.omg.rst.Coursing',
                    id: 2,
                    rule: 'optional',
                  },
                  seating: {
                    type: 'squareup.omg.rst.Seating',
                    id: 3,
                    rule: 'optional',
                  },
                  reopenDetails: {
                    type: 'squareup.omg.rst.ReopenDetails',
                    id: 4,
                    rule: 'optional',
                  },
                  updatedByRstPos: {
                    type: 'bool',
                    id: 5,
                    rule: 'optional',
                  },
                  reservationInformation: {
                    type: 'squareup.omg.rst.ReservationInformation',
                    id: 6,
                    rule: 'optional',
                  },
                },
              },
              RstLineItemExtension: {
                fields: {
                  courseUid: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  seating: {
                    type: 'squareup.omg.rst.SeatingAssignment',
                    id: 2,
                    rule: 'optional',
                  },
                },
              },
              RstModifierExtension: {
                fields: {
                  conversationalMode: {
                    type: 'squareup.omg.rst.ConversationalMode',
                    id: 1,
                    rule: 'repeated',
                  },
                },
              },
              RstServiceChargeExtension: {
                fields: {
                  autoGratuitySettings: {
                    type: 'squareup.omg.rst.AutoGratuitySettings',
                    id: 1,
                    rule: 'optional',
                  },
                  disabled: {
                    type: 'bool',
                    id: 2,
                    rule: 'optional',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.omg?.nested?.rst?.nested?.BillPrintState &&
  !$root.nested?.squareup?.nested?.omg?.nested?.rst?.nested
    ?.SourceOrderInformation &&
  !$root.nested?.squareup?.nested?.omg?.nested?.rst?.nested?.Coursing &&
  !$root.nested?.squareup?.nested?.omg?.nested?.rst?.nested?.Coursing?.nested
    ?.Course &&
  !$root.nested?.squareup?.nested?.omg?.nested?.rst?.nested?.Coursing?.nested
    ?.Course?.nested?.Event &&
  !$root.nested?.squareup?.nested?.omg?.nested?.rst?.nested?.Coursing?.nested
    ?.Course?.nested?.Event?.nested?.EventType &&
  !$root.nested?.squareup?.nested?.omg?.nested?.rst?.nested?.Seating &&
  !$root.nested?.squareup?.nested?.omg?.nested?.rst?.nested?.Seating?.nested
    ?.Seat &&
  !$root.nested?.squareup?.nested?.omg?.nested?.rst?.nested?.Seating?.nested
    ?.Event &&
  !$root.nested?.squareup?.nested?.omg?.nested?.rst?.nested?.Seating?.nested
    ?.Event?.nested?.EventType &&
  !$root.nested?.squareup?.nested?.omg?.nested?.rst?.nested
    ?.SeatingAssignment &&
  !$root.nested?.squareup?.nested?.omg?.nested?.rst?.nested?.SeatingAssignment
    ?.nested?.Destination &&
  !$root.nested?.squareup?.nested?.omg?.nested?.rst?.nested?.SeatingAssignment
    ?.nested?.Destination?.nested?.Type &&
  !$root.nested?.squareup?.nested?.omg?.nested?.rst?.nested?.ReopenDetails &&
  !$root.nested?.squareup?.nested?.omg?.nested?.rst?.nested?.ReopenDetails
    ?.nested?.TenderDisplayDetails &&
  !$root.nested?.squareup?.nested?.omg?.nested?.rst?.nested?.ReopenDetails
    ?.nested?.TenderDisplayDetails?.nested?.CardDisplayDetails &&
  !$root.nested?.squareup?.nested?.omg?.nested?.rst?.nested
    ?.ConversationalMode &&
  !$root.nested?.squareup?.nested?.omg?.nested?.rst?.nested?.ConversationalMode
    ?.nested?.Type &&
  !$root.nested?.squareup?.nested?.omg?.nested?.rst?.nested
    ?.AutoGratuitySettings &&
  !$root.nested?.squareup?.nested?.omg?.nested?.rst?.nested
    ?.AutoGratuitySettings?.nested?.AutoEnableType &&
  !$root.nested?.squareup?.nested?.omg?.nested?.rst?.nested
    ?.ReservationInformation &&
  !$root.nested?.squareup?.nested?.omg?.nested?.rst?.nested
    ?.ReservationInformation?.nested?.ReservationNotes &&
  !$root.nested?.squareup?.nested?.omg?.nested?.rst?.nested
    ?.ReservationInformation?.nested?.Deposit &&
  !$root.nested?.squareup?.nested?.omg?.nested?.rst?.nested
    ?.RstOrderExtension &&
  !$root.nested?.squareup?.nested?.omg?.nested?.rst?.nested
    ?.RstLineItemExtension &&
  !$root.nested?.squareup?.nested?.omg?.nested?.rst?.nested
    ?.RstModifierExtension &&
  !$root.nested?.squareup?.nested?.omg?.nested?.rst?.nested
    ?.RstServiceChargeExtension
) {
  $root.addJSON(jsonData);
}

/**
 * The state of printing the bill
 *
 * SOURCE BillPrintState @ squareup/omg/rst/extensions.proto at 22:1
 */
export const BillPrintState = $root.lookupType(
  'squareup.omg.rst.BillPrintState'
);
fixType(BillPrintState);
Builder.createAndAttachToType(BillPrintState);
/**
 * Order information used to track information about feature details such as
 * coursing and seating when orders are split & merged.
 *
 * SOURCE SourceOrderInformation @ squareup/omg/rst/extensions.proto at 43:1
 */
export const SourceOrderInformation = $root.lookupType(
  'squareup.omg.rst.SourceOrderInformation'
);
fixType(SourceOrderInformation, {
  orderId: { required: true },
  orderName: { required: true },
});
Builder.createAndAttachToType(SourceOrderInformation);
/**
 * Information about the Order coursing
 *
 * SOURCE Coursing @ squareup/omg/rst/extensions.proto at 60:1
 */
export const Coursing = $root.lookupType('squareup.omg.rst.Coursing');
fixType(Coursing);
Builder.createAndAttachToType(Coursing);
/**
 * Information about a specific course
 *
 * SOURCE Course @ squareup/omg/rst/extensions.proto at 62:3
 */
Coursing.Course = $root.lookupType('squareup.omg.rst.Coursing.Course');
fixType(Coursing.Course, {
  ordinal: { required: true },
  straightFire: { required: true },
});
Builder.createAndAttachToType(Coursing.Course);
/**
 * Contains details about course lifecycle on an Order.
 *
 * SOURCE Event @ squareup/omg/rst/extensions.proto at 87:5
 */
Coursing.Course.Event = $root.lookupType(
  'squareup.omg.rst.Coursing.Course.Event'
);
fixType(Coursing.Course.Event, {
  eventType: { required: true },
  createdAt: { required: true },
});
Builder.createAndAttachToType(Coursing.Course.Event);
/**
 * The step in the course lifecycle
 *
 * SOURCE EventType @ squareup/omg/rst/extensions.proto at 89:7
 */
Coursing.Course.Event.EventType = $root.lookupEnum(
  'squareup.omg.rst.Coursing.Course.Event.EventType'
).values;
/**
 * Information about the Order seating
 *
 * SOURCE Seating @ squareup/omg/rst/extensions.proto at 170:1
 */
export const Seating = $root.lookupType('squareup.omg.rst.Seating');
fixType(Seating);
Builder.createAndAttachToType(Seating);
/**
 * Information about a single seat
 *
 * SOURCE Seat @ squareup/omg/rst/extensions.proto at 172:3
 */
Seating.Seat = $root.lookupType('squareup.omg.rst.Seating.Seat');
fixType(Seating.Seat, {
  ordinal: { required: true },
  createdAt: { required: true },
});
Builder.createAndAttachToType(Seating.Seat);
/**
 * Contains details about how a cover count is changed on an Order.
 *
 * SOURCE Event @ squareup/omg/rst/extensions.proto at 210:3
 */
Seating.Event = $root.lookupType('squareup.omg.rst.Seating.Event');
fixType(Seating.Event, {
  eventType: { required: true },
  createdAt: { required: true },
  ordinal: { required: true },
});
Builder.createAndAttachToType(Seating.Event);
/**
 * What triggered the change in cover count
 *
 * SOURCE EventType @ squareup/omg/rst/extensions.proto at 212:5
 */
Seating.Event.EventType = $root.lookupEnum(
  'squareup.omg.rst.Seating.Event.EventType'
).values;
/**
 * Information about the seating for a particular line item
 *
 * SOURCE SeatingAssignment @ squareup/omg/rst/extensions.proto at 274:1
 */
export const SeatingAssignment = $root.lookupType(
  'squareup.omg.rst.SeatingAssignment'
);
fixType(SeatingAssignment, {
  destination: { required: true },
});
Builder.createAndAttachToType(SeatingAssignment);
/**
 * The destination of the line item
 *
 * SOURCE Destination @ squareup/omg/rst/extensions.proto at 276:3
 */
SeatingAssignment.Destination = $root.lookupType(
  'squareup.omg.rst.SeatingAssignment.Destination'
);
fixType(SeatingAssignment.Destination, {
  type: { required: true },
  seatUids: { required: true },
  createdAt: { required: true },
});
Builder.createAndAttachToType(SeatingAssignment.Destination);
/**
 * Whether the item is being shared by the table or limited to one or more seats
 *
 * SOURCE Type @ squareup/omg/rst/extensions.proto at 278:5
 */
SeatingAssignment.Destination.Type = $root.lookupEnum(
  'squareup.omg.rst.SeatingAssignment.Destination.Type'
).values;
/**
 * Contains details representing re-opened tickets and the attached tenders.
 *
 * SOURCE ReopenDetails @ squareup/omg/rst/extensions.proto at 319:1
 */
export const ReopenDetails = $root.lookupType('squareup.omg.rst.ReopenDetails');
fixType(ReopenDetails, {
  reopenedAt: { required: true },
});
Builder.createAndAttachToType(ReopenDetails);
/**
 * Per-tender display details, including type, total money, and instrument info.
 *
 * SOURCE TenderDisplayDetails @ squareup/omg/rst/extensions.proto at 321:3
 */
ReopenDetails.TenderDisplayDetails = $root.lookupType(
  'squareup.omg.rst.ReopenDetails.TenderDisplayDetails'
);
fixType(ReopenDetails.TenderDisplayDetails, {
  tenderId: { required: true },
});
Builder.createAndAttachToType(ReopenDetails.TenderDisplayDetails);
/**
 * Display data for card tenders.
 *
 * SOURCE CardDisplayDetails @ squareup/omg/rst/extensions.proto at 323:5
 */
ReopenDetails.TenderDisplayDetails.CardDisplayDetails = $root.lookupType(
  'squareup.omg.rst.ReopenDetails.TenderDisplayDetails.CardDisplayDetails'
);
fixType(ReopenDetails.TenderDisplayDetails.CardDisplayDetails, {
  lastFour: { required: true },
  brand: { required: true },
});
Builder.createAndAttachToType(
  ReopenDetails.TenderDisplayDetails.CardDisplayDetails
);
/**
 * The conversational mode attached to the modifier.
 *
 * SOURCE ConversationalMode @ squareup/omg/rst/extensions.proto at 372:1
 */
export const ConversationalMode = $root.lookupType(
  'squareup.omg.rst.ConversationalMode'
);
fixType(ConversationalMode, {
  type: { required: true },
});
Builder.createAndAttachToType(ConversationalMode);
/**
 * The pre-defined type of the conversational mode.
 *
 * SOURCE Type @ squareup/omg/rst/extensions.proto at 374:3
 */
ConversationalMode.Type = $root.lookupEnum(
  'squareup.omg.rst.ConversationalMode.Type'
).values;
/**
 * SOURCE AutoGratuitySettings @ squareup/omg/rst/extensions.proto at 395:1
 */
export const AutoGratuitySettings = $root.lookupType(
  'squareup.omg.rst.AutoGratuitySettings'
);
fixType(AutoGratuitySettings);
Builder.createAndAttachToType(AutoGratuitySettings);
/**
 * SOURCE AutoEnableType @ squareup/omg/rst/extensions.proto at 400:3
 */
AutoGratuitySettings.AutoEnableType = $root.lookupEnum(
  'squareup.omg.rst.AutoGratuitySettings.AutoEnableType'
).values;
/**
 * SOURCE ReservationInformation @ squareup/omg/rst/extensions.proto at 416:1
 */
export const ReservationInformation = $root.lookupType(
  'squareup.omg.rst.ReservationInformation'
);
fixType(ReservationInformation);
Builder.createAndAttachToType(ReservationInformation);
/**
 * SOURCE ReservationNotes @ squareup/omg/rst/extensions.proto at 441:3
 */
ReservationInformation.ReservationNotes = $root.lookupType(
  'squareup.omg.rst.ReservationInformation.ReservationNotes'
);
fixType(ReservationInformation.ReservationNotes);
Builder.createAndAttachToType(ReservationInformation.ReservationNotes);
/**
 * SOURCE Deposit @ squareup/omg/rst/extensions.proto at 473:3
 */
ReservationInformation.Deposit = $root.lookupType(
  'squareup.omg.rst.ReservationInformation.Deposit'
);
fixType(ReservationInformation.Deposit);
Builder.createAndAttachToType(ReservationInformation.Deposit);
/**
 * Extensions for RST-specific features
 *
 * SOURCE RstOrderExtension @ squareup/omg/rst/extensions.proto at 488:1
 */
export const RstOrderExtension = $root.lookupType(
  'squareup.omg.rst.RstOrderExtension'
);
fixType(RstOrderExtension);
Builder.createAndAttachToType(RstOrderExtension);
/**
 * Extensions for RST-specific features
 *
 * SOURCE RstLineItemExtension @ squareup/omg/rst/extensions.proto at 510:1
 */
export const RstLineItemExtension = $root.lookupType(
  'squareup.omg.rst.RstLineItemExtension'
);
fixType(RstLineItemExtension);
Builder.createAndAttachToType(RstLineItemExtension);
/**
 * Extensions for RST-specific features
 *
 * SOURCE RstModifierExtension @ squareup/omg/rst/extensions.proto at 521:1
 */
export const RstModifierExtension = $root.lookupType(
  'squareup.omg.rst.RstModifierExtension'
);
fixType(RstModifierExtension);
Builder.createAndAttachToType(RstModifierExtension);
/**
 * Extensions for RST-specific features
 *
 * SOURCE RstServiceChargeExtension @ squareup/omg/rst/extensions.proto at 529:1
 */
export const RstServiceChargeExtension = $root.lookupType(
  'squareup.omg.rst.RstServiceChargeExtension'
);
fixType(RstServiceChargeExtension);
Builder.createAndAttachToType(RstServiceChargeExtension);
