/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import '../../ecom_connect/resources/ecom_connect';
import '../../common/location';
import '../../roster/mds';
import '../../customers/request';
import '../buyer/data';
import '../common/data';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      buyerportal: {
        nested: {
          merchantportal: {
            nested: {
              MerchantOverview: {
                fields: {
                  merchantId: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  merchantName: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  bannerImage: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  logoImage: {
                    type: 'string',
                    id: 4,
                    rule: 'optional',
                  },
                  mainUnitId: {
                    type: 'string',
                    id: 5,
                    rule: 'optional',
                  },
                  businessDescription: {
                    type: 'string',
                    id: 6,
                    rule: 'optional',
                  },
                  websiteUrl: {
                    type: 'string',
                    id: 7,
                    rule: 'optional',
                  },
                  fullFormatLogo: {
                    type: 'string',
                    id: 8,
                    rule: 'optional',
                  },
                  primaryBrandColor: {
                    type: 'string',
                    id: 9,
                    rule: 'optional',
                  },
                  sites: {
                    type: 'squareup.connect.v2.ecom_connect.resources.Site',
                    id: 10,
                    rule: 'repeated',
                  },
                },
              },
              MerchantLocation: {
                fields: {
                  locationId: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  address: {
                    type: 'squareup.common.location.GlobalAddress',
                    id: 2,
                    rule: 'optional',
                  },
                  phone: {
                    type: 'squareup.common.location.Phone',
                    id: 3,
                    rule: 'optional',
                  },
                  email: {
                    type: 'string',
                    id: 4,
                    rule: 'optional',
                  },
                  timezone: {
                    type: 'string',
                    id: 5,
                    rule: 'optional',
                  },
                  coordinates: {
                    type: 'squareup.common.location.Coordinates',
                    id: 6,
                    rule: 'optional',
                  },
                  businessHours: {
                    type: 'squareup.roster.mds.Hours',
                    id: 7,
                    rule: 'repeated',
                  },
                },
              },
              GetMerchantPortalBaseDataRequest: {
                fields: {
                  merchantId: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                },
              },
              GetMerchantPortalBaseDataResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                  merchantOverview: {
                    type: 'squareup.buyerportal.merchantportal.MerchantOverview',
                    id: 3,
                    rule: 'optional',
                  },
                  buyer: {
                    type: 'squareup.buyerportal.buyer.Buyer',
                    id: 4,
                    rule: 'optional',
                  },
                },
              },
              GetVisitedMerchantsRequest: {
                fields: {},
              },
              GetVisitedMerchantsResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                  merchants: {
                    type: 'squareup.buyerportal.common.MerchantInfo',
                    id: 3,
                    rule: 'repeated',
                  },
                },
              },
              GetMerchantLocationsRequest: {
                fields: {
                  merchantId: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  cursor: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                },
              },
              GetMerchantLocationsResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                  locations: {
                    type: 'squareup.buyerportal.merchantportal.MerchantLocation',
                    id: 3,
                    rule: 'repeated',
                  },
                  cursor: {
                    type: 'string',
                    id: 4,
                    rule: 'optional',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.MerchantOverview &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.MerchantLocation &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.GetMerchantPortalBaseDataRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.GetMerchantPortalBaseDataResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.GetVisitedMerchantsRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.GetVisitedMerchantsResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.GetMerchantLocationsRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.GetMerchantLocationsResponse
) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE MerchantOverview @ squareup/buyerportal/merchantportal/data.proto at 16:1
 */
export const MerchantOverview = $root.lookupType(
  'squareup.buyerportal.merchantportal.MerchantOverview'
);
fixType(MerchantOverview);
Builder.createAndAttachToType(MerchantOverview);
/**
 * SOURCE MerchantLocation @ squareup/buyerportal/merchantportal/data.proto at 29:1
 */
export const MerchantLocation = $root.lookupType(
  'squareup.buyerportal.merchantportal.MerchantLocation'
);
fixType(MerchantLocation);
Builder.createAndAttachToType(MerchantLocation);
/**
 * SOURCE GetMerchantPortalBaseDataRequest @ squareup/buyerportal/merchantportal/data.proto at 39:1
 */
export const GetMerchantPortalBaseDataRequest = $root.lookupType(
  'squareup.buyerportal.merchantportal.GetMerchantPortalBaseDataRequest'
);
fixType(GetMerchantPortalBaseDataRequest, {
  merchantId: { required: true },
});
Builder.createAndAttachToType(GetMerchantPortalBaseDataRequest);
/**
 * SOURCE GetMerchantPortalBaseDataResponse @ squareup/buyerportal/merchantportal/data.proto at 44:1
 */
export const GetMerchantPortalBaseDataResponse = $root.lookupType(
  'squareup.buyerportal.merchantportal.GetMerchantPortalBaseDataResponse'
);
fixType(GetMerchantPortalBaseDataResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(GetMerchantPortalBaseDataResponse);
/**
 * SOURCE GetVisitedMerchantsRequest @ squareup/buyerportal/merchantportal/data.proto at 56:1
 */
export const GetVisitedMerchantsRequest = $root.lookupType(
  'squareup.buyerportal.merchantportal.GetVisitedMerchantsRequest'
);
fixType(GetVisitedMerchantsRequest);
Builder.createAndAttachToType(GetVisitedMerchantsRequest);
/**
 * SOURCE GetVisitedMerchantsResponse @ squareup/buyerportal/merchantportal/data.proto at 58:1
 */
export const GetVisitedMerchantsResponse = $root.lookupType(
  'squareup.buyerportal.merchantportal.GetVisitedMerchantsResponse'
);
fixType(GetVisitedMerchantsResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(GetVisitedMerchantsResponse);
/**
 * SOURCE GetMerchantLocationsRequest @ squareup/buyerportal/merchantportal/data.proto at 67:1
 */
export const GetMerchantLocationsRequest = $root.lookupType(
  'squareup.buyerportal.merchantportal.GetMerchantLocationsRequest'
);
fixType(GetMerchantLocationsRequest, {
  merchantId: { required: true },
});
Builder.createAndAttachToType(GetMerchantLocationsRequest);
/**
 * SOURCE GetMerchantLocationsResponse @ squareup/buyerportal/merchantportal/data.proto at 75:1
 */
export const GetMerchantLocationsResponse = $root.lookupType(
  'squareup.buyerportal.merchantportal.GetMerchantLocationsResponse'
);
fixType(GetMerchantLocationsResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(GetMerchantLocationsResponse);
