import { Address, AddressConverters } from 'routes/profile/models/Address';
import { Identifier, IdentifierType } from 'routes/profile/models/Identifier';
import {
  Buyer,
  Email,
  Name as RpcName,
  Phone,
  PreferenceCard,
} from 'rpc/model/squareup/buyerportal/buyer/data';
import { compareIdentifiers } from 'utils/identifiers';

export type Profile = {
  personToken?: string;
  verifiedIdentifiers: Identifier[];
  addresses: Address[];
  primaryAddressId?: string;
  primaryEmailId?: string;
  primaryPhoneId?: string;
  primaryCardId?: string;
  unverifiedEmail: Identifier | null;
} & Name;

export type Name = {
  firstName?: string;
  lastName?: string;
};

export const ProfileConverters = {
  fromUnverifiedEmail(unverifiedEmail?: Email): Identifier | null {
    if (!unverifiedEmail) {
      return null;
    }
    return {
      token: unverifiedEmail.id,
      displayValue: unverifiedEmail.displayValue,
      identifierType: IdentifierType.Email,
    };
  },

  toUnverifiedEmail(unverifiedEmail: Identifier): Email {
    return new Email.Builder()
      .id(unverifiedEmail.token)
      .displayValue(unverifiedEmail.displayValue)
      .build();
  },

  fromRpcBuyer(buyer: Buyer): Profile {
    if (buyer === null || buyer === undefined) {
      throw new Error('Unable to map null or undefined buyer');
    }

    const emailIdentifiers: Identifier[] = buyer.emails.map((email) => ({
      identifierType: IdentifierType.Email,
      token: email.id,
      displayValue: email.displayValue,
    }));

    const phoneIdentifiers: Identifier[] = buyer.phones.map((phone) => ({
      identifierType: IdentifierType.Phone,
      token: phone.id,
      displayValue: phone.displayValue,
    }));

    const cardIdentifiers: Identifier[] = buyer.preferenceCards.map((card) => ({
      identifierType: IdentifierType.Card,
      token: card.id,
      displayValue: card.displayValue,
    }));

    const unverifiedEmailIdentifier = ProfileConverters.fromUnverifiedEmail(
      buyer.unverifiedEmail
    );

    return {
      firstName: buyer.name?.first,
      lastName: buyer.name?.last,
      personToken: buyer.personToken,
      verifiedIdentifiers: [
        ...emailIdentifiers,
        ...phoneIdentifiers,
        ...cardIdentifiers,
      ].sort(compareIdentifiers),
      addresses: buyer!.addresses.map(AddressConverters.fromRpcAddress),
      primaryAddressId: buyer.primaryAddressId,
      primaryEmailId: buyer.primaryEmailId,
      primaryPhoneId: buyer.primaryPhoneId,
      primaryCardId: buyer.primaryPaymentCardId,
      unverifiedEmail: unverifiedEmailIdentifier,
    };
  },
  toRpcBuyer(buyer: Profile): Buyer {
    const emails = buyer.verifiedIdentifiers
      .filter(
        (identifier) => identifier.identifierType === IdentifierType.Email
      )
      .map((identifier) =>
        new Email.Builder()
          .displayValue(identifier.displayValue)
          .id(identifier.token)
          .build()
      );

    const phones = buyer.verifiedIdentifiers
      .filter(
        (identifier) => identifier.identifierType === IdentifierType.Phone
      )
      .map((identifier) =>
        new Phone.Builder()
          .displayValue(identifier.displayValue)
          .id(identifier.token)
          .build()
      );

    const cards = buyer.verifiedIdentifiers
      .filter((identifier) => identifier.identifierType === IdentifierType.Card)
      .map((identifier) =>
        new PreferenceCard.Builder()
          .displayValue(identifier.displayValue)
          .id(identifier.token)
          .build()
      );

    const buyerBuilder = new Buyer.Builder()
      .name(
        new RpcName.Builder()
          .first(buyer.firstName)
          .last(buyer.lastName)
          .build()
      )
      .personToken(buyer.personToken)
      .emails(emails)
      .phones(phones)
      .preferenceCards(cards);

    if (buyer.primaryAddressId !== '') {
      buyerBuilder.primaryAddressId(buyer.primaryAddressId);
    }

    if (buyer.primaryEmailId !== '') {
      buyerBuilder.primaryEmailId(buyer.primaryEmailId);
    }

    if (buyer.primaryPhoneId !== '') {
      buyerBuilder.primaryPhoneId(buyer.primaryPhoneId);
    }

    if (buyer.primaryCardId !== '') {
      buyerBuilder.primaryPaymentCardId(buyer.primaryCardId);
    }

    if (buyer.unverifiedEmail) {
      buyerBuilder.unverifiedEmail(
        ProfileConverters.toUnverifiedEmail(buyer.unverifiedEmail)
      );
    }

    return buyerBuilder.build();
  },
};
