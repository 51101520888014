const FEATURE = 'automatic_receipt';

type Selection = 'all' | 'merchant';

/* Page Views */

export const viewAutoReceiptUnsubscribe = (receiptToken: string) => {
  return {
    pageTitle: 'Auto Receipt Unsubscribe',
    feature: FEATURE,
    receiptToken,
  };
};

export const viewAutoReceiptUnsubscribed = (
  receiptToken: string,
  selection: Selection
) => {
  return {
    pageTitle: 'Auto Receipt Unsubscribed',
    feature: FEATURE,
    receiptToken,
    selection,
  };
};

export const viewAutoReceiptResubscribed = (
  receiptToken: string,
  selection: Selection
) => {
  return {
    pageTitle: 'Auto Receipt Resubscribed',
    feature: FEATURE,
    receiptToken,
    selection,
  };
};

/* Actions */

export const actionTurnOffReceipts = (
  receiptToken: string,
  selection: Selection
) => {
  return {
    feature: FEATURE,
    receiptToken,
    selection,
  };
};
