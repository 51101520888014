import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { selectPointsTerminology } from '../loyaltySlice';
import { useTranslation } from 'react-i18next';
import { ClaimablePoints } from 'rpc/model/squareup/card/balance/loyalty_api/model/claimable-points';

interface ClaimablePointsDescriptionProps {
  claimablePoints?: ClaimablePoints;
}

const ClaimablePointsDescription: React.FC<ClaimablePointsDescriptionProps> = ({
  claimablePoints,
}) => {
  const { t } = useTranslation();
  const terminology = useSelector((state: AppState) =>
    selectPointsTerminology(state)
  );
  const points = claimablePoints?.points ?? 0;
  const pointsTerminology =
    points === 1 ? terminology?.one : terminology?.other;

  switch (claimablePoints?.reason) {
    case 1: {
      return t('loyalty.claimablePointsDescription.missedTransaction', {
        points,
        pointsTerminology,
      });
    }
    case 2: {
      return t('loyalty.claimablePointsDescription.emailImport', {
        points,
        pointsTerminology,
      });
    }
    default: {
      return t('loyalty.claimablePointsDescription.expired', {
        pointsTerminology: terminology?.other,
      });
    }
  }
};

export default ClaimablePointsDescription;
