import {
  IMerchantLocation,
  MerchantLocation,
} from 'rpc/model/squareup/buyerportal/merchantportal/data';
import { formatMultilineAddress } from 'utils/globalAddress';

// Basically, the RPC model + the formatted address
export type FormattedMerchantLocation = Pick<
  IMerchantLocation,
  'locationId' | 'address' | 'phone' | 'email'
> & {
  formattedAddress?: string;
};

export function formatMerchantLocations(
  merchantLocations: MerchantLocation[]
): FormattedMerchantLocation[] {
  return merchantLocations.map((rpcLocation) => {
    return {
      ...rpcLocation,
      formattedAddress: formatMultilineAddress(rpcLocation.address),
    };
  });
}
