import React from 'react';
import BaseModal, { ModalSize } from 'components/modals/BaseModal';
import { MarketButton, MarketButtonGroup, MarketFooter } from '@market/react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'store/modalSlice';
import { useRemoveGiftCardMutation } from 'store/query';
import { openToast } from 'store/toastSlice';
import { useHistory } from 'react-router-dom';
import { buyerportalCdpClient } from 'services/tracking/cdp/clients/buyerportal';
import { GiftCard } from 'routes/profile/models/GiftCard';
import { actionRemoveGiftCardProperties } from 'services/tracking/cdp/events/paymentMethods';
import { useTranslation } from 'react-i18next';

export type ConfirmRemoveGiftCardModalProps = {
  giftCard: GiftCard;
};

const ConfirmRemoveGiftCardModal: React.FC<ConfirmRemoveGiftCardModalProps> = ({
  giftCard,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [removeGiftCard, removeGiftCardResult] = useRemoveGiftCardMutation();

  const confirmRemoveGiftCard = async () => {
    await removeGiftCard(giftCard.token)
      .unwrap()
      .then(() => {
        dispatch(
          openToast({
            variant: 'success',
            message: t(
              'profile.paymentMethods.giftCards.confirmRemoveGiftCardModal.giftCardRemoved'
            ),
          })
        );
        dispatch(closeModal());
        buyerportalCdpClient.track(
          'Remove Gift Card',
          actionRemoveGiftCardProperties(giftCard)
        );

        history.push('/paymentmethods');
      })
      .catch(() => {
        dispatch(
          openToast({
            variant: 'critical',
            message: t('common.somethingWentWrong.retryable.direct'),
          })
        );
      });
  };

  return (
    <BaseModal size={ModalSize.Small}>
      <h2 className="mt-0">
        {t('profile.paymentMethods.giftCards.confirmRemoveGiftCardModal.title')}
      </h2>
      <p>
        {t(
          'profile.paymentMethods.giftCards.confirmRemoveGiftCardModal.subtitle'
        )}
      </p>
      <MarketFooter style={{ paddingBottom: 4 }}>
        <MarketButtonGroup alignment={'fill'}>
          <MarketButton
            rank={'primary'}
            variant={'destructive'}
            onClick={confirmRemoveGiftCard}
            data-testid={'confirm-remove-gift-card'}
            {...(removeGiftCardResult.isLoading && { disabled: true })}
          >
            {t(
              'profile.paymentMethods.giftCards.confirmRemoveGiftCardModal.removeCard'
            )}
          </MarketButton>
          <MarketButton
            rank={'secondary'}
            onClick={() => dispatch(closeModal())}
            {...(removeGiftCardResult.isLoading && { disabled: true })}
          >
            {t('common.cancel')}
          </MarketButton>
        </MarketButtonGroup>
      </MarketFooter>
    </BaseModal>
  );
};

export default ConfirmRemoveGiftCardModal;
