import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * This is a custom React hook that will prevent a function from being called multiple times within a time period.
 *
 * @param throttleFn The function to throttle
 * @param timeout The time period in which to throttle
 */
const useThrottle = (throttleFn: Function, timeout: number) => {
  const throttledFnRef = useRef<Function>();
  const lastCallRef = useRef<number | null>(null);
  const [isThrottled, setIsThrottled] = useState<boolean>(false);

  useEffect(() => {
    throttledFnRef.current = throttleFn;
  }, [throttleFn]);

  const throttledFn = useCallback(
    (...args: any[]) => {
      const currentTime = Date.now();
      const timeElapsed = lastCallRef.current
        ? currentTime - lastCallRef.current
        : Number.POSITIVE_INFINITY;

      if (timeElapsed >= timeout) {
        lastCallRef.current = currentTime;
        setIsThrottled(true);
        throttledFnRef.current?.(...args);

        // The setTimeout will update isThrottled after the given timeout
        setTimeout(() => {
          setIsThrottled(false);
        }, timeout);
      }
    },
    [timeout]
  );

  return [throttledFn, isThrottled] as const;
};

export default useThrottle;
