import { MarketButton } from '@market/react';
import AvatarImage from 'components/profile/AvatarImage';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectBuyer } from 'store/buyerSlice';
import { buyerportalCdpClient } from 'services/tracking/cdp/clients/buyerportal';
import { startUpdateProfileProperties } from 'services/tracking/cdp/events/profile';
import { AppState } from 'store';
import { ModalType, openModal } from 'store/modalSlice';
import { useTranslation } from 'react-i18next';

const ProfileDetails: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const buyer = useSelector((state: AppState) => selectBuyer(state));

  const { firstName, lastName } = buyer;
  const fullName = [firstName, lastName].filter(Boolean).join(' ');

  const openEditProfileDetailsModal = () => {
    buyerportalCdpClient.track(
      'Start Update Details',
      startUpdateProfileProperties('edit', 'name')
    );

    dispatch(
      openModal({
        type: ModalType.EditProfileDetails,
      })
    );
  };

  return (
    <div
      className={'flex flex-col md:flex-row items-center justify-center py-4'}
    >
      <AvatarImage placeholder={firstName} />
      <span className={'pt-4 pb-2 md:ml-6 md:py-0 font-medium text-base'}>
        {fullName}
      </span>
      <MarketButton
        className={'md:ml-auto'}
        onClick={openEditProfileDetailsModal}
      >
        {t('profile.account.profileDetails.editDetails')}
      </MarketButton>
    </div>
  );
};

export default ProfileDetails;
