import React from 'react';
import Human from 'svgs/market-icons/Human';

type AvatarImageProps = {
  imageUrl?: string;
  placeholder?: string;
};

const AvatarImage: React.FC<AvatarImageProps> = (props) => {
  const getImage = () => {
    if (props.imageUrl) {
      return <img src={props.imageUrl} alt={props.placeholder} />;
    }

    const firstLetter =
      props.placeholder && props.placeholder.length > 0
        ? props.placeholder.charAt(0).toUpperCase()
        : '';

    if (!firstLetter) {
      return <Human fill={'white'} width={32} height={32} />;
    }

    return (
      <div className={'text-white text-[38px] font-bold'}>{firstLetter}</div>
    );
  };

  return (
    <div
      className={
        'flex items-center justify-center h-[80px] w-[80px] rounded-lg bg-blue'
      }
    >
      {getImage()}
    </div>
  );
};

export default AvatarImage;
