import { v4 as uuidv4 } from 'uuid';
import { BuyerCardConverter } from 'routes/profile/models/BuyerCard';
import {
  RequestError,
  RequestErrorType,
  IdentifierClaimedError,
} from 'models/Error';
import { Identifier, IdentifierType } from 'routes/profile/models/Identifier';
import client from 'rpc/client';
import { RequestStatus } from 'rpc/model/squareup/customers/request';
import { RetrieveSuggestedProfileIdentifiersRequest } from 'rpc/model/squareup/buyerportal/profile/suggested_identifier';
import {
  ExportProfileRequest,
  DeleteProfileRequest,
} from 'rpc/model/squareup/buyerportal/profile/privacy';
import { getRpcIdentifierType } from 'utils/identifiers';
import {
  AddProfileIdentifierRequest,
  VerifyAddProfileIdentifierRequest,
} from 'rpc/model/squareup/buyerportal/profile/verified';
import { CreateCardRequest } from 'rpc/model/squareup/buyerportal/cards/data';
import {
  CreateVerificationRequest,
  CheckCashVerificationRequest,
} from 'rpc/model/squareup/buyerportal/request/request';
import {
  RetrieveCashLinkInfoRequest,
  RetrieveCashLinkRedirectUrlRequest,
  LinkWithCashRequest,
} from 'rpc/model/squareup/buyerportal/cashlink/data';
import {
  AccountlessPrivacyRequestType,
  CreateAccountlessPrivacyRequestRequest,
  ExecuteAccountlessPrivacyRequestRequest,
} from 'rpc/model/squareup/buyerportal/accountlessprivacy/data';
import PrivacyRequestType from 'routes/privacy-requests/enums/PrivacyRequestType';
import {
  Name,
  SignInIdentifier,
  SignInIdentifierType,
} from 'routes/native-sign-in/types';
import {
  CreateBuyerFromDraftRequest,
  ICreateBuyerFromDraftRequest,
} from 'rpc/model/squareup/buyerportal/onboarding/data';

export async function createCard(nonce: string) {
  const response = await client.createCard(
    new CreateCardRequest.Builder()
      .cardNonce(nonce)
      .idempotencyKey(uuidv4())
      .build()
  );

  if (response.status !== RequestStatus.STATUS_SUCCESS || !response.card) {
    let message = '';
    if (response.status === RequestStatus.STATUS_BAD_REQUEST) {
      message = response.errors[0].details;
    }

    throw new Error(message);
  }

  return BuyerCardConverter.fromRpcBuyerCard(response.card);
}

export async function retrieveSuggestedProfileIdentifiers(): Promise<
  Identifier[]
> {
  const response = await client.retrieveSuggestedProfileIdentifiers(
    RetrieveSuggestedProfileIdentifiersRequest.create()
  );

  if (response.status !== RequestStatus.STATUS_SUCCESS) {
    throw new Error('Failed to retrieve suggested profile identifiers');
  }

  return response.identifiers?.map(Identifier.fromResponse) ?? [];
}

export async function deleteProfile(idempotencyKey: string) {
  const response = await client.deleteProfile(
    DeleteProfileRequest.create({ idempotencyKey })
  );

  if (response.status !== RequestStatus.STATUS_SUCCESS) {
    throw new Error('Failed to delete profile');
  }
}

export async function exportProfile(
  requestorEmail: string,
  idempotencyKey: string
) {
  const response = await client.exportProfile(
    ExportProfileRequest.create({ requestorEmail, idempotencyKey })
  );

  if (response.status !== RequestStatus.STATUS_SUCCESS) {
    throw new Error('Failed to export profile');
  }
}

export async function requestAddProfileIdentifier(
  identifierRawValue: string,
  identifierType: IdentifierType
) {
  const response = await client.requestAddProfileIdentifier(
    AddProfileIdentifierRequest.create({
      idempotencyKey: uuidv4(),
      identifierType: getRpcIdentifierType(identifierType),
      identifierRawValue,
    })
  );

  if (response.status !== RequestStatus.STATUS_SUCCESS) {
    const serverError = response.errors![0];

    if (
      serverError.code === 'CODE_PRECONDITION_FAILED' ||
      response.status === RequestStatus.STATUS_BAD_REQUEST
    ) {
      const error: RequestError = {
        type: RequestErrorType.ValueError,
        message: serverError.details,
      };

      throw error;
    }

    // Other unrecoverable errors
    const error: RequestError = {
      message: response.errors![0].details,
    };

    throw error;
  }
}

export async function verifyAddProfileIdentifier(
  identifierRawValue: string,
  identifierType: IdentifierType,
  verificationCode?: string
) {
  const response = await client.verifyAddProfileIdentifier(
    VerifyAddProfileIdentifierRequest.create({
      identifierRawValue,
      identifierType: getRpcIdentifierType(identifierType),
      verificationCode,
    })
  );

  if (
    response.status !== RequestStatus.STATUS_SUCCESS ||
    !response.identifier
  ) {
    const serverError = response.errors![0];

    // PAN goes through verification flow only, so errors about the PAN field
    // should be treated as if they are verification errors.
    if (
      serverError.field === 'FIELD_VERIFICATION_CODE' ||
      (serverError.field === 'FIELD_PAN' && serverError.code === 'CODE_INVALID')
    ) {
      const error: RequestError = {
        type: RequestErrorType.ValueError,
        message: serverError.details,
      };

      throw error;
    }

    const error: RequestError = {
      message: serverError.details,
    };

    throw error;
  }

  return Identifier.fromResponse(response.identifier);
}

export async function retrieveCashLinkInfo(cashLinkToken: string) {
  const response = await client.retrieveCashLinkInfo(
    RetrieveCashLinkInfoRequest.create({
      cashLinkToken,
    })
  );

  if (response.status !== RequestStatus.STATUS_SUCCESS) {
    const error: RequestError = {
      message: response.errors![0].details,
    };

    throw error;
  }

  return response.cashLinkInfo;
}

export async function retrieveCashCouponLinkRedirectUrl(cashLinkToken: string) {
  const response = await client.retrieveCashLinkRedirectUrl(
    RetrieveCashLinkRedirectUrlRequest.create({
      cashLinkToken,
    })
  );

  if (response.status !== RequestStatus.STATUS_SUCCESS) {
    const error: RequestError = {
      message: response.errors![0].details,
    };

    throw error;
  }

  return response;
}

export async function requestPhoneVerification(
  phoneNumber: string | null,
  phoneId: string | null
) {
  const response = await client.createVerification(
    CreateVerificationRequest.create({
      phoneNumber: phoneNumber || undefined,
      phoneId: phoneId || undefined,
      idempotencyKey: uuidv4(),
    })
  );

  if (response.status !== RequestStatus.STATUS_SUCCESS) {
    const error: RequestError = {
      message: response.errors![0].details,
    };

    throw error;
  }
}

export async function linkWithCashApp(
  cashLinkToken: string,
  collectedEmail: string | null,
  totp: string | null,
  phoneId: string | null,
  phoneNumber: string | null
) {
  const response = await client.linkWithCash(
    LinkWithCashRequest.create({
      cashLinkToken,
      collectedEmail: collectedEmail || undefined,
      phoneId: phoneId || undefined,
      phoneNumber: phoneNumber || undefined,
      code: totp || undefined,
    })
  );

  if (response.status !== RequestStatus.STATUS_SUCCESS) {
    const error = {
      status: response.status,
    };

    throw error;
  }

  return response.redirectUrl;
}

export async function checkCashVerification(
  cashLinkToken: string,
  phoneId: string | null,
  phoneNumber: string | null,
  code: string,
  collectedEmail: string | null
) {
  const response = await client.checkCashVerification(
    CheckCashVerificationRequest.create({
      cashLinkToken,
      phoneId: phoneId || undefined,
      phoneNumber: phoneNumber || undefined,
      code,
      collectedEmail: collectedEmail || undefined,
    })
  );

  if (response.status !== RequestStatus.STATUS_SUCCESS) {
    const error: RequestError = {
      message: response.errors![0].details,
    };

    throw error;
  }

  return response.personToken;
}

const getVerificationCredential = (
  identifierType: IdentifierType,
  identifierValue: string
) => {
  return {
    email:
      identifierType === IdentifierType.Email ? identifierValue : undefined,
    phoneNumber:
      identifierType === IdentifierType.Phone ? identifierValue : undefined,
  };
};

const getAccountlessPrivacyRequestType = (intent: PrivacyRequestType) => {
  return intent === PrivacyRequestType.Delete
    ? AccountlessPrivacyRequestType.DELETION
    : AccountlessPrivacyRequestType.EXPORT;
};

export async function createAccountlessPrivacyRequest(
  identifierType: IdentifierType,
  identifierValue: string,
  intent: PrivacyRequestType,
  idempotencyKey: string = uuidv4()
) {
  const response = await client.createAccountlessPrivacyRequest(
    CreateAccountlessPrivacyRequestRequest.create({
      verificationCredential: getVerificationCredential(
        identifierType,
        identifierValue
      ),
      requestType: getAccountlessPrivacyRequestType(intent),
      idempotencyKey,
    })
  );

  if (response.status !== RequestStatus.STATUS_SUCCESS) {
    const error = {
      status: response.status,
      message: response.errors![0].details,
    };

    throw error;
  }

  return {
    accountExists: response.accountExists,
    accountPendingDeletion: response.accountPendingDeletion,
  };
}

export async function executeAccountlessPrivacyRequest(
  identifierType: IdentifierType,
  identifierValue: string,
  intent: PrivacyRequestType,
  verificationCode: string,
  destinationEmail?: string,
  idempotencyKey: string = uuidv4()
) {
  const response = await client.executeAccountlessPrivacyRequest(
    ExecuteAccountlessPrivacyRequestRequest.create({
      verificationCredential: getVerificationCredential(
        identifierType,
        identifierValue
      ),
      requestType: getAccountlessPrivacyRequestType(intent),
      verificationCode,
      destinationEmail,
      idempotencyKey,
    })
  );

  if (response.status !== RequestStatus.STATUS_SUCCESS) {
    const error = {
      status: response.status,
      message: response.errors![0].details,
    };

    throw error;
  }
}

export async function createBuyerFromDraft(
  identifier: SignInIdentifier,
  usingIdentifierId: boolean,
  verificationCode?: string,
  name?: Name
) {
  const getVerificationCredentialKeyName = (
    identifierType: SignInIdentifierType,
    usingIdentifierId: boolean
  ) => {
    const TYPE_TO_BASE_KEY = {
      [IdentifierType.Email]: 'email',
      [IdentifierType.Phone]: 'phoneNumber',
    };

    return `${TYPE_TO_BASE_KEY[identifierType]}${
      usingIdentifierId ? 'Id' : ''
    }`;
  };
  const verificationCredentialKey = getVerificationCredentialKeyName(
    identifier.type,
    usingIdentifierId
  );
  const parameters: ICreateBuyerFromDraftRequest = {
    verificationCredential: {
      [verificationCredentialKey]: usingIdentifierId
        ? identifier.id
        : identifier.value,
    },
    verificationCode,
    name: { first: name?.firstName, last: name?.lastName },
  };

  const response = await client.createBuyerFromDraft(
    CreateBuyerFromDraftRequest.create(parameters)
  );

  switch (response.status) {
    case RequestStatus.STATUS_SUCCESS: {
      break;
    }
    case RequestStatus.STATUS_CONFLICT: {
      let claimedType: SignInIdentifierType | undefined;

      for (let i = 0; i < response.errors.length && !claimedType; i++) {
        const errorField = response.errors[i].details;

        if (/email/g.test(errorField)) {
          claimedType = IdentifierType.Email;
        } else if (/phone/g.test(errorField)) {
          claimedType = IdentifierType.Phone;
        }
      }

      if (claimedType) {
        throw new IdentifierClaimedError(
          response.errors[0].details,
          claimedType
        );
      } else {
        throw new Error();
      }
    }
    default: {
      const { code } = response.errors[0];
      throw new Error(code);
    }
  }
}
