/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import '../protobuf/rpc/rpc';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      sake: {
        nested: {
          Error: {
            fields: {
              code: {
                type: 'squareup.protobuf.rpc.ResponseCode',
                id: 1,
                rule: 'optional',
                options: {
                  default: 'REQUEST_COMPLETED',
                },
              },
              appCode: {
                type: 'int32',
                id: 3,
                rule: 'optional',
              },
              debugText: {
                type: 'string',
                id: 2,
                rule: 'optional',
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.sake?.nested?.Error) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE Error @ squareup/sake/wire_format.proto at 61:1
 */
export const Error = $root.lookupType('squareup.sake.Error');
fixType(Error);
Builder.createAndAttachToType(Error);
