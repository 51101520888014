/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import '../common/data';
import '../../customers/request';
import '../profile/common';
import '../buyer/data';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      buyerportal: {
        nested: {
          onboarding: {
            nested: {
              CreateLoginOrSignupVerificationRequest: {
                fields: {
                  verificationCredential: {
                    type: 'squareup.buyerportal.common.VerificationCredential',
                    id: 1,
                    rule: 'optional',
                  },
                  idempotencyKey: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                },
              },
              CreateLoginOrSignupVerificationResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                },
              },
              AuthenticateOrCreateAccountDraftRequest: {
                fields: {
                  verificationCredential: {
                    type: 'squareup.buyerportal.common.VerificationCredential',
                    id: 1,
                    rule: 'optional',
                  },
                  verificationCode: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                },
              },
              AuthenticateOrCreateAccountDraftResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                  personToken: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  accountPendingDeletion: {
                    type: 'bool',
                    id: 4,
                    rule: 'optional',
                  },
                  missingIdentifier: {
                    type: 'squareup.buyerportal.profile.Identifier',
                    id: 5,
                    rule: 'optional',
                  },
                },
              },
              CreateBuyerFromDraftRequest: {
                fields: {
                  verificationCredential: {
                    type: 'squareup.buyerportal.common.VerificationCredential',
                    id: 1,
                    rule: 'optional',
                  },
                  verificationCode: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  name: {
                    type: 'squareup.buyerportal.buyer.Name',
                    id: 3,
                    rule: 'optional',
                  },
                },
              },
              CreateBuyerFromDraftResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                  buyer: {
                    type: 'squareup.buyerportal.buyer.Buyer',
                    id: 3,
                    rule: 'optional',
                  },
                },
              },
              DecoupleUnverifiedEmailInAccountDraftRequest: {
                fields: {},
              },
              DecoupleUnverifiedEmailInAccountDraftResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.onboarding?.nested
    ?.CreateLoginOrSignupVerificationRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.onboarding?.nested
    ?.CreateLoginOrSignupVerificationResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.onboarding?.nested
    ?.AuthenticateOrCreateAccountDraftRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.onboarding?.nested
    ?.AuthenticateOrCreateAccountDraftResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.onboarding?.nested
    ?.CreateBuyerFromDraftRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.onboarding?.nested
    ?.CreateBuyerFromDraftResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.onboarding?.nested
    ?.DecoupleUnverifiedEmailInAccountDraftRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.onboarding?.nested
    ?.DecoupleUnverifiedEmailInAccountDraftResponse
) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE CreateLoginOrSignupVerificationRequest @ squareup/buyerportal/onboarding/data.proto at 20:1
 */
export const CreateLoginOrSignupVerificationRequest = $root.lookupType(
  'squareup.buyerportal.onboarding.CreateLoginOrSignupVerificationRequest'
);
fixType(CreateLoginOrSignupVerificationRequest, {
  verificationCredential: { required: true },
  idempotencyKey: { required: true, notEmpty: true },
});
Builder.createAndAttachToType(CreateLoginOrSignupVerificationRequest);
/**
 * SOURCE CreateLoginOrSignupVerificationResponse @ squareup/buyerportal/onboarding/data.proto at 30:1
 */
export const CreateLoginOrSignupVerificationResponse = $root.lookupType(
  'squareup.buyerportal.onboarding.CreateLoginOrSignupVerificationResponse'
);
fixType(CreateLoginOrSignupVerificationResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(CreateLoginOrSignupVerificationResponse);
/**
 * SOURCE AuthenticateOrCreateAccountDraftRequest @ squareup/buyerportal/onboarding/data.proto at 38:1
 */
export const AuthenticateOrCreateAccountDraftRequest = $root.lookupType(
  'squareup.buyerportal.onboarding.AuthenticateOrCreateAccountDraftRequest'
);
fixType(AuthenticateOrCreateAccountDraftRequest, {
  verificationCredential: { required: true },
  verificationCode: { required: true },
});
Builder.createAndAttachToType(AuthenticateOrCreateAccountDraftRequest);
/**
 * SOURCE AuthenticateOrCreateAccountDraftResponse @ squareup/buyerportal/onboarding/data.proto at 46:1
 */
export const AuthenticateOrCreateAccountDraftResponse = $root.lookupType(
  'squareup.buyerportal.onboarding.AuthenticateOrCreateAccountDraftResponse'
);
fixType(AuthenticateOrCreateAccountDraftResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(AuthenticateOrCreateAccountDraftResponse);
/**
 * SOURCE CreateBuyerFromDraftRequest @ squareup/buyerportal/onboarding/data.proto at 63:1
 */
export const CreateBuyerFromDraftRequest = $root.lookupType(
  'squareup.buyerportal.onboarding.CreateBuyerFromDraftRequest'
);
fixType(CreateBuyerFromDraftRequest, {
  verificationCredential: { required: true },
  verificationCode: { required: true },
  name: { required: true },
});
Builder.createAndAttachToType(CreateBuyerFromDraftRequest);
/**
 * SOURCE CreateBuyerFromDraftResponse @ squareup/buyerportal/onboarding/data.proto at 74:1
 */
export const CreateBuyerFromDraftResponse = $root.lookupType(
  'squareup.buyerportal.onboarding.CreateBuyerFromDraftResponse'
);
fixType(CreateBuyerFromDraftResponse, {
  status: { required: true },
  buyer: { required: true },
});
Builder.createAndAttachToType(CreateBuyerFromDraftResponse);
/**
 * SOURCE DecoupleUnverifiedEmailInAccountDraftRequest @ squareup/buyerportal/onboarding/data.proto at 85:1
 */
export const DecoupleUnverifiedEmailInAccountDraftRequest = $root.lookupType(
  'squareup.buyerportal.onboarding.DecoupleUnverifiedEmailInAccountDraftRequest'
);
fixType(DecoupleUnverifiedEmailInAccountDraftRequest);
Builder.createAndAttachToType(DecoupleUnverifiedEmailInAccountDraftRequest);
/**
 * SOURCE DecoupleUnverifiedEmailInAccountDraftResponse @ squareup/buyerportal/onboarding/data.proto at 87:1
 */
export const DecoupleUnverifiedEmailInAccountDraftResponse = $root.lookupType(
  'squareup.buyerportal.onboarding.DecoupleUnverifiedEmailInAccountDraftResponse'
);
fixType(DecoupleUnverifiedEmailInAccountDraftResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(DecoupleUnverifiedEmailInAccountDraftResponse);
