const merchantPortalRoutePattern = '/merchantportal/:merchantId';
export const loyaltyRoutePattern = `${merchantPortalRoutePattern}/loyalty`;
export const authenticatedLoyaltyRoutePattern = `${loyaltyRoutePattern}/lalt/:loyaltyAccountLookupToken`;
export const authenticatedLoyaltySubRoutePattern = `${authenticatedLoyaltyRoutePattern}/:subRoute`;
export const loyaltyClaimPointsRoutePattern = `${loyaltyRoutePattern}/claim/:claimablePointsToken`;
export const mailingListRoutePattern = `${authenticatedLoyaltyRoutePattern}/earning/mailing-list`;

export enum LoyaltySubRoute {
  Account = 'account',
  Activity = 'activity',
  Earning = 'earning',
  Rewards = 'rewards',
}

export interface MerchantPortalRouteParams {
  merchantId: string;
}

export interface AuthenticatedLoyaltyRouteParams
  extends MerchantPortalRouteParams {
  loyaltyAccountLookupToken: string;
}

export interface AuthenticatedLoyaltySubRouteParams
  extends AuthenticatedLoyaltyRouteParams {
  subRoute: LoyaltySubRoute;
}

export interface LoyaltyClaimPointsRouteParams
  extends MerchantPortalRouteParams {
  claimablePointsToken: string;
}

export const createLoyaltySubRouteUrl = ({
  merchantId,
  loyaltyAccountLookupToken,
  subRoute = LoyaltySubRoute.Rewards,
}: {
  merchantId: string;
  loyaltyAccountLookupToken: string;
  subRoute?: LoyaltySubRoute;
}) =>
  authenticatedLoyaltySubRoutePattern
    .replace(':merchantId', merchantId)
    .replace(':loyaltyAccountLookupToken', loyaltyAccountLookupToken)
    .replace(':subRoute', subRoute);

export const createMailingListUrl = ({
  merchantId,
  loyaltyAccountLookupToken,
}: {
  merchantId: string;
  loyaltyAccountLookupToken: string;
}) =>
  mailingListRoutePattern
    .replace(':merchantId', merchantId)
    .replace(':loyaltyAccountLookupToken', loyaltyAccountLookupToken);
