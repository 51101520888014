import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const TrashIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width="18" height="20" viewBox="0 0 18 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 3H16H14H12C12 1.35 10.65 0 9 0C7.35 0 6 1.35 6 3H4H2H0V5H2V16.75C2 18.54 3.66 20 5.7 20H12.3C14.34 20 16 18.54 16 16.75V5H18V3ZM9 2C9.55 2 10 2.45 10 3H8C8 2.45 8.45 2 9 2ZM14 16.75C14 17.43 13.22 18 12.3 18H5.7C4.78 18 4 17.43 4 16.75V5H14V16.75ZM12 7H10V16H12V7ZM6 7H8V16H6V7Z"
        fill={props.fill}
        fillOpacity="0.9"
      />
    </SvgIcon>
  );
};

export default TrashIcon;
