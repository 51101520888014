import i18n from 'i18n';
import { Card } from 'rpc/model/squareup/connect/v2/resources/card';
import nonTranslatableStrings from 'utils/nonTranslatableStrings';

/**
 * Used to convert the raw brand name supplied by the cards API into a more human friendly version.
 * @param cardBrand
 * @returns Translated, human friendly card brand.
 */
export const getHumanFriendlyCardBrand = (cardBrand: Card.Brand): string => {
  switch (cardBrand) {
    case Card.Brand.OTHER_BRAND: {
      return i18n.t('common.cardBrand.otherBrand');
    }
    case Card.Brand.VISA: {
      return nonTranslatableStrings.cardBrands.visa;
    }
    case Card.Brand.MASTERCARD: {
      return nonTranslatableStrings.cardBrands.mastercard;
    }
    case Card.Brand.AMERICAN_EXPRESS: {
      return nonTranslatableStrings.cardBrands.americanExpress;
    }
    case Card.Brand.DISCOVER: {
      return nonTranslatableStrings.cardBrands.discover;
    }
    case Card.Brand.DISCOVER_DINERS: {
      return nonTranslatableStrings.cardBrands.discover;
    }
    case Card.Brand.JCB: {
      return nonTranslatableStrings.cardBrands.jcb;
    }
    case Card.Brand.CHINA_UNIONPAY: {
      return nonTranslatableStrings.cardBrands.chinaUnionPay;
    }
    case Card.Brand.SQUARE_GIFT_CARD: {
      return nonTranslatableStrings.cardBrands.squareGiftCard;
    }
    case Card.Brand.SQUARE_CAPITAL_CARD: {
      return nonTranslatableStrings.cardBrands.squareCapitalCard;
    }
    case Card.Brand.INTERAC: {
      return nonTranslatableStrings.cardBrands.interac;
    }
    case Card.Brand.EFTPOS: {
      return nonTranslatableStrings.cardBrands.eftPos;
    }
    case Card.Brand.FELICA: {
      return nonTranslatableStrings.cardBrands.feliCa;
    }
    case Card.Brand.EBT: {
      return nonTranslatableStrings.cardBrands.ebt;
    }
    default: {
      return i18n.t('common.cardBrand.otherBrand');
    }
  }
};
