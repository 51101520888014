// eslint-disable-next-line filenames/match-exported
import { matchPath } from 'react-router-dom';
import { GIFT_CARD_DETAIL_URL_BASE } from 'routes/profile';

const LOYALTY_AUTHENTICATED_ROUTES = [
  {
    path: '/merchantportal/:merchantId/loyalty/lalt/:loyaltyAccountLookupToken',
  },
  {
    path: '/merchantportal/:merchantId/loyalty/lalt/:loyaltyAccountLookupToken/account',
  },
  {
    path: '/merchantportal/:merchantId/loyalty/lalt/:loyaltyAccountLookupToken/activity',
  },
  {
    path: '/merchantportal/:merchantId/loyalty/lalt/:loyaltyAccountLookupToken/earning',
  },
  {
    path: '/merchantportal/:merchantId/loyalty/lalt/:loyaltyAccountLookupToken/rewards',
  },
];

// These are intermediate redirect routes as we migrate traffic from old URLs from Loyalty Web
// to the new routes in Merchant Portal.
const LEGACY_LOYALTY_ROUTES = [
  {
    path: '/loyalty/claim/:claimablePointsToken',
  },
  {
    path: '/loyalty-stars/:claimablePointsToken',
  },
  {
    path: '/loyalty/profile/:loyaltyAccountLookupToken',
  },
  {
    path: '/loyalty-status/:loyaltyAccountLookupToken',
  },
  {
    path: '/outreach/welcome/:loyaltyAccountLookupToken',
  },
  {
    path: '/loyalty/:merchantId',
  },
];

export const MERCHANT_PORTAL_OVERVIEW_ROUTE_PATTERN =
  '/merchantportal/:merchantId';

const ROUTE_PATTERNS = [
  { path: '/manage/not-your-receipt/:id' },
  { path: '/manage/marketing/:id' },
  { path: '/manage/receipt/:id' },
  { path: '/privacyrequests' },
  { path: '/signin' },
  { path: '/link_coupon/:cashLinkToken/link' },
  { path: '/link_coupon/:cashLinkToken' },
  { path: '/v2_link_coupon/:cashLinkToken/link' },
  { path: '/v2_link_coupon/:cashLinkToken' },
  { path: '/on-file-management/:baToken' },
  { path: '/verify_email/:verifyEmailToken' },
  { path: '/report-message' },
  { path: '/not-your-receipt/:receiptToken' },
  { path: MERCHANT_PORTAL_OVERVIEW_ROUTE_PATTERN },
  { path: '/merchantportal/:merchantId/loyalty' },
  ...LOYALTY_AUTHENTICATED_ROUTES,
  ...LEGACY_LOYALTY_ROUTES,
  { path: '/merchantportal/:merchantId/appointments' },
  { path: '/account' },
  { path: '/paymentmethods' },
  { path: '/paymentmethods/giftcard/:giftCardToken' },
  { path: `${GIFT_CARD_DETAIL_URL_BASE}/add` },
  { path: '/paymentmethods/:paymentCardId' },
  { path: '/purchasehistory' },
  { path: '/purchasehistory/:paymentId' },
  { path: '/receiptsettings' },
  { path: '/marketingpreferences' },
  { path: '/' },
];

const wildcardPathParameters = (rawPath: string) => {
  const matchingRoutePattern = findMatchingRoutePattern(rawPath);
  if (matchingRoutePattern) {
    return matchingRoutePattern.replace(/:[^/]+/g, '?');
  }

  return null;
};

export const findMatchingRoutePattern = (rawPath: string) => {
  for (const route of ROUTE_PATTERNS) {
    const match = matchPath(rawPath, { path: route.path, exact: true });
    if (match) {
      return route.path;
    }
  }
  return null;
};

export default wildcardPathParameters;
