/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import '../../connect/v2/resources/card';
import '../../customers/request';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      buyerportal: {
        nested: {
          cards: {
            nested: {
              Card: {
                fields: {
                  cardId: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  cardBrand: {
                    type: 'squareup.connect.v2.resources.Card.Brand',
                    id: 2,
                    rule: 'optional',
                  },
                  lastFour: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  expMonth: {
                    type: 'int64',
                    id: 4,
                    rule: 'optional',
                  },
                  expYear: {
                    type: 'int64',
                    id: 5,
                    rule: 'optional',
                  },
                  preferenceCardId: {
                    type: 'string',
                    id: 6,
                    rule: 'optional',
                  },
                  billingZip: {
                    type: 'string',
                    id: 7,
                    rule: 'optional',
                  },
                },
              },
              ListCardsRequest: {
                fields: {},
              },
              ListCardsResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                  cards: {
                    type: 'squareup.buyerportal.cards.Card',
                    id: 3,
                    rule: 'repeated',
                  },
                },
              },
              CreateCardRequest: {
                fields: {
                  idempotencyKey: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  cardNonce: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                },
              },
              CreateCardResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                  card: {
                    type: 'squareup.buyerportal.cards.Card',
                    id: 3,
                    rule: 'optional',
                  },
                },
              },
              DeleteCardRequest: {
                fields: {
                  cardId: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                },
              },
              DeleteCardResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.cards?.nested?.Card &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.cards?.nested
    ?.ListCardsRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.cards?.nested
    ?.ListCardsResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.cards?.nested
    ?.CreateCardRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.cards?.nested
    ?.CreateCardResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.cards?.nested
    ?.DeleteCardRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.cards?.nested
    ?.DeleteCardResponse
) {
  $root.addJSON(jsonData);
}

/**
 * Buyerportal representation of a credit/debit card used to display general card
 * information to users.
 *
 * SOURCE Card @ squareup/buyerportal/cards/data.proto at 17:1
 */
export const Card = $root.lookupType('squareup.buyerportal.cards.Card');
fixType(Card);
Builder.createAndAttachToType(Card);
/**
 * Request to list cards associated with the logged in profile. Pagination is not supported since
 * there are, at most, 10 cards on a profile.
 *
 * SOURCE ListCardsRequest @ squareup/buyerportal/cards/data.proto at 44:1
 */
export const ListCardsRequest = $root.lookupType(
  'squareup.buyerportal.cards.ListCardsRequest'
);
fixType(ListCardsRequest);
Builder.createAndAttachToType(ListCardsRequest);
/**
 * Response to list cards associated with the logged in profile.
 *
 * SOURCE ListCardsResponse @ squareup/buyerportal/cards/data.proto at 50:1
 */
export const ListCardsResponse = $root.lookupType(
  'squareup.buyerportal.cards.ListCardsResponse'
);
fixType(ListCardsResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(ListCardsResponse);
/**
 * Request to add a card via the provided card nonce.
 *
 * SOURCE CreateCardRequest @ squareup/buyerportal/cards/data.proto at 64:1
 */
export const CreateCardRequest = $root.lookupType(
  'squareup.buyerportal.cards.CreateCardRequest'
);
fixType(CreateCardRequest, {
  idempotencyKey: { required: true },
  cardNonce: { required: true },
});
Builder.createAndAttachToType(CreateCardRequest);
/**
 * Response to add a card via the provided card nonce.
 *
 * SOURCE CreateCardResponse @ squareup/buyerportal/cards/data.proto at 75:1
 */
export const CreateCardResponse = $root.lookupType(
  'squareup.buyerportal.cards.CreateCardResponse'
);
fixType(CreateCardResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(CreateCardResponse);
/**
 * Request to remove a card by the card token.
 *
 * SOURCE DeleteCardRequest @ squareup/buyerportal/cards/data.proto at 89:1
 */
export const DeleteCardRequest = $root.lookupType(
  'squareup.buyerportal.cards.DeleteCardRequest'
);
fixType(DeleteCardRequest, {
  cardId: { required: true },
});
Builder.createAndAttachToType(DeleteCardRequest);
/**
 * Response to remove a card by the card token.
 *
 * SOURCE DeleteCardResponse @ squareup/buyerportal/cards/data.proto at 97:1
 */
export const DeleteCardResponse = $root.lookupType(
  'squareup.buyerportal.cards.DeleteCardResponse'
);
fixType(DeleteCardResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(DeleteCardResponse);
