import {
  LoyaltyPromotion,
  LoyaltyPromotionIncentive,
  LoyaltyPromotionTriggerLimit,
  LoyaltyPromotionTriggerLimitInterval,
} from 'rpc/model/squareup/card/balance/loyalty_api/model/loyalty-promotion';
import i18n from 'i18n';
import { ParsedTimePeriod } from 'routes/merchant-scoped-portal/integrations/loyalty/utils/parseIcal';
import { Money } from 'rpc/model/squareup/connect/v2/common/money';
import { formatConnectMoney } from 'utils/currency';
import { LoyaltyProgramTerminology } from 'rpc/model/squareup/card/balance/loyalty_api/model/loyalty-program';
import { dayjs, DateFormat } from 'utils/date';
import { DEFAULT_POINTS_TERMINOLOGY } from 'routes/merchant-scoped-portal/integrations/loyalty/utils/program';

export function formatDateRange(promotion: LoyaltyPromotion): string {
  const startDateObj = dayjs(promotion.availableTime?.startDate);

  const endDate = promotion.canceledAt ?? promotion.availableTime?.endDate;
  if (!endDate) {
    return i18n.t(
      'loyalty.loyaltyPromotionDetailsModal.dateRange.startDateOnly',
      {
        startDate: startDateObj.format(DateFormat.ll),
      }
    );
  }

  const endDateObj = dayjs(endDate);
  if (endDateObj.isSame(startDateObj)) {
    // the promotion is only available on a single day
    return startDateObj.format(DateFormat.ll);
  }
  if (endDateObj.isBefore(startDateObj)) {
    // the promotion was canceled before it started
    return i18n.t(
      'loyalty.loyaltyPromotionDetailsModal.dateRange.endDateOnly',
      {
        endDate: endDateObj.format(DateFormat.ll),
      }
    );
  }
  return `${startDateObj.format(DateFormat.ll)} – ${endDateObj.format(
    DateFormat.ll
  )}`;
}

export function formatTimePeriod(parsedTimePeriod: ParsedTimePeriod) {
  const startTime = dayjs()
    .hour(parsedTimePeriod.hours.startTime.hour)
    .minute(parsedTimePeriod.hours.startTime.minute)
    .format('h:mm a');

  const endTime = dayjs()
    .hour(parsedTimePeriod.hours.endTime.hour)
    .minute(parsedTimePeriod.hours.endTime.minute)
    .format('h:mm a');

  const weekday = dayjs().day(parsedTimePeriod.weekday).format('ddd');

  return `${weekday} ${startTime} – ${endTime}`;
}

export function getDetailsText(
  minimumSpendAmountMoney: Money | undefined,
  triggerLimit: LoyaltyPromotionTriggerLimit | undefined
) {
  const minimumPurchaseRequiredText = minimumSpendAmountMoney?.amount
    ? i18n.t(
        'loyalty.loyaltyPromotionDetailsModal.minimumPurchaseRequiredText',
        {
          formattedAmount: formatConnectMoney(minimumSpendAmountMoney),
        }
      )
    : null;

  let triggerLimitText = null;
  if (triggerLimit?.interval === LoyaltyPromotionTriggerLimitInterval.DAY) {
    triggerLimitText =
      triggerLimit.times === 1
        ? i18n.t(
            'loyalty.loyaltyPromotionDetailsModal.triggerLimitText.day.one'
          )
        : i18n.t(
            'loyalty.loyaltyPromotionDetailsModal.triggerLimitText.day.other',
            {
              count: triggerLimit.times,
            }
          );
  }
  if (
    triggerLimit?.interval === LoyaltyPromotionTriggerLimitInterval.ALL_TIME
  ) {
    triggerLimitText =
      triggerLimit.times === 1
        ? i18n.t(
            'loyalty.loyaltyPromotionDetailsModal.triggerLimitText.allTime.one'
          )
        : i18n.t(
            'loyalty.loyaltyPromotionDetailsModal.triggerLimitText.allTime.other',
            {
              count: triggerLimit.times,
            }
          );
  }

  return [minimumPurchaseRequiredText, triggerLimitText]
    .filter((text) => text !== null)
    .join(' ');
}

export function getEarningRuleDescription(
  promotion: LoyaltyPromotion,
  terminology: LoyaltyProgramTerminology = DEFAULT_POINTS_TERMINOLOGY
) {
  switch (promotion.incentive?.type) {
    case LoyaltyPromotionIncentive.Type.POINTS_MULTIPLIER: {
      return i18n.t('loyalty.tiers.benefits.pointsMultiplier', {
        multiplier: Number(
          promotion.incentive?.pointsMultiplierData?.multiplier
        ),
        pointsTerminology: terminology.other,
      });
    }
    case LoyaltyPromotionIncentive.Type.POINTS_ADDITION: {
      return i18n.t('loyalty.tiers.benefits.pointsAddition', {
        count: promotion.incentive?.pointsAdditionData?.pointsAddition,
        pointsTerminology:
          promotion.incentive?.pointsAdditionData?.pointsAddition === 1
            ? terminology.one
            : terminology.other,
      });
    }
    default: {
      return '';
    }
  }
}

export function getQualifyingCategoriesText(
  qualifyingCategoryNames?: readonly string[]
): string {
  if (!qualifyingCategoryNames?.length) {
    return '';
  }

  if (qualifyingCategoryNames.length === 1) {
    return i18n.t(
      'loyalty.loyaltyPromotionDetailsModal.qualifyingCategories.one',
      {
        category: qualifyingCategoryNames[0],
      }
    );
  }
  return i18n.t(
    'loyalty.loyaltyPromotionDetailsModal.qualifyingCategories.other',
    {
      formattedCategoryList: qualifyingCategoryNames.slice(0, -1).join(', '),
      lastCategory: qualifyingCategoryNames.slice(-1)[0],
    }
  );
}

export function getQualifyingItemsText(
  qualifyingItemNames?: readonly string[]
): string {
  if (!qualifyingItemNames?.length) {
    return '';
  }

  if (qualifyingItemNames.length === 1) {
    return i18n.t('loyalty.loyaltyPromotionDetailsModal.qualifyingItems.one', {
      item: qualifyingItemNames[0],
    });
  }
  return i18n.t('loyalty.loyaltyPromotionDetailsModal.qualifyingItems.other', {
    formattedItemList: qualifyingItemNames.slice(0, -1).join(', '),
    lastItem: qualifyingItemNames.slice(-1)[0],
  });
}
