import React from 'react';
import { MarketSvgProps } from '.';

const Human: React.FC<MarketSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || 24}
      height={props.height || 24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 7C14 5.9 13.1 5 12 5C10.9 5 10 5.9 10 7C10 8.1 10.9 9 12 9C13.1 9 14 8.1 14 7ZM8 7C8 4.79 9.79 3 12 3C14.21 3 16 4.79 16 7C16 9.21 14.21 11 12 11C9.79 11 8 9.21 8 7ZM18 21H20C20 16.59 16.41 13 12 13C7.59 13 4 16.59 4 21H6C6 17.69 8.69 15 12 15C15.31 15 18 17.69 18 21Z"
        fill={props.fill || '#000'}
      />
    </svg>
  );
};

export default Human;
