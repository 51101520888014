const FEATURE = 'receipts_settings';

export const viewReceiptSettingsProperties = (
  verifiedCardIds: string[],
  defaultEmailId?: string,
  defaultPhoneId?: string,
  allAutomaticReceipts?: boolean,
  selectedCardId?: string
) => {
  return {
    pageTitle: 'Receipt Settings',
    defaultEmailId,
    defaultPhoneId,
    allAutomaticReceipts,
    selectedCardId,
    verifiedCardIds: verifiedCardIds.join(', '),
  };
};

export const actionUpdateLinkedEmailProperties = (
  emailId: string,
  selectedCardId: string
) => {
  return {
    feature: FEATURE,
    emailId,
    selectedCardId,
  };
};

export const actionAddEmailProperties = (selectedCardId: string) => {
  return {
    feature: FEATURE,
    selectedCardId,
  };
};

export const actionUpdateLinkedPhoneProperties = (
  phoneId: string,
  selectedCardId: string
) => {
  return {
    feature: FEATURE,
    phoneId,
    selectedCardId,
  };
};

export const actionToggleAutoReceipts = (
  toggledOn: boolean,
  merchantToken?: string
) => {
  return {
    feature: FEATURE,
    toggledOn,
    merchantToken,
  };
};
