import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LogoIcon } from 'svgs';
import Es2Tracker from 'services/tracking/tracker';
import nonTranslatableStrings from 'utils/nonTranslatableStrings';
import { MarketButton } from '@market/react';
import { privacyRequestsSuccessDeleteViewEvent } from 'services/tracking/events/privacyRequests';
import { IdentifierType } from 'routes/profile/models/Identifier';

type SuccessDeleteDataProps = {
  identifierType: IdentifierType;
  identifierValue: string;
  onRestart: () => void;
};

const SuccessDeleteData: React.FC<SuccessDeleteDataProps> = (props) => {
  const { t } = useTranslation(undefined, { useSuspense: false });

  useEffect(() => {
    Es2Tracker.track(
      privacyRequestsSuccessDeleteViewEvent(props.identifierType)
    );
  }, [props.identifierType]);

  return (
    <>
      <LogoIcon className={'text-[32px]'} />
      <h2 className={'mb-4 heading-30'}>
        {t('privacyManagement.successDeleteData.title')}
      </h2>
      <p className={'mt-0 mb-6 paragraph-30'}>
        {t('privacyManagement.successDeleteData.subtitle', {
          identifierValue: props.identifierValue,
        })}
      </p>
      <p className={'mt-0 mb-6 paragraph-30'}>
        {t('privacyManagement.successDeleteData.subtitle2', {
          appName: nonTranslatableStrings.square,
        })}
      </p>
      <p className={'mt-0 mb-6 paragraph-30'}>
        {t('privacyManagement.successDeleteData.subtitle3')}
      </p>
      <MarketButton rank={'secondary'} onClick={props.onRestart}>
        {t('privacyManagement.successDeleteData.newRequest')}
      </MarketButton>
    </>
  );
};

export default SuccessDeleteData;
