import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import {
  BoolFlag,
  CreateDefaultBoolFlagConfig,
  Flags,
  FlagConverter,
  PartialFlags,
  AnonymousBoolFlag,
} from 'routes/profile/models/Flags';
import { DataType } from 'rpc/model/squareup/feature/relay/common/data_type';
import { GetFlagRequest } from 'rpc/model/squareup/feature/relay/flags/message/get_flags';
import { fetchFlags } from 'services/flags';
import { AppState } from 'store';
import { getOrSetSavt } from 'utils/cookies';

export type FeatureState = {
  flags: Flags;
  areAnonymousFlagsLoaded: boolean;
};

export const initialState: FeatureState = {
  flags: {
    boolean: CreateDefaultBoolFlagConfig(),
  },
  areAnonymousFlagsLoaded: false,
};

export const selectBoolFlag = createSelector(
  (state: AppState) => state.feature.flags,
  (_: AppState, flag: BoolFlag | AnonymousBoolFlag) => flag,
  (flags: Flags, flag: BoolFlag | AnonymousBoolFlag) => flags.boolean[flag]
);

export const fetchFeatures = createAsyncThunk<PartialFlags, string>(
  'feature/fetchFeatures',
  async (personToken: string) => {
    const flags = await fetchFlags(
      Object.values(BoolFlag).map((flag) => {
        return new GetFlagRequest.Builder()
          .flagName(flag)
          .flagType(DataType.BOOLEAN)
          .build();
      }),
      personToken
    );

    return FlagConverter.fromRpcGetFlagsResponse(flags);
  }
);

export const fetchAnonymousFeatures = createAsyncThunk<PartialFlags>(
  'feature/fetchAnonymousFeatures',
  async () => {
    const savt = getOrSetSavt();
    const flags = await fetchFlags(
      Object.values(AnonymousBoolFlag).map((flag) => {
        return new GetFlagRequest.Builder()
          .flagName(flag)
          .flagType(DataType.BOOLEAN)
          .build();
      }),
      savt
    );

    return FlagConverter.fromRpcGetFlagsResponse(flags);
  }
);

export const fetchAnonymousFeaturesNoStore = async () => {
  const savt = getOrSetSavt();
  const flags = await fetchFlags(
    Object.values(AnonymousBoolFlag).map((flag) => {
      return new GetFlagRequest.Builder()
        .flagName(flag)
        .flagType(DataType.BOOLEAN)
        .build();
    }),
    savt
  );

  return FlagConverter.fromRpcGetFlagsResponse(flags);
};

const featureSlice = createSlice({
  name: 'feature',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFeatures.fulfilled, (state, action) => {
      state.flags.boolean = {
        ...state.flags.boolean,
        ...action.payload.boolean,
      };
    });

    builder.addCase(fetchAnonymousFeatures.fulfilled, (state, action) => {
      state.areAnonymousFlagsLoaded = true;
      state.flags.boolean = {
        ...state.flags.boolean,
        ...action.payload.boolean,
      };
    });

    builder.addCase(fetchAnonymousFeatures.rejected, (state) => {
      state.areAnonymousFlagsLoaded = true;
    });
  },
});

export default featureSlice.reducer;
