import React, { HTMLAttributes, useState } from 'react';
import { MarketLink, MarketList } from '@market/react';
import RewardTier from './RewardTier';
import { LoyaltyProgramRewardTier } from 'rpc/model/squareup/card/balance/loyalty_api/model/loyalty-program';
import { selectAccount } from 'routes/merchant-scoped-portal/integrations/loyalty/loyaltySlice';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line no-undef
interface RewardTierProps extends HTMLAttributes<HTMLMarketListElement> {
  rewardTiers: readonly LoyaltyProgramRewardTier[];
}

const DEFAULT_NUM_REWARDS_TO_SHOW = 5;

const RewardTierList: React.FC<RewardTierProps> = ({
  rewardTiers,
  ...props
}) => {
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState(false);
  const accountInfo = useSelector((state: AppState) =>
    selectAccount(state.loyaltyMerchantPortal)
  );

  const isLocked = (rewardTier: LoyaltyProgramRewardTier) => {
    if (!accountInfo?.loyaltyAccount) {
      return false;
    }
    return rewardTier.points > accountInfo?.loyaltyAccount?.balance;
  };

  const rewardTiersToShow = showAll
    ? rewardTiers
    : rewardTiers.slice(0, DEFAULT_NUM_REWARDS_TO_SHOW);

  return (
    <>
      <MarketList {...props}>
        {rewardTiersToShow.map((rewardTier) => (
          <RewardTier
            key={rewardTier.id}
            rewardTier={rewardTier}
            isLocked={isLocked(rewardTier)}
          ></RewardTier>
        ))}
      </MarketList>
      {!showAll && rewardTiers.length > DEFAULT_NUM_REWARDS_TO_SHOW && (
        <div className="mb-5 text-center">
          <MarketLink
            data-testid="loyalty__reward-tier-list__show-all"
            onClick={() => setShowAll(true)}
          >
            {t('loyalty.rewardsList.showAllRewards')}
          </MarketLink>
        </div>
      )}
    </>
  );
};

export default RewardTierList;
