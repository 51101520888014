// eslint-disable-next-line filenames/match-exported
import React from 'react';
import { LogoIcon } from 'svgs';
import { MarketButton } from '@market/react';
import { useTranslation } from 'react-i18next';

const VerifyEmailThroughEmailTokenFailure: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div
      className={
        'flex flex-col relative align-self-center xs:max-w-[440px] w-screen h-4/5 content-center justify-center'
      }
    >
      <div className={'top-1/2 flex flex-col justify-center'}>
        {<LogoIcon fontSize={'large'} className={'relative top-5'} />}

        <div className={'pt-10'}>
          <h2 className={'font-bold pb-5'}>
            {t('unauthenticatedVerifyEmail.invalid.title')}
          </h2>

          <p className={'text-base pb-5'}>
            {t('unauthenticatedVerifyEmail.invalid.subtitle')}
          </p>
        </div>

        {
          <MarketButton rank={'primary'} href={'/'}>
            {t('unauthenticatedVerifyEmail.signIn')}
          </MarketButton>
        }
      </div>
    </div>
  );
};

export default VerifyEmailThroughEmailTokenFailure;
