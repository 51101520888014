import { getCountryFromLocale } from 'utils/globalAddress';
import { Country } from 'rpc/model/squareup/common/countries';

import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import { languageDetector } from 'i18n';

export function getPhoneNumber(
  number: string,
  callingCode: string | undefined,
  merchantCountry: Country | undefined
) {
  try {
    // parse number to PhoneNumber object
    let parsedNumber;
    if (callingCode) {
      parsedNumber = parsePhoneNumber(callingCode + number);
    } else if (merchantCountry) {
      parsedNumber = parsePhoneNumber(
        number,
        Country[merchantCountry] as CountryCode
      );
    } else {
      return number;
    }

    // get locale and display in phone format in user's locale
    const locale: string | string[] | undefined = languageDetector.detect();
    const userCountryCode = getCountryFromLocale(locale);

    return merchantCountry !== undefined && userCountryCode === merchantCountry
      ? parsedNumber.formatNational()
      : parsedNumber.formatInternational();
  } catch {
    return '';
  }
}
