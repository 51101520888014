import { CashLinkInfo } from 'rpc/model/squareup/buyerportal/cashlink/data';
import { Identifier } from 'rpc/model/squareup/buyerportal/profile/common';

/*
 * Derives and returns the identifier of type from cashLinkInfo.
 */
const getIdentifier = (
  cashLinkInfo: CashLinkInfo,
  identifierType: Identifier.Type
) => {
  if (!cashLinkInfo) {
    return null;
  }

  const { deliveryIdentifier, linkedIdentifier } = cashLinkInfo;

  if (deliveryIdentifier?.type === identifierType) {
    return deliveryIdentifier;
  }

  if (linkedIdentifier?.type === identifierType) {
    return linkedIdentifier;
  }

  return null;
};

export { getIdentifier };
