import {
  NotificationStore,
  NotificationActionTypes,
  NOTIFICATION_DISPLAY_MESSAGE,
  NOTIFICATION_HIDE_MESSAGE,
} from './types';

export const initialState: NotificationStore = {
  open: false,
};

export function notificationReducer(
  state = initialState,
  action: NotificationActionTypes
): NotificationStore {
  switch (action.type) {
    case NOTIFICATION_DISPLAY_MESSAGE: {
      return { ...state, open: true, message: action.message };
    }
    case NOTIFICATION_HIDE_MESSAGE: {
      return { ...state, open: false, message: undefined };
    }
    default: {
      return state;
    }
  }
}
