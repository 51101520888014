/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import '../common/time';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      roster: {
        nested: {
          mds: {
            nested: {
              Hours: {
                fields: {
                  dayOfWeek: {
                    type: 'int32',
                    id: 1,
                    rule: 'optional',
                  },
                  openIntervals: {
                    type: 'squareup.roster.mds.Hours.Interval',
                    id: 2,
                    rule: 'repeated',
                  },
                },
                nested: {
                  Interval: {
                    fields: {
                      start: {
                        type: 'squareup.common.time.LocalTime',
                        id: 1,
                        rule: 'optional',
                      },
                      durationMinutes: {
                        type: 'int32',
                        id: 2,
                        rule: 'optional',
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.roster?.nested?.mds?.nested?.Hours &&
  !$root.nested?.squareup?.nested?.roster?.nested?.mds?.nested?.Hours?.nested
    ?.Interval
) {
  $root.addJSON(jsonData);
}

/**
 * Deprecated along with its parent, UnitHoursData. Use top-level "NormalBusinessHours" field on Unit instead
 *
 * SOURCE Hours @ squareup/roster/mds.proto at 726:1
 */
export const Hours = $root.lookupType('squareup.roster.mds.Hours');
fixType(Hours);
Builder.createAndAttachToType(Hours);
/**
 * SOURCE Interval @ squareup/roster/mds.proto at 732:3
 */
Hours.Interval = $root.lookupType('squareup.roster.mds.Hours.Interval');
fixType(Hours.Interval);
Builder.createAndAttachToType(Hours.Interval);
