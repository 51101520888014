import { Money } from 'routes/profile/models/GiftCard';
import {
  Money as ConnectV2Money,
  Currency,
} from 'rpc/model/squareup/connect/v2/common/money';
import { languageDetector, extractPrimaryLocale } from 'i18n';

const DECIMAL_SEPARATOR_PATTERN = /([\d,]+)(\.\d*)?/;
const COMMA_SEPARATOR_PATTERN = /([\d\s]+)(,\d*)?/;
const DEFAULT_FALLBACK_LOCALE = 'en-US';

function usesCents(currency: Currency) {
  return currency !== Currency.JPY;
}

export function formatConnectMoney(
  money: ConnectV2Money,
  opts: Intl.NumberFormatOptions = {}
): string {
  const locale =
    extractPrimaryLocale(languageDetector.detect()) ?? DEFAULT_FALLBACK_LOCALE;
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: Currency[money.currency],
    ...opts,
  });

  let amount = money.amount;
  if (usesCents(money.currency)) {
    amount /= 100;
  }

  return formatter.format(amount);
}

export function format(money: Money): string {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: money.currencyCode,
  });

  return formatter.format(money.amount / 100);
}

function usesCommaSeparator(locale?: string) {
  return locale && ['fr', 'fr-ca'].includes(locale.toLowerCase());
}

/**
 * Handles converting user currency input to a raw numerical amount without formatting.
 * Includes cents for applicable currency codes.
 *
 * Ex USD: 1,000.59 => 100059
 * Ex CAD: 1 000,59 => 100059
 * Ex JPY: 100,059 => 100059
 */
export function getMoneyFromFormattedString(
  formattedString: string,
  currencyCode: string,
  locale?: string
) {
  const pattern = usesCommaSeparator(locale)
    ? COMMA_SEPARATOR_PATTERN
    : DECIMAL_SEPARATOR_PATTERN;
  const matches = formattedString.match(pattern);
  const beforeSeparator =
    matches &&
    matches[1] &&
    Number.parseInt(matches[1].replace(/[\s,.]/, ''), 10);
  const afterSeparator =
    matches && matches[2] ? matches[2].replace(/,/, '.') : '';
  let amount = Number(beforeSeparator) + Number(afterSeparator);

  if (Number.isNaN(amount)) {
    return {
      amount: 0,
      currencyCode,
    };
  }
  // USD and CAD are in cents, JPY is not.
  if (currencyCode !== 'JPY') {
    amount *= 100;
  }
  return {
    amount,
    currencyCode,
  };
}
