import {
  MarketAccessory,
  MarketButton,
  MarketDropdown,
  MarketList,
  MarketPill,
  MarketPopover,
  MarketRow,
} from '@market/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectIdentifierCollection, selectPrimaryId } from 'store/buyerSlice';
import {
  ContactInformationIdentifierType,
  Identifier,
  IdentifierType,
} from 'routes/profile/models/Identifier';
import { buyerportalCdpClient } from 'services/tracking/cdp/clients/buyerportal';
import {
  contactInfoRemoveProperties,
  contactInfoSetAsDefaultProperties,
  startUpdateProfileProperties,
} from 'services/tracking/cdp/events/profile';
import { AppState } from 'store';
import { ModalType, openModal } from 'store/modalSlice';
import {
  useDeleteVerifiedIdentifierMutation,
  useUpdatePrimaryIdentifierIdMutation,
} from 'store/query';
import { openToast } from 'store/toastSlice';
import Call from 'svgs/market-icons/Call';
import EllipsisHorizontal from 'svgs/market-icons/EllipsisHorizontal';
import Envelope from 'svgs/market-icons/Envelope';
import { theme } from 'tailwind.config';
import { useTranslation } from 'react-i18next';

type VerifiedIdentifierProps = {
  identifier: Identifier;
  isDefault: boolean;
  hasMultipleOfType: boolean;
  canRemove: boolean;
  className?: string;
};

const VerifiedIdentifier: React.FC<VerifiedIdentifierProps> = (props) => {
  const dispatch = useDispatch();

  const [updatePrimaryIdentifierId] = useUpdatePrimaryIdentifierIdMutation();
  const [deleteVerifiedIdentifier] = useDeleteVerifiedIdentifierMutation();

  const { t } = useTranslation();

  if (
    props.identifier.identifierType !== IdentifierType.Email &&
    props.identifier.identifierType !== IdentifierType.Phone
  ) {
    throw new Error(
      'VerifiedIdentifier props.identifier must be of type IdentifierType.Email or IdentifierType.Phone'
    );
  }

  const setAsPrimary = async () => {
    await updatePrimaryIdentifierId({
      id: props.identifier.token,
      identifierType: props.identifier.identifierType,
    })
      .unwrap()
      .then(() => {
        buyerportalCdpClient.track(
          'Set As Default',
          contactInfoSetAsDefaultProperties(
            props.identifier.identifierType as ContactInformationIdentifierType
          )
        );

        dispatch(
          openToast({
            variant: 'success',
            message: t('common.changeSuccess'),
          })
        );
      })
      .catch(() => {
        dispatch(
          openToast({
            variant: 'critical',
            message: t('common.somethingWentWrong.retryable.direct'),
          })
        );
      });
  };

  const deleteIdentifier = async () => {
    await deleteVerifiedIdentifier(props.identifier)
      .unwrap()
      .then(() => {
        buyerportalCdpClient.track(
          'Remove Details',
          contactInfoRemoveProperties(
            props.identifier.identifierType as ContactInformationIdentifierType
          )
        );

        dispatch(
          openToast({
            variant: 'success',
            message: t('profile.account.contactInfo.contactInfoRemoved'),
          })
        );
      })
      .catch(() => {
        dispatch(
          openToast({
            variant: 'critical',
            message: t('common.somethingWentWrong.retryable.direct'),
          })
        );
      });
  };

  const getIcon = () => {
    switch (props.identifier.identifierType) {
      case IdentifierType.Email: {
        return <Envelope />;
      }
      case IdentifierType.Phone: {
        return <Call />;
      }
      default: {
        return null;
      }
    }
  };

  const getLabel = () => {
    switch (props.identifier.identifierType) {
      case IdentifierType.Email: {
        return t('profile.account.contactInfo.emailAddress');
      }
      case IdentifierType.Phone: {
        return t('profile.account.contactInfo.phoneNumber');
      }
      default: {
        return t('profile.account.contactInfo.unknown');
      }
    }
  };

  const canSetPrimary = !props.isDefault && props.hasMultipleOfType;

  return (
    <div className={`flex items-center py-4 ${props.className}`}>
      <MarketAccessory size={'icon'} slot={'icon'}>
        {getIcon()}
      </MarketAccessory>
      <div className={'ml-4'}>
        <label className={'font-medium'}>{getLabel()}</label>
        <div className={'paragraph-20 text-grey'}>
          {props.identifier.displayValue}
        </div>
      </div>
      {(canSetPrimary || props.canRemove) && (
        <div className={'ml-auto flex items-center'}>
          {props.isDefault && props.hasMultipleOfType && (
            <MarketPill className={'mr-4'}>{t('common.default')}</MarketPill>
          )}
          <MarketDropdown popoverPlacement={'bottom-start'}>
            <MarketAccessory size={'icon'} slot={'trigger'} role={'button'}>
              <EllipsisHorizontal fill={theme.extend.colors.grey} />
            </MarketAccessory>
            <MarketPopover slot={'popover'}>
              <MarketList interactive transient>
                {canSetPrimary && (
                  <MarketRow className={'medium-30'} onClick={setAsPrimary}>
                    {t('common.setAsDefault')}
                  </MarketRow>
                )}
                <MarketRow
                  className={'medium-30 text-criticalRed'}
                  onClick={deleteIdentifier}
                >
                  {t('common.remove')}
                </MarketRow>
              </MarketList>
            </MarketPopover>
          </MarketDropdown>
        </div>
      )}
    </div>
  );
};

const ContactInfo: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const emails = useSelector((state: AppState) =>
    selectIdentifierCollection(state, IdentifierType.Email)
  );
  const phones = useSelector((state: AppState) =>
    selectIdentifierCollection(state, IdentifierType.Phone)
  );
  const primaryEmailId = useSelector((state: AppState) =>
    selectPrimaryId(state, IdentifierType.Email)
  );
  const primaryPhoneId = useSelector((state: AppState) =>
    selectPrimaryId(state, IdentifierType.Phone)
  );

  const addIdentifier = () => {
    buyerportalCdpClient.track(
      'Start Update Details',
      startUpdateProfileProperties('add', 'contact info')
    );

    dispatch(
      openModal({
        type: ModalType.AddContactInfo,
      })
    );
  };

  const canRemoveIdentifier = emails.length + phones.length > 1;

  return (
    <>
      <div className={'flex grow items-center justify-between mb-4'}>
        <h3 className={'heading-20 my-0'}>
          {t('profile.account.contactInfo.contactInfo')}
        </h3>
        <MarketButton rank={'tertiary'} size={'small'} onClick={addIdentifier}>
          {t('common.add')}
        </MarketButton>
      </div>
      <ul className={'list-none p-0 m-0'}>
        {emails.map((email, index) => (
          <li key={email.token}>
            <VerifiedIdentifier
              identifier={email}
              isDefault={email.token === primaryEmailId}
              hasMultipleOfType={emails.length > 1}
              canRemove={canRemoveIdentifier}
              className={
                index !== emails.length - 1 || phones.length > 0
                  ? 'shadow-bottomDivider'
                  : ''
              }
            />
          </li>
        ))}
        {phones.map((phone, index) => (
          <li key={phone.token}>
            <VerifiedIdentifier
              identifier={phone}
              isDefault={phone.token === primaryPhoneId}
              hasMultipleOfType={phones.length > 1}
              canRemove={canRemoveIdentifier}
              className={
                index === phones.length - 1 ? '' : 'shadow-bottomDivider'
              }
            />
          </li>
        ))}
      </ul>
    </>
  );
};

export default ContactInfo;
