/** NOTE: See https://square.slack.com/archives/CTU91JFU0/p1665512412775839.
 * InModal is not available as an importable package yet and has to be created
 * as a new file as of (Apr 10, 2023).
 * -----------------------------------
 **/

import { DialogElement } from '@market/web-components';
import React, { FunctionComponent, ReactNode, useEffect, useRef } from 'react';

interface InModalProps {
  children: ReactNode;
}

/**
 *
 * Market modals, and Web Components in general, make use of the `<template>` tag.
 * However, this paradigm really doesn't work well with React.
 *
 * Our goal here is to create a simple component that, when it enters the DOM, it pushes its
 * children into a market-context-manager with the approprirate market modal. When this component
 * leaves the DOM, we want the modal to close, similar to the Market Ember {{open-modal}} modifier
 *
 * We need 3 pieces:
 * 1. An element still in the normal DOM and document flow, outside of the market context. This way, we know
 * when to dismiss the modal when it goes out of the DOM. We make this `display: none` to ensure it doesn't appear
 * to the user.
 * 2. The contents of the modal, where the top level node is one
 * of a `MarketModalPartial`, `MarketModalFull`, `MarketDialog`, or `MarketBlade`.
 *
 * With these, we do the following:
 * 1. Get a ref to the normal container element on the page
 * 2. Get the market-context-manager from the DOM.
 * 3. Call the context manager's open() function with the first child of our generic container element in the
 * normal document flow. Market's context manager will copy that, and render it in the modal.
 *
 * Now you can create modals using normal `useState` to control the visibility.
 *
 * Note that this relies on you actually creating a MarketContextManager first in the document
 *
 * Usage
 * ```tsx
 * {showModal &&
 *   <InModal>
 *     <MarketModalPartial onMarketDialogDismissed={() => setShowModal(false)}>
 *       <MarketHeader>Hello There!</MarketHeader>
 *       <main>This is some content</main>
 *   </MarketModalPartial>
 * </InModal>}
 * ```
 */
const InModal: FunctionComponent<InModalProps> = ({ children }) => {
  // A container created to house the modal. It's also our anchor
  // to tell us when this element disappears from the DOM
  const containerRef = useRef<HTMLDivElement>(null);

  // Ensure we don't open the modal multiple times per render
  const isOpenRef = useRef<boolean>(false);

  const MARKET_CONTEXT_MANAGER_TAG_NAME = 'market-context-manager';
  const getMarketContextManager = () => {
    return document.querySelector(MARKET_CONTEXT_MANAGER_TAG_NAME);
  };

  useEffect(() => {
    const contextManager = getMarketContextManager();
    const currentContainerRef = containerRef.current;

    async function setupModal() {
      if (!contextManager) {
        return;
      }

      if (isOpenRef.current) {
        return;
      }

      isOpenRef.current = true;
      if (currentContainerRef?.firstElementChild) {
        await contextManager.open(
          currentContainerRef.firstElementChild as DialogElement,
          false
        );
      }
    }

    setupModal().catch(() => null);

    return () => {
      // In the tests, js-dom will not infer the correct type for contextManager
      // we therefore have to check that the close function exists.
      if (!currentContainerRef && contextManager && contextManager.close) {
        contextManager.close().catch(() => null);
      }
    };
  }, []);

  return <div ref={containerRef}>{children}</div>;
};

export { InModal };
