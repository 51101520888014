import React from 'react';
import { MarketList, MarketRow } from '@market/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BoolFlag } from 'routes/profile/models/Flags';
import { faqClickArticleActionEvent } from 'services/tracking/events';
import { selectBoolFlag } from 'store/featureSlice';
import Es2Tracker from 'services/tracking/tracker';
import SqLink from 'components/SqLink';
import { getDashboardDomain } from 'config/config';

const getQuestions = (useAppSubdomain: boolean) => [
  {
    key: 'preference.components.notYourReceiptFaq.question.unsubscribeAutomaticReceipts',
    url: `https://${getDashboardDomain(
      useAppSubdomain
    )}/help/article/5212#how-can-i-unsubscribe-from-automatic-receipts`,
  },
  {
    key: 'preference.components.notYourReceiptFaq.question.incorrectReceipt',
    url: `https://${getDashboardDomain(useAppSubdomain)}/help/article/4989`,
  },
  {
    key: 'preference.components.notYourReceiptFaq.question.resubscribe',
    url: `https://${getDashboardDomain(
      useAppSubdomain
    )}/help/article/5212#resubscribe`,
  },
  {
    key: 'preference.components.notYourReceiptFaq.question.sharedCard',
    url: `https://${getDashboardDomain(
      useAppSubdomain
    )}/help/article/5213-shared-cards-with-automatic-receipts`,
  },
];

const ReceiptFAQ: React.FC = () => {
  const { t } = useTranslation();

  const useAppSubdomain = useSelector((state: AppState) =>
    selectBoolFlag(state, BoolFlag.useAppSubdomain)
  );

  const questions = getQuestions(useAppSubdomain);

  return (
    <>
      <h3 className={'mb-[5px]'}>
        {t('preference.components.notYourReceiptFaq.title')}
      </h3>

      <MarketList>
        {questions.map((question) => (
          <MarketRow>
            <SqLink
              href={question.url}
              target="_blank"
              onClick={() =>
                Es2Tracker.track(faqClickArticleActionEvent(question.url))
              }
            >
              <span className={'color-blue font-semibold text-base'}>
                {t(question.key)}
              </span>
            </SqLink>
          </MarketRow>
        ))}
      </MarketList>
    </>
  );
};

export default ReceiptFAQ;
