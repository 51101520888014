/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      cost: {
        nested: {
          FeeClass: {
            values: {
              DEFAULT_FEECLASS_ENUM_DO_NOT_USE: 0,
              ASSESSMENT: 1,
              INTERCHANGE: 2,
              PROCESSING: 3,
              SERVICE: 4,
            },
          },
          Acquirer: {
            values: {
              DEFAULT_ACQUIRER_ENUM_DO_NOT_USE: 0,
              PAYMENTECH: 1,
              WELLS_FARGO: 2,
              AMEX: 3,
              OMNIPAY: 4,
              ACCULYNK: 5,
              INTERAC: 6,
              JCB: 7,
              SMCC: 8,
              CUSCAL: 9,
              NYCE: 10,
              EFTPOS: 11,
              VISA_DEX: 12,
              EMONEY: 13,
              CASH_APP: 14,
              MASTERCARD_MIP: 15,
              AFTERPAY: 16,
              WELLS_ACH: 17,
              PAYPAY: 18,
              TRUELAYER: 19,
              DGFT: 20,
            },
          },
          Action: {
            values: {
              DEFAULT_ACTION_ENUM_DO_NOT_USE: 0,
              UNKNOWN: 1,
              AUTH: 2,
              CAPTURE: 3,
              REFUND: 4,
              VOID: 5,
              CHARGEBACK: 6,
              REPRESENTMENT: 7,
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.cost?.nested?.FeeClass &&
  !$root.nested?.squareup?.nested?.cost?.nested?.Acquirer &&
  !$root.nested?.squareup?.nested?.cost?.nested?.Action
) {
  $root.addJSON(jsonData);
}

/**
 * Types of fees
 *
 * SOURCE FeeClass @ squareup/cost/model.proto at 14:1
 */
export const FeeClass = $root.lookupEnum('squareup.cost.FeeClass').values;
/**
 * List of acquirers we have costs for
 *
 * SOURCE Acquirer @ squareup/cost/model.proto at 25:1
 */
export const Acquirer = $root.lookupEnum('squareup.cost.Acquirer').values;
/**
 * List of actions that may cost Square fees.
 * N.B. some reports don't make it clear what action caused the fee
 *
 * SOURCE Action @ squareup/cost/model.proto at 78:1
 */
export const Action = $root.lookupEnum('squareup.cost.Action').values;
