import React, { useMemo } from 'react';
import BuyerCard from 'routes/profile/models/BuyerCard';
import MarketTab from 'components/profile/MarketTab';

type ReceiptCardTabsProps = {
  paymentCards?: BuyerCard[];
  primaryCardId?: string;
  selectedPaymentCardId?: string;
  onCardSelected: (cardsApiId: string) => void;
};

const ReceiptCardTabs: React.FC<ReceiptCardTabsProps> = ({
  paymentCards,
  primaryCardId,
  selectedPaymentCardId,
  onCardSelected,
}) => {
  const orderedPaymentCards = useMemo(() => {
    if (!paymentCards) {
      return [];
    }

    const primaryCardIdx = paymentCards.findIndex(
      (card) => card.cardsApiId === primaryCardId
    );
    if (primaryCardIdx === -1) {
      return paymentCards;
    }

    return [
      paymentCards[primaryCardIdx],
      ...paymentCards.slice(0, primaryCardIdx),
      ...paymentCards.slice(primaryCardIdx + 1),
    ];
  }, [paymentCards, primaryCardId]);

  return (
    <div className={'shadow-bottomDivider'}>
      {orderedPaymentCards.map((card) => {
        return (
          <MarketTab
            key={card.cardsApiId}
            id={card.cardsApiId}
            onMarketTabSelected={(selectedCardsApiId) =>
              onCardSelected(selectedCardsApiId)
            }
            selected={card.cardsApiId === selectedPaymentCardId}
          >
            <span data-testid={'card-display-name'}>{card.displayName}</span>
          </MarketTab>
        );
      })}
    </div>
  );
};

export default ReceiptCardTabs;
