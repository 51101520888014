import React, { useState, useEffect, useCallback } from 'react';
import {
  MarketButton,
  MarketCodeInput,
  MarketField,
  MarketHeader,
  MarketLink,
} from '@market/react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'store/modalSlice';
import { useTranslation } from 'react-i18next';
import {
  ContactInformationIdentifierType,
  IdentifierType,
} from 'routes/profile/models/Identifier';
import {
  useRequestAddProfileIdentifierMutation,
  useVerifyAddProfileIdentifierMutation,
} from 'store/query';
import { openToast } from 'store/toastSlice';
import { RequestStatus } from 'rpc/model/squareup/customers/request';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { buyerportalCdpClient } from 'services/tracking/cdp/clients/buyerportal';
import { saveUpdateProfileProperties } from 'services/tracking/cdp/events/profile';

type VerifyProps = {
  identifierType: ContactInformationIdentifierType;
  identifierValue: string;
};

const CODE_LENGTH = 6;

const Verify: React.FC<VerifyProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [code, setCode] = useState<string>('');
  const [verifyErrorStatus, setVerifyErrorStatus] =
    useState<RequestStatus | null>(null);

  const [requestAddProfileIdentifier, requestAddProfileIdentifierResult] =
    useRequestAddProfileIdentifierMutation();
  const [verifyAddProfileIdentifier, verifyAddProfileIdentifierResult] =
    useVerifyAddProfileIdentifierMutation();

  const verifyIdentifier = useCallback(async () => {
    await verifyAddProfileIdentifier({
      identifierType: props.identifierType,
      identifierValue: props.identifierValue,
      verificationCode: code,
    })
      .unwrap()
      .then(() => {
        buyerportalCdpClient.track(
          'Save Update Details',
          saveUpdateProfileProperties('add', props.identifierType)
        );

        dispatch(
          openToast({
            variant: 'success',
            message: t(
              'profile.account.contactInfo.addContactInfoModal.verify.contactInfoAdded'
            ),
          })
        );
        dispatch(closeModal());
      })
      .catch((ex) => {
        if ('data' in ex) {
          const apiError = ex as FetchBaseQueryError;
          setVerifyErrorStatus(Number(apiError.status));
        } else {
          dispatch(
            openToast({
              variant: 'critical',
              message: t('common.somethingWentWrong.retryable.direct'),
              persistent: true,
            })
          );
        }
      });
  }, [
    code,
    dispatch,
    props.identifierType,
    props.identifierValue,
    verifyAddProfileIdentifier,
    t,
  ]);

  const [shouldVerifyCode, setShouldVerifyCode] = useState<boolean>(false);
  useEffect(() => {
    if (shouldVerifyCode) {
      verifyIdentifier();
      setShouldVerifyCode(false);
    }
  }, [shouldVerifyCode, verifyIdentifier]);

  const requestAddIdentifier = async () => {
    await requestAddProfileIdentifier({
      identifierType: props.identifierType,
      identifierValue: props.identifierValue,
    })
      .unwrap()
      .catch(() => {
        dispatch(
          openToast({
            variant: 'critical',
            message: t('common.somethingWentWrong.retryable.direct'),
          })
        );
      });
  };

  const getErrorText = () => {
    switch (verifyErrorStatus) {
      case RequestStatus.STATUS_BAD_REQUEST: {
        return t(
          'profile.account.contactInfo.addContactInfoModal.verify.enterValidCode'
        );
      }
      case RequestStatus.STATUS_CONFLICT: {
        if (props.identifierType === IdentifierType.Email) {
          return t(
            'profile.account.contactInfo.addContactInfoModal.verify.emailConflict'
          );
        } else {
          return t(
            'profile.account.contactInfo.addContactInfoModal.verify.phoneNumberConflict'
          );
        }
      }
      default: {
        return t('common.somethingWentWrong.retryable.direct');
      }
    }
  };

  const isSaveButtonDisabled =
    code.length < CODE_LENGTH ||
    verifyAddProfileIdentifierResult.isLoading ||
    verifyErrorStatus !== null;

  return (
    <>
      <MarketHeader>
        <MarketButton
          rank={'primary'}
          slot={'actions'}
          onClick={verifyIdentifier}
          {...(isSaveButtonDisabled && { disabled: true })}
        >
          <span data-testid={'verify-btn-text'}>
            {verifyAddProfileIdentifierResult.isLoading
              ? t('common.adding')
              : t('common.add')}
          </span>
        </MarketButton>
      </MarketHeader>
      <h2 className={'heading-30 mt-6 mb-4'}>
        {t(
          'profile.account.contactInfo.addContactInfoModal.verify.enterCodeSentTo',
          { identifier: props.identifierValue }
        )}
      </h2>
      <p className={'paragraph-30 p-0 mb-8'}>
        {t(
          'profile.account.contactInfo.addContactInfoModal.verify.codeTookTooLong'
        )}{' '}
        <MarketLink
          {...((requestAddProfileIdentifierResult.isLoading ||
            verifyErrorStatus !== null) && { disabled: true })}
          onClick={requestAddIdentifier}
        >
          {t('common.requestNewCode')}
        </MarketLink>
      </p>
      <MarketField invalid={verifyErrorStatus !== null}>
        <MarketCodeInput
          data-testid={'code-input'}
          value={code}
          length={CODE_LENGTH}
          onMarketCodeInputValueChange={(e) => {
            if (verifyErrorStatus === RequestStatus.STATUS_BAD_REQUEST) {
              setVerifyErrorStatus(null);
            }

            setCode(e.detail.code);
            if (e.detail.code.length === CODE_LENGTH) {
              setShouldVerifyCode(true);
            }
          }}
        />
        {verifyErrorStatus !== null && (
          <small slot={'error'}>{getErrorText()}</small>
        )}
      </MarketField>
    </>
  );
};

export default Verify;
