import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

export function getOrSetSavt(): string {
  const existingSavt = Cookies.get('_savt');
  if (existingSavt) {
    return existingSavt;
  }

  const newSavt = uuidv4();
  Cookies.set('_savt', newSavt);
  return newSavt;
}
