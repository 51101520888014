import React, { useEffect } from 'react';
import RewardTierList from 'routes/merchant-scoped-portal/integrations/loyalty/components/RewardTierList';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from 'store';
import { selectLoyaltyProgram } from 'routes/merchant-scoped-portal/integrations/loyalty/loyaltySlice';
import ModuleLoadFailed from 'routes/profile/common/errors/ModuleLoadFailed';
import { MarketButton } from '@market/react';
import { useTranslation } from 'react-i18next';
import { ModalType, openModal } from 'store/modalSlice';
import { useTrackLoyaltyEvent } from 'utils/custom-react-hooks/loyalty/useTrackLoyaltyEvent';
import { EventName, FeatureFormat } from 'services/tracking/cdp/events/types';

const RewardsPage = () => {
  const loyaltyProgram = useSelector((state: AppState) =>
    selectLoyaltyProgram(state.loyaltyMerchantPortal)
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const openRedemptionOptionsModal = () => {
    dispatch(
      openModal({
        type: ModalType.LoyaltyRedemptionOptionsModal,
      })
    );
  };

  const trackLoyaltyEvent = useTrackLoyaltyEvent();
  useEffect(() => {
    trackLoyaltyEvent(EventName.VIEW_FEATURE, {
      event_description: 'View rewards tab',
      feature_format: FeatureFormat.PAGE,
    });
  }, [trackLoyaltyEvent]);

  if (!loyaltyProgram) {
    return <ModuleLoadFailed embedded={true} />;
  }

  return (
    <>
      <RewardTierList
        className="mt-4"
        rewardTiers={loyaltyProgram.rewardTiers}
      />
      <MarketButton rank="primary" onClick={openRedemptionOptionsModal}>
        {t('loyalty.rewardsPage.button.redeem')}
      </MarketButton>
    </>
  );
};

export default RewardsPage;
