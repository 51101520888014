import { Factory } from 'rosie';
import { BuyerState as Profile } from 'store/buyerSlice';
import IdentifierFactory from './IdentifierFactory';

export default Factory.define<Profile>('profile')
  .attr('firstName', 'First')
  .attr('lastName', 'Last')
  .attr('isLoaded', true)
  .attr('isInactive', false)
  .attr('isLoading', false)
  .attr('isLoadingSuggestedIdentifiers', false)
  .attr('suggestedIdentifiers', [])
  .attr('verifiedIdentifiers', () => IdentifierFactory.buildList(3));
