/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../Fixes';
import Builder from '../../../../Builder';
import '../common/money';
import './additional_recipient';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      connect: {
        nested: {
          v2: {
            nested: {
              resources: {
                nested: {
                  Refund: {
                    fields: {
                      id: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      locationId: {
                        type: 'string',
                        id: 3,
                        rule: 'optional',
                      },
                      transactionId: {
                        type: 'string',
                        id: 4,
                        rule: 'optional',
                      },
                      tenderId: {
                        type: 'string',
                        id: 5,
                        rule: 'optional',
                      },
                      createdAt: {
                        type: 'string',
                        id: 6,
                        rule: 'optional',
                      },
                      reason: {
                        type: 'string',
                        id: 7,
                        rule: 'optional',
                      },
                      amountMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 8,
                        rule: 'optional',
                      },
                      status: {
                        type: 'squareup.connect.v2.resources.Refund.Status',
                        id: 9,
                        rule: 'optional',
                      },
                      processingFeeMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 10,
                        rule: 'optional',
                      },
                      additionalRecipients: {
                        type: 'squareup.connect.v2.resources.AdditionalRecipient',
                        id: 11,
                        rule: 'repeated',
                      },
                      refundType: {
                        type: 'squareup.connect.v2.resources.Refund.RefundType',
                        id: 12,
                        rule: 'optional',
                        options: {
                          default: 'LINKED',
                        },
                      },
                    },
                    nested: {
                      Status: {
                        values: {
                          PENDING: 1,
                          APPROVED: 2,
                          REJECTED: 3,
                          FAILED: 4,
                        },
                      },
                      RefundType: {
                        values: {
                          REFUND_TYPE_DO_NOT_USE: 0,
                          LINKED: 1,
                          UNLINKED: 2,
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources
    ?.nested?.Refund &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources
    ?.nested?.Refund?.nested?.Status &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources
    ?.nested?.Refund?.nested?.RefundType
) {
  $root.addJSON(jsonData);
}

/**
 * --
 * @desc Represents a refund processed for a Square transaction.
 * --
 *
 * SOURCE Refund @ squareup/connect/v2/resources/refund.proto at 25:1
 */
export const Refund = $root.lookupType('squareup.connect.v2.resources.Refund');
fixType(Refund, {
  id: { required: true },
  locationId: { required: true },
  reason: { required: true },
  amountMoney: { required: true },
  status: { required: true },
});
Builder.createAndAttachToType(Refund);
/**
 * @desc Indicates a refund's current status.
 *
 * SOURCE Status @ squareup/connect/v2/resources/refund.proto at 70:3
 */
Refund.Status = $root.lookupEnum(
  'squareup.connect.v2.resources.Refund.Status'
).values;
/**
 * SOURCE RefundType @ squareup/connect/v2/resources/refund.proto at 103:3
 */
Refund.RefundType = $root.lookupEnum(
  'squareup.connect.v2.resources.Refund.RefundType'
).values;
