/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      common: {
        nested: {
          token: {
            nested: {
              TokenPair: {
                fields: {
                  serverToken: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  clientToken: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.common?.nested?.token?.nested?.TokenPair) {
  $root.addJSON(jsonData);
}

/**
 * A client-server token pair to represent an object.
 *
 * SOURCE TokenPair @ squareup/common/token.proto at 9:1
 */
export const TokenPair = $root.lookupType('squareup.common.token.TokenPair');
fixType(TokenPair);
Builder.createAndAttachToType(TokenPair);
