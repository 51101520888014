import React, { useEffect, useState } from 'react';
import { MarketButton, MarketInputText } from '@market/react';
import BackButton from 'components/BackButton';
import buyerPortalClient from 'rpc/client';
import { useTranslation } from 'react-i18next';
import {
  signInIsThisYourProfileViewEvent,
  signInIsThisYourProfileAcceptActionEvent,
  signInIsThisYourProfileDeclineActionEvent,
} from 'services/tracking/events/signIn';
import Es2Tracker from 'services/tracking/tracker';
import { v4 as uuidv4 } from 'uuid';
import {
  CreateLoginOrSignupVerificationRequest,
  DecoupleUnverifiedEmailInAccountDraftRequest,
} from 'rpc/model/squareup/buyerportal/onboarding/data';
import { RequestStatus } from 'rpc/model/squareup/customers/request';

interface IsThisYourProfileProps {
  identifierId: string;
  identifierValue: string;
  onBack: () => void;
  onForward: () => void;
  onForwardDecline: () => void;
  onUnexpectedError: (isCatastrophic?: boolean) => void;
}

const IsThisYourProfile: React.FC<IsThisYourProfileProps> = (props) => {
  const { t } = useTranslation();

  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  useEffect(() => {
    Es2Tracker.track(signInIsThisYourProfileViewEvent());
  }, []);

  const sendCode = async () => {
    try {
      setIsProcessing(true);
      Es2Tracker.track(signInIsThisYourProfileAcceptActionEvent());

      const response = await buyerPortalClient.createLoginOrSignupVerification(
        CreateLoginOrSignupVerificationRequest.create({
          idempotencyKey: uuidv4(),
          verificationCredential: {
            phoneNumberId: props.identifierId,
          },
        })
      );

      if (response.status !== RequestStatus.STATUS_SUCCESS) {
        throw new Error();
      }

      props.onForward();
    } catch {
      props.onUnexpectedError();
    } finally {
      setIsProcessing(false);
    }
  };

  const notMyPhone = async () => {
    try {
      setIsProcessing(true);
      Es2Tracker.track(signInIsThisYourProfileDeclineActionEvent());

      const response =
        await buyerPortalClient.decoupleUnverifiedEmailInAccountDraft(
          DecoupleUnverifiedEmailInAccountDraftRequest.create({})
        );
      if (response.status !== RequestStatus.STATUS_SUCCESS) {
        throw new Error();
      }

      props.onForwardDecline();
    } catch {
      props.onUnexpectedError();
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <>
      <BackButton onClick={props.onBack} />
      <h2 className={'mt-7 mb-4 heading-30'}>
        {t('onboarding.createProfile.isThisYourProfile.title')}
      </h2>
      <p className={'m-0 mb-6 paragraph-30'}>
        {t('onboarding.createProfile.isThisYourProfile.subtitle')}
      </p>

      <MarketInputText
        className="obfuscated-phone-input-market-wrapper mb-6"
        disabled
        value={props.identifierValue}
      >
        <label className="obfuscated-phone-label">
          {t('common.phoneNumber')}
        </label>
        <input
          className="obfuscated-phone-input"
          slot={'input'}
          data-testid={'phone-input'}
        />
      </MarketInputText>

      <MarketButton
        className={'mb-2'}
        rank={'primary'}
        /* Fun workaround to get disabled working: https://github.com/squareup/market/issues/1570 */
        {...(isProcessing && { disabled: true })}
        onClick={sendCode}
      >
        {t('onboarding.createProfile.isThisYourProfile.buttonText.accept')}
      </MarketButton>

      <MarketButton
        rank={'tertiary'}
        onClick={notMyPhone}
        /* Fun workaround to get disabled working: https://github.com/squareup/market/issues/1570 */
        {...(isProcessing && { disabled: true })}
      >
        {t('onboarding.createProfile.isThisYourProfile.buttonText.decline')}
      </MarketButton>
    </>
  );
};

export default IsThisYourProfile;
