import React, { useEffect, useState } from 'react';
import { MarketActivityIndicator, MarketRow } from '@market/react';
import buyerPortalClient from 'rpc/client';
import {
  RetrieveOnFileMerchantRequest,
  RetrieveOnFileMerchantResponse,
} from 'rpc/model/squareup/buyerportal/onfile/data';
import { RequestStatus } from 'rpc/model/squareup/customers/request';

type DashboardHeaderProps = {
  baToken: string;
  updateDashboardFetchState: (requestStatus: RequestStatus) => void;
};

const DEFAULT_MERCHANT_LOGO =
  'https://s3-eu-west-1.amazonaws.com/tpd/logos/5aeadef0f840a40001b866ac/0x0.png';

const DashboardHeader: React.FC<DashboardHeaderProps> = ({
  baToken,
  updateDashboardFetchState,
}) => {
  const [merchantData, setMerchantData] =
    useState<RetrieveOnFileMerchantResponse | null>(null);

  useEffect(() => {
    const getMerchantDetails = async () => {
      try {
        const response = await buyerPortalClient.retrieveOnFileMerchant(
          RetrieveOnFileMerchantRequest.create({
            buyerAccessToken: baToken.toString(),
          })
        );
        setMerchantData(response);
        // eslint-disable-next-line no-empty
      } catch {}
    };

    if (!merchantData) {
      getMerchantDetails();
    }
  }, [merchantData, baToken]);

  if (merchantData && merchantData.status) {
    updateDashboardFetchState(merchantData.status);
  }

  /*
    Currently adding a BoF is a US only so the country code will always be +1
   */
  const formatPhoneNumber = (phoneNumberString?: string) => {
    const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  };

  let contactFields = '';
  if (merchantData) {
    const formattedPhone = formatPhoneNumber(merchantData.phoneNumber);
    if (merchantData.emailAddress && formattedPhone) {
      contactFields = `${merchantData.emailAddress} | ${formattedPhone}`;
    } else if (merchantData.emailAddress) {
      contactFields = `${merchantData.emailAddress}`;
    } else {
      contactFields = `${formattedPhone}`;
    }
  }

  return (
    <div>
      {merchantData === null ||
      merchantData.status !== RequestStatus.STATUS_SUCCESS ? (
        <MarketActivityIndicator
          size="small"
          className={
            'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
          }
        />
      ) : (
        <div className={'w-full h-[300px]'}>
          <div className={'w-full h-[40%] bg-[#F5F6F7]'} />
          <div className={'w-full h-[60%]'}>
            <MarketRow>
              <img
                src={merchantData.profileImageUrl ?? DEFAULT_MERCHANT_LOGO}
                alt="Square Merchant Logo"
                className={
                  'absolute w-16 h-16 top-0 left-1/2 translate-x-[-50%] translate-y-[-50%] rounded-[10%]'
                }
              />
              <h1
                className={'--core-type-heading-30-size text-center pt-[20px]'}
              >
                {merchantData.merchantName}
              </h1>
              <p className={'--core-type-semibold-20-size text-center'}>
                {contactFields}
              </p>
            </MarketRow>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardHeader;
