/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      common: {
        nested: {
          script: {
            nested: {
              Script: {
                values: {
                  Zyyy: 998,
                  Hani: 500,
                  Hira: 410,
                  Jpan: 413,
                  Kana: 411,
                  Latn: 215,
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.common?.nested?.script?.nested?.Script) {
  $root.addJSON(jsonData);
}

/**
 * A script that can be used to write a name. These come from ISO15924
 * (http://unicode.org/iso15924/iso15924-codes.html), but we will add them as we need them.
 * Sorted by script name, except for Zyyy.
 *
 * SOURCE Script @ squareup/common/script.proto at 8:1
 */
export const Script = $root.lookupEnum('squareup.common.script.Script').values;
