import { Factory } from 'rosie';
import { Identifier } from 'rpc/model/squareup/buyerportal/profile/common';
import { AccountInfo } from 'rpc/model/squareup/buyerportal/merchantportal/loyalty/data';
import { LoyaltyAccountFactory } from './LoyaltyAccountFactory';
import { MemberFactory } from './MemberFactory';

export const AccountInfoFactory = new Factory<AccountInfo>()
  .attr('loyaltyAccount', LoyaltyAccountFactory.build())
  .attr('member', MemberFactory.build())
  .attr(
    'phoneIdentifier',
    Identifier.create({
      id: 'phone-identifier-id',
      displayValue: '(•••) •••-9000',
      type: Identifier.Type.TYPE_PHONE,
    })
  )
  .after((accountInfo) => AccountInfo.create(accountInfo));
