import React from 'react';

type MarketRowImageProps = {
  imageUrl?: string;
  placeholder?: string;
};

const MarketRowImage: React.FC<MarketRowImageProps> = (props) => {
  const getImage = () => {
    if (props.imageUrl) {
      return <img src={props.imageUrl} alt={props.placeholder} />;
    }

    const firstLetter =
      props.placeholder && props.placeholder.length > 0
        ? props.placeholder.charAt(0).toUpperCase()
        : '';

    return <span className="text-4xl font-bold ">{firstLetter}</span>;
  };

  return <>{getImage()}</>;
};

export default MarketRowImage;
