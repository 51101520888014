/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../Fixes';
import Builder from '../../../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      omg: {
        nested: {
          card: {
            nested: {
              balance: {
                nested: {
                  RewardMappingOrderExtension: {
                    fields: {
                      rewardMappings: {
                        type: 'squareup.omg.card.balance.RewardMappingOrderExtension.RewardMapping',
                        id: 1,
                        rule: 'repeated',
                      },
                      loyaltyAccountId: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                    },
                    nested: {
                      RewardMapping: {
                        fields: {
                          uid: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                          loyaltyAccountId: {
                            type: 'string',
                            id: 2,
                            rule: 'optional',
                          },
                          rewardTierId: {
                            type: 'string',
                            id: 3,
                            rule: 'optional',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.omg?.nested?.card?.nested?.balance?.nested
    ?.RewardMappingOrderExtension &&
  !$root.nested?.squareup?.nested?.omg?.nested?.card?.nested?.balance?.nested
    ?.RewardMappingOrderExtension?.nested?.RewardMapping
) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE RewardMappingOrderExtension @ squareup/omg/card/balance/extensions.proto at 15:1
 */
export const RewardMappingOrderExtension = $root.lookupType(
  'squareup.omg.card.balance.RewardMappingOrderExtension'
);
fixType(RewardMappingOrderExtension);
Builder.createAndAttachToType(RewardMappingOrderExtension);
/**
 * Maps Rewards to Loyalty accounts.
 *
 * SOURCE RewardMapping @ squareup/omg/card/balance/extensions.proto at 17:3
 */
RewardMappingOrderExtension.RewardMapping = $root.lookupType(
  'squareup.omg.card.balance.RewardMappingOrderExtension.RewardMapping'
);
fixType(RewardMappingOrderExtension.RewardMapping, {
  uid: { notEmpty: true },
  loyaltyAccountId: { notEmpty: true },
  rewardTierId: { notEmpty: true },
});
Builder.createAndAttachToType(RewardMappingOrderExtension.RewardMapping);
