import React, { useState } from 'react';
import {
  MarketButton,
  MarketFooter,
  MarketDialog,
  MarketButtonGroup,
  MarketContextManager,
} from '@market/react';
import * as request from 'rpc/model/squareup/customers/request';
import {
  UnlinkOnFileBankAccountRequest,
  UnlinkOnFileBankAccountResponse,
} from 'rpc/model/squareup/buyerportal/onfile/data';
import { InModal } from './InModal';
import type { PaymentMethod } from './PaymentMethodOnFile';
import buyerPortalClient from 'rpc/client';
import {
  onFileBuyerDashboardUnlinkEvent,
  onFileBuyerDashboardErrorEvent,
  EVENT_SOURCE_BOF,
  UNLINK_FAILURE_ERROR,
} from 'services/tracking/events/onFileManagementEvents';
import Es2Tracker from 'services/tracking/tracker';
import { getUtmMediumQueryParam } from '../index';

type RemovePaymentMethodModalProps = {
  isModalOpen: boolean;
  selectedPaymentMethod: PaymentMethod;
  onDismissed: () => void;
  baToken: string;
  onError: (errors: request.IRequestError[]) => void;
  onSuccess: () => void;
};

const RemovePaymentMethodModal = ({
  isModalOpen,
  selectedPaymentMethod,
  onDismissed,
  baToken,
  onError,
  onSuccess,
}: RemovePaymentMethodModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const unlinkOnFileBankAccount = async () => {
    try {
      setIsLoading(true);
      const response: UnlinkOnFileBankAccountResponse =
        await buyerPortalClient.unlinkOnFileBankAccount(
          UnlinkOnFileBankAccountRequest.create({
            buyerAccessToken: baToken.toString(),
            bankAccountId: selectedPaymentMethod.bankId,
          })
        );
      if (response?.errors.length > 0) {
        Es2Tracker.track(
          onFileBuyerDashboardErrorEvent({
            eventSource: EVENT_SOURCE_BOF,
            errorDetail: UNLINK_FAILURE_ERROR,
            utmMedium: getUtmMediumQueryParam(),
          })
        );
        onError([...response.errors]);
      } else {
        // TODO: when we start supporting CardOnFile, conditionally use
        // different values for the eventSource parameter
        Es2Tracker.track(
          onFileBuyerDashboardUnlinkEvent({
            eventSource: EVENT_SOURCE_BOF,
            utmMedium: getUtmMediumQueryParam(),
          })
        );
        onSuccess();
      }
    } catch (error) {
      onError([error as request.IRequestError]);
    }
    setIsLoading(false);
  };

  if (!isModalOpen) {
    return null;
  }

  return (
    <MarketContextManager>
      <InModal>
        <MarketDialog onMarketDialogDismissed={onDismissed}>
          <main>
            <h2>
              Remove{' '}
              {`${selectedPaymentMethod.bankName} **** ${selectedPaymentMethod.accountNumberSuffix}`}
            </h2>
            <p>
              By continuing, you will remove your{' '}
              {selectedPaymentMethod.bankName} ending in{' '}
              {selectedPaymentMethod.accountNumberSuffix} from future purchases.
            </p>
          </main>
          <MarketFooter>
            <MarketButtonGroup alignment="fill">
              <MarketButton
                rank="primary"
                variant="destructive"
                onClick={async () => {
                  await unlinkOnFileBankAccount();
                  onDismissed();
                }}
                // NOTE: have to pass in `undefined` instead of false
                // https://github.com/squareup/market/issues/1570
                disabled={isLoading ? true : undefined}
              >
                {isLoading ? 'Removing...' : 'Remove'}
              </MarketButton>
              <MarketButton
                rank="secondary"
                onClick={onDismissed}
                // NOTE: have to pass in `undefined` instead of false
                // https://github.com/squareup/market/issues/1570
                disabled={isLoading ? true : undefined}
              >
                Cancel
              </MarketButton>
            </MarketButtonGroup>
          </MarketFooter>
        </MarketDialog>
      </InModal>
    </MarketContextManager>
  );
};

export default RemovePaymentMethodModal;
