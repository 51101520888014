import { MarketAccessory, MarketActionCard, MarketRow } from '@market/react';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { EmailCollectionIncentiveTermsAndStatus } from 'rpc/model/squareup/card/balance/loyalty_api/model/email-collection-incentive-terms-and-status';
import { AppState } from 'store';
import { selectLoyaltyProgram } from '../loyaltySlice';
import Envelope from 'svgs/market-icons/Envelope';
import { useHistory } from 'react-router-dom';
import { createMailingListUrl } from '../routeUtils';

interface EmailCollectionIncentiveCardProps {
  incentiveTermsAndStatus: EmailCollectionIncentiveTermsAndStatus;
  merchantId: string;
  loyaltyAccountLookupToken: string;
}

const EmailCollectionIncentiveCard: React.FC<
  EmailCollectionIncentiveCardProps
> = ({ incentiveTermsAndStatus, merchantId, loyaltyAccountLookupToken }) => {
  const program = useSelector((state: AppState) =>
    selectLoyaltyProgram(state.loyaltyMerchantPortal)
  );
  const points = incentiveTermsAndStatus.pointAmount;
  const pointsTerminology =
    incentiveTermsAndStatus.pointAmount === 1
      ? program!.terminology!.one!
      : program!.terminology!.other!;
  const { t } = useTranslation();
  const subtext = t('loyalty.emailCollectionIncentiveCard.subtext', {
    points,
    pointsTerminology,
  });
  const history = useHistory();
  const emailCollectionIncentivePageUrl = useMemo(
    () =>
      createMailingListUrl({
        loyaltyAccountLookupToken,
        merchantId,
      }),
    [loyaltyAccountLookupToken, merchantId]
  );
  const openEmailCollectionIncentiveModal = useCallback(() => {
    history.push(emailCollectionIncentivePageUrl);
  }, [emailCollectionIncentivePageUrl, history]);

  return (
    <MarketActionCard onClick={openEmailCollectionIncentiveModal}>
      <MarketRow variant="drill" transient>
        <MarketAccessory slot="leading-accessory">
          <Envelope />
        </MarketAccessory>
        <label slot="label">
          {t('loyalty.emailCollectionIncentiveCard.label')}
        </label>
        <label slot="subtext">{subtext}</label>
      </MarketRow>
    </MarketActionCard>
  );
};

export default EmailCollectionIncentiveCard;
