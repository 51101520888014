/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import './benefit';
import './progress-metric';
import './benefit-package';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      memberships: {
        nested: {
          model: {
            nested: {
              Member: {
                fields: {
                  id: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  type: {
                    type: 'squareup.memberships.model.Member.Type',
                    id: 2,
                    rule: 'optional',
                  },
                  programId: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  memberIdentifier: {
                    type: 'squareup.memberships.model.Member.MemberIdentifier',
                    id: 4,
                    rule: 'optional',
                  },
                  benefits: {
                    type: 'squareup.memberships.model.Benefit',
                    id: 5,
                    rule: 'repeated',
                  },
                  tieredData: {
                    type: 'squareup.memberships.model.Member.TieredData',
                    id: 6,
                    rule: 'optional',
                  },
                  version: {
                    type: 'int32',
                    id: 7,
                    rule: 'optional',
                  },
                },
                nested: {
                  Type: {
                    values: {
                      TYPE_DO_NOT_USE: 0,
                      TIERED: 1,
                    },
                  },
                  TieredData: {
                    fields: {
                      currentTier: {
                        type: 'squareup.memberships.model.Member.BenefitPackageStatus',
                        id: 1,
                        rule: 'optional',
                      },
                      nextTier: {
                        type: 'squareup.memberships.model.Member.BenefitPackageStatus',
                        id: 2,
                        rule: 'optional',
                      },
                    },
                  },
                  Progress: {
                    fields: {
                      uid: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      metric: {
                        type: 'squareup.memberships.model.ProgressMetric',
                        id: 2,
                        rule: 'optional',
                      },
                      threshold: {
                        type: 'int64',
                        id: 3,
                        rule: 'optional',
                      },
                      value: {
                        type: 'int64',
                        id: 4,
                        rule: 'optional',
                      },
                    },
                  },
                  BenefitPackageStatus: {
                    fields: {
                      type: {
                        type: 'squareup.memberships.model.Member.BenefitPackageStatus.Type',
                        id: 1,
                        rule: 'optional',
                      },
                      package: {
                        type: 'squareup.memberships.model.BenefitPackage',
                        id: 2,
                        rule: 'optional',
                      },
                      earnedData: {
                        type: 'squareup.memberships.model.Member.BenefitPackageStatus.EarnedData',
                        id: 3,
                        rule: 'optional',
                      },
                      progressData: {
                        type: 'squareup.memberships.model.Member.BenefitPackageStatus.ProgressData',
                        id: 4,
                        rule: 'optional',
                      },
                    },
                    nested: {
                      Type: {
                        values: {
                          TYPE_DO_NOT_USE: 0,
                          PROGRESS: 1,
                          EARNED: 2,
                        },
                      },
                      ProgressData: {
                        fields: {
                          progress: {
                            type: 'squareup.memberships.model.Member.Progress',
                            id: 1,
                            rule: 'repeated',
                          },
                        },
                      },
                      EarnedData: {
                        fields: {
                          earnedAt: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                          expiresAt: {
                            type: 'string',
                            id: 2,
                            rule: 'optional',
                          },
                        },
                      },
                    },
                  },
                  MemberIdentifier: {
                    fields: {
                      type: {
                        type: 'squareup.memberships.model.Member.MemberIdentifier.Type',
                        id: 1,
                        rule: 'optional',
                      },
                      token: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                      loyaltyAccountId: {
                        type: 'string',
                        id: 3,
                        rule: 'optional',
                      },
                    },
                    nested: {
                      Type: {
                        values: {
                          TYPE_DO_NOT_USE: 0,
                          LOYALTY_ACCOUNT: 1,
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.memberships?.nested?.model?.nested?.Member &&
  !$root.nested?.squareup?.nested?.memberships?.nested?.model?.nested?.Member
    ?.nested?.Type &&
  !$root.nested?.squareup?.nested?.memberships?.nested?.model?.nested?.Member
    ?.nested?.TieredData &&
  !$root.nested?.squareup?.nested?.memberships?.nested?.model?.nested?.Member
    ?.nested?.Progress &&
  !$root.nested?.squareup?.nested?.memberships?.nested?.model?.nested?.Member
    ?.nested?.BenefitPackageStatus &&
  !$root.nested?.squareup?.nested?.memberships?.nested?.model?.nested?.Member
    ?.nested?.BenefitPackageStatus?.nested?.Type &&
  !$root.nested?.squareup?.nested?.memberships?.nested?.model?.nested?.Member
    ?.nested?.BenefitPackageStatus?.nested?.ProgressData &&
  !$root.nested?.squareup?.nested?.memberships?.nested?.model?.nested?.Member
    ?.nested?.BenefitPackageStatus?.nested?.EarnedData &&
  !$root.nested?.squareup?.nested?.memberships?.nested?.model?.nested?.Member
    ?.nested?.MemberIdentifier &&
  !$root.nested?.squareup?.nested?.memberships?.nested?.model?.nested?.Member
    ?.nested?.MemberIdentifier?.nested?.Type
) {
  $root.addJSON(jsonData);
}

/**
 * --
 * @desc Represents a buyer's membership status.
 * --
 *
 * SOURCE Member @ squareup/memberships/model/member.proto at 20:1
 */
export const Member = $root.lookupType('squareup.memberships.model.Member');
fixType(Member, {
  programId: { notEmpty: true },
  memberIdentifier: { required: true },
});
Builder.createAndAttachToType(Member);
/**
 * --
 * @desc The type of member.
 * --
 *
 * SOURCE Type @ squareup/memberships/model/member.proto at 24:3
 */
Member.Type = $root.lookupEnum('squareup.memberships.model.Member.Type').values;
/**
 * --
 * @desc Provides metadata when the member `type` is `TIERED`.
 * --
 *
 * SOURCE TieredData @ squareup/memberships/model/member.proto at 36:3
 */
Member.TieredData = $root.lookupType(
  'squareup.memberships.model.Member.TieredData'
);
fixType(Member.TieredData);
Builder.createAndAttachToType(Member.TieredData);
/**
 * --
 * @desc Represents a buyer's progress towards earning a benefit package, based on a specific metric.
 * --
 *
 * SOURCE Progress @ squareup/memberships/model/member.proto at 51:3
 */
Member.Progress = $root.lookupType(
  'squareup.memberships.model.Member.Progress'
);
fixType(Member.Progress, {
  metric: { required: true },
  threshold: { required: true },
  value: { required: true },
});
Builder.createAndAttachToType(Member.Progress);
/**
 * --
 * @desc Represents the status of a benefit package as it relates to a given buyer.
 * --
 *
 * SOURCE BenefitPackageStatus @ squareup/memberships/model/member.proto at 76:3
 */
Member.BenefitPackageStatus = $root.lookupType(
  'squareup.memberships.model.Member.BenefitPackageStatus'
);
fixType(Member.BenefitPackageStatus, {
  type: { required: true },
  package: { required: true },
});
Builder.createAndAttachToType(Member.BenefitPackageStatus);
/**
 * --
 * @desc The type of status.
 * --
 *
 * SOURCE Type @ squareup/memberships/model/member.proto at 80:5
 */
Member.BenefitPackageStatus.Type = $root.lookupEnum(
  'squareup.memberships.model.Member.BenefitPackageStatus.Type'
).values;
/**
 * --
 * @desc Provides metadata when the benefit package status `type` is `PROGRESS`.
 * --
 *
 * SOURCE ProgressData @ squareup/memberships/model/member.proto at 97:5
 */
Member.BenefitPackageStatus.ProgressData = $root.lookupType(
  'squareup.memberships.model.Member.BenefitPackageStatus.ProgressData'
);
fixType(Member.BenefitPackageStatus.ProgressData);
Builder.createAndAttachToType(Member.BenefitPackageStatus.ProgressData);
/**
 * --
 * @desc Provides metadata when the benefit package status `type` is `EARNED`.
 * --
 *
 * SOURCE EarnedData @ squareup/memberships/model/member.proto at 107:5
 */
Member.BenefitPackageStatus.EarnedData = $root.lookupType(
  'squareup.memberships.model.Member.BenefitPackageStatus.EarnedData'
);
fixType(Member.BenefitPackageStatus.EarnedData);
Builder.createAndAttachToType(Member.BenefitPackageStatus.EarnedData);
/**
 * --
 * @desc Represents the identifier used to connect a member to other entities at Square.
 * --
 *
 * SOURCE MemberIdentifier @ squareup/memberships/model/member.proto at 143:3
 */
Member.MemberIdentifier = $root.lookupType(
  'squareup.memberships.model.Member.MemberIdentifier'
);
fixType(Member.MemberIdentifier, {
  type: { required: true },
  token: { notEmpty: true },
});
Builder.createAndAttachToType(Member.MemberIdentifier);
/**
 * --
 * @desc The type of identifier.
 * --
 *
 * SOURCE Type @ squareup/memberships/model/member.proto at 147:5
 */
Member.MemberIdentifier.Type = $root.lookupEnum(
  'squareup.memberships.model.Member.MemberIdentifier.Type'
).values;
