import React from 'react';
import { useTranslation } from 'react-i18next';
import { getPhoneNumber } from 'utils/phoneNumber';
import MerchantContactInfoLine from './MerchantContactInfoLine';
import { FormattedMerchantLocation } from './view-models/FormattedMerchantLocation';

interface MerchantLocationProps {
  merchantLocationFormatted: FormattedMerchantLocation;
  shouldShowContactInfo: boolean;
}

const MerchantLocation: React.FC<MerchantLocationProps> = ({
  merchantLocationFormatted,
  shouldShowContactInfo,
}) => {
  const { t } = useTranslation();
  const phoneNumber = getPhoneNumber(
    merchantLocationFormatted.phone?.number ?? '',
    merchantLocationFormatted.phone?.callingCode,
    merchantLocationFormatted.address?.countryCode
  );

  return (
    <div className="merchant-location-container">
      {merchantLocationFormatted.address?.locality && (
        <p className="medium-30 m-0" data-testid="city-element">
          {merchantLocationFormatted.address?.locality}
        </p>
      )}
      <MerchantContactInfoLine
        text={
          merchantLocationFormatted?.formattedAddress ??
          t('merchantPortal.merchantLocation.noAddress')
        }
        testId="address-element"
      />
      <br />
      {shouldShowContactInfo && (
        <div>
          {phoneNumber && (
            <MerchantContactInfoLine
              text={`${t('common.tel')}: ${phoneNumber}`}
              testId="phone-number"
            />
          )}
          {merchantLocationFormatted?.email && (
            <MerchantContactInfoLine
              text={`${t('common.email')}: ${merchantLocationFormatted?.email}`}
              testId="email-address"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default MerchantLocation;
