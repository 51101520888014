import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useTheme } from '@material-ui/core/styles';

const LoadingIcon: React.FC<SvgIconProps> = (props) => {
  const theme = useTheme();

  return (
    <SvgIcon width="80" height="80" viewBox="0 0 80 80" {...props}>
      <path
        d="M40 78C60.9868 78 78 60.9868 78 40C78 19.0132 60.9868 2 40 2C19.0132 2 2.00001 19.0132 2.00001 40C2.00001 60.9868 19.0132 78 40 78Z"
        fill="none"
        stroke="#D5D9DC"
        strokeWidth="4"
      />
      <path
        d="M78 40C78 19.0132 60.9868 2 40 2"
        fill="none"
        stroke={theme.palette.primary.main}
        strokeWidth="4"
      />
    </SvgIcon>
  );
};

export default LoadingIcon;
