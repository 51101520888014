import { useCallback, useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { MerchantPortalContext } from 'routes/merchant-scoped-portal';
import {
  AuthenticatedLoyaltyRouteParams,
  authenticatedLoyaltyRoutePattern,
} from 'routes/merchant-scoped-portal/integrations/loyalty/routeUtils';
import { buyerportalCdpClient } from 'services/tracking/cdp/clients/buyerportal';
import {
  EventName,
  FeatureFormat,
  FeatureID,
  FeatureName,
  StandardizedEvent,
} from 'services/tracking/cdp/events/types';

type LoyaltyActionEvent = Partial<StandardizedEvent> & {
  event_description: string;
  feature_format: FeatureFormat;
};

/**
 * Custom hook that provides a simplified way to track CDP action events
 * for Loyalty-related interactions.
 */
export const useTrackLoyaltyEvent = () => {
  const { merchantPortalBaseData } = useContext(MerchantPortalContext);
  const { merchantId } = merchantPortalBaseData?.merchantOverview ?? {};
  const { buyer } = merchantPortalBaseData ?? {};

  const match = useRouteMatch<Partial<AuthenticatedLoyaltyRouteParams>>(
    authenticatedLoyaltyRoutePattern
  );
  const loyaltyAccountLookupToken = match?.params?.loyaltyAccountLookupToken;

  const trackLoyaltyEvent = useCallback(
    (eventName: EventName, data: LoyaltyActionEvent) => {
      const defaultData = {
        additional_parameters: {
          buyer_authenticated: Boolean(buyer),
          merchant_id: merchantId,
          ...(loyaltyAccountLookupToken && {
            loyalty_account_lookup_token: loyaltyAccountLookupToken,
          }),
        },
        feature_id: FeatureID.LoyaltyStatusPage,
        feature_name: FeatureName.LoyaltyStatusPage,
        is_default_view: true,
      };

      const actionEvent: StandardizedEvent = {
        ...defaultData,
        ...data,
      };

      buyerportalCdpClient.track(eventName, actionEvent);
    },
    [merchantId, buyer, loyaltyAccountLookupToken]
  );

  return trackLoyaltyEvent;
};
