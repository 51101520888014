import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LogoIcon } from 'svgs';
import { MarketButton } from '@market/react';
import { v4 as uuidv4 } from 'uuid';
import Es2Tracker from 'services/tracking/tracker';
import { isValidEmail } from 'utils/identifiers';
import IdentifierValueInput from 'components/IdentifierValueInput';
import { IdentifierType } from 'routes/profile/models/Identifier';
import {
  signInViewEvent,
  signInRequestVerifyActionEvent,
  signInSwitchIdentifierActionEvent,
} from 'services/tracking/events/signIn';
import client from 'rpc/client';
import { RequestStatus } from 'rpc/model/squareup/customers/request';
import { SignInIdentifier, SignInIdentifierType } from './types';
import {
  CreateLoginOrSignupVerificationRequest,
  ICreateLoginOrSignupVerificationRequest,
} from 'rpc/model/squareup/buyerportal/onboarding/data';
import PhoneInput from './components/PhoneInput';
import nonTranslatableStrings from 'utils/nonTranslatableStrings';

interface SignInProps {
  identifier: SignInIdentifier | undefined;
  onForward: (value: string, type: SignInIdentifierType) => void;
  onUnexpectedError: (isCatastrophic?: boolean) => void;
  onAuthMethodChange?: () => void;
}

const SignIn: React.FC<SignInProps> = (props) => {
  const { t } = useTranslation();

  const [identifierValue, setIdentifierValue] = useState<string>(
    props.identifier?.value || ''
  );
  const [nationalNumber, setNationalNumber] = useState<string>(''); // TODO: should factor in initial identifier
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [authMethod, setAuthMethod] = useState<SignInIdentifierType>(
    props.identifier?.type || IdentifierType.Phone
  );
  const [isPhoneValid, setIsPhoneValid] = useState<boolean>(false); // This will be updated by the PhoneInput for prefilled phones
  const [isEmailValid, setIsEmailValid] = useState<boolean>(() => {
    if (authMethod === IdentifierType.Email && props.identifier) {
      return isValidEmail(props.identifier.value);
    }
    return false; // Empty => invalid
  });

  const [
    shouldDisplayInvalidSubmissionError,
    setShouldDisplayInvalidSubmissionError,
  ] = useState<boolean>(false);

  const isAuthMethodValid = () => {
    if (authMethod === IdentifierType.Phone) {
      return isPhoneValid;
    } else {
      return isEmailValid;
    }
  };

  const initialAuthMethod = useRef<SignInIdentifierType>(
    props.identifier?.type || IdentifierType.Email
  );

  useEffect(() => {
    Es2Tracker.track(signInViewEvent(initialAuthMethod.current));
  }, []);

  const sendCode = async () => {
    if (!isAuthMethodValid()) {
      setShouldDisplayInvalidSubmissionError(true);
      return;
    }

    try {
      setIsProcessing(true);
      Es2Tracker.track(signInRequestVerifyActionEvent(authMethod));

      const parameters: ICreateLoginOrSignupVerificationRequest = {
        idempotencyKey: uuidv4(),
        verificationCredential: {},
      };

      if (authMethod === IdentifierType.Email) {
        parameters.verificationCredential = { email: identifierValue };
      } else {
        parameters.verificationCredential = { phoneNumber: identifierValue };
      }

      const response = await client.createLoginOrSignupVerification(
        CreateLoginOrSignupVerificationRequest.create(parameters)
      );
      if (response.status !== RequestStatus.STATUS_SUCCESS) {
        throw new Error();
      }

      props.onForward(
        identifierValue,
        authMethod === IdentifierType.Email
          ? IdentifierType.Email
          : IdentifierType.Phone
      );
    } catch {
      props.onUnexpectedError();
    } finally {
      setIsProcessing(false);
    }
  };

  const toggleField = () => {
    const newAuthMethod =
      authMethod === IdentifierType.Email
        ? IdentifierType.Phone
        : IdentifierType.Email;

    Es2Tracker.track(signInSwitchIdentifierActionEvent(newAuthMethod));
    setAuthMethod(newAuthMethod);
    setIdentifierValue('');
    // Reset validation
    setIsPhoneValid(false);
    setIsEmailValid(false);
    setShouldDisplayInvalidSubmissionError(false);
    if (props.onAuthMethodChange) {
      props.onAuthMethodChange();
    }
  };

  let component;
  if (authMethod === IdentifierType.Email) {
    component = (
      <IdentifierValueInput
        emailInputDataTestId={'email-input'}
        phoneInputDataTestId={'phone-input'}
        identifierType={authMethod}
        identifierValue={identifierValue}
        isInvalid={shouldDisplayInvalidSubmissionError}
        onEnterPressed={() => sendCode()}
        onIdentifierValueChanged={(value) => {
          setIdentifierValue(value);
          setIsEmailValid(isValidEmail(value));
          setShouldDisplayInvalidSubmissionError(false);
        }}
      />
    );
  } else {
    component = (
      <PhoneInput
        onChange={(
          isValid: boolean,
          number: string,
          nationalNumber: string
        ) => {
          setIdentifierValue(number);
          setIsPhoneValid(isValid);
          setShouldDisplayInvalidSubmissionError(false);
          setNationalNumber(nationalNumber);
        }}
        onEnterPressed={sendCode}
        shouldShowValidationError={shouldDisplayInvalidSubmissionError}
        value={identifierValue}
      />
    );
  }
  return (
    <>
      <LogoIcon className={'text-[32px]'} />
      <h2 className={'mt-7 mb-4 heading-30'}>
        {t('onboarding.signIn.title', {
          SQUARE: nonTranslatableStrings.square,
        })}
      </h2>
      <p className={'m-0 mb-4 paragraph-30'}>
        {t(
          `onboarding.signIn.subtitle.${
            authMethod === IdentifierType.Email ? 'email' : 'phone'
          }`,
          { SQUARE: nonTranslatableStrings.square }
        )}
      </p>
      {component}
      <MarketButton
        rank={'primary'}
        /* Fun workaround to get disabled working: https://github.com/squareup/market/issues/1570 */
        {...((isProcessing ||
          shouldDisplayInvalidSubmissionError ||
          (authMethod === IdentifierType.Email && !identifierValue) ||
          (authMethod === IdentifierType.Phone && !nationalNumber)) && {
          disabled: true,
        })}
        onClick={() => sendCode()}
      >
        {t('common.sendCode')}
      </MarketButton>
      <MarketButton
        rank={'tertiary'}
        {...(isProcessing && { disabled: true })}
        onClick={() => toggleField()}
      >
        {authMethod === IdentifierType.Email
          ? t('common.usePhoneNumber')
          : t('common.useEmailAddress')}
      </MarketButton>
    </>
  );
};

export default SignIn;
