/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      agenda: {
        nested: {
          NotificationButton: {
            fields: {
              title: {
                type: 'string',
                id: 1,
                rule: 'optional',
              },
              action: {
                type: 'squareup.agenda.NotificationButton.ActionType',
                id: 2,
                rule: 'optional',
                options: {
                  default: 'DISMISS',
                },
              },
              url: {
                type: 'string',
                id: 3,
                rule: 'optional',
              },
            },
            nested: {
              ActionType: {
                values: {
                  DISMISS: 1,
                  OPEN_URL: 2,
                },
              },
            },
          },
          CheckApplicationNotification: {
            fields: {
              title: {
                type: 'string',
                id: 1,
                rule: 'optional',
              },
              message: {
                type: 'string',
                id: 2,
                rule: 'optional',
              },
              canBeDismissed: {
                type: 'bool',
                id: 3,
                rule: 'optional',
                options: {
                  default: true,
                },
              },
              cancelButton: {
                type: 'squareup.agenda.NotificationButton',
                id: 4,
                rule: 'optional',
              },
              button: {
                type: 'squareup.agenda.NotificationButton',
                id: 5,
                rule: 'optional',
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.agenda?.nested?.NotificationButton &&
  !$root.nested?.squareup?.nested?.agenda?.nested?.NotificationButton?.nested
    ?.ActionType &&
  !$root.nested?.squareup?.nested?.agenda?.nested?.CheckApplicationNotification
) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE NotificationButton @ squareup/agenda/agenda.proto at 616:1
 */
export const NotificationButton = $root.lookupType(
  'squareup.agenda.NotificationButton'
);
fixType(NotificationButton, {
  title: { required: true },
  action: { required: true },
});
Builder.createAndAttachToType(NotificationButton);
/**
 * SOURCE ActionType @ squareup/agenda/agenda.proto at 622:3
 */
NotificationButton.ActionType = $root.lookupEnum(
  'squareup.agenda.NotificationButton.ActionType'
).values;
/**
 * SOURCE CheckApplicationNotification @ squareup/agenda/agenda.proto at 635:1
 */
export const CheckApplicationNotification = $root.lookupType(
  'squareup.agenda.CheckApplicationNotification'
);
fixType(CheckApplicationNotification, {
  title: { required: true },
  message: { required: true },
  cancelButton: { required: true },
});
Builder.createAndAttachToType(CheckApplicationNotification);
