export function isProductionBuild() {
  return process.env.NODE_ENV === 'production';
}

function isProductionHost() {
  return /^(\w*\.)?squareup.com/.test(window.location.host);
}

export function environment() {
  return {
    isProduction: isProductionHost(),
    name: isProductionHost() ? 'production' : 'staging',
  };
}

/**
 * Env-aware logout url.
 */
export const logoutUrl = (returnTo?: string) =>
  isProductionBuild()
    ? `/logout?return_to=${returnTo || '/signin'}`
    : `/logout?app=buyer-portal&return_to=${
        returnTo || '/signin?app=buyer-portal'
      }`;
