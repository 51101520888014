/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      common: {
        nested: {
          payment: {
            nested: {
              SignatureType: {
                values: {
                  UNKNOWN: 0,
                  NO_SIGNATURE: 1,
                  DIGITAL_SIGNATURE: 2,
                  PAPER_SIGNATURE: 3,
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.common?.nested?.payment?.nested
    ?.SignatureType
) {
  $root.addJSON(jsonData);
}

/**
 * Whether an authorizing signature was captured during a transaction.
 *
 * SOURCE SignatureType @ squareup/common/payment.proto at 139:1
 */
export const SignatureType = $root.lookupEnum(
  'squareup.common.payment.SignatureType'
).values;
