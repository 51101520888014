import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Store from 'svgs/market-icons/Store';
import { theme } from 'tailwind.config';
import normalizeHexColor from '../utils/normalizeHexColor';
import classNames from 'classnames';

export type MerchantLogoProps = {
  imgUrl?: string;
  sellerBrandColor?: string;
  clickable?: boolean;
};

const MerchantLogo: React.FC<MerchantLogoProps> = ({
  imgUrl,
  sellerBrandColor,
  clickable = true,
}) => {
  const history = useHistory();
  const { merchantId } = useParams<{ merchantId: string }>();

  const redirectToOverview = useCallback(() => {
    history.push(`/merchantPortal/${merchantId}`);
  }, [history, merchantId]);

  const renderImg = () => (
    <img className="h-20" src={imgUrl} alt="Merchant Logo" />
  );

  const renderDefaultMerchantLogo = () => (
    <div
      style={{
        backgroundColor: normalizeHexColor(
          sellerBrandColor || theme.extend.colors.bluegrey
        ),
      }}
      className={'p-3 rounded-lg flex items-center justify-center'}
    >
      <Store fill="#FFFFFF" />
    </div>
  );

  const logoContainerClasses = classNames(
    'merchant-logo-container flex justify-center',
    {
      'cursor-pointer': clickable,
    }
  );

  return (
    <div
      className={logoContainerClasses}
      onClick={clickable ? redirectToOverview : undefined}
      role={clickable ? 'button' : 'img'}
    >
      {imgUrl ? renderImg() : renderDefaultMerchantLogo()}
    </div>
  );
};

export default MerchantLogo;
