import React from 'react';
import { Redirect, useParams } from 'react-router-dom';

const LegacyMerchantPageRedirect = () => {
  const { merchantId } = useParams<{ merchantId: string }>();
  const redirectUrl = `/merchantportal/${merchantId}/loyalty`;

  return <Redirect to={redirectUrl} />;
};

export default LegacyMerchantPageRedirect;
