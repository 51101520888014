import React from 'react';
import { MarketRow, MarketSelect, MarketList } from '@market/react';
import { Identifier } from 'routes/profile/models/Identifier';
import { MarketSelectCustomEvent } from '@market/web-components/dist/types/components';
import { useTranslation } from 'react-i18next';

type MarketingEmailDropdownProps = {
  emailIdentifiers: Identifier[];
  selectedEmailId: string;
  onSelect: (
    event: MarketSelectCustomEvent<{
      value: string | any[];
      newSelectedOption: HTMLMarketRowElement;
      newDeselectedOption: HTMLMarketRowElement;
      currentSelectedOptions: HTMLMarketRowElement[];
    }>
  ) => void;
};

const MarketingEmailDropdown: React.FC<MarketingEmailDropdownProps> = ({
  emailIdentifiers,
  selectedEmailId,
  onSelect,
}) => {
  const { t } = useTranslation();

  return (
    <MarketSelect
      {...(emailIdentifiers.length === 0 && { disabled: true })}
      {...(emailIdentifiers.length === 1 && { readonly: true })}
      onMarketSelectValueDidChange={onSelect}
      value={selectedEmailId}
    >
      <label>{t('common.emailAddress')}</label>
      <MarketList slot="list">
        {emailIdentifiers.map((identifier) => {
          const { displayValue, token } = identifier;
          return (
            <MarketRow key={token} value={token}>
              {displayValue}
            </MarketRow>
          );
        })}
      </MarketList>
    </MarketSelect>
  );
};

export default MarketingEmailDropdown;
