import { SignInIdentifierType } from 'routes/native-sign-in/types';

export interface RequestError {
  type?: RequestErrorType;
  message?: string;
  field?: string;
}

export enum RequestErrorType {
  ValueError = 'ValueError',
  IdentifierClaimedError = 'IdentifierClaimedError',
}

export enum RequestErrorCode {
  CodeInvalidError = 'CODE_INVALID',
}

export class IdentifierClaimedError extends Error implements RequestError {
  public type: RequestErrorType;

  constructor(message: string, public claimedType: SignInIdentifierType) {
    super(message);
    this.type = RequestErrorType.IdentifierClaimedError;
    this.claimedType = claimedType;
  }
}

export function isRequestError(error: any): error is RequestError {
  return (
    Boolean((error as RequestError).message) || Boolean(error as RequestError)
  );
}
