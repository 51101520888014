/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import './common';
import '../../customers/request';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      buyerportal: {
        nested: {
          profile: {
            nested: {
              AddProfileIdentifierRequest: {
                fields: {
                  idempotencyKey: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  identifierType: {
                    type: 'squareup.buyerportal.profile.Identifier.Type',
                    id: 2,
                    rule: 'optional',
                  },
                  identifierRawValue: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                },
              },
              AddProfileIdentifierResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                },
              },
              VerifyAddProfileIdentifierRequest: {
                fields: {
                  identifierType: {
                    type: 'squareup.buyerportal.profile.Identifier.Type',
                    id: 1,
                    rule: 'optional',
                  },
                  identifierRawValue: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  verificationCode: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                },
              },
              VerifyAddProfileIdentifierResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                  identifier: {
                    type: 'squareup.buyerportal.profile.Identifier',
                    id: 3,
                    rule: 'optional',
                  },
                },
              },
              DeleteProfileIdentifierRequest: {
                fields: {
                  identifierType: {
                    type: 'squareup.buyerportal.profile.Identifier.Type',
                    id: 1,
                    rule: 'optional',
                  },
                  identifierId: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                },
              },
              DeleteProfileIdentifierResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                },
              },
              VerifyEmailByTokenRequest: {
                fields: {
                  token: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                },
              },
              VerifyEmailByTokenResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                  obfuscatedVerifiedEmail: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.profile?.nested
    ?.AddProfileIdentifierRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.profile?.nested
    ?.AddProfileIdentifierResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.profile?.nested
    ?.VerifyAddProfileIdentifierRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.profile?.nested
    ?.VerifyAddProfileIdentifierResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.profile?.nested
    ?.DeleteProfileIdentifierRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.profile?.nested
    ?.DeleteProfileIdentifierResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.profile?.nested
    ?.VerifyEmailByTokenRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.profile?.nested
    ?.VerifyEmailByTokenResponse
) {
  $root.addJSON(jsonData);
}

/**
 * Verified Profile related protos for Buyer Portal Service RPC Request-Responses
 * Request to add a new email or phone identifier to an authenticated buyer's profile.
 * Note: This step sends a one time verification code to the email or phone, which needs to be verified by VerifyAddProfileIdentifier.
 * Adding a card does not require verification, hence no need to use this request for Cards.
 *
 * SOURCE AddProfileIdentifierRequest @ squareup/buyerportal/profile/verified.proto at 20:1
 */
export const AddProfileIdentifierRequest = $root.lookupType(
  'squareup.buyerportal.profile.AddProfileIdentifierRequest'
);
fixType(AddProfileIdentifierRequest, {
  idempotencyKey: { required: true },
  identifierType: { required: true },
  identifierRawValue: { required: true },
});
Builder.createAndAttachToType(AddProfileIdentifierRequest);
/**
 * Response containing status of input request and errors that might have occurred while processing the input request.
 *
 * SOURCE AddProfileIdentifierResponse @ squareup/buyerportal/profile/verified.proto at 34:1
 */
export const AddProfileIdentifierResponse = $root.lookupType(
  'squareup.buyerportal.profile.AddProfileIdentifierResponse'
);
fixType(AddProfileIdentifierResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(AddProfileIdentifierResponse);
/**
 * Request to verify adding a new identifier to an authenticated buyer's profile.
 *
 * SOURCE VerifyAddProfileIdentifierRequest @ squareup/buyerportal/profile/verified.proto at 45:1
 */
export const VerifyAddProfileIdentifierRequest = $root.lookupType(
  'squareup.buyerportal.profile.VerifyAddProfileIdentifierRequest'
);
fixType(VerifyAddProfileIdentifierRequest, {
  identifierType: { required: true },
  identifierRawValue: { required: true },
});
Builder.createAndAttachToType(VerifyAddProfileIdentifierRequest);
/**
 * Response containing newly added identifier of a buyer's profile along with status of input request
 * and errors that might have occurred while processing the input request.
 *
 * SOURCE VerifyAddProfileIdentifierResponse @ squareup/buyerportal/profile/verified.proto at 60:1
 */
export const VerifyAddProfileIdentifierResponse = $root.lookupType(
  'squareup.buyerportal.profile.VerifyAddProfileIdentifierResponse'
);
fixType(VerifyAddProfileIdentifierResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(VerifyAddProfileIdentifierResponse);
/**
 * Request to delete an existing identifier of an authenticated buyer's profile.
 *
 * SOURCE DeleteProfileIdentifierRequest @ squareup/buyerportal/profile/verified.proto at 74:1
 */
export const DeleteProfileIdentifierRequest = $root.lookupType(
  'squareup.buyerportal.profile.DeleteProfileIdentifierRequest'
);
fixType(DeleteProfileIdentifierRequest, {
  identifierType: { required: true },
  identifierId: { required: true },
});
Builder.createAndAttachToType(DeleteProfileIdentifierRequest);
/**
 * Response containing status of input request and errors that might have occurred while processing the input request.
 *
 * SOURCE DeleteProfileIdentifierResponse @ squareup/buyerportal/profile/verified.proto at 85:1
 */
export const DeleteProfileIdentifierResponse = $root.lookupType(
  'squareup.buyerportal.profile.DeleteProfileIdentifierResponse'
);
fixType(DeleteProfileIdentifierResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(DeleteProfileIdentifierResponse);
/**
 * Request to verify an unverified email by token. The token is encrypted with a buyer id, unverified email token, and expiration date.
 * These tokens are currently being generated in the buyerprofile-buyer-events-consumer.
 *
 * SOURCE VerifyEmailByTokenRequest @ squareup/buyerportal/profile/verified.proto at 97:1
 */
export const VerifyEmailByTokenRequest = $root.lookupType(
  'squareup.buyerportal.profile.VerifyEmailByTokenRequest'
);
fixType(VerifyEmailByTokenRequest, {
  token: { required: true },
});
Builder.createAndAttachToType(VerifyEmailByTokenRequest);
/**
 * Response for the email verification request which contains the status and errors
 *
 * SOURCE VerifyEmailByTokenResponse @ squareup/buyerportal/profile/verified.proto at 104:1
 */
export const VerifyEmailByTokenResponse = $root.lookupType(
  'squareup.buyerportal.profile.VerifyEmailByTokenResponse'
);
fixType(VerifyEmailByTokenResponse, {
  status: { required: true },
  obfuscatedVerifiedEmail: { required: true },
});
Builder.createAndAttachToType(VerifyEmailByTokenResponse);
