import { Product, Value } from 'routes/profile/models/EmailPreference';
import { EmailPreference as RpcEmailPreference } from 'rpc/model/squareup/buyerportal/preference/email';

export function getEnumProduct(product: RpcEmailPreference.Product) {
  switch (product) {
    case RpcEmailPreference.Product.PRODUCT_MARKETING: {
      return Product.Marketing;
    }
    case RpcEmailPreference.Product.PRODUCT_RECEIPT: {
      return Product.Receipt;
    }
    default: {
      throw new Error('Unsupported product type');
    }
  }
}

export function getEnumValue(value: RpcEmailPreference.Value) {
  switch (value) {
    case RpcEmailPreference.Value.VALUE_OPT_IN: {
      return Value.OptIn;
    }
    case RpcEmailPreference.Value.VALUE_OPT_OUT: {
      return Value.OptOut;
    }
    default: {
      throw new Error('Unsupported value type');
    }
  }
}

export function getRpcProduct(product: Product) {
  switch (product) {
    case Product.Marketing: {
      return RpcEmailPreference.Product.PRODUCT_MARKETING;
    }
    case Product.Receipt: {
      return RpcEmailPreference.Product.PRODUCT_RECEIPT;
    }
    default: {
      throw new Error('Unsupported product type');
    }
  }
}

export function getRpcValue(value: Value) {
  switch (value) {
    case Value.OptIn: {
      return RpcEmailPreference.Value.VALUE_OPT_IN;
    }
    case Value.OptOut: {
      return RpcEmailPreference.Value.VALUE_OPT_OUT;
    }
    default: {
      throw new Error('Unsupported value type');
    }
  }
}
