import React from 'react';
import { MarketSvgProps } from '.';

type ChevronProps = {
  direction: 'left' | 'right' | 'up' | 'down';
} & MarketSvgProps;

const Chevron: React.FC<ChevronProps> = ({
  direction = 'right',
  fill = '#000',
}) => {
  const renderChevronPath = () => {
    switch (direction) {
      case 'left': {
        return (
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.29289 7.29285C3.90237 7.68337 3.90237 8.31654 4.29289 8.70706L10.2929 14.7071L11.7071 13.2928L6.41421 7.99995L11.7071 2.70706L10.2929 1.29285L4.29289 7.29285Z"
            fill={fill}
          />
        );
      }
      case 'up': {
        return (
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.29291 4.29289C7.68343 3.90237 8.3166 3.90237 8.70712 4.29289L14.7071 10.2929L13.2929 11.7071L8.00001 6.41421L2.70712 11.7071L1.29291 10.2929L7.29291 4.29289Z"
            fill={fill}
          />
        );
      }
      case 'down': {
        return (
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.70711 11.7071C8.31658 12.0976 7.68342 12.0976 7.29289 11.7071L1.29289 5.70711L2.70711 4.29289L8 9.58579L13.2929 4.29289L14.7071 5.70711L8.70711 11.7071Z"
            fill={fill}
          />
        );
      }
      case 'right': {
        return (
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.7071 7.29285C12.0976 7.68337 12.0976 8.31654 11.7071 8.70706L5.70711 14.7071L4.29289 13.2928L9.58579 7.99995L4.29289 2.70706L5.70711 1.29285L11.7071 7.29285Z"
            fill={fill}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      {renderChevronPath()}
    </svg>
  );
};

export default Chevron;
