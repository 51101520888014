/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      multipass: {
        nested: {
          CredentialState: {
            values: {
              NONE: 0,
              REQUIRED: 1,
              OPTIONAL: 2,
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.multipass?.nested?.CredentialState) {
  $root.addJSON(jsonData);
}

/**
 * A tri-state flag indicating whether a method requires/accepts credentials.
 *
 * SOURCE CredentialState @ squareup/multipass/options.proto at 16:1
 */
export const CredentialState = $root.lookupEnum(
  'squareup.multipass.CredentialState'
).values;
