import React from 'react';
import {
  AmericanExpressMulticolorIcon,
  MastercardMulticolorIcon,
  VisaMulticolorIcon,
} from 'svgs';
import CardSvg from 'svgs/market-icons/Card';

type BrandedCardIconProps = {
  brand?: string;
};

const BrandedCardIcon: React.FC<BrandedCardIconProps> = ({ brand }) => {
  switch (brand) {
    case 'Visa': {
      return <VisaMulticolorIcon />;
    }
    case 'MasterCard': {
      return <MastercardMulticolorIcon />;
    }
    case 'American Express':
    case 'Amex': {
      return <AmericanExpressMulticolorIcon />;
    }
    default: {
      return <CardSvg />;
    }
  }
};

export default BrandedCardIcon;
