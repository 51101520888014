import { MarketAccessory, MarketPill, MarketRow } from '@market/react';
import React from 'react';
import Chevron from 'svgs/market-icons/Chevron';
import BrandedCardIcon from 'components/profile/BrandedCardIcon';
import { theme } from 'tailwind.config';
import { Identifier } from 'routes/profile/models/Identifier';
import { useDispatch } from 'react-redux';
import { ModalType, openModal } from 'store/modalSlice';
import { useTranslation } from 'react-i18next';

type UnverifiedPaymentCardRowProps = {
  unverifiedCard: Identifier;
};

const UnverifiedPaymentCardRow: React.FC<UnverifiedPaymentCardRowProps> = ({
  unverifiedCard,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <MarketRow
      role={'option'}
      transient
      onClick={() => {
        dispatch(
          openModal({
            type: ModalType.AddPaymentCard,
            props: {
              unverifiedCard,
            },
          })
        );
      }}
    >
      <div className={'flex items-center'}>
        <MarketAccessory size={'image'} slot={'icon'}>
          <BrandedCardIcon brand={unverifiedCard.displayValue.split(' ')[0]} />
        </MarketAccessory>
        <div className={'ml-4'}>
          <div className={'font-medium'}>{unverifiedCard.displayValue}</div>
        </div>
        <div className={'ml-auto flex items-center'}>
          <MarketPill className={'mr-4'} variant={'critical'}>
            {t(
              'profile.paymentMethods.paymentCards.details.cardInfo.unverified'
            )}
          </MarketPill>
          <Chevron fill={theme.extend.colors.lightgrey} direction={'right'} />
        </div>
      </div>
    </MarketRow>
  );
};

export default UnverifiedPaymentCardRow;
