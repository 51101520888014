import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type MerchantPortalState = {
  activeLocationId?: string;
};

export const selectActiveLocationId = (
  state: MerchantPortalState
): string | undefined => {
  return state.activeLocationId;
};

export const initialState: MerchantPortalState = {
  activeLocationId: undefined,
};

const merchantPortalSlice = createSlice({
  name: 'merchantPortal',
  initialState,
  reducers: {
    setActiveLocation(state, action: PayloadAction<string>) {
      state.activeLocationId = action.payload;
    },
  },
});

export const { setActiveLocation } = merchantPortalSlice.actions;

export default merchantPortalSlice.reducer;
