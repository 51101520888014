/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../../Fixes';
import Builder from '../../../../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      card: {
        nested: {
          balance: {
            nested: {
              loyalty_api: {
                nested: {
                  model: {
                    nested: {
                      LoyaltyReward: {
                        fields: {
                          id: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                          status: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyReward.Status',
                            id: 2,
                            rule: 'optional',
                          },
                          loyaltyAccountId: {
                            type: 'string',
                            id: 3,
                            rule: 'optional',
                          },
                          rewardTierId: {
                            type: 'string',
                            id: 4,
                            rule: 'optional',
                          },
                          points: {
                            type: 'uint32',
                            id: 5,
                            rule: 'optional',
                          },
                          orderId: {
                            type: 'string',
                            id: 6,
                            rule: 'optional',
                          },
                          createdAt: {
                            type: 'string',
                            id: 7,
                            rule: 'optional',
                          },
                          updatedAt: {
                            type: 'string',
                            id: 8,
                            rule: 'optional',
                          },
                          redeemedAt: {
                            type: 'string',
                            id: 9,
                            rule: 'optional',
                          },
                        },
                        nested: {
                          Status: {
                            values: {
                              STATUS_DO_NOT_USE: 0,
                              ISSUED: 1,
                              REDEEMED: 2,
                              DELETED: 3,
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyReward &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyReward?.nested?.Status
) {
  $root.addJSON(jsonData);
}

/**
 * --
 * @desc Represents a contract to redeem loyalty points for a [reward tier](entity:LoyaltyProgramRewardTier) discount. Loyalty rewards can be in an ISSUED, REDEEMED, or DELETED state.
 * For more information, see [Manage loyalty rewards](https://developer.squareup.com/docs/loyalty-api/loyalty-rewards).
 * --
 *
 * SOURCE LoyaltyReward @ squareup/card/balance/loyalty_api/model/loyalty-reward.proto at 19:1
 */
export const LoyaltyReward = $root.lookupType(
  'squareup.card.balance.loyalty_api.model.LoyaltyReward'
);
fixType(LoyaltyReward, {
  loyaltyAccountId: { notEmpty: true },
  rewardTierId: { notEmpty: true },
});
Builder.createAndAttachToType(LoyaltyReward);
/**
 * --
 * @desc The status of the loyalty reward.
 * --
 *
 * SOURCE Status @ squareup/card/balance/loyalty_api/model/loyalty-reward.proto at 23:3
 */
LoyaltyReward.Status = $root.lookupEnum(
  'squareup.card.balance.loyalty_api.model.LoyaltyReward.Status'
).values;
