import React from 'react';

const MastercardMulticolorIcon: React.FC = () => {
  return (
    <svg
      width="36"
      height="24"
      viewBox="0 0 36 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H32C34.2091 0 36 1.79086 36 4V20C36 22.2091 34.2091 24 32 24H4C1.79086 24 0 22.2091 0 20V4Z"
        fill="white"
      />
      <path
        d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H32C33.933 0.5 35.5 2.067 35.5 4V20C35.5 21.933 33.933 23.5 32 23.5H4C2.067 23.5 0.5 21.933 0.5 20V4Z"
        stroke="black"
        strokeOpacity="0.15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5988 17.4985H21.4011V6.50146H14.5988V17.4985Z"
        fill="#FE5F00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3983 12.0011C15.396 9.85296 16.3554 7.82324 17.9996 6.49712C15.0423 4.1085 10.7607 4.63615 8.43635 7.67529C6.11223 10.7144 6.62549 15.1147 9.583 17.5031C12.0528 19.4978 15.5298 19.4978 17.9996 17.5031C16.356 16.1772 15.3966 14.1484 15.3983 12.0011Z"
        fill="#EB011B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.0197 12.0012C29.0195 15.8666 25.9703 19 22.2089 19C20.6822 19 19.1999 18.4728 17.9998 17.5032C20.9571 15.1148 21.4705 10.7145 19.1462 7.67541C18.811 7.2372 18.4262 6.84156 17.9998 6.49724C20.9567 4.10805 25.2383 4.63472 27.5632 7.67366C28.5066 8.90685 29.0195 10.4303 29.0197 11.9993V12.0012Z"
        fill="#F69E1C"
      />
    </svg>
  );
};

export default MastercardMulticolorIcon;
