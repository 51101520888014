import { MarketModalPartial } from '@market/react';
import React, { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'store/modalSlice';

export const enum ModalSize {
  Small = 'marketFullWidthModal:w-[460px]',
}

type Props = {
  children: ReactNode;
  hideClose?: boolean;
  onClose?: (() => void) | (() => Promise<void>);
  size?: ModalSize;
};

const BaseModal: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const close = () => {
    props.onClose?.();
    dispatch(closeModal());
  };

  return (
    <MarketModalPartial
      className={`top-auto ${props.size?.toString() || ''}`}
      onMarketDialogDismissed={close}
    >
      <main>{props.children}</main>
    </MarketModalPartial>
  );
};

export default BaseModal;
