// eslint-disable-next-line filenames/match-exported
import React from 'react';
import { MarketActivityIndicator } from '@market/react';
import { LogoIcon } from 'svgs';
import { useTranslation } from 'react-i18next';

const LoadingVerification: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div
      className={
        'relative align-self-center xs:max-w-[440px] w-screen h-4/5 content-center justify-center'
      }
    >
      {<LogoIcon fontSize={'large'} className={'relative top-5 '} />}

      <div
        className={
          'flex flex-col place-items-center justify-center relative bg-red-1000 w-full h-full'
        }
      >
        <MarketActivityIndicator
          size={'small'}
          className={'top-1/2 left-1/2'}
        />

        <h3 className={'font-bold'}>
          {t('unauthenticatedVerifyEmail.pending.verify')}
        </h3>
      </div>
    </div>
  );
};

export default LoadingVerification;
