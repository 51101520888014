/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import '../common/data';
import '../../common/currency';
import '../../common/time';
import '../../giftcard/model/egift_theme';
import '../../customers/request';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      buyerportal: {
        nested: {
          giftcards: {
            nested: {
              GiftCard: {
                fields: {
                  giftCardTokenFingerprint: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  merchantInfo: {
                    type: 'squareup.buyerportal.common.MerchantInfo',
                    id: 2,
                    rule: 'optional',
                  },
                  balance: {
                    type: 'squareup.common.Money',
                    id: 3,
                    rule: 'optional',
                  },
                  unitTokenFingerprint: {
                    type: 'string',
                    id: 4,
                    rule: 'optional',
                  },
                  updatedAt: {
                    type: 'squareup.common.time.DateTime',
                    id: 5,
                    rule: 'optional',
                  },
                  panSuffix: {
                    type: 'string',
                    id: 6,
                    rule: 'optional',
                  },
                },
              },
              GiftCardDetail: {
                fields: {
                  giftCardTokenFingerprint: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  merchantInfo: {
                    type: 'squareup.buyerportal.common.MerchantInfo',
                    id: 2,
                    rule: 'optional',
                  },
                  balance: {
                    type: 'squareup.common.Money',
                    id: 3,
                    rule: 'optional',
                  },
                  gan: {
                    type: 'string',
                    id: 4,
                    rule: 'optional',
                  },
                  isMerchantBlacklisted: {
                    type: 'bool',
                    id: 5,
                    rule: 'optional',
                  },
                  unitTokenFingerprint: {
                    type: 'string',
                    id: 6,
                    rule: 'optional',
                  },
                  eGiftTheme: {
                    type: 'squareup.giftcard.model.EGiftTheme',
                    id: 7,
                    rule: 'optional',
                  },
                  recipientDetailUrl: {
                    type: 'string',
                    id: 8,
                    rule: 'optional',
                  },
                  isAutoLink: {
                    type: 'bool',
                    id: 9,
                    rule: 'optional',
                  },
                },
              },
              ListGiftCardsRequest: {
                fields: {
                  offset: {
                    type: 'int32',
                    id: 1,
                    rule: 'optional',
                  },
                  limit: {
                    type: 'int32',
                    id: 2,
                    rule: 'optional',
                  },
                },
              },
              ListGiftCardsResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                  giftCards: {
                    type: 'squareup.buyerportal.giftcards.GiftCard',
                    id: 3,
                    rule: 'repeated',
                  },
                  total: {
                    type: 'int32',
                    id: 4,
                    rule: 'optional',
                  },
                },
              },
              RetrieveGiftCardDetailRequest: {
                fields: {
                  giftCardTokenFingerprint: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  unitTokenFingerprint: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                },
              },
              RetrieveGiftCardDetailResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                  giftCardDetail: {
                    type: 'squareup.buyerportal.giftcards.GiftCardDetail',
                    id: 3,
                    rule: 'optional',
                  },
                },
              },
              RetrieveGiftCardDetailBySecretRequest: {
                fields: {
                  giftCardTokenSecret: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                },
              },
              RetrieveGiftCardDetailBySecretResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                  giftCardDetail: {
                    type: 'squareup.buyerportal.giftcards.GiftCardDetail',
                    id: 3,
                    rule: 'optional',
                  },
                },
              },
              UnlinkBuyerFromGiftCardRequest: {
                fields: {
                  giftCardToken: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                },
              },
              UnlinkBuyerFromGiftCardResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                },
              },
              LinkBuyerToGiftCardRequest: {
                fields: {
                  giftCardTokenSecret: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                },
              },
              LinkBuyerToGiftCardResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                },
              },
              TransferGiftCardBalanceRequest: {
                fields: {
                  fromGiftCardToken: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  toGiftCardToken: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  amount: {
                    type: 'squareup.common.Money',
                    id: 3,
                    rule: 'optional',
                  },
                  idempotencyKey: {
                    type: 'string',
                    id: 4,
                    rule: 'optional',
                  },
                },
              },
              TransferGiftCardBalanceResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.giftcards?.nested
    ?.GiftCard &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.giftcards?.nested
    ?.GiftCardDetail &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.giftcards?.nested
    ?.ListGiftCardsRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.giftcards?.nested
    ?.ListGiftCardsResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.giftcards?.nested
    ?.RetrieveGiftCardDetailRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.giftcards?.nested
    ?.RetrieveGiftCardDetailResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.giftcards?.nested
    ?.RetrieveGiftCardDetailBySecretRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.giftcards?.nested
    ?.RetrieveGiftCardDetailBySecretResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.giftcards?.nested
    ?.UnlinkBuyerFromGiftCardRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.giftcards?.nested
    ?.UnlinkBuyerFromGiftCardResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.giftcards?.nested
    ?.LinkBuyerToGiftCardRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.giftcards?.nested
    ?.LinkBuyerToGiftCardResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.giftcards?.nested
    ?.TransferGiftCardBalanceRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.giftcards?.nested
    ?.TransferGiftCardBalanceResponse
) {
  $root.addJSON(jsonData);
}

/**
 * Giftcard related protos for Buyer Portal Service RPC Request-Responses
 * Buyerportal representation of a merchant-scoped eGift card used to display general card
 * information to users. This representation does not include detailed information (like GAN) -
 * see GiftCardDetail for detailed information.
 *
 * SOURCE GiftCard @ squareup/buyerportal/giftcards/data.proto at 23:1
 */
export const GiftCard = $root.lookupType(
  'squareup.buyerportal.giftcards.GiftCard'
);
fixType(GiftCard, {
  updatedAt: { required: true },
});
Builder.createAndAttachToType(GiftCard);
/**
 * Buyerportal representation of a merchant-scoped eGift card used to display detailed card
 * information to users. Aggregates additional details about the eGift card from multiple services.
 *
 * SOURCE GiftCardDetail @ squareup/buyerportal/giftcards/data.proto at 47:1
 */
export const GiftCardDetail = $root.lookupType(
  'squareup.buyerportal.giftcards.GiftCardDetail'
);
fixType(GiftCardDetail);
Builder.createAndAttachToType(GiftCardDetail);
/**
 * Request to list gift cards associated with the logged in profile. Only fetches by emails
 * linked to the profile. Pagination uses offset and limit to support client selecting page
 * numbers.
 *
 * SOURCE ListGiftCardsRequest @ squareup/buyerportal/giftcards/data.proto at 83:1
 */
export const ListGiftCardsRequest = $root.lookupType(
  'squareup.buyerportal.giftcards.ListGiftCardsRequest'
);
fixType(ListGiftCardsRequest, {
  offset: { required: true },
  limit: { required: true },
});
Builder.createAndAttachToType(ListGiftCardsRequest);
/**
 * Response to list gift cards associated with the logged in profile. Only fetches by emails
 * linked to the profile.
 *
 * SOURCE ListGiftCardsResponse @ squareup/buyerportal/giftcards/data.proto at 95:1
 */
export const ListGiftCardsResponse = $root.lookupType(
  'squareup.buyerportal.giftcards.ListGiftCardsResponse'
);
fixType(ListGiftCardsResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(ListGiftCardsResponse);
/**
 * Request to retrieve an eGift card by gift card token.
 *
 * SOURCE RetrieveGiftCardDetailRequest @ squareup/buyerportal/giftcards/data.proto at 112:1
 */
export const RetrieveGiftCardDetailRequest = $root.lookupType(
  'squareup.buyerportal.giftcards.RetrieveGiftCardDetailRequest'
);
fixType(RetrieveGiftCardDetailRequest, {
  giftCardTokenFingerprint: { required: true },
  unitTokenFingerprint: { required: true },
});
Builder.createAndAttachToType(RetrieveGiftCardDetailRequest);
/**
 * Response to retrieve an eGift card by gift card token.
 *
 * SOURCE RetrieveGiftCardDetailResponse @ squareup/buyerportal/giftcards/data.proto at 123:1
 */
export const RetrieveGiftCardDetailResponse = $root.lookupType(
  'squareup.buyerportal.giftcards.RetrieveGiftCardDetailResponse'
);
fixType(RetrieveGiftCardDetailResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(RetrieveGiftCardDetailResponse);
/**
 * Request to retrieve a gift card by encrypted gift card token.
 *
 * SOURCE RetrieveGiftCardDetailBySecretRequest @ squareup/buyerportal/giftcards/data.proto at 137:1
 */
export const RetrieveGiftCardDetailBySecretRequest = $root.lookupType(
  'squareup.buyerportal.giftcards.RetrieveGiftCardDetailBySecretRequest'
);
fixType(RetrieveGiftCardDetailBySecretRequest, {
  giftCardTokenSecret: { required: true },
});
Builder.createAndAttachToType(RetrieveGiftCardDetailBySecretRequest);
/**
 * Response to retrieve a gift card by encrypted gift card token.
 *
 * SOURCE RetrieveGiftCardDetailBySecretResponse @ squareup/buyerportal/giftcards/data.proto at 145:1
 */
export const RetrieveGiftCardDetailBySecretResponse = $root.lookupType(
  'squareup.buyerportal.giftcards.RetrieveGiftCardDetailBySecretResponse'
);
fixType(RetrieveGiftCardDetailBySecretResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(RetrieveGiftCardDetailBySecretResponse);
/**
 * SOURCE UnlinkBuyerFromGiftCardRequest @ squareup/buyerportal/giftcards/data.proto at 156:1
 */
export const UnlinkBuyerFromGiftCardRequest = $root.lookupType(
  'squareup.buyerportal.giftcards.UnlinkBuyerFromGiftCardRequest'
);
fixType(UnlinkBuyerFromGiftCardRequest, {
  giftCardToken: { required: true },
});
Builder.createAndAttachToType(UnlinkBuyerFromGiftCardRequest);
/**
 * SOURCE UnlinkBuyerFromGiftCardResponse @ squareup/buyerportal/giftcards/data.proto at 161:1
 */
export const UnlinkBuyerFromGiftCardResponse = $root.lookupType(
  'squareup.buyerportal.giftcards.UnlinkBuyerFromGiftCardResponse'
);
fixType(UnlinkBuyerFromGiftCardResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(UnlinkBuyerFromGiftCardResponse);
/**
 * SOURCE LinkBuyerToGiftCardRequest @ squareup/buyerportal/giftcards/data.proto at 169:1
 */
export const LinkBuyerToGiftCardRequest = $root.lookupType(
  'squareup.buyerportal.giftcards.LinkBuyerToGiftCardRequest'
);
fixType(LinkBuyerToGiftCardRequest, {
  giftCardTokenSecret: { required: true },
});
Builder.createAndAttachToType(LinkBuyerToGiftCardRequest);
/**
 * SOURCE LinkBuyerToGiftCardResponse @ squareup/buyerportal/giftcards/data.proto at 174:1
 */
export const LinkBuyerToGiftCardResponse = $root.lookupType(
  'squareup.buyerportal.giftcards.LinkBuyerToGiftCardResponse'
);
fixType(LinkBuyerToGiftCardResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(LinkBuyerToGiftCardResponse);
/**
 * SOURCE TransferGiftCardBalanceRequest @ squareup/buyerportal/giftcards/data.proto at 182:1
 */
export const TransferGiftCardBalanceRequest = $root.lookupType(
  'squareup.buyerportal.giftcards.TransferGiftCardBalanceRequest'
);
fixType(TransferGiftCardBalanceRequest, {
  fromGiftCardToken: { required: true },
  toGiftCardToken: { required: true },
  amount: { required: true },
  idempotencyKey: { required: true },
});
Builder.createAndAttachToType(TransferGiftCardBalanceRequest);
/**
 * SOURCE TransferGiftCardBalanceResponse @ squareup/buyerportal/giftcards/data.proto at 196:1
 */
export const TransferGiftCardBalanceResponse = $root.lookupType(
  'squareup.buyerportal.giftcards.TransferGiftCardBalanceResponse'
);
fixType(TransferGiftCardBalanceResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(TransferGiftCardBalanceResponse);
