// TODO: define strict typing event data structure

import { IdentifierType } from 'routes/profile/models/Identifier';
import { AuthorizedApplicationName } from 'rpc/model/squareup/buyerportal/authorizedapps/data';
import { Identifier } from 'rpc/model/squareup/buyerportal/profile/common';
import { Money } from 'routes/profile/models/GiftCard';

export const EVENT_NAME_VIEW = 'View';
export const EVENT_NAME_ACTION = 'Action';
export const EVENT_GROUP_ERROR = 'Error';

// Profile Group
const EVENT_GROUP_PROFILE = 'Profile';

export const profileViewProfileEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_PROFILE,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Profile Page',
  };
};

export const profileRequestAddIdentifierEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_PROFILE,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Request Add: Profile',
  };
};

export const profileRequestDeleteEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_PROFILE,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Request Delete: Profile',
  };
};

export const profileRequestAddNameEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_PROFILE,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Request Add: Name',
  };
};

export const profileGiftCardTabEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_PROFILE,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'View Gift Card Details',
  };
};

export const profileLocalGiftCardBrowseEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_PROFILE,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'External: Browse Local Gift Cards',
  };
};

export const profileGiftCardViewEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_PROFILE,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Gift Card Section Visible',
  };
};

// Add Group - More 'initiating the action'
const EVENT_GROUP_ADD = 'Add';

export const addViewPreferredNameEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_ADD,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Name',
  };
};

export const addSavePreferredNameEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_ADD,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Name',
  };
};

export const addRequestAddIdentifierEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_ADD,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Profile',
  };
};

export const addRequstPhoneIdentifierEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_ADD,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Dropdown: Phone',
  };
};

export const addRequstEmailIdentifierEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_ADD,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Dropdown: Email',
  };
};

export const addVerifyPhoneIdentifierEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_ADD,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Phone',
  };
};

export const addVerifyEmailIdentifierEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_ADD,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Email',
  };
};

export const addVerifyChargeableCardIdentifierEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_ADD,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: CHARGEABLE_CARD,
  };
};

// Confirm Group - Just that the 'confirm' action was initiated;
// success is tracked by separate 'success' events.
const EVENT_GROUP_CONFIRM = 'Confirm';

export const confirmViewPhoneEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_CONFIRM,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Phone',
  };
};

export const confirmViewEmailEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_CONFIRM,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Email',
  };
};

export const confirmAddPhoneEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_CONFIRM,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Phone',
  };
};

export const confirmAddEmailEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_CONFIRM,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Email',
  };
};

export const confirmAddChargeableCardEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_CONFIRM,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: CHARGEABLE_CARD,
  };
};

// Verify New
const EVENT_GROUP_VERIFY_NEW = 'Verify New';

const attachIfAutoPrompt = function (isAutoPrompt: boolean) {
  if (isAutoPrompt) {
    return {
      buyerportal_events_page_detail: 'auto-prompt',
    };
  }

  return {};
};

export const verifyNewViewPhoneEvent = function (isAutoPrompt: boolean) {
  return {
    buyerportal_events_event_group: EVENT_GROUP_VERIFY_NEW,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Phone',
    ...attachIfAutoPrompt(isAutoPrompt),
  };
};

export const verifyNewViewEmailEvent = function (isAutoPrompt: boolean) {
  return {
    buyerportal_events_event_group: EVENT_GROUP_VERIFY_NEW,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Email',
    ...attachIfAutoPrompt(isAutoPrompt),
  };
};

export const verifyNewSavePhoneEvent = function (isAutoPrompt: boolean) {
  return {
    buyerportal_events_event_group: EVENT_GROUP_VERIFY_NEW,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Phone',
    ...attachIfAutoPrompt(isAutoPrompt),
  };
};

export const verifyNewSaveEmailEvent = function (isAutoPrompt: boolean) {
  return {
    buyerportal_events_event_group: EVENT_GROUP_VERIFY_NEW,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Email',
    ...attachIfAutoPrompt(isAutoPrompt),
  };
};

// Verify Existing
const EVENT_GROUP_VERIFY_EXISTING = 'Verify Existing';

export const verifyExistingViewPhoneEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_VERIFY_EXISTING,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Phone',
  };
};

export const verifyExistingViewEmailEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_VERIFY_EXISTING,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Email',
  };
};

export const verifyExistingSavePhoneEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_VERIFY_EXISTING,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Phone',
  };
};

export const verifyExistingSaveEmailEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_VERIFY_EXISTING,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Email',
  };
};

// Download
const EVENT_GROUP_DOWNLOAD = 'Download';

export const downloadViewDownloadPrefilledEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_DOWNLOAD,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Prefilled',
  };
};

export const downloadViewDownloadEmptyEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_DOWNLOAD,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'No Email',
  };
};

export const downloadConfirmDownloadPrefilledEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_DOWNLOAD,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Prefilled',
  };
};

export const downloadConfirmDownloadEmptyEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_DOWNLOAD,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'No Email',
  };
};

// Feedback
const EVENT_GROUP_FEEDBACK = 'Feedback';

export const feedbackPositiveSubmitEvent = function (content?: string) {
  return {
    buyerportal_events_event_group: EVENT_GROUP_FEEDBACK,
    buyerportal_events_event_name: 'Positive',
    buyerportal_events_event_value: content,
  };
};

export const feedbackNegativeSubmitEvent = function (content?: string) {
  return {
    buyerportal_events_event_group: EVENT_GROUP_FEEDBACK,
    buyerportal_events_event_name: 'Negative',
    buyerportal_events_event_value: content,
  };
};

// Success
const EVENT_GROUP_SUCCESS = 'Success';
// TODO: Define `source`s as an enum to prevent magic strings from floating around in
// event dispatching code.
export const successAddPhoneEvent = function (source: string) {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SUCCESS,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Add:Phone',
    buyerportal_events_page_detail: source,
  };
};

export const successAddEmailEvent = function (source: string) {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SUCCESS,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Add:Email',
    buyerportal_events_page_detail: source,
  };
};

export const successAddChargeableCardEvent = function (source: string) {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SUCCESS,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: `Add:${CHARGEABLE_CARD}`,
    buyerportal_events_page_detail: source,
  };
};

export const successAddNameEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SUCCESS,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Add:Name',
  };
};

export const successDeletePhoneEvent = function (source: string) {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SUCCESS,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Delete:Phone',
    buyerportal_events_page_detail: source,
  };
};

export const successDeleteEmailEvent = function (source: string) {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SUCCESS,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Delete:Email',
    buyerportal_events_page_detail: source,
  };
};

export const successDeleteCardEvent = function (source: string) {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SUCCESS,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Delete:Card',
    buyerportal_events_page_detail: source,
  };
};

export const successDeleteChargeableCardEvent = function (source: string) {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SUCCESS,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Delete:ChargeableCard',
    buyerportal_events_page_detail: source,
  };
};

export const successDeleteNameEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SUCCESS,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Delete:Name',
  };
};

export const successExportData = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SUCCESS,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Download',
  };
};

function getAuthorizedAppUnlinkEventValue(app: AuthorizedApplicationName) {
  let eventValue = 'Unknown';
  switch (app) {
    case AuthorizedApplicationName.CASH_APP: {
      eventValue = 'Cash_App unlink';
      break;
    }
    default: {
      break;
    }
  }

  return eventValue;
}

export const successUnlinkAuthorizedApplicationEvent = function (
  app: AuthorizedApplicationName
) {
  return {
    buyerportal_events_page_group: EVENT_GROUP_SUCCESS,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: getAuthorizedAppUnlinkEventValue(app),
  };
};

export const successUnlinkCardEvent = function () {
  return {
    buyerportal_events_page_detail: EVENT_GROUP_SUCCESS,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Not Your Receipt:Card Removed',
  };
};

// Sub Profile
const EVENT_GROUP_SUB_PROFILE = 'Sub Profile';

export const subProfileLoadMerchantEvent = function (merchantCount: number) {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SUB_PROFILE,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Preferences: Merchant',
    buyerportal_events_num_merchants_loaded: merchantCount,
  };
};

export const subProfileViewPhonePreferencesEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SUB_PROFILE,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Preferences: Phone',
  };
};

export const subProfileDeletePhoneActionEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SUB_PROFILE,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Request Delete: Phone',
  };
};

export const subProfileViewEmailPreferencesEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SUB_PROFILE,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Preferences: Email',
  };
};

export const subProfileDeleteEmailActionEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SUB_PROFILE,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Request Delete: Email',
  };
};

export const subProfileViewCardPreferencesEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SUB_PROFILE,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Preferences: Card',
  };
};

export const subProfileDeleteCardActionEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SUB_PROFILE,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Request Delete: Card',
  };
};

export const subProfileReceiptOptOutActionEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SUB_PROFILE,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Receipts Opt Out: Email',
  };
};

export const subProfileReceiptOptInActionEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SUB_PROFILE,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Receipts Opt In: Email',
  };
};

export const subProfileMarketingOptOutActionEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SUB_PROFILE,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Marketing Opt Out: Email',
  };
};

export const subProfileMarketingOptInActionEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SUB_PROFILE,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Marketing Opt In: Email',
  };
};

export const subProfileSellerReceiptOptOutActionEvent = function (
  merchantToken: string
) {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SUB_PROFILE,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Seller Receipt Opt Out: Email',
    buyerportal_events_page_detail: merchantToken,
  };
};

export const subProfileSellerReceiptOptInActionEvent = function (
  merchantToken: string
) {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SUB_PROFILE,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Seller Receipt Opt In: Email',
    buyerportal_events_page_detail: merchantToken,
  };
};

export const subProfileSellerMarketingOptOutActionEvent = function (
  merchantToken: string
) {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SUB_PROFILE,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Seller Marketing Opt Out: Email',
    buyerportal_events_page_detail: merchantToken,
  };
};

export const subProfileSellerMarketingOptInActionEvent = function (
  merchantToken: string
) {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SUB_PROFILE,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Seller Marketing Opt In: Email',
    buyerportal_events_page_detail: merchantToken,
  };
};

export const subProfileLinkEmailActionEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SUB_PROFILE,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Request Link: Email',
  };
};

export const subProfileUnlinkEmailActionEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SUB_PROFILE,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Request Unlink: Email',
  };
};

export const subProfileLinkPhoneActionEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SUB_PROFILE,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Request Link: Phone',
  };
};

export const subProfileUnlinkPhoneActionEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SUB_PROFILE,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Request Unlink: Phone',
  };
};

export const subProfileGiftCardDetailViewEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SUB_PROFILE,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'View Gift Card Details',
  };
};

export const subProfileGiftCardDetailTabEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SUB_PROFILE,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'External: Gift Card Details',
  };
};

export const subProfileGiftCardDetailLocalGiftCardBrowseEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SUB_PROFILE,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'External: Browse Local Gift Cards',
  };
};

// Edit
const EVENT_GROUP_EDIT = 'Edit';

export const editViewProfileEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_EDIT,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Name',
  };
};

export const editSaveProfileActionEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_EDIT,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Request Save: Name',
  };
};

export const editDeleteProfileActionEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_EDIT,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Request Delete: Name',
  };
};

export const editIdentifierPrimaryStatusActionEvent = function (
  identifierType: IdentifierType
) {
  return {
    buyerportal_events_event_group: EVENT_GROUP_EDIT,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: `Primary change: ${identifierType}`,
  };
};

// Delete
const EVENT_GROUP_DELETE = 'Delete';

export const deleteViewDeleteAccountEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_DELETE,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Profile',
  };
};

export const deleteConfirmDeleteAccountEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_DELETE,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Profile',
  };
};

export const deleteViewDeleteEmailEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_DELETE,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Email',
  };
};

export const deleteDeleteEmailActionEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_DELETE,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Email',
  };
};

export const deleteViewDeletePhoneEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_DELETE,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Phone',
  };
};

export const deleteDeletePhoneActionEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_DELETE,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Phone',
  };
};

export const deleteViewDeleteCardEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_DELETE,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Card',
  };
};

// TODO: Bundle this + identifiers into an enum
const CHARGEABLE_CARD = 'Chargeable Card';

export const deleteViewDeleteChargeableCardEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_DELETE,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: CHARGEABLE_CARD,
  };
};

export const deleteDeleteChargeableCardActionEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_DELETE,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: CHARGEABLE_CARD,
  };
};

export const deleteDeleteCardActionEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_DELETE,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Card',
  };
};

export const deleteViewDeleteNameEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_DELETE,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Name',
  };
};

export const deleteDeleteNameActionEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_DELETE,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Name',
  };
};

// Link
const EVENT_GROUP_LINK = 'Link';

export const linkViewLinkEmailEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_LINK,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Email',
  };
};

export const linkLinkEmailActionEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_LINK,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Email',
  };
};

export const linkViewLinkPhoneEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_LINK,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Phone',
  };
};

export const linkLinkPhoneActionEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_LINK,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Phone',
  };
};

// Unlink
const EVENT_GROUP_UNLINK = 'Unlink';

export const unlinkViewUnlinkEmailEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_UNLINK,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Email',
  };
};

export const unlinkUnlinkEmailActionEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_UNLINK,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Email',
  };
};

export const unlinkViewUnlinkPhoneEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_UNLINK,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Phone',
  };
};

export const unlinkUnlinkPhoneActionEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_UNLINK,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Phone',
  };
};

// Unlink Authorized Apps
export const authorizedApplicationRequestDeleteEvent = function (
  app: AuthorizedApplicationName
) {
  return {
    buyerportal_events_event_group: EVENT_GROUP_UNLINK,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: getAuthorizedAppUnlinkEventValue(app),
  };
};

export const unlinkViewUnlinkAuthorizedApplicationEvent = function (
  app: AuthorizedApplicationName
) {
  return {
    buyerportal_events_event_group: EVENT_GROUP_UNLINK,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: getAuthorizedAppUnlinkEventValue(app),
  };
};

// Unauthenticated Marketing
const EVENT_GROUP_UNAUTHENTICATED_MARKETING = 'Unauthenticated Marketing';

export const unauthenticatedMarketingViewUnsubscribeEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_UNAUTHENTICATED_MARKETING,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
  };
};

export const unauthenticatedMarketingUnsubscribeActionEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_UNAUTHENTICATED_MARKETING,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Unsubscribe',
  };
};

export const unauthenticatedMarketingResubscribeActionEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_UNAUTHENTICATED_MARKETING,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Resubscribe',
  };
};

export const unauthenticatedMarketingLoginActionEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_UNAUTHENTICATED_MARKETING,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Sign-In',
  };
};

// Unauthenticated Receipt
const EVENT_GROUP_UNAUTHENTICATED_RECEIPT = 'Unauthenticated Receipt';

export const unauthenticatedReceiptViewUnsubscribeEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_UNAUTHENTICATED_RECEIPT,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
  };
};

export const unauthenticatedReceiptUnsubscribeActionEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_UNAUTHENTICATED_RECEIPT,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Unsubscribe',
  };
};

export const unauthenticatedReceiptResubscribeActionEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_UNAUTHENTICATED_RECEIPT,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Resubscribe',
  };
};

export const unauthenticatedReceiptLoginActionEvent = function () {
  return {
    buyerportal_events_event_group: EVENT_GROUP_UNAUTHENTICATED_RECEIPT,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Sign-In',
  };
};

// Not Your Receipt
const EVENT_GROUP_UNAUTHENTICATED_NOT_YOUR_RECEIPT =
  'Unauthenticated Not Your Receipt';

export const unauthenticatedNotYourReceiptViewAction = function () {
  return {
    buyerportal_events_event_group:
      EVENT_GROUP_UNAUTHENTICATED_NOT_YOUR_RECEIPT,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
  };
};

export const unauthenticatedNotYourReceiptUnlinkActionEvent = function () {
  return {
    buyerportal_events_event_group:
      EVENT_GROUP_UNAUTHENTICATED_NOT_YOUR_RECEIPT,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Unlink Card',
  };
};

export const unauthenticatedNotYourReceiptLoginActionEvent = function () {
  return {
    buyerportal_events_event_group:
      EVENT_GROUP_UNAUTHENTICATED_NOT_YOUR_RECEIPT,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Sign-In',
  };
};

// FAQ
const EVENT_GROUP_FAQ = 'FAQ';

export const faqClickArticleActionEvent = function (source: string) {
  return {
    buyerportal_events_event_group: EVENT_GROUP_FAQ,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Click',
    buyerportal_events_page_detail: source,
  };
};

// Cash Coupons Linking
const EVENT_VALUE_CASH_APP = 'Cash_App';
const EVENT_VALUE_PHONE = 'Phone';
const EVENT_VALUE_EMAIL = 'Email';
const PAGE_DETAIL_CASH_EMAIL = 'Cash App Linking - Marketing Email';
const PAGE_DETAIL_CASH_TEXT = 'Cash App Linking - Marketing Text';

const getIdentifierSpecificEvents = (deliveryIdentifier: Identifier) => {
  switch (deliveryIdentifier.type) {
    case Identifier.Type.TYPE_EMAIL: {
      return {
        buyerportal_events_page_detail: PAGE_DETAIL_CASH_EMAIL,
        buyerportal_events_email_id: deliveryIdentifier.id,
      };
    }
    case Identifier.Type.TYPE_PHONE: {
      return {
        buyerportal_events_page_detail: PAGE_DETAIL_CASH_TEXT,
        buyerportal_events_phone_id: deliveryIdentifier.id,
      };
    }
    default: {
      return {};
    }
  }
};

export const cashDisclaimerViewEvent = (
  deliveryIdentifier: Identifier,
  merchantToken: string
) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_LINK,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: EVENT_VALUE_CASH_APP,
    buyerportal_events_merchant_token: merchantToken,
    ...getIdentifierSpecificEvents(deliveryIdentifier),
  };
};

export const cashDisclaimerActionEvent = (
  deliveryIdentifier: Identifier,
  merchantToken: string
) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_LINK,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: EVENT_VALUE_CASH_APP,
    buyerportal_events_merchant_token: merchantToken,
    ...getIdentifierSpecificEvents(deliveryIdentifier),
  };
};

export const cashRequestPhoneVerificationViewEvent = (
  deliveryIdentifier: Identifier,
  merchantToken: string
) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_ADD,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: EVENT_VALUE_PHONE,
    buyerportal_events_merchant_token: merchantToken,
    ...getIdentifierSpecificEvents(deliveryIdentifier),
  };
};

export const cashRequestPhoneVerificationActionEvent = (
  deliveryIdentifier: Identifier,
  merchantToken: string
) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_ADD,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: EVENT_VALUE_PHONE,
    buyerportal_events_merchant_token: merchantToken,
    ...getIdentifierSpecificEvents(deliveryIdentifier),
  };
};

export const cashVerifyPhoneViewEvent = (
  deliveryIdentifier: Identifier,
  isNewPhone: boolean,
  merchantToken: string
) => {
  return {
    buyerportal_events_event_group: isNewPhone
      ? EVENT_GROUP_VERIFY_NEW
      : EVENT_GROUP_VERIFY_EXISTING,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: EVENT_VALUE_PHONE,
    buyerportal_events_merchant_token: merchantToken,
    ...getIdentifierSpecificEvents(deliveryIdentifier),
  };
};

export const cashVerifyPhoneActionEvent = (
  deliveryIdentifier: Identifier,
  isNewPhone: boolean,
  merchantToken: string
) => {
  return {
    buyerportal_events_event_group: isNewPhone
      ? EVENT_GROUP_VERIFY_NEW
      : EVENT_GROUP_VERIFY_EXISTING,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: EVENT_VALUE_PHONE,
    buyerportal_events_merchant_token: merchantToken,
    ...getIdentifierSpecificEvents(deliveryIdentifier),
  };
};

export const cashRedirectViewEvent = (
  deliveryIdentifier: Identifier,
  merchantToken: string
) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SUCCESS,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: EVENT_VALUE_CASH_APP,
    buyerportal_events_merchant_token: merchantToken,
    ...getIdentifierSpecificEvents(deliveryIdentifier),
  };
};

export const cashRequestEmailViewEvent = (
  deliveryIdentifier: Identifier,
  merchantToken: string
) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_ADD,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: EVENT_VALUE_EMAIL,
    buyerportal_events_merchant_token: merchantToken,
    ...getIdentifierSpecificEvents(deliveryIdentifier),
  };
};

export const cashRequestEmailActionEvent = (
  deliveryIdentifier: Identifier,
  merchantToken: string
) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_ADD,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: EVENT_VALUE_EMAIL,
    buyerportal_events_merchant_token: merchantToken,
    ...getIdentifierSpecificEvents(deliveryIdentifier),
  };
};

export const cashNotOnMobileViewEvent = () => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_LINK,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value:
      'Cash_App linkage message: Need to link on phone',
  };
};

// Cash Coupon Linking BETA
export const cashCouponLinkingBetaInterstitialViewEvent = () => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_LINK,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Cash Coupon Beta - Interstitial',
  };
};

// New Gift Card Flows

export const addGiftCardModalViewEvent = (giftCardToken: string) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_ADD,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'View Add Gift Card Modal',
    buyerportal_events_gift_card_token: giftCardToken,
  };
};

export const addGiftCardClickEvent = (giftCardToken: string) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_ADD,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Add:Gift Card',
    buyerportal_events_gift_card_token: giftCardToken,
  };
};

export const addGiftCardSuccessEvent = (giftCardToken: string) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SUCCESS,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Add Gift Card Success',
    buyerportal_events_gift_card_token: giftCardToken,
  };
};

export const addGiftCardErrorEvent = (
  giftCardToken: string,
  errorMessage: string
) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_ERROR,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Add Gift Card Failure',
    buyerportal_events_page_detail: errorMessage,
    buyerportal_events_gift_card_token: giftCardToken,
  };
};

const EVENT_GROUP_LOAD_GIFT_CARD_FUNDS = 'Load Gift Card Funds';
export const loadGiftCardFundsClickEvent = (giftCardToken: string) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_LOAD_GIFT_CARD_FUNDS,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Load Gift Card Funds',
    buyerportal_events_gift_card_token: giftCardToken,
  };
};

const EVENT_GROUP_TRANSFER_GIFT_CARD_BALANCE = 'Transfer Gift Card Balance';
export const transferGiftCardBalanceModalViewEvent = (
  giftCardToken: string
) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_TRANSFER_GIFT_CARD_BALANCE,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'View Transfer Gift Card Balance Modal',
    buyerportal_events_gift_card_token: giftCardToken,
  };
};

export const transferGiftCardBalanceClickEvent = (
  giftCardToken: string,
  toGiftCardToken: string,
  transferAmount: Money
) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_TRANSFER_GIFT_CARD_BALANCE,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Transfer Gift Card Balance',
    buyerportal_events_gift_card_token: giftCardToken,
    buyerportal_events_gift_card_transfer_to_token: toGiftCardToken,
    buyerportal_events_gift_card_transfer_amount: transferAmount.amount,
    buyerportal_events_gift_card_transfer_currency_code:
      transferAmount.currencyCode,
  };
};

export const transferGiftCardBalanceSuccessEvent = (giftCardToken: string) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SUCCESS,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Transfer Gift Card Balance Success',
    buyerportal_events_gift_card_token: giftCardToken,
  };
};

export const transferGiftCardBalanceErrorEvent = (
  giftCardToken: string,
  errorMessage: string
) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_ERROR,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Transfer Gift Card Balance Failure',
    buyerportal_events_page_detail: errorMessage,
    buyerportal_events_gift_card_token: giftCardToken,
  };
};

export const newGiftCardDetailsPageViewEvent = (giftCardToken: string) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SUB_PROFILE,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'View New Gift Card Details Page',
    buyerportal_events_gift_card_token: giftCardToken,
  };
};

const EVENT_GROUP_GIFT_CARD_WAYS_TO_REDEEM = 'Gift Card Ways to Redeem';
export const giftCardWaysToRedeemClickEvent = (giftCardToken: string) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_GIFT_CARD_WAYS_TO_REDEEM,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Gift Card Ways to Redeem',
    buyerportal_events_gift_card_token: giftCardToken,
  };
};

export const unlinkGiftCardModalViewEvent = (giftCardToken: string) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_UNLINK,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'View Unlink Gift Card Modal',
    buyerportal_events_gift_card_token: giftCardToken,
  };
};

export const unlinkGiftCardClickEvent = (giftCardToken: string) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_UNLINK,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Unlink Gift Card',
    buyerportal_events_gift_card_token: giftCardToken,
  };
};

export const unlinkGiftCardSuccessEvent = (giftCardToken: string) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_SUCCESS,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Unlink Gift Card Success',
    buyerportal_events_gift_card_token: giftCardToken,
  };
};

export const unlinkGiftCardErrorEvent = (giftCardToken: string) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_ERROR,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Unlink Gift Card Failure',
    buyerportal_events_gift_card_token: giftCardToken,
  };
};
