import React, { useContext } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import ContentContainer from '../../../layout-components/ContentContainer';
import AuthenticatedLoyaltyResourcePage from '../wrappers/AuthenticatedLoyaltyResourcePage';
import {
  authenticatedLoyaltyRoutePattern,
  loyaltyClaimPointsRoutePattern,
} from '../routeUtils';
import {
  useRetrieveAccountInfoForBuyerQuery,
  useRetrieveProgramInfoQuery,
} from 'store/query/api-extensions/loyalty';
import ModuleLoadFailed from 'routes/profile/common/errors/ModuleLoadFailed';
import ModuleLoading from 'routes/profile/common/loading/ModuleLoading';

import { MerchantPortalContext } from '../../../index';
import UnauthenticatedLoyaltyResourcePage from 'routes/merchant-scoped-portal/integrations/loyalty/wrappers/UnauthenticatedLoyaltyResourcePage';
import ClaimPointsPage from '../wrappers/ClaimPointsPage';
import { selectBestLoyaltyAccount } from 'routes/merchant-scoped-portal/integrations/loyalty/utils/loyaltyAccount';
import { useSelector } from 'react-redux';
import { selectIsLoyaltyProgramActive } from '../loyaltySlice';
import { AppState } from 'store';

const LoyaltyResourcePage = () => {
  const history = useHistory();
  const mpContext = useContext(MerchantPortalContext);
  const merchantId =
    mpContext.merchantPortalBaseData?.merchantOverview.merchantId ?? '';
  const match = useRouteMatch(authenticatedLoyaltyRoutePattern);

  const isProgramActive = useSelector((state: AppState) =>
    selectIsLoyaltyProgramActive(state)
  );
  const isBuyerAuthenticated = Boolean(mpContext.merchantPortalBaseData?.buyer);
  const isLoyaltyAccountAuthenticated = Boolean(match);
  const shouldFetchAccountInfoForBuyer =
    isBuyerAuthenticated && !isLoyaltyAccountAuthenticated && isProgramActive;

  const { isLoading, isUninitialized, error } = useRetrieveProgramInfoQuery(
    merchantId,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { isLoading: isAccountInfoLoading, data: accounts } =
    useRetrieveAccountInfoForBuyerQuery(merchantId, {
      skip: !shouldFetchAccountInfoForBuyer,
    });

  if (isLoading || isAccountInfoLoading || isUninitialized) {
    return <ModuleLoading />;
  }

  if (error) {
    return <ModuleLoadFailed />;
  }

  if (!isProgramActive) {
    return <Redirect to={`/merchantportal/${merchantId}`} />;
  }

  if (shouldFetchAccountInfoForBuyer && accounts?.[0]?.loyaltyAccount) {
    // Redirect to the authenticated loyalty route if a loyalty account was found
    const bestLoyaltyAccount = selectBestLoyaltyAccount(
      accounts,
      mpContext.merchantPortalBaseData?.buyer?.primaryPhoneId
    );
    const lalt = bestLoyaltyAccount?.loyaltyAccountLookupToken;
    if (lalt) {
      history.push(
        `/merchantportal/${merchantId}/loyalty/lalt/${lalt}/rewards`
      );
    }
  }

  return (
    <>
      {isLoyaltyAccountAuthenticated && (
        <div className="bg-black/[.03] h-[120px]"></div>
      )}
      <ContentContainer>
        <Switch>
          <Route
            path={authenticatedLoyaltyRoutePattern}
            component={AuthenticatedLoyaltyResourcePage}
          />
          <Route
            path={loyaltyClaimPointsRoutePattern}
            component={ClaimPointsPage}
          ></Route>
          <Route component={UnauthenticatedLoyaltyResourcePage}></Route>
        </Switch>
      </ContentContainer>
    </>
  );
};

export default LoyaltyResourcePage;
