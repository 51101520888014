import { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MerchantPortalContext } from 'routes/merchant-scoped-portal';
import { selectLoyaltyProgram } from 'routes/merchant-scoped-portal/integrations/loyalty/loyaltySlice';
import { IBuyer } from 'rpc/model/squareup/buyerportal/buyer/data';
import { AppState } from 'store';

export interface LoyaltySignInVariantState {
  isTosConsentOptional?: boolean;
  claimablePointsToken?: string;
  buyer?: IBuyer;
  merchantId: string;
  programId: string;
}

export type OverridableLoyaltySignInVariantState = Pick<
  LoyaltySignInVariantState,
  'isTosConsentOptional' | 'claimablePointsToken'
>;
/**
 * This hook returns a helper function which navigates to the Loyalty variant of the sign-in page.
 * It owns boilerplate logic for providing required push state passed to the sign-in page, and allows
 * consumers to pass additional arguments (e.g. extra URL search params, additional push state ) to
 * the sign-in page.
 *
 * This hook assumes
 * - The consumer is rendered within <MerchantPortalContext.Provider>, and
 * - The Loyalty program has been fetched and is available in the Redux store
 */
const useNavigateToLoyaltyOnboarding = () => {
  const history = useHistory();
  const mpContext = useContext(MerchantPortalContext);
  const buyer = mpContext.merchantPortalBaseData?.buyer;
  const merchantId =
    mpContext.merchantPortalBaseData?.merchantOverview.merchantId;
  const loyaltyProgram = useSelector((state: AppState) =>
    selectLoyaltyProgram(state.loyaltyMerchantPortal)
  );
  const navigateToLoyaltyOnboarding = useCallback(
    ({
      extraUrlSearchParams = {},
      state,
    }: {
      extraUrlSearchParams?: Record<string, string>;
      state?: OverridableLoyaltySignInVariantState;
    } = {}) => {
      history.push({
        pathname: '/signin',
        search: new URLSearchParams({
          ...extraUrlSearchParams,
          variant: 'LOYALTY',
        }).toString(),
        state: {
          buyer,
          claimablePointsToken: state?.claimablePointsToken,
          isTosConsentOptional: state?.isTosConsentOptional ?? false,
          merchantId,
          programId: loyaltyProgram?.id,
        },
      });
    },
    [buyer, history, loyaltyProgram?.id, merchantId]
  );

  return navigateToLoyaltyOnboarding;
};

export default useNavigateToLoyaltyOnboarding;
