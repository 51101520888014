import React, { useContext } from 'react';
import { MarketRow } from '@market/react';
import { useTranslation } from 'react-i18next';
import AddToAppleWalletButton from 'components/AddToAppleWalletButton';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { selectLoyaltyProgram } from 'routes/merchant-scoped-portal/integrations/loyalty/loyaltySlice';
import { MerchantPortalContext } from 'routes/merchant-scoped-portal';

interface AddToAppleWalletSectionProps {
  loyaltyPassDownloadUrl: string;
}

const AddToAppleWalletSection: React.FC<AddToAppleWalletSectionProps> = ({
  loyaltyPassDownloadUrl,
}) => {
  const { t } = useTranslation();

  const loyaltyProgram = useSelector((state: AppState) =>
    selectLoyaltyProgram(state.loyaltyMerchantPortal)
  );
  const merchantPortalContext = useContext(MerchantPortalContext);
  const { merchantOverview } = merchantPortalContext.merchantPortalBaseData!;

  return (
    <section>
      <MarketRow>
        <label slot="label">{t('loyalty.addToAppleWalletSection.title')}</label>
        <p slot="subtext">
          {t('loyalty.addToAppleWalletSection.subtext', {
            merchantName: merchantOverview?.merchantName,
            pointsTerminology: loyaltyProgram?.terminology?.other,
          })}
        </p>
      </MarketRow>
      <AddToAppleWalletButton downloadUrl={loyaltyPassDownloadUrl} />
    </section>
  );
};

export default AddToAppleWalletSection;
