/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../../Fixes';
import Builder from '../../../../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      card: {
        nested: {
          balance: {
            nested: {
              loyalty_api: {
                nested: {
                  model: {
                    nested: {
                      ClaimablePoints: {
                        fields: {
                          points: {
                            type: 'uint32',
                            id: 1,
                            rule: 'optional',
                          },
                          merchantId: {
                            type: 'string',
                            id: 2,
                            rule: 'optional',
                          },
                          requiresTosConsent: {
                            type: 'bool',
                            id: 3,
                            rule: 'optional',
                          },
                          reason: {
                            type: 'squareup.card.balance.loyalty_api.model.ClaimablePoints.Reason',
                            id: 4,
                            rule: 'optional',
                          },
                        },
                        nested: {
                          Reason: {
                            values: {
                              DO_NOT_USE: 0,
                              MISSED_TRANSACTION: 1,
                              EMAIL_IMPORT: 2,
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.ClaimablePoints &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.ClaimablePoints?.nested?.Reason
) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE ClaimablePoints @ squareup/card/balance/loyalty_api/model/claimable-points.proto at 16:1
 */
export const ClaimablePoints = $root.lookupType(
  'squareup.card.balance.loyalty_api.model.ClaimablePoints'
);
fixType(ClaimablePoints, {
  points: { required: true },
  merchantId: { required: true, notEmpty: true },
  reason: { required: true },
});
Builder.createAndAttachToType(ClaimablePoints);
/**
 * SOURCE Reason @ squareup/card/balance/loyalty_api/model/claimable-points.proto at 19:3
 */
ClaimablePoints.Reason = $root.lookupEnum(
  'squareup.card.balance.loyalty_api.model.ClaimablePoints.Reason'
).values;
