import { Factory } from 'rosie';
import { Benefit } from 'rpc/model/squareup/memberships/model/benefit';
import { BenefitPackage } from 'rpc/model/squareup/memberships/model/benefit-package';
import { MembershipProgram } from 'rpc/model/squareup/memberships/model/membership-program';
import { ProgressMetric } from 'rpc/model/squareup/memberships/model/progress-metric';

export const LoyaltyPointsMultiplierBenefitFactory =
  new Factory<Benefit.LoyaltyPointsMultiplier>().attr(
    'pointsMultiplier',
    '5.0'
  );

export const LoyaltyPointsAdditionBenefitFactory =
  new Factory<Benefit.LoyaltyPointsAddition>().attr('pointsAddition', 10);

export const UnenforcedBenefitFactory = new Factory<Benefit.Unenforced>().attr(
  'description',
  'Priority checkout'
);

export const BenefitFactory = new Factory<Benefit>()
  .sequence('uid', (i) => `benefit-${i}`)
  .attr('type', Benefit.Type.UNENFORCED)
  .attr('loyaltyPointsAddition', LoyaltyPointsAdditionBenefitFactory.build())
  .attr(
    'loyaltyPointsMultiplier',
    LoyaltyPointsMultiplierBenefitFactory.build()
  )
  .attr('unenforced', UnenforcedBenefitFactory.build())
  .after((benefit) => {
    const builder = new Benefit.Builder().uid(benefit.uid).type(benefit.type);

    switch (benefit.type) {
      case Benefit.Type.LOYALTY_POINTS_ADDITION: {
        builder.loyaltyPointsAddition(benefit.loyaltyPointsAddition);
        break;
      }
      case Benefit.Type.LOYALTY_POINTS_MULTIPLIER: {
        builder.loyaltyPointsMultiplier(benefit.loyaltyPointsMultiplier);
        break;
      }
      case Benefit.Type.UNENFORCED: {
        builder.unenforced(benefit.unenforced);
        break;
      }
      default: {
        break;
      }
    }

    return builder.build();
  });

export const EarnCriteriaFactory =
  new Factory<BenefitPackage.EligibilityCriteria.EarnCriteria>()
    .attr('metric', ProgressMetric.LOYALTY_POINTS)
    .attr('threshold', 1000);

export const EligibilityCriteriaFactory =
  new Factory<BenefitPackage.EligibilityCriteria>()
    .sequence('uid', (i) => `earn-criteria-${i}`)
    .attr('earnCriteria', EarnCriteriaFactory.build())
    .attr(
      'eligibilityPeriod',
      BenefitPackage.EligibilityCriteria.EligibilityPeriod
        .ELIGIBILITY_ROLLING_YEAR
    );

export const BenefitPackageFactory = new Factory<BenefitPackage>()
  .sequence('name', (i) => `Tier ${i}`)
  .sequence('uid', (i) => `benefit-package-${i}`)
  .option('threshold', 500)
  .option('pointsMultiplier', '2.0')
  .option('pointsAddition', null)
  .attr(
    'benefits',
    ['pointsMultiplier', 'pointsAddition'],
    (pointsMultiplier: string, pointsAddition: number) => {
      if (pointsMultiplier) {
        return [
          BenefitFactory.build({
            loyaltyPointsMultiplier:
              LoyaltyPointsMultiplierBenefitFactory.build({ pointsMultiplier }),
            type: Benefit.Type.LOYALTY_POINTS_MULTIPLIER,
          }),
          BenefitFactory.build({}, { type: Benefit.Type.UNENFORCED }),
        ];
      }
      if (pointsAddition) {
        return [
          BenefitFactory.build({
            loyaltyPointsAddition: LoyaltyPointsAdditionBenefitFactory.build({
              pointsAddition,
            }),
            type: Benefit.Type.LOYALTY_POINTS_ADDITION,
          }),
          BenefitFactory.build({}, { type: Benefit.Type.UNENFORCED }),
        ];
      }
      return [BenefitFactory.build({}, { type: Benefit.Type.UNENFORCED })];
    }
  )
  .attr('type', BenefitPackage.Type.TIERED)
  .attr('eligibilityCriteria', ['threshold'], (threshold: number) => [
    EligibilityCriteriaFactory.build({
      earnCriteria: EarnCriteriaFactory.build({ threshold }),
    }),
  ])
  .attr('color', '#B4DA55');

export const MembershipProgramFactory = new Factory<MembershipProgram>()
  .attr('status', MembershipProgram.Status.ACTIVE)
  .attr('type', MembershipProgram.Type.TIERED)
  .attr('tieredData', () => {
    return new MembershipProgram.TieredData.Builder()
      .tiers([
        BenefitPackageFactory.build(
          { color: '#111111', name: 'Bronze' },
          { pointsMultiplier: '1.5', threshold: 500 }
        ),
        BenefitPackageFactory.build(
          { color: '#222222', name: 'Silver' },
          { pointsMultiplier: '2.0', threshold: 1000 }
        ),
        BenefitPackageFactory.build(
          { color: '#333333', name: 'Gold' },
          { pointsMultiplier: '3.0', threshold: 5000 }
        ),
      ])
      .build();
  });
