import client from 'rpc/client';
import {
  AuthorizedApplicationName,
  ListAuthorizedApplicationsRequest,
  UnlinkAuthorizedApplicationRequest,
} from 'rpc/model/squareup/buyerportal/authorizedapps/data';
import { RequestStatus } from 'rpc/model/squareup/customers/request';
import {
  AuthorizedApplication,
  AuthorizedApplicationConverters,
} from 'routes/profile/models/AuthorizedApplication';

export async function fetchAuthorizedAppsEndpoint(): Promise<
  Array<AuthorizedApplication>
> {
  const response = await client.listAuthorizedApplications(
    ListAuthorizedApplicationsRequest.create()
  );

  if (response.status !== RequestStatus.STATUS_SUCCESS) {
    throw new Error();
  }

  return AuthorizedApplicationConverters.fromResponse(response);
}

export async function unlinkAuthorizedAppEndpoint(
  name: AuthorizedApplicationName
): Promise<void> {
  const response = await client.unlinkAuthorizedApplication(
    UnlinkAuthorizedApplicationRequest.create({ name })
  );

  if (response.status !== RequestStatus.STATUS_SUCCESS) {
    throw new Error();
  }
}
