/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../../Fixes';
import Builder from '../../../../../Builder';
import '../../../../connect/v2/common/money';
import '../../../../connect/v2/catalog/resources/datatypes';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      card: {
        nested: {
          balance: {
            nested: {
              loyalty_api: {
                nested: {
                  model: {
                    nested: {
                      LoyaltyProgramStatus: {
                        values: {
                          STATUS_DO_NOT_USE: 0,
                          INACTIVE: 1,
                          ACTIVE: 2,
                        },
                      },
                      LoyaltyProgramRewardDefinition: {
                        fields: {
                          scope: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyProgramRewardDefinition.Scope',
                            id: 1,
                            rule: 'optional',
                          },
                          discountType: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyProgramRewardDefinition.Type',
                            id: 2,
                            rule: 'optional',
                          },
                          fixedDiscount: {
                            type: 'squareup.connect.v2.common.Money',
                            id: 3,
                            rule: 'optional',
                          },
                          percentageDiscount: {
                            type: 'string',
                            id: 4,
                            rule: 'optional',
                          },
                          maxDiscount: {
                            type: 'squareup.connect.v2.common.Money',
                            id: 5,
                            rule: 'optional',
                          },
                          catalogObjectIds: {
                            type: 'string',
                            id: 6,
                            rule: 'repeated',
                          },
                          fixedDiscountMoney: {
                            type: 'squareup.connect.v2.common.Money',
                            id: 7,
                            rule: 'optional',
                          },
                          maxDiscountMoney: {
                            type: 'squareup.connect.v2.common.Money',
                            id: 8,
                            rule: 'optional',
                          },
                        },
                        nested: {
                          Scope: {
                            values: {
                              SCOPE_DO_NOT_USE: 0,
                              ORDER: 1,
                              ITEM_VARIATION: 2,
                              CATEGORY: 3,
                            },
                          },
                          Type: {
                            values: {
                              TYPE_DO_NOT_USE: 0,
                              FIXED_AMOUNT: 1,
                              FIXED_PERCENTAGE: 2,
                            },
                          },
                        },
                      },
                      LoyaltyProgramRewardTier: {
                        fields: {
                          id: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                          points: {
                            type: 'uint32',
                            id: 2,
                            rule: 'optional',
                          },
                          name: {
                            type: 'string',
                            id: 3,
                            rule: 'optional',
                          },
                          definition: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyProgramRewardDefinition',
                            id: 4,
                            rule: 'optional',
                          },
                          createdAt: {
                            type: 'string',
                            id: 5,
                            rule: 'optional',
                          },
                          pricingRuleReference: {
                            type: 'squareup.connect.v2.merchant_catalog.resources.CatalogObjectReference',
                            id: 6,
                            rule: 'optional',
                          },
                        },
                      },
                      LoyaltyProgramAccrualRule: {
                        fields: {
                          accrualType: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyProgramAccrualRule.Type',
                            id: 1,
                            rule: 'optional',
                          },
                          points: {
                            type: 'uint32',
                            id: 2,
                            rule: 'optional',
                          },
                          visitMinimumAmountMoney: {
                            type: 'squareup.connect.v2.common.Money',
                            id: 3,
                            rule: 'optional',
                          },
                          spendAmountMoney: {
                            type: 'squareup.connect.v2.common.Money',
                            id: 4,
                            rule: 'optional',
                          },
                          catalogObjectId: {
                            type: 'string',
                            id: 5,
                            rule: 'optional',
                          },
                          excludedCategoryIds: {
                            type: 'string',
                            id: 6,
                            rule: 'repeated',
                          },
                          excludedItemVariationIds: {
                            type: 'string',
                            id: 7,
                            rule: 'repeated',
                          },
                          visitData: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyProgramAccrualRule.VisitData',
                            id: 8,
                            rule: 'optional',
                          },
                          spendData: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyProgramAccrualRule.SpendData',
                            id: 9,
                            rule: 'optional',
                          },
                          itemVariationData: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyProgramAccrualRule.ItemVariationData',
                            id: 10,
                            rule: 'optional',
                          },
                          categoryData: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyProgramAccrualRule.CategoryData',
                            id: 11,
                            rule: 'optional',
                          },
                        },
                        nested: {
                          Type: {
                            values: {
                              TYPE_DO_NOT_USE: 0,
                              VISIT: 1,
                              SPEND: 2,
                              ITEM_VARIATION: 3,
                              CATEGORY: 4,
                            },
                          },
                          TaxMode: {
                            values: {
                              TAX_MODE_DO_NOT_USE: 0,
                              BEFORE_TAX: 1,
                              AFTER_TAX: 2,
                            },
                          },
                          VisitData: {
                            fields: {
                              minimumAmountMoney: {
                                type: 'squareup.connect.v2.common.Money',
                                id: 1,
                                rule: 'optional',
                              },
                              taxMode: {
                                type: 'squareup.card.balance.loyalty_api.model.LoyaltyProgramAccrualRule.TaxMode',
                                id: 2,
                                rule: 'optional',
                              },
                            },
                          },
                          SpendData: {
                            fields: {
                              amountMoney: {
                                type: 'squareup.connect.v2.common.Money',
                                id: 1,
                                rule: 'optional',
                              },
                              excludedCategoryIds: {
                                type: 'string',
                                id: 2,
                                rule: 'repeated',
                              },
                              excludedItemVariationIds: {
                                type: 'string',
                                id: 3,
                                rule: 'repeated',
                              },
                              taxMode: {
                                type: 'squareup.card.balance.loyalty_api.model.LoyaltyProgramAccrualRule.TaxMode',
                                id: 4,
                                rule: 'optional',
                              },
                            },
                          },
                          ItemVariationData: {
                            fields: {
                              itemVariationId: {
                                type: 'string',
                                id: 1,
                                rule: 'optional',
                              },
                            },
                          },
                          CategoryData: {
                            fields: {
                              categoryId: {
                                type: 'string',
                                id: 1,
                                rule: 'optional',
                              },
                            },
                          },
                        },
                      },
                      LoyaltyProgramExpirationPolicy: {
                        fields: {
                          expirationDuration: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                        },
                      },
                      LoyaltyProgramTerminology: {
                        fields: {
                          one: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                          other: {
                            type: 'string',
                            id: 2,
                            rule: 'optional',
                          },
                        },
                      },
                      LoyaltyProgram: {
                        fields: {
                          id: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                          description: {
                            type: 'string',
                            id: 2,
                            rule: 'optional',
                          },
                          status: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyProgramStatus',
                            id: 3,
                            rule: 'optional',
                          },
                          rewardTiers: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyProgramRewardTier',
                            id: 4,
                            rule: 'repeated',
                          },
                          rule: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyProgramAccrualRule',
                            id: 5,
                            rule: 'optional',
                          },
                          expirationPolicy: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyProgramExpirationPolicy',
                            id: 6,
                            rule: 'optional',
                          },
                          terminology: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyProgramTerminology',
                            id: 7,
                            rule: 'optional',
                          },
                          locationIds: {
                            type: 'string',
                            id: 8,
                            rule: 'repeated',
                          },
                          createdAt: {
                            type: 'string',
                            id: 9,
                            rule: 'optional',
                          },
                          updatedAt: {
                            type: 'string',
                            id: 10,
                            rule: 'optional',
                          },
                          accrualRules: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyProgramAccrualRule',
                            id: 11,
                            rule: 'repeated',
                          },
                          version: {
                            type: 'int32',
                            id: 12,
                            rule: 'optional',
                          },
                          isCashIntegrationEnabled: {
                            type: 'bool',
                            id: 13,
                            rule: 'optional',
                          },
                          isAppleVasIntegrationEnabled: {
                            type: 'bool',
                            id: 14,
                            rule: 'optional',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyProgramStatus &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyProgramRewardDefinition &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyProgramRewardDefinition?.nested?.Scope &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyProgramRewardDefinition?.nested?.Type &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyProgramRewardTier &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyProgramAccrualRule &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyProgramAccrualRule?.nested?.Type &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyProgramAccrualRule?.nested?.TaxMode &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyProgramAccrualRule?.nested?.VisitData &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyProgramAccrualRule?.nested?.SpendData &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyProgramAccrualRule?.nested
    ?.ItemVariationData &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyProgramAccrualRule?.nested?.CategoryData &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyProgramExpirationPolicy &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyProgramTerminology &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyProgram
) {
  $root.addJSON(jsonData);
}

/**
 * TODO (@romil) - Add field validations to RFC3339 type fields once we have those available.
 * --
 * @desc Indicates whether the program is currently active.
 * --
 *
 * SOURCE LoyaltyProgramStatus @ squareup/card/balance/loyalty_api/model/loyalty-program.proto at 25:1
 */
export const LoyaltyProgramStatus = $root.lookupEnum(
  'squareup.card.balance.loyalty_api.model.LoyaltyProgramStatus'
).values;
/**
 * --
 * @desc Provides details about the reward tier discount. DEPRECATED at version 2020-12-16. Discount details
 * are now defined using a catalog pricing rule and other catalog objects. For more information, see
 * [Getting discount details for a reward tier](https://developer.squareup.com/docs/loyalty-api/loyalty-rewards#get-discount-details).
 * --
 *
 * SOURCE LoyaltyProgramRewardDefinition @ squareup/card/balance/loyalty_api/model/loyalty-program.proto at 44:1
 */
export const LoyaltyProgramRewardDefinition = $root.lookupType(
  'squareup.card.balance.loyalty_api.model.LoyaltyProgramRewardDefinition'
);
fixType(LoyaltyProgramRewardDefinition, {
  scope: { required: true },
  discountType: { required: true },
});
Builder.createAndAttachToType(LoyaltyProgramRewardDefinition);
/**
 * --
 * @desc Indicates the scope of the reward tier. DEPRECATED at version 2020-12-16. Discount details
 * are now defined using a catalog pricing rule and other catalog objects. For more information, see
 * [Getting discount details for a reward tier](https://developer.squareup.com/docs/loyalty-api/loyalty-rewards#get-discount-details).
 * --
 *
 * SOURCE Scope @ squareup/card/balance/loyalty_api/model/loyalty-program.proto at 51:3
 */
LoyaltyProgramRewardDefinition.Scope = $root.lookupEnum(
  'squareup.card.balance.loyalty_api.model.LoyaltyProgramRewardDefinition.Scope'
).values;
/**
 * --
 * @desc The type of discount the reward tier offers. DEPRECATED at version 2020-12-16. Discount details
 * are now defined using a catalog pricing rule and other catalog objects. For more information, see
 * [Getting discount details for a reward tier](https://developer.squareup.com/docs/loyalty-api/loyalty-rewards#get-discount-details).
 * --
 *
 * SOURCE Type @ squareup/card/balance/loyalty_api/model/loyalty-program.proto at 75:3
 */
LoyaltyProgramRewardDefinition.Type = $root.lookupEnum(
  'squareup.card.balance.loyalty_api.model.LoyaltyProgramRewardDefinition.Type'
).values;
/**
 * --
 * @desc Represents a reward tier in a loyalty program. A reward tier defines how buyers can redeem points for a reward, such as the number of points required and the value and scope of the discount. A loyalty program can offer multiple reward tiers.
 * --
 *
 * SOURCE LoyaltyProgramRewardTier @ squareup/card/balance/loyalty_api/model/loyalty-program.proto at 159:1
 */
export const LoyaltyProgramRewardTier = $root.lookupType(
  'squareup.card.balance.loyalty_api.model.LoyaltyProgramRewardTier'
);
fixType(LoyaltyProgramRewardTier, {
  points: { required: true },
  pricingRuleReference: { required: true },
});
Builder.createAndAttachToType(LoyaltyProgramRewardTier);
/**
 * --
 * @desc Represents an accrual rule, which defines how buyers can earn points from the base [loyalty program](entity:LoyaltyProgram).
 * --
 *
 * SOURCE LoyaltyProgramAccrualRule @ squareup/card/balance/loyalty_api/model/loyalty-program.proto at 204:1
 */
export const LoyaltyProgramAccrualRule = $root.lookupType(
  'squareup.card.balance.loyalty_api.model.LoyaltyProgramAccrualRule'
);
fixType(LoyaltyProgramAccrualRule, {
  accrualType: { required: true },
});
Builder.createAndAttachToType(LoyaltyProgramAccrualRule);
/**
 * --
 * @desc The type of the accrual rule that defines how buyers can earn points.
 * --
 *
 * SOURCE Type @ squareup/card/balance/loyalty_api/model/loyalty-program.proto at 208:3
 */
LoyaltyProgramAccrualRule.Type = $root.lookupEnum(
  'squareup.card.balance.loyalty_api.model.LoyaltyProgramAccrualRule.Type'
).values;
/**
 * --
 * @desc Indicates how taxes should be treated when calculating the purchase amount used for loyalty points accrual.
 * This setting applies only to `SPEND` accrual rules or `VISIT` accrual rules that have a minimum spend requirement.
 * --
 *
 * SOURCE TaxMode @ squareup/card/balance/loyalty_api/model/loyalty-program.proto at 241:3
 */
LoyaltyProgramAccrualRule.TaxMode = $root.lookupEnum(
  'squareup.card.balance.loyalty_api.model.LoyaltyProgramAccrualRule.TaxMode'
).values;
/**
 * --
 * @desc Represents additional data for rules with the `VISIT` accrual type.
 * --
 *
 * SOURCE VisitData @ squareup/card/balance/loyalty_api/model/loyalty-program.proto at 261:3
 */
LoyaltyProgramAccrualRule.VisitData = $root.lookupType(
  'squareup.card.balance.loyalty_api.model.LoyaltyProgramAccrualRule.VisitData'
);
fixType(LoyaltyProgramAccrualRule.VisitData, {
  taxMode: { required: true },
});
Builder.createAndAttachToType(LoyaltyProgramAccrualRule.VisitData);
/**
 * --
 * @desc Represents additional data for rules with the `SPEND` accrual type.
 * --
 *
 * SOURCE SpendData @ squareup/card/balance/loyalty_api/model/loyalty-program.proto at 279:3
 */
LoyaltyProgramAccrualRule.SpendData = $root.lookupType(
  'squareup.card.balance.loyalty_api.model.LoyaltyProgramAccrualRule.SpendData'
);
fixType(LoyaltyProgramAccrualRule.SpendData, {
  amountMoney: { required: true },
  taxMode: { required: true },
});
Builder.createAndAttachToType(LoyaltyProgramAccrualRule.SpendData);
/**
 * --
 * @desc Represents additional data for rules with the `ITEM_VARIATION` accrual type.
 * --
 *
 * SOURCE ItemVariationData @ squareup/card/balance/loyalty_api/model/loyalty-program.proto at 313:3
 */
LoyaltyProgramAccrualRule.ItemVariationData = $root.lookupType(
  'squareup.card.balance.loyalty_api.model.LoyaltyProgramAccrualRule.ItemVariationData'
);
fixType(LoyaltyProgramAccrualRule.ItemVariationData, {
  itemVariationId: { notEmpty: true },
});
Builder.createAndAttachToType(LoyaltyProgramAccrualRule.ItemVariationData);
/**
 * --
 * @desc Represents additional data for rules with the `CATEGORY` accrual type.
 * --
 *
 * SOURCE CategoryData @ squareup/card/balance/loyalty_api/model/loyalty-program.proto at 326:3
 */
LoyaltyProgramAccrualRule.CategoryData = $root.lookupType(
  'squareup.card.balance.loyalty_api.model.LoyaltyProgramAccrualRule.CategoryData'
);
fixType(LoyaltyProgramAccrualRule.CategoryData, {
  categoryId: { notEmpty: true },
});
Builder.createAndAttachToType(LoyaltyProgramAccrualRule.CategoryData);
/**
 * --
 * @desc Describes when the loyalty program expires.
 * --
 *
 * SOURCE LoyaltyProgramExpirationPolicy @ squareup/card/balance/loyalty_api/model/loyalty-program.proto at 433:1
 */
export const LoyaltyProgramExpirationPolicy = $root.lookupType(
  'squareup.card.balance.loyalty_api.model.LoyaltyProgramExpirationPolicy'
);
fixType(LoyaltyProgramExpirationPolicy, {
  expirationDuration: { notEmpty: true },
});
Builder.createAndAttachToType(LoyaltyProgramExpirationPolicy);
/**
 * --
 * @desc Represents the naming used for loyalty points.
 * --
 *
 * SOURCE LoyaltyProgramTerminology @ squareup/card/balance/loyalty_api/model/loyalty-program.proto at 444:1
 */
export const LoyaltyProgramTerminology = $root.lookupType(
  'squareup.card.balance.loyalty_api.model.LoyaltyProgramTerminology'
);
fixType(LoyaltyProgramTerminology, {
  one: { notEmpty: true },
  other: { notEmpty: true },
});
Builder.createAndAttachToType(LoyaltyProgramTerminology);
/**
 * --
 * @desc Represents a Square loyalty program. Loyalty programs define how buyers can earn points and redeem points for rewards.
 * Square sellers can have only one loyalty program, which is created and managed from the Seller Dashboard.
 * For more information, see [Loyalty Program Overview](https://developer.squareup.com/docs/loyalty/overview).
 * --
 *
 * SOURCE LoyaltyProgram @ squareup/card/balance/loyalty_api/model/loyalty-program.proto at 461:1
 */
export const LoyaltyProgram = $root.lookupType(
  'squareup.card.balance.loyalty_api.model.LoyaltyProgram'
);
fixType(LoyaltyProgram, {
  locationIds: { notEmpty: true },
});
Builder.createAndAttachToType(LoyaltyProgram);
