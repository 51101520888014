import React from 'react';

const AmericanExpressMulticolorIcon: React.FC = () => {
  return (
    <svg
      width="36"
      height="24"
      viewBox="0 0 36 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H32C34.2091 0 36 1.79086 36 4V20C36 22.2091 34.2091 24 32 24H4C1.79086 24 0 22.2091 0 20V4Z"
        fill="white"
      />
      <path
        d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H32C33.933 0.5 35.5 2.067 35.5 4V20C35.5 21.933 33.933 23.5 32 23.5H4C2.067 23.5 0.5 21.933 0.5 20V4Z"
        stroke="black"
        strokeOpacity="0.15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.2341 8L27.1171 9.24794L26.039 8H15.6927L14.8683 10.0292L13.9756 8H9.93659L9.70732 8.00507H6.29268L3 16H6.90244L7.39024 14.7571H8.52195L9.00976 16H25.9512L27.0732 14.7368L28.1507 16H32.9707L29.478 12.0127L33 8H28.2341ZM28.7024 9.12112H30.561L28.0093 12.0025L30.5122 14.8687H28.639L27.0878 13.0572L25.4732 14.8687H19.6488V9.12112H25.5512L27.1024 10.9169L28.7024 9.12112ZM16.4146 9.12112H18.658L18.6439 14.8687H17.1805V10.7744L15.439 14.8687H14.2195L12.4629 10.7647V14.8687H9.73171L9.2439 13.6259H6.65854L6.17073 14.8687H4.61463L6.97512 9.12112H8.98049L11.0488 14.1281V9.12112H13.2683L14.9117 12.8091L16.4146 9.12112ZM24.0488 10.435H21.0683V11.2974H23.9951V12.5905H21.0683V13.5549H24.0732V14.3259L26.1707 11.9822L24.0732 9.54673L24.0488 10.435ZM7.18049 12.3069H8.72683L7.95122 10.3538L7.18049 12.3069Z"
        fill="#026FD1"
      />
    </svg>
  );
};

export default AmericanExpressMulticolorIcon;
