import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const LogoIcon: React.FC<SvgIconProps> = (props) => {
  const { color } = props;

  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4.8C0 4.0568 0 3.68519 0.0492466 3.37426C0.320333 1.66269 1.66269 0.320333 3.37426 0.0492466C3.68519 0 4.0568 0 4.8 0H19.2C19.9432 0 20.3148 0 20.6257 0.0492466C22.3373 0.320333 23.6797 1.66269 23.9508 3.37426C24 3.68519 24 4.0568 24 4.8V19.2C24 19.9432 24 20.3148 23.9508 20.6257C23.6797 22.3373 22.3373 23.6797 20.6257 23.9508C20.3148 24 19.9432 24 19.2 24H4.8C4.0568 24 3.68519 24 3.37426 23.9508C1.66269 23.6797 0.320333 22.3373 0.0492466 20.6257C0 20.3148 0 19.9432 0 19.2V4.8ZM4.8 5.93684C4.8 5.30898 5.30898 4.8 5.93684 4.8H18.0632C18.691 4.8 19.2 5.30898 19.2 5.93684V18.0632C19.2 18.691 18.691 19.2 18.0632 19.2H5.93684C5.30898 19.2 4.8 18.691 4.8 18.0632V5.93684ZM9.03115 9.43972C9 9.53557 9 9.65402 9 9.89091V14.1091C9 14.346 9 14.4644 9.03115 14.5603C9.09409 14.754 9.24598 14.9059 9.43972 14.9689C9.53557 15 9.65402 15 9.89091 15H14.1091C14.346 15 14.4644 15 14.5603 14.9689C14.754 14.9059 14.9059 14.754 14.9689 14.5603C15 14.4644 15 14.346 15 14.1091V9.89091C15 9.65402 15 9.53557 14.9689 9.43972C14.9059 9.24598 14.754 9.09409 14.5603 9.03115C14.4644 9 14.346 9 14.1091 9H9.89091C9.65402 9 9.53557 9 9.43972 9.03115C9.24598 9.09409 9.09409 9.24598 9.03115 9.43972Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default LogoIcon;
