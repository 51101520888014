import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConfirmDeleteProps as ConfirmDeleteAuthorizedApplicationProps } from 'routes/profile/account/AuthorizedApplications/modals/ConfirmDeleteModal';
import { RequestDownloadModalProps } from 'routes/profile/account/DownloadData/modals/RequestDownloadModal';
import { ConfirmRemoveGiftCardModalProps } from 'routes/profile/payment-methods/GiftCards/modals/ConfirmRemoveGiftCardModal';
import { ConfirmRemovePaymentCardModalProps } from 'routes/profile/payment-methods/PaymentCards/modals/ConfirmRemovePaymentCardModal';
import { TransferGiftCardBalanceModalProps } from 'routes/profile/payment-methods/GiftCards/modals/TransferGiftCardBalanceModal';
import { AddPaymentCardModalProps } from 'routes/profile/payment-methods/PaymentCards/modals/AddPaymentCardModal';
import { AppState } from 'store';
import { AddGiftCardModalProps } from 'routes/profile/payment-methods/GiftCards/modals/AddGiftCardModal';
import { PointsExpirationModalProps } from 'routes/merchant-scoped-portal/integrations/loyalty/components/PointsExpirationModal';
import { EmailCollectionIncentiveModalProps } from 'routes/merchant-scoped-portal/integrations/loyalty/components/EmailCollectionIncentiveModal';
import { TierProgramDetailsModalProps } from 'routes/merchant-scoped-portal/integrations/loyalty/components/modals/TierProgramDetailsModal';
import { BasicDialogProps } from 'components/modals/BasicDialog';
import { LoyaltyPromotionDetailModalProps } from 'routes/merchant-scoped-portal/integrations/loyalty/components/modals/LoyaltyPromotionDetailModal';
import { LocationListModalProps } from 'routes/merchant-scoped-portal/components/modals/LocationListModal';

export enum ModalType {
  EditProfileDetails,
  AddContactInfo,
  ConfirmDeleteAuthorizedApplication,
  MobileNav,
  DownloadData,
  ConfirmDeleteAccount,
  AddPaymentCard,
  ConfirmRemoveGiftCard,
  ConfirmRemovePaymentCard,
  TransferGiftCardBalance,
  AddGiftCard,
  LocationList,
  PointsExpiration,
  LoyaltyTierProgramDetailsModal,
  LoyaltyPromotionDetailModal,
  LoyaltyEmailIncentiveModal,
  BasicDialog,
  LoyaltyRedemptionOptionsModal,
}

export interface ModalTypeProps {
  [ModalType.EditProfileDetails]: null;
  [ModalType.AddContactInfo]: null;
  [ModalType.ConfirmDeleteAuthorizedApplication]: ConfirmDeleteAuthorizedApplicationProps;
  [ModalType.MobileNav]: null;
  [ModalType.DownloadData]: RequestDownloadModalProps;
  [ModalType.ConfirmDeleteAccount]: null;
  [ModalType.AddPaymentCard]: AddPaymentCardModalProps;
  [ModalType.ConfirmRemoveGiftCard]: ConfirmRemoveGiftCardModalProps;
  [ModalType.ConfirmRemovePaymentCard]: ConfirmRemovePaymentCardModalProps;
  [ModalType.TransferGiftCardBalance]: TransferGiftCardBalanceModalProps;
  [ModalType.AddGiftCard]: AddGiftCardModalProps;
  [ModalType.LocationList]: LocationListModalProps;
  [ModalType.PointsExpiration]: PointsExpirationModalProps;
  [ModalType.LoyaltyTierProgramDetailsModal]: TierProgramDetailsModalProps;
  [ModalType.LoyaltyPromotionDetailModal]: LoyaltyPromotionDetailModalProps;
  [ModalType.LoyaltyEmailIncentiveModal]: EmailCollectionIncentiveModalProps;
  [ModalType.BasicDialog]: BasicDialogProps;
  [ModalType.LoyaltyRedemptionOptionsModal]: null;
}

export type ModalDefinition = {
  type: ModalType;
  props?: ModalTypeProps[ModalType];
};

export type ModalState = {
  activeModal: ModalDefinition | null;
};

export const selectActiveModal = (state: AppState) => state.modal.activeModal;

export const initialState: ModalState = {
  activeModal: null,
};

const modalSlice = createSlice({
  initialState,
  name: 'modal-v2',
  reducers: {
    /*
      Redux Toolkit cannot hold onto generics so Typescript cannot dynamically determine the correct props to infer.
      As a result, this payload's props property is inferred as a union of ModalTypeProps.
      We will rely on runtime errors to catch when a developer passes the wrong props to the modal type.
     */
    closeModal(state: ModalState) {
      Object.assign(state, {
        activeModal: null,
      });
    },
    openModal(state: ModalState, action: PayloadAction<ModalDefinition>) {
      Object.assign(state, {
        activeModal: action.payload,
      });
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
