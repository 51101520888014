import React from 'react';
import ContentContainer from '../../../layout-components/ContentContainer';

const AppointmentsResourcePage = () => {
  const sections = Array.from({ length: 9 }).fill(0);
  return (
    <ContentContainer>
      hi from appointments resource page
      {sections.map((section, index) => (
        <>
          <div key={index} className="bg-blue h-10 mb-10">
            content
          </div>
        </>
      ))}
    </ContentContainer>
  );
};

export default AppointmentsResourcePage;
