/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import './profile/verified';
import './profile/suggested_identifier';
import './profile/privacy';
import './preference/card';
import './preference/email';
import './giftcards/data';
import './cards/data';
import './request/request';
import './cashlink/data';
import './accountlessprivacy/data';
import './authorizedapps/data';
import './onfile/data';
import './onboarding/data';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      buyerportal: {
        nested: {
          BuyerPortalService: {
            methods: {
              RequestAddProfileIdentifier: {
                requestType:
                  'squareup.buyerportal.profile.AddProfileIdentifierRequest',
                responseType:
                  'squareup.buyerportal.profile.AddProfileIdentifierResponse',
              },
              VerifyAddProfileIdentifier: {
                requestType:
                  'squareup.buyerportal.profile.VerifyAddProfileIdentifierRequest',
                responseType:
                  'squareup.buyerportal.profile.VerifyAddProfileIdentifierResponse',
              },
              DeleteProfileIdentifier: {
                requestType:
                  'squareup.buyerportal.profile.DeleteProfileIdentifierRequest',
                responseType:
                  'squareup.buyerportal.profile.DeleteProfileIdentifierResponse',
              },
              RetrieveSuggestedProfileIdentifiers: {
                requestType:
                  'squareup.buyerportal.profile.RetrieveSuggestedProfileIdentifiersRequest',
                responseType:
                  'squareup.buyerportal.profile.RetrieveSuggestedProfileIdentifiersResponse',
              },
              UnlinkSuggestedProfileIdentifier: {
                requestType:
                  'squareup.buyerportal.profile.UnlinkSuggestedProfileIdentifierRequest',
                responseType:
                  'squareup.buyerportal.profile.UnlinkSuggestedProfileIdentifierResponse',
              },
              DeleteProfile: {
                requestType:
                  'squareup.buyerportal.profile.DeleteProfileRequest',
                responseType:
                  'squareup.buyerportal.profile.DeleteProfileResponse',
              },
              ExportProfile: {
                requestType:
                  'squareup.buyerportal.profile.ExportProfileRequest',
                responseType:
                  'squareup.buyerportal.profile.ExportProfileResponse',
              },
              RetrieveCardPreferences: {
                requestType:
                  'squareup.buyerportal.preference.RetrieveCardPreferencesRequest',
                responseType:
                  'squareup.buyerportal.preference.RetrieveCardPreferencesResponse',
              },
              UpdateCardPreference: {
                requestType:
                  'squareup.buyerportal.preference.UpdateCardPreferenceRequest',
                responseType:
                  'squareup.buyerportal.preference.UpdateCardPreferenceResponse',
              },
              SearchCardPreferences: {
                requestType:
                  'squareup.buyerportal.preference.SearchCardPreferencesRequest',
                responseType:
                  'squareup.buyerportal.preference.SearchCardPreferencesResponse',
              },
              RetrieveGlobalEmailPreferences: {
                requestType:
                  'squareup.buyerportal.preference.RetrieveGlobalEmailPreferencesRequest',
                responseType:
                  'squareup.buyerportal.preference.RetrieveGlobalEmailPreferencesResponse',
              },
              RetrieveMerchantEmailPreferences: {
                requestType:
                  'squareup.buyerportal.preference.RetrieveMerchantEmailPreferencesRequest',
                responseType:
                  'squareup.buyerportal.preference.RetrieveMerchantEmailPreferencesResponse',
              },
              UpdateEmailPreference: {
                requestType:
                  'squareup.buyerportal.preference.UpdateEmailPreferenceRequest',
                responseType:
                  'squareup.buyerportal.preference.UpdateEmailPreferenceResponse',
              },
              ListGiftCards: {
                requestType:
                  'squareup.buyerportal.giftcards.ListGiftCardsRequest',
                responseType:
                  'squareup.buyerportal.giftcards.ListGiftCardsResponse',
              },
              RetrieveGiftCardDetail: {
                requestType:
                  'squareup.buyerportal.giftcards.RetrieveGiftCardDetailRequest',
                responseType:
                  'squareup.buyerportal.giftcards.RetrieveGiftCardDetailResponse',
              },
              RetrieveGiftCardDetailBySecret: {
                requestType:
                  'squareup.buyerportal.giftcards.RetrieveGiftCardDetailBySecretRequest',
                responseType:
                  'squareup.buyerportal.giftcards.RetrieveGiftCardDetailBySecretResponse',
              },
              UnlinkBuyerFromGiftCard: {
                requestType:
                  'squareup.buyerportal.giftcards.UnlinkBuyerFromGiftCardRequest',
                responseType:
                  'squareup.buyerportal.giftcards.UnlinkBuyerFromGiftCardResponse',
              },
              LinkBuyerToGiftCard: {
                requestType:
                  'squareup.buyerportal.giftcards.LinkBuyerToGiftCardRequest',
                responseType:
                  'squareup.buyerportal.giftcards.LinkBuyerToGiftCardResponse',
              },
              TransferGiftCardBalance: {
                requestType:
                  'squareup.buyerportal.giftcards.TransferGiftCardBalanceRequest',
                responseType:
                  'squareup.buyerportal.giftcards.TransferGiftCardBalanceResponse',
              },
              ListCards: {
                requestType: 'squareup.buyerportal.cards.ListCardsRequest',
                responseType: 'squareup.buyerportal.cards.ListCardsResponse',
              },
              CreateCard: {
                requestType: 'squareup.buyerportal.cards.CreateCardRequest',
                responseType: 'squareup.buyerportal.cards.CreateCardResponse',
              },
              DeleteCard: {
                requestType: 'squareup.buyerportal.cards.DeleteCardRequest',
                responseType: 'squareup.buyerportal.cards.DeleteCardResponse',
              },
              AddAddress: {
                requestType: 'squareup.buyerportal.request.AddAddressRequest',
                responseType: 'squareup.buyerportal.request.AddAddressResponse',
              },
              RemoveAddress: {
                requestType:
                  'squareup.buyerportal.request.RemoveAddressRequest',
                responseType:
                  'squareup.buyerportal.request.RemoveAddressResponse',
              },
              RetrieveBuyer: {
                requestType:
                  'squareup.buyerportal.request.RetrieveBuyerRequest',
                responseType:
                  'squareup.buyerportal.request.RetrieveBuyerResponse',
              },
              UpdateBuyer: {
                requestType: 'squareup.buyerportal.request.UpdateBuyerRequest',
                responseType:
                  'squareup.buyerportal.request.UpdateBuyerResponse',
              },
              RetrieveCashLinkInfo: {
                requestType:
                  'squareup.buyerportal.cashlink.RetrieveCashLinkInfoRequest',
                responseType:
                  'squareup.buyerportal.cashlink.RetrieveCashLinkInfoResponse',
              },
              RetrieveCashLinkRedirectUrl: {
                requestType:
                  'squareup.buyerportal.cashlink.RetrieveCashLinkRedirectUrlRequest',
                responseType:
                  'squareup.buyerportal.cashlink.RetrieveCashLinkRedirectUrlResponse',
              },
              CreateVerification: {
                requestType:
                  'squareup.buyerportal.request.CreateVerificationRequest',
                responseType:
                  'squareup.buyerportal.request.CreateVerificationResponse',
              },
              CreateAccountlessPrivacyRequest: {
                requestType:
                  'squareup.buyerportal.accountlessprivacy.CreateAccountlessPrivacyRequestRequest',
                responseType:
                  'squareup.buyerportal.accountlessprivacy.CreateAccountlessPrivacyRequestResponse',
              },
              ExecuteAccountlessPrivacyRequest: {
                requestType:
                  'squareup.buyerportal.accountlessprivacy.ExecuteAccountlessPrivacyRequestRequest',
                responseType:
                  'squareup.buyerportal.accountlessprivacy.ExecuteAccountlessPrivacyRequestResponse',
              },
              CheckCashVerification: {
                requestType:
                  'squareup.buyerportal.request.CheckCashVerificationRequest',
                responseType:
                  'squareup.buyerportal.request.CheckCashVerificationResponse',
              },
              LinkWithCash: {
                requestType:
                  'squareup.buyerportal.cashlink.LinkWithCashRequest',
                responseType:
                  'squareup.buyerportal.cashlink.LinkWithCashResponse',
              },
              ListAuthorizedApplications: {
                requestType:
                  'squareup.buyerportal.authorizedapps.ListAuthorizedApplicationsRequest',
                responseType:
                  'squareup.buyerportal.authorizedapps.ListAuthorizedApplicationsResponse',
              },
              UnlinkAuthorizedApplication: {
                requestType:
                  'squareup.buyerportal.authorizedapps.UnlinkAuthorizedApplicationRequest',
                responseType:
                  'squareup.buyerportal.authorizedapps.UnlinkAuthorizedApplicationResponse',
              },
              ListOnFileBankAccounts: {
                requestType:
                  'squareup.buyerportal.onfile.ListOnFileBankAccountsRequest',
                responseType:
                  'squareup.buyerportal.onfile.ListOnFileBankAccountsResponse',
              },
              UnlinkOnFileBankAccount: {
                requestType:
                  'squareup.buyerportal.onfile.UnlinkOnFileBankAccountRequest',
                responseType:
                  'squareup.buyerportal.onfile.UnlinkOnFileBankAccountResponse',
              },
              RetrieveOnFileMerchant: {
                requestType:
                  'squareup.buyerportal.onfile.RetrieveOnFileMerchantRequest',
                responseType:
                  'squareup.buyerportal.onfile.RetrieveOnFileMerchantResponse',
              },
              CreateLoginOrSignupVerification: {
                requestType:
                  'squareup.buyerportal.onboarding.CreateLoginOrSignupVerificationRequest',
                responseType:
                  'squareup.buyerportal.onboarding.CreateLoginOrSignupVerificationResponse',
              },
              AuthenticateOrCreateAccountDraft: {
                requestType:
                  'squareup.buyerportal.onboarding.AuthenticateOrCreateAccountDraftRequest',
                responseType:
                  'squareup.buyerportal.onboarding.AuthenticateOrCreateAccountDraftResponse',
              },
              CreateBuyerFromDraft: {
                requestType:
                  'squareup.buyerportal.onboarding.CreateBuyerFromDraftRequest',
                responseType:
                  'squareup.buyerportal.onboarding.CreateBuyerFromDraftResponse',
              },
              DecoupleUnverifiedEmailInAccountDraft: {
                requestType:
                  'squareup.buyerportal.onboarding.DecoupleUnverifiedEmailInAccountDraftRequest',
                responseType:
                  'squareup.buyerportal.onboarding.DecoupleUnverifiedEmailInAccountDraftResponse',
              },
              VerifyEmailByToken: {
                requestType:
                  'squareup.buyerportal.profile.VerifyEmailByTokenRequest',
                responseType:
                  'squareup.buyerportal.profile.VerifyEmailByTokenResponse',
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.buyerportal?.nested?.BuyerPortalService) {
  $root.addJSON(jsonData);
}

/**
 * Buyer Portal Service & RPC Method protos
 *
 * SOURCE BuyerPortalService @ squareup/buyerportal/service.proto at 25:1
 */
export const BuyerPortalService = $root.lookupService(
  'squareup.buyerportal.BuyerPortalService'
);
