/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      memberships: {
        nested: {
          model: {
            nested: {
              Benefit: {
                fields: {
                  uid: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  type: {
                    type: 'squareup.memberships.model.Benefit.Type',
                    id: 2,
                    rule: 'optional',
                  },
                  loyaltyPointsMultiplier: {
                    type: 'squareup.memberships.model.Benefit.LoyaltyPointsMultiplier',
                    id: 3,
                    rule: 'optional',
                  },
                  loyaltyPointsAddition: {
                    type: 'squareup.memberships.model.Benefit.LoyaltyPointsAddition',
                    id: 4,
                    rule: 'optional',
                  },
                  unenforced: {
                    type: 'squareup.memberships.model.Benefit.Unenforced',
                    id: 5,
                    rule: 'optional',
                  },
                },
                nested: {
                  Type: {
                    values: {
                      TYPE_DO_NOT_USE: 0,
                      LOYALTY_POINTS_MULTIPLIER: 1,
                      LOYALTY_POINTS_ADDITION: 2,
                      UNENFORCED: 3,
                    },
                  },
                  LoyaltyPointsMultiplier: {
                    fields: {
                      pointsMultiplierPermille: {
                        type: 'uint32',
                        id: 1,
                        rule: 'optional',
                      },
                      pointsMultiplier: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                    },
                  },
                  LoyaltyPointsAddition: {
                    fields: {
                      pointsAddition: {
                        type: 'uint32',
                        id: 1,
                        rule: 'optional',
                      },
                    },
                  },
                  Unenforced: {
                    fields: {
                      description: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.memberships?.nested?.model?.nested
    ?.Benefit &&
  !$root.nested?.squareup?.nested?.memberships?.nested?.model?.nested?.Benefit
    ?.nested?.Type &&
  !$root.nested?.squareup?.nested?.memberships?.nested?.model?.nested?.Benefit
    ?.nested?.LoyaltyPointsMultiplier &&
  !$root.nested?.squareup?.nested?.memberships?.nested?.model?.nested?.Benefit
    ?.nested?.LoyaltyPointsAddition &&
  !$root.nested?.squareup?.nested?.memberships?.nested?.model?.nested?.Benefit
    ?.nested?.Unenforced
) {
  $root.addJSON(jsonData);
}

/**
 * --
 * @desc Represents a single benefit provided to the buyer.
 * --
 *
 * SOURCE Benefit @ squareup/memberships/model/benefit.proto at 17:1
 */
export const Benefit = $root.lookupType('squareup.memberships.model.Benefit');
fixType(Benefit, {
  type: { required: true },
});
Builder.createAndAttachToType(Benefit);
/**
 * --
 * @desc The type of benefit.
 * --
 *
 * SOURCE Type @ squareup/memberships/model/benefit.proto at 21:3
 */
Benefit.Type = $root.lookupEnum(
  'squareup.memberships.model.Benefit.Type'
).values;
/**
 * --
 * @desc Provides metadata when the benefit `type` is `LOYALTY_POINTS_MULTIPLIER`
 * --
 *
 * SOURCE LoyaltyPointsMultiplier @ squareup/memberships/model/benefit.proto at 43:3
 */
Benefit.LoyaltyPointsMultiplier = $root.lookupType(
  'squareup.memberships.model.Benefit.LoyaltyPointsMultiplier'
);
fixType(Benefit.LoyaltyPointsMultiplier);
Builder.createAndAttachToType(Benefit.LoyaltyPointsMultiplier);
/**
 * --
 * @desc Provides metadata when the benefit `type` is `LOYALTY_POINTS_ADDITION`
 * --
 *
 * SOURCE LoyaltyPointsAddition @ squareup/memberships/model/benefit.proto at 65:3
 */
Benefit.LoyaltyPointsAddition = $root.lookupType(
  'squareup.memberships.model.Benefit.LoyaltyPointsAddition'
);
fixType(Benefit.LoyaltyPointsAddition, {
  pointsAddition: { required: true },
});
Builder.createAndAttachToType(Benefit.LoyaltyPointsAddition);
/**
 * --
 * @desc Provides metadata when the benefit `type` is `UNENFORCED`
 * --
 *
 * SOURCE Unenforced @ squareup/memberships/model/benefit.proto at 75:3
 */
Benefit.Unenforced = $root.lookupType(
  'squareup.memberships.model.Benefit.Unenforced'
);
fixType(Benefit.Unenforced, {
  description: { notEmpty: true },
});
Builder.createAndAttachToType(Benefit.Unenforced);
