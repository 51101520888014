import { AsYouType, parsePhoneNumber } from 'libphonenumber-js';
import {
  ContactInformationIdentifierType,
  Identifier,
  IdentifierType,
} from 'routes/profile/models/Identifier';
import { Identifier as RpcIdentifier } from 'rpc/model/squareup/buyerportal/profile/common';

export const MAX_IDENTIFIER_PER_TYPE = 10;
export const MAX_CARD_NUMBER_LENGTH = 18;
export const MIN_CARD_NUMBER_LENGTH = 11;

// https://emailregex.com/
export const EMAIL_REGEX =
  /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/;

export function getRpcIdentifierType(
  identifierType: IdentifierType
): RpcIdentifier.Type {
  switch (identifierType) {
    case IdentifierType.Email: {
      return RpcIdentifier.Type.TYPE_EMAIL;
    }
    case IdentifierType.Phone: {
      return RpcIdentifier.Type.TYPE_PHONE;
    }
    case IdentifierType.Card: {
      return RpcIdentifier.Type.TYPE_CARD;
    }
  }
}

export function cleanNationalNumber(nationalNumber: string) {
  return nationalNumber.replaceAll(/\D/g, '');
}

export function cleanInternationalCode(internationalCode: string) {
  return internationalCode.replaceAll(/[^\d+]/g, '');
}

export function formatPhoneNational(value: string) {
  try {
    const phone = parsePhoneNumber(value, 'US');

    return phone.formatNational();
  } catch {
    return value;
  }
}

export function formatPhoneNationalAsYouType(
  value: string,
  countryCode: CountryCode,
  internationalCode: string
) {
  try {
    const number = cleanNationalNumber(value);
    const code = cleanInternationalCode(
      internationalCode.startsWith('+')
        ? internationalCode
        : `+${internationalCode}`
    );
    const formatter = new AsYouType(countryCode);

    if (countryCode === 'US') {
      // If the country is US, re-format using ONLY the national number.
      // Results in `(xxx) xxx-xxxx` format instead of `xxx xxx-xxxx`.
      formatter.input(number);
      const usFormatter = new AsYouType(countryCode);
      const result = usFormatter.input(formatter.getNationalNumber());
      return {
        isValid: usFormatter.isValid(),
        nationalNumber: result,
      };
    }

    // Non-US numbers are formatted with the international code AND national number.
    const result = formatter.input(code + number);
    return {
      nationalNumber: result.slice(Math.max(0, code.length + 1)),
      isValid: formatter.isValid(),
    };
  } catch {
    return {
      isValid: false,
      nationalNumber: cleanNationalNumber(value),
    };
  }
}

export function isValidIdentifier(
  identifierType: ContactInformationIdentifierType,
  identifierValue: string
): boolean {
  if (identifierType === IdentifierType.Phone) {
    return isValidPhone(identifierValue);
  } else {
    return isValidEmail(identifierValue);
  }
}

export function isValidPhone(value: string): boolean {
  if (!value) {
    return false;
  }

  try {
    const phone = parsePhoneNumber(value, 'US');

    return phone.isValid();
  } catch {
    return false;
  }
}

export function isValidEmail(value: string): boolean {
  return EMAIL_REGEX.test(value);
}

export function isUniqueCredentialIdentifier(identifiers: Array<Identifier>) {
  return (
    identifiers.filter(
      (identifier) =>
        identifier.identifierType === IdentifierType.Email ||
        identifier.identifierType === IdentifierType.Phone
    ).length <= 1
  );
}

/**
 * Sorts identifiers in the order [emails, phones, cards], and if types are equal, in
 * alphabetical order by display name.
 *
 * The identifier collection is sorted both upon receiving it from the BE, and when
 * mutating it on the FE when a new identifier is added.
 * @param identifiers
 */
export function compareIdentifiers(a: Identifier, b: Identifier): number {
  const TYPE_TO_SORT_ORDER = {
    [IdentifierType.Email]: 1,
    [IdentifierType.Phone]: 2,
    [IdentifierType.Card]: 3,
  };

  if (
    TYPE_TO_SORT_ORDER[a.identifierType] ===
    TYPE_TO_SORT_ORDER[b.identifierType]
  ) {
    return a.displayValue < b.displayValue ? -1 : 1;
  }

  return TYPE_TO_SORT_ORDER[a.identifierType] <
    TYPE_TO_SORT_ORDER[b.identifierType]
    ? -1
    : 1;
}
