import React, { Fragment, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import NotYourReceipt from 'routes/not-your-receipt';
import ManageAutomaticReceipts from 'routes/preference/routes/automatic-receipts';
import ManageMarketing from 'routes/preference/routes/marketing';

// https://reacttraining.com/react-router/web/guides/scroll-restoration
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const Preference: React.FC = () => {
  return (
    <Fragment>
      <ScrollToTop />
      <Switch>
        <Route
          exact
          path="/manage/not-your-receipt/:receiptToken"
          component={NotYourReceipt}
        />
        <Route exact path="/manage/marketing/:id" component={ManageMarketing} />
        <Route
          exact
          path="/manage/receipt/:id"
          component={ManageAutomaticReceipts}
        />
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </Fragment>
  );
};

export default Preference;
