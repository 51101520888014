const FEATURE = 'unsubscribe_from_marketing';

type UnsubscribeType = 'all' | 'merchant';
type CampaignType = 'email' | 'phone';

/* Page Views */

export const viewMarketingUnsubscribe = (emailUsrOptInId: string) => {
  return {
    pageTitle: 'Unsubscribe from Marketing',
    feature: FEATURE,
    emailUsrOptInId,
  };
};

export const viewMarketingUnsubscribed = (
  emailUsrOptInId: string,
  campaignType: CampaignType,
  unsubscribeType: UnsubscribeType
) => {
  return {
    pageTitle: 'Marketing Unsubscribed',
    feature: FEATURE,
    emailUsrOptInId,
    campaignType,
    unsubscribeType,
  };
};

export const viewMarketingResubscribed = (
  emailUsrOptInId: string,
  campaignType: CampaignType,
  unsubscribeType: UnsubscribeType
) => {
  return {
    pageTitle: 'Marketing Resubscribed',
    feature: FEATURE,
    emailUsrOptInId,
    campaignType,
    unsubscribeType,
  };
};

/* Actions */

export const actionClickUnsubscribe = (
  emailUsrOptInId: string,
  campaignType: CampaignType,
  unsubscribeType: UnsubscribeType
) => {
  return {
    feature: FEATURE,
    emailUsrOptInId,
    campaignType,
    unsubscribeType,
  };
};
