/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import './data';
import '../../customers/request';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      buyerportal: {
        nested: {
          orders: {
            nested: {
              ListOrdersRequest: {
                fields: {
                  cursorToken: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                },
              },
              ListOrdersResponse: {
                fields: {
                  orderListItems: {
                    type: 'squareup.buyerportal.orders.OrderListItem',
                    id: 1,
                    rule: 'repeated',
                  },
                  cursorToken: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 3,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 4,
                    rule: 'repeated',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.orders?.nested
    ?.ListOrdersRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.orders?.nested
    ?.ListOrdersResponse
) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE ListOrdersRequest @ squareup/buyerportal/orders/request.proto at 12:1
 */
export const ListOrdersRequest = $root.lookupType(
  'squareup.buyerportal.orders.ListOrdersRequest'
);
fixType(ListOrdersRequest);
Builder.createAndAttachToType(ListOrdersRequest);
/**
 * SOURCE ListOrdersResponse @ squareup/buyerportal/orders/request.proto at 16:1
 */
export const ListOrdersResponse = $root.lookupType(
  'squareup.buyerportal.orders.ListOrdersResponse'
);
fixType(ListOrdersResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(ListOrdersResponse);
