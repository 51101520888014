import { ContactInformationIdentifierType } from 'routes/profile/models/Identifier';

const PAGE_TITLE = 'Profile';
const FEATURE = 'Profile';

export const viewProfileProperties = () => {
  return {
    pageTitle: PAGE_TITLE,
    feature: FEATURE,
  };
};

// when user opens modal to edit profile
export const startUpdateProfileProperties = (
  updateType: 'add' | 'edit',
  detail: 'contact info' | 'name'
) => {
  return {
    feature: FEATURE,
    type: updateType,
    detail,
  };
};

// when user saves update to profile
export const saveUpdateProfileProperties = (
  updateType: 'add' | 'edit',
  detail: ContactInformationIdentifierType | 'name'
) => {
  return {
    feature: FEATURE,
    type: updateType,
    detail: detail.toLowerCase(),
  };
};

export const contactInfoSetAsDefaultProperties = (
  detail: ContactInformationIdentifierType
) => {
  return {
    feature: FEATURE,
    detail: detail.toLowerCase(),
  };
};

export const contactInfoRemoveProperties = (
  detail: ContactInformationIdentifierType
) => {
  return {
    feature: FEATURE,
    detail: detail.toLowerCase(),
  };
};

export const startDisconnectCashAppProperties = () => {
  return {
    feature: FEATURE,
    app: 'cash_app',
  };
};

export const confirmDisconnectCashAppProperties = () => {
  return {
    feature: FEATURE,
    app: 'cash_app',
  };
};

export const startDownloadDataProperties = () => {
  return {
    feature: FEATURE,
  };
};

export const confirmDownloadDataProperties = () => {
  return {
    feature: FEATURE,
  };
};

export const startDeleteAccountProperties = () => {
  return {
    feature: FEATURE,
  };
};

export const confirmDeleteAccountProperties = () => {
  return {
    feature: FEATURE,
  };
};
