import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MarketButton } from '@market/react';
import Es2Tracker from 'services/tracking/tracker';
import BackButton from 'components/BackButton';
import { IdentifierType } from 'routes/profile/models/Identifier';
import IdentifierValueInput from '../../../components/IdentifierValueInput';
import useValidation from 'utils/custom-react-hooks/useValidation';
import { isValidEmail } from 'utils/identifiers';
import {
  privacyRequestsDestinationEmailExportActionEvent,
  privacyRequestsDestinationEmailExportViewEvent,
  privacyRequestsDestinationExportInvalidActionEvent,
} from 'services/tracking/events/privacyRequests';

type DestinationExportDataProps = {
  identifierType: IdentifierType;
  identifierValue: string;
  onClickBack: () => void;
  onClickNext: (destinationEmail: string) => void;
};

const DestinationExportData: React.FC<DestinationExportDataProps> = (props) => {
  const { t } = useTranslation(undefined, { useSuspense: false });

  const [emailDestination, setEmailDestination] = useState<string>(
    props.identifierType === IdentifierType.Email ? props.identifierValue : ''
  );

  const [isInvalidEmail, resetValidation, validateEmail] = useValidation(
    (email) => isValidEmail(email)
  );

  useEffect(() => {
    Es2Tracker.track(
      privacyRequestsDestinationEmailExportViewEvent(props.identifierType)
    );
  }, [props.identifierType]);

  const onSetEmailDestination = (email: string) => {
    setEmailDestination(email);
    resetValidation();
  };

  const onNextStep = () => {
    Es2Tracker.track(
      privacyRequestsDestinationEmailExportActionEvent(props.identifierType)
    );

    if (validateEmail(emailDestination)) {
      props.onClickNext(emailDestination);
    } else {
      Es2Tracker.track(
        privacyRequestsDestinationExportInvalidActionEvent(props.identifierType)
      );
    }
  };

  return (
    <>
      <BackButton onClick={props.onClickBack} />
      <h2 className={'mb-4 heading-30'}>
        {t('privacyManagement.destinationExportData.title')}
      </h2>
      <p className={'mt-0 mb-6 paragraph-30'}>
        {t('privacyManagement.destinationExportData.subtitle')}
      </p>
      <IdentifierValueInput
        identifierType={IdentifierType.Email}
        identifierValue={emailDestination}
        onIdentifierValueChanged={onSetEmailDestination}
        isInvalid={isInvalidEmail}
        emailInputDataTestId={'email-input'}
        onEnterPressed={onNextStep}
      />
      <MarketButton
        onClick={onNextStep}
        /* Fun workaround to get disabled working: https://github.com/squareup/market/issues/1570 */
        {...(isInvalidEmail && { disabled: true })}
        rank={'primary'}
      >
        {t('common.next')}
      </MarketButton>
    </>
  );
};

export default DestinationExportData;
