/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../../Fixes';
import Builder from '../../../../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      card: {
        nested: {
          balance: {
            nested: {
              loyalty_api: {
                nested: {
                  model: {
                    nested: {
                      EmailCollectionIncentiveTermsAndStatus: {
                        fields: {
                          pointAmount: {
                            type: 'uint32',
                            id: 1,
                            rule: 'optional',
                          },
                          emailMarketingConsentCopy: {
                            type: 'squareup.card.balance.loyalty_api.model.EmailCollectionIncentiveTermsAndStatus.EmailMarketingConsentCopy',
                            id: 2,
                            rule: 'optional',
                          },
                          eligible: {
                            type: 'bool',
                            id: 3,
                            rule: 'optional',
                          },
                        },
                        nested: {
                          EmailMarketingConsentCopy: {
                            fields: {
                              copyToken: {
                                type: 'string',
                                id: 1,
                                rule: 'optional',
                              },
                              content: {
                                type: 'squareup.card.balance.loyalty_api.model.EmailCollectionIncentiveTermsAndStatus.EmailMarketingConsentCopy.Content',
                                id: 2,
                                rule: 'optional',
                              },
                            },
                            nested: {
                              Content: {
                                fields: {
                                  text: {
                                    type: 'string',
                                    id: 1,
                                    rule: 'optional',
                                  },
                                  privacyDisclosure: {
                                    type: 'string',
                                    id: 2,
                                    rule: 'optional',
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.EmailCollectionIncentiveTermsAndStatus &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.EmailCollectionIncentiveTermsAndStatus?.nested
    ?.EmailMarketingConsentCopy &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.EmailCollectionIncentiveTermsAndStatus?.nested
    ?.EmailMarketingConsentCopy?.nested?.Content
) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE EmailCollectionIncentiveTermsAndStatus @ squareup/card/balance/loyalty_api/model/email-collection-incentive-terms-and-status.proto at 16:1
 */
export const EmailCollectionIncentiveTermsAndStatus = $root.lookupType(
  'squareup.card.balance.loyalty_api.model.EmailCollectionIncentiveTermsAndStatus'
);
fixType(EmailCollectionIncentiveTermsAndStatus, {
  pointAmount: { required: true },
});
Builder.createAndAttachToType(EmailCollectionIncentiveTermsAndStatus);
/**
 * SOURCE EmailMarketingConsentCopy @ squareup/card/balance/loyalty_api/model/email-collection-incentive-terms-and-status.proto at 19:3
 */
EmailCollectionIncentiveTermsAndStatus.EmailMarketingConsentCopy =
  $root.lookupType(
    'squareup.card.balance.loyalty_api.model.EmailCollectionIncentiveTermsAndStatus.EmailMarketingConsentCopy'
  );
fixType(EmailCollectionIncentiveTermsAndStatus.EmailMarketingConsentCopy, {
  copyToken: { notEmpty: true },
  content: { required: true },
});
Builder.createAndAttachToType(
  EmailCollectionIncentiveTermsAndStatus.EmailMarketingConsentCopy
);
/**
 * SOURCE Content @ squareup/card/balance/loyalty_api/model/email-collection-incentive-terms-and-status.proto at 22:5
 */
EmailCollectionIncentiveTermsAndStatus.EmailMarketingConsentCopy.Content =
  $root.lookupType(
    'squareup.card.balance.loyalty_api.model.EmailCollectionIncentiveTermsAndStatus.EmailMarketingConsentCopy.Content'
  );
fixType(
  EmailCollectionIncentiveTermsAndStatus.EmailMarketingConsentCopy.Content,
  {
    text: { notEmpty: true },
  }
);
Builder.createAndAttachToType(
  EmailCollectionIncentiveTermsAndStatus.EmailMarketingConsentCopy.Content
);
