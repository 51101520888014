import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/browser';
import {
  CountryCode,
  isValidPhoneNumber,
  parsePhoneNumber,
  parsePhoneNumberWithError,
} from 'libphonenumber-js';
import { MarketField, MarketInputText } from '@market/react';
import CountrySelector from './CountrySelector';
import {
  cleanInternationalCode,
  cleanNationalNumber,
  formatPhoneNationalAsYouType,
} from 'utils/identifiers';

interface ICountryPhoneDetails {
  countryCode: CountryCode;
  internationalCallingCode: string;
}

interface PhoneInputProps {
  countryCode?: CountryCode;
  disabled?: boolean;
  onChange: (isValid: boolean, number: string, nationalNumber: string) => void;
  onEnterPressed: () => void;
  shouldShowValidationError?: boolean;
  value?: string;
}

const PhoneInput: React.FC<PhoneInputProps> = (props) => {
  const { t } = useTranslation();

  const [countryPhoneDetails, setCountryPhoneDetails] =
    useState<ICountryPhoneDetails>({
      countryCode: 'US',
      internationalCallingCode: '+1',
    });
  const [nationalNumber, setNationalNumber] = useState<string>('');

  // Initial parse of props.value
  useEffect(() => {
    if (!props.value) {
      return;
    }

    let countryCode, formattedNationalNumber, internationalCode, isValid;

    try {
      // Parse for country and calling code
      const phone = parsePhoneNumber(cleanInternationalCode(props.value));

      countryCode = phone.country || 'US';
      internationalCode = phone.countryCallingCode;

      // Re-parse to get properly formatted national number
      const result = formatPhoneNationalAsYouType(
        phone.nationalNumber,
        countryCode,
        internationalCode
      );
      isValid = result.isValid;
      formattedNationalNumber = result.nationalNumber;
    } catch {
      // Partial phone number
      const result = formatPhoneNationalAsYouType(
        cleanNationalNumber(props.value),
        'US',
        '+1'
      );
      isValid = result.isValid;
      countryCode = 'US';
      internationalCode = '+1';
      formattedNationalNumber = result.nationalNumber;
    }

    setCountryPhoneDetails({
      countryCode: (countryCode as CountryCode) || 'US',
      internationalCallingCode: internationalCode || '+1',
    });
    setNationalNumber(formattedNationalNumber);
    props.onChange(
      isValid,
      `${internationalCode} ${formattedNationalNumber}`,
      formattedNationalNumber
    );
    // We only want this effect to run once, on mount. Therefore, we pass an empty deps array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCountryInfoChange = (newCountryInfo: ICountryPhoneDetails) => {
    if (newCountryInfo.countryCode !== countryPhoneDetails.countryCode) {
      setNationalNumber('');
      props.onChange(false, `${newCountryInfo.internationalCallingCode} `, '');
    }

    setCountryPhoneDetails(newCountryInfo);
  };

  const handleNationalNumberChange = (newRawNationalNumber: string) => {
    const newFullPhoneNumber = `${countryPhoneDetails.internationalCallingCode}${newRawNationalNumber}`;
    let newFormattedNationalNumber = newRawNationalNumber;

    const isNewFullPhoneNumberValid = isValidPhoneNumber(
      newFullPhoneNumber,
      countryPhoneDetails.countryCode
    );
    if (isNewFullPhoneNumberValid) {
      try {
        const parsedPhone = parsePhoneNumberWithError(
          newFullPhoneNumber,
          countryPhoneDetails.countryCode
        );
        newFormattedNationalNumber = parsedPhone.format('NATIONAL');
      } catch {
        Sentry.captureException(
          'Failed to parse seemingly valid phone number',
          {
            extra: {
              ...countryPhoneDetails,
            },
          }
        );
      }
    }

    setNationalNumber(newFormattedNationalNumber);
    props.onChange(
      isNewFullPhoneNumberValid,
      `${countryPhoneDetails.internationalCallingCode} ${newFormattedNationalNumber}`,
      newFormattedNationalNumber
    );
  };

  return (
    <div className="flex flex-col mb-6 md:flex-row">
      <div className="mb-2 md:mb-0 md:basis-[27%] md:mr-2">
        <CountrySelector
          className="market-grid-item-large"
          selectedCountryCode={
            countryPhoneDetails ? countryPhoneDetails.countryCode : 'US'
          }
          disabled={Boolean(props.disabled)}
          onChange={(countryCode: CountryCode, internationalCode: string) => {
            const newCountryPhoneDetails: ICountryPhoneDetails = {
              countryCode,
              internationalCallingCode: internationalCode,
            };
            handleCountryInfoChange(newCountryPhoneDetails);
          }}
        />
      </div>
      <div className="md:grow">
        <MarketField key={'phone'} invalid={props.shouldShowValidationError}>
          <MarketInputText
            className="inputText market-grid-item-large"
            {...(props.disabled && { disabled: true })}
            value={nationalNumber}
            inputmode={'tel'}
          >
            <label className="inputTextLabel">{t('common.phoneNumber')}</label>
            <input
              className="inputTextInput"
              slot={'input'}
              data-testid={'phone-input'}
              onChange={(e) => {
                const newNationalNumber = e.target.value;
                handleNationalNumberChange(newNationalNumber);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  props.onEnterPressed();
                }
              }}
              value={nationalNumber}
            />
          </MarketInputText>
          <small slot={'error'}>{t('common.message.validation.phone')}</small>
        </MarketField>
      </div>
    </div>
  );
};

export default PhoneInput;
