import flagsClient from 'rpc/flag-client';
import {
  GetFlagRequest,
  GetFlagsRequest,
  GetFlagResponse,
} from 'rpc/model/squareup/feature/relay/flags/message/get_flags';
import { v4 as uuidv4 } from 'uuid';
import { Token } from 'rpc/model/squareup/feature/relay/common/token';
import { Status } from 'rpc/model/squareup/feature/relay/flags/message/status';

export async function fetchFlags(
  flagRequests: GetFlagRequest[],
  token?: string
): Promise<readonly GetFlagResponse[]> {
  if (token === undefined) {
    token = uuidv4();
  }

  const response = await flagsClient.getFlags(
    GetFlagsRequest.create({
      projectId: 'pie',
      userToken: {
        token,
        // TODO: Figure out what this is being used for
        // and make request to #framework-services to include buyer type.
        type: Token.Type.ANONYMOUS_VISITOR,
      },
      flagRequests: flagRequests.map(({ flagName, flagType }) =>
        GetFlagRequest.create({ flagName, flagType })
      ),
    })
  );

  if (response.status !== Status.SUCCESS) {
    throw new Error('Failed to fetch feature flags.');
  }

  return response.flagResponses;
}
