/*
 * Note: we use buyerportal_events_* to leverage the existing event store.
 * You can check the events being stored in go/rtevents
 */

const EVENT_GROUP_ON_FILE_BUYER = 'ON_FILE_BUYER';
const EVENT_NAME_VIEW = 'VIEW';
const EVENT_NAME_ACTION = 'ACTION';
const EVENT_NAME_ERROR = 'ERROR';

export const SURFACE_DASHBOARD = 'Dashboard';
export const onFileBuyerDashboardViewEvent = function ({
  surface,
  utmMedium = '',
}: {
  surface: 'Dashboard';
  utmMedium: string;
}) {
  return {
    buyerportal_events_event_group: EVENT_GROUP_ON_FILE_BUYER,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: surface,
    webpage_utm_medium: utmMedium,
  };
};

type EventSource = 'CardOnFile' | 'BankOnFile';

export const EVENT_SOURCE_BOF = 'BankOnFile';
export const onFileBuyerDashboardUnlinkEvent = function ({
  eventSource,
  utmMedium = '',
}: {
  eventSource: EventSource;
  utmMedium: string;
}) {
  return {
    buyerportal_events_event_group: EVENT_GROUP_ON_FILE_BUYER,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Unlink',
    buyerportal_events_page_detail: eventSource,
    webpage_utm_medium: utmMedium,
  };
};

export const UNLINK_FAILURE_ERROR = 'UnlinkFailure';
export const onFileBuyerDashboardErrorEvent = function ({
  errorDetail,
  eventSource,
  utmMedium = '',
}: {
  errorDetail: 'UnlinkFailure';
  eventSource: EventSource;
  utmMedium: string;
}) {
  return {
    buyerportal_events_event_group: EVENT_GROUP_ON_FILE_BUYER,
    buyerportal_events_event_name: EVENT_NAME_ERROR,
    buyerportal_events_event_value: errorDetail,
    buyerportal_events_page_detail: eventSource,
    webpage_utm_medium: utmMedium,
  };
};
