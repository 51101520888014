import { useEffect } from 'react';
import {
  buyerportalCdpClient,
  buyerportalUnsubscribeCdpClient,
} from 'services/tracking/cdp/clients/buyerportal';

export enum Client_Namespace {
  DEFAULT = 0,
  UNSUBSCRIBE = 1,
}

type CdpPageTracking = {
  properties: Record<string, any>;
  isLoading: boolean;
  didLoadingFail: boolean;
  onLoad?: Function;
  client?: Client_Namespace;
};

/**
 * This is a custom React hook that will track page views to CDP (Customer Data Platform).
 *
 * @param properties Metadata to include with the event
 * @param isLoading Whether the page is loading. The page view event will not be sent until this is false.
 * @param didLoadingFail Whether the page failed to load. The page view event will not be sent if this is true.
 * @param onLoad Optional callback function. This is called when the page is done loading and the page load didn't fail.
 */
export default function useCdpPageTracking({
  properties,
  isLoading,
  didLoadingFail,
  onLoad,
  client = Client_Namespace.DEFAULT,
}: CdpPageTracking) {
  useEffect(() => {
    if (isLoading || didLoadingFail) {
      return;
    }

    onLoad && onLoad();

    switch (client) {
      case Client_Namespace.UNSUBSCRIBE: {
        buyerportalUnsubscribeCdpClient.page(properties);
        break;
      }
      default: {
        buyerportalCdpClient.page(properties);
      }
    }

    // onLoad and properties require memoization, otherwise developers will log to cdp more than intended. I'm opting to not listen to these as it feels risky to have developers to remember to memoize these passed in parameters.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, didLoadingFail]);
}
