import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import i18n from 'i18n';
import {
  LoadingState,
  LOADING_STATES,
} from 'routes/profile/models/LoadingState';
import { AuthorizedApplication } from 'routes/profile/models/AuthorizedApplication';
import {
  fetchAuthorizedAppsEndpoint,
  unlinkAuthorizedAppEndpoint,
} from 'services/AuthorizedApp';
import Es2Tracker from 'services/tracking/tracker';
import { successUnlinkAuthorizedApplicationEvent } from 'services/tracking/events';
import { closeModal } from 'store/modalSlice';
import { AppState } from 'store';
import { AuthorizedApplicationName } from 'rpc/model/squareup/buyerportal/authorizedapps/data';
import { openToast } from '../../../../store/toastSlice';

export type AuthorizedAppsState = {
  apps: AuthorizedApplication[];
} & LoadingState;

export const initialState: AuthorizedAppsState = {
  ...LOADING_STATES.INITIAL,
  apps: [],
};

export const fetchAuthorizedApps = createAsyncThunk<AuthorizedApplication[]>(
  'authorizedApps/fetchAuthorizedApps',
  async (_, thunkApi) => {
    const { rejectWithValue } = thunkApi;

    try {
      return await fetchAuthorizedAppsEndpoint();
    } catch {
      // TODO - replace with redirect to error page

      return rejectWithValue(
        'Could not retrieve authorized application linkages.'
      );
    }
  }
);

export const unlinkAuthorizedApp = createAsyncThunk(
  'authorizedApps/unlinkAuthorizedApp',
  async (app: AuthorizedApplication, thunkApi) => {
    const { dispatch, rejectWithValue } = thunkApi;

    try {
      await unlinkAuthorizedAppEndpoint(app.id);

      Es2Tracker.track(successUnlinkAuthorizedApplicationEvent(app.id));

      dispatch(closeModal());
      dispatch(
        openToast({
          variant: 'success',
          message: i18n.t('manageAuthorizedApplicationModal.unlink.success', {
            displayAppName: app.displayAppName,
          }),
        })
      );

      return app.id;
    } catch {
      dispatch(
        openToast({
          variant: 'critical',
          message: i18n.t('common.somethingWentWrong.retryable.apologetic'),
        })
      );

      return rejectWithValue(`Could not unlink app ${app}.`);
    }
  }
);

export const selectAuthorizedApplications = (state: AppState) =>
  state.authorizedApps.apps;
export const selectAuthorizedApplication = createSelector(
  [
    selectAuthorizedApplications,
    (_: AppState, id: AuthorizedApplicationName) => id,
  ],
  (authorizedApps, id) => authorizedApps.find((app) => app.id === id)
);

const authorizedAppsSlice = createSlice({
  name: 'authorizedApps',
  initialState,
  reducers: {
    manualDeleteAuthorizedApplication(
      state,
      action: PayloadAction<AuthorizedApplicationName>
    ) {
      state.apps = state.apps.filter((app) => app.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    // fetchAuthorizedApps
    builder.addCase(fetchAuthorizedApps.pending, (state) => {
      return { ...state, ...LOADING_STATES.LOADING };
    });

    builder.addCase(fetchAuthorizedApps.fulfilled, (state, action) => {
      return { ...state, ...LOADING_STATES.LOADED, apps: action.payload };
    });

    builder.addCase(fetchAuthorizedApps.rejected, (state) => {
      return { ...state, ...LOADING_STATES.LOADED };
    });

    // unlinkAuthorizedApp
    builder.addCase(unlinkAuthorizedApp.pending, (state) => {
      return { ...state, ...LOADING_STATES.LOADING };
    });

    builder.addCase(unlinkAuthorizedApp.fulfilled, (state, action) => {
      return {
        ...state,
        ...LOADING_STATES.LOADED,
        apps: state.apps.filter((app) => app.id !== action.payload),
      };
    });

    builder.addCase(unlinkAuthorizedApp.rejected, (state) => {
      return { ...state, ...LOADING_STATES.LOADED };
    });
  },
});

export const { manualDeleteAuthorizedApplication } =
  authorizedAppsSlice.actions;
export default authorizedAppsSlice.reducer;
