import {
  MarketDropdown,
  MarketPopover,
  MarketList,
  MarketRow,
} from '@market/react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { buyerportalCdpClient } from 'services/tracking/cdp/clients/buyerportal';
import {
  ClickFeatureEvent,
  FeatureName,
  FeatureID,
  FeatureFormat,
  EventName,
} from 'services/tracking/cdp/events/types';
import Human from 'svgs/market-icons/Human';
import { logoutUrl } from 'utils/environment';
import { MerchantPortalContext } from '..';

type AccountManagementMenuProps = {
  buyerName?: string;
};

const AccountManagementMenu: React.FC<AccountManagementMenuProps> = ({
  buyerName,
}) => {
  const { t } = useTranslation();
  const location = useLocation();

  const merchantPortalContext = useContext(MerchantPortalContext);
  const { merchantOverview } = merchantPortalContext.merchantPortalBaseData!;
  const { merchantId, merchantName } = merchantOverview;
  const { buyer } = merchantPortalContext.merchantPortalBaseData!;

  const createAccountManagementMenuClickEvent = (
    action_item: string,
    event_description: string
  ): ClickFeatureEvent => {
    return {
      feature_name: FeatureName.AccountManagementMenu,
      feature_id: FeatureID.AccountManagementMenu,
      feature_parent_id: FeatureID.MerchantOverviewPage,
      feature_format: FeatureFormat.PAGE,
      action_item,
      event_description,
      additional_parameters: {
        merchant_id: merchantId,
        merchant_name: merchantName,
        buyer_authenticated: Boolean(buyer),
      },
    };
  };

  const handleDropdownTriggerClick = () => {
    buyerportalCdpClient.track(
      EventName.CLICK_FEATURE,
      createAccountManagementMenuClickEvent(
        'click_menu',
        'clicks account management menu'
      )
    );
  };

  const handleManageProfileClick = () => {
    buyerportalCdpClient.track(
      EventName.CLICK_FEATURE,
      createAccountManagementMenuClickEvent(
        'manage_profile',
        'clicks manage profile from account management menu'
      )
    );
  };

  const handleSignOutClick = () => {
    buyerportalCdpClient.track(
      EventName.CLICK_FEATURE,
      createAccountManagementMenuClickEvent(
        'sign_out',
        'clicks sign out from account management menu'
      )
    );
  };
  const grayCircleIconContainerClasses =
    'flex items-center justify-center w-10 h-10 bg-slate-100 rounded-full';
  return (
    <MarketDropdown
      popoverPlacement={'bottom-start'}
      interaction="click"
      onMarketDropdownOpened={handleDropdownTriggerClick}
    >
      <div
        slot={'trigger'}
        role={'button'}
        data-testid={`${buyerName ? 'buyer-initial' : 'human'}-icon-container`}
        className={`${grayCircleIconContainerClasses} font-medium`}
      >
        {buyerName ? buyerName.charAt(0) : <Human />}
      </div>
      <MarketPopover slot={'popover'}>
        <MarketList transient>
          <MarketRow onClick={handleManageProfileClick}>
            <a
              target="_blank"
              href={'/account'}
              className={'text-black no-underline font-medium'}
              rel="noreferrer"
            >
              {t('navBar.manageProfile')}
            </a>
          </MarketRow>
          <MarketRow onClick={handleSignOutClick}>
            <a
              href={logoutUrl(location.pathname)}
              className={'text-black no-underline font-medium'}
            >
              {t('navBar.signOut')}
            </a>
          </MarketRow>
        </MarketList>
      </MarketPopover>
    </MarketDropdown>
  );
};

export default AccountManagementMenu;
