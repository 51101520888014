import { environment } from 'utils/environment';
import productionConfig from 'config/production.json';
import stagingConfig from 'config/staging.json';

interface CopyId {
  id: string;
}

// Application ID associated with Chargeable Card SDK.
// Location ID can be found under [Application] > Locations.
// Application IDs come from the MTT that buyerportal is using.
// Select the 'buyerportal fe' application to view the credentials.
// Account email: [redacted]
// See documentation in go/customers-directory-developer for retrieving the password.
interface SquareDeveloperConfig {
  applicationId: string;
  locationId: string;
  webPaymentsSdkSrc: string;
}

// These keys are created by creating a source within a namespace using https://go/cdp.
interface CustomerDataPlatformConfig {
  // namespace RISK
  riskApiKey: string;
  // namespace buyer_portal
  buyerportalApiKey: string;
  buyerportalUnsubscribeApiKey: string;
}

// These values are CopyTokens from the Marketing team originating from the Consentsys service.
// More info here - https://docs.google.com/document/d/1AOIglRWARr4kG2JYXdzax9wH8cVBpybNrNy8IpZ-zJg
interface Config {
  globalScopedMarketingConsent: CopyId;
  merchantScopedMarketingConsent: CopyId;
  squareDeveloper: SquareDeveloperConfig;
  customerDataPlatform: CustomerDataPlatformConfig;
}

export const getDashboardDomain = (useAppSubdomain: boolean) => {
  return useAppSubdomain ? 'app.squareup.com' : 'squareup.com';
};

const config: Config = environment().isProduction
  ? productionConfig
  : stagingConfig;
export default config;
