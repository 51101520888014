import React, { useEffect, useState } from 'react';
import { MarketField, MarketInputText, MarketButton } from '@market/react';
import BackButton from '../../components/BackButton';
import { useTranslation } from 'react-i18next';
import Es2Tracker from 'services/tracking/tracker';
import {
  signInCollectNameActionEvent,
  signInCollectNameViewEvent,
} from 'services/tracking/events/signIn';
import { MAX_NAME_LENGTH } from './util/constants';
import nonTranslatableStrings from 'utils/nonTranslatableStrings';

interface CollectNameProps {
  firstName: string;
  lastName: string;
  onBack: () => void;
  onForward: (firstName: string, lastName: string) => void;
}

const CollectName: React.FC<CollectNameProps> = (props) => {
  const { t } = useTranslation();

  const [firstName, setFirstName] = useState<string>(props.firstName || '');
  const [lastName, setLastName] = useState<string>(props.lastName || '');
  const [isFirstNameValid, setIsFirstNameValid] = useState<boolean>(true);
  const [isLastNameValid, setIsLastNameValid] = useState<boolean>(true);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  useEffect(() => {
    Es2Tracker.track(signInCollectNameViewEvent());
  });

  const isNameValid = (name: string): boolean => {
    return name.length > 0;
  };

  const next = () => {
    setIsProcessing(true);
    setIsFirstNameValid(isNameValid(firstName));
    setIsLastNameValid(isNameValid(lastName));

    if (!isFirstNameValid || !isLastNameValid) {
      setIsProcessing(false);
      return;
    }

    Es2Tracker.track(signInCollectNameActionEvent());
    props.onForward(firstName, lastName);
  };

  return (
    <>
      <BackButton onClick={props.onBack} />
      <h2 className={'mt-7 mb-4 heading-30'}>
        {t('onboarding.collectName.title', {
          SQUARE: nonTranslatableStrings.square,
        })}
      </h2>
      <p className={'m-0 mb-4 paragraph-30'}>
        {t('onboarding.collectName.subtitle')}
      </p>

      <MarketField
        key={'firstName'}
        invalid={!isFirstNameValid}
        className={'mb-4'}
      >
        <MarketInputText value={firstName}>
          <label>{t('common.firstName')}</label>
          <input
            slot={'input'}
            data-testid={'firstName'}
            type={'text'}
            inputMode={'text'}
            maxLength={MAX_NAME_LENGTH}
            autoCapitalize={'off'}
            onChange={(e) => {
              const { value } = e.target;
              setFirstName(value);
              setIsFirstNameValid(isNameValid(value));
            }}
          />
        </MarketInputText>
        <small slot={'error'}>{t('common.message.validation.name')}</small>
      </MarketField>

      <MarketField
        key={'lastName'}
        invalid={!isLastNameValid}
        className={'mb-6'}
      >
        <MarketInputText value={lastName}>
          <label>{t('common.lastName')}</label>
          <input
            slot={'input'}
            data-testid={'lastName'}
            type={'text'}
            inputMode={'text'}
            maxLength={MAX_NAME_LENGTH}
            autoCapitalize={'off'}
            onChange={(e) => {
              const { value } = e.target;
              setLastName(value);
              setIsLastNameValid(isNameValid(value));
            }}
          />
        </MarketInputText>
        <small slot={'error'}>{t('common.message.validation.name')}</small>
      </MarketField>

      <MarketButton
        rank={'primary'}
        /* Fun workaround to get disabled working: https://github.com/squareup/market/issues/1570 */
        {...((isProcessing ||
          firstName.length === 0 ||
          lastName.length === 0 ||
          !isFirstNameValid ||
          !isLastNameValid) && { disabled: true })}
        onClick={() => next()}
      >
        {t('common.next')}
      </MarketButton>
    </>
  );
};

export default CollectName;
