import {
  AsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { AppState } from 'store';

type UpdatePendingActionPayload = {
  actionName: string;
  isPending: boolean;
};

type RequestStatusState = {
  // used to tell if a request is currently in flight
  pending: {
    [actionName: string]: true;
  };
};

export const initialState: RequestStatusState = {
  pending: {},
};

type AsyncThunkWithTypePrefix = Pick<AsyncThunk<any, any, any>, 'typePrefix'>;

export const selectIsPending = createSelector(
  [
    (state: AppState) => state.requestStatus,
    (_: AppState, action: AsyncThunkWithTypePrefix) => action,
  ],
  (requestStatus, action) => requestStatus.pending[action.typePrefix]
);

const requestStatusSlice = createSlice({
  name: 'requestStatus',
  initialState,
  reducers: {
    setPendingState(
      state: RequestStatusState,
      action: PayloadAction<UpdatePendingActionPayload>
    ) {
      const { isPending, actionName } = action.payload;

      if (isPending) {
        state.pending[actionName] = true;
      } else {
        delete state.pending[actionName];
      }
    },
  },
});

export const { setPendingState } = requestStatusSlice.actions;

export default requestStatusSlice.reducer;
