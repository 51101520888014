// eslint-disable-next-line filenames/match-exported
import BackButton from 'components/BackButton';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import ModuleLoadFailed from 'routes/profile/common/errors/ModuleLoadFailed';
import ModuleLoading from 'routes/profile/common/loading/ModuleLoading';
import { PAGE_SIZE } from 'routes/profile/models/GiftCard';
import {
  useLazyBatchRetrieveGiftCardDetailQuery,
  useListGiftCardsQuery,
} from 'store/query';
import { openToast } from 'store/toastSlice';
import GiftCardPreview from './GiftCardPreview';
import { MarketButton, MarketHeader } from '@market/react';
import GiftCardInfo from './GiftCardInfo';
import { ModalType, openModal } from 'store/modalSlice';
import { getGiftCardLoadFundsUrl } from 'utils/GiftCardDetailHelper';
import Page from 'routes/profile/layout/Page';
import useCdpPageTracking from 'utils/custom-react-hooks/useCdpPageTracking';
import { viewGiftCardDetailsProperties } from 'services/tracking/cdp/events/paymentMethods';
import { useTranslation } from 'react-i18next';

type GiftCardDetailRouteProps = {
  giftCardToken: string;
};

const GiftCardDetails: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    data: giftCards,
    isLoading: isGiftCardsLoading,
    isError: didGiftCardsLoadingFail,
  } = useListGiftCardsQuery(PAGE_SIZE);

  const [batchRetrieveGiftCardDetail, batchRetrieveGiftCardDetailResult] =
    useLazyBatchRetrieveGiftCardDetailQuery();

  const { giftCardToken: selectedGiftCardToken } =
    useParams<GiftCardDetailRouteProps>();

  // Validates the gift card token points to a gc that is on the user's account
  const validatedSelectedGiftCard = useMemo(() => {
    const selectedGiftCard = giftCards?.find(
      (card) => card.token === selectedGiftCardToken
    );
    return selectedGiftCard;
  }, [giftCards, selectedGiftCardToken]);

  const giftCardsOnMerchant = useMemo(() => {
    return (
      giftCards?.filter(
        (card) =>
          card.merchantUnitToken ===
          validatedSelectedGiftCard?.merchantUnitToken
      ) || []
    );
  }, [giftCards, validatedSelectedGiftCard?.merchantUnitToken]);

  useEffect(() => {
    if (giftCards && validatedSelectedGiftCard) {
      // replace with forthcoming batch retrieve details endpoint
      batchRetrieveGiftCardDetail({
        giftCardTokens: giftCardsOnMerchant.map((card) => card.token),
        merchantUnitToken: validatedSelectedGiftCard.merchantUnitToken,
      });
    }
  }, [
    giftCards,
    validatedSelectedGiftCard,
    selectedGiftCardToken,
    batchRetrieveGiftCardDetail,
    giftCardsOnMerchant,
  ]);

  const isLoading =
    isGiftCardsLoading ||
    batchRetrieveGiftCardDetailResult.isUninitialized ||
    batchRetrieveGiftCardDetailResult.isLoading;

  const didLoadingFail =
    didGiftCardsLoadingFail || batchRetrieveGiftCardDetailResult.isError;

  const selectedGiftCardDetail = batchRetrieveGiftCardDetailResult.data?.find(
    (gcDetail) => gcDetail.token === selectedGiftCardToken
  );

  useCdpPageTracking({
    properties: viewGiftCardDetailsProperties(
      selectedGiftCardToken,
      validatedSelectedGiftCard
        ? validatedSelectedGiftCard.formattedBalance()
        : '',
      validatedSelectedGiftCard?.merchantInfo?.id || '',
      !selectedGiftCardDetail?.isAutoLink
    ),
    isLoading,
    didLoadingFail,
  });

  if (isLoading) {
    return <ModuleLoading />;
  }

  if (didLoadingFail) {
    return <ModuleLoadFailed />;
  }

  if (!validatedSelectedGiftCard) {
    dispatch(
      openToast({
        variant: 'info',
        message: 'This gift card is no longer on your account.',
        persistent: false,
      })
    );

    return <Redirect to={'/paymentmethods'} />;
  }

  const shouldShowTransferBalanceButton = giftCardsOnMerchant.length > 1;

  return (
    <Page type={'backdrop'}>
      <div className={'flex flex-col'}>
        <MarketHeader compact showNavigation className={'mb-[56px]'}>
          <BackButton
            slot={'navigation'}
            onClick={() => history.push('/paymentmethods')}
          />
          <h2>{t('profile.paymentMethods.giftCards.giftCardDetails.title')}</h2>
        </MarketHeader>
        <GiftCardPreview
          className={'self-center mb-8'}
          giftCardDetail={selectedGiftCardDetail!}
        />
        <div className={'flex justify-between gap-4 self-center w-[352px]'}>
          <MarketButton
            rank={'primary'}
            className={shouldShowTransferBalanceButton ? 'w-1/2' : 'w-full'}
            href={getGiftCardLoadFundsUrl(selectedGiftCardDetail!)}
            target={'_blank'}
          >
            {t('profile.paymentMethods.giftCards.giftCardDetails.loadFunds')}
          </MarketButton>
          {shouldShowTransferBalanceButton && (
            <MarketButton
              className={'w-1/2'}
              onClick={() => {
                dispatch(
                  openModal({
                    type: ModalType.TransferGiftCardBalance,
                    props: {
                      originalFromGiftCard: selectedGiftCardDetail!,
                      allGiftCardDetailsForBuyerAtMerchant:
                        batchRetrieveGiftCardDetailResult.data!,
                    },
                  })
                );
              }}
            >
              {t(
                'profile.paymentMethods.giftCards.giftCardDetails.transferBalance'
              )}
            </MarketButton>
          )}
        </div>
        <GiftCardInfo giftCardDetail={selectedGiftCardDetail!} />
        <MarketButton
          className={'w-fit'}
          variant={'destructive'}
          onClick={() => {
            dispatch(
              openModal({
                type: ModalType.ConfirmRemoveGiftCard,
                props: {
                  giftCard: validatedSelectedGiftCard,
                },
              })
            );
          }}
        >
          {t('profile.paymentMethods.giftCards.giftCardDetails.removeCard')}
        </MarketButton>
      </div>
    </Page>
  );
};

export default GiftCardDetails;
