import React from 'react';
import { GiftCardDetail } from 'routes/profile/models/GiftCard';
import DefaultMerchantCard from 'routes/profile/payment-methods/GiftCards/default-merchant-card.png';
import { useTranslation } from 'react-i18next';

type GiftCardPreviewProps = {
  className?: string;
  giftCardDetail: GiftCardDetail;
};

const GiftCardPreview: React.FC<GiftCardPreviewProps> = ({
  className,
  giftCardDetail,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={`${className} w-[220px] sm:w-[352px] h-[213px] sm:h-[266px] overflow-hidden rounded-xl shadow-previewCard`}
    >
      <img
        className={'w-full h-[106px] sm:h-[160px]'}
        alt={'Merchant'}
        src={giftCardDetail.imageUrl || DefaultMerchantCard}
      />
      <div className={'bg-white p-4'}>
        <div className={'paragraph-30'}>
          {t('profile.paymentMethods.giftCards.giftCardPreview.balance')}
        </div>
        <div className={'display-10'}>{giftCardDetail.formattedBalance()}</div>
      </div>
    </div>
  );
};

export default GiftCardPreview;
