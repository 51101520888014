import { IdentifierType } from 'routes/profile/models/Identifier';
import { EVENT_NAME_VIEW, EVENT_NAME_ACTION } from '.';

const EVENT_GROUP_PRIVACY_REQUESTS = 'Privacy Request';
const PAGE_DETAIL_EMAIL = 'Email';
const PAGE_DETAIL_PHONE = 'Phone';

const getPageDetailFromIdentifierType = (identifierType: IdentifierType) => {
  return identifierType === IdentifierType.Email
    ? PAGE_DETAIL_EMAIL
    : PAGE_DETAIL_PHONE;
};

export const privacyRequestsViewEvent = () => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_PRIVACY_REQUESTS,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
  };
};

export const privacyRequestsRequestDeleteViewEvent = () => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_PRIVACY_REQUESTS,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Request Delete',
  };
};

export const privacyRequestsRequestDeleteActionEvent = (
  identifierType: IdentifierType
) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_PRIVACY_REQUESTS,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Send Code',
    buyerportal_events_page_detail:
      getPageDetailFromIdentifierType(identifierType),
  };
};

export const privacyRequestsRequestDeleteInvalidActionEvent = (
  identifierType: IdentifierType
) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_PRIVACY_REQUESTS,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Request Delete - Invalid Identifier',
    buyerportal_events_page_detail:
      getPageDetailFromIdentifierType(identifierType),
  };
};

export const privacyRequestsVerifyDeleteViewEvent = () => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_PRIVACY_REQUESTS,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Verify Delete',
  };
};

export const privacyRequestsVerifyDeleteAccountExistsViewEvent = (
  identifierType: IdentifierType
) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_PRIVACY_REQUESTS,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Verify Delete - Existing Account',
    buyerportal_events_page_detail:
      getPageDetailFromIdentifierType(identifierType),
  };
};

export const privacyRequestsVerifyDeleteActionEvent = (
  identifierType: IdentifierType
) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_PRIVACY_REQUESTS,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Verify Delete',
    buyerportal_events_page_detail:
      getPageDetailFromIdentifierType(identifierType),
  };
};

export const privacyRequestsVerifyDeleteInvalidActionEvent = (
  identifierType: IdentifierType
) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_PRIVACY_REQUESTS,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Verify Delete - Invalid Code',
    buyerportal_events_page_detail:
      getPageDetailFromIdentifierType(identifierType),
  };
};

export const privacyRequestsSuccessDeleteViewEvent = (
  identifierType: IdentifierType
) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_PRIVACY_REQUESTS,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Delete Success',
    buyerportal_events_page_detail:
      getPageDetailFromIdentifierType(identifierType),
  };
};

export const privacyRequestsRequestExportViewEvent = () => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_PRIVACY_REQUESTS,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Request Export',
  };
};

export const privacyRequestsRequestExportActionEvent = (
  identifierType: IdentifierType
) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_PRIVACY_REQUESTS,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Request Export',
    buyerportal_events_page_detail:
      getPageDetailFromIdentifierType(identifierType),
  };
};

export const privacyRequestsRequestExportInvalidActionEvent = (
  identifierType: IdentifierType
) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_PRIVACY_REQUESTS,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Request Export - Invalid Identifier',
    buyerportal_events_page_detail:
      getPageDetailFromIdentifierType(identifierType),
  };
};

export const privacyRequestsDestinationEmailExportViewEvent = (
  identifierType: IdentifierType
) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_PRIVACY_REQUESTS,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Destination Export',
    buyerportal_events_page_detail:
      getPageDetailFromIdentifierType(identifierType),
  };
};

export const privacyRequestsDestinationEmailExportActionEvent = (
  identifierType: IdentifierType
) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_PRIVACY_REQUESTS,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Destination Export',
    buyerportal_events_page_detail:
      getPageDetailFromIdentifierType(identifierType),
  };
};

export const privacyRequestsDestinationExportInvalidActionEvent = (
  identifierType: IdentifierType
) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_PRIVACY_REQUESTS,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Destination Export - Invalid Email',
    buyerportal_events_page_detail:
      getPageDetailFromIdentifierType(identifierType),
  };
};

export const privacyRequestsVerifyExportViewEvent = (
  identifierType: IdentifierType
) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_PRIVACY_REQUESTS,
    buyerportal_events_event_name: EVENT_NAME_VIEW,
    buyerportal_events_event_value: 'Verify Export',
    buyerportal_events_page_detail:
      getPageDetailFromIdentifierType(identifierType),
  };
};

export const privacyRequestsVerifyExportActionEvent = (
  identifierType: IdentifierType
) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_PRIVACY_REQUESTS,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Verify Export',
    buyerportal_events_page_detail:
      getPageDetailFromIdentifierType(identifierType),
  };
};

export const privacyRequestsVerifyExportInvalidActionEvent = (
  identifierType: IdentifierType
) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_PRIVACY_REQUESTS,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Verify Export - Invalid Code',
    buyerportal_events_page_detail:
      getPageDetailFromIdentifierType(identifierType),
  };
};

export const privacyRequestsSuccessExportActionEvent = (
  identifierType: IdentifierType
) => {
  return {
    buyerportal_events_event_group: EVENT_GROUP_PRIVACY_REQUESTS,
    buyerportal_events_event_name: EVENT_NAME_ACTION,
    buyerportal_events_event_value: 'Success Export',
    buyerportal_events_page_detail:
      getPageDetailFromIdentifierType(identifierType),
  };
};
