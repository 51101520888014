import { Middleware, PayloadAction } from '@reduxjs/toolkit';
import { setPendingState } from 'store/requestStatusSlice';

/* Subscribes to pending, fulfilled, and rejected actions. Sets state of pending actions in root store. */
const requestStatus: Middleware =
  (api) =>
  (next) =>
  <
    A extends PayloadAction<
      any,
      string,
      { requestStatus: 'pending' | 'fulfilled' | 'rejected' }
    >
  >(
    action: A
  ) => {
    const actionName = action.type.slice(
      0,
      Math.max(0, action.type.lastIndexOf('/'))
    );

    const shouldDispatchUpdates =
      action.type && action.meta && !action.type.startsWith('requestStatus/');

    if (shouldDispatchUpdates && action.meta.requestStatus === 'pending') {
      api.dispatch(
        setPendingState({
          actionName,
          isPending: true,
        })
      );
    }

    const nextResult = next(action);

    if (!shouldDispatchUpdates) {
      return nextResult;
    }

    if (
      action.meta.requestStatus === 'fulfilled' ||
      action.meta.requestStatus === 'rejected'
    ) {
      api.dispatch(
        setPendingState({
          actionName,
          isPending: false,
        })
      );
    }

    return nextResult;
  };

export default requestStatus;
