import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteProps } from 'react-router';
import { Route } from 'react-router-dom';
import { AppState } from 'store';
import { selectBuyer, fetchBuyer } from 'store/buyerSlice';
import SqLoading from 'components/SqLoading';
import DeletedProfile from 'routes/profile/DeletedProfile';
import * as Sentry from '@sentry/react';

type AuthenticatedRouteProps = {} & RouteProps;

export const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = (
  props
) => {
  const profile = useSelector((appState: AppState) => selectBuyer(appState));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBuyer());
  }, [dispatch]);

  // set the Sentry user context if person token is available
  useEffect(() => {
    if (profile.personToken && !profile.isInactive) {
      Sentry.setUser({
        id: profile.personToken,
      });
    }
  }, [profile.isInactive, profile.personToken]);

  if (!profile.isLoaded) {
    return (
      <div
        className={
          'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
        }
      >
        <SqLoading />
      </div>
    );
  }

  if (profile.isInactive) {
    return <DeletedProfile />;
  }

  return <Route {...props} />;
};

export default AuthenticatedRoute;
