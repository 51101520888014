import React, { useContext, useEffect, useMemo } from 'react';
import ContentContainer from './layout-components/ContentContainer';
import AboutUs from './layout-components/AboutUs';
import { ErrorBoundary } from 'react-error-boundary';
import { MarketDivider } from '@market/react';
import { MerchantPortalContext } from '.';
import LoyaltyOverviewTile from './integrations/loyalty/overview/LoyaltyOverviewTile';
import { logUnexpectedError } from 'routes/merchant-scoped-portal/utils/error';
import { Team } from './teamRegistry';
import { useRetrieveProgramInfoQuery } from 'store/query/api-extensions/loyalty';
import { useSelector } from 'react-redux';
import { selectIsLoyaltyProgramActive } from './integrations/loyalty/loyaltySlice';
import { AppState } from 'store';
import {
  FeatureName,
  ViewFeatureEvent,
  FeatureID,
  FeatureFormat,
  EventName,
} from 'services/tracking/cdp/events/types';
import { buyerportalCdpClient } from 'services/tracking/cdp/clients/buyerportal';

// ...other tiles
const MerchantOverviewPage: React.FC = () => {
  const { merchantPortalBaseData } = useContext(MerchantPortalContext);
  const { merchantOverview } = merchantPortalBaseData ?? {};
  const { businessDescription, merchantId, websiteUrl } =
    merchantOverview ?? {};
  const programInfoResult = useRetrieveProgramInfoQuery(merchantId ?? '', {
    skip: !merchantId,
  });
  const isLoyaltyProgramActive = useSelector((state: AppState) =>
    selectIsLoyaltyProgramActive(state)
  );
  const maybeLoyaltyTile = useMemo(() => {
    // Don’t show loyalty overview tile while loading; the program may not exist, and showing the a spinner
    // would result in a flicker
    if (programInfoResult.isLoading || programInfoResult.isUninitialized) {
      return null;
    }

    // The program does not exist
    if (!programInfoResult.data?.loyaltyProgram || !isLoyaltyProgramActive) {
      return null;
    }

    return (
      <ErrorBoundary
        key={Team.CeWeb}
        /* Since this just catches entirely unexpected errors, there's no real fallback to present. */
        fallback={null}
        onError={(error) => logUnexpectedError(error, [Team.CeWeb])}
      >
        <MarketDivider margin="large" />
        <LoyaltyOverviewTile program={programInfoResult.data?.loyaltyProgram} />
      </ErrorBoundary>
    );
  }, [
    isLoyaltyProgramActive,
    programInfoResult.data,
    programInfoResult.isLoading,
    programInfoResult.isUninitialized,
  ]);

  // Track the view event of the overview page
  useEffect(() => {
    if (!merchantPortalBaseData) {
      return;
    }
    const { buyer, merchantOverview } = merchantPortalBaseData;
    const viewMerchantPortalOverviewEvent: ViewFeatureEvent = {
      feature_name: FeatureName.MerchantOverviewPage,
      feature_id: FeatureID.MerchantOverviewPage,
      feature_format: FeatureFormat.PAGE,
      event_description: 'view Profile seller page',
      is_default_view: true,
      feature_layout: {
        has_about_us: Boolean(merchantOverview?.businessDescription),
        has_logo: Boolean(
          merchantOverview?.logoImage || merchantOverview?.fullFormatLogo
        ),
        has_banner: false,
        has_site: Boolean(merchantOverview?.websiteUrl),
        // TODO: If user is auth'd, loyalty will need to push up data to the merchant portal context
        // after the loyalty tile is loaded
        // loyalty_count: num associated loyalty accounts
      },
      additional_parameters: {
        merchant_id: merchantOverview.merchantId,
        merchant_name: merchantOverview.merchantName,
        buyer_authenticated: Boolean(buyer),
      },
    };

    buyerportalCdpClient.track(
      EventName.VIEW_FEATURE,
      viewMerchantPortalOverviewEvent
    );
  }, [merchantPortalBaseData]);

  return (
    <>
      {/* This has the styles to center and add padding */}
      <ContentContainer>
        <AboutUs
          businessDescription={businessDescription}
          websiteUrl={websiteUrl}
        />
        {maybeLoyaltyTile}
      </ContentContainer>
    </>
  );
};

export default MerchantOverviewPage;
