/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../Fixes';
import Builder from '../../../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      connect: {
        nested: {
          v2: {
            nested: {
              common: {
                nested: {
                  Product: {
                    values: {
                      PRODUCT_DO_NOT_USE: -1,
                      SQUARE_POS: 0,
                      EXTERNAL_API: 1,
                      BILLING: 2,
                      APPOINTMENTS: 3,
                      INVOICES: 4,
                      ONLINE_STORE: 5,
                      PAYROLL: 6,
                      DASHBOARD: 8,
                      ITEM_LIBRARY_IMPORT: 9,
                      RETAIL: 10,
                      RESTAURANT: 11,
                      CUSTOMERS_DIRECTORY: 12,
                      CHANNELS: 13,
                      OTHER: 7,
                    },
                  },
                  SourceApplication: {
                    fields: {
                      product: {
                        type: 'squareup.connect.v2.common.Product',
                        id: 1,
                        rule: 'optional',
                      },
                      applicationId: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                      name: {
                        type: 'string',
                        id: 3,
                        rule: 'optional',
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.common?.nested
    ?.Product &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.common?.nested
    ?.SourceApplication
) {
  $root.addJSON(jsonData);
}

/**
 * --
 * @desc Indicates the Square product used to generate a change.
 * --
 *
 * SOURCE Product @ squareup/connect/v2/common/source_application.proto at 16:1
 */
export const Product = $root.lookupEnum(
  'squareup.connect.v2.common.Product'
).values;
/**
 * --
 * @desc Represents information about the application used to generate a change.
 * --
 *
 * SOURCE SourceApplication @ squareup/connect/v2/common/source_application.proto at 56:1
 */
export const SourceApplication = $root.lookupType(
  'squareup.connect.v2.common.SourceApplication'
);
fixType(SourceApplication);
Builder.createAndAttachToType(SourceApplication);
