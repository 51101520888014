import React from 'react';

export type ContentContainerProps = {
  children: React.ReactNode;
};

// Note: We may be able to move this wrapper div into MerchantPortal and
// have it apply to both the Overview and Resource pages.
// Will have to wait until we have a better understanding of usage.
const ContentContainer: React.FC<ContentContainerProps> = ({ children }) => {
  return (
    <div className="box-border mx-auto px-6 md:px-0 py-9 max-w-desktopContent">
      {children}
    </div>
  );
};

export default ContentContainer;
