/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import '../../customers/request';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      buyerportal: {
        nested: {
          accountlessprivacy: {
            nested: {
              AccountlessPrivacyRequestType: {
                values: {
                  UNKNOWN: 0,
                  DELETION: 1,
                  EXPORT: 2,
                },
              },
              VerificationCredential: {
                fields: {
                  phoneNumber: {
                    type: 'string',
                    id: 1,
                  },
                  email: {
                    type: 'string',
                    id: 2,
                  },
                },
                nested: {
                  credential: {
                    oneof: ['phoneNumber', 'email'],
                  },
                },
              },
              CreateAccountlessPrivacyRequestRequest: {
                fields: {
                  verificationCredential: {
                    type: 'squareup.buyerportal.accountlessprivacy.VerificationCredential',
                    id: 1,
                    rule: 'optional',
                  },
                  requestType: {
                    type: 'squareup.buyerportal.accountlessprivacy.AccountlessPrivacyRequestType',
                    id: 2,
                    rule: 'optional',
                  },
                  idempotencyKey: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                },
              },
              CreateAccountlessPrivacyRequestResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                  accountExists: {
                    type: 'bool',
                    id: 3,
                    rule: 'optional',
                  },
                  accountPendingDeletion: {
                    type: 'bool',
                    id: 4,
                    rule: 'optional',
                  },
                },
              },
              ExecuteAccountlessPrivacyRequestRequest: {
                fields: {
                  verificationCredential: {
                    type: 'squareup.buyerportal.accountlessprivacy.VerificationCredential',
                    id: 1,
                    rule: 'optional',
                  },
                  requestType: {
                    type: 'squareup.buyerportal.accountlessprivacy.AccountlessPrivacyRequestType',
                    id: 2,
                    rule: 'optional',
                  },
                  destinationEmail: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  verificationCode: {
                    type: 'string',
                    id: 4,
                    rule: 'optional',
                  },
                  idempotencyKey: {
                    type: 'string',
                    id: 5,
                    rule: 'optional',
                  },
                },
              },
              ExecuteAccountlessPrivacyRequestResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.accountlessprivacy
    ?.nested?.AccountlessPrivacyRequestType &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.accountlessprivacy
    ?.nested?.VerificationCredential &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.accountlessprivacy
    ?.nested?.CreateAccountlessPrivacyRequestRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.accountlessprivacy
    ?.nested?.CreateAccountlessPrivacyRequestResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.accountlessprivacy
    ?.nested?.ExecuteAccountlessPrivacyRequestRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.accountlessprivacy
    ?.nested?.ExecuteAccountlessPrivacyRequestResponse
) {
  $root.addJSON(jsonData);
}

/**
 * @desc The supported accountless privacy flows.
 *
 * SOURCE AccountlessPrivacyRequestType @ squareup/buyerportal/accountlessprivacy/data.proto at 15:1
 */
export const AccountlessPrivacyRequestType = $root.lookupEnum(
  'squareup.buyerportal.accountlessprivacy.AccountlessPrivacyRequestType'
).values;
/**
 * SOURCE VerificationCredential @ squareup/buyerportal/accountlessprivacy/data.proto at 22:1
 */
export const VerificationCredential = $root.lookupType(
  'squareup.buyerportal.accountlessprivacy.VerificationCredential'
);
fixType(VerificationCredential);
Builder.createAndAttachToType(VerificationCredential);
/**
 * SOURCE CreateAccountlessPrivacyRequestRequest @ squareup/buyerportal/accountlessprivacy/data.proto at 29:1
 */
export const CreateAccountlessPrivacyRequestRequest = $root.lookupType(
  'squareup.buyerportal.accountlessprivacy.CreateAccountlessPrivacyRequestRequest'
);
fixType(CreateAccountlessPrivacyRequestRequest, {
  verificationCredential: { required: true },
  requestType: { required: true },
  idempotencyKey: { required: true, notEmpty: true },
});
Builder.createAndAttachToType(CreateAccountlessPrivacyRequestRequest);
/**
 * SOURCE CreateAccountlessPrivacyRequestResponse @ squareup/buyerportal/accountlessprivacy/data.proto at 43:1
 */
export const CreateAccountlessPrivacyRequestResponse = $root.lookupType(
  'squareup.buyerportal.accountlessprivacy.CreateAccountlessPrivacyRequestResponse'
);
fixType(CreateAccountlessPrivacyRequestResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(CreateAccountlessPrivacyRequestResponse);
/**
 * SOURCE ExecuteAccountlessPrivacyRequestRequest @ squareup/buyerportal/accountlessprivacy/data.proto at 57:1
 */
export const ExecuteAccountlessPrivacyRequestRequest = $root.lookupType(
  'squareup.buyerportal.accountlessprivacy.ExecuteAccountlessPrivacyRequestRequest'
);
fixType(ExecuteAccountlessPrivacyRequestRequest, {
  verificationCredential: { required: true },
  requestType: { required: true },
  verificationCode: { required: true },
  idempotencyKey: { required: true, notEmpty: true },
});
Builder.createAndAttachToType(ExecuteAccountlessPrivacyRequestRequest);
/**
 * SOURCE ExecuteAccountlessPrivacyRequestResponse @ squareup/buyerportal/accountlessprivacy/data.proto at 76:1
 */
export const ExecuteAccountlessPrivacyRequestResponse = $root.lookupType(
  'squareup.buyerportal.accountlessprivacy.ExecuteAccountlessPrivacyRequestResponse'
);
fixType(ExecuteAccountlessPrivacyRequestResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(ExecuteAccountlessPrivacyRequestResponse);
