import React from 'react';
import { useDispatch } from 'react-redux';
import { LogoIcon } from 'svgs';
import { ModalType, openModal } from 'store/modalSlice';
import { logoutUrl } from 'utils/environment';
import Hamburger from 'svgs/market-icons/Hamburger';
import { useTranslation } from 'react-i18next';
import nonTranslatableStrings from 'utils/nonTranslatableStrings';

const Header: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const mobileStickyClasses = 'sticky top-0 bg-white z-[2]';
  const flexClasses = 'flex justify-between items-center';
  const paddingClasses = 'pl-3 pr-6 py-1';

  return (
    <header
      className={`${mobileStickyClasses} md:static shadow-bottomDivider ${flexClasses} ${paddingClasses}`}
    >
      <div className={'flex items-center'}>
        <LogoIcon className={'p-3'} height={20} width={20} />
        <h1 className={'text-[19px]'}>
          {nonTranslatableStrings.squareProfile}
        </h1>
      </div>
      <a
        className={'hidden md:block px-2 semibold-20 text-black no-underline'}
        href={logoutUrl()}
      >
        {t('sidenav.signOut')}
      </a>
      <div className="ml-auto md:hidden">
        <span
          className="p-3 cursor-pointer"
          onClick={() => {
            dispatch(
              openModal({
                type: ModalType.MobileNav,
              })
            );
          }}
        >
          <Hamburger />
        </span>
      </div>
    </header>
  );
};

export default Header;
