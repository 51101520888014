/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import '../../common/location';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      buyerportal: {
        nested: {
          buyer: {
            nested: {
              Address: {
                fields: {
                  addressToken: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  addressData: {
                    type: 'squareup.common.location.GlobalAddress',
                    id: 2,
                    rule: 'optional',
                  },
                },
              },
              Name: {
                fields: {
                  first: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  last: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                },
              },
              Phone: {
                fields: {
                  id: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  displayValue: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                },
              },
              Email: {
                fields: {
                  id: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  displayValue: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                },
              },
              PreferenceCard: {
                fields: {
                  id: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  displayValue: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                },
              },
              Buyer: {
                fields: {
                  personToken: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  name: {
                    type: 'squareup.buyerportal.buyer.Name',
                    id: 2,
                    rule: 'optional',
                  },
                  emails: {
                    type: 'squareup.buyerportal.buyer.Email',
                    id: 3,
                    rule: 'repeated',
                  },
                  primaryEmailId: {
                    type: 'string',
                    id: 4,
                    rule: 'optional',
                  },
                  phones: {
                    type: 'squareup.buyerportal.buyer.Phone',
                    id: 5,
                    rule: 'repeated',
                  },
                  primaryPhoneId: {
                    type: 'string',
                    id: 6,
                    rule: 'optional',
                  },
                  addresses: {
                    type: 'squareup.buyerportal.buyer.Address',
                    id: 7,
                    rule: 'repeated',
                  },
                  primaryAddressId: {
                    type: 'string',
                    id: 8,
                    rule: 'optional',
                  },
                  primaryPaymentCardId: {
                    type: 'string',
                    id: 9,
                    rule: 'optional',
                  },
                  preferenceCards: {
                    type: 'squareup.buyerportal.buyer.PreferenceCard',
                    id: 10,
                    rule: 'repeated',
                  },
                  unverifiedEmail: {
                    type: 'squareup.buyerportal.buyer.Email',
                    id: 11,
                    rule: 'optional',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.buyer?.nested
    ?.Address &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.buyer?.nested?.Name &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.buyer?.nested?.Phone &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.buyer?.nested?.Email &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.buyer?.nested
    ?.PreferenceCard &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.buyer?.nested?.Buyer
) {
  $root.addJSON(jsonData);
}

/**
 * An address (mailing/postal) associated with a profile.
 *
 * SOURCE Address @ squareup/buyerportal/buyer/data.proto at 14:1
 */
export const Address = $root.lookupType('squareup.buyerportal.buyer.Address');
fixType(Address);
Builder.createAndAttachToType(Address);
/**
 * Buyer Name
 *
 * SOURCE Name @ squareup/buyerportal/buyer/data.proto at 25:1
 */
export const Name = $root.lookupType('squareup.buyerportal.buyer.Name');
fixType(Name);
Builder.createAndAttachToType(Name);
/**
 * Phone identifier associated with a Buyer
 *
 * SOURCE Phone @ squareup/buyerportal/buyer/data.proto at 36:1
 */
export const Phone = $root.lookupType('squareup.buyerportal.buyer.Phone');
fixType(Phone);
Builder.createAndAttachToType(Phone);
/**
 * Email identifier associated with a Buyer
 *
 * SOURCE Email @ squareup/buyerportal/buyer/data.proto at 47:1
 */
export const Email = $root.lookupType('squareup.buyerportal.buyer.Email');
fixType(Email);
Builder.createAndAttachToType(Email);
/**
 * Preference card identifier associated with a Buyer
 *
 * SOURCE PreferenceCard @ squareup/buyerportal/buyer/data.proto at 58:1
 */
export const PreferenceCard = $root.lookupType(
  'squareup.buyerportal.buyer.PreferenceCard'
);
fixType(PreferenceCard);
Builder.createAndAttachToType(PreferenceCard);
/**
 * A Buyer consists of all fields related to a buyer profile (phones, emails, attributes,
 * status, etc.)
 *
 * SOURCE Buyer @ squareup/buyerportal/buyer/data.proto at 70:1
 */
export const Buyer = $root.lookupType('squareup.buyerportal.buyer.Buyer');
fixType(Buyer);
Builder.createAndAttachToType(Buyer);
