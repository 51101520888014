import React from 'react';

const VisaMulticolorIcon: React.FC = () => {
  return (
    <svg
      width="36"
      height="24"
      viewBox="0 0 36 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H32C34.2091 0 36 1.79086 36 4V20C36 22.2091 34.2091 24 32 24H4C1.79086 24 0 22.2091 0 20V4Z"
        fill="white"
      />
      <path
        d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H32C33.933 0.5 35.5 2.067 35.5 4V20C35.5 21.933 33.933 23.5 32 23.5H4C2.067 23.5 0.5 21.933 0.5 20V4Z"
        stroke="black"
        strokeOpacity="0.15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9544 10.0711C20.949 10.4797 21.4526 10.7488 22.275 11.1521C23.6322 11.7744 24.259 12.5292 24.25 13.5212C24.2319 15.331 22.6265 16.5 20.1535 16.5C19.0985 16.489 18.0825 16.278 17.533 16.0347L17.8631 14.0829L18.1662 14.2215C18.9388 14.547 19.4391 14.6791 20.3807 14.6791C21.0569 14.6791 21.7824 14.4122 21.7882 13.8278C21.7925 13.446 21.4847 13.1736 20.569 12.7462C19.6765 12.3291 18.4946 11.6301 18.5076 10.3773C18.5215 8.68252 20.159 7.5 22.4836 7.5C23.3956 7.5 24.1259 7.68957 24.5921 7.86611L24.2732 9.75597L24.0624 9.65526C23.6282 9.47855 23.0709 9.30919 22.3016 9.32121C21.3807 9.32121 20.9544 9.7087 20.9544 10.0711ZM28.661 10.8424C28.661 10.8424 27.9183 12.7774 27.7237 13.2798H29.6767C29.5824 12.8434 29.1321 10.7621 29.1321 10.7621L28.973 10.0103C28.9108 10.1816 28.8245 10.4078 28.7583 10.5812C28.698 10.7393 28.6544 10.8535 28.661 10.8424ZM28.8068 7.65999H30.6247L32.5287 16.3734H30.3458C30.3458 16.3734 30.13 15.3721 30.0596 15.0669C29.8783 15.0669 29.1229 15.0659 28.4108 15.0649L28.407 15.0649L28.4045 15.0649L28.4003 15.0649C27.7685 15.0641 27.1733 15.0633 27.0467 15.0633C26.9554 15.2991 26.5513 16.3734 26.5513 16.3734H24.0811L27.5749 8.38303C27.8223 7.81515 28.2437 7.65999 28.8068 7.65999ZM11.7349 7.65778L9.43161 13.6036L9.18625 12.3952L8.36139 8.3885C8.21887 7.83633 7.80586 7.67164 7.29498 7.65244H3.50153L3.47144 7.83382C4.39417 8.05947 5.21936 8.385 5.94254 8.79019L8.03403 16.363L10.5232 16.3601L14.2268 7.65778H11.7349ZM13.708 16.3707L15.1788 7.65147H17.5314L16.0596 16.3707H13.708Z"
        fill="#1434CB"
      />
    </svg>
  );
};

export default VisaMulticolorIcon;
