/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      client: {
        nested: {
          orders: {
            nested: {
              OrderDiscount: {
                fields: {
                  discounts: {
                    type: 'squareup.client.orders.DiscountCodeData',
                    id: 1,
                    rule: 'repeated',
                  },
                },
              },
              DiscountCodeData: {
                fields: {
                  id: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  code: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.client?.nested?.orders?.nested
    ?.OrderDiscount &&
  !$root.nested?.squareup?.nested?.client?.nested?.orders?.nested
    ?.DiscountCodeData
) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE OrderDiscount @ squareup/client/orders/order-discount.proto at 7:1
 */
export const OrderDiscount = $root.lookupType(
  'squareup.client.orders.OrderDiscount'
);
fixType(OrderDiscount);
Builder.createAndAttachToType(OrderDiscount);
/**
 * SOURCE DiscountCodeData @ squareup/client/orders/order-discount.proto at 11:1
 */
export const DiscountCodeData = $root.lookupType(
  'squareup.client.orders.DiscountCodeData'
);
fixType(DiscountCodeData);
Builder.createAndAttachToType(DiscountCodeData);
