import { MarketButton } from '@market/react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { buyerportalCdpClient } from 'services/tracking/cdp/clients/buyerportal';
import { startDeleteAccountProperties } from 'services/tracking/cdp/events/profile';
import { ModalType, openModal } from 'store/modalSlice';
import { useTranslation } from 'react-i18next';

const DeleteAccount: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const deleteAccount = () => {
    buyerportalCdpClient.track(
      'Start Delete Account',
      startDeleteAccountProperties()
    );

    dispatch(
      openModal({
        type: ModalType.ConfirmDeleteAccount,
      })
    );
  };

  return (
    <>
      <h3 className={'heading-20 my-0'}>
        {t('profile.account.deleteAccount.title')}
      </h3>
      <p className={'paragraph-30 mt-3 mb-4 text-grey'}>
        {t('profile.account.deleteAccount.subtitle')}
      </p>
      <MarketButton variant={'destructive'} onClick={deleteAccount}>
        {t('common.delete')}
      </MarketButton>
    </>
  );
};

export default DeleteAccount;
