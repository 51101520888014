// eslint-disable-next-line filenames/match-exported
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import LoadingVerification from './loading';
import Footer from 'components/SqPrivacyFooter';
import VerifyEmailThroughEmailTokenSuccess from './success';
import VerifyEmailThroughEmailTokenFailure from './invalid';
import { useVerifyEmailByTokenMutation } from 'store/query';

type UnauthenticatedVerifyEmailRouteProps = {
  verifyEmailToken: string;
};

const UnauthenticatedVerifyEmail: React.FC = () => {
  const { verifyEmailToken } =
    useParams<UnauthenticatedVerifyEmailRouteProps>();
  const [verifyEmailByToken, verifyEmailByTokenResult] =
    useVerifyEmailByTokenMutation();

  useEffect(() => {
    verifyEmailByToken(verifyEmailToken);
  }, [verifyEmailToken, verifyEmailByToken]);

  const getPage = () => {
    if (
      verifyEmailByTokenResult.isUninitialized ||
      verifyEmailByTokenResult.isLoading
    ) {
      return <LoadingVerification />;
    } else if (verifyEmailByTokenResult.isSuccess) {
      return (
        <VerifyEmailThroughEmailTokenSuccess
          obfuscatedEmail={verifyEmailByTokenResult.data!}
        />
      );
    } else {
      return <VerifyEmailThroughEmailTokenFailure />;
    }
  };

  return (
    <div className={'flex flex-col h-screen w-screen place-items-center'}>
      {getPage()}
      <Footer />
    </div>
  );
};

export default UnauthenticatedVerifyEmail;
