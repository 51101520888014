import { MarketButton } from '@market/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LogoIcon } from 'svgs';
import { logoutUrl } from 'utils/environment';

const DeletedProfile: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={'flex items-center justify-center sm:py-12'}>
      <div className={'w-[440px] p-8'}>
        <LogoIcon fontSize={'large'} />
        <h2 className={'heading-30'}>{t('deletedProfile.title')}</h2>
        <p className={'mt-4 mb-6'}>{t('deletedProfile.subtitle')}</p>
        <MarketButton className={'w-full'} rank={'primary'} href={logoutUrl()}>
          {t('deletedProfile.signIn')}
        </MarketButton>
      </div>
    </div>
  );
};

export default DeletedProfile;
