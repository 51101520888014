import { Factory } from 'rosie';
import { Identifier, IdentifierType } from 'routes/profile/models/Identifier';
import { v4 as uuidv4 } from 'uuid';

export default Factory.define<Identifier>('identifier', Identifier)
  .sequence('token')
  .attr('identifierType', IdentifierType.Email)
  .attr('displayValue', ['identifierType'], function (identifierType) {
    const part = uuidv4().slice(0, 8);

    switch (identifierType) {
      case IdentifierType.Email: {
        return `Email - ${part}`;
      }
      case IdentifierType.Phone: {
        return `Phone - ${part}`;
      }
      case IdentifierType.Card: {
        return `Card - ${part}`;
      }
      default: {
        return `Unknown - ${part}`;
      }
    }
  });
