import React, { useState } from 'react';
import Request from './Request';
import BaseModal from 'components/modals/BaseModal';
import Verify from './Verify';
import { ContactInformationIdentifierType } from 'routes/profile/models/Identifier';

enum Step {
  Request,
  Verify,
}

const AddContactInfoModal: React.FC = () => {
  const [step, setStep] = useState<Step>(Step.Request);
  const [identifierType, setIdentifierType] =
    useState<ContactInformationIdentifierType | null>(null);
  const [identifierValue, setIdentifierValue] = useState<string | null>(null);

  const renderStep = () => {
    switch (step) {
      case Step.Request: {
        return (
          <Request
            onRequestAdd={(
              identifierType: ContactInformationIdentifierType,
              identifierValue: string
            ) => {
              setIdentifierType(identifierType);
              setIdentifierValue(identifierValue);
              setStep(Step.Verify);
            }}
          />
        );
      }
      case Step.Verify: {
        return (
          <Verify
            identifierType={identifierType!}
            identifierValue={identifierValue!}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  return <BaseModal>{renderStep()}</BaseModal>;
};

export default AddContactInfoModal;
