import React from 'react';
import BaseModal from 'components/modals/BaseModal';
import { MarketButton, MarketButtonGroup, MarketFooter } from '@market/react';
import { AuthorizedApplication } from 'routes/profile/models/AuthorizedApplication';
import { useDispatch } from 'react-redux';
import { closeModal } from 'store/modalSlice';
import { useUnlinkAuthorizedApplicationMutation } from 'store/query';
import { openToast } from 'store/toastSlice';
import { buyerportalCdpClient } from 'services/tracking/cdp/clients/buyerportal';
import { confirmDisconnectCashAppProperties } from 'services/tracking/cdp/events/profile';
import { useTranslation } from 'react-i18next';

export type ConfirmDeleteProps = {
  appMetadata: AuthorizedApplication;
};

const ConfirmDeleteModal: React.FC<ConfirmDeleteProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [unlinkAuthorizedApplication, unlinkAuthorizedApplicationResult] =
    useUnlinkAuthorizedApplicationMutation();

  const unlink = async () => {
    await unlinkAuthorizedApplication(props.appMetadata.id)
      .unwrap()
      .then(() => {
        buyerportalCdpClient.track(
          'Confirm Disconnect App',
          confirmDisconnectCashAppProperties()
        );

        dispatch(closeModal());
        dispatch(
          openToast({
            variant: 'success',
            message: t('common.changeSuccess'),
          })
        );
      })
      .catch(() => {
        dispatch(
          openToast({
            variant: 'critical',
            message: t('common.somethingWentWrong.retryable.direct'),
          })
        );
      });
  };

  return (
    <BaseModal>
      <h2>
        {t('manageAuthorizedApplicationModal.title', {
          displayAppName: props.appMetadata.displayAppName,
        })}
      </h2>
      <p>{props.appMetadata.removeConfirmDescription}</p>
      <MarketFooter>
        <MarketButtonGroup alignment={'fill'}>
          <MarketButton
            rank={'secondary'}
            onClick={() => dispatch(closeModal())}
            disabled={unlinkAuthorizedApplicationResult.isLoading}
          >
            {t('common.cancel')}
          </MarketButton>
          <MarketButton
            rank={'primary'}
            variant={'destructive'}
            onClick={unlink}
            data-testid={'confirm-delete-app'}
            disabled={unlinkAuthorizedApplicationResult.isLoading}
          >
            {t('common.disconnect')}
          </MarketButton>
        </MarketButtonGroup>
      </MarketFooter>
    </BaseModal>
  );
};

export default ConfirmDeleteModal;
