import { Dispatch, SetStateAction, useState } from 'react';

/**
 * This is a custom React hook that keeps track of lazy validity state (valid or unvalidated, and invalid).
 *
 * When initializing this hook, isInvalid will be false until validateFn is called. If invalid, you can reset
 * the validation state with resetValidation.
 *
 * Returns [isInvalid: boolean, resetValidation: () => void, validateFn: (input: any) => boolean]
 *
 * Example usage
 * ---
 * Imagine a number input component where a number greater than 3 is considered invalid.
 *
 * const [ number, setNumber ] = useState(0);
 * const {isInvalidNumber, resetNumberValidation, validateNumber} = useValidation(num => num <= 3);
 *
 * ....
 *
 * <input type="number" value={number} onChange={(e) => {
 *    setNumber(e.target.value);
 *    resetNumberValidation(); // this will make isInvalidNumber false again
 * }} />
 * <button onClick={() => validateNumber(number)}>Validate</button>
 * <div>Is invalid: {isInvalidNumber}</div>
 *
 * @param validationFn A function that should return a boolean. This is called when validate is called and determines the validity of an input.

 */
const useValidation = (
  validationFn: (...input: any[]) => boolean
): [boolean, Dispatch<SetStateAction<void>>, (...input: any[]) => boolean] => {
  const [isInvalid, setIsInvalid] = useState<boolean>(false);

  const resetValidation = () => setIsInvalid(false);

  const validate = (...input: any[]): boolean => {
    if (!input) {
      throw new Error(
        'Check usage of useValidation hook. No arg was passed to validate'
      );
    }

    const isValid = validationFn(...input);
    setIsInvalid(!isValid);

    return isValid;
  };

  return [isInvalid, resetValidation, validate];
};

export default useValidation;
