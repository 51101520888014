/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../Fixes';
import Builder from '../../../../Builder';
import './country';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      connect: {
        nested: {
          v2: {
            nested: {
              resources: {
                nested: {
                  Address: {
                    fields: {
                      addressLine1: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      addressLine2: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                      addressLine3: {
                        type: 'string',
                        id: 3,
                        rule: 'optional',
                      },
                      locality: {
                        type: 'string',
                        id: 6,
                        rule: 'optional',
                      },
                      sublocality: {
                        type: 'string',
                        id: 7,
                        rule: 'optional',
                      },
                      sublocality2: {
                        type: 'string',
                        id: 8,
                        rule: 'optional',
                      },
                      sublocality3: {
                        type: 'string',
                        id: 9,
                        rule: 'optional',
                      },
                      administrativeDistrictLevel1: {
                        type: 'string',
                        id: 13,
                        rule: 'optional',
                      },
                      administrativeDistrictLevel2: {
                        type: 'string',
                        id: 14,
                        rule: 'optional',
                      },
                      administrativeDistrictLevel3: {
                        type: 'string',
                        id: 15,
                        rule: 'optional',
                      },
                      postalCode: {
                        type: 'string',
                        id: 16,
                        rule: 'optional',
                      },
                      country: {
                        type: 'squareup.connect.v2.resources.Country',
                        id: 17,
                        rule: 'optional',
                      },
                      firstName: {
                        type: 'string',
                        id: 18,
                        rule: 'optional',
                      },
                      lastName: {
                        type: 'string',
                        id: 19,
                        rule: 'optional',
                      },
                      organization: {
                        type: 'string',
                        id: 20,
                        rule: 'optional',
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources
    ?.nested?.Address
) {
  $root.addJSON(jsonData);
}

/**
 * --
 * @desc Represents a postal address in a country.
 * For more information, see [Working with Addresses](https://developer.squareup.com/docs/build-basics/working-with-addresses).
 * --
 *
 * SOURCE Address @ squareup/connect/v2/resources/address.proto at 19:1
 */
export const Address = $root.lookupType(
  'squareup.connect.v2.resources.Address'
);
fixType(Address);
Builder.createAndAttachToType(Address);
