import React from 'react';
import { useTranslation } from 'react-i18next';

// https://developer.apple.com/wallet/add-to-apple-wallet-guidelines/
import LogoCaES from 'images/add-to-apple-wallet/CAES_Add_to_Apple_Wallet_RGB_101421.svg';
import LogoEs from 'images/add-to-apple-wallet/ES_Add_to_Apple_Wallet_RGB_101921.svg';
import LogoEsMx from 'images/add-to-apple-wallet/ESMX_Add_to_Apple_Wallet_RGB_101821.svg';
import LogoFr from 'images/add-to-apple-wallet/FR_Add_to_Apple_Wallet_RGB_102921.svg';
import LogoJa from 'images/add-to-apple-wallet/JP_Add_to_Apple_Wallet_RGB_101821.svg';
import LogoEnGb from 'images/add-to-apple-wallet/US-UK_Add_to_Apple_Wallet_RGB_101421.svg';

interface AddToAppleWalletButtonProps {
  downloadUrl: string;
}

function svgFromLocale(locale: string) {
  switch (locale.toLowerCase()) {
    case 'ca':
    case 'ca-es': {
      return LogoCaES;
    }
    case 'es':
    case 'es-es': {
      return LogoEs;
    }
    case 'es-us': {
      return LogoEsMx;
    }
    case 'fr':
    case 'fr-fr':
    case 'fr-ca': {
      return LogoFr;
    }
    case 'ja':
    case 'ja-jp': {
      return LogoJa;
    }
    case 'en-au':
    case 'en-ca':
    case 'en-gb':
    case 'en-us': {
      return LogoEnGb;
    }
    default: {
      return LogoEnGb;
    }
  }
}

const AddToAppleWalletButton: React.FC<AddToAppleWalletButtonProps> = ({
  downloadUrl,
}) => {
  const { i18n, t } = useTranslation();
  const svg = svgFromLocale(i18n.language);

  return (
    <a
      className="inline-block h-[56px] hover:cursor-pointer"
      href={downloadUrl}
    >
      <img className="h-full" src={svg} alt={t('apple.addToAppleWallet')} />
    </a>
  );
};

export default AddToAppleWalletButton;
