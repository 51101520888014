/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import '../../customers/request';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      buyerportal: {
        nested: {
          authorizedapps: {
            nested: {
              ListAuthorizedApplicationsRequest: {
                fields: {},
              },
              ListAuthorizedApplicationsResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                  applications: {
                    type: 'squareup.buyerportal.authorizedapps.AuthorizedApplication',
                    id: 3,
                    rule: 'repeated',
                  },
                },
              },
              UnlinkAuthorizedApplicationRequest: {
                fields: {
                  name: {
                    type: 'squareup.buyerportal.authorizedapps.AuthorizedApplicationName',
                    id: 1,
                    rule: 'optional',
                  },
                },
              },
              UnlinkAuthorizedApplicationResponse: {
                fields: {
                  status: {
                    type: 'squareup.customers.request.RequestStatus',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.customers.request.RequestError',
                    id: 2,
                    rule: 'repeated',
                  },
                },
              },
              AuthorizedApplication: {
                fields: {
                  name: {
                    type: 'squareup.buyerportal.authorizedapps.AuthorizedApplicationName',
                    id: 1,
                    rule: 'optional',
                  },
                  displayValue: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                },
              },
              AuthorizedApplicationName: {
                values: {
                  DO_NOT_USE: 0,
                  CASH_APP: 1,
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.authorizedapps?.nested
    ?.ListAuthorizedApplicationsRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.authorizedapps?.nested
    ?.ListAuthorizedApplicationsResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.authorizedapps?.nested
    ?.UnlinkAuthorizedApplicationRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.authorizedapps?.nested
    ?.UnlinkAuthorizedApplicationResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.authorizedapps?.nested
    ?.AuthorizedApplication &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.authorizedapps?.nested
    ?.AuthorizedApplicationName
) {
  $root.addJSON(jsonData);
}

/**
 * --
 * @desc Represents the request to list all authorized services for the buyer account.
 * --
 *
 * SOURCE ListAuthorizedApplicationsRequest @ squareup/buyerportal/authorizedapps/data.proto at 14:1
 */
export const ListAuthorizedApplicationsRequest = $root.lookupType(
  'squareup.buyerportal.authorizedapps.ListAuthorizedApplicationsRequest'
);
fixType(ListAuthorizedApplicationsRequest);
Builder.createAndAttachToType(ListAuthorizedApplicationsRequest);
/**
 * --
 * @desc Represents the response to list all authorized services for the buyer account.
 * --
 *
 * SOURCE ListAuthorizedApplicationsResponse @ squareup/buyerportal/authorizedapps/data.proto at 20:1
 */
export const ListAuthorizedApplicationsResponse = $root.lookupType(
  'squareup.buyerportal.authorizedapps.ListAuthorizedApplicationsResponse'
);
fixType(ListAuthorizedApplicationsResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(ListAuthorizedApplicationsResponse);
/**
 * --
 * @desc Represents the request to unlink a given service from the buyer account.
 * --
 *
 * SOURCE UnlinkAuthorizedApplicationRequest @ squareup/buyerportal/authorizedapps/data.proto at 31:1
 */
export const UnlinkAuthorizedApplicationRequest = $root.lookupType(
  'squareup.buyerportal.authorizedapps.UnlinkAuthorizedApplicationRequest'
);
fixType(UnlinkAuthorizedApplicationRequest, {
  name: { required: true },
});
Builder.createAndAttachToType(UnlinkAuthorizedApplicationRequest);
/**
 * --
 * @desc Represents the response to unlink a given service from the buyer account.
 * --
 *
 * SOURCE UnlinkAuthorizedApplicationResponse @ squareup/buyerportal/authorizedapps/data.proto at 38:1
 */
export const UnlinkAuthorizedApplicationResponse = $root.lookupType(
  'squareup.buyerportal.authorizedapps.UnlinkAuthorizedApplicationResponse'
);
fixType(UnlinkAuthorizedApplicationResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(UnlinkAuthorizedApplicationResponse);
/**
 * --
 * @desc Represents the request to list all authorized services for the buyer account.
 * --
 *
 * SOURCE AuthorizedApplication @ squareup/buyerportal/authorizedapps/data.proto at 47:1
 */
export const AuthorizedApplication = $root.lookupType(
  'squareup.buyerportal.authorizedapps.AuthorizedApplication'
);
fixType(AuthorizedApplication);
Builder.createAndAttachToType(AuthorizedApplication);
/**
 * SOURCE AuthorizedApplicationName @ squareup/buyerportal/authorizedapps/data.proto at 57:1
 */
export const AuthorizedApplicationName = $root.lookupEnum(
  'squareup.buyerportal.authorizedapps.AuthorizedApplicationName'
).values;
