import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

interface CustomBrandIconProps {
  logoColor?: string;
  textColor?: string;
  hideLetters?: boolean;
}

const BrandLogoIcon: React.FC<SvgIconProps & CustomBrandIconProps> = (
  props
) => {
  const { logoColor, textColor, hideLetters, ...rest } = props;
  return (
    <SvgIcon width="87" height="22" viewBox="0 0 87 22" {...rest}>
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 4.39969C0 3.71847 0 3.37786 0.0451397 3.09286C0.293618 1.52403 1.52403 0.293618 3.09286 0.0451397C3.37786 0 3.71848 0 4.3997 0H17.5988C18.28 0 18.6206 0 18.9056 0.0451397C20.4745 0.293618 21.7049 1.52403 21.9534 3.09286C21.9985 3.37786 21.9985 3.71848 21.9985 4.3997V17.5988C21.9985 18.28 21.9985 18.6206 21.9534 18.9056C21.7049 20.4745 20.4745 21.7049 18.9056 21.9534C18.6206 21.9985 18.28 21.9985 17.5988 21.9985H4.39969C3.71847 21.9985 3.37786 21.9985 3.09286 21.9534C1.52403 21.7049 0.293618 20.4745 0.0451397 18.9056C0 18.6206 0 18.28 0 17.5988V4.39969ZM4.12472 5.21017C4.12472 4.61069 4.61069 4.12472 5.21017 4.12472H16.7883C17.3878 4.12472 17.8738 4.61069 17.8738 5.21017V16.7883C17.8738 17.3878 17.3878 17.8738 16.7883 17.8738H5.21017C4.61069 17.8738 4.12472 17.3878 4.12472 16.7883V5.21017ZM8.00586 8.41781C7.97446 8.51446 7.97446 8.63388 7.97446 8.87273V13.1258C7.97446 13.3646 7.97446 13.484 8.00586 13.5807C8.06933 13.776 8.22247 13.9292 8.41781 13.9926C8.51446 14.024 8.63388 14.024 8.87273 14.024H13.1258C13.3646 14.024 13.484 14.024 13.5807 13.9926C13.776 13.9292 13.9292 13.776 13.9926 13.5807C14.024 13.484 14.024 13.3646 14.024 13.1258V8.87273C14.024 8.63388 14.024 8.51446 13.9926 8.41781C13.9292 8.22247 13.776 8.06933 13.5807 8.00586C13.484 7.97446 13.3646 7.97446 13.1258 7.97446H8.87273C8.63388 7.97446 8.51446 7.97446 8.41781 8.00586C8.22247 8.06933 8.06933 8.22247 8.00586 8.41781Z"
          fill={logoColor || 'white'}
        />
        {!hideLetters && (
          <>
            <path
              d="M32.8631 10.0214C32.1888 9.83575 31.5504 9.66075 31.0548 9.43772C30.1374 9.02368 29.7104 8.44958 29.7104 7.63217C29.7104 6.08699 31.19 5.39123 32.6633 5.39123C34.0636 5.39123 35.2864 5.97601 36.1076 7.03672L36.1636 7.10928L37.3473 6.17449L37.2902 6.10193C36.2006 4.69974 34.5836 3.92822 32.7373 3.92822C31.5082 3.92822 30.3815 4.2633 29.5667 4.87262C28.6525 5.55343 28.1685 6.53304 28.1685 7.7058C28.1685 10.4323 30.7113 11.0971 32.7553 11.6317C34.8235 12.1823 36.0907 12.6006 36.0907 14.1928C36.0907 15.7604 34.8352 16.773 32.8926 16.773C31.932 16.773 30.1533 16.5159 29.0351 14.7914L28.9833 14.7103L27.7383 15.6206L27.787 15.6942C28.8438 17.3098 30.668 18.2371 32.7975 18.2371C35.6901 18.2371 37.6348 16.5884 37.6348 14.1362C37.6316 11.3329 34.9873 10.6062 32.8631 10.0214Z"
              fill={textColor || 'white'}
            />
            <path
              d="M47.0682 7.97863V9.45445C46.264 8.35212 45.0718 7.74707 43.68 7.74707C41.0378 7.74707 39.1841 9.9037 39.1841 12.993C39.1841 16.0823 41.0336 18.2218 43.68 18.2218C45.0814 18.2218 46.2735 17.62 47.0682 16.5198V21.9962H48.4601V7.97863H47.0682ZM43.792 16.8549C42.2733 16.8549 40.651 15.8358 40.651 12.9759C40.651 11.1106 41.4764 9.11724 43.792 9.11724C45.781 9.11724 47.0682 10.6325 47.0682 12.9759C47.0682 15.3193 45.7831 16.8602 43.7941 16.8602L43.792 16.8549Z"
              fill={textColor || 'white'}
            />
            <path
              d="M57.6612 7.979V13.512C57.6612 15.4488 56.3475 16.8552 54.5382 16.8552C53.0353 16.8552 52.304 15.9546 52.304 14.1032V7.979H50.9121V14.3902C50.9121 16.7912 52.2046 18.2243 54.3691 18.2243C55.7176 18.2243 56.876 17.647 57.6623 16.5917V17.996H59.0542V7.979H57.6612Z"
              fill={textColor || 'white'}
            />
            <path
              d="M65.4946 7.74951C64.0942 7.74951 62.7013 8.21584 61.6751 9.03005L61.6096 9.08233L62.3367 10.1964L62.4159 10.127C63.2815 9.36939 64.555 9.09941 65.4565 9.09941C65.967 9.09941 67.6336 9.19972 67.6336 10.4963C67.6336 11.6434 66.2492 11.8568 64.9101 12.0649C64.7452 12.0905 64.5804 12.1161 64.4208 12.1428C63.4421 12.3071 61.1445 12.6934 61.1445 15.1553C61.1445 17.0483 62.4698 18.2253 64.6015 18.2253C65.9099 18.2253 66.9266 17.775 67.6294 16.8861V17.997H69.0224V10.7246C69.0266 8.85717 67.7066 7.74951 65.4946 7.74951ZM64.7389 16.8605C64.0953 16.8605 62.5988 16.6866 62.5988 15.0816C62.5988 13.9654 63.2995 13.6325 64.6797 13.3743L65.112 13.2964C65.9776 13.1448 66.9509 12.9762 67.6336 12.5408V13.99C67.6389 16.1029 66.1382 16.8605 64.7389 16.8605Z"
              fill={textColor || 'white'}
            />
            <path
              d="M77.0863 8.16749C76.7555 7.92952 76.2344 7.7876 75.6912 7.7876C75.1406 7.7949 74.5992 7.93125 74.1097 8.18587C73.6202 8.4405 73.196 8.80645 72.8705 9.25488V7.97434H71.4786V17.9903H72.8705V12.6323C72.8705 10.2569 74.1926 9.19298 75.502 9.19298C75.8852 9.18781 76.2647 9.26911 76.6128 9.43095L76.7069 9.4811L77.1423 8.20697L77.0863 8.16749Z"
              fill={textColor || 'white'}
            />
            <path
              d="M82.1444 7.74951C79.4082 7.74951 77.4942 9.91468 77.4942 13.0136C77.4942 16.1295 79.4071 18.2221 82.2501 18.2221C84.2687 18.2221 85.4904 17.2383 86.1604 16.4134L86.2165 16.3451L85.2082 15.4381L85.148 15.517C84.4917 16.3803 83.4919 16.8605 82.3293 16.8605C80.3118 16.8605 78.9971 15.437 78.9622 13.2323H86.5716L86.58 13.1502C86.6053 12.912 86.6184 12.6726 86.6191 12.4331C86.6223 9.67138 84.7812 7.74951 82.1444 7.74951ZM79.0118 11.9881C79.2 10.2284 80.4058 9.09834 82.1063 9.09834C84.1693 9.09834 85.0254 10.5731 85.1459 11.9881H79.0118Z"
              fill={textColor || 'white'}
            />
          </>
        )}
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="86.625" height="22" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default BrandLogoIcon;
