import React from 'react';
import { MarketSvgProps } from '.';

const LockOn: React.FC<MarketSvgProps> = ({ fill = '#000' }) => {
  return (
    <svg
      width="14"
      height="20"
      viewBox="0 0 14 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5V7.18C13.16 7.6 14 8.7 14 10V17C14 18.66 12.66 20 11 20H3C1.34 20 0 18.66 0 17V10C0 8.7 0.84 7.6 2 7.18V5C2 2.24 4.24 0 7 0C9.76 0 12 2.24 12 5ZM10 5C10 3.35 8.65 2 7 2C5.35 2 4 3.35 4 5V7H10V5ZM11 18C11.55 18 12 17.55 12 17V10C12 9.45 11.55 9 11 9H3C2.45 9 2 9.45 2 10V17C2 17.55 2.45 18 3 18H11ZM8.5 13.5C8.5 14.3284 7.82843 15 7 15C6.17157 15 5.5 14.3284 5.5 13.5C5.5 12.6716 6.17157 12 7 12C7.82843 12 8.5 12.6716 8.5 13.5Z"
        fill={fill}
      />
    </svg>
  );
};

export default LockOn;
